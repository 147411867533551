import React, {Component} from "react";
import axios from "axios";

class Notifications extends Component{

    constructor(props) {
        super(props);
        this.state = {
            espaceId  : null,
            tout      : true,
            critique  : true,
            principale: true,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.espace && this.props.espace.id !== this.state.espaceId) {
            const critique = this.checkValeur(this.props.espace.notifications, 'critique')
            const principale = this.checkValeur(this.props.espace.notifications, 'principale')
            this.setState({
                espaceId: this.props.espace.id,
                critique: critique,
                principale: principale,
                tout: principale && critique,
                notifications: this.props.espace.notifications
            })
        }
    }

    checkValeur(array, valeur) {
        return !!(array && array.includes(valeur));
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        })
        if((e.target.name === 'principale' ||  e.target.name === 'critique') && !e.target.checked ) {
            this.setState({
                tout: false
            })
            const notifications = this.state.notifications
            notifications.splice(notifications.indexOf(e.target.name),1)
            this.updateNotifications(notifications)
        } else if ( e.target.name === 'tout' && e.target.checked) {
            this.setState({
                principale: true,
                critique  : true
            })
            this.updateNotifications(['critique', 'principale'])
        } else if (
            (e.target.name === 'principale' && e.target.checked && this.state.critique)
            ||
            (e.target.name === 'critique' && e.target.checked && this.state.principale)
        ) {
            this.setState({tout: true})
            this.updateNotifications(['critique', 'principale'])
        }
    }

    updateNotifications = (array) => {
        axios.put(process.env.REACT_APP_API_URL + '/e_spaces/' + localStorage.getItem('espace_id'), {
            notifications : array
        })
        this.setState({notifications: array})
    }

    render() {
        return (
            <div className="container-fluid form-wrapper mt-3">
                <h1 className="title soleilBold-fonts purple mb-0">notifications</h1>
                <div className="px-5">
                    <div className="d-flex mt-4">
                        <label className="switch">
                            <input type="checkbox" name="tout" checked={this.state.tout} onChange={this.handleChange}/>
                            <span className="slider round"/>
                        </label>
                        <div className="ml-3 slider-label  soleilBold-fonts">Toutes les notifications</div>
                    </div>
                    <div className="d-flex mt-4">
                        <label className="switch">
                            <input type="checkbox" name="principale" checked={this.state.principale} onChange={this.handleChange}/>
                            <span className="slider round"/>
                        </label>
                        <div className="ml-3 slider-label  soleilBold-fonts">Notifications principales</div>
                    </div>
                    <div className="d-flex mt-4">
                        <label className="switch">
                            <input type="checkbox" name="critique" checked={this.state.critique} onChange={this.handleChange}/>
                            <span className="slider round"/>
                        </label>
                        <div className="ml-3 slider-label  soleilBold-fonts">Notifications critiques</div>
                    </div>

                </div>
                <div className="mt-5 px-4">
                    <div className="col-lg-12 ressources-liens">
                        <p className="purple soleilBold-fonts mt-2">Notifications principales</p>
                        <ul className="liste-notifs pl-1 mt-3">
                            <li>Avertissement concession échue</li>
                            <li>Avertissement concession à renouveler</li>
                            <li>Démarches effectuées pour la première fois</li>
                            <li>Étapes d'une procédure finalisée</li>
                            <li>Rappel des procédures à finaliser</li>
                            <li>Rappel paramétrage du compte à finaliser (horaires mairies, horaies cimetières, signature/tampon du maire)</li>
                            <li>Rappel avant les élections</li>
                            <li>Suggestions d'amélioration de Logicim</li>
                        </ul>
                    </div>
                    <div className="col-lg-12 ressources-liens mt-5">
                        <p className="purple soleilBold-fonts mt-2">Notifications critiques</p>
                        <ul className="liste-notifs pl-1 mt-3">
                            <li>Liste des concessions échues dans l'année</li>
                            <li>Liste des concessions échues depuis plus de deux ans</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Notifications;