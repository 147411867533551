import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Document from './Document';
import LoadingSpinner from './LoadingSpinner';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import AjoutNouveau from "../../components/concession/AjoutNouveau";
import {Pagination} from "react-bootstrap";
import pdfIconTbl from "../../assets/images/pdf-icon-tbl.svg";
import btnPlusIcon from "../../assets/images/btn-plus-icon.svg";
import Filtrer from "../../components/pagination/Filtrer";

const Documents = (props) => {
    const [selected, setSelected] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [total, setTotal] = useState(0);
    const [pageActuel, setPageActuel] = useState(1);
    const [search, setSearch] = useState(null);
    const [typeDocument, setTypeDocument] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [nbItem, setNbItem] = useState(30);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + `/documents?${search != null ? 'search=' + search : ''}${typeDocument != null && typeDocument != '' ? 'type=' + typeDocument : ''}&itemsPerPage=${nbItem}&page=${pageActuel}` ).then((res) => {
            let documents = res.data['hydra:member']
            setDocuments(documents)
            setTotal(res.data['hydra:totalItems']);
            setTotalPages(Math.ceil(res.data['hydra:totalItems'] / nbItem) || 1);
        });
    }, [search, nbItem, pageActuel, typeDocument]);

    return (
        <Fragment>
            <div className="contents content-margin">
                <div className="content-header px-3 px-lg-4 page2">
                    <span className="sidemenu-expand-btn">&#9776;</span>
                    <div className="d-flex align-items-center">
                        <div className="col-12 soleilBold-fonts title">
                            MODÈLES
                        </div>
                    </div>
                </div>

                <div className='' >
                    <div className=''>
                        <div className="mx-3 mt-3" >
                            <div className="table-responsive px-3 scroll listes-leftpanel">
                                <table className="table table-striped table-borderless">
                                    <thead>
                                    <tr>
                                        <th className="sortStyle">Référence</th>
                                        <th className="sortStyle">Nom</th>
                                        <th className="sortStyle">Description</th>
                                        <th className="sortStyle">Type</th>
                                        <th className="sortStyle">Actif ?</th>
                                        <th className="sortStyle">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {documents && documents.length > 0 ? (
                                        documents.map((document) => (
                                            <Document key={document.id} currentdocument={document} />
                                        ))
                                    ) : (
                                        <LoadingSpinner />
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid py-3 px-lg-5">
                        <div className="row d-flex justify-content-between">
                            <div className="text-center text-sm-left">
                                <Link to="/ajout-document" className="add-new-location-btn">
                                    Ajouter nouveau
                                    <img src={btnPlusIcon} alt="+" className="img-fluid ml-2"/>
                                </Link>
                            </div>

                            {
                                nbItem === 30
                                ?
                            <React.Fragment>
                                <Pagination>
                                    {pageActuel !== 1 ?
                                        <React.Fragment>
                                            <Pagination.First onClick={() => setPageActuel(1)} />
                                            <Pagination.Prev onClick={() => setPageActuel(prev => prev - 1)}/>
                                            <Pagination.Item onClick={() => setPageActuel(prev => prev - 1)}>
                                                {pageActuel-1}
                                            </Pagination.Item>
                                        </React.Fragment>
                                        : ''
                                    }
                                    <Pagination.Item onClick={() => setPageActuel(pageActuel)} active>{pageActuel}</Pagination.Item>
                                    {pageActuel !== totalPages ?
                                        <React.Fragment>
                                            <Pagination.Item onClick={() => setPageActuel(prev =>  prev + 1)}>
                                                {pageActuel + 1}
                                            </Pagination.Item>
                                            <Pagination.Next onClick={() => setPageActuel(prev => prev + 1)}/>
                                            <Pagination.Last onClick={() => setPageActuel(totalPages)}/>
                                        </React.Fragment>
                                        : ''
                                    }
                                </Pagination>
                            </React.Fragment>
                                    : ''
                            }
                            <div
                                className="list-button d-flex justify-content-center justify-content-sm-end align-items-center mt-4 mt-sm-0">

                                <div className={" mx-4"}>
                                    <select className={"form-control"} onChange={(e) => {setTypeDocument(e.target.value)}} placeholder={"Sélectionner le type de document"} defaultValue="">
                                        <option value="">Sélectionner le type de document</option>
                                        <option value="mails">Mails</option>
                                        <option value="documents">PDF</option>
                                    </select>
                                </div>
                                <div>
                                    <input type="search" onKeyUp={(e) => {setPageActuel(1); setSearch(e.target.value)}} placeholder="Recherche" className={"form-control"} />
                                </div>
                                <button type="button" className="listing-complete-btn soleilBold-fonts">Sélection <span
                                    className="slt-nb">{ selected }</span></button>
                                <button type="button" className="listing-complete-btn soleilBold-fonts">Liste filtrée ( 0
                                    )
                                </button>
                                <button onClick={() => {
                                    setPageActuel(1);
                                    setNbItem(prev => prev === 30 ? 9999 : 30)
                                }} type="button" className="listing-incomplete-btn soleilBold-fonts">Liste complète (
                                    {total} )
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};




export default Documents;


