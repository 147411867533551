import StepsMainFunctions from "./StepsMainFunctions";
import $ from "jquery";

const handleChange = ($this) => {
    document.querySelectorAll('.next').forEach(e => {
        e.addEventListener('click', () => $this.tour.next())
    })
    document.querySelectorAll('.back').forEach(e => {
        e.addEventListener('click', () => $this.tour.back())
    })
    document.querySelectorAll('.cancel').forEach(e => {
        e.addEventListener('click', () => $this.tour.cancel())
    })
}

const stepsCommun = [
    {
        id: 'fiche-commun-etape1',
        scrollTo: true,
        attachTo: { element: '#concession-tour-1', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function() {
            return StepsMainFunctions.PromiseListe('concessions', "#concessions-path", this)
        },
        when: {
            show(){
                if(window.location.pathname === '/aide') {
                    StepsMainFunctions.observer('concession', '#concessions-path', this)
                    this.tour.addSteps(stepsOccupe)
                } else {
                    console.log(this.tour.steps.length)
                    if (this.tour && this.tour.steps && this.tour.steps.length === 8) {
                        this.tour.steps.splice(1, 2)
                    }
                    if(this.tour.steps.length === 6 || this.tour.steps.length === 8) {
                        if (document.querySelector('.deceased-list')) {
                            if (!document.querySelector('.names-wrapper')) {
                                stepsOccupe.splice(stepsOccupe.length - 1, 1)
                                stepsOccupe[stepsOccupe.length - 1].buttons = [
                                    {
                                        classes: 'shepherd-button-secondary cancel',
                                        text: 'Arrêter',
                                        type: 'cancel'
                                    },
                                    {
                                        classes: 'shepherd-button-primary back',
                                        text: 'Précédent',
                                        type: 'back'
                                    },
                                    {
                                        classes: 'shepherd-button-primary cancel',
                                        text: 'Terminer',
                                        type: 'next'
                                    }
                                ]
                            }
                            this.tour.addSteps(stepsOccupe)
                        } else {
                            this.tour.addSteps(stepsVide)
                        }
                    }
                }
                const $this = this
                StepsMainFunctions.showRewrite($this)
            },
            hide() {
                $('#concessions-path').off('click')
            },
            cancel() {
                $('#concessions-path').off('click')
            },
            destroy() {
                $('#concessions-path').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: [
            `<p>Vous pouvez retrouver les <span class="purple">informations importantes</span> concernant l'<span class="purple">emplacement</span> que vous consultez.</p>`
        ],
    },
    {
        id: 'fiche-toLocation-0',
        attachTo: {element: '.contents.content-margin', on: 'top'},
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
        ],
        when: {
            show() {
                StepsMainFunctions.showRewrite(this)
                StepsMainFunctions.locationToEmplacement('.edit-tbl.concession', 'concession-tour-1', this)
            },
            hide() {
                $('.edit-tbl.concession').off('click')
            },
            cancel() {
                $('.edit-tbl.concession').off('click')
            },
            destroy() {
                $('.edit-tbl.concession').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur <span class="purple">"consulter"</span> pour la <span class="purple">concession</span> de votre choix pour poursuivre le tutoriel.</p>`,
    },
    {
        id: 'fiche-toLocation-1',
        scrollTo: true,
        attachTo: { element: '#concession-tour-1', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: [
            `<p>Vous pouvez retrouver les <span class="purple">informations importantes</span> concernant l'<span class="purple">emplacement</span> que vous consultez.</p>`
        ],
    },
    {
        id: 'fiche-commun-etape2',
        scrollTo: true,
        attachTo: { element: '#emplacement-btn', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez consulter grâce à ce bouton le <span class="purple">détail des informations</span> relatives à l'emplacement que vous consultez et les <span
            class="purple">modifier</span>.</p>`
    },
    {
        id: 'fiche-commun-etape3',
        scrollTo: true,
        attachTo: { element: '.durand-buttons', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette section regroupe plusieurs <span class="purple">actions relatives à la gestion de la location</span> de votre emplacement.</p>`,
    },
    {
        id: 'fiche-commun-etape4',
        scrollTo: true,
        attachTo: { element: '.editer-location-btn', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez <span class="purple">consulter</span> ou <span class="purple">modifier</span> les informations de la location de votre emplacement ou en <span class="purple">créer</span> une si l'emplacement n'est pas loué.</p>`,
    },
    {
        id: 'fiche-commun-etape5',
        scrollTo: true,
        attachTo: { element: '.historique-location-btn', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez consulter l\'<span class="purple">historique</span> des locations de l\'emplacement.</p>`,
    },
    {
        id: 'fiche-commun-etape6',
        scrollTo: true,
        attachTo: { element: '.exporter-location-btn', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez <span class="purple">exporter les données</span> de la location en <span class="purple">fichier PDF téléchargeable</span>.</p>`,
    }
]

const stepsOccupe = [
    {
        id: 'fiche-occupe-etape1',
        scrollTo: true,
        attachTo: { element: '#tour-infos-step', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary cancel',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary back',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                handleChange($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Retrouvez ici les <span class="purple">informations importantes</span> de la <span class="purple">location actuelle</span> et de son <span class="purple">état</span>.</p>`,
    },
    {
        id: 'fiche-occupe-etape2',
        scrollTo: true,
        attachTo: { element: '.deceased-list', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary cancel',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary back',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                handleChange($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette section vous permet de <span class="purple">consulter et gérer les photos de la concession</span> et de consulter la <span class="purple">liste des défunts</span>. Un tutoriel détaillé sur la gestion des défunts est disponible dans la fenêtre d'assistance <span class="mini-aide"></span>.</p>`,
    },
    {
        id: 'fiche-occupe-etape3',
        scrollTo: true,
        attachTo: { element: '.map-wrapper-cont', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary cancel',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary back',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                handleChange($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>L\'<span class="purple">emplacement en surbrillance</span> sur le plan du cimetière est celui que vous consultez actuellement.</p>`,
    },
    {
        id: 'fiche-occupe-etape4',
        scrollTo: true,
        attachTo: { element: '.procedures-acces-wrapper', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary cancel',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary back',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                handleChange($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: [
            `
            <p>Vous pouvez ici accéder aux <span class="purple">différentes procédures</span>. Ce symbole <span class="green-tick left-0 position-relative mr-3"></span>
            signifie que la procédure est <span class="purple">possible</span>. Au contraire, <span class="red-tick left-0 position-relative mr-3"></span> 
            indique que la procédure est <span class="purple">impossible</span>.
            </p>
            `
        ]
    },
    {
        id: 'fiche-occupe-etape5',
        scrollTo: true,
        attachTo: { element: '.documents-wrapper', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary cancel',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary back',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                handleChange($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>La section <span class="purple">Documents</span> vous permet de télécharger les documents de la concession ou de les générer.</p>`,
    },
    {
        id: 'fiche-occupe-etape6',
        scrollTo: true,
        attachTo: { element: '.emails-wrapper', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary cancel',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary back',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                handleChange($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>La section <span class="purple">Dernier(s) E-mail(s)</span> vous permet de visualiser les derniers emails qui vous ont été envoyés par Logicim.</p>`,
    },
    {
        id: 'fiche-occupe-etape7',
        scrollTo: true,
        attachTo: { element: '.travaux-wrapper', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary cancel',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary back',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                handleChange($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>La section <span class="purple">Travaux</span> vous permet d'ajouter, modifier ou consulter les travaux 
                effectués sur l'emplacement. Un tutoriel est disponible sur l'ajout de travaux dans la fenêtre d'assistance <span class="mini-aide"></span>.</p>`,
    },
    {
        id: 'fiche-occupe-etape8',
        scrollTo: true,
        attachTo: { element: '.names-wrapper', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary cancel',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary back',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                handleChange($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette section vous donne l'identité et les informations de contact des différents <span class="purple">ayant-droits</span> de votre concession.</p>`,
    },
    {
        id: 'fiche-occupe-etape9',
        buttons: [
            {
                classes: 'shepherd-button-secondary cancel',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary back',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: 'Terminer',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                handleChange($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez maintenant utiliser la <span class="purple">fiche concession</span> de Logicim. Vous pouvez refaire à tout moment ce tutoriel via la fenêtre d'assistance <span class="mini-aide"></span>.</p>`
      }
];

const stepsVide = [
    {
        id: 'fiche-vide-etape1',
        scrollTo: true,
        attachTo: { element: '.map-wrapper-cont', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary cancel',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary back',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                handleChange($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>L\'<span class="purple">emplacement en surbrillance</span> sur le plan du cimetière est celui que vous consultez actuellement.</p>`
    },
    {
        id: 'fiche-vide-etape2',
        scrollTo: true,
        attachTo: { element: '#travaux-wrapper', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary cancel',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary back',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                handleChange($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>La partie <span class="purple">Travaux</span> vous permet d'ajouter, consulter ou modifier les travaux effectués sur l'emplacement.</p>`
    },
    {
        id: 'fiche-vide-etape3',
        scrollTo: true,
        attachTo: { element: '.durand-family-wrapper', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary cancel',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary back',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary next',
                text: 'Terminer',
                type: 'complete'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                handleChange($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette emplacement est vide, nous vous invitons à <span class="purple">relancer ce tutoriel</span> sur un <span class="purple">emplacement occupé</span> pour
        avoir plus de détails sur l'utilisation de cette page ! Vous pouvez également créer une location via le bouton <span class="purple">"éditer une location"</span> ou suivre
        notre <span class="purple">tutoriel sur la création de concession</span> disponible dans la <span class="purple">fenêtre d'aide de l'assistance</span>.</p>`
    },
];

export default stepsCommun;