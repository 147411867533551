import React, { Component } from 'react';
import {Tab, Tabs} from "react-bootstrap";
import Importation from "./Importation";
import Exportation from "./Exportation";
import Restauration from "./Restauration";
import '../../assets/css/gestion_donnees.css'

class donneesMenu extends Component{

    render() {
        return (
            <div className="contents content-margin">
                <div className="content-header px-3 px-lg-4 page2">
                    <span className="sidemenu-expand-btn">&#9776;</span>
                    <div className="d-flex align-items-center">
                        <div className="col-12 soleilBold-fonts title">
                            GÉRER MES DONNÉES
                        </div>
                    </div>
                </div>
                <div className="px-3 px-lg-4">
                    <Tabs defaultActiveKey="exportation" className="quick-navbar d-flex mb-3 justify-content-center px-3">
                                <Tab eventKey="exportation" title="Exportation">
                                    <div className="essential-cemetery-wrapper scroll">
                                        <Exportation/>
                                    </div>
                                </Tab>
                        {/*
                                <Tab eventKey="importation" title="Importation">
                                    <div className="essential-cemetery-wrapper scroll">
                                        <Importation/>
                                    </div>
                                </Tab>
*/}
                                <Tab eventKey="point_restauration" title="Point de restauration">
                                    <div className="essential-cemetery-wrapper scroll">
                                        <Restauration/>
                                    </div>
                                </Tab>

                    </Tabs>
                </div>
            </div>
        )
    }
}

export default donneesMenu;