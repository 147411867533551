import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import filtreIcon from "../../assets/images/filter-icon-submenu.svg";
import filtreIconBlanc from "../../assets/images/filter-icon-submenu-blanc.svg";
import "../../assets/css/filtrer.css";

function Filtrer(props) {
    console.log(props)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFilterEmplacementChange = function(event, type) {
        const target = event.currentTarget;

        if (target.checked && props.filters[type].indexOf(target.value) === -1) {
            props.filters[type].push(target.value);
        }

        if (!target.checked && props.filters[type].indexOf(target.value) > -1) {
            props.filters[type] = props.filters[type].filter((value) => {
                return value !== target.value
            })
        }

        props.emplacementListRef.current.refreshPagination();
    }

    return (
        <>
            <span className="soleilRegular-fonts title cursor" onClick={handleShow} id="filtre-title">
                    <img src={filtreIcon}
                         alt=""
                         className="img-fluid mr-2" style={{width: '15px', marginTop: '-3px'}}/>
                {props.title}
            </span>

            <Modal show={show} onHide={handleClose} className="filtre-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <img src={filtreIconBlanc}
                             alt=""
                             className="img-fluid mr-2" style={{width: '15px', marginTop: '-3px'}}/>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div className="row"></div>
                    <div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="red cancel-btn tbl" data-dismiss="modal" onClick={handleClose}>Annuler</button>
                    <button id="step-tour-valider" className="tbl btn-navigation bk-red ml-2 valider" onClick={handleClose}>Valider</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default Filtrer;