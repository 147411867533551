import React, { Component } from 'react';
import $ from 'jquery';
import 'ol/ol.css';
import { Map, View } from 'ol';
import { Vector as VectorSource, OSM } from 'ol/source';
import { Tile, Vector as VectorLayer } from 'ol/layer';
import { Select, Draw } from 'ol/interaction';
import Bar from 'ol-ext/control/Bar';
import Toggle from 'ol-ext/control/Toggle';
import TextButton from 'ol-ext/control/TextButton';
import Button from 'ol-ext/control/Button';
import LineString from 'ol/geom/LineString';
import GeoJSON from 'ol/format/GeoJSON';
// import '../index.css';
import './functionality.css';


export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.createMap();
  }

  createMap = () => {
    //  Vector layer
    const vector = new VectorLayer({ source: new VectorSource() })
    const map = new Map({
      target: 'map-container',
      view: new View({
        zoom: 14,
        center: [270701, 6247637]
      }),
      layers: [
        new Tile({ source: new OSM() }),
        vector
      ]
    });

    // Main control bar
    const mainbar = new Bar(
      //@ts-expect-error
      { className: 'mainbar' });
    map.addControl(mainbar);
    var editbar = new Bar({
      toggleOne: true,	// one control active at the same time
      group: false			// group controls together
    });
    mainbar.addControl(editbar);
    const sbar = new Bar();
    sbar.addControl(new Button({
      html: '<i class="fa fa-times"></i>',
      title: "Delete",
      handleClick: function () {
        var features = selectCtrl.getInteraction().getFeatures();
        if (!features.getLength()) info("Select an object first...");
        else info(features.getLength() + " object(s) deleted.");
        for (var i = 0, f; f = features.item(i); i++) {
          vector.getSource().removeFeature(f);
        }
        selectCtrl.getInteraction().getFeatures().clear();
      }
    }));
    sbar.addControl(new Button({
      html: '<i class="fa fa-info"></i>',
      title: "Show informations",
      handleClick: function () {
        switch (selectCtrl.getInteraction().getFeatures().getLength()) {
          case 0: info("Select an object first...");
            break;
          case 1:
            var f = selectCtrl.getInteraction().getFeatures().item(0);
            info("Selection is a " + f.getGeometry().getType());
            break;
          default:
            info(selectCtrl.getInteraction().getFeatures().getLength() + " objects seleted.");
            break;
        }
      }
    }));
    const selectCtrl = new Toggle(
      // @ts-expect-error
      {
        html: '<i class="fa fa-hand-pointer"></i>',
        className: "select",
        title: "Select",
        interaction: new Select({ hitTolerance: 2 }),
        active: true,
        onToggle: (active) => {
          $("#info").text("Select is " + (active ? "activated" : "deactivated"));
        }
      });
    mainbar.addControl(selectCtrl);
    var pedit = new Toggle({
      html: '<i class="fa fa-map-marker" ></i>',
      className: "point",
      title: 'Point',
      interaction: new Draw({
        type: 'Point',
        source: vector.getSource()
      })
    });
    editbar.addControl(pedit);
    var ledit = new Toggle({
      html: '<i class="fa fa-share-alt" ></i>',
      className: "LineString",
      title: 'LineString',
      interaction: new Draw({
        type: 'LineString',
        source: vector.getSource(),
        // Count inserted points
        geometryFunction: function (coordinates, geometry) {
          if (geometry) geometry.setCoordinates(coordinates);
          else geometry = new LineString(coordinates);
          this.nbpts = geometry.getCoordinates().length;
          return geometry;
        }
      }),
      // Options bar associated with the control
      bar: new Bar({
        controls: [
          new TextButton({
            html: 'undo',
            title: "Delete last point",
            handleClick: function () {
              if (ledit.getInteraction().nbpts > 1) ledit.getInteraction().removeLastPoint();
            }
          }),
          new TextButton({
            html: 'Finish',
            title: "finish",
            handleClick: function () {
              // Prevent null objects on finishDrawing
              if (ledit.getInteraction().nbpts > 2) ledit.getInteraction().finishDrawing();
            }
          })
        ]
      })
    });
    editbar.addControl(ledit);
    var save = new Button({
      html: '<i class="fa fa-download"></i>',
      title: "Save",
      handleClick: function (e) {
        var json = new GeoJSON().writeFeatures(vector.getSource().getFeatures());
        info(json);
        console.log(json)
      }
    });
    mainbar.addControl(save);

    // Show info
    function info(i) {
      $("#info").html(i || "");
    }
  }
  
  render = () => {
    return (
      <>
        <div>
          <h1 className="App">Ol-Ext With React</h1>
        </div>
        <div id='map-container' style={{height: '100vh', width: '100%'}}>
        </div>
        <div>
          Position :
            <select >
            <option value="top">top</option>
            <option value="top-left">top-left</option>
            <option value="left">left</option>
            <option value="bottom-left">bottom-left</option>
            <option value="bottom">bottom</option>
            <option value="bottom-right">bottom-right</option>
            <option value="right">right</option>
            <option value="top-right">top-right</option>
          </select>
        </div>
        <textarea id='info' style={{ width: '25em', height: '10em' }}></textarea>
      </>
    );
  }
}