import React, { Component } from 'react';
import '../../assets/css/ajoutEspace.css'
import axios from "axios";
import timerIcon from "../../assets/images/timer-icon.svg";

class AjoutCimetiere extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nom       : '',
            adresse   : '',
            codePostal: '',
            ville     : '',
            map       : null,
            fichier_donnees       : null,
            plan_pdf       : null,
            fichier_zip       : null,
            actif     : 0,
            horaires       : [],
        }
    }

    handleChange = async (e)  =>{
        let name = e.target.name
        await this.setState({
            [name]: e.target.value
        });
        this.updateEspace(this.state)
    }
    handleUpload = async (e) => {
        let name = e.target.name
        const uploadFile = this.props.uploadFile
        if (uploadFile) {
            const res = await this.props.uploadFile(e)
            this.setState({
                [name] : res ? res.data.nom : null
            });
        }
        this.updateEspace(this.state)
    }

    handleChangeFile = async (e, field) => {
        const file =  e.target.files[0]
        await file.text().then(svgText => {
            this.setState({[field]: svgText})
        })
        this.updateEspace(this.state)
        e.target.value = null
    }

    handleChangePlan = async (e) => {
        const file =  e.target.files[0]
        await file.text().then(svgText => {
            this.setState({map: svgText})
        })
        this.updateEspace(this.state)
        e.target.value = null
    }

    supprimerPlan = () => {
        this.setState({
            map: null
        })
    }

    supprimerFile = (field) => {
        this.setState({
            [field]: null
        })
    }

    updateEspace = (espace) => {
        const updateGroups = this.props.updateGroups
        console.log(this.props)
        if (updateGroups) {
            updateGroups('espaces', this.props.index, espace)
            updateGroups('maire', this.props.index, espace)
        }
    }
    handleHoraires = (e) => {
        const index = e.target.getAttribute('data-index')
        let horaires = this.state.horaires
        horaires[index] = e.target.value
        this.setState({
            horaires : horaires
        })
    }
    updateHoraires = () => {
        axios.put(process.env.REACT_APP_API_URL + '/e_spaces/' + localStorage.getItem('espace_id'), {
            horaires : this.state.horaires
        })
    }

    render() {
        const jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]
        const horaires = jours.map( (jour,index) => {
            return (
                <div className="cols" key={index}>
                    <label>{jour}</label>
                    <textarea className="horaire-mairie" placeholder="non renseigné" value={this.state.horaires[index] ? this.state.horaires[index] : ''} data-index={index} onChange={this.handleHoraires} onBlur={this.updateHoraires}/>
                </div>
            )
        })
        return (
            <>
                <div className="form-wrapper px-5">
                    <p className="soleilBold-fonts purple">Cimetière {this.props.index + 1}</p>
                    <div className="px-2">
                        <div className="d-flex pt-3">
                            <label htmlFor="commune" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Nom :</label>
                            <input type="text" id="nom" name="nom" value={this.state.nom}
                                   className="soleilRegular-fonts col-3" placeholder="Nom" onChange={this.handleChange} required/>
                        </div>
                        <div className="d-flex pt-3">
                            <label htmlFor="adresse" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Adresse :</label>
                            <input type="text" id="adresse" name="adresse" value={this.state.adresse}
                                   className="soleilRegular-fonts col-3" placeholder="Adresse" onChange={this.handleChange}/>
                        </div>
                        <div className="d-flex pt-3">
                            <label htmlFor="codePostal" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Code postal :</label>
                            <input type="text" id="codePostal" name="codePostal" value={this.state.codePostal}
                                   className="soleilRegular-fonts col-3" placeholder="code postal" onChange={this.handleChange}/>
                        </div>
                        <div className="d-flex pt-3">
                            <label htmlFor="ville" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Ville :</label>
                            <input type="text" id="ville" name="ville" value={this.state.ville}
                                   className="soleilRegular-fonts col-3" placeholder="ville" onChange={this.handleChange}/>
                        </div>
                        <div className="d-flex pt-3">
                            <label htmlFor="actif" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Actif :</label>
                            <div className="select-grey-wrapper">
                                <select className="soleilRegular-fonts purple select-grey" id="actif" name="actif" value={this.state.actif} onChange={this.handleChange}>
                                    <option value="0">Non</option>
                                    <option value="1">Oui</option>
                                </select>
                            </div>
                        </div>

                        <div className="timer-wrapper mt-3">
                            <div className="title soleilBold-fonts">
                                <img src={timerIcon} alt="" className="img-fluid mr-3"/> HORAIRES CIMETIÈRE
                            </div>
                            <div className="d-flex">
                                {horaires}
                            </div>
                        </div>

                        <p className="soleilBold-fonts purple mt-5">Cartographie</p>
                        <div className="mt-3 d-flex">
                            <div className="d-flex mt-2">
                                <label className="col-4 p-0 m-0 soleilRegular-fonts">Plan SVG du cimetière :</label>
                                {
                                    this.state.map ?
                                        <a target="_blank" className=" soleilRegular-fonts img-fluid" href={process.env.REACT_APP_MEDIA_URL + this.state.map} alt="logo_mairie" style={{maxWidth: "200px"}}>Télécharger</a>
                                        : <p className="grey small px-4">Aucune fichier</p>
                                }
                            </div>
                            <div className="d-flex mt-2 ml-3">
                                <div className="col-4 "/>
                                <div className="d-flex">
                                    <div className="modifier-file">
                                        <label htmlFor={'modif_plan' + this.props.index } className="soleilRegular-fonts red mr-3 cursor">{ this.state.map  ? 'Modifier le plan' : 'Ajouter le plan'}</label>
                                        <input id={'modif_plan' + this.props.index } className="d-none" type="file" name="map" onChange={this.handleUpload}/>
                                    </div>
                                    { this.state.map  ?
                                        <div>
                                            <a className="soleilRegular-fonts purple cursor"
                                               onClick={this.supprimerPlan}>Supprimer</a>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="mt-3 d-flex">
                            <div className="d-flex mt-2">
                                <label className="col-4 p-0 m-0 soleilRegular-fonts">Plan PDF du cimetière</label>
                                {
                                    this.state.plan_pdf ?
                                        <a target="_blank" className=" soleilRegular-fonts img-fluid" href={process.env.REACT_APP_MEDIA_URL + this.state.plan_pdf} alt="logo_mairie" style={{maxWidth: "200px"}}>Télécharger</a>
                                        : <p className="grey small px-4">Aucune fichier</p>
                                }
                            </div>
                            <div className="d-flex mt-2 ml-3">
                                <div className="col-4 "/>
                                <div className="d-flex">
                                    <div className="modifier-file">
                                        <label htmlFor={'modif_blason' + this.props.index } className="soleilRegular-fonts red mr-3 cursor">{ this.state.plan_pdf  ? 'Modifier le plan PDF' : 'Ajouter un plan PDF'}</label>
                                        <input id={'modif_blason' + this.props.index } className="d-none" type="file" name="plan_pdf" onChange={this.handleUpload}/>
                                    </div>
                                    { this.state.plan_pdf  ?
                                        <div>
                                            <a className="soleilRegular-fonts purple cursor"
                                               onClick={() => {this.supprimerFile('plan_pdf')}}>Supprimer</a>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>


                        <p className="soleilBold-fonts purple mt-5">Données</p>
                        <div className="mt-3 d-flex">
                            <div className="d-flex mt-2">
                                <label className="col-4 p-0 m-0 soleilRegular-fonts">Données du cimetière :</label>
                                {
                                    this.state.fichier_donnees ?
                                        <a href={process.env.REACT_APP_MEDIA_URL + this.state.fichier_donnees } target="_blank" style={{height: 'auto', width: '300px'}}>Télécharger</a>
                                        : <p className="grey small px-4">Aucun plan</p>
                                }
                            </div>
                            <div className="d-flex mt-2 ml-3">
                                <div className="col-4 "/>
                                <div className="d-flex">
                                    <div className="modifier-file">
                                        <label htmlFor={'modif_donnees' + this.props.index } className="soleilRegular-fonts red mr-3 cursor">{ this.state.fichier_donnees  ? 'Modifier les données' : 'Ajouter'}</label>
                                        <input id={'modif_donnees' + this.props.index } className="d-none" type="file" name="fichier_donnees" onChange={this.handleUpload}/>
                                    </div>
                                    { this.state.fichier_donnees  ?
                                        <div>
                                            <a className="soleilRegular-fonts purple cursor"
                                               onClick={() => {this.supprimerFile('fichier_donnees')}}>Supprimer</a>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="mt-3 d-flex">
                            <div className="d-flex mt-2">
                                <label className="col-4 p-0 m-0 soleilRegular-fonts">ZIP Archives PDF</label>
                                {
                                    this.state.fichier_zip ?
                                        <a target="_blank" className=" soleilRegular-fonts img-fluid" href={process.env.REACT_APP_MEDIA_URL + this.state.fichier_zip} alt="logo_mairie" style={{maxWidth: "200px"}}/>
                                        : <p className="grey small px-4">Aucune fichier</p>
                                }
                            </div>
                            <div className="d-flex mt-2 ml-3">
                                <div className="col-4 "/>
                                <div className="d-flex">
                                    <div className="modifier-file">
                                        <label htmlFor={'modif_zip' + this.props.index } className="soleilRegular-fonts red mr-3 cursor">{ this.state.fichier_zip  ? 'Modifier les données' : 'Ajouter'}</label>
                                        <input id={'modif_zip' + this.props.index } className="d-none" type="file" name="fichier_zip" onChange={this.handleUpload}/>
                                    </div>
                                    { this.state.plan_pdf  ?
                                        <div>
                                            <a className="soleilRegular-fonts purple cursor"
                                               onClick={() => {this.supprimerFile('fichier_zip')}}>Supprimer</a>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        )
    }
}

export default AjoutCimetiere;