import {useState} from "react"
import {Button, Modal} from "react-bootstrap"
import {connect} from "react-redux";

function PopupWrongCredential(props) {
    const dispatch = props.dispatch

    const handleClose = () => {
        dispatch({
            type: "RESET_WRONG_CREDENTIAL"
        })
    };
    return (
        <>
            <Modal
                show={props.wrongCredential}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Attention !</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Le mot de passe ou le pseudo de l'utilisateur ne correspondent pas, veuillez réessayer.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const mapStateToProps = ({ wrongCredential = false }) => ({
    wrongCredential
});
export default connect(
    mapStateToProps
)(PopupWrongCredential);
