import React, {Component} from "react";
import axios              from "../../../helpers/axios";
import $                  from "jquery";
import Tools              from "../../../components/utils/Tools";
import redBellIcon        from "../../../assets/images/red-bell-icon.svg";
import infoWhiteIcon      from "../../../assets/images/info-icon-white.svg";
import dlDocProcedure     from "../../../assets/images/dl-documents-procedures.svg";
import {Link}             from "react-router-dom";
import HandleDownload     from "../../../components/utils/HandleDownload"

class Retrocession extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emplacementId : this.props.match.params.emplacementId,
            form : [],
            etape : 1,
            emplacement: {} ,
            lastLocation : {},
            retrocessions : [],
            lastRetrocessionCall : 0,
            textEtape1 : ''
        }
    }

    componentDidMount() {
        Tools.navigationPopup('Rétrocession', 1)
        Tools.updateNameUploadFile()
        axios.get(process.env.REACT_APP_API_URL + '/emplacements/' + this.state.emplacementId)
            .then ((response) => {
                this.setState({ emplacement: response.data})

                let procedures = this.state.emplacement.procedureRetrocessions
                if (procedures !== undefined) {
                    axios.all(procedures.map(p => axios.get(process.env.REACT_APP_API_URL + '/procedure_retrocessions/' + p.replace(/\D/g, ""))))
                        .then(axios.spread((...res) => {
                            this.setState({retrocessions: res})
                        }));
                }

                const location = this.state.emplacement.lastLocation
                if (location) {
                    axios.get(process.env.REACT_APP_API_URL + '/locations/' + location.replace(/\D/g, "")).then((response) => {
                        this.setState({lastLocation: response.data})
                        if (this.state.lastLocation.champsSuppValeurs.fini === true) {
                            this.setState({textEtape1: 'Rétrocession impossible car l\'emplacement est libre.'})
                            $('.step-btn').remove()
                        } else {
                            if (this.state.emplacement.occupants && this.state.emplacement.occupants.length) {
                                this.setState({textEtape1: 'Rétrocession impossible car la concession est occupée.'})
                                $('.step-btn').remove()
                            } else if (!this.state.lastLocation.dateDebut) {
                                this.setState({textEtape1: 'Les informations de la concession sont incomplètes. Veuillez vérifier les dates de début et de fin de la concession'})
                                $('.step-btn').remove()
                            } else {
                                this.setState({textEtape1: 'Vous enregistrez ici les éléments de procédure pour effectuer une rétrocession.'})
                            }
                        }
                    })
                } else{
                    this.setState({textEtape1: 'Rétrocession impossible car aucune concession n\'est en cours.'})
                    $('.step-btn').remove()
                }
            })
    }

    getForm = (e) => {
        if (this.state.lastRetrocessionCall!== $(e.target).attr('data-retrocession')) {

            this.setState({lastRetrocessionCall : $(e.target).attr('data-retrocession')})

            e.stopPropagation();

            let promise = process.env.REACT_APP_API_URL + '/retrocession/form/' + this.state.emplacementId + '/1';
            if ($(e.target).attr('data-retrocession') !== undefined) {
                promise += '/' + $(e.target).attr('data-retrocession')
            }

            axios.get(promise)
                .then((response) => {
                    this.setState({form: response.data})

                    $('.modal.retrocession').modal('show');

                    if ($(e.target).attr('data-retrocession') === undefined) {
                        this.updateField();
                    }else {
                        $('input, select').prop( "disabled", true )
                        $('[type="radio"].table-checkbox:not(:checked)').parent().remove()
                        $('[type="radio"]').parent().addClass('select-row')
                    }
                })
        }
    }

    updateField = () => {
        if ($('.modal.retrocession .ligne.s2').hasClass('last')) {
            $('.preview').show()
        }

        $('[type="radio"].table-checkbox').change(function () {
            $('[type="radio"].table-checkbox:not(:checked)').parent().removeClass('select-row');
        })

        document.querySelectorAll('#retrocession1_dateRetrocession', '#retrocession1_prixConcessionInitiale').forEach((e) => {
           e.addEventListener('change', () => {
                this.updateProrataTemporis()
            })
        })

        document.getElementById('retrocession1_accordConseilMunicipal').addEventListener('change', function () {
            if (this.value === '0') {
               document.getElementById('error').innerText = "Le concessionaire doit rester titulaire de la concession si l'accord de la commune n'a pas été donné."
               document.querySelector('.retrocession .preview').style.display = "none";
            } else {
                document.getElementById('error').innerText = ""
                document.querySelector('.retrocession .preview').style.display  = 'inline-block'
            }
        })
        document.getElementById('retrocession1_demandeeTitulaire').addEventListener('change', function () {
            if (this.value === '0') {
               document.getElementById('error').innerText = "La rétrocession doit obligatoirement être demandé par le titulaire."
               document.querySelector('.retrocession .preview').style.display = "none";
            } else {
                document.getElementById('error').innerText = ""
                document.querySelector('.retrocession .preview').style.display  = 'inline-block'
            }
        })
    }

   updateProrataTemporis = () => {
        let data = {
            emplacementId: this.state.emplacementId,
            tarif: $('[id$=prixConcessionInitiale]').val(),
            dateFin: new Date($('[id$=dateRetrocession]').val())
        }
        axios.get(process.env.REACT_APP_API_URL + '/tarifs/remboursement', {
            params: data
        }).then((response) => {
            $('[id$=montantRembourser]').val(response.data.prorata)
        })
    }

    render() {
        require('../../../assets/css/procedures.css')
        require('../../../assets/css/formPop-up.css')

        const proceduresFaites = []

        for (const procedureFaite of this.state.retrocessions){
            const dateRetrocession = new Date(procedureFaite.data.dateRetrocession)
            proceduresFaites.push (
                <div className="etape bg-white p-3 etape-finie">
                    <p className="soleilBold-fonts title purple">étape 1/1 : rétrocession</p>
                    <p className="soleilRegular-fonts purple etape-description">Rétrocession effectuée le {dateRetrocession.toLocaleDateString()}</p>
                    <div className="d-flex justify-content-between align-items-center">
                        <a className="dl-doc soleilBold-fonts d-flex cursor" onClick={() => HandleDownload([procedureFaite.data.acte])}>
                            <img src={dlDocProcedure} className="img-fluid mr-2" width="20px" alt=""/>
                            <p>Documents</p>
                        </a>
                        <button type="button" className="add-doc-btn soleilBold-fonts"
                                data-toggle="modal" data-target="#etape1" data-retrocession = {procedureFaite.data.id} onClick={this.getForm}>Détails
                        </button>
                    </div>
                </div>
            )
        }

        return (
            <div className="contents content-margin">
                <div className="content-header px-3 px-lg-4 page2">
                    <span className="expand-btn">&#9776;</span>
                    <div className="d-flex align-items-center">
                        <div className="col-12 soleilBold-fonts">
                            <span className="title">RETROCESSION DE CONCESSION : </span>
                            <span className="text-white">Emplacement {this.state.emplacement.carre} {this.state.emplacement.allee}</span>
                        </div>
                    </div>
                </div>
                <div className="px-3 px-lg-4  ">
                    <div className="retour-concession d-flex align-items-center mt-3 ml-4 mb-3">
                        <img src={redBellIcon} className="mr-3" width="40px" alt=""/>
                        <Link to={{pathname: "/fiche-concession/" + this.props.match.params.emplacementId}}
                              className="soleilBold-fonts purple m-0 pt-4">

                            {
                                this.state.lastLocation && this.state.lastLocation.champsSuppValeurs ?
                                    'Famille ' + this.state.lastLocation.champsSuppValeurs.famille
                                    : ''
                            } (retour à l'emplacement)
                        </Link>
                    </div>

                    <div className="essential-cemetery-wrapper scroll">
                        <div className="container-fluid px-0 ">
                            <div className="col-lg-12 bg-white py-3">
                                <div className="info-security-content">
                                    <div className="d-lg-inline-flex align-items-center mb-3">
                                        <img src={infoWhiteIcon} alt=""
                                             className="img-fluid mr-2" width="25px"/>
                                        <p className="soleilBold-fonts mt-2 ">Rappels</p>
                                    </div>
                                    <p className="soleilBook-fonts">
                                        Attention, pour effectuer une rétrocession, la concession doit être vide de tout
                                        corps (non utilisée ou défunts exhumés) et la procédure doit être demandée
                                        exclusivement par le(s) concessionnaire(s) titulaire(s) et acceptée par le conseil
                                        municipal. Les ayant-droits n'ont pas la possibilité d'effectuer une rétrocession.
                                    </p>
                                </div>
                            </div>
                            <div className="etapes-procedures pt-3 d-flex justify-content-center">
                                <div className="etapes-procedures pt-3 d-flex justify-content-center">
                                    <div className="etapes-wrapper d-flex flex-wrap">
                                        {proceduresFaites}
                                        <div className="etape bg-white p-3">
                                            <p className="soleilBold-fonts title purple etape-title">étape 1/1 :
                                                rétrocession</p>
                                            <p className="soleilRegular-fonts purple etape-description" >{this.state.textEtape1}</p>
                                            <button type="button" className="add-doc-btn soleilBold-fonts step-btn"
                                                    data-toggle="modal" data-target="#etape1" id="1" onClick={this.getForm}>Rétrocession
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{__html:this.state.form}} className="formulaire" data-submit={process.env.REACT_APP_API_URL + '/retrocession/form/' + this.state.emplacement.id + '/' + this.state.etape}/>
            </div>
        )
    }
}

export default Retrocession;