import React, {Component} from "react";
import axios from "axios";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import back from "../../assets/images/back-icon-purple.svg";
import searchIcon from '../../assets/images/search-icon-purple.svg';
import aide from "../../assets/images/aide-icon.svg";
import {Link} from "react-router-dom";
import Tour from "../tutorisarion/Tour";
import tutoriels from "../tutorisarion/tutoriels.json";
import AssistanceTour from "../tutorisarion/AssistanceTour";

class Aide extends Component {

    constructor(props) {
        super(props)
        this.state = {
            search: '',
            searchResult: [],
            loading: false,
            user: null
        }
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + '/users/isFirstConnection').then((res) => {
            this.setState({user : res.data})
        })
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.search()
        }
    }

    handleChange = (event) => {
        this.setState({search: event.target.value});
    }

    ouvrirNav() {
        document.getElementById("sidePanelAide").style.width = "350px"
        document.querySelector('#overlay-aide').style.visibility = "visible"
    }

    /* Ferme la sidebar pour la gestion du compte */
    fermerNav() {
        document.getElementById("sidePanelAide").style.width = "0"
        document.querySelector('#overlay-aide').style.visibility = "hidden"
    }

    search = () => {
        this.setState({loading : true})
        axios.get(process.env.REACT_APP_API_URL + "/lexiques?titre=" + this.state.search).then((res) => {
            this.setState({
                searchResult: res.data['hydra:member'],
                loading: false
            })
        })
    }

    tronquerContenu(contenu, maxLength) {
        let trimmedString = contenu.substr(0, maxLength);
        return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
    }

    render() {
        require('../../assets/css/aide.css');
        require('../../assets/css/spinner-loading.css');
        const location = window.location.pathname;
        const maxLenght = 300
        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" className="tooltip-aide mb-1" {...props}>
                Ouvrir l'assistance
            </Tooltip>
        );
        const results = this.state.searchResult.map((result) =>
            <div className="result">
                <p className="titre"> {result.titre }</p>
                <p className="contenu">
                    {
                        result.contenu.length > 300 ? this.tronquerContenu(result.contenu, maxLenght) + '...' : result.contenu
                    }
                </p>
                {
                    result.contenu.length > 300 ?
                        <Link to={{
                                pathname: "/aide/" + result.titre,
                              }}
                              className="see-more underline float-right"
                              onClick={this.fermerNav}
                              target="_blank"
                        >
                            voir toute la définition
                        </Link>
                        : ''
                }
            </div>
        )

        const tutoForPage = []
        tutoriels.map(  tuto => {
            if(tuto.acces.length === 0 || tuto.acces.includes(location) || tuto.acces.some((acces) => location.includes(acces))) {
                let etapes =  require('../tutorisarion/tuto-steps/' + tuto.etapes)
                tutoForPage.push(
                    <Tour steps={etapes.default} title={tuto.titre} etapes={tuto.etapes} key={tuto.titre}/>
                )
            }
        })

        return (
            <React.Fragment>
                {
                    location !== '/login' &&  location !== '/switch-espace'?
                        <div className="overlay" id="overlay-aide" onClick={this.fermerNav}/>
                        : ''
                }
                {
                    location !== '/login' &&  location !== '/switch-espace' ?
                        <React.Fragment>
                            {
                                this.state.user && this.state.user.firstConnection === false  ?
                                    <AssistanceTour firstConnection = {this.state.user.firstConnection} userId = {this.state.user.id}/>
                                : ''
                            }
                            <div id="sidePanelAide" className="sidepanel">
                                <img src={back} className="closebtn" onClick={this.fermerNav} alt=""/>
                                <div className="d-flex flex-column justify-content-between h-100">
                                    <div>
                                        <div className="title">
                                            <img src={aide} className="img-fluid mr-3" width='25px' alt=""/>
                                            <p>Centre d'aide</p>
                                        </div>
                                        <div className="d-flex flex-column align-items-center mt-2" id="aide-list-tuto">
                                            {tutoForPage}
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-end flex-column px-3 pb-4">
                                        <div id="result-research-lexique" className="w-100 mb-3">
                                            {
                                                this.state.searchResult.length > 0 && !this.state.loading?
                                                    <div className="results">
                                                        {results}
                                                    </div>
                                                    :
                                                    ''
                                            }
                                            { this.state.loading ?
                                                <div className="results">
                                                    <div className="lds-spinner">
                                                        <div/>
                                                        <div/>
                                                        <div/>
                                                        <div/>
                                                        <div/>
                                                        <div/>
                                                        <div/>
                                                        <div/>
                                                        <div/>
                                                        <div/>
                                                        <div/>
                                                        <div/>
                                                    </div>
                                                </div>
                                                : ''
                                            }
                                        </div>
                                        <div id="input-search-lexique" className="mb-3 d-flex">
                                            <input type="text" placeholder="Rechercher dans le lexique" value={this.state.search}
                                                   onChange={this.handleChange} onKeyDown={this._handleKeyDown}/>
                                            <div id="input-search-icon-wrapper"
                                                 className="cursor d-flex justify-content-center align-items-center"
                                                 onClick={this.search}>
                                                <img src={searchIcon} width='18px' alt=""/>
                                            </div>
                                        </div>
                                        <Link to="/aide" className="text-bold red cursor"
                                              onClick={this.fermerNav} id="plus-aide-link">
                                            Obtenir plus d'aide
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="help-wrapper">
                                <OverlayTrigger
                                placement="top"
                                delay={{show: 250, hide: 400}}
                                overlay={renderTooltip}
                                >
                                <div className="help-icon" onClick={this.ouvrirNav}>
                                <p className="m-0 p-0">?</p>
                                </div>
                                </OverlayTrigger>
                            </div>
                            </React.Fragment>
                        : ''
                }
            </React.Fragment>
        )
    }
}

export default Aide;