import React, {Component} from "react";
import $                  from "jquery";
import axios              from "../../../helpers/axios";
import dlDocProcedure     from "../../../assets/images/dl-documents-procedures.svg";
import redBellIcon        from "../../../assets/images/red-bell-icon.svg";
import infoWhiteIcon      from "../../../assets/images/info-icon-white.svg";
import Tools              from "../../../components/utils/Tools";
import {Link}             from "react-router-dom";
import DateTools          from "../../../components/utils/DateTools";
import {Modal}            from "react-bootstrap";
import HandleDownload     from "../../../components/utils/HandleDownload"

class Renouvellement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emplacementId : this.props.match.params.emplacementId,
            form : [],
            etape : 0,
            emplacement: {} ,
            lastLocation : {},
            renouvellements : [],
            lastRenouvCall : 0,
            lastEtapeCall : 0,
            textEtape0 : 'Vous pouvez ici générer les modèles de courier à envoyer aux familles.',
            textEtape1 : 'Vous enregistrez ici la procédure de renouvellement.',
            newTarif : false,
            show : false,
            showFormTarif : false,
            tarif: '',
            tarifRenouv: '',
            duree: '',
            surface: '',
            parMetreCarre: false
        }
    }

    componentDidMount() {
        Tools.updateNameUploadFile()
        axios.get(process.env.REACT_APP_API_URL + '/emplacements/' + this.state.emplacementId)
            .then ((response) => {
                this.setState({ emplacement: response.data})
                const location = this.state.emplacement.lastLocation
                if(location) {
                    axios.get(process.env.REACT_APP_API_URL + '/locations/' + location.replace(/\D/g, "")).then((response) => {
                        this.setState({lastLocation: response.data})
                        if(!response.data.dateFin){
                            this.setState({
                                textEtape0: 'Renouvellement impossible car aucune date de fin n\'a été enregistrée',
                                textEtape1: 'Renouvellement impossible car aucune date de fin n\'a été enregistrée',
                            })
                            $('.step-btn').remove()
                        } else {
                            if (DateTools.compareDate(response.data.dateFin, 'now', 5, null)) {
                                this.setState({
                                    textEtape0: 'Le renouvellement doit se faire au maximum 5 ans avant la fin de la concession.',
                                    textEtape1: 'Le renouvellement doit se faire au maximum 5 ans avant la fin de la concession.',
                                })
                                $('.step-btn').remove()
                            }
                        }
                    })
                } else {
                    this.setState({
                        textEtape0: 'Renouvellement impossible car aucune concession n\'est en cours',
                        textEtape1: 'Renouvellement impossible car aucune concession n\'est en cours',
                    })
                    $('.step-btn').remove()
                }
                let procedures = this.state.emplacement.procedureRenouvellements
                if (procedures !== undefined && procedures.length > 0) {
                    axios.all(procedures.map(p => axios.get(process.env.REACT_APP_API_URL + '/procedure_renouvellements/' + p.replace(/\D/g, ""))))
                        .then(axios.spread((...res) => {
                            let array = {};
                            for (let key in res){
                                array[res[key].data.id] = res[key].data
                            }
                            this.setState({renouvellements: array})
                            let lastRenouvellement = this.state.emplacement.lastProcedureRenouvellement
                            if (lastRenouvellement != null) {
                                lastRenouvellement = lastRenouvellement.replace(/\D/g, "")
                                this.setState({lastRenouvellement : this.state.renouvellements[lastRenouvellement]})
                                if (this.state.lastRenouvellement.etat <= 1 && (this.state.lastRenouvellement.dateFin === null || this.state.lastRenouvellement.dateFin === undefined )) {
                                    this.setState({etape : this.state.lastRenouvellement.etat + 1})
                                }
                            }
                        }));
                }
                Tools.navigationPopup('Renouvellement', true)
            })
    }

    calculSurface = (surface) => {
        if(2 <= surface && surface < 4) {
            return <option value="1">Simple (entre 2m² et 4m²)</option>
        }
        else if (4 <= surface && surface < 6){
            return <option value="2">Double (entre 4m² et 6m²)</option>
        }
        else if (6 <= surface && surface < 8){
            return <option value="3">Double (entre 6m² et 8m²)</option>
        }
        else if (8 <= surface && surface <= 10){
            return <option value="4">Quadruple (entre 8m² et 10m²)</option>
        }
        else {
            return <option value="5">plus de 10m²</option>
        }
    }

    getForm = (e) => {
        if (this.state.lastRenouvCall !== $(e.target).attr('data-renouv') || this.state.lastEtapeCall !== e.target.id) {
            this.setState({lastRenouvCall : $(e.target).attr('data-renouv'), lastEtapeCall : e.target.id})

            e.stopPropagation();

            let promise = process.env.REACT_APP_API_URL + '/renouvellement/form/' + this.state.emplacementId + '/';
            if ($(e.target).attr('data-renouv') !== undefined) {
                promise += e.target.id + '/' + $(e.target).attr('data-renouv')
            } else {
                promise += e.target.id
            }

            axios.get(promise)
                .then((response) => {
                    this.setState({form: response.data})

                    $('.modal.renouvellement').modal('show');

                    if ($(e.target).attr('data-renouv') === undefined) {
                        this.updateField();
                    }else {
                        $('input, select').prop( "disabled", true )
                        $('[type="radio"].table-checkbox:not(:checked)').parent().remove()
                        $('[type="radio"],[type="checkbox"]').parent().addClass('select-row')
                    }

                    $('[data-toggle="popover"]').popover()

                    const selectDuree = document.querySelector('#renouvellement1_duree')
                    const optionsDuree = document.querySelectorAll('#renouvellement1_duree option')
                    if(selectDuree && optionsDuree.length === 1) {
                        this.changeChoiceDuree(selectDuree)
                        document.getElementById('errorTarif').innerText =
                            "Aucun tarif trouvé pour la date " + DateTools.formatDate(document.getElementById('renouvellement1_debutRenouvellement').value)
                    } else if (selectDuree) {
                        selectDuree.addEventListener('change',  (e) => {
                            if(e.target.value === '0') {
                                this.changeChoiceDuree(selectDuree)
                            }
                        })
                    }

                    const inputTarif = document.getElementById('renouvellement1_modifTarif')
                    if(inputTarif) {
                        inputTarif.addEventListener('change',  (e) => {
                            if(this.state.newTarif && selectDuree.value) {
                                this.setState({
                                    show: true,
                                    tarifRenouv: e.target.value
                                })
                            }
                        })
                    }
                })
        }
    }

    changeChoiceDuree(champs) {
        document.getElementById('renouvellement1_modifTarif').value = ''
        champs.innerHTML = ''
        champs.innerHTML += '<option value=""></option>\n'
        for (let i = 5; i <= 15; i++) {
            champs.innerHTML += '<option value=' + i + '>' + i + '</option>';
        }
        champs.innerHTML += '<option value="30">30</option>\n' +
                            '<option value="50">50</option>\n' +
                            '<option value="99">99</option>\n' +
                            '<option value="100">100</option>\n' +
                            '<option value="perpetuelle">perpétuelle</option>'
        const ajoutTarifButton = document.getElementById('modifierTarif')
        const modifTarifWrapper = document.getElementById('modifTarifDiv')
        if(ajoutTarifButton) {
            ajoutTarifButton.classList.add("display-none")
            modifTarifWrapper.classList.remove("display-none")
            document.querySelector('label[for=renouvellement1_modifTarif]').innerText = "Tarif de la durée choisie applicable au " + DateTools.formatDate(document.getElementById('renouvellement1_debutRenouvellement').value)
        }
        this.setState({newTarif : true})
    }

    updateField = () => {
        if ($('.modal.renouvellement .ligne.s2').hasClass('last')) {
            $('.preview').show()
        }

        $('[type="radio"].table-checkbox').change(function () {
            $('[type="radio"].table-checkbox:not(:checked)').parent().removeClass('select-row');
        })

        if(this.state.lastEtapeCall === '1') {
            this.updateDateFin()

            document.querySelector('[id$=duree]').addEventListener('change',  () => {
                this.updateDateFin()
            })

            document.getElementById('modifierTarif').addEventListener('click',  () => {
                document.getElementById('modifTarifDiv').classList.remove('display-none')
                this.changeTarif()
            })
        }
    }

    changeTarif() {
        const valueDuree = $('[id$=duree] option:selected').text()
        if (valueDuree.includes('€')) {
            let prix = valueDuree.substring(valueDuree.indexOf("(") + 1, valueDuree.lastIndexOf("€"))
            document.querySelector('[id$=modifTarif]').value = parseInt(prix)
        } else {
            document.querySelector('[id$=modifTarif]').value = ''
        }
    }

    updateDateFin() {
        let duree = parseInt(document.querySelector('[id$=duree]').value)
        if(duree) {
            let dateDebut = new Date(document.querySelector('[id$=debutRenouvellement]').value)
            let inputDateFin = document.querySelector('[id$=finRenouvellement]')
            inputDateFin.value = new Date(dateDebut.setFullYear(dateDebut.getFullYear() + duree)).toISOString().substr(0, 10)
            this.changeTarif()
        }
    }

    saveTarif = () => {
        const duree = document.querySelector('#renouvellement1_duree').value
        const data = {
            typeEmplacement: this.state.lastLocation.type,
            tarifFixe: parseFloat(this.state.tarif),
            tarifRenouv: parseFloat(this.state.tarifRenouv),
            duree: parseInt(document.querySelector('[id$=duree]').value),
            dateApplication: this.state.dateApplication,
            perpetuelle: duree === 'perpetuelle',
            tarifDuree: this.state.duree !== 'perpetuelle' && this.state.tarif ? parseFloat(parseFloat(this.state.tarif) / parseFloat(duree)) : null,
            eSpace: process.env.REACT_APP_API_URI + '/e_spaces/' + localStorage.getItem('espace_id'),
            surface: parseInt(this.state.surface),
            parMetreCarre:  this.state.parMetreCarre,
            archive : false
        }
        axios.post(process.env.REACT_APP_API_URL + '/tarifs', data)
        this.setState({showFormTarif : false})
    }

    handleChange = (event)  =>{
        let name = event.target.name
        this.setState({[name] : event.target.value});
    }

    handleChangeCheckbox(e) {
        let isChecked = e.target.checked;
        this.setState({parMetreCarre: isChecked})
    }

    render() {
        require('../../../assets/css/procedures.css')
        require('../../../assets/css/formPop-up.css')

        const etiquettesEnCours = [];
        const etiquettesFinies = [];
        const etiquettesTexte = {
            0: {
                button: 'Générer',
                texte: this.state.textEtape0,
            },
            1 : {
                button: 'Renouveler',
                texte: this.state.textEtape1,
            }
        }

        if (this.state.lastRenouvellement !== undefined) {
            for (const procedure of Object.keys(this.state.renouvellements)) {
                const minirenouvellement = this.state.renouvellements[procedure]
                for (let index = 0; index <= minirenouvellement['etat']; index++) {
                    let documents = [];
                    if (index === 0 && minirenouvellement.hasOwnProperty('courrier')) {
                        documents = [minirenouvellement.courrier]
                    } else if (index !== 0) {
                        if (minirenouvellement.hasOwnProperty('acte')) {
                            documents.push(minirenouvellement.acte)
                        }

                        Object.keys(minirenouvellement.locationDocuments).map((doc) => {
                            documents.push(minirenouvellement.locationDocuments[doc])
                        })
                    }
                    // on verifie si l'étiquette a affiché n'est pas celle en cours et si une autre procedure a été commencé
                    if (minirenouvellement['id'] !== this.state.lastRenouvellement.id || index !== this.state.etape || this.state.lastRenouvellement.etat === 1) {
                        let dataTarget = '#etape' + index;
                        if(minirenouvellement['etapes'][index]) {
                            let dateFin = new Date(minirenouvellement['etapes'][index]['date_fin']);
                            etiquettesFinies.push(
                                <div className="etape bg-white p-3 etape-finie">
                                    <p className="soleilBold-fonts title purple">
                                        {
                                            index === 0 ? 'étape facultative' : 'étape ' + index + '/1 : renouveler'
                                        }
                                    </p>
                                    <p className="soleilRegular-fonts purple etape-description first-letter-uppercase">étape
                                        effectuée
                                        le {dateFin.toLocaleDateString()}</p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a className="dl-doc soleilBold-fonts d-flex cursor" onClick={() => HandleDownload(documents)}>
                                            <img src={dlDocProcedure} className="img-fluid mr-2" width="20px" alt=""/>
                                            <p>Documents</p>
                                        </a>
                                        <button type="button" className="add-doc-btn soleilBold-fonts"
                                                data-toggle="modal" data-target={dataTarget} id={index}
                                                data-renouv={procedure}
                                                onClick={this.getForm}>Détails
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    }
                }
            }
        }

        let i = this.state.etape
        let dataTarget = '#etape' + i
        etiquettesEnCours.push (
            <div className="etape bg-white p-3">
                <p className="soleilBold-fonts title purple etape-title">
                    {
                         i === 0 ? "étape facultative" : 'étape 1/1 : renouveler'
                    }
                </p>
                <p className="soleilRegular-fonts purple etape-description">{etiquettesTexte[i]['texte']}</p>
                <button type="button" className="add-doc-btn soleilBold-fonts step-btn"
                        data-toggle="modal" data-target={dataTarget} id={i} onClick={this.getForm}>{etiquettesTexte[i]['button']}
                </button>
            </div>
        )

        if (i === 0) {
            etiquettesEnCours.push (
                <div className="etape bg-white p-3">
                    <p className="soleilBold-fonts title purple etape-title">
                      etape 1/1 : renouveler
                    </p>
                    <p className="soleilRegular-fonts purple etape-description">{etiquettesTexte[1]['texte']}</p>
                    <button type="button" className="add-doc-btn soleilBold-fonts step-btn"
                            data-toggle="modal" data-target='#etape1' id={1} onClick={this.getForm}>{etiquettesTexte[1]['button']}
                    </button>
                </div>
            )
        }
        return (
            <React.Fragment>
                <div className="contents content-margin">
                    <div className="content-header px-3 px-lg-4 page2">
                        <span className="expand-btn">&#9776;</span>
                        <div className="d-flex align-items-center">
                            <div className="col-12 soleilBold-fonts">
                                <span className="title">RENOUVELLEMENT DE CONCESSION : </span> <span className="text-white">
                                Emplacement {this.state.emplacement.carre} {this.state.emplacement.allee}
                            </span>
                            </div>
                        </div>
                    </div>
                    <div className="px-3 px-lg-4  ">
                        <div className="retour-concession d-flex align-items-center mt-3 ml-4 mb-3">
                            <img src={redBellIcon} className="mr-3" width="40px" alt=""/>
                            <Link to={{pathname: "/fiche-concession/" + this.props.match.params.emplacementId}}
                                  className="soleilBold-fonts purple m-0 pt-4">
                                {
                                    this.state.lastLocation && this.state.lastLocation.champsSuppValeurs ?
                                        'Famille ' + this.state.lastLocation.champsSuppValeurs.famille
                                        : ''
                                } (retour à l'emplacement)
                            </Link>
                        </div>
                        <div className="essential-cemetery-wrapper scroll">
                            <div className="container-fluid px-0 ">
                                <div className="col-lg-12 bg-white py-3">
                                    <div className="info-security-content">
                                        <div className="d-lg-inline-flex align-items-center mb-3">
                                            <img src={infoWhiteIcon} alt=""
                                                 className="img-fluid mr-2" width="25px"/>
                                            <p className="soleilBold-fonts mt-2 ">Rappels</p>
                                        </div>
                                        <p className="soleilBook-fonts">
                                            Attention, le renouvellement doit se faire au maximum 5 ans avant la fin de la
                                            concession ou 2 ans maximum après son expiration.
                                            <br/>
                                            Logicim ne fait pas obstacle à renouveler une concession de 2 ans après sa date
                                            d'échéance pour le bien des familles.
                                        </p>
                                    </div>
                                </div>
                                <div className="etapes-procedures pt-3 d-flex justify-content-center">
                                    <div className="etapes-wrapper">
                                        {etiquettesFinies}
                                        {etiquettesEnCours}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{__html:this.state.form}} className="formulaire" data-submit={process.env.REACT_APP_API_URL + '/renouvellement/form/' + this.state.emplacement.id + '/' + this.state.lastEtapeCall} data-etape={this.state.lastEtapeCall}/>
                </div>
                <Modal show={this.state.show} onHide={() => {this.setState({show : false})}} id="modal-tarif-renouvellement">
                    <Modal.Header className="bk-grey" closeButton>
                        Sauvegarder le tarif
                    </Modal.Header>
                    <Modal.Body>
                        <p className="text-center">
                            Voulez vous sauvegarder ce tarif ?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="red cancel-btn tbl" onClick={() => {this.setState({show : false})}}>
                            Non
                        </button>
                        <button type="button" className="tbl bk-red ml-2" onClick={() => {
                            this.setState({showFormTarif : true, show : false})
                            $(document).off('focusin.modal');
                        }}>
                            Oui
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showFormTarif} onHide={() => {this.setState({showFormTarif : false})}} id="modal-tarifForm-renouvellement">
                    <Modal.Header className="bk-grey" closeButton>
                        Sauvegarder le tarif
                    </Modal.Header>
                    <Modal.Body>
                        <div id="surface-wrapper" className="form-wrapper">
                            <div className="d-flex align-items-center mt-1 d-flex">
                                <label className="col-4 soleilRegular-fonts p-0 m-0">Choisir la surface</label>
                                <div className="d-flex col-8 pl-0 pr-0">
                                    <div className="p-0 select-grey-wrapper col-5 ">
                                        <select className="soleilRegular-fonts select-grey"
                                                name="surface"
                                                onChange={this.handleChange}>
                                            <option value=""></option>
                                            {this.calculSurface(this.state.emplacement.surface)}
                                        </select>
                                    </div>
                                    <p className="col-2">Ou</p>
                                    <div className="col-5 d-flex align-items-center">
                                        <label className="soleilRegular-fonts m-0 mr-2 p-0">Par m²</label>
                                        <input type="checkbox"
                                               className="soleilRegular-fonts"
                                               name="parMetreCarre"
                                               checked={this.state.parMetreCarre}
                                               onChange={ e => this.handleChangeCheckbox(e)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mt-4 align-items-center">
                                <label className="col-4 soleilRegular-fonts p-0 m-0">Tarif d'attribution TTC (€)</label>
                                <input type="number"
                                       name="tarif"
                                       className="col-8 soleilRegular-fonts"
                                       placeholder="Tarif d'attribution de l'emplacement prestations incluses"
                                       value={this.state.tarif}
                                       onChange={this.handleChange}/>
                            </div>
                            <div className="d-flex mt-2 align-items-center">
                                <label className="col-4 soleilRegular-fonts p-0 m-0">Tarif du renouvellement TTC (€)</label>
                                <input type="number"
                                       name="tarifRenouv"
                                       className="col-8 soleilRegular-fonts"
                                       placeholder="Tarif de renouvellement prestations incluses"
                                       value={this.state.tarifRenouv}
                                       onChange={this.handleChange}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts p-0 m-0">Date de
                                    l'application</label>
                                <input type="date"
                                       className="col-8 soleilRegular-fonts"
                                       name="dateApplication"
                                       value={this.state.dateApplication}
                                       onChange={this.handleChange}/>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="red cancel-btn tbl" onClick={() => {this.setState({showFormTarif : false})}}>
                            Annuler
                        </button>
                        <button type="button" className="tbl bk-red ml-2" onClick={() => {this.saveTarif()}}>
                            Sauvegarder
                        </button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }

}

export default Renouvellement;