import React, {Component} from 'react';
import './styles.scss';
import axios from "../../helpers/axios";

class LostPassword  extends Component {

    state = {
        email: '',
        sended: false
    }
    componentDidMount() {
        document.getElementById("root").className = document.getElementById("root").className + " login-page"
        if (document.getElementById("sidePanelAide"))
        document.getElementById("sidePanelAide").style.width = "0"
    }
    onChangeInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    submit = (e) => {
        e.preventDefault()
        axios.post(process.env.REACT_APP_API_URL + '/reset-password', {
            email: this.state.email
        }).then((res) => {
            this.setState({
                sended: true
            })
            console.log(res)
        })
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row mt-5 mt-md-0">
                    <div className="col-md-3 mx-auto">

                        <h1 className="text-center mb-5 text-primary">Mot de passe oublié ?
                        </h1>
                        { !this.state.sended  ?
                            <form onSubmit={this.submit}>
                                <div className="form-group">
                                    <label htmlFor="inputUsername" className="sr-only">Saisir votre email</label>
                                    <input name="email" type="email" id="inputUsername" onChange={this.onChangeInput}
                                           className="form-control" placeholder="Email" />
                                </div>


                                <button className="btn btn-outline-primary btn-block btn-primary">Envoyer </button>
                            </form>
                        :
                            <p style={{    'text-align': 'center',
                                'color': 'green'}}>Veuillez vérifier votre boîte mail et suivre les consignes.</p>
                        }

                    </div>
                </div>
            </div>
        )
    }
}

export default LostPassword;
