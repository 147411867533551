import { SET_USER, API, FETCH_USER} from "./types";
import user from "../helpers/user"

function login(data) {
    return apiAction({
        url: "/login_check",
        data: data,
        method: 'POST',
        onSuccess: setUserDetails,
        onFailure: () => {
            return {
                type: "WRONG_CREDENTIAL",
                message: 'WrongCredential'
            }
        },
        label: FETCH_USER
    });
}

function setUserDetails(data) {
    const now = new Date()
    const ttl = 6 * 60 * 60;

    localStorage.setItem(user.USER_LOCAL_KEY, JSON.stringify({
        token: data.token,
        user: data.data.username,
        roles: data.data.roles,
        refreshToken: data.refresh_token,
        identite: data.data.identite,
        email: data.data.email,
        expiry: now.getTime() + ttl
    }))

    return {
        type: SET_USER,
        payload: data
    };
}

function fetchApi (config = {method: 'GET', url: '', data: {}, onSuccess: {}, onFailure: {}, label: '', type: 'EMPLACEMENT', noPagination: false, links: []}) {
    function onSuccess (data,type) {
        const out = setApi(data, type, config.links)
        if (config.onSuccess) {
            config.onSuccess(data, type)
        }
        return out
    }

    return apiAction({
        url: config.url,
        data: config.data,
        method: config.method,
        onSuccess: onSuccess,
        onFailure: config.onFailure,
        label: config.label,
        type: config.type,
        noPagination: config.noPagination,
        links: config.links
    });
}

function setApi (data, type, links) {
    return {
        type: "SET_" + type,
        payload: data,
        links: links
    }
}

function apiAction({
                       url = "",
                       method = "GET",
                       data = null,
                       accessToken = null,
                       onSuccess = () => {},
                       onFailure = () => {},
                       label = "",
                       headersOverride = {
                           "Access-Control-Allow-Origin" : "*",
                       },
                       type = "",
                       noPagination = false
                   }) {
    return {
        type: API,
        payload: {
            url,
            method,
            data,
            accessToken,
            onSuccess,
            onFailure,
            label,
            headersOverride,
            type,
            noPagination
        }
    };
}

export {login, fetchApi }