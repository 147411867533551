import axios from "axios";
import {
    EMPLACEMENT_ERROR,
    SET_CHOOSE_EMPLACEMENT,
    GET_EMPLACEMENTS,
} from "./types";

export const getEmplacements = async (dispatch) => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/locations');
        return dispatch({
            type: GET_EMPLACEMENTS,
            payload: response.data['hydra:member'],
        });
    } catch (err) {
        return dispatch({
            type: EMPLACEMENT_ERROR,
            payload: {
                msg: err.statusText,
                status: err.status,
            },
        });
    }
};

export const getEmplacement = async (id, dispatch) => {
    try {
        const espaceId = localStorage.getItem('espace_id')
        const response = await axios.get(process.env.REACT_APP_API_URL + '/emplacements/emplacementToutInfos/' + id + '?espace=' + espaceId);

        return dispatch({
            type: SET_CHOOSE_EMPLACEMENT,
            payload: response.data,
        });
    } catch (err) {
        return dispatch({
            type: EMPLACEMENT_ERROR,
            payload: {
                msg: err.statusText,
                status: err.status,
            },
        });
    }
};


