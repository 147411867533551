import React, { Component } from 'react';
import editerIcon from '../../assets/images/edit-icon-purple.svg';
import btnPlusIcon from '../../assets/images/btn-plus-icon.svg';
import pdfIconTbl from '../../assets/images/pdf-icon-tbl.svg';
import filterIconSubmenu from '../../assets/images/filter-icon-submenu.svg';

class EditeurList extends Component {

    constructor(props) {
        super(props);
        this.state = {selected:0}
    }

    componentDidMount() {
       window.tablesort()
    }

    handleCheck = (e) => {
        let parent = e.target.parentElement.parentElement

        if(e.target.checked === true) {
            parent.style.background = "rgba(232, 232, 232, 0.4)";
            parent.style.border = "1px solid #6B00B2";
            this.setState({selected: this.state.selected + 1})
        }else {
            parent.style.background = "transparent";
            parent.style.border = "none";
            this.setState({selected: this.state.selected - 1})
        }

    }

    render() {
        return(
            <div>
                <div className="mx-3">
                    <div className="table-responsive px-3 scroll listes-leftpanel">
                        <table className="table table-striped table-borderless">
                            <thead>
                            <tr>
                                <th className="sortStyle">Titre</th>
                                <th className="sortStyle">Description</th>
                                <th className="sortStyle">Catégorie &nbsp; &nbsp;</th>
                                <th className="sortStyle">Durée</th>
                                <th className="sortStyle">Fin</th>
                                <th className="sortStyle">État</th>
                                <th className="sortStyle">Capacité</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="checkbox" className="table-checkbox" onChange={this.handleCheck}/>
                                            Principal C1
                                    </td>
                                    <td>
                                        <span className="family_no">165</span>
                                        Legrand
                                    </td>
                                    <td className="black">Familiale</td>
                                    <td className="black">50 ans</td>
                                    <td className="green">26/03/2068</td>
                                    <td>
                                        <span className="tbl bk-green">EN COURS</span>
                                    </td>
                                    <td>2/3</td>
                                    <td>
                                        <a href="" className="edit-tbl">
                                            <img src={editerIcon} alt="" className="img-fluid"/>
                                            éditer
                                        </a>
                                    </td>
                                    <td>
                                        <a href="" className="exporter-tbl">
                                            <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                            exporter
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" className="table-checkbox" onChange={this.handleCheck}/>
                                            Colombarium 12
                                    </td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>
                                        <span className="tbl bk-grey">NON DÉFINI</span>
                                    </td>
                                    <td>-</td>
                                    <td>
                                        <a href="" className="edit-tbl">
                                            <img src={editerIcon} alt="" className="img-fluid"/>
                                            éditer
                                        </a>
                                    </td>
                                    <td>
                                        <a href="" className="exporter-tbl">
                                            <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                            exporter
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" className="table-checkbox" onChange={this.handleCheck}/>
                                            Principal H8
                                    </td>
                                    <td>
                                        <span className="family_no">239</span>
                                        Bernard
                                    </td>
                                    <td className="black">Familiale</td>
                                    <td className="black">99 ans</td>
                                    <td className="orange">26/03/2012</td>
                                    <td>
                                        <span className="tbl bk-orange txt-short">ABANDON EN COURS</span>
                                    </td>
                                    <td>0/2</td>
                                    <td>
                                        <a href="" className="edit-tbl">
                                            <img src={editerIcon} alt="" className="img-fluid"/>
                                            éditer
                                        </a>
                                    </td>
                                    <td>
                                        <a href="" className="exporter-tbl">
                                            <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                            exporter
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" className="table-checkbox" onChange={this.handleCheck}/>
                                            Principal I2
                                    </td>
                                    <td>
                                        <span className="family_no">235</span>
                                        Padrois
                                    </td>
                                    <td className="black">Familiale</td>
                                    <td className="black">50 ans</td>
                                    <td className="red">17/05/2008</td>
                                    <td>
                                        <span className="tbl bk-red pdg ">ÉCHUE</span>
                                    </td>
                                    <td>0/2</td>
                                    <td>
                                        <a href="" className="edit-tbl">
                                            <img src={editerIcon} alt="" className="img-fluid"/>
                                            éditer
                                        </a>
                                    </td>
                                    <td>
                                        <a href="" className="exporter-tbl">
                                            <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                            exporter
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" className="table-checkbox"  onChange={this.handleCheck}/>
                                            Principal A2
                                    </td>
                                    <td>
                                        <span className="family_no">22</span>
                                        Petit
                                    </td>
                                    <td className="black">Familiale</td>
                                    <td className="black">50 ans</td>
                                    <td className="green">08/11/2031</td>
                                    <td>
                                        <span className="tbl bk-green">EN COURS</span>
                                    </td>
                                    <td>
                                        2/2
                                        <span className="listing-incomplete-btn soleilBold-fonts">Pleine</span>
                                    </td>
                                    <td>
                                        <a href="" className="edit-tbl">
                                            <img src={editerIcon} alt="" className="img-fluid"/>
                                            éditer
                                        </a>
                                    </td>
                                    <td>
                                        <a href="" className="exporter-tbl">
                                            <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                            exporter
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="container-fluid py-3 px-lg-5">
                    <div className="row" >
                        <div className="col-sm-3 text-center text-sm-left">
                            <button type="button" className="add-new-location-btn">
                                Ajouter nouveau
                                <img src={btnPlusIcon} alt="+" className="img-fluid ml-2"/>
                            </button>
                        </div>
                        <div
                            className="col-sm-9 d-flex justify-content-center justify-content-sm-end align-items-center mt-4 mt-sm-0">
                            <button type="button" className="export-locations-list-btn d-flex">
                                <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                <span className="soleilBold-fonts">EXPORTER LISTE <br/> CONCESSIONS</span>
                            </button>
                            <button type="button" className="export-locations-list-btn d-flex">
                                <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                <span className="soleilBold-fonts">EXPORTER LISTE <br/> CONCESSIONS</span>
                            </button>
                            <button type="button" className="listing-complete-btn soleilBold-fonts">Sélection <span
                                className="slt-nb">{ this.state.selected }</span></button>
                            <button type="button" className="listing-complete-btn soleilBold-fonts">Liste filtrée ( 0
                                )
                            </button>
                            <button type="button" className="listing-incomplete-btn soleilBold-fonts">Liste complète (
                                389 )
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditeurList;