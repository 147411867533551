import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import axios from "axios";

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('root')
);

/**
 * Catch the AunAuthorized Request
 */
//axios.interceptors.response.use((response) => {
   // return response
//}, error => {
  //  if (error.response.status === 401) {
    //    window.location = '/login'
    //}
    //return Promise.reject(error)
//})

window.onunhandledrejection = function(e, error) {
    if (e.hasOwnProperty('reason') && e.reason.response.status && e.reason.response.status === 401) {
        console.log(e)
        //window.location = '/login'
    }
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
