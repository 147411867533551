import React, {useState} from 'react'
import { ShepherdTour,  TourMethods } from 'react-shepherd'
import '../../assets/css/shepherd.css';
import {Modal} from "react-bootstrap";
import $ from 'jquery';
import axios from "axios";

const AssistanceTour = (props) => {

    const [start, setStart ] =  useState (false)
    const [done, setDone ] =  useState (false)
    const [confirmCancel, setConfirmCancel] = useState(false)
    const [step, setStep] = useState(0)

    const steps = [
        {
            id: 'aide-steps-1',
            attachTo: { element: '.help-icon', on: 'top' },
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Arrêter',
                    type: 'cancel'
                },
            ],
            when: {
                show() {
                    if(!this.tour.options.useModalOverlay)
                        this.tour.options.useModalOverlay = true
                    $('.help-icon').off('click').on('click', () => {
                        this.tour.next()
                    })
                },
                hide () {
                    $('.help-icon').off('click')
                }
            },
            classes: 'custom-class-name-1 custom-class-name-2',
            text: `<p>Bienvenue dans <span class="purple">Logicim</span> ! Ceci est l'<span class="purple">assistance</span>. Elle va vous permettre de découvrir le logiciel et d'être accompagné pendant son utilisation. Cliquez sur <span class="mini-aide"></span> pour commencer le tutoriel.</p>`,
        },
        {
            id: 'aide-steps-2',
            attachTo: { element: '#sidePanelAide', on: 'left' },
            buttons: [
                {
                    classes: 'shepherd-button-secondary large-margin-button',
                    text: 'Arrêter',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Suivant',
                    type: 'next'
                }
            ],
            beforeShowPromise: function() {
                if(document.getElementById('sidePanelAide').offsetWidth === 0) {
                    return new Promise(function (resolve) {
                        isVisible()
                        function isVisible() {
                            const widthDiv = document.getElementById('sidePanelAide').offsetWidth
                            if (widthDiv > 0) {
                                resolve()
                            } else {
                                setTimeout(isVisible, 350)
                            }
                        }
                    });
                }
            },
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            text: `<p>Cette fenêtre est l'<span class="purple">assistance</span> de Logicim. Elle est accessible depuis toutes les pages pour vous aider.</p>`,
        },
        {
            id: 'aide-steps-3',
            attachTo: { element: '#aide-list-tuto', on: 'left' },
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Arrêter',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Précédent',
                    type: 'back'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Suivant',
                    type: 'next'
                }
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            text: `<p>Vous y retrouvez les tutoriels disponibles sur la page que vous consultez actuellement.</p>`,
        },
        {
            id: 'aide-steps-4',
            attachTo: { element: '#input-search-lexique', on: 'top' },
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Arrêter',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Précédent',
                    type: 'back'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Suivant',
                    type: 'next'
                }
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            text: `<p>Vous pouvez effectuer une recherche rapide dans le lexique grâce cette barre de recherche.</p>`,
        },
        {
            id: 'aide-steps-5',
            attachTo: { element: '#plus-aide-link', on: 'top' },
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Arrêter',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Précédent',
                    type: 'back'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Suivant',
                    type: 'next'
                }
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            text: `<p>Le lien <span class="purple">"Obtenir plus d'aide"</span> vous permet d'accéder à tous les tutoriels de Logicim et à notre <span class="purple">centre d'aide</span>.</p>`,
        },
        {
            id: 'aide-steps-6',
            buttons: [
                {
                    classes: 'shepherd-button-secondary mr-4',
                    text: 'Arrêter',
                    type: 'cancel',
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Précédent',
                    type: 'back'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Terminer',
                    type: 'complete'
                }
            ],
            classes: 'custom-class-name-1 custom-class-name-2',
            highlightClass: 'highlight',
            text: `<p>Vous pouvez maintenant découvrir <span class="purple">Logicim</span> ! N'hésitez à commencer par le tutoriel <span class="purple">"Présentation générale"</span> de la fenêtre d'assistance.</p>`,
        },
    ]

    const tourOptions = {
        defaultStepOptions: {
            cancelIcon: {
                enabled: true
            },
            scrollTo : true,
            classes: 'shepherd-theme-custom',
            title: 'Bienvenue dans Logicim',
            confirmCancel: true,
            confirmCancelMessage: 'fegegeg',
            when: {
                show() {
                    setStep(this.tour.steps.indexOf(this.tour.currentStep))
                    const currentStepElement = this.el;
                    const header = currentStepElement.querySelector('.shepherd-header');
                    const progress = document.createElement('span');
                    progress.style['margin-right'] = '15px';
                    progress.innerText = `ETAPE ${this.tour.steps.indexOf(this.tour.currentStep)}/${this.tour.steps.length - 1}`;
                    header.insertBefore(progress, currentStepElement.querySelector('.shepherd-cancel-icon'));
                },
            }
        },
        useModalOverlay: false,
        popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }]
        },
    }

    const handleClose = () => {
        setConfirmCancel(false)
        axios.put(process.env.REACT_APP_API_URL + '/users/' + props.userId, {
            firstConnection : true
        })
        setDone(true)
        setStart(false)
    }

    const retourAuTutoriel = () => {
        setConfirmCancel(false)
        setStart(false)
    }

    const stop = () => {
        $('.help-icon').off('click')
        setConfirmCancel(true)
    }

    return (
        <div>
            <ShepherdTour steps={steps} tourOptions={tourOptions}>
                <TourMethods>
                    {tourContext => {
                        if (document.querySelector('.help-icon') && !start && !done && props.firstConnection === false) {
                            setStart(true)
                            tourContext.on("cancel", stop)
                            if(step > 0) {
                                for(let i = 0; i <= step; i++) {
                                    tourContext.next()
                                }
                            } else {
                                tourContext.start()
                            }
                            tourContext.on("complete", handleClose)
                        }
                    }}
                </TourMethods>
            </ShepherdTour>
            <Modal show={confirmCancel} backdrop="static" keyboard={false} id="confirm-cancel">
                <Modal.Header>
                    <p className="modal-title">Quitter le tutoriel</p>
                </Modal.Header>
                <Modal.Body>
                    <p>Êtes-vous sûr de vouloir quitter ce <span className="purple">tutoriel d'accueil</span> sur Logicim ?</p>
                    <p>Tous nos autres tutoriels sont disponibles dans la fenêtre d'assistance en cliquant sur <span className="mini-aide"/> ou via le centre d'aide.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="shepherd-button-secondary shepherd-button" onClick={handleClose}>
                        Quitter
                    </button>
                    <button className="shepherd-button-primary shepherd-button" onClick={retourAuTutoriel}>
                        Revenir au tutoriel
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AssistanceTour;