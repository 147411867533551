import axios from "axios";
import user from "./user";

let currentUser = user.getUser()
if (currentUser) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${currentUser.token}`;
  if(localStorage.getItem('impersonate')) {
    axios.defaults.headers.common["x-switch-user"] = localStorage.getItem('impersonate');
  }
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL || "";
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.defaults.withCredentials = true;

export default axios;

