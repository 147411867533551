import React, { Component } from 'react';
import $ from "jquery";
import axios from "../../helpers/axios";
import Notifications from "./Notifications";

class Tools extends Component {

    static navigationPopup(procedure = null, lastEtape = null) {
        window.autocompletetimer = false;
        let etape_modal      = 1
        let defuntFormId     = '0'
        let travauxFormId     = '0'
        // previewIsVisible pour verifier si on est à l'étape de la preview : éviter erreur affichage si double click sur le bouton preview
        let previewIsVisible = false
        let etape_numero

        $(".step-btn").click(function(e) {
            startNavigation(e)
        })

        $(document).off("click", ".step-btn").on("click",".step-btn", function(e) {
            startNavigation(e)
        });

        function startNavigation(e) {
            if(etape_numero !== e.target.id && e.target.id !== 'none' ) {
                if(e.target.classList.contains('defunt-form')) {
                    defuntFormId = e.target.getAttribute('data-defunt-id')
                }
                etape_modal = 1
                emptyForm();
            } else if (e.target.classList.contains('defunt-form') && e.target.getAttribute('data-defunt-id') !== defuntFormId) {
                defuntFormId = e.target.getAttribute('data-defunt-id')
                emptyForm()
                etape_modal = 1
            } else if (e.target.classList.contains('travaux-form') && e.target.getAttribute('data-travaux-id') !== travauxFormId) {
                travauxFormId = e.target.getAttribute('data-travaux-id')
                emptyForm()
                etape_modal = 1
            }
            etape_numero = e.target.id
            if($('.ligne.s2').hasClass('last')){
                $('.preview').show()
            }
        }

        $(document).off("change", ".checked-row").on("change",".checked-row", function () {
            if ($(this).is(":checked")) {
                $(this).parent().addClass('select-row')
            } else {
                $(this).parent().removeClass('select-row')
            }
        });

        $(document).off('click', '.suivant').on("click",'.suivant', function () {
            $('#error').text('')
            nextStep()
        })

        // passage à l'étape suivante
        function nextStep() {
            let modal = $('#etape' + etape_numero);
            let $form = $('#etape' + etape_numero + ' form');
            document.querySelectorAll('.scroll-popup').forEach((element) => element.scrollTop = 0 )

            // on test les champs obligatoire
            let errorRequired = $form.find( '[data-require]:visible').filter(function () {
                return $(this).val() === ""
            });
            // s'il y a des champs obligatoire pas rempli on ajoute la classe pour les surligner en rouge
            // sinon on cache l'étape actuel et affiche la prochaine
            if (errorRequired.length>0) {
                errorRequired.addClass('empty-required')
            } else {
                $('.empty-required').removeClass('empty-required')
                modal.find('.etape' + etape_modal).hide()
                etape_modal += 1;
                console.log('#etape' + etape_numero + ' .ligne.s' + etape_modal)
                let div = document.querySelector('#etape' + etape_numero + ' .ligne.s' + etape_modal)
                if (div) {
                    div.classList.add('fait')

                    if (etape_modal === 2) {
                        $('.precedent').show()
                    }

                    if (div.classList.contains('last')) {
                        modal.find('.verification').show()
                        modal.find('.valider').show()
                        modal.find('.preview').hide()
                    } else {
                        modal.find('.etape' + etape_modal).show()
                        let nextStepNumber = etape_modal + 1
                        div = document.querySelector('#etape' + etape_numero + ' .ligne.s' + nextStepNumber)
                        if (div !== null && div.classList.contains('last')) {
                            modal.find('.preview').show()
                            modal.find('.suivant').hide()
                        }
                    }
                } else {
                    etape_modal -= 1;
                }
            }
        }

        $(document).off('submit', 'form').on('submit', 'form', function (event) {
            event.preventDefault()
        });

        $(document).off('click', ".preview").on("click",".preview", function(){
            let form;
            if ($(this).hasClass('procedure')) {
                form = $('.formulaire');
            } else {
                form = $('#etape' + etape_numero + ' .form-wrapper .formulaire');
            }
            let errorRequired = form.find( '[data-require]:visible').filter(function () {
                return $(this).val() === ""
            });
            if (errorRequired.length>0 || verifUploadDocument()) {
                if (verifUploadDocument()) {
                    $('#error').text('Veuillez ajouter le(s) fichier(s) demandé(s).')
                }
                errorRequired.addClass('empty-required')
            } else {
                if (!previewIsVisible) {
                    previewIsVisible = true;
                    axios.post(form.attr('data-submit'), form.find('form').serialize()).then(function (response) {
                        if ($(this).hasClass('procedure')) {
                            form.append(response.data)
                        } else {
                            form.find('form').not('.not-for-add').append(response.data)
                        }
                        $('#error').text('')
                        nextStep()
                        previewIsVisible = false
                    });
                }
            }
        });

        function emptyForm() {
            previewIsVisible = false
            $('.ligne').removeClass('fait')
            $('.jalon.s1').addClass('fait')
            $('.modal-step-content').hide()
            $('.etape1').show()
            $('.btn-navigation').hide()
            $('.suivant').show()
            $('.empty-required').removeClass('empty-required')
            $('#error').text('')
        }

        function verifUploadDocument() {
            const etapeProcedure = $('#etape' + etape_numero  + ' .etape' + etape_modal);
            let errorRequired = etapeProcedure.find('.upload-wrapper:visible').find( '.required-file input[type=file]:not([data-required-pass])').filter(function () {
                return $(this).val() === ""
            });
            return errorRequired.length > 0;
        }

        $(document).off('click', '.valider.defunt').on("click",'.valider.defunt', function () {
            if (defuntFormId === '0') {
                defuntFormId = 'update'
            }
        })

        $(document).off('click', '.valider.procedure').on("click",'.valider.procedure', function () {
            let data = [];
            const form = $('.formulaire');
            const checkboxValidation = document.querySelector('#etape' + etape_numero + ' .valid_input');
            if (checkboxValidation !== null) {
                if (checkboxValidation.checked === true) {
                    $('.modal').modal('hide')
                    sendForm(form,data);
                } else {
                    let div = document.querySelector('#etape' + etape_numero + ' .valid_form')
                    div.classList.add('c-error')
                }
            }else {
                $('.modal').modal('hide')
                sendForm(form,data)
            }
        })

        function uploadFile(e, file, mapping, setProgress) {
            let formData = new FormData()
            formData.append("file", file)
            axios.post("/documents/upload/" + mapping, formData, {
                maxContentLength: 100000000,
                maxBodyLength: 1000000000,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            }).then((res) => {
                $(e.target).closest('.upload-wrapper').find('select').empty().append('<option value="' + res.data.id + '">' + res.data.id + '</option>')
            })
        }

       $(document).on('change', '.dl-actes' , function (e) {
                uploadFile(e, e.target.files[0], $(e.target).closest('.dl-actes').attr('data-mapping'), function (progress) {
                    e.target.setAttribute('progress', progress)
                })
       })

        function sendForm(form, data) {
            let postDatas = new FormData();
            form.find('input[type=text], input[type=number], input[type=radio]:checked, input[type=checkbox], input[type=date], input[type=time], input[type=email], textarea').each(function () {
                postDatas.append($(this).attr('name'),$(this).val())
            })
            form.find('select').each(function () {
                let value = $(this).val();
                if ($(this).hasClass('bool')) {
                   value = value === "1"
                }
                postDatas.append($(this).attr('name'),value)
            })
            if (data) {
                data.forEach(function (doc) {
                    postDatas.append(doc[0], doc[1])
                })
            }
            postDatas.append("valider",1);
            if (
                (procedure && lastEtape && procedure !== 'Renouvellement')
                ||
                (procedure && procedure === 'Renouvellement' && form.attr('data-etape') && form.attr('data-etape') === '1')
            )
            {
                Notifications.checkFirstProcedure(procedure)
            }
            axios.post(form.data('submit'), postDatas).then(() =>{
                window.location.reload();
            })
            etape_modal = 1
            emptyForm()
        }

        $(document).off('click', '.precedent').on("click",'.precedent',function () {
            let modal = $('#etape' + etape_numero );
            let div = document.querySelector('#etape' + etape_numero + ' .ligne.s' + etape_modal);
            modal.find('.etape' + etape_modal).hide();
            div.classList.remove('fait');

            if(div && div.classList.contains('last')){
                previewIsVisible = false
                modal.find('.verification').remove()
                modal.find('.valider').hide()
                modal.find('.preview').show()
            }else {
                let nextStepNumber = etape_modal + 1
                div = document.querySelector('#etape' + etape_numero + ' .ligne.s' + nextStepNumber)
                if (div && div.classList.contains('last')){
                    modal.find('.preview').hide()
                    modal.find('.suivant').show()
                }
            }

            etape_modal -= 1;
            if(etape_modal===1){
                $('.precedent').hide()
            }

            modal.find('.etape' + etape_modal).show()
        });

        autocompleteContact();

        function autocompleteContact() {
            $('body').on('keyup', '.autocomplete input, .autocomplete', function() {
                if ($(this).next().is('select')) {
                    $(this).next().remove();
                }
                if ($(this).val().length < 4) {
                    return;
                }

                clearTimeout(window.autocompletetimer)
                window.autocompletetimer = setTimeout(() => {
                    $('.autocomplete-liste').remove()


                   axios.get(process.env.REACT_APP_API_URL + '/autocomplete/' + $(this).attr('data-type'), {
                       params: {
                           eSpace : localStorage.getItem('espace_id'),
                           query: $(this).val()
                       }
                   }).then((result) => {
                       const data = result.data;
                       if (data.length > 0) {
                           const $select = $('<ul class="autocomplete-liste"></ul>');
                           $(this).parent().css('position', 'relative')
                           $(this).after($select)
                           for (let item in data) {
                               if($(this).attr('data-type') === 'contact') {
                                   $select.append('<li class="autocomplete-choice cursor soleilRegular-fonts" value="' + data[item].id + '">' +
                                       '<p>' + (data[item].nom ? data[item].nom : '') + ' ' + (data[item].prenom ? data[item].prenom : '') + '</p>' +
                                       '<span>' + (data[item].dateDeNaissance ? ('0' + new Date(data[item].dateDeNaissance).getDate()).slice(-2) + '/'
                                           + ('0' + (new Date(data[item].dateDeNaissance).getMonth() + 1)).slice(-2) + '/'
                                           + new Date(data[item].dateDeNaissance).getFullYear() : 'Naissance inconnue') +
                                       '</span>' +
                                       '</li>')
                               } else {
                                   $select.append('<li class="autocomplete-choice cursor soleilRegular-fonts" value="' + data[item].id + '">' +
                                       '<p class="purple">' + data[item].nomSociete + '</p>' +
                                       '<span class="black">' + (data[item].adresse ? data[item].adresse : 'pas d\'adresse connue') + '</span><br>' +
                                       '<span>' + (data[item].email ?  data[item].email : 'pas d\'email connu') + '</span>' +
                                       '</li>')
                               }
                           }
                       }
                   })

                    $(document).on('click', 'body', function (e) {
                        if (!$(e.target).attr('class') !== undefined &&
                            !$(e.target).hasClass('autocomplete-choice') &&
                            !$(e.target).hasClass('autocomplete-liste') &&
                            !$(e.target).parent().hasClass('autocomplete-choice')
                        ) {
                           $('.autocomplete-liste').remove()
                        }
                    })
                    $(document).on('click', '.autocomplete-choice:visible', (val) => {
                        //currentTarget return toujours le parent, target return l'enfant clique
                        if($(this).closest('.form-contact').is(":visible")) {
                            $(this).closest('.form-contact').find(".autocomplete-cancel").remove()
                            $(this).closest('.form-contact').find('.autocomplete').parent().after('<div class="py-2"><a class="autocomplete-cancel cursor red">annuler et ajouter un nouveau contact</a></div>')
                        }
                        axios.get(process.env.REACT_APP_API_URL + '/contacts/' + $(val.currentTarget).val()).then((result) => {
                            const data = result.data;
                            $('.autocomplete-liste').remove()
                            for (let item in data) {
                                if(item === 'typeDeContact' && ($(this).closest('.form-contact').find('[id$="typeDeContact"]').hasClass('disable-field'))){

                                } else if ($(this).closest('.form-contact').is(":visible")) {
                                    if (item === 'dateDeNaissance') {
                                        let date = new Date(data[item])
                                        $(this).closest('.form-contact').find('[id$="' + item + '"]').val(date.toJSON().split('T')[0])
                                    } else if (item === 'civilite') {
                                        if (data[item] === 'H' || data[item] === 'Homme') {
                                            $(this).closest('.form-contact').find('[id$="' + item + '_0"]').attr('checked', 'checked')
                                        } else {
                                            $(this).closest('.form-contact').find('[id$="' + item + '_1"]').attr('checked', 'checked')

                                        }
                                    } else {
                                        $(this).closest('.form-contact').find('[id$="' + item + '"]').val(data[item])
                                    }
                                }
                            }
                            $(this).closest('.form-contact').find('input').addClass('disable-field')
                        });
                    })

                    $(document).on('click', '.autocomplete-cancel', function (){
                        $(this).closest('.form-contact').find('[id$="id"]').val('')
                        $(this).closest('.form-contact').find('input').removeClass('disable-field')
                        $(this).parent().remove()
                    })
                }, 500)
            })
        }
    }

    static switchColorCheckbox(){
        $(document).on('change','input[type=checkbox]', function () {
            const label = $(this).closest('.switch-color').find('.slider-label');
            if ($(this).prop('checked') === true) {
                label.css('color', '#6B00B2')
            }else {
                label.css('color','#D3B2E9')
            }
        })
    }

    static updateNameUploadFile() {
        $(document).on('change', '.dl-actes input', function () {
            let fileName = this.files[0].name;
            let inputFileDiv =  $(this).parent().parent().parent().parent().parent();
            if (fileName !== '') {
                inputFileDiv.find('.select-pdf-file-name').text(fileName)
                inputFileDiv.find('input[id$=nom]').val(fileName)
            } else {
                inputFileDiv.find('.select-pdf-file-name').text('Aucun fichier selectionné')
            }

        })
    }

    static displaySpecificStep = (form, etape) => {
        $('.ligne').removeClass('fait')
        $('.modal-step-content').hide()
        for(let i = 0; i <= etape; i++) {
            let div = document.querySelector('#' + form + ' .ligne.s' + i)
            div && div.classList.add('fait')
        }
        const etapeDiv = document.querySelector('#' + form + ' .etape' + etape)
        console.log(etapeDiv)
        if (etapeDiv) {
            etapeDiv.style.display = 'block'
            const boutonSuivant   =  document.querySelector('#' + form + ' .suivant')
            const boutonPrecedent = document.querySelector('#' + form + ' .precedent')
            const boutonValider   = document.querySelector('#' + form + ' .valider')
            if (boutonValider && boutonPrecedent && boutonSuivant) {
                boutonValider.style.display = ''
                boutonPrecedent.style.display = 'none'
                boutonValider.classList.remove('display-none')
                boutonSuivant.style.display = 'none'
            }
        }
    }

    render() {
        return (
            <div/>
        )
    }
}

export default Tools;