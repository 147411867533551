import React, {Component} from "react";
import axios from "../../../helpers/axios";
import redBellIcon from "../../../assets/images/red-bell-icon.svg";
import infoWhiteIcon from "../../../assets/images/info-icon-white.svg";
import dlDocProcedure from "../../../assets/images/dl-documents-procedures.svg";
import {Link} from "react-router-dom";
import $ from "jquery";
import Tools from "../../../components/utils/Tools";
import EtapeValideTexte from "./EtapeValideTexte";
import DateTools from "../../../components/utils/DateTools";
import EtapeEnCoursText from "./EtapeEnCoursText";
import HandleDownload from "../../../components/utils/HandleDownload";
import dayjs from "dayjs";

class RepriseAbandon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emplacementId: this.props.match.params.emplacementId,
            form: [],
            etape: 1,
            etapes : [],
            emplacement: {},
            lastLocation: {},
            reprises: [],
            lastRepriseCall: 0,
            lastEtapeCall: 0,
            lastReprise : null
        }
    }

    //on verifie si le contrat de concession a plus de 30 ans
    verifDateContrat = (date) => {
        let dateDebut = new Date(date)
        dateDebut.setFullYear(dateDebut.getFullYear() + 30)
        return dateDebut < new Date();
    }

    componentDidMount() {
        Tools.updateNameUploadFile()
        axios.get(process.env.REACT_APP_API_URL + '/emplacements/' + this.state.emplacementId)
            .then ((response) => {
                this.setState({ emplacement: response.data})
                const location = this.state.emplacement.lastLocation
                if (location) {
                    axios.get(process.env.REACT_APP_API_URL + '/locations/' + location.replace(/\D/g, "")).then((response) => {
                        this.setState({lastLocation: response.data})
                        if (response.data.dateDebut && !this.verifDateContrat(response.data.dateDebut)) {
                            $('.step-btn').remove()
                        } else if (!response.data.dateDebut) {
                            this.setState({etape : 0})
                        }
                    })
                } else {
                    this.setState({textEtape1: 'Reprise après constat d\'abandon impossible car l\'emplacement est libre.'})
                    $('.step-btn').remove()
                }
                this.getReprises(this.state.emplacement.procedureReprises)
            })
    }

    async getReprises(procedures) {
        const nbEtape = this.state.emplacement.occupants ? this.state.emplacement.occupants.length > 0 ? 13 : 12 : 13
        if (procedures !== undefined && procedures.length > 0) {
            await axios.all(procedures.map(p => axios.get(process.env.REACT_APP_API_URL + '/procedure_reprises/' + p.replace(/\D/g, ""))))
                .then(axios.spread((...res) => {
                    let reprises = {};
                    for (let key in res){
                        reprises[res[key].data.id] = res[key].data
                    }

                    let lastReprise = this.state.emplacement.lastprocedureReprise
                    if (lastReprise  != null) {
                        lastReprise  = lastReprise .replace(/\D/g, "")
                        lastReprise  = reprises[lastReprise]

                        this.setState({
                            lastReprise  : lastReprise,
                            etapes : lastReprise.etapes,
                            reprises: reprises,
                            etiquettesTexte : EtapeEnCoursText.generateTextEtape(lastReprise.etapes, lastReprise)
                        })
                        if (lastReprise.etat <= nbEtape && (lastReprise.dateFin === null || lastReprise.dateFin === undefined )) {
                            let etape = lastReprise.etat + 1
                            if (etape === 4 || etape === 10) {
                               /*let date = etape === 4 ? new Date(lastReprise.dateHeurePV1) : new Date(lastReprise.dateHeurePV2)
                                date.setDate(date.getDate() + 8)
                                const now = new Date()*/
                                if (dayjs().isBefore(dayjs(lastReprise['etapes'][etape-1]['date_fin'])))
                                //if (date <= now)
                                    {
                                    etape -= 1
                                }
                            }
                            this.setState({
                                etape : etape,
                            })
                            Tools.navigationPopup('Reprise pour état d\'abandon', etape === nbEtape)
                        } else {
                            Tools.navigationPopup('Reprise pour état d\'abandon', false)
                        }
                    }
                }));
        } else {
            Tools.navigationPopup('Reprise pour état d\'abandon', false)
        }
    }

    getForm = (e) => {
        if (this.state.lastRepriseCall !== $(e.target).attr('data-reprise') || this.state.lastEtapeCall !== e.target.id) {

            this.setState({lastRepriseCall : $(e.target).attr('data-reprise'), lastEtapeCall : e.target.id})

            let promise = process.env.REACT_APP_API_URL + '/repriseAbandon/form/' + this.state.emplacementId+ '/';
            if ($(e.target).attr('data-reprise') !== undefined) {
                promise += e.target.id + '/' + $(e.target).attr('data-reprise')
            } else {
                promise += this.state.etape
            }

            axios.get(promise)
                .then((response) => {
                    this.setState({form: response.data})

                    $('.modal.reprise-abandon').modal('show');

                    if ($(e.target).attr('data-reprise') === undefined) {
                        this.updateField();
                    } else {
                        $('input, select').prop( "disabled", true )
                    }

                    if ((this.state.etape === 1)) {
                        $('#reprise1_dateDebut').change(function() {
                            console.log($(this).val())
                            if (dayjs($(this).val(), 'YYYY-MM-DD', true).isValid()) {
                                const strdate = dayjs($(this).val()).add(1, 'month')
                                $('#reprise1_datePremierPVestimee_date').attr('min', strdate.format('YYYY-MM-DD')).val(strdate.format('YYYY-MM-DD'))
                            }
                        }).change()
                    }
                    if((this.state.etape === 1 || this.state.etape === 7) && !$(e.target).attr('data-reprise')) {
                        let collection = this.state.etape === 1  ? 'autreEtatAbandonFile' : 'autreEtatAbandonFile2'
                        this.addDocumentType(collection)
                        const ajoutUploadDoc = document.querySelector('#autreEtatAbandon-ajout')
                        if(ajoutUploadDoc) {
                            ajoutUploadDoc.addEventListener('click',  () => {
                                this.addDocumentType(collection)
                            })
                        }
                    }
                })
        }
    }

    addCollectionFormContact() {
        const $tagsCollectionHolder = $('ul.contacts');
        $tagsCollectionHolder.data('index', $tagsCollectionHolder.find('input').length);
        let $collectionHolder = $('.contacts');
        const maxItem = $collectionHolder.find('.form-wrapper-contact').length;
        let prototype = $collectionHolder.data('prototype').replaceAll('__name__', maxItem);
        let index = $collectionHolder.data('index');
        let newForm = $(prototype);
        newForm.addClass('form-contact')
        $collectionHolder.data('index', index + 1);
        newForm.append('<button type="button" class="mt-3 add-doc-btn supprimer-contact-reprise soleilRegular-fonts">Supprimer le contact</button>')
        let $newFormLi = $("<li class='new-contact form-wrapper-contact'></li>").append(newForm);
        $collectionHolder.append($newFormLi)

        $('#attribution_emplacement_contacts_'+ maxItem + ' div:not(div:nth-child(1),div:nth-child(2),div:has( > [id$="civilite"]))').each(function (index) {
            $(this).append('<div class="check-inconnu float-right"> ' +
                '<input class="table-checkbox" id="inconnu-index' + index + maxItem + '" type="checkbox">'+
                '<label for="inconnu-index' + index + maxItem + '" class="soleilBold-fonts purple p-0 m-0">Inconnu</label></div>');
        })
    }

    addDocumentType (collection) {
        const $tagsCollectionHolder = $('ul.' + collection);
        $tagsCollectionHolder.data('index', $tagsCollectionHolder.find('input').length);
        let $collectionHolder = $('.' + collection);
        const maxItem = $collectionHolder.find('.form-wrapper-abandon-documents').length;
        let prototype = $collectionHolder.data('prototype').replaceAll('__name__', maxItem);
        let newForm = $(prototype);
        newForm.find('.vich-image').addClass('d-none dl-actes form_document')
        newForm.find('.vich-image').attr('data-mapping', 'media')
        let formInput = $(document.createElement('div', {
            class : 'd-flex mt-2 file-select mt-4'
        }))
         formInput.append(
             "<div class=\"d-flex mt-2 file-select mt-4\">\n" +
             "                <div class=\"d-flex align-items-center\">\n" +
             "                     <div class=\"col-6\">\n" +
             "                          <p class=\"soleilRegular-fonts\">Autre état d'abandon :</p>\n" +
             "                      </div>\n" +
             "                           <div class=\"col-md-6 d-flex p-0 m-0 upload-wrapper form-wrapper-abandon-documents\">\n" +
             "                               <label for='reprise" + this.state.etape + "_" + collection + "_" + maxItem + "_imageFile_file' class=\"mt-1 mr-3 add-doc-btn dl-actes\">\n" +
             "                                  Choisir un fichier\n" +
             "                                </label>\n" +
             "                               <p class=\"select-pdf-file-name soleilRegular-fonts\">Aucun fichier sélectionné</p>\n" +
             "                               <select id='reprise" + this.state.etape + "_" + collection + "_" + maxItem + "' type='number' name='reprise" + this.state.etape + "[" + collection.replace('File', '') + "][" + maxItem + "]' hidden></select>\n"
             + newForm.html() +
                                        " </div>\n" +
             "                     </div>\n" +
             "                  </div>"
             )
        $collectionHolder.append(formInput)

    }

    updateField = () => {
        $('[data-toggle="popover"]').popover()

        const attestionActe = document.getElementById('reprise0_attestationConcessionPlus30ans')
        if (attestionActe) {
            attestionActe.addEventListener('change', function () {
                document.querySelector('.valider').classList.toggle('display-none')
            })
        }

        if(document.querySelector('[id$=familleOuEntretienChoice]')) {
            document.querySelector('[id$=familleOuEntretienChoice]').addEventListener('change', (e) => {
                if (e.target.value === "1") {
                    this.addCollectionFormContact()
                }
                document.querySelector('.reprise-contact').classList.toggle('display-none')
            })
        }

        const addContactRepriseButton = document.getElementById('add-contact-reprise')
        if(addContactRepriseButton){
            addContactRepriseButton.addEventListener('click', () => {
                this.addCollectionFormContact()
            })
        }

        $(document).on('click','.supprimer-contact-reprise',(e) => {
            if(e.target.getAttribute('data-id')) {
                axios.delete(process.env.REACT_APP_API_URL + '/repriseAbandon/deleteContact/' + this.state.lastReprise.id  + '/' + e.target.getAttribute('data-id'))
            }
            e.target.closest('.form-wrapper-contact').remove()
        })

        const datesToCheck = document.querySelectorAll('.date-to-check')
        if(datesToCheck.length > 0) {
            datesToCheck.forEach((e) => {
                e.addEventListener('change', (e) => {
                    let date1 = $(e.target).parent().parent().find('.date-for-check').val()
                    let date2 = e.target.value
                    if(date1 && date2) {
                        if(!DateTools.compareDate(date1, date2, null, 1)) {
                            e.target.value = ''
                            document.getElementById('error').innerText = 'La suspension doit avoir lieu minimum un mois après l\'affichage.'
                        } else {
                            document.getElementById('error').innerText = ''
                        }
                    }else if(!date1) {
                        e.target.value = ''
                        document.getElementById('error').innerText = 'Veuillez dabord indiquez la date d\'affichage correspondante.'
                    }
                })
            })
        }

        const datesForGenerate = document.querySelectorAll('.date-for-generate')
        if(datesForGenerate.length > 0) {
            datesForGenerate.forEach((e) => {
                e.addEventListener('change', (e) => {
                    $(e.target).closest('.row').find('.date-to-generate').val(DateTools.addMonthOrYear(e.target.value, null, 1))
                })
            })
        }

        const famillePresenteConstat = document.querySelector('[id$=famillePresenteConstat]');
        if(famillePresenteConstat) {
            famillePresenteConstat.addEventListener('change', function () {
                document.getElementById('famillePresenteConstatWrapper').classList.toggle('display-none')
            })

            document.querySelector('[id$=objectionsQuestion]').addEventListener('change', function () {
                document.getElementById('objectionsConstatWrapper').classList.toggle('display-none')
            })
        }

    const destinationDefuntChoice = document.querySelector('[id$=destinationResteDefunt]');
        if(destinationDefuntChoice) {
            destinationDefuntChoice.addEventListener('change', function () {
                if (this.value === 'Ossuaire') {
                    document.getElementById('reprise-deplacer-defunt-ossuaire').classList.remove('display-none')
                    document.getElementById('reprise-deplacer-defunt-jardinDuSouvenir').classList.add('display-none')
                }else {
                    document.getElementById('reprise-deplacer-defunt-ossuaire').classList.add('display-none')
                    document.getElementById('reprise-deplacer-defunt-jardinDuSouvenir').classList.remove('display-none')
                }
            })
        }
    }

    render() {
        require('../../../assets/css/procedures.css')
        require('../../../assets/css/formPop-up.css')

        const etiquettesEnCours = [];
        const etiquettesFinies = [];
        const nbEtape = this.state.emplacement.occupants ? this.state.emplacement.occupants.length > 0 ? 13 : 12 : 13
        const firstStep = this.state.lastLocation ? this.state.lastLocation.dateDebut ? 1 : 0 : 0

        for (const procedure of Object.keys(this.state.reprises)) {
                const reprise = this.state.reprises[procedure]
                for (let i = firstStep; i <= reprise['etat']; i++) {
                    // on verifie si l'étiquette a affiché n'est pas celle en cours et si une autre procedure a été commencé
                    if (reprise['id'] !== this.state.lastReprise.id || i !== this.state.etape || this.state.lastReprise.etat === nbEtape) {
                        let dataTarget = '#etape' + i;
                        let etapeEnCoursText = EtapeEnCoursText.generateTextEtape(this.state.etapes, i, this.state.lastReprise, this.state.lastLocation.dateDebut)
                        etiquettesFinies.push(
                            <div className="etape bg-white p-3 etape-finie">
                                <p className="soleilBold-fonts title purple etape-title">{etapeEnCoursText['titre']}</p>
                                <p className="soleilRegular-fonts purple etape-description first-letter-uppercase">
                                    <EtapeValideTexte etape={i} procedure={this.state.lastReprise} etapePrecedente = {this.etapePrecedente}/>
                                </p>
                                {
                                    etapeEnCoursText && etapeEnCoursText['document'] ?
                                        <div className="d-flex justify-content-between align-items-center">
                                            <a className="dl-doc soleilBold-fonts d-flex " onClick={() => HandleDownload(etapeEnCoursText['document'])}>
                                                <img src={dlDocProcedure} className="img-fluid mr-2" width="20px" alt=""/>
                                                <p>Documents</p>
                                            </a>
                                            <button type="button" className="add-doc-btn soleilBold-fonts step-btn"
                                                    data-toggle="modal" data-target={dataTarget} id={i} data-reprise={procedure}
                                                    onClick={this.getForm}>Détails
                                            </button>
                                        </div>
                                    :
                                        <div className="d-flex justify-content-end">
                                            <button type="button" className="add-doc-btn soleilBold-fonts step-btn"
                                                    data-toggle="modal" data-target={dataTarget} id={i} data-reprise={procedure}
                                                    onClick={this.getForm}>Détails
                                            </button>
                                        </div>
                                }
                            </div>
                        )
                    }
                }
            }

        if(this.state.lastReprise || this.state.etape === 1 || this.state.etape === 0) {
            let i = this.state.etape
            let dataTarget = '#etape' + i
            let textPourEtapeBis = null
            const etapeEnCoursText = EtapeEnCoursText.generateTextEtape(this.state.etapes, this.state.etape, this.state.lastReprise, this.state.lastLocation.dateDebut)
            if (this.state.lastReprise && this.state.etapes.length > 1 && this.state.etapes[i]['en_cours'] !== undefined && this.state.etapes[i]['en_cours'] === 'suspension' ) {
                textPourEtapeBis = <EtapeValideTexte etape={i} procedure={this.state.lastReprise} />
            }
            etiquettesEnCours.push (
                <div className="etape bg-white p-3">
                    <p className="soleilBold-fonts title purple etape-title">étape {i}/{nbEtape} :&nbsp;
                        {etapeEnCoursText['titre']}</p>
                    <div className="small-text-etape">
                        <p className="soleilRegular-fonts purple etape-description">{textPourEtapeBis ? textPourEtapeBis : etapeEnCoursText['texte'] }</p>
                    </div>
                    {this.state.emplacement ?
                    <button disabled={etapeEnCoursText['button'].disabled} type="button" className="add-doc-btn soleilBold-fonts step-btn"
                            data-toggle="modal" data-target={dataTarget} id={i} onClick={this.getForm}>{etapeEnCoursText['button'].text}
                    </button>
                        :''}
                </div>
            )
        }

        return (
            <div className="contents content-margin">
                <div className="content-header px-3 px-lg-4 page2">
                    <span className="expand-btn">&#9776;</span>
                    <div className="d-flex align-items-center">
                        <div className="col-12 soleilBold-fonts">
                            <span className="title">REPRISE DE CONCESSION APRES CONSTAT D'ABANDON : </span>
                            <span className="text-white">Emplacement {this.state.emplacement.carre} {this.state.emplacement.allee}</span>
                        </div>
                    </div>
                </div>
                <div className="px-3 px-lg-4  ">
                    <div className="retour-concession d-flex align-items-center mt-3 ml-4 mb-3">
                        <img src={redBellIcon} className="mr-3" width="40px" alt=""/>
                        <Link to={{
                            pathname: "/fiche-concession/" + this.state.emplacement.id}}  className="soleilBold-fonts purple m-0 pt-4">{this.state.lastLocation && this.state.lastLocation.champsSuppValeurs ? 'Famille ' + this.state.lastLocation.champsSuppValeurs.famille : ''} (retour
                            à l' emplacement)
                        </Link>
                    </div>
                    <div className="essential-cemetery-wrapper scroll">
                        <div className="container-fluid px-0 ">
                            <div className="col-lg-12 bg-white py-3">
                                <div className="info-security-content">
                                    <div className="d-lg-inline-flex align-items-center mb-3">
                                        <img src={infoWhiteIcon} alt=""
                                             className="img-fluid mr-2" width="25px"/>
                                        <p className="soleilBold-fonts mt-2 ">Rappels</p>
                                    </div>
                                    <p className="soleilBook-fonts">
                                        Attention, la reprise de concession n’est possible qu’après l’expiration d’un
                                        délai de 30 ans après le début du contrat de concession et maximum 10 ans après
                                        la dernière inhumation.
                                    </p>
                                </div>
                            </div>
                            <div className="etapes-procedures pt-3 d-flex justify-content-center" id="etapes-reprise-abandon">
                                <div className="etapes-wrapper">
                                    {etiquettesFinies}
                                    {etiquettesEnCours}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{__html:this.state.form}} className="formulaire" data-submit={process.env.REACT_APP_API_URL + '/repriseAbandon/form/' + this.state.emplacement.id + '/' + this.state.etape}/>
            </div>
        )
    }
}

export default RepriseAbandon;