 export default class User {
    static USER_LOCAL_KEY = "currentUser";

    static logout = () => {
        localStorage.removeItem(this.USER_LOCAL_KEY);
        localStorage.removeItem("espace_id");
        localStorage.removeItem("societe_id");
        window.location = "/login"
    }
    static getUser = () => {
        return JSON.parse(localStorage.getItem(this.USER_LOCAL_KEY));
    }
}