import React, { Component } from 'react';
import redBellIcon from '../../assets/images/red-bell-icon.svg';
import btnPlusIcon from '../../assets/images/btn-plus-icon.svg'

class Restauration extends Component{

    render() {
        return (
            <div class="container-fluid px-0">
                <div class="row">
                    <div class="col-12">
                        <div class="container-fluid form-wrapper mt-3">
                            <h1 class="soleilBold-fonts purple title ">Pourquoi créer un point de restauration ? </h1>
                            <p class="soleilRegular-fonts purple mt-3 mb-2">LogiPlace conserve les modifications des 7 derniers jours automatiquement.</p>
                            <p class="soleilRegular-fonts purple mb-2">
                                Pour conserver des sauvegardes à des moments précis (par exemple avant l'arrivée d'une nouvelle recrue),
                                vous pouvez créer des points de restauration manuellement sur cette page.
                            </p>
                            <p class="soleilRegular-fonts purple mb-2">
                                Le point de restauration crée une sauvegarde instantanée de toutes vos données à un moment précis.
                            </p>
                            <p class="soleilRegular-fonts purple">
                                Pour restaurer votre espace client, veuillez sélectionner votre point de restauration dans la liste ci-dessous.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 mt-3">
                        <div class="form-wrapper mt-3" >
                            <div class="d-flex flex-wrap" id="restauration-cimetiere">
                                <p class="soleilBold-fonts m-0 col-4 p-0 d-inline-flex align-items-center">
                                    <img src={redBellIcon} alt="" class="img-fluid mr-1" />
                                    Cimetière
                                </p>
                                <div class="select-grey-wrapper">
                                    <select type="text" value="Cimetière Essentiel" class=" soleilRegular-fonts purple select-grey">
                                        <option value="Choisir le cimetière" disabled selected>Choisir le cimetière</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <button type="button" class="add-new-location-btn d-flex align-items-center justify-content-center mt-4 mb-4">
                                    <p class="m-0 soleilRegular-fonts">Créer un point de restauration</p>
                                    <img src={btnPlusIcon} alt="+" class="img-fluid ml-2" />
                                </button>
                            </div>
                            <div class="d-flex justify-content-center">
                                <p class="sous-notes soleilRegular-fonts">Une fois créé, votre point de restauration se trouvera dans la liste ci-contre.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mt-3 mb-3">
                        <div class="form-wrapper mt-3">
                            <p class="soleilBold-fonts small_title">Liste de vos derniers points de restauration manuels</p>
                            <div className="table-responsive px-3 bg-white">
                                <table className="table table-striped table-borderless restauration">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Cimetière</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p class="m-0">19/04/21 à 9h02</p>
                                            </td>
                                            <td>
                                                <p class="m-0">Essentiel</p>
                                            </td>
                                            <td>
                                                <button type="button" class="add-doc-btn soleilRegular-fonts">Restaurer</button>
                                            </td>
                                        </tr>
                                        <tr class="blank_row">
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr class="blank_row">
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr class="blank_row">
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr class="blank_row">
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Restauration;