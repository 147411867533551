function render() {
  return (
    <div className="contents content-margin">
      <div className="content-header px-3 px-lg-4 page2">
        <span className="sidemenu-expand-btn">&#9776;</span>
        <div className="d-flex align-items-center">
          <div className="col-12 soleilBold-fonts title">
            Emplacement principal G8
            <span className="mx-3">|</span>
            <span className="soleilRegular-fonts sub-text">surface 6m2</span>
            <span className="mx-3">|</span>
            <span className="soleilRegular-fonts sub-text">Pré-réservation impossible</span>
            <img src="assets/images/info-icon.svg" alt="info-icon"
                 className="img-fluid ml-4 ml-lg-5 mr-3"/>
            <img src="assets/images/edit-icon-purple.svg" alt=""
                 className="img-fluid"/>
          </div>
        </div>
      </div>
      <div className="px-3 px-lg-4"
           style="background: rgba(232, 232, 232, 0.4);">
        <div className="durand-family-wrapper container-fluid pb-2">
          <div className="row py-2 align-items-center">
            <div
              className="col-md-4 title soleilBold-fonts text-center text-md-left">
              <img src="assets/images/green-bell-icon.svg" alt=""
                   className="img-fluid mr-3"/>
              Famille DURAND
              <img src="assets/images/info-icon.svg" alt=""
                   className="img-fluid ml-3"/>
            </div>
            <div className="col-md-8 mt-3 mt-md-0">
              <div className="mx-auto ml-md-auto mr-md-0 durand-buttons">
                <button type="button" className="active">
                  <img src="assets/images/edit-white-icon.svg" alt=""
                       className="img-fluid mr-2" />
                    éditer
                </button>
                <button type="button">
                  <img src="assets/images/archive-icon.svg" alt=""
                       className="img-fluid mr-2" />
                    archiver
                </button>
                <button type="button">
                  <img src="assets/images/refresh-purple-icon.svg" alt=""
                       className="img-fluid" />
                </button>
                <button type="button">
                  <img src="assets/images/pdf-export-icon-purple.svg" alt=""
                       className="img-fluid mr-2" />
                    exporter
                </button>
                <button type="button">
                  <img src="assets/images/delete-icon-purple.svg" alt=""
                       className="img-fluid" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 progress-one mt-3">
              <div className="sub-title-1 soleilBold-fonts">En cours</div>
              <div className="item soleilRegular-fonts mt-2">Concession
                n°<strong className="soleilBold-fonts">364</strong></div>
              <div className="item soleilRegular-fonts">Durée : <strong
                className="soleilBold-fonts">30 ans</strong></div>
              <div className="item soleilRegular-fonts">Type : <strong
                className="soleilBold-fonts">Familiale</strong></div>
              <div className="item soleilRegular-fonts green-tick">Capacité
                : <strong className="soleilBold-fonts" style="color: #09D35F;">3/4
                  ( 1 place restante )</strong></div>
              <div className="item soleilRegular-fonts">Date de début : <strong
                className="soleilBold-fonts">10/09/1990</strong></div>
              <div className="item soleilRegular-fonts green-tick">Date de fin
                : <strong className="soleilBold-fonts"
                          style="color: #09D35F;">10/09/2020</strong></div>
            </div>
            <div className="col-md-4 mt-3 deceased-list d-flex pt-4 pb-3">
              <div className="col-6 text soleilRegular-fonts">
                <strong className="soleilBold-fonts">3 défunts </strong><br />
                M. Marcel Durand <br />
                (1911 – 1990) <br />
                Mme. Josiane Durand <br />
                (1914 – 1999) <br />
                M. Michel Durand <br />
                (1940 – 2012) <br />
                <img src="assets/images/plus-red-icon.svg" alt=""
                     className="img-fluid mt-2"/>
              </div>
              <div className="col-6 text-center">
                <img src="assets/images/img-kabar.png" alt=""
                     className="img-fluid" />
              </div>
            </div>
            <div className="col-md-5 map-wrapper mt-md-3">
              <img src="assets/images/map-icon-1.svg" alt=""
                   className="img-fluid"/>
            </div>
          </div>
        </div>
        <div className="row step-2">
          <div className="col-md-8 mt-3 pl-5-extra">
            <div className="title soleilRegular-fonts">ASSISTANCE JURIDIQUE AUX
              PROCÉDURES
            </div>
            <div className="d-flex mt-3 border-bottom-h green-tick">
              <div className="col-sm-2 pl-0 soleilRegular-fonts">Conversion
              </div>
              <div className="col-sm-9 pl-0 soleilBold-fonts">Possible jusqu'au
                06/02/2040
              </div>
            </div>
            <div className="d-flex mt-3 border-bottom-h green-tick">
              <div className="col-sm-2 pl-0 soleilRegular-fonts">Donation</div>
              <div className="col-sm-9 pl-0 soleilBold-fonts">Possible dans la
                même famille
              </div>
            </div>
            <div className="d-flex mt-3 border-bottom-h red-tick">
              <div className="col-sm-2 pl-0 soleilRegular-fonts">Rétrocession
              </div>
              <div className="col-sm-9 pl-0 soleilBold-fonts">Impossible la
                concession est occupée
              </div>
            </div>
            <div className="d-flex mt-3 border-bottom-h red-tick">
              <div
                className="col-sm-2 pl-0 soleilRegular-fonts">Renouvellement
              </div>
              <div className="col-sm-9 pl-0 soleilBold-fonts">Impossible avant
                06/02/2033 et après 06/02/2040 - Forcer
              </div>
            </div>
            <div className="d-flex mt-3 border-bottom-h red-tick">
              <div className="col-sm-2 pl-0 soleilRegular-fonts">Reprise par
                abandon
              </div>
              <div className="col-sm-9 pl-0 soleilBold-fonts">Le contrat de
                concession a été conclu il y a moins de 30 ans <br /> ou
                  l’inhumation du dernier défunt a été réalisée il y a moins de
                  10</div>
            </div>
            <div className="d-flex mt-3 border-bottom-h red-tick">
              <div className="col-sm-2 pl-0 soleilBold-fonts">ans.</div>
            </div>
            <div className="d-flex mt-1">
              <div className="col-sm-2 pl-0 soleilRegular-fonts">Reprise pour
              </div>
            </div>
            <div className="d-flex mt-1">
              <div className="col-sm-2 pl-0 soleilRegular-fonts">non
                renouvellement
              </div>
              <div className="col-sm-9 pl-0 soleilBold-fonts">Impossible</div>
            </div>
          </div>
          <div className="col-md-4 mt-3">
            <div className="title soleilRegular-fonts pl-5-extra">ACTES</div>
            <div className="pl-5-extra pt-2 pb-2 mt-2"
                 style="background: rgba(232, 232, 232, 0.4);">
              <div className="d-flex mt-2 green-tick position-relative">
                <div className="col-6 pl-0 soleilRegular-fonts">Demande</div>
                <div className="col-6 pl-0 soleilBold-fonts">oui</div>
              </div>
              <div className="d-flex mt-1 green-tick position-relative">
                <div className="col-6 pl-0 soleilRegular-fonts">Acte</div>
                <div className="col-6 pl-0 soleilBold-fonts">oui</div>
              </div>
              <div className="d-flex mt-1 green-tick position-relative">
                <div className="col-6 pl-0 soleilRegular-fonts">Titre
                  provisoire
                </div>
                <div className="col-6 pl-0 soleilBold-fonts">oui</div>
              </div>
              <img src="assets/images/plus-red-icon.svg" alt=""
                   className="img-fluid mt-3"/>
            </div>
            <div className="title soleilRegular-fonts pl-5-extra mt-3">TRAVAUX
            </div>
            <div className="pl-5-extra pt-1 pb-2 mt-2"
                 style="background: rgba(232, 232, 232, 0.4);">
              <div className="d-flex mt-2">
                <div className="col-6 pl-0 soleilRegular-fonts">Aucun travaux en
                  cours
                </div>
              </div>
              <img src="assets/images/plus-red-icon.svg" alt=""
                   className="img-fluid mt-2"/>
            </div>
          </div>
        </div>
        <div className="names-wrapper">
          <div className="d-flex">
            <div className="my-col-2">
              <div className="title soleilBold-fonts">Concessionnaire
                principal
              </div>
              <div className="text soleilBold-fonts">
                M. Marc DURAND <br />
                10 Rue Voltaire 10000 TROYES <br />
                05 55 98 98 78 <br />
                marc.durand@wanadoo.fr
              </div>
              <img src="assets/images/info-icon.svg" alt=""
                   className="img-fluid"/>
            </div>
            <div className="my-col-2">
              <div className="title soleilBold-fonts">Co-concessionnaire</div>
              <div className="text soleilRegular-fonts">
                M. Sophie DURAND <br />
                12 Rue Diderot 69000 LYON <br />
                07 35 12 18 91 <br />
                sophie.durand@gmail.com
              </div>
              <img src="assets/images/info-icon.svg" alt=""
                   className="img-fluid"/>
            </div>
            <div className="my-col-2 mt-3 mt-sm-0">
              <div className="title soleilBold-fonts">Ayant-droit</div>
              <div className="text soleilRegular-fonts">
                M. Victor DURAND <br />
                111 Boulevard des Capucines 75000 PARIS <br />
                06 14 15 41 11 <br />
                victor.durand@gmail.com
              </div>
              <img src="assets/images/info-icon.svg" alt=""
                   className="img-fluid"/>
            </div>
            <div className="my-col-2 mt-3 mt-lg-0">
              <div className="title soleilBold-fonts">Ayant-droit</div>
              <div className="text soleilRegular-fonts">
                M. Thierry DURAND <br />
                53bis Avenue De Gaulle 17000 LA ROCHELLE <br />
                07 81 19 42 82 <br />
                t.durand17@gmail.com
              </div>
              <img src="assets/images/info-icon.svg" alt=""
                   className="img-fluid"/>
            </div>
            <div className="my-col-2 mt-3 mt-lg-0">
              <img src="assets/images/plus-red-icon.svg" alt=""
                   className="img-fluid mt-sm-5"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default render;
