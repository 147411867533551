import React, { useCallback, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/wordcount';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';// Import as a module in your JS
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../../assets/css/documents.css';
import Dropzone from 'react-dropzone'

import { Editor } from '@tinymce/tinymce-react';
import { connect } from 'react-redux';
import { getDocument, updateDocument } from '../../actions/documents';
import {Link} from 'react-router-dom';
import axios from "../../helpers/axios";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {Typeahead} from "react-bootstrap-typeahead";

const EditDocument = ({
    match,
    history
}) => {

    const declencheursCles = {
        "hook.espace.create.after": {
            trigger: 'create_after',
            category: 'Espace'
        }
    };


    // Our custom function for setting up the Web Component.
    // Read more about web components here:
    // https://developer.mozilla.org/en-US/docs/Web/Web_Components
    const setupWebComponent = (win, doc, editor) => {
        // the shadow root gets it's HTML content from the template element.
        // We do not need to inject the template element into the content,
        // we can simply create it in memory and attach it to the shadow root
        const template = doc.createElement('template');

        template.innerHTML = `
                <style>
                    /* The host selector targets the shadow DOM host element
                     * https://developer.mozilla.org/en-US/docs/Web/CSS/:host()
                     */
                    :host {
                        display: block; /* Required to get block behavior inside TinyMCE */
                        background-color: rgba(240, 210, 140, .20);
                        border-radius: 6px;
                    }

                    header {
                        display: flex;
                        padding: 4px 6px;
                        margin: 0;
                        background-color: rgba(240, 210, 140, .20);
                        border-radius: 6px 6px 0 0;
                    }

                    header p {
                        margin: 0;
                        line-height: 24px;
                        font-size: 14px;
                        color: #B7974C;
                    }

                    header > svg {
                        fill: #B7974C;
                        margin-right: 6px;
                    }

                    span#property {
                        font-weight: bold;
                    }

                    span#value {
                        font-weight: bold;
                    }

                    button {
                        background: rgba(240, 210, 140, .5);
                        border: 0;
                        outline: 0;
                        -webkit-tap-highlight-color: rgba(0,0,0,0);
                        -webkit-user-select: none;
                        user-select: none;
                        font-weight: normal;
                        padding: 6px;
                        margin: 0 0 0 10px;
                        border-radius: 6px;
                    }

                    button svg {
                        fill: #B7974C;
                        display: block;
                    }

                    button:hover {
                        background-color: rgba(240, 210, 140, .75);
                    }

                    .content {
                        margin: 0 6px;
                        box-sizing: border-box;
                        padding-bottom: 2px;
                    }
                </style>

                <!--
                    The web component's HTML. The <slot> will be
                    replaced by the content wrapped in the <condidional-block> element.
                    https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_templates_and_slots
                -->
                <header>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M19 4a2 2 0 1 1-1.854 2.751L15 6.75c-1.239 0-1.85.61-2.586 2.31l-.3.724c-.42 1.014-.795 1.738-1.246 2.217.406.43.751 1.06 1.12 1.92l.426 1.018c.704 1.626 1.294 2.256 2.428 2.307l.158.004h2.145a2 2 0 1 1 0 1.501L15 18.75l-.219-.004c-1.863-.072-2.821-1.086-3.742-3.208l-.49-1.17c-.513-1.163-.87-1.57-1.44-1.614L9 12.75l-2.146.001a2 2 0 1 1 0-1.501H9c.636 0 1.004-.383 1.548-1.619l.385-.92c.955-2.291 1.913-3.382 3.848-3.457L15 5.25h2.145A2 2 0 0 1 19 4z" fill-rule="evenodd"/></svg>
<!-- <span id="value"></span> -->
                    <p>Visible si <span id="property"></span>&nbsp;<span id="operator">&nbsp;</span>&nbsp;<span id="value"></span></p>

                    <button type="button" id="btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M0 9.502v2.5h2.5l7.373-7.374-2.5-2.5L0 9.502zm11.807-6.807c.26-.26.26-.68 0-.94l-1.56-1.56a.664.664 0 0 0-.94 0l-1.22 1.22 2.5 2.5 1.22-1.22z"/></svg>
                    </button>
                </header>
                <div class="content">
                    <slot></slot>
                </div>
            `;

        // Create the conditional block custom element.
        // Familiarize yourself with web components and custom elements here:
        // https://developer.mozilla.org/en-US/docs/Web/Web_Components
        class ConditionalBlock extends win.HTMLElement {
            constructor() {
                super();

                // During the creation of the web component we set contenteditable false
                // on the web component to make it behave like a noneditable-but-selectable
                // element inside TinyMCE.
                this.setAttribute('contenteditable', false);

                // Attach the shadow DOM to the element
                // https://developer.mozilla.org/en-US/docs/Web/API/Element/attachShadow
                const shadow = this.attachShadow({mode: 'open'});

                // Attach the html template to the web components shadow DOM
                this.shadowRoot.appendChild(template.content.cloneNode(true));
            }

            connectedCallback() {
                // Make the content within <conditional-block> editable by wrapping the
                // content in a <div> with contenteditable on it.
                const cleanupContentEditable = () => {
                    if (this.firstChild.contentEditable !== 'true') {
                        const editableWrapper = document.createElement('div');
                        editableWrapper.setAttribute('contenteditable', true);

                        while (this.firstChild) {
                            editableWrapper.appendChild(this.firstChild)
                        }

                        this.appendChild(editableWrapper);
                    }
                }
                cleanupContentEditable();

                // Open the edit dialog
                const editConditionalBlock = () => {
                    dialogManager(this, editor);
                    return false;
                }
                this.shadowRoot.getElementById('btn').addEventListener('click', editConditionalBlock);
            }

            // Everytime a custom element's attributes is added, changed or removed
            // the `attributeChangedCallback` method is invoked. Which attributes are
            // observed is defined by the `observedAttributes` method.
            attributeChangedCallback(name, oldValue, newValue) {
                if (name === 'data-property') {
                    this.shadowRoot.getElementById('property').textContent = newValue;
                }
                else if (name === 'data-operator') {
                    this.shadowRoot.getElementById('operator').textContent = newValue;
                }
                else if (name === 'data-value') {
                    this.shadowRoot.getElementById('value').textContent = newValue;
                }
            }

            static get observedAttributes() { return ['data-property', 'data-operator', 'data-value']; }

        }
        // Register our web component to the tag we want to use it as
        // https://developer.mozilla.org/en-US/docs/Web/API/CustomElementRegistry/define
        win.customElements.define('conditional-block', ConditionalBlock);
    }

    // Custom function that manages the Insert/edit dialog
    const dialogManager = (conditionalBlock, editor) => {
        // Open a TinyMCE modal where the user can set the badge's
        // background and text color.
        // https://www.tiny.cloud/docs/ui-components/dialog/
        // https://www.tiny.cloud/docs/ui-components/dialogcomponents/
        editor.windowManager.open({
            title: 'Insérer votre condition',
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'input',
                        name: 'property',
                        label: 'Propriété'
                    }, {
                        type: 'selectbox',
                        name: 'operator',
                        label: 'Opérateur',
                        items: [
                            {value: 'exist', text: 'existe'},
                            {value: 'not_exist', text: 'est vide / n\'existe pas'},
                            {value: 'more_than_1', text: 'supérieur à 1'},
                            {value: 'equal', text: 'égal à'},
                            {value: 'not_equal', text: 'différent de'},
                        ]
                    },
                    {
                        type: 'input',
                        name: 'value',
                        label: 'Valeur à comparer'
                    },
                ]
            },
            buttons: [
                {
                    type: 'cancel',
                    name: 'closeButton',
                    text: 'Cancel'
                },
                {
                    type: 'submit',
                    name: 'submitButton',
                    text: 'Save',
                    primary: true
                }
            ],
            initialData: {
                property: conditionalBlock ? conditionalBlock.dataset.property : '$',
                operator: conditionalBlock ? conditionalBlock.dataset.operator : 'exist',
                value: conditionalBlock ? conditionalBlock.dataset.value : ''
            },
            onSubmit: (dialog) => {
                // Get the form data.
                var data = dialog.getData();

                // Check if a block is edited or a new block is to be inserted
                if (!conditionalBlock) {
                    // Insert content at the location of the cursor.
                    // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#insertcontent
                    //
                    editor.insertContent(`<conditional-block data-property="${data.property}" data-operator="${data.operator}" data-value="${data.value}"><p>Votre contenu conditionnel</p></conditional-block>`);
                } else {
                    // Working directly with the DOM often requires manually adding
                    // the actions to the undo stack.
                    // https://www.tiny.cloud/docs/api/tinymce/tinymce.undomanager/
                    editor.undoManager.transact(() => {
                        // Update the data-attributes on the conditional-block element
                        conditionalBlock.dataset.property = data.property;
                        conditionalBlock.dataset.operator = data.operator;
                        conditionalBlock.dataset.value = data.value;
                    });

                    // Tell TinyMCE that the ui has been updated.
                    // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#nodechanged
                    editor.nodeChanged();
                }

                // Close the dialog.
                dialog.close();
            }
        });
    }

    const declencheurs = ["Utilisateur", "Espace", "Emplacement", "Concession", "Défunt", "Contact", "Concessionnaire", "Inhumation", "Travaux"];
    const [loaded, setLoaded] = useState(false);
    const [isChange, setIsChange] = useState(false);
    const [togglePreview, setTogglePreview] = useState(true);
    const [typeContent, setTypeContent] = useState('mail');
    const [documentsPdf, setDocumentsPdf] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [previewDocument, setPreviewDocument] = useState('');
    const [checked, setChecked] = useState({});
    const [documentObj, setDocument] = useState({
        id: false,
        content: {mail: '', sms: '', pdf: ''},
        notifieQui: '',
        champsEntite: '',
        nom: '',
        nomInterne: '',
        type: '',
        documentStatiques: [],
        hooks: [],
        moredata: {}
    });

    const downloadPreviewDocument = (documentObj) => {
        axios.post(process.env.REACT_APP_API_URL + '/documents/generate?preview=1', {
            slug: documentObj.slug,
            moredata: documentObj.moredata
        }).then((response) => {

            const a       = document.createElement('a')
            a.href     = process.env.REACT_APP_FILES_URL + '/' + response.data
            a.target   = '_blank'
            document.body.appendChild(a);
            a.click()
            URL.revokeObjectURL(a.href);
        })
    }

    const uploadFiles = useCallback(acceptedFiles => {
        const config = {
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
            headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();

        acceptedFiles.map((file) => {
            fd.append('File[]',file);
        });

        axios.post(process.env.REACT_APP_API_URL + '/tools/uploads', fd, config ).then((response) => {
            setDocument({
                ...documentObj,
                documentStatiques: [
                    ...documentObj.documentStatiques,
                    ...response.data
                ],
            })
        });

    }, [documentObj.documentStatiques]);

    const triggerTypeContent = (text) => {
        setTypeContent(text)

        const societeId = localStorage.getItem('societe_id') || null;
        const espaceId = localStorage.getItem('espace_id') || null;
        axios.post(process.env.REACT_APP_API_URL + '/documents/preview', {
            data: documentObj.content[text],
            societeId: societeId,
            cimetiereId: espaceId
        }).then((res) => {
            setPreviewDocument(res.data)
        })
    }

    const removeFile = file => () => {
        if (window.confirm('Etes vous sûr de vouloir supprimer ce fichier ?')) {
            setDocument({
                ...documentObj,
                documentStatiques: documentObj.documentStatiques.filter((item) => {
                    return item !== file
                })
            })
        }
    }


    const files = documentObj.documentStatiques?.map(file => (
        <li key={file}>
            <a href={process.env.REACT_APP_API_URL + file} target={"_blank"}>{file}</a>
            <button class="btn btn-primary" onClick={removeFile(file)}>Supprimer fichier</button>
        </li>
    ))


    useEffect(() => {
        if (documentsPdf && documentObj) {
            setSelectedDocuments(
                documentsPdf.filter((item) => {
                    return documentObj.relatedDocuments?.includes(item['@id'])
                }).map((item) => {
                    return {id: item.id, label: item.nomInterne}
                }))
        }
    }, [documentObj, documentsPdf])
    useEffect(() => {
        declencheurs.forEach((item) => {
            checked[item] = false
        })

        axios.get(process.env.REACT_APP_API_URL + '/documents?type=documents&itemsPerPage=9999').then((res) => {
            setDocumentsPdf(res.data['hydra:member'])


        })

        axios.get(process.env.REACT_APP_API_URL + '/documents/custom_fields').then((res) => {
            setCustomFields(res.data)
        })

        if (match.params.id) {
            axios.get(process.env.REACT_APP_API_URL + '/documents/' + match.params.id).then((res) => {
                setDocument({
                    id: res.data.id,
                    content: res.data.content,
                    actif: res.data.actif,
                    description: res.data.description,
                    nom: res.data.nom,
                    nomInterne: res.data.nomInterne,
                    type: res.data.type,
                    slug: res.data.slug,
                    hooks: res.data.hooks,
                    notifieQui: res.data.notifieQui,
                    champsEntite: res.data.champsEntite,
                    relatedDocuments: res.data.relatedDocuments,
                    documentStatiques: res.data.documentStatiques || []
                })
                setLoaded(true)
                let newChecked = []

                declencheurs.forEach((item) => {
                    newChecked[item] = res.data.hooks.join('.').indexOf("hook." + item) > -1
                })

                setChecked(newChecked)
            });
        }

    }, [])

    const editorOnChange = (content, editor) => {

        const societeId = localStorage.getItem('societe_id') || null;
        const espaceId = localStorage.getItem('espace_id') || null;
        if ("content_" + typeContent === editor.targetElm.name) {
            axios.post(process.env.REACT_APP_API_URL + '/documents/preview', {
                data: content,
                cimetiereId: espaceId,
                societeId: societeId
            }).then((res) => {
                setPreviewDocument(res.data)
            })
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        if (match.params.id) {
            await axios.put(
                `${process.env.REACT_APP_API_URL}/documents/${match.params.id}`,
                JSON.stringify({
                    ...documentObj,
                    content: {
                        'mail': form.content_mail.value,
                        'sms': form.content_sms.value
                    }
                }),
                {headers: {
                        'Content-Type': 'application/json',
                    }}
            ).catch((e) => {console.log(e)} );
        }
        else {
            documentObj.id = null;
            await axios.post(
                `${process.env.REACT_APP_API_URL}/documents`,
                {
                    ...documentObj,
                    content: {
                        'mail': form.content_mail.value,
                        'sms': form.content_sms.value
                    }
                }
            );

            history.push(`/documents`);
        }
    };

    const myFetch = useCallback(pattern => {
        return new window.tinymce.util.Promise((resolve) => {
            var results = getMatchedChars(pattern, customFields).map(function (char) {
                return {
                    type: 'cardmenuitem',
                    value: char.label,
                    label: "" + char.value,
                    items: [
                        {
                            type: 'cardcontainer',
                            direction: 'vertical',
                            items: [
                                {
                                    type: 'cardtext',
                                    text: "" + char.label,
                                    classes: ['font-weight-bold'],
                                    name: 'char_name'
                                },
                                {
                                    type: 'cardtext',
                                    text: "" + char.value +"",
                                    classes: ['exemple-card'],
                                }
                            ]
                        }
                    ]
                }
            });
            resolve(results);
        });
    }, [customFields])

    const getMatchedChars = (pattern) => {
        return customFields.filter(function (char) {
            return char['label'].indexOf(pattern) !== -1;
        });
    };

    if (!customFields.length) {
        return <div>En attente..</div>
    }

    return  (
        <Fragment>
            <div className="contents content-margin">
                <div className="content-header px-3 px-lg-4 page2">
                    <span className="sidemenu-expand-btn">&#9776;</span>
                    <div className="d-flex align-items-center">
                        <div className="col-12 soleilBold-fonts title">
                            MODÈLES
                        </div>
                    </div>
                </div>
                {
                    <div className="mx-5 scroll ">
                        <form className='form' onSubmit={(e) => onSubmit(e)}>
                            <h1>
                                {
                                    documentObj.id ? 'Modification' : 'Ajout'
                                }
                            </h1>
                            <div className="">
                                <div className="row">
                                <div className="col-md-4">

                                    <div className="form-group">
                                        <label htmlFor="">Nom</label>
                                        <input type="text" className="form-control" defaultValue={documentObj.nom} name="nom" onChange={(e) => {setDocument((prev) => ({
                                            ...prev,
                                            nom: e.target.value
                                        }))}}/>
                                    </div>
                                </div>
                                <div className="col-md-4">

                                    <div className="form-group">
                                        <label htmlFor="">Nom interne</label>
                                        <input type="text" className="form-control" defaultValue={documentObj.nomInterne}  onChange={(e) => {setDocument((prev) => ({
                                            ...prev,
                                            nomInterne: e.target.value
                                        }))}}
                                               name="nomInterne"/>
                                    </div>

                                </div>
                                <div className="col-md-4">

                                    <div className="form-group">
                                        <label htmlFor="">Référence</label>
                                        <input type="text" className="form-control" defaultValue={documentObj.slug}  onChange={(e) => {setDocument((prev) => ({
                                            ...prev,
                                            slug: e.target.value
                                        }))}}
                                               name="slug"/>
                                    </div>

                                </div></div>
                            </div>


                            <div className="row">
                                <div className="col-md-4">

                                    <FormControlLabel
                                        control={<Switch />}
                                        checked={documentObj.type === 'documents'}
                                        onChange={(props) => {setDocument((prev) => ({...prev, type: props.target.checked ? 'documents' : 'mails'}))}}
                                        label="Sauvegarder en PDF ?"
                                    />
                                    <br/>
                                    <FormControlLabel
                                        control={<Switch />}
                                        checked={documentObj.type === 'mails'}
                                        onChange={(props) => {setDocument((prev) => ({...prev, type: props.target.checked ? 'mails' : 'documents'}))}}
                                        label="Sauvegarder en mail ?"
                                    />
                                </div>
                                <div className="col-md-4">

                                    <FormControlLabel
                                        control={<Switch />}
                                        checked={documentObj.actif===true}
                                        onChange={(props) => {setDocument((prev) => ({...prev, actif: props.target.checked}))}}
                                        label="Actif ?"
                                    />
                                </div>
                            </div>

                            <div className='form-group'>
                                <div style={{'display': documentObj.type === 'mails' ? 'block' : 'none'}}>
                                    <label htmlFor='editor'> Type de document</label>
                                    <select className="form-control" onChange={(e) => {
                                        triggerTypeContent(e.target.value)
                                    }}>
                                        <option value="mail">-- Choisir un canal --</option>
                                        <option value="mail">Contenu</option>
                                        <option value="sms">SMS</option>
                                    </select>
                                </div>
                                <br/>
                                <div style={{'display': documentObj.type === 'mails' ? 'block' : 'none'}}>
                                    <label htmlFor='editor'> Notifier qui ?</label>
                                    <input type="text" className="form-control" defaultValue={documentObj.notifieQui}  onChange={(e) => {setDocument((prev) => ({
                                        ...prev,
                                        notifieQui: e.target.value
                                    }))}}
                                           name="notifieQui"/>
                                </div>
                                <div style={{'display': documentObj.type === 'documents' ? 'block' : 'none'}}>
                                    <label htmlFor='editor'> Editer quel champs ?</label>
                                    <input type="text" className="form-control" defaultValue={documentObj.champsEntite}  onChange={(e) => {setDocument((prev) => ({
                                        ...prev,
                                        champsEntite: e.target.value
                                    }))}}
                                           name="champsEntite"/>
                                </div>

                                <FormControlLabel
                                    control={<Switch />}
                                    checked={togglePreview===true}
                                    onChange={(props) => {setTogglePreview((prev) =>  !prev)}}
                                    label="Activer la prévisualisation"
                                />
                            <div id="document-with-side" className={togglePreview===true ? 'active' : '' }>
                                <div>

                                        <div  class={"tinymcecontainer"}
                                            style={{'display': typeContent === 'sms' ? 'none' : 'block'}}>
                                            <Editor
                                                initialValue={documentObj.content['mail']}
                                                textareaName={'content_mail'}
                                                onEditorChange={editorOnChange}
                                                init={{
                                                    height: 250,
                                                    menubar: true,
                                                    language: "fr_FR",
                                                    language_url: "/assets/langs/fr_FR.js",
                                                    plugins: [
                                                        'advlist autolink lists link image',
                                                        'charmap print preview anchor help',
                                                        'searchreplace visualblocks code',
                                                        'pagebreak insertdatetime media table paste wordcount template',
                                                    ], custom_elements: 'conditional-block',
                                                    setup: (editor) => {
                                                        // Instead of defining a custom icon pack, we can register individual
                                                        // icons using the API.
                                                        // https://www.tiny.cloud/docs/api/tinymce.editor.ui/tinymce.editor.ui.registry/#addicon
                                                        editor.ui.registry.addIcon('conditional-block', '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M19 4a2 2 0 1 1-1.854 2.751L15 6.75c-1.239 0-1.85.61-2.586 2.31l-.3.724c-.42 1.014-.795 1.738-1.246 2.217.406.43.751 1.06 1.12 1.92l.426 1.018c.704 1.626 1.294 2.256 2.428 2.307l.158.004h2.145a2 2 0 1 1 0 1.501L15 18.75l-.219-.004c-1.863-.072-2.821-1.086-3.742-3.208l-.49-1.17c-.513-1.163-.87-1.57-1.44-1.614L9 12.75l-2.146.001a2 2 0 1 1 0-1.501H9c.636 0 1.004-.383 1.548-1.619l.385-.92c.955-2.291 1.913-3.382 3.848-3.457L15 5.25h2.145A2 2 0 0 1 19 4z" fill-rule="evenodd"/></svg>');

                                                        // The preinit event is fired after the editor is loaded but before
                                                        // the content is loaded
                                                        // https://www.tiny.cloud/docs/advanced/events/#editorcoreevents
                                                        editor.on('PreInit', () => {
                                                            // Get the iframe window object and the iframes document object
                                                            // and call our setup function that creates the web component
                                                            // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#getwin
                                                            // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#getdoc
                                                            const win = editor.getWin();
                                                            const doc = editor.getDoc();
                                                            setupWebComponent(win, doc, editor);

                                                            // During the creation of the web component we set contenteditable false
                                                            // on the web component to make it behave like a noneditable but selectable
                                                            // element inside TinyMCE. But we don't want the contenteditable attribute
                                                            // to be saved with the content. We therefore need to filter out the attribute
                                                            // upon serlialization (which happens on "save", view sourcecode and preview
                                                            // among others).
                                                            // https://www.tiny.cloud/docs/api/tinymce.dom/tinymce.dom.serializer/#addnodefilter
                                                            editor.serializer.addNodeFilter('conditional-block', (nodes) => {
                                                                // Iterate through all filtered nodes and remove the contenteditable attribute
                                                                // as well as the wrapper <div> we add when creating the custom element
                                                                nodes.forEach((node) => {
                                                                    if (!!node.attr('contenteditable')) {
                                                                        // Remove the contenteditable attribute on the <conditional-block>
                                                                        node.attr('contenteditable', null);
                                                                        // Traverse into the first child which would be the <div contenteditable="true">
                                                                        // and use unwrap to remove the <div> but keep the children.
                                                                        // https://www.tiny.cloud/docs/api/tinymce.html/tinymce.html.node/#unwrap
                                                                        node.firstChild.unwrap();
                                                                    }
                                                                });
                                                            });
                                                        });

                                                        // Register a custom toolbar menu button to insert the conditional block
                                                        // https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#menubutton
                                                        editor.ui.registry.addButton('conditionalblock', {
                                                            icon: 'conditional-block',
                                                            tooltip: 'Insert conditional block',
                                                            onAction: () => {
                                                                dialogManager(null, editor);
                                                            }
                                                        });

                                                        /** autocompelte **/
                                                        var onAction = function (autocompleteApi, rng, value) {
                                                            editor.selection.setRng(rng);
                                                            console.log(value)
                                                            console.log(rng)
                                                            editor.insertContent('$' + value);
                                                            autocompleteApi.hide();
                                                        };

                                                        editor.ui.registry.addAutocompleter('customFields', {
                                                            ch: '$',
                                                            minChars: 0,
                                                            highlightOn: ['char_name'],
                                                            columns: 'auto',
                                                            onAction: onAction,
                                                            fetch: myFetch
                                                        });
                                                    },
                                                    // The following css will be injected into the editor, extending
                                                    // the default styles.
                                                    // In a real world scenario, with much more custom styles for headings
                                                    // links, tables, images etc, it's recommended to use the content_css
                                                    // option to load a separate CSS file. Makes editing easier too.
                                                    // https://www.tiny.cloud/docs/configure/content-appearance/#content_style
                                                    // https://www.tiny.cloud/docs/configure/content-appearance/#content_css
                                                    content_style: `
                                                /* We remove the default selected outline because it doesn't follow the
                                                 * border radius and reintroduce it as a box-shadow.
                                                 */
                                                .mce-content-body conditional-block[contenteditable=false][data-mce-selected] {
                                                    outline: none;
                                                    cursor: default;
                                                    box-shadow: 0 0 0 3px #b4d7ff;
                                                }
                                
                                                .mce-content-body *[contentEditable=false] *[contentEditable=true]:focus {
                                                    outline: none;
                                                }
                                
                                                .mce-content-body *[contentEditable=false] *[contentEditable=true]:hover {
                                                    outline: none;
                                                }
                                

.mce-pagebreak {
text-align: center;
height: 10px;
width: 100%;
background-color: #ccc;
opacity: 0.8;
background: repeating-linear-gradient( -45deg, #6D00B5, #6D00B5 5px, #e5e5f7 5px, #e5e5f7 25px );
}
                                                body {
                                                    max-width: 600px;
                                                    margin: 2rem auto;
                                                }
                                
                                                a {
                                                    color: #2980b9;
                                                }
                                
                                                conditional-block {
                                                    margin: 1rem -6px;
                                                }
                                            `,
                                                    templates: [
                                                        {
                                                            title: 'Espace - Nom',
                                                            description: 'Exemple : Cimetière 1',
                                                            content: '{{espace.nom}}}'
                                                        },
                                                        {
                                                            title: 'Espace - Logo',
                                                            description: 'Exemple : <img src="..." />',
                                                            content: '{{espace.logo}}'
                                                        },
                                                        {
                                                            title: 'Espace - Type ',
                                                            description: 'Exemple : Cimetière',
                                                            content: '{{espace.typeESpace}}'
                                                        },
                                                        {
                                                            title: 'Espace - Adresse ',
                                                            description: 'Exemple : 25 route de la Bourse 10150 Lavau',
                                                            content: '{{espace.adresse_complete}}'
                                                        },
                                                        {title: '-----', description: '', content: ''},
                                                        {
                                                            title: 'Concession - Lien ',
                                                            description: 'Exemple : <a href="">Concession Dupont</a>',
                                                            content: '{{concession.link}}'
                                                        },
                                                        {
                                                            title: 'Concession - Nom ',
                                                            description: 'Exemple : Dupont',
                                                            content: '{{concession.nom}}'
                                                        },
                                                        {
                                                            title: 'Concession - Numéro',
                                                            description: 'Exemple : 243',
                                                            content: '{{concession.numero}}'
                                                        },
                                                        {
                                                            title: 'Concession - Date de début',
                                                            description: 'Exemple : 2016/12/24',
                                                            content: '{{concession.dateDebut}}'
                                                        },
                                                        {
                                                            title: 'Concession - Date de fin',
                                                            description: 'Exemple : 2016/12/24',
                                                            content: '{{concession.dateFin}}'
                                                        },
                                                        {
                                                            title: 'Concession - Durée',
                                                            description: 'Exemple : 30 ans',
                                                            content: '{{concession.duree}}'
                                                        },
                                                        {
                                                            title: 'Concession - Places',
                                                            description: 'Exemple : 2 places',
                                                            content: '{{concession.nbPlaces}}'
                                                        },
                                                        {title: '-----', description: '', content: ''},
                                                        {
                                                            title: 'Emplacement - Lien',
                                                            description: 'Exemple : <a href="">Emplacement AAAA</a>',
                                                            content: '{{emplacement.link}}'
                                                        },
                                                        {
                                                            title: 'Emplacement - Numéro',
                                                            description: 'Exemple : A-4-3-1',
                                                            content: '{{emplacement.code_allee}}'
                                                        },
                                                        {
                                                            title: 'Emplacement - Uid',
                                                            description: 'Exemple : doifodi8diufA',
                                                            content: '{{emplacement.uid}}'
                                                        },
                                                        {
                                                            title: 'Emplacement - Défunts',
                                                            description: 'Exemple : Dupont Jean-pierre, Dupont Marie, ...',
                                                            content: '{{emplacement.occupants}}'
                                                        },
                                                        {
                                                            title: 'Emplacement - Contacts',
                                                            description: 'Exemple : Dupont Jean-pierre, Dupont Marie, ...',
                                                            content: '{{emplacement.contacts}}'
                                                        },
                                                        {title: '-----', description: '', content: ''},
                                                        {
                                                            title: 'Défunt - Nom',
                                                            description: 'Exemple : Dupont ',
                                                            content: '{{defunt.nom}}'
                                                        },
                                                        {
                                                            title: 'Défunt - Prénom',
                                                            description: 'Exemple : Jean-pierre',
                                                            content: '{{defunt.prenom}}'
                                                        },
                                                        {
                                                            title: 'Défunt - Date de Naissance',
                                                            description: 'Exemple : 23/03/1987',
                                                            content: '{{defunt.dateDeNaissance}}'
                                                        },
                                                        {
                                                            title: 'Défunt - Date de décès',
                                                            description: 'Exemple : 23/03/1987',
                                                            content: '{{defunt.dateDeces}}'
                                                        },
                                                        {
                                                            title: 'Défunt - Adresse',
                                                            description: 'Exemple : 25 route de la Bourse 10150 Lavau',
                                                            content: '{{defunt.adresse_complete}}'
                                                        },
                                                        {
                                                            title: 'Défunt - Civilite',
                                                            description: 'Exemple : Monsieur',
                                                            content: '{{defunt.civilite}}'
                                                        },
                                                        {
                                                            title: 'Défunt - Email',
                                                            description: 'Exemple : toto@titi.com',
                                                            content: '{{defunt.email}}'
                                                        },
                                                        {
                                                            title: 'Défunt - Téléphone',
                                                            description: 'Exemple : 09090909',
                                                            content: '{{defunt.telephone}}'
                                                        },
                                                        {title: '-----', description: '', content: ''},
                                                        {
                                                            title: 'Contact - Nom',
                                                            description: 'Exemple : Dupont ',
                                                            content: '{{contact.nom}}'
                                                        },
                                                        {
                                                            title: 'Contact - Prénom',
                                                            description: 'Exemple : Jean-pierre',
                                                            content: '{{contact.prenom}}'
                                                        },
                                                        {
                                                            title: 'Contact - Date de Naissance',
                                                            description: 'Exemple : 23/03/1987',
                                                            content: '{{contact.dateDeNaissance}}'
                                                        },
                                                        {
                                                            title: 'Contact - Date de décès',
                                                            description: 'Exemple : 23/03/1987',
                                                            content: '{{contact.dateDeces}}'
                                                        },
                                                        {
                                                            title: 'Contact - Adresse',
                                                            description: 'Exemple : 25 route de la Bourse 10150 Lavau',
                                                            content: '{{contact.adresse_complete}}'
                                                        },
                                                        {
                                                            title: 'Contact - Civilite',
                                                            description: 'Exemple : Monsieur',
                                                            content: '{{contact.civilite}}'
                                                        },
                                                        {
                                                            title: 'Contact - Email',
                                                            description: 'Exemple : toto@titi.com',
                                                            content: '{{contact.email}}'
                                                        },
                                                        {
                                                            title: 'Contact - Téléphone',
                                                            description: 'Exemple : 09090909',
                                                            content: '{{contact.telephone}}'
                                                        },
                                                    ],
                                                    pagebreak_separator: '<hr class="page-break"><!-- my page break -->',
                                                    toolbar:
                                                    // eslint-disable-next-line
                                                        'undo redo | formatselect | bold italic | \
                                                        alignleft aligncenter alignright | \
                                                        bullist numlist outdent indent | link image media | template | conditionalblock | help',
                                                }}
                                            />

                                        </div>
                                <div className={"tinymcecontainer"}
                                    style={{'display': typeContent != 'sms' ? 'none': 'block'}}>

                                <Editor
                                    initialValue={documentObj.content['sms']}
                                    textareaName={'content_sms'}
                                    onEditorChange={editorOnChange}
                                    init={{
                                        height: 250,
                                        menubar: true,

                                        language: "fr_FR",
                                        language_url: "/assets/langs/fr_FR.js",
                                        plugins: [
                                            'advlist autolink lists link image',
                                            'charmap print preview anchor help',
                                            'searchreplace visualblocks code',
                                            'insertdatetime media table paste wordcount template',
                                        ],
                                        toolbar_sticky: true,
                                        custom_elements: 'conditional-block',
                                        setup: (editor) => {
                                            // Instead of defining a custom icon pack, we can register individual
                                            // icons using the API.
                                            // https://www.tiny.cloud/docs/api/tinymce.editor.ui/tinymce.editor.ui.registry/#addicon
                                            editor.ui.registry.addIcon('conditional-block', '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M19 4a2 2 0 1 1-1.854 2.751L15 6.75c-1.239 0-1.85.61-2.586 2.31l-.3.724c-.42 1.014-.795 1.738-1.246 2.217.406.43.751 1.06 1.12 1.92l.426 1.018c.704 1.626 1.294 2.256 2.428 2.307l.158.004h2.145a2 2 0 1 1 0 1.501L15 18.75l-.219-.004c-1.863-.072-2.821-1.086-3.742-3.208l-.49-1.17c-.513-1.163-.87-1.57-1.44-1.614L9 12.75l-2.146.001a2 2 0 1 1 0-1.501H9c.636 0 1.004-.383 1.548-1.619l.385-.92c.955-2.291 1.913-3.382 3.848-3.457L15 5.25h2.145A2 2 0 0 1 19 4z" fill-rule="evenodd"/></svg>');

                                            // The preinit event is fired after the editor is loaded but before
                                            // the content is loaded
                                            // https://www.tiny.cloud/docs/advanced/events/#editorcoreevents
                                            editor.on('PreInit', () => {
                                                // Get the iframe window object and the iframes document object
                                                // and call our setup function that creates the web component
                                                // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#getwin
                                                // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#getdoc
                                                const win = editor.getWin();
                                                const doc = editor.getDoc();
                                                setupWebComponent(win, doc, editor);

                                                // During the creation of the web component we set contenteditable false
                                                // on the web component to make it behave like a noneditable but selectable
                                                // element inside TinyMCE. But we don't want the contenteditable attribute
                                                // to be saved with the content. We therefore need to filter out the attribute
                                                // upon serlialization (which happens on "save", view sourcecode and preview
                                                // among others).
                                                // https://www.tiny.cloud/docs/api/tinymce.dom/tinymce.dom.serializer/#addnodefilter
                                                editor.serializer.addNodeFilter('conditional-block', (nodes) => {
                                                    // Iterate through all filtered nodes and remove the contenteditable attribute
                                                    // as well as the wrapper <div> we add when creating the custom element
                                                    nodes.forEach((node) => {
                                                        if (!!node.attr('contenteditable')) {
                                                            // Remove the contenteditable attribute on the <conditional-block>
                                                            node.attr('contenteditable', null);
                                                            // Traverse into the first child which would be the <div contenteditable="true">
                                                            // and use unwrap to remove the <div> but keep the children.
                                                            // https://www.tiny.cloud/docs/api/tinymce.html/tinymce.html.node/#unwrap
                                                            node.firstChild.unwrap();
                                                        }
                                                    });
                                                });
                                            });

                                            // Register a custom toolbar menu button to insert the conditional block
                                            // https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#menubutton
                                            editor.ui.registry.addButton('conditionalblock', {
                                                icon: 'conditional-block',
                                                tooltip: 'Insert conditional block',
                                                onAction: () => {
                                                    dialogManager(null, editor);
                                                }
                                            });
                                        },
                                        // The following css will be injected into the editor, extending
                                        // the default styles.
                                        // In a real world scenario, with much more custom styles for headings
                                        // links, tables, images etc, it's recommended to use the content_css
                                        // option to load a separate CSS file. Makes editing easier too.
                                        // https://www.tiny.cloud/docs/configure/content-appearance/#content_style
                                        // https://www.tiny.cloud/docs/configure/content-appearance/#content_css
                                        content_style: `
                                                /* We remove the default selected outline because it doesn't follow the
                                                 * border radius and reintroduce it as a box-shadow.
                                                 */
                                                .mce-content-body conditional-block[contenteditable=false][data-mce-selected] {
                                                    outline: none;
                                                    cursor: default;
                                                    box-shadow: 0 0 0 3px #b4d7ff;
                                                }
                                                

.mce-pagebreak {
    background: #ccc;
}
                                
                                                .mce-content-body *[contentEditable=false] *[contentEditable=true]:focus {
                                                    outline: none;
                                                }
                                
                                                .mce-content-body *[contentEditable=false] *[contentEditable=true]:hover {
                                                    outline: none;
                                                }
                                
                                                body {
                                                    max-width: 600px;
                                                    margin: 2rem auto;
                                                }
                                
                                                a {
                                                    color: #2980b9;
                                                }
                                
                                                conditional-block {
                                                    margin: 1rem -6px;
                                                }
                                            `,
                                        templates: [
                                            {title: 'Espace - Nom', description: 'Exemple : Cimetière 1', content: '{{espace.nom}}'},
                                            {title: 'Espace - Logo', description: 'Exemple : <img src="..." />', content: '{{espace.logo}}'},
                                            {title: 'Espace - Type ', description: 'Exemple : Cimetière', content: '{{espace.typeESpace}}'},
                                            {title: 'Espace - Adresse ', description: 'Exemple : 25 route de la Bourse 10150 Lavau', content: '{{espace.adresse_complete}}'},
                                            {title: '-----', description: '', content: ''},
                                            {title: 'Concession - Lien ', description: 'Exemple : <a href="">Concession Dupont</a>', content: '{{concession.link}}'},
                                            {title: 'Concession - Nom ', description: 'Exemple : Dupont', content: '{{concession.nom}}'},
                                            {title: 'Concession - Numéro', description: 'Exemple : 243', content: '{{concession.numero}}'},
                                            {title: 'Concession - Date de début', description: 'Exemple : 2016/12/24', content: '{{concession.dateDebut}}'},
                                            {title: 'Concession - Date de fin', description: 'Exemple : 2016/12/24', content: '{{concession.dateFin}}'},
                                            {title: 'Concession - Durée', description: 'Exemple : 30 ans', content: '{{concession.duree}}'},
                                            {title: 'Concession - Places', description: 'Exemple : 2 places', content: '{{concession.nbPlaces}}'},
                                            {title: '-----', description: '', content: ''},
                                            {title: 'Emplacement - Lien', description: 'Exemple : <a href="">Emplacement AAAA</a>', content: '{{emplacement.link}}'},
                                            {title: 'Emplacement - Numéro', description: 'Exemple : A-4-3-1', content: '{{emplacement.code_allee}}'},
                                            {title: 'Emplacement - Uid', description: 'Exemple : doifodi8diufA', content: '{{emplacement.uid}}'},
                                            {title: 'Emplacement - Défunts', description: 'Exemple : Dupont Jean-pierre, Dupont Marie, ...', content: '{{emplacement.occupants}}'},
                                            {title: 'Emplacement - Contacts', description: 'Exemple : Dupont Jean-pierre, Dupont Marie, ...', content: '{{emplacement.contacts}}'},
                                            {title: '-----', description: '', content: ''},
                                            {title: 'Défunt - Nom', description: 'Exemple : Dupont ', content: '{{defunt.nom}}'},
                                            {title: 'Défunt - Prénom', description: 'Exemple : Jean-pierre', content: '{{defunt.prenom}}'},
                                            {title: 'Défunt - Date de Naissance', description: 'Exemple : 23/03/1987', content: '{{defunt.dateDeNaissance}}'},
                                            {title: 'Défunt - Date de décès', description: 'Exemple : 23/03/1987', content: '{{defunt.dateDeces}}'},
                                            {title: 'Défunt - Adresse', description: 'Exemple : 25 route de la Bourse 10150 Lavau', content: '{{defunt.adresse_complete}}'},
                                            {title: 'Défunt - Civilite', description: 'Exemple : Monsieur', content: '{{defunt.civilite}}'},
                                            {title: 'Défunt - Email', description: 'Exemple : toto@titi.com', content: '{{defunt.email}}'},
                                            {title: 'Défunt - Téléphone', description: 'Exemple : 09090909', content: '{{defunt.telephone}}'},
                                            {title: '-----', description: '', content: ''},
                                            {title: 'Contact - Nom', description: 'Exemple : Dupont ', content: '{{contact.nom}}'},
                                            {title: 'Contact - Prénom', description: 'Exemple : Jean-pierre', content: '{{contact.prenom}}'},
                                            {title: 'Contact - Date de Naissance', description: 'Exemple : 23/03/1987', content: '{{contact.dateDeNaissance}}'},
                                            {title: 'Contact - Date de décès', description: 'Exemple : 23/03/1987', content: '{{contact.dateDeces}}'},
                                            {title: 'Contact - Adresse', description: 'Exemple : 25 route de la Bourse 10150 Lavau', content: '{{contact.adresse_complete}}'},
                                            {title: 'Contact - Civilite', description: 'Exemple : Monsieur', content: '{{contact.civilite}}'},
                                            {title: 'Contact - Email', description: 'Exemple : toto@titi.com', content: '{{contact.email}}'},
                                            {title: 'Contact - Téléphone', description: 'Exemple : 09090909', content: '{{contact.telephone}}'},
                                        ],
                                        toolbar:
                                        // eslint-disable-next-line
                                            'undo redo | formatselect | bold italic | \
                                            alignleft aligncenter alignright | \
                                            bullist numlist outdent indent | link image media | conditionalblock | help',
                                    }}
                                />

                                    </div>
                                </div>
                                <div id="preview-document" dangerouslySetInnerHTML={{__html: previewDocument}} className={"shadow-lg p-4"}>

                                </div>
                            </div>

                                    <fieldset className={"mt-4"}>
                                        <legend>Ajouter des pièces jointes</legend>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="">Documents PDF</label>
                                                <Typeahead id="documentspdf" selected={selectedDocuments} options={documentsPdf.map((item) => {
                                                    return {
                                                        id: item.id,
                                                        label:  `(${item.slug}) ${item.nomInterne}`
                                                    }
                                                })} multiple onChange={(selected) => {
                                                    setDocument({
                                                        ...documentObj,
                                                        relatedDocuments: selected.map((item) => {
                                                            return process.env.REACT_APP_API_URI + "/documents/" + item.id
                                                        })
                                                    })
                                                }}
                                                           placeholder="Choisir des documents PDF à joindre à ce mail"
                                                    /*selected={documentObj.}*/
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="">Et/ou ajoutez des fichiers statiques</label>
                                                <Dropzone onDrop={uploadFiles}>
                                                    {({getRootProps, getInputProps}) => (
                                                        <section className="dropzone">
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <p>Glissez/déposer vos fichier ici, ou cliquez pour ajouter une pièce jointe</p>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                <aside id={"filesZone"} style={{display: files.length > 0 ? 'block' : 'none'}}>
                                                    <ul>{files}</ul>
                                                </aside>
                                            </div>
                                        </div>

                                        <br/>

                                    </fieldset>

                            </div>
                            <fieldset id="hook" class={{"test": !isChange}}>
                                <legend>Déclencheurs</legend>
                                <FormGroup row >
                                {
                                    loaded && checked && declencheurs.map((item) => {
                                        return (
                                                <FormControlLabel
                                                    control={<Switch checked={checked[item]} onChange={(e) => {

                                                        setChecked((prev) => {
                                                            prev[e.target.name] = !prev[e.target.name];

                                                            return prev;

                                                        })
                                                        setIsChange((prev) => {
                                                            return !prev;
                                                        });
                                                        }}
                                                     name={item} />}
                                                    label={item}
                                                />
                                        );
                                    })
                                }
                                </FormGroup>
                                <div className="row">
                                {
                                    loaded && checked && Object.keys(checked).map((item) => {
                                        if (!checked[item]) return '';

                                        const indexCreateBefore = "hook." + item + ".create.before" + (documentObj.type === "documents" ? ".document" : "");
                                        const indexCreateAfter = "hook." + item + ".create.after" + (documentObj.type === "documents" ? ".document" : "");
                                        const indexUpdateBefore = "hook." + item + ".update.before" + (documentObj.type === "documents" ? ".document" : "");
                                        const indexUpdateAfter = "hook." + item + ".update.after" + (documentObj.type === "documents" ? ".document" : "");
                                        const indexDeleteBefore = "hook." + item + ".delete.before" + (documentObj.type === "documents" ? ".document" : "");
                                        const indexDeleteAfter = "hook." + item + ".delete.after" + (documentObj.type === "documents" ? ".document" : "");

                                        return (
                                            <div className="col-sm-3" key={"section-" + item} >
                                            <div  className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{item}</h5>
                                                        { /*
                                                        <FormControlLabel
                                                            control={<Switch />}
                                                            checked={documentObj.hooks.includes(indexCreateBefore)}
                                                            onClick={(e) => {
                                                                setDocument((prev) => {

                                                                    const hooks = prev.hooks.filter((item) => {
                                                                        return item !== indexCreateBefore
                                                                    })

                                                                    if (e.target.checked) {
                                                                        hooks.push(indexCreateBefore)
                                                                    }
                                                                   return {
                                                                    ...prev,
                                                                        hooks: hooks
                                                                    }
                                                                })
                                                            }}
                                                            label="Avant création"
                                                        /> */
                                                        }

                                                        <FormControlLabel
                                                            control={<Switch />}
                                                            checked={documentObj.hooks.includes(indexCreateAfter)}
                                                            onClick={(e) => {
                                                                setDocument((prev) => {

                                                                    const hooks = prev.hooks.filter((item) => {
                                                                        return item !== indexCreateAfter
                                                                    })

                                                                    if (e.target.checked) {
                                                                        hooks.push(indexCreateAfter)
                                                                    }
                                                                    return {
                                                                        ...prev,
                                                                        hooks: hooks
                                                                    }
                                                                })
                                                            }}
                                                            label="Après création"
                                                        />

                                                        <FormControlLabel
                                                            control={<Switch />}
                                                            checked={documentObj.hooks.includes(indexUpdateBefore)}
                                                            onClick={(e) => {
                                                                setDocument((prev) => {

                                                                    const hooks = prev.hooks.filter((item) => {
                                                                        return item !== indexUpdateBefore
                                                                    })

                                                                    if (e.target.checked) {
                                                                        hooks.push(indexUpdateBefore)
                                                                    }
                                                                    return {
                                                                        ...prev,
                                                                        hooks: hooks
                                                                    }
                                                                })
                                                            }}
                                                            label="Avant modification"
                                                        />
                                                        <FormControlLabel
                                                            control={<Switch />}
                                                            checked={documentObj.hooks.includes(indexUpdateAfter)}
                                                            onClick={(e) => {
                                                                setDocument((prev) => {

                                                                    const hooks = prev.hooks.filter((item) => {
                                                                        return item !== indexUpdateAfter
                                                                    })

                                                                    if (e.target.checked) {
                                                                        hooks.push(indexUpdateAfter)
                                                                    }
                                                                    return {
                                                                        ...prev,
                                                                        hooks: hooks
                                                                    }
                                                                })
                                                            }}
                                                            label="Après modification"
                                                        />
                                                        <FormControlLabel
                                                            control={<Switch />}
                                                            checked={documentObj.hooks.includes(indexDeleteBefore)}
                                                            onClick={(e) => {
                                                                setDocument((prev) => {

                                                                    const hooks = prev.hooks.filter((item) => {
                                                                        return item !== indexDeleteBefore
                                                                    })

                                                                    if (e.target.checked) {
                                                                        hooks.push(indexDeleteBefore)
                                                                    }
                                                                    return {
                                                                        ...prev,
                                                                        hooks: hooks
                                                                    }
                                                                })
                                                            }}
                                                            label="Avant suppression"
                                                        />
                                                        <FormControlLabel
                                                            control={<Switch />}
                                                            checked={documentObj.hooks.includes(indexDeleteAfter)}
                                                            onClick={(e) => {
                                                                setDocument((prev) => {

                                                                    const hooks = prev.hooks.filter((item) => {
                                                                        return item !== indexDeleteAfter
                                                                    })

                                                                    if (e.target.checked) {
                                                                        hooks.push(indexDeleteAfter)
                                                                    }
                                                                    return {
                                                                        ...prev,
                                                                        hooks: hooks
                                                                    }
                                                                })
                                                            }}
                                                            label="Après suppression"
                                                        />

                                                    </div>
                                            </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>

                                <div>
                                    <textarea name="" id="" cols="30" rows="10" placeholder='{"concessionId":145} ou {"travauxId":' defaultValue={documentObj.moredata}   onChange={(e) => {setDocument((prev) => ({
                                        ...prev,
                                        moredata: e.target.value
                                    }))}}>

                                    </textarea>
                                </div>
                            </fieldset>
                            <div className="" style={{textAlign: 'center', marginTop: '20px'}}>
                                {
                                    documentObj.type === 'documents' ?
                                        <button type="button" className='btn btn-primary mr-2' onClick={() => {
                                            downloadPreviewDocument(documentObj)
                                        }}>Prévisualiser le pdf</button>
                                        : <></>
                                }
                                <button type='submit' className='btn btn-primary mr-2'>
                                    {
                                        documentObj.id ? 'Sauvegarder' : 'Ajouter'
                                    }
                                </button>
                                <Link to="/documents">
                                    <button className='btn btn-primary'> Annuler</button>
                                </Link>
                            </div>
                        </form>
                    </div>
                }
            </div>
        </Fragment>
    );
};

EditDocument.propTypes = {
    updateDocument: PropTypes.func.isRequired,
    getDocument: PropTypes.func.isRequired,
    documentObj: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    document: state.documentObj,
});

export default connect(mapStateToProps, { updateDocument, getDocument })(
    EditDocument
);
