import React, {useEffect, useState} from "react";

const OrderBy = (props) => {

    const [classname, setClass] = useState('fa-sort')
    const [orderBy, setOrderBy] = useState('none')
    const [clicked, setClicked] = useState(false)

    useEffect(() => {
        if(props.isClicked === false && clicked === true) {
            setClicked(false)
            setClass('fa-sort')
            setOrderBy('none')
        }
    });

    const animation = () => {
        switch (orderBy) {
            case 'none' :
            case 'desc' :
                setClass('fa-sort-asc')
                setOrderBy('asc')
                setClicked(true)
                break;
            case 'asc' :
                setClass('fa-sort-desc')
                setOrderBy('desc')
                setClicked(true)
                break;
        }
    }

    return (
        <i className={"fa fa-fw red " + classname} onClick={animation}/>
    )
}
export default OrderBy;