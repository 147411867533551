
export default function DownloadSnappy (response, name, type) {
    let mime = 'application/pdf';
    if (type === 'csv') {
        mime = 'application/csv';
    }
    const file = new Blob([response.data], {
        type: mime
    });
    const fileURL = URL.createObjectURL(file)
    const a       = document.createElement('a')
    a.href     = fileURL
    a.target   = '_blank'
    a.download = name
    document.body.appendChild(a);
    a.click()
    URL.revokeObjectURL(a.href);
}