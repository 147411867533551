import React, {Component, useState} from 'react';
import * as d3 from "d3";
import greenBellIcon from "../../assets/images/green-bell-icon.svg";
import redBellIcon from "../../assets/images/red-bell-icon.svg";
import greyBellIcon from "../../assets/images/grey-bell-icon.svg";
import editIconWhite from "../../assets/images/edit-white-icon.svg";
import refreshPurple from "../../assets/images/refresh-purple-icon.svg";
import backIcon from "../../assets/images/back-icon.svg";
import poubelleIcon from "../../assets/images/archive-icon.svg";
import pdfExportIcon from "../../assets/images/pdf-export-icon-purple.svg";
import deleteIcon from "../../assets/images/delete-icon-purple.svg";
import plusRedButton from "../../assets/images/plus-red-icon.svg";
import {Dropdown} from "react-bootstrap";
import threeDots from "../../assets/images/three-dots-vertical.svg";
import FormAjoutDefunt from "../defunts/FormAjoutDefunt";
import $ from "jquery";
import {connect} from "react-redux";
import ProcedureAccess from "../../components/procedures/ProcedureAccess";
import SupprimerDefunt from "../defunts/SupprimerDefunt";
import {Link} from "react-router-dom";
import DeplacerDefunt from "../../components/concession/DeplacerDefunt";
import GestionPictoDefunt from "../../components/concession/GestionPictoDefunt";
import DateTools from "../../components/utils/DateTools";
import ImagesEmplacement from "../../components/concession/ImagesEmplacement";
import Tools from "../../components/utils/Tools";
import axios from "axios";
import GetPDF from "../../components/utils/GetPDF";
import ShowHistorique from "../../components/utils/ShowHistorique";
import AffichageExclus from "../../components/concession/AffichageExclus";
import ArchiveModal from "../../components/concession/ArchiveModal";
import dayjs from 'dayjs';

class EmplacementOccupeDefault extends Component{
    constructor(props) {
        super(props);
        this.state = {
            placeOccupee : this.props.location.hasOwnProperty('nbPlacesOccupees') ? this.props.location.nbPlacesOccupees : 0,
            placeTotal : this.props.location.hasOwnProperty('nbPlaces') ? this.props.location.nbPlaces : 0,
            defuntDisplay : null,
            facture: null,
            etatAbandon: this.props.location.hasOwnProperty('etatAbandon') ? this.props.location.etatAbandon : null,
            historique: []
        }
    }
    componentWillUnmount() {
        $('body').off('click', '.delete-this-contact')
    }

    async componentDidMount() {
        this.setState({placeRestante : this.state.placeTotal - this.state.placeOccupee})
        this.carrousel()
        $(function () {
            $('[data-toggle="popover"]').popover()
        })

        $('body').on('click', '.delete-this-contact', (e) => {
            this.removeContact($(e.target).data('contact-id'))
        })


        const mapConainer = document.getElementById("map");
        const minimapConainer = document.getElementById("minimap");

        if (!mapConainer || !minimapConainer) {
            return;
        }

        d3.svg(process.env.REACT_APP_API_URL + "/emplacements/emplacement-svg/" + this.props.emplacement.id).then( (xml)=> {


            document.querySelector('#map').appendChild(xml.documentElement.cloneNode(true));
            document.querySelector('#minimap').appendChild(xml.documentElement.cloneNode(true));

            let map = d3.select('#map').select('svg')
            let minimap = d3.select('#minimap').select('svg')
                .attr('width', 200);

            let minimapRect = minimap.append('rect')
                .attr('id', 'minimapRect');


            let transform = d3.zoomIdentity.translate(0, 0).scale(1);
            let zoom = d3.zoom()
                .scaleExtent([0, 3])
                .on('zoom', zoomed);

            map.call(zoom)
                .call(zoom.transform, transform);
            $('#svgmap').on('shown.bs.modal',  (e) =>  {
                let width = parseInt( $('#map svg').width() );
                let height = parseInt( $('#map svg').height() );

                let ratio = width / height;
                let initialScale = width / $('#viewport').width()

                // do something...
                var bbox = document.querySelector('#map [data-id="' + this.props.emplacement.id + '"]').getBBox();
                var scale = 10;
                // Manually calculating the position to which to transition to
                // Will differ on a case by case basis.
                map.transition().duration(3000)
                    .call(zoom.transform, d3.zoomIdentity
                        .translate( (- bbox.x + $('#map').width()/2 )
                            ,- bbox.y + $('#map').height()/2
                        )
                       // .scale(scale)
                    );


            })

            function zoomed(event) {
                let transform = event.transform;
                let modifiedTransform = d3.zoomIdentity.scale( 1/transform.k ).translate( -transform.x, -transform.y );

                let mapMainContainer = map.attr('transform', transform);


                minimapRect
                    .attr('width', mapMainContainer.node().getBBox().width )
                    .attr('height', mapMainContainer.node().getBBox().height )
                    .attr('stroke', '#6D00B5')
                    .attr('stroke-width', 10/modifiedTransform.k )
                    .attr('stroke-dasharray', 10/modifiedTransform.k )
                    .attr('fill', 'none')
                    .attr('transform', modifiedTransform);
            }
        })

    }

    carrousel = () => {
        if (($('.carousel-item').length <= 3 && window.matchMedia("(min-width: 1670px)").matches) || $('.carousel-item').length === 1 ){
            $('.w-auto').hide()
        }
        if ($('.carousel-item').length === 2) {
            $('.card-body').css('width', 'calc(45vw / 2)')
            if(window.matchMedia("(min-width: 1080px)").matches) {
                $('.w-auto').hide()
            }
        }

        $('#recipeCarousel').carousel({
            interval: false,
        })

        $('.carousel .carousel-item').each(function() {
            let minPerSlide = 3;
            let next = $(this).next();
            if (!next.length) {
                next = $(this).siblings(':first');
            }
            next.children(':first-child').clone().appendTo($(this));

            for (let i=0;i<minPerSlide;i++) {
                next=next.next();
                if (!next.length) {
                    next = $(this).siblings(':first');
                }

                next.children(':first-child').clone().appendTo($(this));
            }
        });

        //gerer l'affichage des flèches du carrousel en fonction du nombre de contact et de la taille de l'ecran
        window.matchMedia("(min-width: 1670px)").addEventListener('change', function () {
            if ($('.carousel-item').length === 3) {
                $('.w-auto').hide()
            }
        });

        window.matchMedia("(max-width: 1590px)").addEventListener('change', function () {
            if ($('.carousel-item').length > 2) {
                $('.w-auto').show()
            }
        });

        window.matchMedia("(min-width: 800px)").addEventListener('change', function () {
            if ($('.carousel-item').length > 1) {
                $('.w-auto').show()
            }
        });

        window.matchMedia("(min-width: 1080px)").addEventListener('change', function () {
            if ($('.carousel-item').length === 2) {
                $('.w-auto').hide()
            }
        });
    }

    ouvrirAjoutDefunt = (e) => {
        if(e.target.getAttribute('data-id') !== null) {
            this.setState({defuntDisplay : e.target.getAttribute('data-id') })
        } else {
            const {defuntDisplay} = this.props
            defuntDisplay(null)
            this.setState({defuntDisplay : null })
        }
        document.getElementById('ajout-defunt-fenetre').style.display = "block";
        $('.names-wrapper').css('margin-bottom', '20px');
    }

    fermerAjoutDefunt = (e) => {
        if (e) {
            e.preventDefault();
        }
        document.getElementById('ajout-defunt-fenetre').style.display = "none";
        $('.names-wrapper').css('margin-bottom', '0');
    }

    renderTextePlaces = () => {
        let placeRestante = this.state.placeTotal - this.state.placeOccupee
        if (placeRestante === 0) {
            return '(pleine)'
        }else if (placeRestante === 1) {
            return '(1 place restante)'
        }else if (placeRestante < 0){
            return ''
        }else {
            return '(' + placeRestante + ' places restantes)'
        }
    }

    getColorEtatAbandon = (abandon) => {
        if ([2, 4].includes(abandon) !== false)
            return '#F38D20';
        if ([5, 6].includes(abandon) !== false)
            return '#EF2F16';

        return '#45CB5A';
    }

    formatDate = (date) => {
        const d = new Date(date);
        return ('0' + d.getDate()).slice(-2) + '/'
            + ('0' + (d.getMonth()+1)).slice(-2) + '/'
            + d.getFullYear();
    }

    verifDateFin = (date, perpetuelle = false) => {
        let statut = 'En cours'
        if (date) {
            const d = new Date(date.substr(0, 10))
            const dateMaintenant = new Date()
            const echeanceRenouvellement = new Date(date.substr(0, 10))
            echeanceRenouvellement.setFullYear(echeanceRenouvellement.getFullYear() + 2)
            if(d < dateMaintenant && dateMaintenant < echeanceRenouvellement) {
                statut = 'A renouveler'
            } else if (d < dateMaintenant){
                statut = 'Échue'
            }
        } else {
            if(perpetuelle) {
                return 'En cours'
            }
            statut = 'Données manquantes'
        }
        return statut
    }

    handlePlaceOccupee = (nb, action) => {
        let placeOccupee = this.state.placeOccupee
        if (action === 'add') {
            this.setState({placeOccupee : placeOccupee + nb})
        } else {
            this.setState({placeOccupee : placeOccupee - nb})
        }
    }

    updateFacture = (e, id) => {
        axios.put(process.env.REACT_APP_API_URL + '/locations/' + id + "/facture", {
            facture : e.target.value
        }).catch()
        this.setState({facture : e.target.value})
    }
    updateEtatAbandonn = (e, id) => {
        axios.put(process.env.REACT_APP_API_URL + '/locations/' + id + "/etatabandon", {
            etatAbandon : parseInt(e.target.value)
        }).catch()
        this.setState({etatAbandon : parseInt(e.target.value)})
    }

    generate = (slug) => {
        if (!this.props.location.dateDebut || dayjs().isSame(dayjs(this.props.location.dateDebut), 'day') || window.confirm("Etes vous sûr de vouloir générer un acte dont la concession n'as pas été fraichement créee ?"))
        {
            axios.post(process.env.REACT_APP_API_URL + '/documents/generate', {
                slug: slug,
                concessionId: this.props.location.id
            }).then(() => {
                window.location.reload();
            })
        }
    }

    setArchive = (locationId) => {
        if (window.confirm('Etes vous sûr de vouloir archiver cette concession ?')) {
            axios.post(process.env.REACT_APP_API_URL + '/locations/location_archive/' + locationId, {}).then(() => {
                window.location.reload();
            })
        }
    }

    setUnarchive = (locationId) => {
        if (window.confirm('Etes vous sûr de vouloir désarchiver cette concession ?')) {
            axios.post(process.env.REACT_APP_API_URL + '/locations/location_unarchive/' + locationId, {}).then(() => {
                window.location.reload();
            })
        }
    }

    deleteLocation = (locationId) => {
        if (window.confirm('Etes vous sûr de vouloir supprimer cette concession ?')) {
            axios.delete(process.env.REACT_APP_API_URL + '/locations/' + locationId, {}).then(() => {
                window.location.reload();
            })
        }
    }

    removeContact = (contact) => {
        if (window.confirm('Etes vous sûr de vouloir supprimer ce contact ?')) {
            axios.delete(process.env.REACT_APP_API_URL + '/contacts/' + contact, {}).then(() => {
                window.location.reload();
            })
        }
    }

    render() {
        let defunts
        let occupants
        let queryOccupant = JSON.parse(this.props.defunt.defuntListe)
        if (queryOccupant !== null) {
            occupants = queryOccupant
        } else {
            occupants = []
        }
        if (this.state.placeOccupee !== 0 || occupants.length > 0) {
            defunts = occupants.map((defunt) => {
                let dateNaissanceIncomplete = null
                if (defunt.hasOwnProperty('dateNaissanceIncomplete')) {
                    dateNaissanceIncomplete = defunt.dateNaissanceIncomplete
                }
                return (
                    <div className="soleilLight-fonts" key={defunt.id}>
                        <div className="d-flex">
                            <div className="px-1">
                                <GestionPictoDefunt defuntId = {defunt.id}/>
                            </div>
                            <div>
                                {(defunt.nom || defunt.prenom) ?
                                    <a className="select-defunt cursor" key={defunt.id} data-id={defunt.id} onClick={this.ouvrirAjoutDefunt}>
                                        {defunt.civilite === 'F' ? 'Mme ' : 'M.'}&nbsp;
                                        {defunt.nom ? defunt.nom : '-'}&nbsp;
                                        {defunt.nomJeuneFille && defunt.nomJeuneFille !== "" ? 'né(e) ' + defunt.nomJeuneFille : ''}&nbsp;
                                        {defunt.prenom}
                                    </a>
                                    :
                                    <a className="select-defunt cursor" data-id={defunt.id} onClick={this.ouvrirAjoutDefunt}>Inconnu</a>
                                }
                                <p>{DateTools.getDateDefunt(defunt.dateDeNaissance, defunt.dateDeces, dateNaissanceIncomplete)}</p>
                            </div>
                        </div>
                    </div>
                )
            })
        }

        let infosTypeEmplacement = {
            'Enfeu' : 'Un enfeu est une niche funéraire hors sol, ménagée dans l\'épaisseur d\'un mur ou d\'une roche et destinée à accueillir des cercueils.',
            'Concession' : 'Une concession est un terrain, concédé par la commune à l’intérieur du cimetière à une personne désirant y fonder une sépulture.',
            'Cavurne' : 'Le cavurne est un caveau de petites dimensions généralement en béton préfabriqué et destiné à recevoir l’inhumation d’une ou de plusieurs urnes.',
            'Colombarium' : 'Un colombarium est un monument cinéraire hors-sol composé de cases. Il fait partie des éléments indispensables\n' +
                'à la création d’un espace cinéraire. Les cases du columbarium permettent en effet d’accueillir, suite à une crémation, l’urne contenant les cendres du défunt.'
        }

        let infosContactType = {
            'Concessionnaire' : 'Le concessionnaire est seul régulateur de la concession (Cours de Cassation. 1re civ. , ' +
                '17 déc. 2008, n° 07-17.596). Il peut exclure ou autoriser les inhumations de parents ou amis dans sa concession ' +
                'familiale ou collective, et décider du changement de type de concession. Il peut exclure toute personne de la ' +
                'concession (même son époux/se), de son vivant, et aussi après son décès via l’établissement d’un testament régissant ' +
                'ses dernières volontés.',
            'Co-concessionnaire'  : 'Un co-concessionnaire peut être un membre de la famille (conjoint(e), enfant(s),\n' +
                'parent(s), etc) ou toute autre personne proche du concessionnaire.Le co-concessionnaire possède\n' +
                'les mêmes droits que le concessionnaire sur la concession et peut donc modifier le contrat de concession.',
            'Ayant-droit' : 'Descendants des concessionnaires qui par lien du sang bénéficie de droits sur la concession.',
            'Successeur'  : '',
            'Contact de secours' : '',
            'Personne chargee de l\'entretien' : ''

        }

        let contactsHtml = this.props.location.contacts.map((contact, i) => {
            return (
                <div className={i === 0 ? "carousel-item active" : "carousel-item"} >
                    <div>
                        <div className="card card-body">
                            <div className="d-flex align-items-center">
                                <div className="title soleilBold-fonts">{contact.typeDeContact}
                                    <span
                                        className="info cursor" data-container="body" data-toggle="popover"
                                        data-trigger="hover" data-placement="top"
                                        data-content={infosContactType[contact.typeDeContact]}>
                                    </span>
                                </div>
                            </div>
                            <div className="text soleilBold-fonts mb-1">
                                {contact.civilite === 'F' ? 'Mme ' : 'M.'} &nbsp;
                                {contact.prenom} &nbsp;
                                {contact.nom}&nbsp;
                                {contact.nomJeuneFille && contact.nomJeuneFille !== "" ? ' né(e) ' + contact.nomJeuneFille : ''}&nbsp;
                                {contact.decede ? '(décédé(e))' : ''}<br/>
                                {contact.adresse} {contact.commune} {contact.codePostal}<br/>
                                {contact.telephone} <br/>
                                {contact.email} <br/>
                                {
                                    !this.props.location.isArchived
                                    ? <a data-contact-id={contact.id} className={"soleilLight-fonts red cursor delete-this-contact underline w-100 text-left"}>Supprimer ce contact</a>
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

        let image = greenBellIcon
        if(this.props.location.dateFin && this.verifDateFin(this.props.location.dateFin, this.props.location.perpetuelle)  === 'Échue') {
            image = redBellIcon
        } else {
            if (!this.props.location.dateFin && !this.props.location.perpetuelle) {
                image = greyBellIcon
            }
        }

        let emails = []
        if (this.props.location && this.props.location.mailArchives) {
            emails = this.props.location.mailArchives.map(((email, index) => (
                <div className="d-flex mt-2" key={index}>
                    <div className="col-8 pl-0 soleilRegular-fonts">{email.objet}</div>
                    <div className="col-2 pl-0 soleilRegular-fonts">{email.dateEnvoi ? DateTools.formatDate(email.dateEnvoi) : '-'}</div>
                </div>
            )))
        }

        let cssMap = `
        .emplacement {
                        fill: white;
                    }
                        .emplacement[data-id="${ this.props.emplacement.id }"] {
                        fill: #D64C63 !important;
                        opacity: 0.8 !important;
                    }
                    `


        return (
            <React.Fragment>
                <div className="px-3 px-lg-4 scroll" id="fiche_en_cours">
                    <div className={"durand-family-wrapper container-fluid pb-2 " +
                    (this.props.location.dateFin && this.verifDateFin(this.props.location.dateFin, this.props.location.perpetuelle) === 'Échue' ? 'echue' :
                        !this.props.location.dateFin && !this.props.location.perpetuelle ? 'noData' :'')}>
                        <div className="row py-2 align-items-center">
                            <div className="col-md-4 title soleilBold-fonts text-center text-md-left">
                                <img src={image} alt="" className="img-fluid mr-3" width="40px" />
                                Famille {this.props.location.famille}
                                <span
                                    className="info cursor mt-3 ml-3" data-container="body" data-toggle="popover"
                                    data-trigger="hover" data-placement="top"
                                    data-content={infosTypeEmplacement[this.props.location.type]}>
                                </span>
                            </div>
                            <div className="col-md-8 mt-3 mt-md-0">
                                <div className="mx-auto ml-md-auto mr-md-0 durand-buttons">

                                    {
                                        !this.props.location.isArchived ?
                                            <button type="button" className="active step-btn editer-location-btn" data-toggle="modal"
                                                    data-target="#etape1" id="1">
                                                <img className="active step-btn img-fluid mr-2" data-toggle="modal"
                                                     data-target="#etape1" id="1" src={editIconWhite} alt=""/>
                                                éditer concession
                                            </button>
                                            :
                                            <>
                                                <Link to={{pathname: "/fiche-concession/" + this.props.emplacement.id}}>
                                                    <button type="button" className="active step-btn editer-location-btn" >
                                                        <img src={backIcon} alt="" className="img-fluid mr-2"/>
                                                        revenir
                                                    </button>
                                                </Link>
                                            </>

                                    }
                                        {
                                            !this.props.location.isArchived ?
                                                <>
                                                    <button type="button" className="historique-location-btn" onClick={() => {
                                                        this.setArchive(this.props.location.id)
                                                    }}>
                                                        <img src={poubelleIcon} alt="" className="img-fluid mr-2"/>
                                                        archiver
                                                    </button>
                                                </>
                                                    :
                                                this.props.emplacement.nbLocations === 0 ?
                                                <>
                                                    <button type="button" className="historique-location-btn" onClick={() => {
                                                        this.setUnarchive(this.props.location.id)
                                                    }}>
                                                        <img src={poubelleIcon} alt="" className="img-fluid mr-2"/>
                                                        Désarchiver
                                                    </button>
                                                </>
                                                    : ''
                                        }
                                        {
                                            this.props.emplacement.nbLocationArchives > 0 ?
                                        <button type="button" className="historique-location-btn"  data-toggle="modal"
                                                data-target="#show-historique" onClick={() => {
                                            ShowHistorique(this.props.emplacement.id, {setEmplacementInfo: (info) => {
                                                    this.setState({historique: info})
                                                }})
                                        }}>
                                            <img src={refreshPurple} alt="" className="img-fluid mr-2"/>
                                            historique
                                        </button>
                                                : ''
                                        }
                                    <button type="button" className="exporter-location-btn" onClick={() => GetPDF('location', this.props.location.id)}>
                                        <img src={pdfExportIcon} alt=""
                                             className="img-fluid mr-2"/>
                                        exporter
                                    </button>
                                    {
                                        !this.props.location.isArchived ?
                                            <button type="button" className="delete-location-btn" onClick={() => this.deleteLocation(this.props.location.id) }>
                                                <img src={deleteIcon} alt="" className="img-fluid"/>
                                            </button>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row" id="infos_concession">
                            <div className="col-lg-3 progress-one mt-2" id="tour-infos-step">
                                {
                                    this.verifDateFin(this.props.location.dateFin, this.props.location.perpetuelle) === 'En cours'?
                                        <div className="sub-title-1 soleilBold-fonts"> En cours</div>
                                        :
                                        this.verifDateFin(this.props.location.dateFin, this.props.location.perpetuelle) === 'A renouveler' ?
                                            this.props.emplacement ?
                                                <Link to={{
                                                    pathname: "/renouvellement/" + this.props.emplacement.id,
                                                }} className="col-sm-4 pl-0 soleilRegular-fonts black text-decoration-none">
                                                    <div className="sub-title-1 soleilBold-fonts">A renouveler</div>
                                                </Link> : ''
                                            :
                                            this.props.location.dateFin ?
                                                <div className="sub-title-1 red-circle soleilBold-fonts">Échue</div> :''
                                }
                                {
                                    !this.props.location.dateFin && !this.props.location.perpetuelle ?
                                        <div className="sub-title-1 grey-circle soleilBold-fonts">Données manquantes</div>
                                        :
                                        ''
                                }
                                <div className="item soleilRegular-fonts mt-2">Concession n°<strong
                                    className="soleilBold-fonts">{this.props.location.numero}</strong></div>
                                <div className="item soleilRegular-fonts">Durée :&nbsp;
                                    <strong className="soleilBold-fonts">
                                        {this.props.location.perpetuelle === 1 || this.props.location.perpetuelle === "1" ? "Perpétuelle" :
                                            this.props.location.duree !== null && this.props.location.duree < 999 ? this.props.location.duree + ' ans' : 'inconnue'
                                        }
                                    </strong>
                                </div>
                                <div className="item soleilRegular-fonts">Type :&nbsp;
                                    <strong className="soleilBold-fonts">
                                        {this.props.location.typeConcession}
                                    </strong>
                                    <span
                                        className="info cursor" data-container="body" data-toggle="popover"
                                        data-trigger="hover" data-placement="top" data-html="true"
                                        data-content={AffichageExclus(this.props.location)}
                                    >
                                </span>
                                </div>
                                <div className={this.state.placeRestante <= 0 ? "item soleilRegular-fonts"
                                    : "item soleilRegular-fonts green-tick"}>Capacité :&nbsp;
                                    <strong className="soleilBold-fonts" >
                                        { this.state.placeTotal !== 0 && this.state.placeTotal ? this.state.placeOccupee + '/'+ this.state.placeTotal + this.renderTextePlaces(): 'inconnue'}&nbsp;
                                        <span
                                            className="info cursor" data-container="body" data-toggle="popover"
                                            data-trigger="hover" data-placement="top"
                                            data-content="Le nombre de place est donné à titre indicatif">
                                        </span>
                                    </strong>
                                </div>
                                {this.props.location.dateOriginelle ? (
                                        <div>
                                            <div className="item soleilRegular-fonts">Date d'origine :&nbsp;
                                                <strong className="soleilBold-fonts">
                                                    {this.formatDate(this.props.location.dateOriginelle.substr(0, 10))}
                                                </strong>
                                            </div>
                                            <div className="item soleilRegular-fonts">Début renouvellement :&nbsp;
                                                <strong className="soleilBold-fonts">
                                                    {this.formatDate(this.props.location.dateDebut.substr(0, 10))}
                                                </strong>
                                            </div>
                                            <div className="item soleilRegular-fonts">Fin renouvellement :&nbsp;
                                                <strong className="soleilBold-fonts">
                                                    {this.formatDate(this.props.location.dateFin.substr(0, 10))}
                                                </strong>
                                            </div>
                                        </div>
                                    ) :
                                    (
                                        <div>
                                            <div className="item soleilRegular-fonts">Date de début :&nbsp;
                                                <strong className="soleilBold-fonts">
                                                    { this.props.location.dateDebut !== null ?
                                                        this.formatDate(this.props.location.dateDebut.substr(0, 10)) : ' inconnue'}
                                                </strong>
                                            </div>
                                            {
                                                this.props.location.perpetuelle != 1 && this.props.location.duree < 999 ?
                                            <div className={this.verifDateFin(this.props.location.dateFin, this.props.location.perpetuelle) === 'En cours' ?
                                                "item soleilRegular-fonts green-tick" : "item soleilRegular-fonts red-tick"}>Date de fin :&nbsp;
                                                <strong className="soleilBold-fonts" >
                                                    { this.props.location.dateFin !== null ?
                                                        this.formatDate(this.props.location.dateFin.substr(0, 10))
                                                        : ' inconnue'}
                                                </strong>
                                            </div>
                                                : ''
                                            }
                                        </div>
                                    )
                                }
                                <div className="item d-flex align-items-center">
                                    <div className="soleilRegular-fonts">Facture :&nbsp;</div>
                                    <div className="col-2 p-0 select-facture-wrapper">
                                        <select className=" soleilRegular-fonts select-facture mt-1" value={this.state.facture ? this.state.facture : this.props.location.facture} onChange={(e) => this.updateFacture(e, this.props.location.id)}>
                                            <option value="">Non renseignée</option>
                                            <option value="réglée">Réglée</option>
                                            <option value="non réglée">Non réglée</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="item d-flex align-items-center">
                                    <div className="soleilRegular-fonts">
                                        { !this.state.etatAbandon || this.state.etatAbandon < 3 ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.621" height="11.682" viewBox="0 0 15.621 11.682"  style={{
                                                position: 'absolute',
                                                left: '-22px',
                                            }}>
                                                <path id="green-tick-icon" d="M1761,500l5,5,8.5-8.5" transform="translate(-1759.939 -495.439)" fill="none" stroke={this.getColorEtatAbandon(this.state.etatAbandon ? this.state.etatAbandon : this.props.location.etatAbandon)} stroke-miterlimit="10" stroke-width="3"/>
                                            </svg>
                                            :
                                            this.state.etatAbandon === 6 ?
                                            <svg viewBox="0 0 1280 1126" xmlns="http://www.w3.org/2000/svg" style={{
                                                position: 'absolute',
                                                width: '15px',
                                                height: '15px',
                                                left: '-22px',
                                                zIndex: 2
                                            }}>
                                            <g transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, 0, 1126)">
                                            <path style={{fill: this.getColorEtatAbandon(this.state.etatAbandon ? this.state.etatAbandon : this.props.location.etatAbandon)}} d="M6201,11240c-41-10-113-37-160-61c-70-35-105-62-187-144c-61-60-124-134-157-185c-85-132-681-1182-2962-5215 C1942,4233,1021,2603,688,2015C355,1426,71,917,57,884c-79-187-72-394,19-559c15-28,64-86,108-130c91-90,177-139,306-175l76-20 l5879,2l5880,3l81,27c363,124,494,499,304,878c-21,43-899,1580-1951,3417C9707,6163,8451,8356,7968,9200 c-484,844-909,1580-946,1635c-118,177-268,311-419,373C6478,11260,6331,11272,6201,11240z M7808,7830c793-1383,2019-3523,2724-4755 l1283-2240l-2712-3c-1492-1-3934-1-5427,0l-2715,3l1666,2945c3188,5637,3725,6583,3734,6572C6365,10348,7016,9213,7808,7830z"/>
                                            <path style={{fill: this.getColorEtatAbandon(this.state.etatAbandon ? this.state.etatAbandon : this.props.location.etatAbandon)}} d="M 5284.129 6982.88 C 5273.555 6980.614 5238.057 6972.307 5205.581 6963.999 C 4911.026 6889.982 4672.362 6606.002 4672.362 6331.839 C 4672.362 6297.097 4688.978 6140.001 4710.125 5983.661 C 4730.517 5827.32 4795.47 5336.396 4853.626 4891.544 C 5005.435 3732.207 5003.924 3745.802 5016.764 3697.465 C 5048.485 3572.846 5123.257 3473.151 5221.441 3424.813 C 5272.044 3399.89 5286.394 3396.113 5348.326 3393.847 C 5463.127 3388.561 5534.122 3416.505 5611.16 3496.564 C 5685.931 3575.867 5719.918 3665.744 5744.087 3847.008 C 5752.395 3910.451 5790.158 4195.942 5827.922 4482.188 C 5864.93 4767.68 5926.862 5234.435 5963.87 5520.682 C 6001.633 5806.173 6039.397 6091.664 6047.704 6155.107 C 6065.831 6292.565 6066.586 6385.463 6050.726 6447.395 C 5988.794 6691.347 5778.829 6899.801 5527.325 6965.509 C 5470.68 6980.614 5326.424 6990.433 5284.129 6982.88 Z" />
                                            <path style={{fill: this.getColorEtatAbandon(this.state.etatAbandon ? this.state.etatAbandon : this.props.location.etatAbandon)}} d="M 7559.436 2867.415 C 7214.436 2823.415 6965.436 2496.415 7007.436 2141.415 C 7027.436 1975.415 7093.436 1840.415 7211.436 1731.415 C 7325.436 1624.415 7448.436 1571.415 7602.436 1561.415 C 7789.436 1550.415 7938.436 1608.415 8077.436 1748.415 C 8211.436 1882.415 8269.436 2021.415 8270.436 2213.415 C 8271.436 2329.415 8257.436 2396.415 8212.436 2493.415 C 8092.436 2754.415 7833.436 2902.415 7559.436 2867.415 Z"/>
                                            <path style={{fill: this.getColorEtatAbandon(this.state.etatAbandon ? this.state.etatAbandon : this.props.location.etatAbandon)}} d="M 7552.777 6974.366 C 7542.309 6972.123 7507.167 6963.898 7475.016 6955.674 C 7183.411 6882.398 6947.136 6601.262 6947.136 6329.844 C 6947.136 6295.45 6963.585 6139.928 6984.521 5985.153 C 7004.71 5830.378 7069.012 5344.369 7126.585 4903.972 C 7276.874 3756.245 7275.379 3769.704 7288.089 3721.851 C 7319.493 3598.48 7393.516 3499.783 7490.718 3451.93 C 7540.813 3427.256 7555.02 3423.517 7616.332 3421.274 C 7729.983 3416.04 7800.267 3443.705 7876.533 3522.961 C 7950.555 3601.47 7984.203 3690.448 8008.129 3869.896 C 8016.354 3932.703 8053.739 4215.336 8091.124 4498.716 C 8127.761 4781.348 8189.073 5243.429 8225.711 5526.81 C 8263.096 5809.442 8300.482 6092.074 8308.706 6154.882 C 8326.651 6290.964 8327.398 6382.931 8311.697 6444.243 C 8250.385 6685.751 8042.523 6892.118 7793.538 6957.169 C 7737.46 6972.123 7594.648 6981.843 7552.777 6974.366 Z" />
                                            <path style={{fill: this.getColorEtatAbandon(this.state.etatAbandon ? this.state.etatAbandon : this.props.location.etatAbandon)}} d="M 5309.526 2870.519 C 4964.526 2826.519 4715.526 2499.519 4757.526 2144.519 C 4777.526 1978.519 4843.526 1843.519 4961.526 1734.519 C 5075.526 1627.519 5198.526 1574.519 5352.526 1564.519 C 5539.526 1553.519 5688.526 1611.519 5827.526 1751.519 C 5961.526 1885.519 6019.526 2024.519 6020.526 2216.519 C 6021.526 2332.519 6007.526 2399.519 5962.526 2496.519 C 5842.526 2757.519 5583.526 2905.519 5309.526 2870.519 Z"/>
                                            </g>
                                            </svg>
                                                :
                                        <svg version="1.1" id="Calque_1"
                                             viewBox="0 0 1280 1126" style={{
                                            position: 'absolute',
                                            width: '15px',
                                            height: '15px',
                                            left: '-22px',
                                        }}>
                                            <g transform="translate(0.000000,1126.000000) scale(0.100000,-0.100000)">
	<path style={{fill: this.getColorEtatAbandon(this.state.etatAbandon ? this.state.etatAbandon : this.props.location.etatAbandon)}} className="st0" d="M6201,11240c-41-10-113-37-160-61c-70-35-105-62-187-144c-61-60-124-134-157-185c-85-132-681-1182-2962-5215
		C1942,4233,1021,2603,688,2015C355,1426,71,917,57,884c-79-187-72-394,19-559c15-28,64-86,108-130c91-90,177-139,306-175l76-20
		l5879,2l5880,3l81,27c363,124,494,499,304,878c-21,43-899,1580-1951,3417C9707,6163,8451,8356,7968,9200
		c-484,844-909,1580-946,1635c-118,177-268,311-419,373C6478,11260,6331,11272,6201,11240z M7808,7830c793-1383,2019-3523,2724-4755
		l1283-2240l-2712-3c-1492-1-3934-1-5427,0l-2715,3l1666,2945c3188,5637,3725,6583,3734,6572C6365,10348,7016,9213,7808,7830z"/>
                                                <path  style={{fill: this.getColorEtatAbandon(this.state.etatAbandon ? this.state.etatAbandon : this.props.location.etatAbandon)}} className="st0" d="M6290,7874c-14-3-61-14-104-25c-390-98-706-474-706-837c0-46,22-254,50-461c27-207,113-857,190-1446
		c201-1535,199-1517,216-1581c42-165,141-297,271-361c67-33,86-38,168-41c152-7,246,30,348,136c99,105,144,224,176,464
		c11,84,61,462,111,841c49,378,131,996,180,1375c50,378,100,756,111,840c24,182,25,305,4,387c-82,323-360,599-693,686
		C6537,7871,6346,7884,6290,7874z"/>
                                                <path  style={{fill: this.getColorEtatAbandon(this.state.etatAbandon ? this.state.etatAbandon : this.props.location.etatAbandon)}} className="st0" d="M6322,2739c-345-44-594-371-552-726c20-166,86-301,204-410c114-107,237-160,391-170c187-11,336,47,475,187
		c134,134,192,273,193,465c1,116-13,183-58,280C6855,2626,6596,2774,6322,2739z"/>
</g>
</svg>
                                        }

                                        Etat :&nbsp;</div>
                                    <div className="col-2 p-0 select-facture-wrapper">
                                        <select className=" soleilRegular-fonts select-facture mt-1" value={this.state.etatAbandon ? this.state.etatAbandon : this.props.location.etatAbandon} onChange={(e) => this.updateEtatAbandonn(e, this.props.location.id)}>
                                            <option value="">Non renseigné</option>
                                            <option value={1}>Bon état - Entretien visible</option>
                                            <option value={2}>Bon état - Aucun Entretien visible</option>
                                            <option value={3}>Abandonnée - Aucun danger visible</option>
                                            <option value={4}>Abandonnée - Peu dangereuse</option>
                                            <option value={5}>Abandonnée - Dangereuse à très dangereuse</option>
                                            <option value={6}>Péril</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 mt-2 text-center">
                                <ImagesEmplacement
                                    emplacement = {this.props.defunt.emplacement ? this.props.defunt.emplacement : null}
                                />
                            </div>
                            <div className="col-lg-4 mt-2 deceased-list d-flex pt-4 pb-1">
                                <div className="col-12 text soleilRegular-fonts">
                                    <div className="d-flex align-items-center mb-1">
                                        <strong className="soleilBold-fonts">{defunts ? defunts.length : '0'} défunt(s) </strong>
                                        {
                                            this.props.location.isArchived ? '' :
                                                <img src={plusRedButton} id="ajout-defunt" alt=""
                                                     className="img-fluid ml-2 mb-1 cursor" onClick={this.ouvrirAjoutDefunt}
                                                />
                                        }

                                    </div>
                                    <div id="list-defunts">
                                        {defunts}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 map-wrapper-cont mt-md-2">
                                <img src={process.env.REACT_APP_API_URL + "/emplacements/emplacement-svg/" + this.props.emplacement.id } alt={""} data-toggle="modal"
                                     data-target="#svgmap" />
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid" id="ajout-defunt-fenetre">
                        <div className="row pb-3">
                            <div className="col-12 d-flex justify-content-end mt-3">
                                <Dropdown>
                                    <Dropdown.Toggle id="#menu-trois-points" className="menu-trois-points">
                                        <img src={threeDots} alt=""/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item className="soleilRegular-fonts purple" onClick={this.fermerAjoutDefunt}>Fermer la
                                            fenêtre du défunt
                                        </Dropdown.Item>
                                        <DeplacerDefunt
                                            removeDefunt = {this.props.removeDefunt}
                                        />
                                        <SupprimerDefunt
                                            removeDefunt = {this.props.removeDefunt}
                                            fermerAjoutDefunt = {this.fermerAjoutDefunt}
                                            defunt ={this.props.defunt.defunt ? this.props.defunt.defunt : null}
                                            emplacementId = {this.props.defunt.emplacement ? this.props.defunt.emplacement.id : null}
                                            handlePlaceOccupee = {this.handlePlaceOccupee}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="col-12 d-flex flex-wrap justify-content-center mb-2 px-0">
                                <FormAjoutDefunt location = {this.props.location} defuntId = {this.state.defuntDisplay} handlePlaceOccupee = {this.handlePlaceOccupee}/>
                            </div>
                        </div>
                    </div>
                    <div className="row step-2">
                        <div className="col-md-8 mt-4 pl-5-extra">
                            <ProcedureAccess emplacement = {this.props.emplacement} location = {this.props.location} procedures = {this.props.procedures} />
                            <div className="names-wrapper py-4 px-4">
                                <div className="d-flex align-items-center">

                                    {
                                        !this.props.location.isArchived ?
                                        <img src={plusRedButton} data-toggle="modal"
                                             data-target="#etape1" id="none" alt="" className="img-fluid ml-2 mb-1 cursor step-btn"
                                             onClick={() => {
                                                 Tools.displaySpecificStep('etape1', 2)
                                                 $('.add_item_link').val('oui').change()
                                                 setTimeout(() => {
                                                    $('.scroll-popup').animate({'scrollTop': $('#attribution_emplacement_contacts').height() + 100})

                                                 }, 800)
                                             }}/>
                                            : ''
                                    }
                                </div>
                                <div id="recipeCarousel" className="carousel slide w-100" data-ride="carousel">
                                    <div className="carousel-inner w-100" role="listbox">
                                        {contactsHtml}
                                    </div>
                                    {
                                        this.props.location && this.props.location.contacts && (this.props.location.contacts.length > 2 || (this.props.location.contacts.length === 2 && window.innerWidth < 1200)) ?
                                            <>
                                                <a className="carousel-control-prev w-auto" href="#recipeCarousel" role="button"
                                                   data-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                                <a className="carousel-control-next w-auto" href="#recipeCarousel" role="button"
                                                   data-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                    <span className="sr-only">Next</span>
                                                </a>
                                            </>
                                            :''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 my-3">
                            <div className="documents-wrapper">
                                <div className="d-flex align-items-center">
                                    <p className="title soleilRegular-fonts mb-0">DOCUMENTS</p>
                                    {
                                        this.props.location.isArchived ? '' :
                                        <img src={plusRedButton} alt="ajout-document"
                                             data-toggle="modal" data-target="#etape1" id="none"
                                             className="cursor img-fluid ml-2 mb-1"
                                             onClick={() => Tools.displaySpecificStep('etape1', 5)}/>
                                    }
                                </div>
                                <div className="pl-5-extra pt-2 pb-2 notif_concession">
                                    <div className={"mt-2 position-relative " + (this.props.location.demandeConcession ? 'green-tick' : 'red-tick')}>
                                        <div className="col-12 pl-0 soleilRegular-fonts">Demande</div>
                                        {
                                            this.props.location.demandeConcession ?
                                                <div className="col-12 pl-0 soleilBold-fonts d-flex flex-wrap">
                                                    <a href={(process.env.REACT_APP_PUBLIC_DIRECTORY + 'documents/' + this.props.location.demandeConcession)} className="purple soleilRegular-fonts mr-2">consulter</a>
                                                    <a className="purple soleilRegular-fonts mr-2">modifier</a>
                                                    <a className="red soleilRegular-fonts">supprimer</a>
                                                </div>
                                                :
                                                <div className="col-12 pl-0 soleilBold-fonts d-flex flex-wrap">
                                                    <a className="latoRegular-fonts btn-acte cursor mt-1">Télécharger une archive
                                                        pdf</a>
                                                </div>
                                        }
                                    </div>
                                    <div className={"mt-2 position-relative " + (this.props.location['acte-de-concession'] ? 'green-tick' : 'red-tick')}>
                                        <div className="col-12 pl-0 soleilRegular-fonts">Acte</div>
                                        {
                                            this.props.location['acte-de-concession'] ?
                                                <div className="col-12 pl-0 soleilBold-fonts d-flex flex-wrap">
                                                    <a href={(process.env.REACT_APP_FILES_URL + this.props.location['acte-de-concession'])} target="_blank" className="purple soleilRegular-fonts mr-2">consulter</a>
                                                    <a className="purple soleilRegular-fonts mr-2 d-none">modifier</a>
                                                    <a className="red soleilRegular-fonts d-none">supprimer</a>
                                                    <a onClick={() => this.generate('acte-creation-de-concession')} className="latoRegular-fonts btn-acte mr-1 cursor mt-1">Forcer la généreration d'un acte</a>
                                                </div>
                                                :
                                                <div className="col-12 pl-0 soleilBold-fonts d-flex flex-wrap">
                                                    {
                                                        this.props.location.isArchived ? '' :
                                                        <a onClick={() => this.generate('acte-creation-de-concession')} className="latoRegular-fonts btn-acte mr-1 cursor mt-1">Générer un acte</a>
                                                    }
                                                    <a className="latoRegular-fonts btn-acte cursor mt-1">Télécharger une archive
                                                        pdf</a>
                                                </div>
                                        }
                                    </div>
                                    <div className={"mt-2 position-relative " + (this.props.location['titre-de-recette'] ? 'green-tick' : 'red-tick')}>
                                        <div className="col-12 pl-0 soleilRegular-fonts">Titre provisoire</div>
                                        {
                                            this.props.location['titre-de-recette']  ?
                                                <div className="col-12 pl-0 soleilBold-fonts d-flex flex-wrap">
                                                    <a href={(process.env.REACT_APP_FILES_URL +  this.props.location['titre-de-recette'])}  className="purple soleilRegular-fonts mr-2" target="_blank">consulter</a>
                                                    <a className="purple soleilRegular-fonts mr-2 d-none">modifier</a>
                                                    <a className="red soleilRegular-fonts d-none">supprimer</a>
                                                </div>
                                                :
                                                <div className="col-12 pl-0 soleilBold-fonts d-flex flex-wrap">
                                                    {
                                                        this.props.location.isArchived ? '' :
                                                        <a onClick={() => this.generate('titre-de-recette-apres-creation-concession')}  className="latoRegular-fonts btn-acte mr-1 cursor mt-1">Générer un acte</a>
                                                    }
                                                    <a className="latoRegular-fonts btn-acte cursor mt-1">Télécharger une archive
                                                        pdf</a>
                                                </div>
                                        }
                                    </div>
                                    {
                                        this.props.location.documents?.map((doc) => {
                                            return (
                                                <div className={"mt-2 position-relative green-tick"}>
                                                    <div className="col-12 pl-0 soleilRegular-fonts">{doc.nom}</div>

                                                        <div className="col-12 pl-0 soleilBold-fonts d-flex flex-wrap">
                                                            <a href={(process.env.REACT_APP_FILES_URL +  doc.genericFile)}  className="purple soleilRegular-fonts mr-2" target="_blank">consulter</a>
                                                            <a className="purple soleilRegular-fonts mr-2 d-none">modifier</a>
                                                            <a className="red soleilRegular-fonts d-none">supprimer</a>
                                                        </div>

                                                </div>

                                            );
                                        })
                                    }
                                    {
                                        this.props.location.documentsLocation?.map((doc) => {
                                            return (
                                                <div className={"mt-2 position-relative green-tick"}>
                                                    <div className="col-12 pl-0 soleilRegular-fonts">{doc.description}</div>

                                                    <div className="col-12 pl-0 soleilBold-fonts d-flex flex-wrap">
                                                        <a href={(process.env.REACT_APP_FILES_URL + "documents/" + doc.nom)}  className="purple soleilRegular-fonts mr-2" target="_blank">consulter</a>
                                                    </div>

                                                </div>

                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className="emails-wrapper">
                                <div className="d-flex align-items-center mt-4">
                                    <p className="title soleilRegular-fonts mb-0">DERNIER(S) E-MAIL(S) ENVOYÉ(S) À LA MAIRIE</p>
                                </div>
                                <div className="px-3 pt-1 pb-2 notif_concession">
                                    {emails}
                                </div>
                            </div>
                            <div className="travaux-wrapper">
                                <div className="d-flex align-items-center mt-4">
                                    <p className="title soleilRegular-fonts mb-0">TRAVAUX</p>

                                    {
                                        !this.props.location.isArchived ?
                                    <img src={plusRedButton} data-toggle="modal" data-target="#etape5" id='5' alt=""
                                         className="img-fluid ml-2 mb-1 step-btn cursor btn-travaux travaux-form" data-travaux-id="0" onClick={this.props.newTravaux}/>
                                            : ''
                                    }
                                </div>
                                <div className="px-2 pt-1 pb-2 notif_concession">
                                    {this.props.travaux}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contents content-margin">
                    <div className="modal fade procedure-modal modal-large" id="svgmap" tabIndex="-1" role="dialog"
                         aria-labelledby="svgmap" aria-hidden="true">
                        <div className="modal-dialog  d-flex justify-content-center align-items-center modal-wrap"
                             role="document">
                            <div className="modal-content" id="content-attribution-emplacement">
                                <div className="modal-header bk-grey">
                                    Carte
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <style>
                                        {cssMap}
                                    </style>
                                    <div style={{"position": "relative"}} id="viewport">
                                        <div id="map"></div>
                                        <div id="minimap"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ArchiveModal historique={this.state.historique} emplacement={this.props.emplacement}></ArchiveModal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        defuntDisplay: (defunt) => dispatch({
            type: 'DISPLAY_DEFUNT',
            defunt : defunt
        }),
        removeDefunt : (defuntId) =>dispatch({
            type: 'REMOVE_DEFUNT',
            defuntId : defuntId
        })
    }
}

const mapStateToProps = ({ defunt = null, defuntListe = {}, defuntAjoute = 0,defuntAjouteId = null }) => ({
    defunt,
    defuntListe,
    defuntAjoute,
    defuntAjouteId
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmplacementOccupeDefault);