import React, { Component } from 'react';
import greenBellIcon from "../../../assets/images/green-bell-icon.svg";
import editIconWhite from "../../../assets/images/edit-white-icon.svg";
import refreshPurple from "../../../assets/images/refresh-purple-icon.svg";
import pdfExportIcon from "../../../assets/images/pdf-export-icon-purple.svg";
import deleteIcon from "../../../assets/images/delete-icon-purple.svg";
import plusRedButton from "../../../assets/images/plus-red-icon.svg";
import {Dropdown} from "react-bootstrap";
import threeDots from "../../../assets/images/three-dots-vertical.svg";
import FormAjoutDefunt from "../../defunts/FormAjoutDefunt";
import $ from "jquery";
import {connect} from "react-redux";
import redBellIcon from "../../../assets/images/red-bell-icon.svg";
import greyBellIcon from "../../../assets/images/grey-bell-icon.svg";
import SupprimerDefunt from "../../defunts/SupprimerDefunt";
import ImagesEmplacement from "../../../components/concession/ImagesEmplacement";
import DateTools from "../../../components/utils/DateTools";
import Tools from "../../../components/utils/Tools";
import GestionPictoDefunt from "../../../components/concession/GestionPictoDefunt";
import DeplacerDefunt from "../../../components/concession/DeplacerDefunt";
import GetPDF from "../../../components/utils/GetPDF";

class TerrainCommunOuCaveau extends Component{
    constructor(props) {
        super(props);
        this.state = {
            placeOccupee : this.props.location.hasOwnProperty('nbPlacesOccupees') ? this.props.location.nbPlacesOccupees : 0,
            placeTotal : this.props.location.hasOwnProperty('nbPlaces') ? this.props.location.nbPlaces : 0,
            defuntDisplay : null,
        }
    }

    componentDidMount() {
        this.setState({placeRestante : this.state.placeTotal - this.state.placeOccupee})
        $(function () {
            $('[data-toggle="popover"]').popover()
        })
    }

    handlePlaceOccupee = (nb, action) => {
        if (action === 'add') {
            this.setState({placeOccupee : this.state.placeOccupee + nb})
        } else {
            this.setState({placeOccupee : this.state.placeOccupee - nb})
        }
    }

    ouvrirAjoutDefunt = (e) => {
        if(e.target.getAttribute('data-id') !== null) {
            this.setState({defuntDisplay : e.target.getAttribute('data-id') })
        } else {
            const {defuntDisplay} = this.props
            defuntDisplay(null)
            this.setState({defuntDisplay : null })
        }
        document.getElementById('ajout-defunt-fenetre').style.display = "block";
        $('.names-wrapper').css('margin-bottom', '20px');
    }

    fermerAjoutDefunt = (e) => {
        if (e) {
            e.preventDefault();
        }
        document.getElementById('ajout-defunt-fenetre').style.display = "none";
        $('.names-wrapper').css('margin-bottom', '0');
    }

    renderTextePlaces = () => {
        let placeRestante = this.state.placeTotal - this.state.placeOccupee
        if (placeRestante === 0) {
            return '(pleine)'
        }else if (placeRestante === 1) {
            return '(1 place restante)'
        }else if (placeRestante < 0){
            return ''
        }else  {
            return '(' + placeRestante + ' places restantes)'
        }
    }

    formatDate = (date) => {
        const d = new Date(date);
        return ('0' + d.getDate()).slice(-2) + '/'
            + ('0' + (d.getMonth()+1)).slice(-2) + '/'
            + d.getFullYear();
    }

    verifDateFin = (date) => {
        let statut = 'En cours'
        if (date) {
            const d = new Date(date.substr(0, 10))
            const dateMaintenant = new Date()
            const echeanceRenouvellement = new Date(date.substr(0, 10))
            echeanceRenouvellement.setFullYear(echeanceRenouvellement.getFullYear() + 2)
            if(d < dateMaintenant && dateMaintenant < echeanceRenouvellement) {
                statut = 'A renouveler'
            } else if (d < dateMaintenant){
                statut = 'Échue'
            }
        } else {
            statut = 'Données manquantes'
        }
        return statut
    }

    render() {
        let defunts
        let occupants
        let queryOccupant = JSON.parse(this.props.defunt.defuntListe)
        if (queryOccupant !== null) {
            occupants = queryOccupant
        } else {
            occupants = []
        }
        if (this.props.defunt.defuntListe && JSON.parse(this.props.defunt.defuntListe).length !== 0) {
            defunts = occupants.map((defunt) => {
                let dateNaissanceIncomplete = null
                if (defunt.hasOwnProperty('dateNaissanceIncomplete')) {
                    dateNaissanceIncomplete = defunt.dateNaissanceIncomplete
                }
                return (
                    <div className="soleilLight-fonts">
                        <div className="d-flex">
                            <div className="px-1">
                                <GestionPictoDefunt defuntId = {defunt.id}/>
                            </div>
                            <div>
                                {(defunt.nom || defunt.prenom) ?
                                    <a className="select-defunt cursor" key={defunt.id} data-id={defunt.id} onClick={this.ouvrirAjoutDefunt}>
                                        {defunt.civilite === 'F' ? 'Mme ' : 'M.'}&nbsp;
                                        {defunt.nom ? defunt.nom : '-'}&nbsp;
                                        {defunt.nomJeuneFille && defunt.nomJeuneFille !== "" ? 'né(e) ' + defunt.nomJeuneFille : ''}&nbsp;
                                        {defunt.prenom}
                                    </a>
                                    :
                                    <a className="select-defunt cursor" data-id={defunt.id} onClick={this.ouvrirAjoutDefunt}>Inconnu</a>
                                }
                                <p>{DateTools.getDateDefunt(defunt.dateDeNaissance, defunt.dateDeces, dateNaissanceIncomplete)}</p>
                            </div>
                        </div>
                    </div>
                )
            })
        }

        let image = greenBellIcon
        if(this.props.location.dateFin && this.verifDateFin(this.props.location.dateFin)  === 'Échue') {
            image = redBellIcon
        } else {
            if (!this.props.location.dateFin) {
                image = greyBellIcon
            }
        }

        let emails = []
        if (this.props.location && this.props.location.mailArchives) {
            emails = this.props.location.mailArchives.map((email => (
                <div className="d-flex mt-2">
                    <div className="col-8 pl-0 soleilRegular-fonts">{email.objet}</div>
                    <div className="col-2 pl-0 soleilRegular-fonts">{email.dateEnvoi ? DateTools.formatDate(email.dateEnvoi) : '-'}</div>
                </div>
            )))
        }

        let infosContactType = {
            'Contact' : ''
        }
        let contacts = this.props.location.contacts.map((contact, i) => {
            return (
                <div className={i === 0 ? "carousel-item active" : "carousel-item"} key={contact.id}>
                    <div>
                        <div className="card card-body">
                            <div className="d-flex align-items-center">
                                <div className="title soleilBold-fonts">Contact
                                    <span
                                        className="info cursor" data-container="body" data-toggle="popover"
                                        data-trigger="hover" data-placement="top"
                                        data-content={infosContactType[contact.typeDeContact]}>
                                    </span>
                                </div>
                            </div>
                            <div className="text soleilBold-fonts mb-1">
                                {contact.civilite === 'F' ? 'Mme ' : 'M.'} &nbsp;
                                {contact.prenom} &nbsp;
                                {contact.nom}&nbsp;
                                {contact.nomJeuneFille && contact.nomJeuneFille !== "" ? ' né(e) ' + contact.nomJeuneFille : ''}&nbsp;
                                {contact.decede ? '(décédé(e))' : ''}<br/>
                                {contact.adresse} {contact.commune} {contact.codePostal}<br/>
                                {contact.telephone} <br/>
                                {contact.email}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <React.Fragment>
                <div className="px-3 px-lg-4 scroll" id="fiche_en_cours">
                    <div className={"durand-family-wrapper container-fluid pb-2 " +
                    (this.props.location.dateFin && this.verifDateFin(this.props.location.dateFin) === 'Échue' ? 'echue' :
                        !this.props.location.dateFin ? 'noData' :'')}>
                        <div className="row py-2 align-items-center">
                            <div className="col-md-4 title soleilBold-fonts text-center text-md-left">
                                <img src={image} alt="" className="img-fluid mr-3" width="40px"/>
                                {this.props.location.type}
                                {this.props.location.terrainCommun ? ' n° ' + this.props.location.terrainCommun : ''  }
                                {this.props.location.caveau ? ' n° ' + this.props.location.caveau : ''  }
                                <span
                                    className="info cursor mt-3 ml-3" data-container="body" data-toggle="popover"
                                    data-trigger="hover" data-placement="top"
                                    data-content="Un terrain commun permet l'inhumation gratuite des personnes domiciliées
                                    ou décédées sur le territoire de la commune, pendant une durée légale maximum de 5 ans.">
                                </span>
                            </div>
                            <div className="col-md-8 mt-3 mt-md-0">
                                <div className="mx-auto ml-md-auto mr-md-0 durand-buttons">
                                    <button type="button" className="active step-btn editer-location-btn" data-toggle="modal"
                                            data-target="#etape1" id="1">
                                        <img className="active step-btn img-fluid mr-2" data-toggle="modal"
                                             data-target="#etape1" id="1" src={editIconWhite} alt=""/>
                                        éditer concession
                                    </button>
                                    <button type="button" className="historique-location-btn">
                                        <img src={refreshPurple} alt="" className="img-fluid mr-2"/>
                                        historique
                                    </button>
                                    <button type="button" className="exporter-location-btn" onClick={() => GetPDF('location', this.props.location.id)}>
                                        <img src={pdfExportIcon} alt=""
                                             className="img-fluid mr-2"/>
                                        exporter
                                    </button>
                                    <button type="button" className="delete-location-btn">
                                        <img src={deleteIcon} alt="" className="img-fluid"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row" id="infos_concession">
                            <div className="col-lg-3 progress-one mt-2" id="tour-infos-step">
                                {
                                    this.verifDateFin(this.props.location.dateFin) === 'En cours' || this.verifDateFin(this.props.location.dateFin) === 'A renouveler'?
                                        <div className="sub-title-1 soleilBold-fonts">{this.verifDateFin(this.props.location.dateFin)} </div>
                                        :
                                        this.props.location.dateFin ?
                                            <div className="sub-title-1 red-circle soleilBold-fonts">Échue</div> :''
                                }
                                {
                                    !this.props.location.dateFin ?
                                        <div className="sub-title-1 grey-circle soleilBold-fonts">Données manquantes</div>
                                        :
                                        ''
                                }
                                <div className="item soleilRegular-fonts">Durée :&nbsp;
                                    <strong className="soleilBold-fonts">
                                        {
                                            this.props.location.duree !== null && this.props.location.type === 'Terrain commun' ?
                                            this.props.location.duree + ' ans'
                                            : this.props.location.type === 'Terrain commun' ? 'inconnue'
                                                    : this.props.location.type === 'Caveau' ? '6 mois maximum'
                                                        : ''
                                        }
                                    </strong>
                                </div>
                                {
                                    this.props.location.type === 'Terrain commun' ?
                                    (
                                        <div className="item soleilRegular-fonts">Type :&nbsp;
                                            <strong className="soleilBold-fonts">
                                                {this.props.location.typeConcession}
                                            </strong>
                                        </div>
                                    )
                                        : ''
                                }
                                <div className={this.state.placeRestante === 0 ? "item soleilRegular-fonts"
                                    : "item soleilRegular-fonts green-tick"}>Capacité :&nbsp;
                                    <strong className="soleilBold-fonts" >
                                        { this.state.placeTotal !== 0 ? this.state.placeOccupee + '/'+ this.state.placeTotal : 'inconnu'}&nbsp;
                                        {this.renderTextePlaces()}
                                        <span
                                            className="info cursor" data-container="body" data-toggle="popover"
                                            data-trigger="hover" data-placement="top"
                                            data-content="Le nombre de place est donné à titre indicatif">
                                        </span>
                                    </strong>
                                </div>
                                <div className="item soleilRegular-fonts">Date de début :&nbsp;
                                    <strong className="soleilBold-fonts">
                                        { this.props.location.dateDebut !== null ?
                                            this.formatDate(this.props.location.dateDebut.substr(0, 10)) : ' inconnue'}
                                    </strong>
                                </div>
                                <div className={this.verifDateFin(this.props.location.dateFin) ?
                                    "item soleilRegular-fonts green-tick" : "item soleilRegular-fonts red-tick"}>Date de fin :&nbsp;
                                    <strong className="soleilBold-fonts" >
                                        { this.props.location.dateFin !== null ?
                                            this.formatDate(this.props.location.dateFin.substr(0, 10))
                                            : ' inconnue'}
                                    </strong>
                                </div>
                            </div>
                            <div className="col-lg-2 mt-2 text-center">
                                <ImagesEmplacement
                                    emplacement = {this.props.defunt.emplacement ? this.props.defunt.emplacement : null}
                                />
                            </div>
                            <div className="col-lg-3 mt-2 deceased-list d-flex pt-4 pb-3">
                                <div className="col-12 text soleilRegular-fonts">
                                    <div className="d-flex align-items-center mb-1">
                                        <strong className="soleilBold-fonts">{this.props.defunt.defuntListe ? JSON.parse(this.props.defunt.defuntListe).length : 0} défunt(s) </strong>
                                        <img src={plusRedButton} id="ajout-defunt" alt=""
                                             className="img-fluid ml-2 mb-1 cursor" onClick={this.ouvrirAjoutDefunt}
                                        />
                                    </div>
                                    <div id="list-defunts">
                                        {defunts}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 map-wrapper-cont mt-md-2">
                                <img src={process.env.REACT_APP_API_URL + "/emplacements/emplacement-svg/" + this.props.emplacement.id } alt={""} />
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid" id="ajout-defunt-fenetre">
                        <div className="row pb-3">
                            <div className="col-12 d-flex justify-content-end mt-3">
                                <Dropdown>
                                    <Dropdown.Toggle id="#menu-trois-points" className="menu-trois-points">
                                        <img src={threeDots} alt=""/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item className="soleilRegular-fonts purple" onClick={this.fermerAjoutDefunt}>
                                            Fermer la fenêtre du défunt
                                        </Dropdown.Item>
                                        <DeplacerDefunt
                                            removeDefunt = {this.props.removeDefunt}
                                        />
                                        <SupprimerDefunt
                                            removeDefunt = {this.props.removeDefunt}
                                            fermerAjoutDefunt = {this.fermerAjoutDefunt}
                                            defunt ={this.props.defunt.defunt ? this.props.defunt.defunt : null}
                                            emplacementId = {this.props.defunt.emplacement ? this.props.defunt.emplacement.id : null}
                                            handlePlaceOccupee = {this.handlePlaceOccupee}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="col-12 d-flex flex-wrap justify-content-center mb-2 px-0">
                                <FormAjoutDefunt location = {this.props.location} defuntId = {this.state.defuntDisplay}/>
                            </div>
                        </div>
                    </div>
                    <div className="row step-2">
                        <div className="col-md-8 mt-4 pl-5-extra">
                            <div className="procedures-acces-wrapper">
                                <div className="title soleilRegular-fonts mt-1">ASSISTANCE RÉGLEMENTAIRE
                                </div>
                                <div className="d-flex border-bottom-h green-tick">
                                    <div className="col-sm-4 pl-0 soleilRegular-fonts">Reprise à échéance</div>
                                    <div className="col-sm-8 pl-0 soleilBold-fonts">À partir du { this.props.location.dateFin !== null ?
                                        this.formatDate(this.props.location.dateFin.substr(0, 10))
                                        : ' inconnue'}</div>
                                </div>
                            </div>


                            <div className="names-wrapper py-4 px-4">
                                <div className="d-flex align-items-center">

                                    {
                                        !this.props.location.isArchived ?
                                            <img src={plusRedButton} data-toggle="modal"
                                                 data-target="#etape1" id="none" alt="" className="img-fluid ml-2 mb-1 cursor step-btn"
                                                 onClick={() => Tools.displaySpecificStep('etape1', 4)}/>
                                            : ''
                                    }
                                </div>
                                <div id="recipeCarousel" className="carousel slide w-100" data-ride="carousel">
                                    <div className="carousel-inner w-100" role="listbox">
                                        {contacts}
                                    </div>
                                    {
                                        this.props.location && this.props.location.contacts && (this.props.location.contacts.length > 2 || (this.props.location.contacts.length === 2 && window.innerWidth < 1200)) ?
                                            <>
                                                <a className="carousel-control-prev w-auto" href="#recipeCarousel" role="button"
                                                   data-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                                <a className="carousel-control-next w-auto" href="#recipeCarousel" role="button"
                                                   data-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"/>
                                                    <span className="sr-only">Next</span>
                                                </a>
                                            </>
                                            :''
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="col-md-4 my-3">
                            <div className="documents-wrapper">
                                <div className="d-flex align-items-center">
                                    <p className="title soleilRegular-fonts mb-0">DOCUMENTS</p>
                                    <img src={plusRedButton} alt="ajout-document"
                                         data-toggle="modal" data-target="#etape1" id="none"
                                         className="cursor img-fluid ml-2 mb-1"
                                         onClick={() => Tools.displaySpecificStep('etape1', 5)}/>
                                </div>
                                <div className="pl-5-extra pt-2 pb-2 notif_concession">
                                    <div className="d-flex mt-2 green-tick position-relative">
                                        <div className="col-5 pl-0 soleilRegular-fonts">Registre</div>
                                        <div className="col-7 pl-0 soleilBold-fonts d-flex flex-wrap cursor">
                                            <a className="purple soleilRegular-fonts mr-2 cursor">consulter</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="emails-wrapper">
                                <div className="d-flex align-items-center mt-4">
                                    <p className="title soleilRegular-fonts mb-0">DERNIER(S) E-MAIL(S) ENVOYÉ(S) À LA MAIRIE</p>
                                </div>
                                <div className="px-3 pt-1 pb-2 notif_concession">
                                    {emails}
                                </div>
                            </div>
                            <div className="travaux-wrapper">
                                <div className="d-flex align-items-center mt-4">
                                    <p className="title soleilRegular-fonts mb-0">TRAVAUX</p>
                                    <img src={plusRedButton} data-toggle="modal"
                                         data-target="#etape5" id='5' alt=""
                                         className="img-fluid ml-2 mb-1 step-btn cursor btn-travaux travaux-form"
                                         data-travaux-id="0"
                                         onClick={this.props.newTravaux}/>
                                </div>
                                <div className="px-2 pt-1 pb-2 notif_concession">
                                    {this.props.travaux}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        defuntDisplay: (defunt) => dispatch({
            type: 'DISPLAY_DEFUNT',
            defunt : defunt
        }),
        removeDefunt : (defuntId) =>dispatch({
            type: 'REMOVE_DEFUNT',
            defuntId : defuntId
        })
    }
}

const mapStateToProps = ({ defunt = null, defuntListe = {}, defuntAjoute = 0,defuntAjouteId = null }) => ({
    defunt,
    defuntListe,
    defuntAjoute,
    defuntAjouteId
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TerrainCommunOuCaveau);