import {Modal} from "react-bootstrap";
import React, {Component} from "react";
import axios from "axios";

class SuggestionsForm extends Component {

    constructor(props) {
        super(props);
        this.state =  {
            confirmation : false,
            suggestions  : '',
            questions    : '',
            reponse      : '',
            remarques    : '',
            error        : null,
            note         : 0,
            show         : false
        }
    }

    handleNotation = (e, value) => {
        const selected = document.querySelector('.suggestions-note .selected')
        if (this.state.note !== value) {
            if (selected) {
                selected.classList.remove('selected')
            }
            this.setState({note : value})
            e.target.classList.add('selected')
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name] : e.target.value})
    }

    envoyerAvis = () => {
        const data = {
            suggestions : this.state.suggestions,
            questions   : this.state.questions,
            remarques   : this.state.remarques,
            note        : this.state.note
        }
        if (this.state.note) {
            axios.post(process.env.REACT_APP_API_URL + '/notifications/envoyer-avis', data).then(() => {
                this.setState({
                    confirmation: true,
                    show: false,
                    response: 'Merci d\'avoir partager votre avis.'
                })
            })
        } else {
            this.setState({error : 'Veuillez sélectionner une notation entre 1 et 10'})
        }
    }

    render() {
        require('../../assets/css/modal.css')
        let options = []
        for (let i = 1; i <= 10; i++) {
            options.push(<div onClick={(e) => this.handleNotation(e, i)} key={i}>{i}</div>)
        }

        return (
            <>
                <a style={{fontSize : '15px'}} className="cursor red float-right" onClick={() => this.setState({show: true})}>Évaluer</a>
                <Modal show={this.state.show} onHide={() => {this.setState({show : false})}}>
                    <Modal.Header className="bk-grey" closeButton/>
                    <Modal.Body>
                        <p className="soleilBold-fonts title purple m-2">Donnez votre avis</p>
                        <div className="form-wrapper mt-2 suggestions-form">
                            <div className="d-flex flex-column">
                                <label className="soleilRegular-fonts">A quel point êtes-vous satisfait de Logicim ?</label>
                                <div className="col-12 suggestions-note d-flex mt-1">
                                    {options}
                                </div>
                            </div>
                            <div className="d-flex mt-4">
                                <label className="soleilRegular-fonts">Avez-vous des questions ?</label>
                                <textarea className="col-12 d-flex" name='questions' value={this.state.questions} onChange={this.handleChange}/>
                            </div>
                            <div className="d-flex mt-3">
                                <label className="soleilRegular-fonts">Avez-vous des suggestions ?</label>
                                <textarea className="col-12 d-flex" name='suggestions' value={this.state.suggestions} onChange={this.handleChange}/>
                            </div>
                            <div className="d-flex mt-3">
                                <label className="soleilRegular-fonts">Avez-vous d'autres remarques ?</label>
                                <textarea className="col-12 d-flex" name='remarques' value={this.state.remarques} onChange={this.handleChange}/>
                            </div>
                            <p className="red text-center mt-3">{this.state.error}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="red cancel-btn tbl" onClick={() => {this.setState({show : false})}}>Annuler</button>
                        {
                            <button type="button" className="tbl bk-red ml-2" onClick={this.envoyerAvis}>Envoyer</button>
                        }
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.confirmation} onHide={() => {this.setState({confirmation : false})}}>
                    <Modal.Header className="bk-grey" closeButton/>
                    <Modal.Body>
                        {this.state.response}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="tbl bk-red ml-2" onClick={() => {this.setState({confirmation : false})}}>Terminer</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default SuggestionsForm;