import axios from "axios";
import DateTools from "./DateTools";
import DownloadSnappy from "./DownloadSnappy";

const GetPDF = (objet, id, data = {}) => {
    axios.get(process.env.REACT_APP_API_URL + '/export/' + objet + '/' + id, {
        params: data,
        responseType: 'arraybuffer'
    }).then(response => {
        const nom = 'export' + Math.floor(Math.random() * 10000) + '_' + objet + '_' + DateTools.formatDate(new Date().toString()) + '.pdf'
        DownloadSnappy(response, nom)
    }).catch(error => {
        console.log(error)
    })
}

export default GetPDF;