import React, { Component } from 'react';
import Tools                from "../../../components/utils/Tools";
import redBellIcon          from '../../../assets/images/red-bell-icon.svg';
import infoWhiteIcon        from '../../../assets/images/info-icon-white.svg';
import dlDocProcedure       from '../../../assets/images/dl-documents-procedures.svg';
import axios                from "../../../helpers/axios";
import $                    from 'jquery';
import {Link}         from "react-router-dom";
import HandleDownload from "../../../components/utils/HandleDownload"

class Conversion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form : [],
            etape : 1,
            emplacement: {} ,
            conversions : [],
            lastConversionCall : undefined,
            textEtape1 : ''
        }
    }

    componentDidMount() {
        Tools.navigationPopup('Conversion', 1)
        Tools.updateNameUploadFile()
        axios.get(process.env.REACT_APP_API_URL + '/emplacements/' + this.props.match.params.emplacementId)
            .then ((response) => {
                this.setState({ emplacement: response.data})
                let procedures = this.state.emplacement.procedureConversions
                if (procedures !== undefined) {
                    axios.all(procedures.map(p => axios.get(process.env.REACT_APP_API_URL + '/procedure_conversions/' + p.replace(/\D/g, ""))))
                        .then(axios.spread((...res) => {
                            this.setState({conversions: res})
                        }));
                }
                axios.get(process.env.REACT_APP_API_URL + '/conversion/form/' + this.state.emplacement.id + '/' + this.state.etape)
                    .then((response) => {
                        this.setState({form: response.data})
                        if ($('[id$=nouvelleDuree]').find('option').length < 2) {
                            this.setState({textEtape1 : 'Pour réaliser la conversion pour une durée plus longue, veuillez enregistrer une durée plus longue et le tarif correspondant.'})
                            $('.step-btn').remove()
                        }else {
                            this.setState({textEtape1 : 'Vous enregistrez ici la procédure de conversion.'})
                        }

                        this.updateField();
                    })
            })
    }

    getForm = (e) => {
        if (this.state.lastConversionCall !== $(e.target).attr('data-conversion')) {
            this.setState({lastConversionCall : $(e.target).attr('data-conversion')})
            e.stopPropagation();
            let promise = process.env.REACT_APP_API_URL + '/conversion/form/' + this.props.match.params.emplacementId + '/' + this.state.etape
            if ($(e.target).attr('data-conversion') !== undefined) {
                promise += '/' + $(e.target).attr('data-conversion')
            }
            axios.get(promise)
                .then((response) => {
                    this.setState({form: response.data})

                    $('.modal.conversion').modal('show')

                    //si affichage form vide on appelle updateField pour la gestion des données du form
                    //si on affiche un form pré-rempli on cache les choix des checkbox pour afficher que le contact selectionner et on disable tout les champs
                    if ($(e.target).attr('data-conversion') === undefined) {
                        this.updateField();
                    }else {
                        $('input, select').prop( "disabled", true )
                        $('[type="radio"].table-checkbox:not(:checked)').parent().remove()
                        $('[type="radio"]').parent().addClass('select-row')
                    }
                })
        }
    }

    updateField = () =>{
        if ($('.modal.conversion .ligne.s2').hasClass('last')) {
            $('.preview').show()
        }

        $('#prixEnregistrer').text($('[id$=prixInitial]').attr('placeholder'))

        //date du passage à l'euro : on vérifie si la date initial de création de la concession est antérieur à celle du passage à l'euro
        let dateFranc = new Date(2002, 1, 1);
        let dateInitiale = new Date($('[id$=dateContratInitial]').val());

        if (dateInitiale < dateFranc) {
            $('#verifFranc').text("Au vu de la date initial du contrat, il se peut que ce montant soit en franc. Veuillez vérifier ce montant et le convertir en euros si nécessaire avant saisis.")
        }

        $('[id$=dateConversion]').change(() => {
            updateProrataTemporis()
        })

        $('[id$=prixInitial]').change(() => {
            updateProrataTemporis()
        })

        $('[id$=nouvelleDuree]').change(() => {
            nouveauxDateMontant()
        })

        $('[type="radio"].table-checkbox').change(function () {
            $('[type="radio"].table-checkbox:not(:checked)').parent().removeClass('select-row');
        })

        let emplacement = this.state.emplacement.id

        function updateProrataTemporis() {
            let data = {
                emplacementId: emplacement,
                tarif: $('[id$=prixInitial]').val(),
                dateFin: $('[id$=dateConversion]').val()
            }

            axios.get(process.env.REACT_APP_API_URL + '/tarifs/remboursement', {
                params: data
            }).then((response) => {
                $('[id$=prorataTemporisSoustrait]').val(response.data.prorata)
                if ($('[id$=nouvelleDuree]').val() !== '') {
                    nouveauxDateMontant()
                }
            })
        }

        function nouveauxDateMontant() {
            let dureeChamps = $('[id$=nouvelleDuree]')
            let nouvelleDuree = parseInt(dureeChamps.val())
            if ($('[id$=prorataTemporisSoustrait]').val() === '') {
                $('#error').text('Veuillez renseigner le prix initial avant de poursuivre.')
                dureeChamps.val('')
                $('[id$=prixInitial]').addClass('empty-required')
            } else {
                let data = {emplacementId: emplacement, fixe: true, duree: nouvelleDuree}
                axios.get(process.env.REACT_APP_API_URL + '/tarifs/tarifEmplacement', {
                    params: data
                }).then((response) => {
                    let nouveauPrix = response.data.tarif;
                    if ($.isNumeric(nouveauPrix)) {
                        $('[id$=prixNouvelleDuree]').val(nouveauPrix);
                        $('[id$=montantAPayer]').val(nouveauPrix - $('[id$=prorataTemporisSoustrait]').val());
                        $('#error').text('')
                    } else {
                        $('#error').text(nouveauPrix)
                        $('[id$=prixNouvelleDuree]').val('');
                        $('[id$=montantAPayer]').val('');
                    }
                })
                let dateInitiale = new Date($('[id$=dateContratInitial]').val());
                if(nouvelleDuree !== 0) {
                    let year = parseInt(dateInitiale.getFullYear()) + parseInt($('[id$=nouvelleDuree]').val())
                    let nouvelleEcheance = new Date(Date.UTC(year, dateInitiale.getMonth(), dateInitiale.getDate(), 0, 0, 0, 0));
                    $('#conversion1_nouvelleDateEcheance').val(nouvelleEcheance.toISOString().substr(0, 10));
                } else {
                    $('#conversion1_nouvelleDateEcheance').val('');
                }
            }
        }
    }

    render() {
        require('../../../assets/css/procedures.css')
        require('../../../assets/css/formPop-up.css')

        const proceduresFaites = []

        for (const procedureFaite of this.state.conversions){
            const dateConvertion = new Date(procedureFaite.data.dateConversion)
            proceduresFaites.push (
                <div className="etape bg-white p-3 etape-finie">
                    <p className="soleilBold-fonts title purple">étape 1/1 : convertir</p>
                    <p className="soleilRegular-fonts purple etape-description">Conversion effectuée le {dateConvertion.toLocaleDateString()}</p>
                    <div className="d-flex justify-content-between align-items-center">
                        <a className="dl-doc soleilBold-fonts d-flex "  onClick={() => HandleDownload([procedureFaite.data.titre, procedureFaite.data.acte])}>
                            <img src={dlDocProcedure} className="img-fluid mr-2" width="20px" alt=""/>
                            <p>Documents</p>
                        </a>
                        <button type="button" className="add-doc-btn soleilBold-fonts"
                                data-toggle="modal" data-target="#etape1" data-conversion = {procedureFaite.data.id} onClick={this.getForm}>Détails
                        </button>
                    </div>
                </div>
            )
        }

        return (
            <div className="contents content-margin">
                <div className="content-header px-3 px-lg-4 page2">
                    <span className="expand-btn">&#9776;</span>
                    <div className="d-flex align-items-center">
                        <div className="col-12 soleilBold-fonts">
                            <span className="title">CONVERSION DE CONCESSION : </span>
                            <span className="text-white">Emplacement {this.state.emplacement.carre} {this.state.emplacement.allee}</span>
                        </div>
                    </div>
                </div>
                <div className="px-3 px-lg-4  ">
                    <div className="retour-concession d-flex align-items-center mt-3 ml-4 mb-3">
                        <img src={redBellIcon} className="mr-3" width="40px" alt=""/>
                        <Link to={{pathname: "/fiche-concession/" + this.props.match.params.emplacementId}}
                              className="soleilBold-fonts purple m-0 pt-4">
                            {
                                this.state.lastLocation && this.state.lastLocation.champsSuppValeurs ?
                                    'Famille ' + this.state.lastLocation.champsSuppValeurs.famille
                                    : ''
                            } (retour à l'emplacement)
                        </Link>
                    </div>
                    <div className="essential-cemetery-wrapper scroll">
                        <div className="container-fluid px-0 ">
                            <div className="col-lg-12 bg-white py-3">
                                <div className="info-security-content">
                                    <div className="d-lg-inline-flex align-items-center mb-3">
                                        <img src={infoWhiteIcon} alt=""
                                             className="img-fluid mr-2" width="25px"/>
                                            <p className="soleilBold-fonts mt-2 ">Rappels</p>
                                    </div>
                                    <p className="soleilBook-fonts">
                                        Attention, la conversion doit se faire pendant la durée de la concession ou 2
                                        ans après son expiration maximum.
                                        <br></br>
                                            La conversion n'est possible que pour une durée plus longue. Si la commune
                                            ne propose pas de durée plus longue, la conversion ne sera pas possible.
                                    </p>
                                </div>
                            </div>
                            <div className="etapes-procedures pt-3 d-flex justify-content-center">
                                <div className="etapes-wrapper d-flex flex-wrap">
                                    {proceduresFaites}
                                    <div className="etape bg-white p-3">
                                        <p className="soleilBold-fonts title purple etape-title">étape 1/1 :
                                            convertir</p>
                                        <p className="soleilRegular-fonts purple etape-description" >{this.state.textEtape1}</p>
                                        <button type="button" className="add-doc-btn soleilBold-fonts step-btn"
                                                data-toggle="modal" data-target="#etape1" id="1" onClick={this.getForm}>Convertir
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{__html:this.state.form}} className="formulaire" data-submit={process.env.REACT_APP_API_URL + '/conversion/form/' + this.state.emplacement.id + '/' + this.state.etape}></div>
            </div>
        )
    }
}

export default Conversion;