import StepsMainFunctions from "./StepsMainFunctions";
import $ from "jquery";

const steps = [
    {
        id: 'ajout-travaux-1',
        scrollTo: true,
        attachTo: { element: '.btn-travaux', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            }
        ],
        beforeShowPromise: function() {
            return StepsMainFunctions.PromiseListe('emplacements', "#emplacements-path", this)
        },
        when: {
            show() {
                if(window.location.pathname === '/aide') {
                    StepsMainFunctions.observer('emplacement', '#emplacements-path', this)
                } else {
                    if (this.tour && this.tour.steps) {
                        this.tour.steps.splice(1, 2)
                    }
                    StepsMainFunctions.watchDivWithHeight('etape5', '.btn-travaux', this)
                }
                StepsMainFunctions.showRewrite(this)
            },
            hide() {
                $('#emplacements-path').off('click')
            },
            cancel() {
                $('#emplacements-path').off('click')
            },
            destroy() {
                $('#emplacements-path').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur ce bouton pour ajouter des <span class="purple">travaux</span>.</p>`,
    },
    {
        id: 'fiche-toLocation-0',
        attachTo: {element: '.contents.content-margin', on: 'top'},
        buttons: [
            {
                classes: 'shepherd-button-secondary ',
                text: 'Arrêter',
                type: 'cancel'
            },
        ],
        when: {
            show() {
                StepsMainFunctions.showRewrite(this)
                StepsMainFunctions.locationToEmplacement('.edit-tbl.emplacement', 'attribution_emplacement_type', this)
            },
            hide() {
                $('.edit-tbl.emplacement').off('click')
            },
            cancel() {
                $('.edit-tbl.emplacement').off('click')
            },
            destroy() {
                $('.edit-tbl.emplacement').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur <span class="purple">"consulter"</span> sur l'<span class="purple">emplacement</span> où vous souhaitez ajouter des travaux.</p>`,
    },
    {
        id: 'ajout-travaux-1',
        scrollTo: true,
        attachTo: { element: '.btn-travaux', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            }
        ],
        when: {
            show() {
                StepsMainFunctions.watchDivWithHeight('etape5', '.btn-travaux', this)
                StepsMainFunctions.showRewrite(this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur ce bouton pour ajouter des <span class="purple">travaux</span>.</p>`,
    },
    {
        id: 'ajout-travaux-2',
        attachTo: { element: '#etape5 .modal-content', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Ce formulaire en cinq étape vous permet d'ajouter des <span class="purple">travaux</span> à votre emplacement.</p>`,
    },
    {
        id: 'ajout-travaux-3',
        attachTo: { element: '#form-travaux-suivant', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Terminer',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Accédez aux autres étape du formulaire en cliquant sur <span class="purple">"Suivant"</span>.</p>`,
    },
]

export default steps;