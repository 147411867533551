export const FETCH_USER = "FETCH_USER";
export const SET_USER = "SET_USER";
export const FETCH_ESPACE = "FETCH_ESPACE";
export const SET_ESPACE = "SET_ESPACE";
export const FETCH_ESPACES = "FETCH_ESPACES";
export const SET_ESPACES = "SET_ESPACES";
export const FETCH_SOCIETE = "FETCH_SOCIETE";
export const RESET_SOCIETE = "RESET_SOCIETE";
export const SET_SOCIETE = "SET_SOCIETE";
export const FETCH_SOCIETES = "FETCH_SOCIETES";
export const SET_SOCIETES = "SET_SOCIETES";
export const FETCH_COLUMN = "FETCH_COLUMN";
export const SET_COLUMN = "SET_COLUMN";
export const FETCH_EMPLACEMENT = "FETCH_EMPLACEMENT";
export const SET_EMPLACEMENT = "SET_EMPLACEMENT";
export const FETCH_EMPLACEMENT_LIST = "FETCH_EMPLACEMENT_LIST";
export const SET_EMPLACEMENT_LIST = "SET_EMPLACEMENT_LIST";

export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";
export const WRONG_CREDENTIAL = "WRONG_CREDENTIAL";
export const RESET_WRONG_CREDENTIAL = "RESET_WRONG_CREDENTIAL";

// Types relatifs au document
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const DOCUMENT_ERROR = "DOCUMENT_ERROR";
export const START_GETTING_DOCUMENT = "START_GETTING_DOCUMENT";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const CREATE_DOCUMENT = "CREATE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const PERSONNALISE_DOCUMENT = "PERSONNALISE_DOCUMENT";
export const GET_PREVIEW_DOCUMENT = "GET_PREVIEW_DOCUMENT";
export const START_GETTING_PREVIEW_DOCUMENT = "START_GETTING_PREVIEW_DOCUMENT";

export const DISPLAY_DEFUNT = 'DISPLAY_DEFUNT';
export const ADD_DEFUNT = 'ADD_DEFUNT';
export const REMOVE_DEFUNT ='REMOVE_DEFUNT';
export const ADD_LISTE_DEFUNT = 'ADD_LISTE_DEFUNT';
export const UPDATE_EMPLACEMENT = 'UPDATE_EMPLACEMENT';
export const ADD_EXHUMATION ='ADD_EXHUMATION';
export const ADD_REDUCTION ='ADD_REDUCTION';

export const SELECT_MAP_LOCATION = 'SELECT_MAP_LOCATION';
export const EMPLACEMENT_ERROR = 'EMPLACEMENT_ERROR';
export const SET_CHOOSE_EMPLACEMENT = 'SET_CHOOSE_EMPLACEMENT';
export const GET_EMPLACEMENTS = 'GET_EMPLACEMENTS';
// Pagination avec redux !
// export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
// export const SEARCH_DOCUMENTS = "SEARCH_DOCUMENTS";

