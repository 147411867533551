import React, {Component, useEffect, useState} from 'react';
import interactionPlugin, {Draggable} from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import multiMonthPlugin from '@fullcalendar/multimonth' // a plugin!
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import 'tippy.js/themes/light.css';
import axios from "axios";
import {Modal} from "react-bootstrap";
import dayjs from 'dayjs';


function CalendrierMenu(props) {

    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [viewRange, setViewRange] = useState({
        start: null,
        end: null
    });
    const [currentEvent, setCurrentEvent] = useState({
        'date': dayjs().format('YYYY-MM-DD'),
        'time': '',
        'id': null,
        'title': '',
        'content': '',
        'isNote': false,
        'isPersonnel': false
    });

    const updateEvenement = () => {
        axios.post(process.env.REACT_APP_API_URL + '/calendrier/donnees', {
            ...currentEvent,
            espace: localStorage.getItem('espace_id')
        }).then(() => {
            setShowModal(false)
            loadEvents()
        })
    }

    const updateCurrentEvent = (e) => {
        setCurrentEvent({...currentEvent, [e.target.name]: e.target.value})
    }
    const loadEvents = () => {
        if (!viewRange.start || !viewRange.end) return;

        axios.get(process.env.REACT_APP_API_URL + '/calendrier/donnees?espaceId=' + localStorage.getItem('espace_id')
            + '&date_from=' + viewRange.start
            + '&date_to=' + viewRange.end
        )
            .then((data) => {

                setEvents(data['data'].map((item) => {
                    let url = '';
                    let color = '';
                    let display = '';
                    let textColor = '';
                    let icon = '';
                    if (item.type === 'ferie') {
                        color = '#eee';
                        textColor = '#6D00B5';
                        display = "background"
                    }
                    if (item.type === 'a_renouveler') {
                        color = '#ff99b7';
                        url = '/fiche-concession/' + item.emplacementId
                    }
                    if (item.type === 'travaux') {
                        color = '#eee';
                        textColor = '#6D00B5';
                        url = '/fiche-concession/' + item.emplacementId
                        icon = '<span style="font-size: 11px" class="material-icons">' +
                            'warning' +
                            '</span> '
                    }

                    const data = {
                        id: item.id,
                        title: item.titre,
                        content: item.contenu,
                        start: item.date_debut,
                        end: item.date_fin,
                        classNames: item.type,
                        backgroundColor: color !== "" ? color : '',
                        textColor: textColor !== '' ? textColor : '',
                        display: display !== "" ? display : '',
                        icon: icon,
                        time: dayjs(item.date_debut).format('HH:mm'),
                        data: {
                            id: item.id,
                            title: item.titre,
                            titre: item.titre,
                            content: item.contenu,
                            date:  dayjs(item.date_debut).format('YYYY-MM-DD'),
                            time: dayjs(item.date_debut).format('HH:mm'),
                            isNote: !!item.isNote,
                            isPersonnel: !!item.isPersonnel
                        },

                        allDay: true,
                    }

                    if (url) {
                        data.url = url
                    }

                    return data;
                }))
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        loadEvents()
        },
        [viewRange])


    return (
        <div className="contents content-margin">
            <div className="content-header px-3 px-lg-4 page2">
                <span className="sidemenu-expand-btn">&#9776;</span>
                <div className="d-flex align-items-center">
                    <div className="col-12 soleilBold-fonts title">
                        CALENDRIER
                    </div>
                </div>
            </div>
            <div className="px-3 px-lg-4 pt-3" id="calendrier">
                <FullCalendar
                    plugins={[multiMonthPlugin, dayGridPlugin, listPlugin, interactionPlugin]}
                    initialView="multiMonthYear"
                    headerToolbar={{
                        center: 'initialView,listMonth,timeGridView' // buttons for switching between views
                    }}
                    dateClick={function (arg) {
                        setShowModal(true)
                        setCurrentEvent({
                            'date': dayjs(arg.date).format('YYYY-MM-DD'),
                            'time': dayjs(arg.date).format('HH:mm'),
                            id: null,
                            'title': '',
                            'content': '',
                            'isNote': false,
                            'isPersonnel': true
                        })
                    }}
                    multiMonthMaxColumns={2}
                    views={{
                        initialView: {
                            type: 'multiMonthYear',
                            buttonText: 'Vue année',
                        },
                        listWeek: {
                            type: 'listWeek',
                            buttonText: 'Liste / semaine'
                        },
                        timeGridView: {
                            type: 'dayGridMonth',
                            buttonText: 'Mois'
                        }
                    }}
                    eventClick={(info) => {

                        setShowModal(true)
                        setCurrentEvent(info.event.extendedProps.data)

                    }}
                    weekends={true}
                    locale={frLocale}
                    eventDidMount={(info) => {
                        let time = '';
                        if (info.event.extendedProps.time) {
                            time = '<strong>[' + info.event.extendedProps.time + ']</strong> '
                        }
                        tippy(info.el, {
                            content: time + info.event.title + ' : ' + info.event.extendedProps.content,
                            allowHTML: true,
                            theme: 'light'
                        });

                        const icon = info.event.extendedProps.icon
                        // Add icon before the title
                        info.el.querySelector(".fc-event-title, .fc-list-event-title").innerHTML = icon + info.el.querySelector(".fc-event-title, .fc-list-event-title").innerHTML;

                    }}
                    datesSet={(info) => {
                        setViewRange({
                            start: dayjs(info.view.currentStart).format('YYYY-MM-DD'),
                            end: dayjs(info.view.currentEnd).format('YYYY-MM-DD')
                        })
                    }
                    }
                    viewDidMount={(info) => {
                        setViewRange({
                            start: dayjs(info.view.currentStart).format('YYYY-MM-DD'),
                            end: dayjs(info.view.currentEnd).format('YYYY-MM-DD')
                        })
                    }}
                    events={{events}}
                    eventColor={'#6D00B5'}
                />
            </div>
            <Modal show={showModal} onHide={() => {
                setShowModal(false);
            }}>
                <Modal.Header className="bk-grey" closeButton>
                    {currentEvent.id !== null ? 'Modifier' : 'Ajouter'} un événement
                </Modal.Header>
                <Modal.Body>
                    <div className="form-wrapper mt-3">
                        <div className="d-flex row">
                            <label htmlFor={"date"} className="col-4 soleilRegular-fonts">Date</label>
                            <input id="date" className="col-8" type="date" name="date" value={currentEvent.date}
                                   onChange={updateCurrentEvent}/>
                        </div>

                        <div className="d-flex mt-2 row">
                            <label htmlFor={"time"} className="col-4 soleilRegular-fonts">Heure</label>
                            <input id="time" className="col-8" type="time" name="time" value={currentEvent.time}
                                   onChange={updateCurrentEvent}/>
                        </div>
                        <div className="d-flex mt-2 row">
                            <label className="col-4 soleilRegular-fonts m-0" htmlFor={"titre"}>Titre</label>
                            <input type="text" className="col-8 soleilRegular-fonts" id={"titre"} name="title" placeholder={"Mon titre"}
                                   value={currentEvent.titre} onChange={updateCurrentEvent}/>
                        </div>

                        <div className="d-flex mt-2 row">
                            <label className="col-12 soleilRegular-fonts m-0" htmlFor={"content"}>Contenu</label>
                            <div className={"col-12 mt-2 p-2 ml-2"}>
                                <textarea name="content" id={"content"} className={"p-2"} cols="30" rows="10"
                                          onChange={updateCurrentEvent} placeholder={"Mon contenu"} value={currentEvent.content}></textarea>
                            </div>
                        </div>

                        <div className="d-flex mt-2">
                            <div className="col-12">
                                <label className=" soleilRegular-fonts m-0" htmlFor={"isNote"}>
                                    <input type="checkbox" className=" mr-2 soleilRegular-fonts" name="isNote"
                                           id={"isNote"} value={1} checked={currentEvent.isNote}
                                           onClick={(e) => {
                                               setCurrentEvent({
                                                   ...currentEvent,
                                                   isNote: e.target.checked
                                               })
                                           } }/>
                                    Lier à une note ?</label>
                            </div>
                        </div>
                        <div className="d-flex mt-2">
                            <div className="col-12">
                                <label className=" soleilRegular-fonts m-0" htmlFor={"isPersonnel"}>
                                    <input type="checkbox" className=" mr-2 soleilRegular-fonts" name="isPersonnel"
                                           id={"isPersonnel"} value={1} checked={currentEvent.isPersonnel}
                                           onClick={(e) => {
                                               setCurrentEvent({
                                                   ...currentEvent,
                                                   isPersonnel: e.target.checked
                                               })
                                           } }/>
                                    Evènement personnel ?</label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="red cancel-btn tbl" onClick={() => {
                        setShowModal(false)
                    }}>
                        Annuler
                    </button>
                    <button type="button" className="tbl bk-red ml-2" onClick={updateEvenement}>
                        Valider
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default CalendrierMenu;