import React, { Component } from 'react';
import greenBellIcon from "../../../assets/images/green-bell-icon.svg";
import editIconWhite from "../../../assets/images/edit-white-icon.svg";
import refreshPurple from "../../../assets/images/refresh-purple-icon.svg";
import pdfExportIcon from "../../../assets/images/pdf-export-icon-purple.svg";
import deleteIcon from "../../../assets/images/delete-icon-purple.svg";
import plusRedButton from "../../../assets/images/plus-red-icon.svg";
import {Dropdown} from "react-bootstrap";
import threeDots from "../../../assets/images/three-dots-vertical.svg";
import FormAjoutDefunt from "../../defunts/FormAjoutDefunt";
import $ from "jquery";
import {connect} from "react-redux";
import SupprimerDefunt from "../../defunts/SupprimerDefunt";
import DateTools from "../../../components/utils/DateTools";
import ImagesEmplacement from "../../../components/concession/ImagesEmplacement";
import DeplacerDefunt from "../../../components/concession/DeplacerDefunt";
import Tools from "../../../components/utils/Tools";
import GetPDF from "../../../components/utils/GetPDF";

class JardinDuSouvenirOuOssuaire extends Component{
    constructor(props) {
        super(props);
        this.state = {
            defuntDisplay : null,
        }
    }

    componentDidMount() {
        $(function () {
            $('[data-toggle="popover"]').popover()
        })
    }

    ouvrirAjoutDefunt = (e) => {
        if(e.target.getAttribute('data-id') !== null) {
            this.setState({defuntDisplay : e.target.getAttribute('data-id') })
        } else {
            const {defuntDisplay} = this.props
            defuntDisplay(null)
            this.setState({defuntDisplay : null })
        }
        document.getElementById('ajout-defunt-fenetre').style.display = "block";
        $('.names-wrapper').css('margin-bottom', '20px');
    }

    fermerAjoutDefunt = (e) => {
        if (e) {
            e.preventDefault();
        }
        document.getElementById('ajout-defunt-fenetre').style.display = "none";
        $('.names-wrapper').css('margin-bottom', '0');
    }

    formatDate = (date) => {
        const d = new Date(date);
        return ('0' + d.getDate()).slice(-2) + '/'
            + ('0' + (d.getMonth()+1)).slice(-2) + '/'
            + d.getFullYear();
    }

    render() {
        let defunts
        let occupants
        let queryOccupant = JSON.parse(this.props.defunt.defuntListe)
        if (queryOccupant !== null) {
            occupants = queryOccupant
        } else {
            occupants = []
        }
        if (this.props.defunt.defuntListe && JSON.parse(this.props.defunt.defuntListe).length !== 0) {
            defunts = occupants.map((defunt) => {
                let dateNaissanceIncomplete = null
                if (defunt.hasOwnProperty('dateNaissanceIncomplete')) {
                    dateNaissanceIncomplete = defunt.dateNaissanceIncomplete
                }
                return (
                    <div className="soleilLight-fonts">
                        {(defunt.nom || defunt.prenom) ?
                            <a className="select-defunt cursor" data-id={defunt.id} onClick={this.ouvrirAjoutDefunt}>
                                {defunt.civilite === 'F' ? 'Mme ' : 'M.'}&nbsp;
                                {defunt.nom ? defunt.nom : '-'}&nbsp;
                                {defunt.nomJeuneFille && defunt.nomJeuneFille !== "" ? 'né(e) ' + defunt.nomJeuneFille : ''}&nbsp;
                                {defunt.prenom}
                            </a>
                            :
                            <a className="select-defunt cursor" data-id={defunt.id} onClick={this.ouvrirAjoutDefunt}>Inconnu</a>
                        }
                        <p>{DateTools.getDateDefunt(defunt.dateDeNaissance, defunt.dateDeces, dateNaissanceIncomplete)}</p>
                    </div>
                )
            })
        }

        let emails = []
        if (this.props.location && this.props.location.mailArchives) {
            emails = this.props.location.mailArchives.map((email => (
                <div className="d-flex mt-2">
                    <div className="col-8 pl-0 soleilRegular-fonts">{email.objet}</div>
                    <div className="col-2 pl-0 soleilRegular-fonts">{email.dateEnvoi ? DateTools.formatDate(email.dateEnvoi) : '-'}</div>
                </div>
            )))
        }

        let typeLocation = null
        if(this.props.location.jardinDuSouvenir) {
            typeLocation = 'jardinDuSouvenir'
        } else {
            typeLocation = 'ossuaire'
        }

        return (
            <React.Fragment>
                <div className="px-3 px-lg-4 scroll" id="fiche_en_cours">
                    <div className="durand-family-wrapper container-fluid pb-2">
                        <div className="row py-2 align-items-center">
                            <div className="col-md-4 title soleilBold-fonts text-center text-md-left">
                                <img src={greenBellIcon} alt="" className="img-fluid mr-3"/>
                                {this.props.location.type}
                                {this.props.location.jardinDuSouvenir ? ' n° ' + this.props.location.jardinDuSouvenir : ''  }
                                {this.props.location.Ossuaire ? ' n° ' + this.props.location.Ossuaire : ''  }
                                <span
                                    className="info cursor mt-3 ml-3" data-container="body" data-toggle="popover"
                                    data-trigger="hover" data-placement="top"
                                    data-content="Lejardin du Souvenir(appelé aussijardin des Souvenirs) permet de disperser,
                                    dans un lieu identifié et spécialement prévu à cet effet, les cendres des défunts crématisées.
                                     Il est obligatoire dans les communes de plus de 2000 habitants depuis le 1er janvier 2013.
                                     Les familles ayant recueilli les cendres de leurs proches peuvent les disperser dans le
                                     jardin du souvenir ou espace de dispersion des cendres. ">
                                </span>
                            </div>
                            <div className="col-md-8 mt-3 mt-md-0">
                                <div className="mx-auto ml-md-auto mr-md-0 durand-buttons">
                                    <button type="button" className="active step-btn editer-location-btn" data-toggle="modal"
                                            data-target="#etape1" id="1">
                                        <img className="active step-btn img-fluid mr-2" data-toggle="modal"
                                             data-target="#etape1" id="1" src={editIconWhite} alt=""/>
                                        éditer concession
                                    </button>
                                    <button type="button" className="historique-location-btn">
                                        <img src={refreshPurple} alt="" className="img-fluid mr-2"/>
                                        historique
                                    </button>
                                    <button type="button" className="exporter-location-btn" onClick={() => GetPDF('location', this.props.location.id)}>
                                        <img src={pdfExportIcon} alt=""
                                             className="img-fluid mr-2"/>
                                        exporter
                                    </button>
                                    <button type="button" className="delete-location-btn">
                                        <img src={deleteIcon} alt="" className="img-fluid"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row" id="infos_concession">
                            <div className="col-lg-3 progress-one mt-2" id="tour-infos-step">
                                <div className="sub-title-1 soleilBold-fonts">En cours</div>
                            </div>
                            <div className="col-lg-2 mt-2 text-center">
                                <ImagesEmplacement
                                    emplacement = {this.props.defunt.emplacement ? this.props.defunt.emplacement : null}
                                />
                            </div>
                            <div className="col-lg-3 mt-2 deceased-list d-flex pt-4 pb-1">
                                <div className="col-12 text soleilRegular-fonts">
                                    <div className="d-flex align-items-center mb-1">
                                        <strong className="soleilBold-fonts">{this.props.defunt.defuntListe ? JSON.parse(this.props.defunt.defuntListe).length : 0} défunt(s) </strong>
                                        <img src={plusRedButton} id="ajout-defunt" alt=""
                                             className="img-fluid ml-2 mb-1 cursor" onClick={this.ouvrirAjoutDefunt}/>
                                    </div>
                                    <div id="list-defunts">
                                        {defunts}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 map-wrapper-cont mt-md-2">
                                <img src={process.env.REACT_APP_API_URL + "/emplacements/emplacement-svg/" + this.props.emplacement.id } alt={""} />
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid" id="ajout-defunt-fenetre">
                        <div className="row pb-3">
                            <div className="col-12 d-flex justify-content-end mt-3">
                                <Dropdown>
                                    <Dropdown.Toggle id="#menu-trois-points" className="menu-trois-points">
                                        <img src={threeDots} alt=""/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item className="soleilRegular-fonts purple" onClick={this.fermerAjoutDefunt}>
                                            Fermer la fenêtre du défunt
                                        </Dropdown.Item>
                                        <DeplacerDefunt
                                            removeDefunt = {this.props.removeDefunt}
                                        />
                                        <SupprimerDefunt
                                            removeDefunt = {this.props.removeDefunt}
                                            fermerAjoutDefunt = {this.fermerAjoutDefunt}
                                            defunt ={this.props.defunt.defunt ? this.props.defunt.defunt : null}
                                            emplacementId = {this.props.defunt.emplacement ? this.props.defunt.emplacement.id : null}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="col-12 d-flex flex-wrap justify-content-center mb-2 px-0">
                                <FormAjoutDefunt location = {this.props.location} defuntId = {this.state.defuntDisplay}/>
                            </div>
                        </div>
                    </div>
                    <div className="row step-2">
                        <div className="col-md-8 mt-4 pl-5-extra">
                            <div className="procedures-acces-wrapper">
                                <div className="title soleilRegular-fonts mt-1">ASSISTANCE RÉGLEMENTAIRE
                                </div>
                                <div className="d-flex px-2 ">
                                    <div className="col-12 pl-0 soleilRegular-fonts">
                                        {
                                            this.props.location.type === 'Jardin du souvenir' ?
                                                'Aucune procédure n\'est possible pour cet emplacement' :
                                                'L\'ossuaire est affecté à perpétuité. Aucune procédure n’est possible pour cet emplacement.'
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 my-3">
                            <div className="documents-wrapper">
                                <div className="d-flex align-items-center">
                                    <p className="title soleilRegular-fonts mb-0">DOCUMENTS</p>
                                    <img src={plusRedButton} alt="ajout-document"
                                         data-toggle="modal" data-target="#etape1" id="none"
                                         className="cursor img-fluid ml-2 mb-1"
                                         onClick={() => Tools.displaySpecificStep('etape1', 5)}/>
                                </div>
                                <div className="pl-5-extra pt-2 pb-2 notif_concession">
                                    <div className="d-flex mt-2 green-tick position-relative">
                                        <div className="col-5 pl-0 soleilRegular-fonts">Registre</div>
                                        <div className="col-7 pl-0 soleilBold-fonts d-flex flex-wrap">
                                            <a className="purple soleilRegular-fonts mr-2 cursor" onClick={() => GetPDF(typeLocation, this.props.location.id)}>consulter</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="emails-wrapper">
                                <div className="d-flex align-items-center mt-4">
                                    <p className="title soleilRegular-fonts mb-0">DERNIER(S) E-MAIL(S) ENVOYÉ(S) À LA MAIRIE</p>
                                </div>
                                <div className="px-3 pt-1 pb-2 notif_concession">
                                    {emails}
                                </div>
                            </div>
                            <div className="travaux-wrapper">
                                <div className="d-flex align-items-center mt-4">
                                    <p className="title soleilRegular-fonts mb-0">TRAVAUX</p>
                                    <img src={plusRedButton} data-toggle="modal"
                                         data-target="#etape5" id='5' alt=""
                                         className="img-fluid ml-2 mb-1 step-btn cursor btn-travaux travaux-form"
                                         data-travaux-id="0"
                                         onClick={this.props.newTravaux}/>
                                </div>
                                <div className="px-2 pt-1 pb-2 notif_concession">
                                    {this.props.travaux}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        defuntDisplay: (defunt) => dispatch({
            type: 'DISPLAY_DEFUNT',
            defunt : defunt
        }),
        removeDefunt : (defuntId) =>dispatch({
            type: 'REMOVE_DEFUNT',
            defuntId : defuntId
        })
    }
}

const mapStateToProps = ({ defunt = null, defuntListe = {}, defuntAjoute = 0,defuntAjouteId = null }) => ({
    defunt,
    defuntListe,
    defuntAjoute,
    defuntAjouteId
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JardinDuSouvenirOuOssuaire);