import $ from 'jquery';
import StepsMainFunctions from "./StepsMainFunctions";

const steps = [
    {
        id: 'plan-etape1',
        attachTo: { element: '.table-filter-wrapper', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function() {
            return StepsMainFunctions.PromiseListe('emplacements', "#emplacements-path", this)
        },
        when: {
            show() {
                const $this = this
                const observer = new MutationObserver((mutations, mutationInstance) => {
                    const someDiv = document.getElementById('listes-leftpanel-emplacement')
                    if (someDiv) {
                        $this.tour.next()
                        mutationInstance.disconnect();
                    }
                });
                if(window.location.pathname !== '/emplacement') {
                    $('#emplacements-path').off('click').on('click', function () {
                        observer.observe(document, {
                            childList: true,
                            subtree: true
                        });
                    })
                } else {
                    if ($this.tour && $this.tour.steps) {
                        $this.tour.steps.splice(1, 1)
                    }
                }
                StepsMainFunctions.showRewrite($this)
            },
            hide() {
                $('#emplacements-path').off('click')
            },
            cancel() {
                $('#emplacements-path').off('click')
            },
            destroy() {
                $('#emplacements-path').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez <span class="purple">filtrer les emplacements</span> affichés soit en <span class="purple">cochant</span> les cases de couleur soit en ouvrant la <span class="purple">fenêtre des filtres</span>.</p>`,
    },
    {
        id: 'plan-etape0',
        attachTo: { element: '.table-filter-wrapper', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez <span class="purple">filtrer les emplacements</span> affichés soit en <span class="purple">cochant</span> les cases de couleur soit en ouvrant la <span class="purple">fenêtre des filtres</span>.</p>`,
    },
    {
        id: 'plan-etape2',
        attachTo: { element: '#filtre-title', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
        ],
        when: {
            show() {
                $('.close').trigger('click')
                const $this = this
                StepsMainFunctions.showRewrite($this)
                let actionToDo = 'next'
                const observer = new MutationObserver((mutations, mutationInstance) => {
                    const someDiv = document.querySelector('.filtre-modal')
                    if (someDiv) {
                        actionToDo === 'next' ? $this.tour.next() : $this.tour.back()
                        mutationInstance.disconnect();
                    }
                });
              $('#filtre-title').on('click',(e, action) => {
                  actionToDo = action === 'back' ? 'back' : 'next'
                    if(!document.querySelector('.filtre-modal') && document.querySelector('.shepherd-content')) {
                        observer.observe(document, {
                            childList: true,
                            subtree: true
                        });
                    }
                })
            },
            cancel() {
                $('#filtre-title').off('click')
            },
            destroy() {
                $('#filtre-title').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur <span class="purple">"Filtrer les emplacements"</span> pour afficher plus de <span class="purple">filtres</span> 
                et <span class="purple">passer</span> à la suite du tutoriel.</p>`,
    },
    {
        id: 'plan-etape3',
        attachTo: { element: '.filtre-modal .modal-content', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Dans cette fenêtre, vous pouvez <span class="purple">selectionner les filtres</span> que vous désirez.</p>`,
    },
    {
        id: 'plan-etape4',
        attachTo: { element: '#first-switch', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez <span class="purple">activer</span> ou <span class="purple">désactiver</span> le filtre en cliquant sur ce bouton. Si le bouton est <span class="green">vert</span>, le filtre est activé.</p>`,
    },
    {
        id: 'plan-etape5',
        attachTo: { element: '#step-tour-valider', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
            },
            hide(){
                document.querySelector('.tour-trigger-plan button').click()
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p><span class="purple">Valider vos choix</span> pour qu'ils s'appliquent au plan et à la liste.</p>`,
    },
    {
        id: 'plan-etape6',
        attachTo: { element: '#plan', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary trigger-back',
                text: 'Précédent'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                $('.close').trigger('click')
                const $this = this
                StepsMainFunctions.showRewrite($this)
                document.querySelector('.trigger-back').addEventListener('click', function () {
                    $('#filtre-title').trigger('click', ['back'])
                })
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Ceci est le <span class="purple">plan intéractif</span> de votre cimetière. Vous pouvez accéder à tous vos <span class="purple">emplacements</span>.</p>`,
    },
]

export default steps;