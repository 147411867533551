import React, { Component } from 'react';
import '../../assets/css/formDefunt.css';
import '../../assets/css/formAjoutEmplacement.css';
import axios from "../../helpers/axios";
import FormulaireCivilite from "./FormulaireCivilite";
import FormExhumation from "./FormExhumation";
import dlDocProcedure from "../../assets/images/dl-documents-procedures.svg";
import {connect} from "react-redux";
import {DISPLAY_DEFUNT} from "../../actions/types";
import FormReductionCorps from "./FormReductionCorps";
import $ from "jquery";

class FormAjoutDefunt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exhumation : false
        }
    }

    updateDefunt = (value) => {
        this.setState({defunt : value})
    }

    componentDidMount() {
        $(function () {
            $('[data-toggle="popover"]').popover()
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {defuntDisplay} = this.props
        let defunt = this.props.defunt.defunt
        if (defunt !== null) {
            defunt = JSON.parse(defunt)
        }
        if(this.props.defuntId !== null && ((defunt !== null && parseInt(defunt.occupant.id) !== parseInt(this.props.defuntId)) || defunt === null )) {
            axios.get(process.env.REACT_APP_API_URL + '/contacts/occupant/' + this.props.defuntId).then((response)=>{
                defuntDisplay(JSON.stringify(response.data))
            })
        }
    }

    formatDate = (date) => {
        let date_format = new Date(date)
        return ('0' + date_format.getDate()).slice(-2) + '/'
                + ('0' + (date_format.getMonth()+1)).slice(-2) + '/'
                + date_format.getFullYear()
    }

    formatEmplacementExhum = (emplacement) => {
        if (emplacement) {
            let emplacementInfos = emplacement.split('&')
            return emplacementInfos[0].split('=')[1]
        }
        return "-"
    }

    render() {
        let exhumation = this.props.defunt.exhumation
        let reduction = this.props.defunt.reduction
        let defunt = this.props.defunt.defunt
        if (defunt !== null) {
            defunt = JSON.parse(defunt)
        }
        let reunionDefunts = []
        if (reduction && reduction.type === "reunion de corps") {
            for (let defuntId in reduction.reunionDefunts){
                if(defunt && defunt.occupant.id !== parseInt(defuntId)) {
                    reunionDefunts.push
                    (
                        <p key={defuntId}>
                            &nbsp;
                            {reunionDefunts.length >= 1 ? 'et ' : ''}
                            {reduction.reunionDefunts[defuntId].nom}
                            &nbsp;
                            {reduction.reunionDefunts[defuntId].prenom}
                            &nbsp;
                        </p>
                    )
                }
            }
        }


        let document = null
        if(defunt && defunt.cremation && defunt.cremation.document) {
            document = defunt.cremation.document
        } else if (defunt && defunt.inhumation && defunt.inhumation.document) {
            document = defunt.inhumation.document
        }

        return (
            <React.Fragment>
                <div className="etape bg-white p-3 ajout-defunt-etape">
                    <p className="soleilBold-fonts title purple etape-title defunt">Civilité</p>
                    <div className="soleilRegular-fonts purple etape-description">
                        { defunt !== null ?
                            (
                                <React.Fragment>
                                    <p>
                                        {defunt.occupant.civilite === 'F' ? 'Madame ' : 'Monsieur '}
                                        {defunt.occupant.nom}&nbsp;
                                        {defunt.occupant.nomJeuneFille && defunt.occupant.nomJeuneFille !== "" ? 'né(e) ' + defunt.occupant.nomJeuneFille : ''}&nbsp;
                                        {defunt.occupant.prenom}
                                    </p>
                                    {defunt.occupant.dateDeNaissance !== null ?
                                        <p>Né(e) le &nbsp;
                                            {this.formatDate(defunt.occupant.dateDeNaissance.substr(0, 10))}
                                            &nbsp; à &nbsp;
                                            {defunt.occupant.lieuDeNaissance ? defunt.occupant.lieuDeNaissance : '-'}
                                        </p> :
                                        defunt.occupant.hasOwnProperty('dateNaissanceIncomplete') && defunt.occupant.dateNaissanceIncomplete !== null  ?
                                            <p>
                                                Né(e) le &nbsp;
                                                {defunt.occupant.dateNaissanceIncomplete.hasOwnProperty('day') && defunt.occupant.dateNaissanceIncomplete.day !== "" ? ('0' + defunt.occupant.dateNaissanceIncomplete.day).slice(-2) : '-' } /&nbsp;
                                                {defunt.occupant.dateNaissanceIncomplete.hasOwnProperty('month') && defunt.occupant.dateNaissanceIncomplete.month !== "" ?  ('0' + defunt.occupant.dateNaissanceIncomplete.month).slice(-2) : '-'} /&nbsp;
                                                {defunt.occupant.dateNaissanceIncomplete.hasOwnProperty('year') && defunt.occupant.dateNaissanceIncomplete.year !== "" ?  defunt.occupant.dateNaissanceIncomplete.year : ' -'}
                                                &nbsp; à &nbsp;
                                                {defunt.occupant.lieuDeNaissance ? defunt.occupant.lieuDeNaissance : '-'}
                                            </p>
                                            : ''
                                    }
                                    <p>
                                        Décédé(e) le &nbsp;
                                        {defunt.occupant.dateDeces.hasOwnProperty('day') && defunt.occupant.dateDeces.day !== "" ? ('0' + defunt.occupant.dateDeces.day).slice(-2) : '-' } /&nbsp;
                                        {defunt.occupant.dateDeces.hasOwnProperty('month') && defunt.occupant.dateDeces.month !== "" ?  ('0' + defunt.occupant.dateDeces.month).slice(-2) : '-'} /&nbsp;
                                        {defunt.occupant.dateDeces.hasOwnProperty('year') && defunt.occupant.dateDeces.year !== "" ?  defunt.occupant.dateDeces.year : '-'}
                                        &nbsp; à &nbsp;
                                        {defunt.occupant.lieuDeces !== "" ? defunt.occupant.lieuDeces : ' -'}
                                    </p>
                                    {
                                        defunt.occupant.hasOwnProperty('criteresSpecifiques') && defunt.occupant.criteresSpecifiques.length > 0 && defunt.occupant.criteresSpecifiques[0] !== "" ?
                                            <p>
                                                Critère(s) spécifique(s) : {defunt.occupant.criteresSpecifiques.toString()}
                                            </p>
                                            :
                                            'Aucun critère spécifique'
                                    }
                                </React.Fragment>
                            )
                            :
                            ''
                        }
                    </div>
                    {defunt !== null ?
                        (
                            <div className="d-flex justify-content-between align-items-center">
                                {
                                    <a className="dl-doc soleilBold-fonts d-flex cursor" href={(document ? process.env.REACT_APP_FILES_URL + document : '')} target="_blank" style={{ visibility: document ? 'visible' : "hidden"}}>
                                        <img src={dlDocProcedure} className="img-fluid mr-2" width="20px" alt=""/>
                                        <p>Documents</p>
                                    </a>
                                }

                                <button type="button" className="add-doc-btn soleilBold-fonts step-btn defunt-form" data-toggle="modal"
                                        data-target="#etape2" id="2" data-defunt-id={defunt.occupant.id}>Détails
                                </button>
                            </div>
                        )
                        :
                        (
                            <button type="button" className="add-doc-btn soleilBold-fonts step-btn defunt-form civilite" data-toggle="modal"
                                    data-target="#etape2" id="2" data-defunt-id="0">Renseigner
                            </button>
                        )
                    }
                </div>
                <div className="etape bg-white p-3">
                    <p className="soleilBold-fonts title purple etape-title defunt">inhumation / crémation</p>
                    <div className="soleilRegular-fonts purple etape-description">
                        {
                            defunt !== null && defunt.hasOwnProperty('cremation') ?
                            (
                                <React.Fragment>
                                    <p>Crémation le&nbsp;
                                        {
                                            defunt.cremation.dateCremation ? this.formatDate(defunt.cremation.dateCremation.substr(0, 10)) : ' -'
                                        }
                                    </p>
                                    {
                                        defunt.cremation.destinationUrneCendre === "Dispersion en Jardin du Souvenir"
                                        ?
                                        <p>Dispersion le {defunt.cremation.dateDispersion ? this.formatDate(defunt.cremation.dateDispersion.substr(0, 10)) : '-'}</p>
                                        :
                                        <p>Inhumation
                                            {
                                                exhumation != null ? ' initiale ' : ''
                                            }
                                            : {defunt.cremation.inhumation.date ? this.formatDate(defunt.cremation.inhumation.date.substr(0, 10)) : ''}
                                        </p>
                                    }
                                </React.Fragment>
                            )
                            :
                            ''
                        }
                        {
                            defunt !== null && defunt.hasOwnProperty('inhumation') ?
                                <React.Fragment>
                                    <p>Inhumation&nbsp;
                                        {
                                            exhumation != null ? 'initiale ' : ''
                                        }
                                        le&nbsp;
                                        {
                                            defunt.inhumation.date ? this.formatDate(defunt.inhumation.date.substr(0, 10))
                                            : ' -'
                                        }
                                    </p>
                                </React.Fragment>
                            :
                            ''
                        }
                    </div>
                    {defunt !== null &&
                        (defunt.hasOwnProperty('cremation') || defunt.hasOwnProperty('inhumation')) ?
                        (
                            <div className="d-flex justify-content-between align-items-center">
                                {
                                    document ?

                                        <a className="dl-doc soleilBold-fonts d-flex cursor" href={(document ? process.env.REACT_APP_FILES_URL + document : '')} target="_blank">
                                            <img src={dlDocProcedure} className="img-fluid mr-2" width="20px" alt=""/>
                                            <p>Documents</p>
                                        </a>
                                        : <span></span>
                                }
                                <button type="button" className="add-doc-btn soleilBold-fonts step-btn defunt-form" data-toggle="modal"
                                        data-target="#etape2" id="2" data-defunt-id={defunt.occupant.id}>Détails
                                </button>
                            </div>
                        )
                        :
                        (
                            <button type="button" className="add-doc-btn soleilBold-fonts step-btn defunt-form" data-toggle="modal"
                                    data-target="#etape2" id="2" data-defunt-id="0">Renseigner
                            </button>
                        )
                    }
                </div>
                {(this.props.location.type !== 'Jardin du souvenir' && this.props.location.type !== 'Ossuaire' && true) || exhumation != null ?
                    <React.Fragment>
                        <div className="etape bg-white p-3">
                            <p className="soleilBold-fonts title purple etape-title defunt">exhumation</p>
                            <div className="soleilRegular-fonts purple etape-description">
                                {
                                    exhumation != null ?
                                        (
                                            <React.Fragment>
                                                <p>Exhumation le {exhumation.date ? this.formatDate(exhumation.date.substr(0, 10)) : '-'}</p>
                                                {
                                                    exhumation.cremation ?
                                                    <p>
                                                        Crémation le {exhumation.cremation.dateCremation ?
                                                        this.formatDate(exhumation.cremation.dateCremation.substr(0, 10)) : ''}
                                                    </p>
                                                    : ''
                                                }
                                                {
                                                    exhumation.cremation && exhumation.cremation.inhumation ?
                                                        <p>
                                                            {exhumation.cremation.destinationUrneCendre} le {exhumation.cremation.inhumation.date ?
                                                            this.formatDate(exhumation.cremation.inhumation.date.substr(0, 10)) : ''}
                                                        </p>
                                                        : ''
                                                }
                                                {
                                                    exhumation.cremation && !exhumation.cremation.inhumation  ?
                                                        <p>
                                                            {exhumation.cremation.destinationUrneCendre}&nbsp;
                                                            le {exhumation.cremation.dateDispersion ? this.formatDate(exhumation.cremation.dateDispersion.substr(0, 10)) : '-'}
                                                        </p>
                                                        : ''
                                                }
                                                {
                                                    exhumation.inhumation ?
                                                    <p>
                                                        Réinhumé le {exhumation.inhumation.date ?
                                                        this.formatDate(exhumation.inhumation.date.substr(0, 10)) : '-'}
                                                    </p>
                                                        : ''
                                                }
                                                {
                                                    exhumation.destinationCorps === 'Réinhumation du corps dans la même concession' ?
                                                        <p>Dans le même emplacement</p>
                                                        : exhumation.destinationCorps === 'Réinhumation du corps dans une autre concession' ?
                                                             exhumation.deplacerCorps === 'Dans mon cimetière' ?
                                                                 <p>A l'emplacement {exhumation.numeroEmplacement}</p>
                                                                 :
                                                                 <p>Dans le cimetière de {this.formatEmplacementExhum(exhumation.emplacement)}</p>
                                                    : ''
                                                }
                                            </React.Fragment>
                                        )
                                        : ''
                                }
                            </div>
                            {
                                defunt && exhumation == null ?
                                <button type="button" className="add-doc-btn soleilBold-fonts step-btn defunt-form" data-toggle="modal"
                                        data-target="#etape3" id="3" data-defunt-id="0" onClick={() => this.setState({exhumation : true})}>Démarrer
                                </button>
                                : defunt && exhumation !== null ?
                                        <div className="d-flex justify-content-between align-items-center">
                                            <a className="dl-doc soleilBold-fonts d-flex cursor" href={(process.env.REACT_APP_FILES_URL + exhumation['autorisation'])} target="_blank">
                                                <img src={dlDocProcedure} className="img-fluid mr-2" width="20px" alt=""/>
                                                <p>Documents</p>
                                            </a>
                                            <button type="button" className="add-doc-btn soleilBold-fonts step-btn defunt-form" data-toggle="modal"
                                                    data-target="#etape3" data-defunt-id={defunt.occupant.id} id="3" onClick={() => this.setState({exhumation : true})}>Détails
                                            </button>
                                        </div>
                                        : ''
                            }
                        </div>
                    </React.Fragment>
                    :
                    ''
                }
                {this.props.location.type !== 'Jardin du souvenir' && this.props.location.type !== 'Ossuaire' ?
                        <div className="etape bg-white p-3">
                            <p className="soleilBold-fonts title purple etape-title defunt">réduction / réunion de
                                corps</p>
                            <div className="soleilRegular-fonts purple etape-description">
                            {
                                this.props.defunt.defunt && reduction ?
                                    <React.Fragment>
                                        <p className="mt-2">Exhumation le {reduction.exhumation.date ? this.formatDate(reduction.exhumation.date.substr(0, 10)) : '-' }</p>
                                        <p className="first-letter-uppercase">{reduction.type} le { reduction.date ? this.formatDate(reduction.date.substr(0, 10)) : '-'}</p>
                                        {
                                            reduction.type === "reunion de corps" ?
                                                <React.Fragment>
                                                    <div className="d-flex">avec {reunionDefunts}</div>
                                                </React.Fragment>
                                                :''
                                        }
                                        <p>Réinhumation le {reduction.dateReinhumation ? this.formatDate(reduction.dateReinhumation.substr(0, 10)) : '-'}</p>
                                    </React.Fragment>
                                : ''
                            }
                            </div>
                            {
                                defunt && reduction ?
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a className="dl-doc soleilBold-fonts d-flex cursor" href={(process.env.REACT_APP_FILES_URL + reduction.exhumation['autorisation'])} target="_blank">
                                            <img src={dlDocProcedure} className="img-fluid mr-2" width="20px" alt=""/>
                                            <p>Documents</p>
                                        </a>
                                        <button type="button" className="add-doc-btn soleilBold-fonts step-btn defunt-form" data-toggle="modal"
                                                data-target="#etape4" id="4" data-defunt-id={defunt.occupant.id}>Détails
                                        </button>
                                    </div>
                                : defunt ?
                                        <button type="button" className="add-doc-btn soleilBold-fonts step-btn defunt-form" data-toggle="modal"
                                                data-target="#etape4" id="4" data-defunt-id={defunt.occupant.id}>Démarrer
                                        </button>
                                : ''
                            }
                        </div>
                    : ''
                }
                    <FormulaireCivilite location = {this.props.location} updateDefunt = {this.updateDefunt} defuntId = {defunt ? defunt.occupant.id : null} handlePlaceOccupee = {this.props.handlePlaceOccupee}  />
                    <FormExhumation defuntId = {defunt ? defunt.occupant.id : null} exhumationId = {this.props.defunt.exhumation ? this.props.defunt.exhumation.id : null} handlePlaceOccupee = {this.props.handlePlaceOccupee}/>
                    <FormReductionCorps locationId = {this.props.location.id}  defuntId = {defunt ? defunt.occupant.id : null} handlePlaceOccupee = {this.props.handlePlaceOccupee}/>
            </React.Fragment>
        )
    }
}
const mapDispatchToProps = dispatch => {
    return {
        defuntDisplay: (defunt) => dispatch({
            type: 'DISPLAY_DEFUNT',
            defunt : defunt
        })
    }
}

const mapStateToProps = ({exhumation = null, defunt = null, defuntListe = {}, defuntAjoute = 0,defuntAjouteId = null, reduction = null }) => ({
    reduction,
    exhumation,
    defunt,
    defuntListe,
    defuntAjoute,
    defuntAjouteId
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormAjoutDefunt);