import React, {Component, useState} from 'react';
import {Nav, NavItem,NavLink} from "react-bootstrap";
import {TabContainer,TabContent,TabPane} from "react-bootstrap";
import EmplacementListe from "./EmplacementListe";
import Plan from '../plan/Plan';
import '../../assets/css/listes.css';
import axios from "axios";
import {connect} from "react-redux";
import {getEmplacement} from "../../actions/emplacements";
import FiltrerEmplacement from "../../components/pagination/FiltrerEmplacement";

class EmplacementMenu extends Component {

    constructor(props) {
        super(props);
        this.emplacements = {};
        this.emplacementListRef = React.createRef();
        this.mapRef = React.createRef();
    }

    static defaultProps = {
        filters: {
            etat: [],
            typeEmplacement: [],
            surface: [],
            carre: [],
            allee: [],
        },
        sort: {}
    }

    componentDidMount() {
    }

    handleFilterEmplacementChange = function(event, type) {
        const target = event.currentTarget;

        if (target.checked && this.props.filters[type].indexOf(target.value) === -1) {
            this.props.filters[type].push(target.value);
        }

        if (!target.checked && this.props.filters[type].indexOf(target.value) > -1) {
            this.props.filters[type] = this.props.filters[type].filter((value) => {
                return value !== target.value
            })
        }

        this.emplacementListRef.current.refreshPagination();
        this.mapRef.current.refreshPagination();
    }


    render() {

        return  (
            <div className="contents content-margin">
                <TabContainer id="left-tabs-example" defaultActiveKey="plan">
                    <div className="content-header px-3 px-lg-4">
                        <span className="expand-btn">&#9776;</span>
                        <div className="d-flex align-items-center">
                            <div className="col-6 soleilBold-fonts title">EMPLACEMENTS</div>
                            <div className="col-6 emp-buttons ">
                                <Nav variant="pills" className="flex-row switch-plan-liste">
                                    <NavItem>
                                        <NavLink eventKey="plan" className="tour-trigger-plan">
                                            <button type="button" className="plan-btn soleilBold-fonts">Plan</button>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink eventKey="liste">
                                            <button type="button" className="liste-btn soleilBold-fonts">Liste</button>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </div>
                    </div>
                    <div className="table-filter-wrapper px-3 px-lg-4 pt-3 pb-2">
                            <FiltrerEmplacement title="FILTRER LES EMPLACEMENTS" filters={this.props.filters}  emplacementListRef={this.emplacementListRef} mapRef={this.mapRef}/>
                        <div>
                            <input type="checkbox" id="is_free" className="mr-2" value="1" name="filters[etat][]" checked={this.props.filters.etat[5]} onChange={e => this.handleFilterEmplacementChange(e, 'etat')} />
                            <label htmlFor="is_free" className="soleilRegular-fonts" >
                                Libre
                            </label>
                        </div>
                        <div>
                            <input type="checkbox" id="is_occupy"  className="mr-2" value="3" name="filters[etat][]" checked={this.props.filters.etat[7] } onChange={e => this.handleFilterEmplacementChange(e, 'etat')} />
                            <label htmlFor="is_occupy" className="soleilRegular-fonts">
                                Occupé
                            </label>
                        </div>
                    </div>
                    <TabContent>
                        <TabPane eventKey="plan">
                            <Plan filters={this.props.filters} ref={this.mapRef} referer={"emplacement"}/>
                        </TabPane>
                        <TabPane eventKey="liste">
                            <EmplacementListe ref={this.emplacementListRef} filters={this.props.filters} sort={this.props.sort} />
                        </TabPane>
                    </TabContent>
                </TabContainer>
                <style>{this.busyItems}</style>
            </div>

        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEmplacement: (id) => getEmplacement(id, dispatch)
    }
}

const mapStateToProps = ({emplacement = null}) => ({
    emplacement
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmplacementMenu);