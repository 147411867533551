import React, { Component } from 'react'; 
import { connect } from "react-redux";
import { fetchApi } from "../../actions";

 class GetEspace extends Component {
     componentDidMount() {
        const espaceId = localStorage.getItem('espace_id')
        this.props.fetchApi({
            url: '/e_spaces/' + espaceId,
            type: "ESPACE"
        })
        
     }
     
    render() {
        return (
            <div className="contents content-margin">
                {this.props.espace.nom} 
                <br />
                Lat long : {this.props.espace.latitude} - {this.props.espace.longitude}
            </div>
        )
    }
}

const mapStateToProps = ({ data = [], isLoadingData = false, espace = {} }) => ({
    data,
    isLoadingData,
    espace
});
export default connect(mapStateToProps, {fetchApi})(GetEspace);