import logo from '../../assets/images/logo.svg';
import bell from '../../assets/images/bell-menu-icon.svg';
import calendar from '../../assets/images/calender-menu-icon.svg';
import notes from '../../assets/images/notes-menu-icon.svg';
import commune  from '../../assets/images/commune-icon.svg';
import back  from '../../assets/images/back-icon-purple.svg';
import troyes  from '../../assets/images/troyes-img.svg';
import aide from '../../assets/images/aide-icon.svg';
import ficheConversion from '../../assets/documents/Fiche_procedure_conversion.pdf';
import ficheRetrocession from '../../assets/documents/Fiche_procedure_retrocession.pdf';
import ficheDonation from '../../assets/documents/Fiche_procedure_donation.pdf';
import ficheRepriseAbandon from '../../assets/documents/Fiche_procedure_reprise_par_abandon.pdf';
import ficheRenouvellement from '../../assets/documents/Fiche_procedure_renouvellement_et_reprise_pour_non_renouvellement.pdf';
import modeleConversion from '../../assets/documents/modele_conversion.docx';
import modeleDonation from '../../assets/documents/modele_donation.docx';
import modeleRenouvellement from '../../assets/documents/modele_renouvellement.docx';
import modeleRepriseApresConstat from '../../assets/documents/modele_reprise_apres_constat_abandon.docx';
import modeleRepriseNonRenouv from '../../assets/documents/modele_reprise_non_renouv.docx';
import modeleRetrocession from '../../assets/documents/modele_retrocession.docx';
import docWordModeleTitreRecette from '../../assets/documents/modele_titre-de-recette.docx';
import docWordModeleActeDeConcession from '../../assets/documents/modele_acte-de-concession.docx';
import docWordDemandeConcessionFuneraire from '../../assets/documents/modele_demande-deconcesssion-funeraire.docx';
import docWordFOActeNotoriete from '../../assets/documents/formulaires_operations/Acte_de_notorit_-_Modle.docx';
import docWordFOAutorisationCremation from '../../assets/documents/formulaires_operations/Autorisation_de_crmation.docx';
import docWordFODptProvisoireCorps from '../../assets/documents/formulaires_operations/Autorisation_de_dpt_provisoire_de_corps.docx';
import docWordFOFermetureCercueil from '../../assets/documents/formulaires_operations/Autorisation_de_fermeture_de_cercueil.docx';
import docWordFOAutorisationMoulage from '../../assets/documents/formulaires_operations/Autorisation_de_moulage.docx';
import docWordFOAutorisationEmbaumement from '../../assets/documents/formulaires_operations/Autorisation_dembaumement.docx';
import docWordFOAutorisationExhumation from '../../assets/documents/formulaires_operations/Autorisation_dexhumation.docx';
import docWordFOAutorisationInhumation from '../../assets/documents/formulaires_operations/Autorisation_dinhumation.docx';
import docWordFOBonIndigence from '../../assets/documents/formulaires_operations/Bon_dindigence_modle.docx';
import docWordFOChangementTypeConcession from '../../assets/documents/formulaires_operations/Changement_type_de_concession.docx';
import docWordFOConstatationDeces from '../../assets/documents/formulaires_operations/Constatation_de_dcs.docx';
import docWordFODateInconnueDeces from '../../assets/documents/formulaires_operations/Date_inconnue_du_dcs.docx';
import docWordFODecesPersonneInconnue from '../../assets/documents/formulaires_operations/Dcs_dune_personne_inconnue.docx';
import docWordFODemandeAutorisationDispersionJS from '../../assets/documents/formulaires_operations/Demande___autorisation_de_dispersion_des_cendres_dans_un_jardin_du_souvenir.docx';
import docWordFODemandeAdminissionFuneraire from '../../assets/documents/formulaires_operations/Demande_dautorisation_dadmission_en_chambre_funraire.docx';
import docWordFODemandeAutorisationCremation from '../../assets/documents/formulaires_operations/Demande_dautorisation_de_crmation.docx';
import docWordFODemandeAutorisationCaveau from '../../assets/documents/formulaires_operations/Demande_dautorisation_de_dpt_provisoire_dans_un_caveau.docx';
import docWordFODemandeAutorisationMoulage from '../../assets/documents/formulaires_operations/Demande_dautorisation_de_moulage.docx';
import docWordFODemandeAutorisationEmbaumement from '../../assets/documents/formulaires_operations/Demande_dautorisation_dembaumement.docx';
import docWordFODemandeAutorisationExhumation from '../../assets/documents/formulaires_operations/Demande_dautorisation_dexhumation.docx';
import docWordFODemandeAutorisationSC from '../../assets/documents/formulaires_operations/Demande_dautorisation_pour_la_destine_de_lurne_ou_des_cendres_en_cimetire_ou_site_cinraire.docx';
import docWordFODemandeAutorisationPN from '../../assets/documents/formulaires_operations/Demande_dautorisation_pour_la_destine_des_cendres_dans_une_proprit_prive_ou_en_pleine_nature.docx';
import docWordFODeliberationTranslationCimetiere from '../../assets/documents/formulaires_operations/Dlibration_translation_cimetire_modle.docx';
import docWordFOModificationDureeTarifs from '../../assets/documents/formulaires_operations/Modification_des_dures_et-ou_tarifs_du_cimetire.docx';
import docWordFODenonciationActeDeces from '../../assets/documents/formulaires_operations/nonciation_de_lacte_de_dcs.docx';
import docWordFOPVArriveeCF from '../../assets/documents/formulaires_operations/PV_d-arrive_en_chambre_funraire.docx';
import docWordFOPVCremation from '../../assets/documents/formulaires_operations/PV_de_crmation.docx';
import docWordFOPVDepartCF from '../../assets/documents/formulaires_operations/PV_de_dpart_en_chambre_funraire.docx';
import docWordFOPVDptTemporaireCorps from '../../assets/documents/formulaires_operations/PV_de_dpt_temporaire_dun_corps.docx';
import docWordFOPVMoulage from '../../assets/documents/formulaires_operations/PV_de_moulage.docx';
import docWordFOPVEmbaumement from '../../assets/documents/formulaires_operations/PV_dembaumement.docx';
import docWordFOPVExhumation from '../../assets/documents/formulaires_operations/PV_dexhumation.docx';
import docWordFOTranscriptionActeDeces from '../../assets/documents/formulaires_operations/Transcription_de_lacte_de_dcs.docx';
import docWordGuideConversionDoc from '../../assets/documents/guides/modele-procedure-de-conversion.docx';
import docWordGuideConversion from '../../assets/documents/guides/Fiche_procedure_conversion.pdf';
import docWordGuideDonationDoc from '../../assets/documents/guides/modele-procedure-de-donation.docx';
import docWordGuideDonation from '../../assets/documents/guides/Fiche_procedure_donation.pdf';
import docWordGuideConcessionEchues from '../../assets/documents/guides/Le-Guide-2_Les-concessions-echues-OK.pdf';
import docWordGuideNonRenouvellement from '../../assets/documents/guides/Fiche_procedure_renouvellement_et_reprise_pour_non_renouvellement.pdf';
import docWordGuideNonRenouvellementDoc from '../../assets/documents/guides/modele-procedure-de-non-renouvellement.docx';
import docWordGuideRenouvellementDoc from '../../assets/documents/guides/modele-procedure-de-renouvellement.docx';
import docWordGuideRepriseConcession from '../../assets/documents/guides/Le-Guide-1_La-reprise-de-concession.pdf';
import docWordGuideRepriseAbandon from '../../assets/documents/guides/Fiche_procedure_reprise_par_abandon.pdf';
import docWordGuideRepriseAbandonDoc from '../../assets/documents/guides/modele-procedure-de-reprise-par-abandon.docx';

import docWordGuideRetrocession from '../../assets/documents/guides/Fiche_procedure_retrocession.pdf';
import docWordGuideRetrocessionDoc from '../../assets/documents/guides/modele-procedure-de-retrocession.docx';
import docWordGuidesaisieActe from '../../assets/documents/guides/Le-Guide-3_La-Saisie-des-actes-de-concession.pdf';
import docWordGuideExhumation from '../../assets/documents/guides/Le-Guide-4_Exhumation.pdf';
import docWordGuideConcessionApres from '../../assets/documents/guides/Le-Guide-5_La-concession-apres-le-deces-du-concessionnaire.pdf';

import React from 'react';
import {Tab} from "react-bootstrap";
import {Nav} from "react-bootstrap";
import $ from 'jquery';
import { Link } from 'react-router-dom'
import {connect} from "react-redux";
import {fetchApi} from "../../actions";
import user from "../../helpers/user";
import Notifications from "./Notifications";
import axios from "axios";
import Select from "react-select";
import workspace from "../../helpers/workspace";
import {logoStyle} from "../../utils/logoStyle";

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            espaces              : [],
            nbNotif              : 0,
            notificationsNonLues : [],
            logo                 : ''
        }
    }

    setNbNotif = (notifs) => {
        this.setState({
            nbNotif              : notifs.length,
            notificationsNonLues : notifs
        })
    }

    /* Ouvre la sidebar pour la gestion du compte */
    ouvrirNav() {
        document.getElementById("sidePanelNotifications").style.width = "0";
        document.getElementById("sidePanelCompte").style.width = "250px";
        document.querySelector('.overlay').style.visibility = "visible";
    }

    /* Ferme la sidebar pour la gestion du compte */
    fermerNav() {
        document.getElementById("sidePanelCompte").style.width = "0";
        document.querySelector('.overlay').style.visibility = "hidden";
    }

    /* Ouvre la sidebar pour les notifications */
    ouvrirNotif = () => {
        document.getElementById("sidePanelCompte").style.width = "0";
        document.getElementById("sidePanelNotifications").style.width = "400px";
        document.querySelector('.overlay').style.visibility = "visible";
        if (this.state.notificationsNonLues.length > 0) {
            this.updateNotifsNonLues()
        }
    }

    /* Ferme la sidebar pour les notifications */
    fermerNotif() {
        document.getElementById("sidePanelNotifications").style.width = "0";
        document.querySelector('.overlay').style.visibility = "hidden";
    }

    stopPropagation(e) {
        e.stopPropagation();
    }

    closeAll = () => {
        this.fermerNav();
        this.fermerNotif();
    }

    logout = () => {
        user.logout()
        window.location = "/"
    }

    updateNotifsNonLues = () => {
        this.state.notificationsNonLues.map(notif => {
            const id = notif['_id']
            delete notif['_id']
            axios.put(process.env.REACT_APP_ES + 'logicim_' + localStorage.getItem('espace_id') + '_notifications/_doc/' + id, {
                ...notif,
                dateLue : '2022-10-20'
            })
        })
        this.setState({nbNotif : 0})
    }

    componentDidMount() {

        this.changeWorkspace = workspace.changeWorkspace.bind(this)

        $('#v-ressources-tab a').on('click', function (e) {
            e.preventDefault()
            $(this).tab('show')
        })

        axios.get('/users/espaces').then((result) => {
            this.state.espaces = result.data
        })

        let espaceId = localStorage.getItem('espace_id')

        let societeId = localStorage.getItem('societe_id')
        if (espaceId && societeId) {
            axios.get(process.env.REACT_APP_API_URL + '/societes/' + societeId).then((res) => {
                this.setState({
                    logo: res.data.logo ? process.env.REACT_APP_MEDIA_URL + res.data.logo.nom : null,
                })
            })
        }
        if (espaceId && societeId && window.location.href.indexOf('switch-espace') == -1 ) {

            this.props.fetchApi({
                url: "/sessions/eSpace",
                method: "POST",
                data: {
                    eSpace: espaceId
                },
                type: "ESPACE",
                onSuccess: () => {
                    this.props.fetchApi({
                        url: "/sessions/societe",
                        method: "POST",
                        data: {
                            societe: societeId
                        },
                        type: "SOCIETE",
                        onSuccess: () => {
                        }
                    })
                }
            })
            localStorage.setItem("espace_id", espaceId)
        }
    }

    render() {
        const _societe = this.state.espaces
        let nbCimetieres = 0;
        if (this.state.espaces.length) {
            this.state.espaces.forEach((item) => {
                nbCimetieres += item.espaces.length
            })
        }
        let myuser = localStorage.getItem(user.USER_LOCAL_KEY)
        if (!myuser) return (
            <div className="text-center">
                <Link to="/emplacement" className="navbar-brand" href={process.env.PUBLIC_URL}>
                    <img src={logo} alt="logo" className="img-fluid navbar-logo"/>
                </Link>
            </div>
        );

        myuser = JSON.parse(myuser)

        return (
            <nav className="navbar navbar-expand-lg py-3" id="navbar">
                <div className="container-fluid">
                    <div className="overlay" onClick={this.closeAll}></div>
                    <div id="sidePanelCompte" className="sidepanel">
                                    <img src={back} className="closebtn" onClick={this.fermerNav} alt=""/>
                                    <div className="d-flex flex-column align-items-center">
                                        <img src={ this.state.logo ? (this.state.logo) : troyes} className="img-fluid" width="50%" alt=""/>
                                        <p className="soleilBold-fonts mt-5  p-0 " style={{"hyphens": "auto", wordBreak: 'break-word', textAlign: "center"}}>{myuser.email}</p>
                                        <div className="btn-compte">
                                            {}
                                            <Link to="/gestion-compte" className="soleilBold-fonts p-0 mt-2 "
                                                  onClick={this.fermerNav} id="gerer-compte-link">Gérer mon compte</Link>
                                            <Link to="/gestion-donnees" className="soleilBold-fonts p-0 mt-2 "
                                                  onClick={this.fermerNav}>Gérer mes données</Link>
                                            {
                                                nbCimetieres > 1 ?
                                                    <Select
                                                        onChange={(e) => this.changeWorkspace(this.props, e.value, true)}
                                                        defaultValue="${this.props.espace.data.id}--${this.props.societe.data.id}"
                                                        label="Choisissez un cimetière"
                                                        placeholder="Choix cimetière"
                                                        options={Object.values(_societe).map(societe => {
                                                            return {
                                                                label: societe.societe.nom,
                                                                logo: societe.societe.logo,
                                                                options: societe.espaces.map(espace => {
                                                                    return {
                                                                        'label': espace.nom,
                                                                        'value': espace.id + '--' + societe.societe.id,
                                                                        'logo' : espace.logo
                                                                    }
                                                                })
                                                            }
                                                        })}
                                                        styles={logoStyle}
                                                    />

                                                    : ''
                                            }
                                        </div>

                                        <div className="sous-menu-compte">
                                            <Link to="/aide" className="mt-5 d-flex justify-content-center"
                                                  onClick={this.fermerNav} id="centre-aide-link">
                                                <img src={aide} className="img-fluid mr-1" width='18px' alt=""/>
                                                Centre d'aide
                                            </Link>
                                        </div>

                                        <a className="soleilBold-fonts deco mt-5 p-0" onClick={this.logout}>Déconnexion</a>
                                        <a className="soleilRegular-fonts mtn-lg"> Mentions légales</a>
                                    </div>
                                </div>
                                {
                                    localStorage.getItem('espace_id') ?
                                        <Notifications fermerNotif={this.fermerNotif} setNbNotif={this.setNbNotif}/>
                                        :''
                                }
                                <Link to='/concessions' className="navbar-brand" href={process.env.PUBLIC_URL}>
                                    <img src={logo} alt="logo" className="img-fluid navbar-logo"/>
                                </Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#logicim-navbar-links"
                                        aria-controls="logicim-navbar-links" aria-expanded="false"
                                        aria-label="Toggle navigation">
                                    &#9776;
                                </button>
                                {!myuser ? '' :
                                    <div className="collapse navbar-collapse" id="logicim-navbar-links">
                                        <ul className="navbar-nav ml-auto pt-4 pt-lg-0">
                                            <li className="nav-item dropdown ml-lg-5">
                                                <a className="nav-link dropdown-toggle soleilBold-fonts pr-2" href="#"
                                                   role="button" data-toggle="dropdown" id="ressources-item-head">
                                                    Ressources
                                                </a>
                                                <Tab.Container id="left-tabs-example" className="ressources-menu" defaultActiveKey="reglementaire" onClick={this.stopPropagation}>
                                                    <div className="dropdown-menu dropdown-menu-right ressources-menu"
                                                         aria-labelledby="navbarDropdown" onClick={this.stopPropagation}>
                                                        <div className="row d-flex">
                                                            <Nav variant="pills" className="flex-column ressources-sous-menu px-0">
                                                                <Nav.Item className="v-ressources-tab">
                                                                    <Nav.Link eventKey="reglementaire" className="soleilBold-fonts">Réglementation</Nav.Link>
                                                                </Nav.Item>
                                                                {/*
                                                                <Nav.Item className="v-ressources-tab">
                                                                    <Nav.Link eventKey="procédures" className="soleilBold-fonts">Procédures</Nav.Link>
                                                                </Nav.Item>
                                                                */}
                                                                <Nav.Item className="v-ressources-tab">
                                                                    <Nav.Link eventKey="doc-words" className="soleilBold-fonts">Modèles de documents Word</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item className="v-ressources-tab">
                                                                    <Nav.Link eventKey="guides-modeles" className="soleilBold-fonts">Guides, fiches schématiques et modèles</Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                            <div className="col">
                                                                <Tab.Content>
                                                                    <Tab.Pane eventKey="reglementaire">
                                                                        <div className="d-flex flex-wrap scroll-liste ml-3">
                                                                            <div className="ressources-liens d-flex flex-column mr-5">
                                                                                <p className="purple soleilBold-fonts mt-2">Réglementaire</p>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000023512760/">Transport
                                                                                    de corps après la mise en bière</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000023512709/">Transport
                                                                                    de corps avant la mise en bière</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000033159771/">Surveillance
                                                                                    des opérations</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000034764277/">Soins
                                                                                    de conservation</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000023508564/">Sites
                                                                                    cinéraires </a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000034764289/">Service
                                                                                    des pompes funèbres </a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000034480129/">Opérations
                                                                                    consécutives au décès</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000023512694/">Moulage</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000035971790/">Mise
                                                                                    en bière et fermeture du cercueil</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042661737/">Inhumation</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000033159758/">Exhumation</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000041765621/">Dépôt
                                                                                    temporaire</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042661730/">Crémation</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000006394801/2021-05-12/">Conseil
                                                                                    National des Opérations Funéraires</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000006396026/">Concession</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000023512831/">Cimetière</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000034764335/">Chambre
                                                                                    funéraire, chambre mortuaire et
                                                                                    crématorium</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000041808837/">Certificat
                                                                                    de décès</a>
                                                                            </div>
                                                                            <div
                                                                                className="ressources-liens d-flex flex-column">
                                                                                <p className="purple soleilBold-fonts mt-2">Législatif</p>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000006390310/">Service
                                                                                    extérieur des pompes funèbres</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000031932403/">Service
                                                                                    des pompes funèbres</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000006390317/">Sanctions
                                                                                    pénales</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000031643378/">Qualifications
                                                                                    professionnelles européennes</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000006390186/">Police
                                                                                    des funérailles et des lieux de
                                                                                    sépulture</a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000038791034/2021-05-12/">Monument
                                                                                    funéraire en péril </a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000019983205/">Les
                                                                                    concessions </a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000019983164/">Les
                                                                                    cimetières </a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000006390323/">Équipements
                                                                                    funéraires </a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000006390336/">Dispositions
                                                                                    diverses et transitoires </a>
                                                                                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000019983175/">Destination
                                                                                    des cendres </a>
                                                                            </div>
                                                                        </div>
                                                                    </Tab.Pane>
                                                                    <Tab.Pane eventKey="procédures">
                                                                        <div className="col-9">
                                                                            <div className="ressources-liens d-flex flex-column">
                                                                                <p className="purple soleilBold-fonts mt-2 col-7 px-0">Guides, fiches schématiques et modèles</p>
                                                                                <a href="#">Modification de destination d'une concession</a>
                                                                                <a href={ficheRetrocession} target="_blank">Fiche schématique - Rétrocession</a>
                                                                                <a href={ficheRepriseAbandon} target="_blank">Fiche schématique - Reprise après constat d'abandon</a>
                                                                                <a href={ficheRenouvellement} target="_blank">Fiche schématique - Renouvellement et reprise pour non renouvellement</a>
                                                                                <a href={ficheDonation} target="_blank">Fiche schématique - Donation</a>
                                                                                <a href={ficheConversion} target="_blank">Fiche schématique - Conversion</a>
                                                                                <a href={modeleRetrocession}>Dossier de procédure de rétrocession</a>
                                                                                <a href={modeleRepriseNonRenouv}>Dossier de procédure de reprise de concession pour non renouvellement</a>
                                                                                <a href={modeleRepriseApresConstat}>Dossier de procédure de reprise de concession après constat d'abandon</a>
                                                                                <a href={modeleRenouvellement}>Dossier de procédure de renouvellement</a>
                                                                                <a href={modeleDonation}>Dossier de procédure de donation</a>
                                                                                <a href={modeleConversion}>Dossier de procédure de conversion</a>
                                                                            </div>
                                                                        </div>
                                                                    </Tab.Pane>
                                                                    <Tab.Pane eventKey="doc-words">
                                                                        <div class="d-flex flex-wrap scroll-liste ml-3">
                                                                            <div className="ressources-liens d-flex flex-column mr-5">
                                                                                <p className="purple soleilBold-fonts mt-2 col-7 px-0">Formulaires concessions</p>
                                                                                <a href={docWordModeleTitreRecette} target="_blank">Titre de recette </a>
                                                                                <a href={docWordModeleActeDeConcession} target="_blank">Acte de concession</a>
                                                                                <a href={docWordDemandeConcessionFuneraire} target="_blank">Demande de concession funéraire</a>
                                                                            </div>
                                                                            <div className="ressources-liens d-flex flex-column mr-5">
                                                                                <p className="purple soleilBold-fonts mt-2 col-7 px-0">Formulaires opérations</p>
                                                                                <a href={docWordFOActeNotoriete} target="_blank">Acte de notoriété</a>
                                                                                <a href={docWordFOAutorisationCremation} target="_blank">Autorisation de crémation</a>
                                                                                <a href={docWordFODptProvisoireCorps} target="_blank">Autorisation de déplacement provisoire de corps</a>
                                                                                <a href={docWordFOFermetureCercueil} target="_blank">Autorisation de fermeture de cercueil</a>
                                                                                <a href={docWordFOAutorisationMoulage} target="_blank">Autorisation de moulage</a>
                                                                                <a href={docWordFOAutorisationEmbaumement} target="_blank">Autorisation d'embaumement</a>
                                                                                <a href={docWordFOAutorisationExhumation} target="_blank">Autorisation d'exhumation</a>
                                                                                <a href={docWordFOAutorisationInhumation} target="_blank">Autorisation d'inhumation</a>
                                                                                <a href={docWordFOBonIndigence} target="_blank">Bon d'indigence</a>
                                                                                <a href={docWordFOChangementTypeConcession} target="_blank">Changement de type de concession</a>
                                                                                <a href={docWordFOConstatationDeces} target="_blank">Constatation de décès</a>
                                                                                <a href={docWordFODateInconnueDeces} target="_blank">Date inconnue du décès</a>
                                                                                <a href={docWordFODecesPersonneInconnue} target="_blank">Décès d'une personne inconnue</a>
                                                                                <a href={docWordFODemandeAutorisationDispersionJS} target="_blank">Demande d'autorisation de disprsion des cendres dans un jardin du souvenir</a>
                                                                                <a href={docWordFODemandeAdminissionFuneraire} target="_blank">Demande d'autorisation d'admission en chambre funéraire</a>
                                                                                <a href={docWordFODemandeAutorisationCremation} target="_blank">Demande d'autorisation de crémation</a>
                                                                                <a href={docWordFODemandeAutorisationCaveau} target="_blank">Demande d'autorisation de déplacement provisoire dans un caveau</a>
                                                                                <a href={docWordFODemandeAutorisationMoulage} target="_blank">Demande d'autorisation de moulage</a>
                                                                                <a href={docWordFODemandeAutorisationEmbaumement} target="_blank">Demande d'autorisation d'embaumement</a>
                                                                                <a href={docWordFODemandeAutorisationExhumation} target="_blank">Demande d'autorisation d'exhumation</a>
                                                                                <a href={docWordFODemandeAutorisationSC} target="_blank">Demande d'autorisation pour la destinée de l'urne ou des cendres en cimetière ou site cinéraire</a>
                                                                                <a href={docWordFODemandeAutorisationPN} target="_blank">Demande d'autorisation pour la destinée des cendres dans une propriété privée ou en pleine nature</a>
                                                                                <a href={docWordFODeliberationTranslationCimetiere} target="_blank">Délibération translation cimetière</a>
                                                                                <a href={docWordFOModificationDureeTarifs} target="_blank">Modification des durées et ou tarifs du cimetière</a>
                                                                                <a href={docWordFODenonciationActeDeces} target="_blank">Dénonciation de l'acte de décès</a>
                                                                                <a href={docWordFOPVArriveeCF} target="_blank">PV d'arrivée en chambre funéraire</a>
                                                                                <a href={docWordFOPVCremation} target="_blank">PV de crémation</a>
                                                                                <a href={docWordFOPVDepartCF} target="_blank">PV de départ en chambre funéraire</a>
                                                                                <a href={docWordFOPVDptTemporaireCorps} target="_blank">PV de déplacement temporaire d'un corps</a>
                                                                                <a href={docWordFOPVMoulage} target="_blank">PV de moulage</a>
                                                                                <a href={docWordFOPVEmbaumement} target="_blank">PV d'embaumement</a>
                                                                                <a href={docWordFOPVExhumation} target="_blank">PV d'exhumation</a>
                                                                                <a href={docWordFOTranscriptionActeDeces} target="_blank">Transcription de l'acte de décès</a>
                                                                            </div>
                                                                        </div>
                                                                    </Tab.Pane>
                                                                    <Tab.Pane eventKey="guides-modeles">
                                                                        <div className="col-9">
                                                                            <div className="ressources-liens d-flex flex-column">
                                                                                <p className="purple soleilBold-fonts mt-2 col-7 px-0">La conversion</p>
                                                                                <a href={docWordGuideConversion} target="_blank">Fiche schématique</a>
                                                                                <a href={docWordGuideConversionDoc} target="_blank">Dossier de procédure de conversion</a>
                                                                            </div>
                                                                            <div className="ressources-liens d-flex flex-column">
                                                                                <p className="purple soleilBold-fonts mt-2 col-7 px-0">La donation</p>
                                                                                <a href={docWordGuideDonation} target="_blank">Fiche schématique</a>
                                                                                <a href={docWordGuideDonationDoc} target="_blank">Dossier de procédure de donation</a>
                                                                            </div>
                                                                            <div className="ressources-liens d-flex flex-column">
                                                                                <p className="purple soleilBold-fonts mt-2 col-7 px-0">Le renouvellement et la reprise pour non renouvellement</p>
                                                                                <a href={docWordGuideConcessionEchues} target="_blank">Guide - Concession échues</a>
                                                                                <a href={docWordGuideNonRenouvellement} target="_blank">Fiche schématique</a>
                                                                                <a href={docWordGuideNonRenouvellementDoc} target="_blank">Dossier de procédure de non renouvellement</a>
                                                                                <a href={docWordGuideRenouvellementDoc} target="_blank">Dossier de procédure de renouvellement</a>
                                                                            </div>
                                                                            <div className="ressources-liens d-flex flex-column">
                                                                                <p className="purple soleilBold-fonts mt-2 col-7 px-0">La reprise de concession</p>
                                                                                <a href={docWordGuideRepriseConcession} target="_blank">Guide - Reprise de Concession</a>
                                                                                <a href={docWordGuideRepriseAbandon} target="_blank">Fiche schématique</a>
                                                                                <a href={docWordGuideRepriseAbandonDoc} target="_blank">Dossier de procédure de reprise par abandon</a>
                                                                            </div>
                                                                            <div className="ressources-liens d-flex flex-column">
                                                                                <p className="purple soleilBold-fonts mt-2 col-7 px-0">La rétrocession</p>
                                                                                <a href={docWordGuideRetrocession} target="_blank">Fiche schématique</a>
                                                                                <a href={docWordGuideRetrocessionDoc} target="_blank">Dossier de procédure de rétrocession</a>
                                                                            </div>
                                                                            <div className="ressources-liens d-flex flex-column">
                                                                                <p className="purple soleilBold-fonts mt-2 col-7 px-0">La saisie des actes de concessions</p>
                                                                                <a href={docWordGuidesaisieActe} target="_blank">Guide - Saisie des actes</a>
                                                                            </div>
                                                                            <div className="ressources-liens d-flex flex-column">
                                                                                <p className="purple soleilBold-fonts mt-2 col-7 px-0">L’exhumation</p>
                                                                                <a href={docWordGuideExhumation} target="_blank">Guide - Exhumation</a>
                                                                            </div>
                                                                            <div className="ressources-liens d-flex flex-column">
                                                                                <p className="purple soleilBold-fonts mt-2 col-7 px-0">La concession après le décès du concessionnaire </p>
                                                                                <a href={docWordGuideConcessionApres} target="_blank">Guide - Concession après décès du concessionnaire</a>
                                                                            </div>
                                                                        </div>
                                                                    </Tab.Pane>
                                                                </Tab.Content>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Container>
                                            </li>
                                            <li className="nav-item ml-lg-5 not-mobile" id="link-to-notes">
                                                <Link to="/notes" className="nav-link soleilBold-fonts" href="#">
                                                    <img src={notes} alt="" className="img-fluid"/>
                                                </Link>
                                            </li>
                                            <li className="nav-item ml-lg-3 not-mobile" id="link-to-calendar">
                                                <Link to="/calendrier" className="nav-link soleilBold-fonts" href="#">
                                                    <img src={calendar} alt="" className="img-fluid"/>
                                                </Link>
                                            </li>
                                            <li className="nav-item ml-lg-3 not-mobile" onClick={this.ouvrirNotif} id='open-notifs'>
                                                <a className="nav-link soleilBold-fonts menu-bell-icon">
                                                    <img src={bell} alt="" className="img-fluid"/>
                                                    {
                                                        this.state.nbNotif > 0 ? <span>{this.state.nbNotif}</span> : ''
                                                    }
                                                </a>
                                            </li>
                                            <li className="nav-item ml-lg-5" onClick={this.ouvrirNav}>
                                                <a className="nav-link soleilBold-fonts" href="#" id="link-commune">
                                                    <img src={commune} alt="" className="img-fluid mt--2"/> &nbsp;
                                                    <span>Commune</span>
                                                </a>
                                            </li>
                                            <li className="nav-item text-center d-lg-none">
                                                <a className="soleilBold-fonts" href="#">
                                                    <img src={notes} alt="" className="img-fluid"/>
                                                </a>
                                                <a className="soleilBold-fonts mx-4" href="#">
                                                    <img src={calendar} alt="" className="img-fluid"/>
                                                </a>
                                                <a className="soleilBold-fonts menu-bell-icon">
                                                    <img src={bell} alt="" className="img-fluid"/>
                                                    {
                                                        this.state.nbNotif > 0 ? <span>{this.state.nbNotif}</span> : ''
                                                    }
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </nav>

        );
    }
}

const mapStateToProps = ({ data = {}, isLoadingData = false, societe = {}, currentSociete = {}  }) => ({
    data,
    isLoadingData,
    societe,
    currentSociete
});
export default connect(
    mapStateToProps,
    {
        fetchApi
    }
)(Header);
