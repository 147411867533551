import React, { Component } from 'react';
import pdfIconTbl from '../../assets/images/pdf-icon-tbl.svg';
import filterIconSubmenu from '../../assets/images/filter-icon-submenu.svg';
import Pagination from "../../components/pagination/Pagination";
import DateTools from "../../components/utils/DateTools";
import {Link} from "react-router-dom";
import AjoutNouveau from "../../components/concession/AjoutNouveau";
import OrderBy from "../../components/utils/OrderBy";
import GetPDF from "../../components/utils/GetPDF";
import GetListePDF from "../../components/utils/GetListePDF";

class AssistancesListe extends Component {

    constructor(props) {
        super(props);
        this.paginationRef = React.createRef();
        this.state = {
            selected  : 0,
            procedures: [],
            total     : 0,
            orderBy   : null,
            sortBy    : null,
            selectedId: [],
            search    : '',
            typeExt   : 'pdf'
        }
    }

    updateState = (res) => {
        this.setState({
            procedures: res.data.data,
            total     : res.data.total,
        })
    }

    refreshPagination () {
        this.paginationRef.current.changePage(1)
    }

    handleCheck = (e) => {
        let parent     = e.target.parentElement.parentElement
        let selectedId = this.state.selectedId
        let number     = this.state.selected

        if (e.target.checked === true) {
            selectedId.push(parent.getAttribute('data-key'))
            number+=1
        } else {
            const index = selectedId.indexOf(parent.getAttribute('data-key'));
            if (index > -1) {
                selectedId.splice(index, 1);
                number-=1
            }
        }
        this.setState({
            selected  : number,
            selectedId: selectedId
        });
    }

    getUrlProcedure = (type) =>{
        switch (type) {
            case 'Reprise pour non renouvellement' :
                return 'repriseNonRenouvellement'
            case 'Reprise après constat d\'abandon' :
                return 'repriseAbandon'
            default:
                return type.toLowerCase().replace('é', 'e')
        }
    }

    sort = (sortBy) => {
        if (this.state.sortBy === sortBy) {
            this.setState(prevState =>{
                return{
                    ...prevState,
                    orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
                }
            })
        } else {
            this.setState({
                sortBy : sortBy,
                orderBy: 'asc'
            })
        }
    }

    render() {
        const tableRows = this.state.procedures.map((p) => {
            let concessionnaire = ""
            if (p.location) {
                 concessionnaire = p.location.contacts.map((c, index) => {
                     if(c.typeDeContact === "Concessionnaire") {
                         return <span key={index}>{c.nom} {c.prenom}</span>
                     }
                })
            }
            return (
                <tr key={[p.type, p.id]} data-key={[p.type, p.id]} className={this.state.selectedId.includes([p.type, p.id].toString()) ? 'liste-border' : 'liste-border-none'}>
                    <td>
                        <input type="checkbox" className="table-checkbox" onChange={this.handleCheck} checked={this.state.selectedId.includes([p.type, p.id].toString())}/>
                        {p.emplacement.carre} {p.emplacement.allee}
                    </td>
                    <td>
                        <span className="family_no">
                            {p.emplacement.numero1}
                            {p.emplacement.numero2}
                            {p.emplacement.numero3}
                            {p.emplacement.numero4}
                        </span>
                    </td>
                    <td>{p.location ? p.location.famille : '-'}</td>
                    <td className="black">{p.type}</td>
                    <td className="black">{p.date_debut ? DateTools.formatDate(p.date_debut) : '-'}</td>
                    <td>
                        {
                            p.date_fin ?
                                <span className="tbl bk-green text-uppercase">réalisé</span>
                                :
                                <span className="tbl bk-orange text-uppercase">en cours</span>
                        }
                    </td>
                    <td className="black">{concessionnaire}</td>
                    <td>
                        <Link to={{
                            pathname: "/" + this.getUrlProcedure(p.type) + "/" + p.emplacement.id,
                        }} className="edit-tbl">
                            consulter
                        </Link>
                    </td>
                    <td className="d-none">
                        <p className="exporter-tbl cursor" onClick={() => GetPDF('procedure', p.id, {'procedure' : p.type})}>
                            <img src={pdfIconTbl} alt="" className="img-fluid" />
                            exporter
                        </p>
                    </td>
                </tr>
            )
        })
        return(
            <div>
                <div className="mx-3">
                    <div className="table-responsive px-3 scroll listes-leftpanel">
                        <table className="table table-striped table-borderless">
                            <thead>
                            <tr>
                                <th className="sortStyle">
                                    Carré / Allée
                                    <span onClick={() => this.sort('allee')}>
                                        <OrderBy isClicked={this.state.sortBy === 'allee'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">N°</th>
                                <th className="sortStyle">
                                    Famille
                                    <span onClick={() => this.sort('famille')}>
                                        <OrderBy isClicked={this.state.sortBy === 'famille'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    Type
                                    <span onClick={() => this.sort('type')}>
                                        <OrderBy isClicked={this.state.sortBy === 'type'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    Date début
                                    <span onClick={() => this.sort('date_debut')}>
                                        <OrderBy isClicked={this.state.sortBy === 'date_debut'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">État</th>
                                <th className="sortStyle">
                                    Concessionnaire
                                </th>
                                <th colSpan="2" className="sortStyle">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {tableRows}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="container-fluid py-3 px-lg-5">
                    <div className="row d-flex justify-content-between">
                        <AjoutNouveau etat="Occupé"/>
                        <Pagination
                            ref          = {this.paginationRef}
                            url          = '/procedures/getAllByEspace/'
                            eSpaceId     = {localStorage.getItem('espace_id')}
                            type         = {null}
                            sort         = {this.state.sortBy}
                            order        = {this.state.orderBy}
                            filters      = {this.props.filters}
                            search       = {this.state.search}
                            updateState  = {this.updateState}
                        />
                        <div
                            className="list-button d-flex justify-content-center justify-content-sm-end align-items-center mt-4 mt-sm-0">
                            <div>
                                <input type="search" onKeyUp={(e) => {this.setState((prevState) => {
                                    return {
                                        ...prevState,
                                        search: e.target.value
                                    }
                                })}} placeholder="Recherche" className={"form-control"} />
                            </div>
                            <div>
                                <button type="button" className="export-locations-list-btn d-flex pointer cursor" onClick={() => GetListePDF('procedure')}>
                                    <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                    <span className="soleilBold-fonts">EXPORTER LISTE <br/> PROCEDURES</span>
                                </button>
                                <button type="button" className="export-locations-list-btn d-flex pointer cursor" onClick={() => GetListePDF('procedure', null, 'csv')}>
                                    <img src={filterIconSubmenu} alt="" className="img-fluid"/>
                                    <span className="soleilBold-fonts">EXPORTER EXCEL <br/> PROCEDURES</span>
                                </button>
                            </div>
                            <div>
                            <select name="" className="form-control mb-2" id="" value={this.state.typeExt} onChange={(e) => {
                                this.state.typeExt = e.target.value
                            }}>
                                <option value="pdf">PDF</option>
                                <option value="csv">Excel</option>
                            </select>
                            <button type="button" className="listing-complete-btn soleilBold-fonts cursor" onClick={() => GetListePDF('procedure',  this.state.selectedId, this.state.typeExt)}>
                                Exporter la sélection
                                <span className="slt-nb">{ this.state.selected }</span></button>
                        </div>
                            <button type="button" className="listing-incomplete-btn soleilBold-fonts">Liste complète (
                                {this.state.total} )
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AssistancesListe;