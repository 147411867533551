import $ from 'jquery';
import StepsMainFunctions from "./StepsMainFunctions";

const steps = [
    {
        id: 'ajout-defunt-1',
        scrollTo: true,
        attachTo: { element: '#ajout-defunt', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            }
        ],
        beforeShowPromise: function() {
            return StepsMainFunctions.PromiseListe('concessions', "#concessions-path", this)
        },
        when: {
            show() {
                if(window.location.pathname === '/aide') {
                    StepsMainFunctions.observer('concession', '#concessions-path', this)
                } else {
                    if(document.getElementById('ajout-defunt')) {
                        if (this.tour && this.tour.steps) {
                            this.tour.steps.splice(1, 2)
                        }
                        StepsMainFunctions.watchDivWithHeight('ajout-defunt-fenetre', '#ajout-defunt', this)
                        StepsMainFunctions.showRewrite(this)
                    } else {
                        this.updateStepOptions({text : 'Cet emplacement est vide, vous ne pouvez pas ajouter de défunts. Relancez ce tutoriel sur un emplacement attribué ou créez une concession.'})
                    }
                }
            },
            hide() {
                $('#concessions-path').off('click')
            },
            cancel() {
                $('#concessions-path').off('click')
            },
            destroy() {
                $('#concessions-path').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur ce bouton pour ouvrir la fenêtre de gestion des défunts.</p>`,
    },
    {
        id: 'fiche-toLocation-0',
        attachTo: {element: '.contents.content-margin', on: 'top'},
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
        ],
        when: {
            show() {
                StepsMainFunctions.showRewrite(this)
                StepsMainFunctions.locationToEmplacement('.edit-tbl.concession', 'ajout-defunt-fenetre', this)
            },
            hide() {
                $('.edit-tbl.concession').off('click')
            },
            cancel() {
                $('.edit-tbl.concession').off('click')
            },
            destroy() {
                $('.edit-tbl.concession').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur <span class="purple">"consulter"</span> pour la <span class="purple">concession</span> où vous souhaitez ajouter un défunt.</p>`,
    },
    {
        id: 'ajout-defunt-1',
        attachTo: { element: '#ajout-defunt', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            }
        ],
        when: {
            show() {
                StepsMainFunctions.watchDivWithHeight('ajout-defunt-fenetre', '#ajout-defunt', this)
                StepsMainFunctions.showRewrite(this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur ce bouton pour ouvrir la fenêtre de gestion des défunts.</p>`,
    },
    {
        id: 'ajout-defunt-2',
        attachTo: { element: '#ajout-defunt-fenetre', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette section est destiné à la <span class="purple">gestion des défunts</span>. Vous pouvez consulter, éditer, ajouter ou supprimer un défunt.</p>`,
    },
    {
        id: 'ajout-defunt-3',
        attachTo: { element: '.menu-trois-points', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez fermer à tout moment cette section via ce <span class="purple">menu</span>. Il vous permet aussi de déplacer ou supprimer un défunt.</p>`,
    },
    {
        id: 'ajout-defunt-4',
        attachTo: { element: '.ajout-defunt-etape', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                $('.defunt-form.civilite').off('click').on('click',function (){
                    isVisible()
                    function isVisible() {
                        const heigthDiv = document.getElementById('etape2').offsetHeight
                        if (heigthDiv > 0) {
                            $this.tour.next()
                        } else {
                            setTimeout(isVisible, 450)
                        }
                    }
                })
            },
            cancel() {
                $('.defunt-form.civilite').off('click')
            },
            destroy() {
                $('.defunt-form.civilite').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette première section concerne la <span class="purple">civilité</span> du défunt. Vous y retrouverez une fois le défunt ajouter les informations concernant sa civilité. Cliquez sur 
                <span class="red">Renseigner</span> pour débuter l'ajout du défunt.</p>`,
    },
    {
        id: 'ajout-defunt-5',
        attachTo: { element: '#etape2 .modal-content', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Terminer',
                type: 'complete'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Remplissez le formulaire en suivant les <span class="purple">étapes</span>. Une fois le formulaire <span class="purple">validé</span>, 
                le défunt sera ajouté à la concession. Vous pourrez accéder à toutes les informations du défunt via la 
                section de <span class="purple">gestion des défunts</span> partie <span class="purple">civilité</span>.</p>`,
    },
]

export default steps