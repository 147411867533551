import React from 'react';
import {Route, Switch} from 'react-router-dom';
import { PrivateRoute } from './components/utils/PrivateRoute';
import Home from './pages/home/Home';
import List from './pages/list/List';
import Login from './components/login/Login';
import LostPassword from './components/login/LostPassword';
import Register from './components/login/Register';
import SwitchEspace from './components/login/SwitchEspace';
import EmplacementView from './pages/emplacement/View';
import EmplacementEdit from './pages/emplacement/Edit';
import DrawEmplacement from './pages/emplacement/DrawEmplacement';
import GetEspace from './pages/emplacement/GetEspace';
import Functionality from './pages/emplacement/Functionality';
import EditeurMCE from './pages/emplacement/EditeurMCE';
import PageNotFound from './pages/emplacement/PageNotFound';
import GestionCompte from './pages/gestionCompte/gestionMenu';
import Documents from './pages/document/Documents';
import EditDocument from './pages/document/EditDocument';
import GestionDonnees from './pages/gestionDonnees/donneesMenu';
import ConcessionsMenu from "./pages/concessions/concessionsMenu";
import DefuntsMenu from "./pages/defunts/DefuntsMenu";
import ConcessionnairesMenu from "./pages/concessionnaires/ConcessionnairesMenu";
import EditeurMenu from "./pages/editeur/EditeurMenu";
import TravauxMenu from "./pages/Travaux/TravauxMenu";
import AssistancesMenu from "./pages/assistances/AssistancesMenu";
import centreAideMenu from "./pages/centreAide/centreAideMenu";
import EmplacementMenu from "./pages/emplacement/EmplacementMenu";
import FicheConcession from "./pages/emplacement/FicheConcession";
import Notes from "./pages/notes/Notes";
import FormAjoutEmplacement from "./pages/emplacement/FormEmplacement";
import Donation from "./pages/procedures/donation/Donation";
import Conversion from './pages/procedures/conversion/conversion';
import Retrocession from "./pages/procedures/retrocession/Retrocession";
import Renouvellement from "./pages/procedures/renouvellement/Renouvellement";
import RepriseNonRenouv from "./pages/procedures/repriseNonRenouvellement/RepriseNonRenouv";
import RepriseAbandon from "./pages/procedures/RepriseAbandon/RepriseAbandon";
import Aide from "./components/assistance/Aide";
import ListeUtilisateurs from "./pages/admin/ListeUtilisateurs";
import CalendrierMenu from "./pages/calendrier/CalendrierMenu";

class Routing extends React.Component {

  render() {
    return (
      <main>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register}/>
          <Route exact path="/lost-password" component={LostPassword}/>
          <PrivateRoute exact path="/switch-espace" component={SwitchEspace}/>
          <PrivateRoute exact path="/" component={Home}/>
          <PrivateRoute exact path="/list" component={List}/>
          <PrivateRoute exact path="/emplacement/:slug" component={EmplacementView}/>
          <PrivateRoute exact path="/function" component={Functionality}/>
          <PrivateRoute exact path="/emplacement" component={EmplacementMenu}/>
          <PrivateRoute exact path="/espace" component={GetEspace}/>
          <PrivateRoute exact path="/post" component={DrawEmplacement}/>
          <PrivateRoute exact path="/emplacement/edit/:slug" component={EmplacementEdit}/>
          <PrivateRoute exact path="/editor-mce" component={EditeurMCE}/>
          <PrivateRoute exact path="/gestion-compte" component={GestionCompte}/>
          <PrivateRoute exact path="/documents" component={Documents}/>
          <PrivateRoute exact path="/edit/document/:id" component={EditDocument}/>
          <PrivateRoute exact path="/ajout-document" component={EditDocument}/>
          <PrivateRoute exact path="/gestion-donnees" component={GestionDonnees}/>
          <PrivateRoute exact path="/concessions" component={ConcessionsMenu}/>
          <PrivateRoute exact path="/editeur" component={EditeurMenu}/>
          <PrivateRoute exact path="/defunts" component={DefuntsMenu}/>
          <PrivateRoute exact path="/utilisateurs" component={ListeUtilisateurs}/>
          <PrivateRoute exact path="/concessionnaires" component={ConcessionnairesMenu}/>
          <PrivateRoute exact path="/travaux" component={TravauxMenu}/>
          <PrivateRoute exact path="/procedures" component={AssistancesMenu}/>
          <PrivateRoute exact path="/aide" component={centreAideMenu}/>
          <PrivateRoute exact path="/aide/:mot" component={centreAideMenu}/>
          <PrivateRoute exact path="/fiche-concession-archive/:emplacementId/:archivedConcessionId" component={FicheConcession} />
          <PrivateRoute exact path="/fiche-concession/:emplacementId" component={FicheConcession} />
          <PrivateRoute exact path="/notes" component={Notes}/>
          <PrivateRoute exact path="/ajout-emplacement" component={FormAjoutEmplacement}/>
          <PrivateRoute exact path="/donation/:emplacementId" component={Donation}/>
          <PrivateRoute exact path="/retrocession/:emplacementId" component={Retrocession}/>
          <PrivateRoute exact path="/conversion/:emplacementId" component={Conversion}/>
          <PrivateRoute exact path="/renouvellement/:emplacementId" component={Renouvellement}/>
          <PrivateRoute exact path="/repriseNonRenouvellement/:emplacementId" component={RepriseNonRenouv}/>
          <PrivateRoute exact path="/repriseAbandon/:emplacementId" component={RepriseAbandon}/>
          <PrivateRoute exact path="/calendrier" component={CalendrierMenu}/>
          <PrivateRoute exact component={PageNotFound}/>
        </Switch>
        {
          window.location.pathname !== '/login' ? <Aide/> : ''
        }
      </main>
     
    );
  }
}

export default Routing;
