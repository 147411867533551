import React, {Component} from 'react';
import './styles.scss';
import { connect } from "react-redux";
import {fetchApi, login} from "../../actions";
import store from "../../store";
import {Link} from "react-router-dom";
import PopupLogged from "./PopupLogged";
import PopupWrongCredential from "./PopupWrongCredential";
import user from "../../helpers/user"
import workspace from "../../helpers/workspace";

class Login extends Component {
    state = {
        username: '',
        password: '',
        user: {}
    }

    componentDidMount() {
        this.changeWorkspace = workspace.changeWorkspace.bind(this);
        localStorage.removeItem('impersonate')
        document.getElementById("root").className = document.getElementById("root").className + " login-page"
    }

    componentWillUnmount() {
        document.getElementById("root").className = document.getElementById("root").className.replace("login-page", "")
    }

    onChangeInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

     login = (e) => {
        e.preventDefault()
        this.props.login(this.state)
    }

    render() {
        if (store.getState() &&  store.getState().user &&  store.getState().user.data  && typeof store.getState().user.data.username === "string" && store.getState().user.data.username !== "" && localStorage.getItem(user.USER_LOCAL_KEY) && store.getState().user.data.espaces.length > 1 /*&& !localStorage.getItem('societe_id')*/) {
          window.location.href = "/switch-espace";
        } else if(store.getState() &&  store.getState().user && store.getState().user.data  && typeof store.getState().user.data.username === "string" && store.getState().user.data.username !== "" && localStorage.getItem(user.USER_LOCAL_KEY) && !localStorage.getItem('societe_id') && store.getState().user.data.espaces.length <= 1) {
            this.changeWorkspace(this.props, store.getState().user.data.espaces[0]+ "--" + store.getState().user.data.societes[0], false)
            console.log('ok ?')
        }

        return (
            <div>
                <div className="container-fluid">
                    <div className="row mt-5 mt-md-0">
                        <div className="col-md-4 mx-auto">

                            <h1 className="text-center mb-5 text-primary">Login {
                                (this.props.user && this.props.user.data)
                                    ? this.props.user.data.username
                                    : ''
                            }</h1>

                            <form onSubmit={this.login} style={{opacity: this.props.isLoadingData ? "0.5" : "1"}}>
                                <div className="form-group">
                                    <label htmlFor="inputUsername" className="sr-only">Identifiant</label>
                                    <input onChange={this.onChangeInput} name="username" type="text" id="inputUsername"
                                           className="form-control" placeholder="Identifiant"/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="inputPassword" className="sr-only">Mot de passe</label>
                                    <input onChange={this.onChangeInput} name="password" type="password"
                                           id="inputPassword"
                                           className="form-control" placeholder="Mot de passe"/>
                                </div>
                                
                                <button onClick={this.login} className="btn btn-outline-primary btn-lg btn-block btn-primary">Se connecter
                                </button>
                                <p className="text-center mt-2">
                                    <Link to="/lost-password">Mot de passe oublié ?</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
                <PopupWrongCredential wrongCredentialVisible={this.props.wrongCredential}/>
                <PopupLogged user={this.props.user.data} visible={this.props.user.data != null}/>
            </div>
        )
    }
}

const mapStateToProps = ({ data = {}, isLoadingData = false, wrongCredential = false, user = {}}) => ({
    data,
    isLoadingData,
    wrongCredential,
    user
});
export default connect(
    mapStateToProps,
    {
        login,
        fetchApi
    }
)(Login);
//export default connect(mapStateToProps,{login})(Login) Ecriture 

