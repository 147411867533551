import {Modal} from "react-bootstrap";
import React, {Component} from "react";
import plusRedButton from "../../assets/images/plus-red-icon.svg";
import axios from "axios";

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact_civilite: 'H',
            contact_nom: '',
            contact_prenom: '',
            contact_fonction: '',
            contact_telephone: '',
            contact_email:'',
            show: false,
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name] : e.target.value})
    }

    ajouterContact = () => {
        axios.post(process.env.REACT_APP_API_URL + '/contacts',{
            civilite : this.state.contact_civilite,
            nom : this.state.contact_nom,
            prenom : this.state.contact_prenom,
            typeDeContact : this.state.contact_fonction,
            telephone : this.state.contact_telephone,
            email : this.state.contact_email,
            eSpace : process.env.REACT_APP_API_URI + '/e_spaces/' + this.props.eSpaceId,
            societes: [process.env.REACT_APP_API_URI + '/societes/' + this.props.societeId]
        }).then(res => {
            this.props.ajouterContact(res.data)
        })
        this.emptyForm()
    }

    modifierContact = () => {
        axios.put(process.env.REACT_APP_API_URL + '/contacts/' + this.props.contact.id, {
            civilite : this.state.contact_civilite,
            nom : this.state.contact_nom,
            prenom : this.state.contact_prenom,
            typeDeContact : this.state.contact_fonction,
            telephone : this.state.contact_telephone,
            email : this.state.contact_email,
        }).then(res => {
            this.props.modifierContact(res.data)
        })
        this.setState({show: false})
    }

    emptyForm = () => {
        this.setState({
            contact_civilite: 'H',
            contact_nom: '',
            contact_prenom: '',
            contact_fonction: '',
            contact_telephone: '',
            contact_email:'',
            show: false,
        })
    }

    updateState = () => {
        this.setState( {
            contact_civilite: this.props.contact.civilite,
            contact_nom: this.props.contact.nom,
            contact_prenom: this.props.contact.prenom,
            contact_fonction: this.props.contact.typeDeContact,
            contact_telephone: this.props.contact.telephone,
            contact_email: this.props.contact.email,
            show: true,
        })
    }

    supprimerContact = () => {
        axios.put(process.env.REACT_APP_API_URL + '/contacts/' + this.props.contact.id, {
            societes: []
        })
        this.props.supprimerContact(this.props.contact)
    }

    render() {
        return (
            <>
                {
                    this.props.contact ?
                        <div className="flex-row mt-3 float-left">
                            <a className="soleilRegular-fonts purple mr-3 cursor" onClick={this.updateState}>Modifier</a>
                            <a className="soleilRegular-fonts red cursor" onClick={this.supprimerContact}>Supprimer</a>
                        </div>
                        :
                        <img src={plusRedButton} alt="" className="img-fluid ml-2 mb-1 cursor" onClick={() => {this.setState({show : true})}}/>
                }
                <Modal show={this.state.show} onHide={() => {this.setState({show : false})}}>
                    <Modal.Header className="bk-grey" closeButton/>
                    <Modal.Body>
                        <p className="soleilBold-fonts title purple m-2">{this.props.contact ? 'modifier contact' : 'nouveau contact'}</p>
                        <div className="form-wrapper mt-3">
                            <div className="d-flex">
                                <label className="col-4 soleilRegular-fonts">Civilité</label>
                                <div className="col-8 d-flex">
                                    <div className="mr-4">
                                        <input type="radio" value="H" id="civilite_H" name="contact_civilite" checked={this.state.contact_civilite === "H"} onChange={this.handleChange}/>
                                        <label htmlFor="civilite_H"
                                               className="soleilRegular-fonts purple radio-label">Monsieur</label>
                                    </div>
                                    <div>
                                        <input type="radio" value="F" id="civilite_F" name="contact_civilite" checked={this.state.contact_civilite === "F"} onChange={this.handleChange}/>
                                        <label htmlFor="civilite_F" className="soleilRegular-fonts purple">Madame</label>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <label className="col-4 soleilRegular-fonts m-0">Nom contact</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="contact_nom" value={this.state.contact_nom} onChange={this.handleChange}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts m-0">Prénom contact</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="contact_prenom" value={this.state.contact_prenom} onChange={this.handleChange}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts m-0">Fonction</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="contact_fonction" value={this.state.contact_fonction} onChange={this.handleChange}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts m-0">Téléphone</label>
                                <input type="number" className="col-8 soleilRegular-fonts" name="contact_telephone" value={this.state.contact_telephone} onChange={this.handleChange}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts m-0">Email</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="contact_email" value={this.state.contact_email} onChange={this.handleChange}/>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="red cancel-btn tbl" onClick={() => {this.setState({show : false})}}>Annuler</button>
                        {
                            this.props.contact ?
                                <button type="button" className="tbl bk-red ml-2" onClick={this.modifierContact}>Modifier</button>
                                :
                                <button type="button" className="tbl bk-red ml-2" onClick={this.ajouterContact}>Ajouter</button>
                        }
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default ContactForm;