import React, {Component} from 'react';
import {Pagination} from "react-bootstrap";
import axios from "axios";

class PaginationComponent extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            pageActuel   : 1,
            totalPages   : 1,
            nbItemParPage: 30,
            sort         : null,
            filters      : {},
            order        : null,
            search       : null,
            isMounted    : false
        }

        this.cancel = null;
    }

    componentDidMount() {
        this._isMounted = true
        this.getListe(1)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sort !== this.state.sort || this.props.order !== this.state.order || this.props.filters !== this.state.filters) {
            this.setState({
                sort: this.props.sort,
                order: this.props.order,
                filters: this.props.filters
            })
            this.getListe(this.state.pageActuel)
        } else if (this.state.search !== this.props.search) {
            this.setState({
                search: this.props.search,
                pageActuel: 1
            })
            this.getListe(this.state.pageActuel)
        }
    }

    changePage = (page) => {
        this.setState({pageActuel: page});
        this.getListe(page);
    }

    getListe = (page) => {
        let url = process.env.REACT_APP_API_URL + this.props.url + localStorage.getItem('espace_id') + '/' + this.state.nbItemParPage
        if(!this.props.eSpaceId) {
            url = process.env.REACT_APP_API_URL + this.props.url + this.state.nbItemParPage
        }

        const CancelToken = axios.CancelToken;

        if (this.cancel) {
            this.cancel()
        }
        axios.post( url, {
            'page'   : page,
            'type'   : this.props.type,
            'filters': this.props.filters,
            'sort'   : this.props.sort,
            'order'  : this.props.order,
            'search' : this.props.search ? this.props.search : null
        }, {
            cancelToken: new CancelToken((c) => this.cancel = c)
        }).then((res) => {
            let totalPages =  Math.ceil(res.data.total / this.state.nbItemParPage)
            if(this._isMounted) {
                this.setState({totalPages: totalPages})
                this.props.updateState(res)
            }
        }).catch((e) => {

        })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        return (
            <React.Fragment>
                <Pagination>
                    {this.state.pageActuel !== 1 ?
                        <React.Fragment>
                            <Pagination.First onClick={() => this.changePage(1)} />
                            <Pagination.Prev onClick={() => this.changePage(this.state.pageActuel-1)}/>
                            <Pagination.Item onClick={() => this.changePage(this.state.pageActuel-1)}>
                                {this.state.pageActuel-1}
                            </Pagination.Item>
                        </React.Fragment>
                        : ''
                    }
                    <Pagination.Item onClick={() => this.changePage(this.state.pageActuel)} active>{this.state.pageActuel}</Pagination.Item>
                    {this.state.pageActuel !== this.state.totalPages ?
                        <React.Fragment>
                            <Pagination.Item onClick={() => this.changePage(this.state.pageActuel + 1)}>
                                {this.state.pageActuel + 1}
                            </Pagination.Item>
                            <Pagination.Next onClick={() => this.changePage(this.state.pageActuel + 1)}/>
                            <Pagination.Last onClick={() => this.changePage(this.state.totalPages)}/>
                        </React.Fragment>
                        : ''
                    }
                </Pagination>
            </React.Fragment>
        )
    }
}

export default PaginationComponent;