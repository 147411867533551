import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import filtreIcon from "../../assets/images/filter-icon-submenu.svg";
import filtreIconBlanc from "../../assets/images/filter-icon-submenu-blanc.svg";
import "../../assets/css/filtrer.css";
import axios from "axios";

function FilterEmplacement(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [carres, setCarres] = useState([]);
    const [allees, setAllees] = useState([]);
    useEffect(() => {
        var url = "/emplacements/getFilters?societe=" + localStorage.getItem('societe_id') + "&espace=" + localStorage.getItem('espace_id');
        axios.get( process.env.REACT_APP_API_URL + url).then((res) => {
            setCarres(res.data['carres']);
            setAllees(res.data['allees']);
        });
    }, [])

    const typesEmplacement = ['Concession', 'Colombarium', 'Cavurne', 'Jardin du Souvenir', 'Ossuaire', 'Caveau provisoire / dépositoire', 'Terrain commun', 'Enfeu'];
    const surfaces = {
        '1': '1m2 et moins',
        '1-2': '1m2 et 2m2',
        '2-3': '2m2 et 3m2',
        '3-4': '3m2 et 4m2',
        '4-5': '4m2 et 5m2',
        '5-6': '5m2 et 6m2',
        '6-7': '6m2 et 7m2',
        '7-8': '7m2 et 8m2',
        '8-9': '8m2 et 9m2',
        '9-10': '9m2 et 10m2',
        '10': '10m2 et plus',
    };

    const handleFilterEmplacementChange = function(event, type) {
        const target = event.currentTarget;

        if (target.checked && props.filters[type].indexOf(target.value) === -1) {
            props.filters[type].push(target.value);
        }

        if (!target.checked && props.filters[type].indexOf(target.value) > -1) {
            props.filters[type] = props.filters[type].filter((value) => {
                return value !== target.value
            })
        }

        props.emplacementListRef.current.refreshPagination();
        props.mapRef.current.refreshPagination();
    }

    return (
        <>
            <span className="soleilRegular-fonts title cursor" onClick={handleShow} id="filtre-title">
                    <img src={filtreIcon}
                         alt=""
                         className="img-fluid mr-2" style={{width: '15px', marginTop: '-3px'}}/>
                {props.title}
            </span>

            <Modal show={show} onHide={handleClose} className="filtre-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <img src={filtreIconBlanc}
                             alt=""
                             className="img-fluid mr-2" style={{width: '15px', marginTop: '-3px'}}/>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-3 px-1 py-2 border-right-filtre">
                            <p className="purple text-uppercase font-weight-bold text-center">Type</p>
                            <div className="mt-3 px-2">
                                {
                                    typesEmplacement.map((item, idx) => {
                                        return (
                                              <div className="d-flex mt-2">
                                                <label className="switch" id="first-switch">
                                                    <input type="checkbox" id={"switch1" + idx}  value={item}  name="filters[typeEmplacement][]"  checked={props.filters.typeEmplacement.includes(item)} onChange={e => handleFilterEmplacementChange(e, 'typeEmplacement')} />
                                                    <span className="slider round"></span>
                                                </label>
                                                <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor={"switch1" + idx}>
                                                    {item}
                                                </label></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-3 px-1 py-2 border-right-filtre">
                            <p className="purple text-uppercase font-weight-bold text-bold text-center">Surface</p>
                            <div className="mt-3 px-2">
                                {
                                    Object.keys(surfaces).map((key, idx) => {
                                        return (
                                            <div className="d-flex mt-2">
                                                <label className="switch" id="first-switch">
                                                    <input type="checkbox" id={"switch2" + idx}  value={key}  name="filters[surface][]"  checked={props.filters.surface.includes(key)} onChange={e => handleFilterEmplacementChange(e, 'surface')} />
                                                    <span className="slider round"></span>
                                                </label>
                                                <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor={"switch2" + idx}>
                                                    {surfaces[key]}
                                                </label></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="col-3 px-1 py-2 border-right-filtre">
                            <p className="purple text-uppercase font-weight-bold text-bold text-center">Carré</p>
                            <div className="mt-3 px-2 mh-100 overflow-auto" style={{
                                height: '450px'
                            }}>
                                <div className={"row mx-0"}>
                                {
                                    carres.map((item, idx) => {
                                        return (
                                                <div className="col-6 d-flex mt-2">
                                                    <label className="switch" id="first-switch">
                                                        <input type="checkbox" id={"switch3" + idx}  value={item}  name="filters[carre][]"  checked={props.filters.carre.includes(item)} onChange={e => handleFilterEmplacementChange(e, 'carre')} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor={"switch3" + idx}>
                                                        {item}
                                                    </label></div>
                                                </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                        <div className="col-3 px-1 py-2">
                            <p className="purple text-uppercase font-weight-bold text-bold text-center">Allée</p>
                            <div className="mt-3 px-2 mh-100 overflow-auto" style={{
                                height: '450px'
                            }}>
                                <div className={"row mx-0"}>
                                    {
                                        allees.map((item, idx) => {
                                            return (
                                                <div className="col-6 d-flex mt-2">
                                                    <label className="switch" id="first-switch">
                                                        <input type="checkbox" id={"switch4" + idx}  value={item}  name="filters[allee][]"  checked={props.filters.allee.includes(item)} onChange={e => handleFilterEmplacementChange(e, 'allee')} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor={"switch4" + idx}>
                                                        {item}
                                                    </label></div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="red cancel-btn tbl" data-dismiss="modal" onClick={handleClose}>Annuler</button>
                    <button id="step-tour-valider" className="tbl btn-navigation bk-red ml-2 valider" onClick={handleClose}>Valider</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default FilterEmplacement;