import communeIcon from '../../assets/images/commune-icon.svg';
import maireIcon from '../../assets/images/maire-icon.svg';
import timerIcon from '../../assets/images/timer-icon.svg';
import React, { Component } from 'react';
import axios from "axios";
import ContactForm from "../../components/gestionCompte/ContactForm";

class InfosMairie extends Component{

    constructor(props) {
        super(props);
        this.state = {
            commune   : '',
            adresse   : '',
            codePostal: '',
            ville     : '',
            email     : '',
            telephone : '',
            logo      : '',
            maireId   : '',
            civilite  : 'H',
            nom       : '',
            prenom    : '',
            signature : '',
            horaires  : [],
            societeId : localStorage.getItem('societe_id'),
            contacts : [],
            tresorerie: '',
            departement: ''
        }
    }

    componentDidMount() {
        if (this.state.societeId) {
            axios.get(process.env.REACT_APP_API_URL + '/societes/' + this.state.societeId).then((res) => {
                this.setState({
                    commune: res.data.commune,
                    adresse: res.data.adresse,
                    codePostal: res.data.codePostal,
                    ville: res.data.ville,
                    email: res.data.email,
                    telephone: res.data.telephone,
                    horaires: res.data.horaires ? res.data.horaires : [],
                    logo: res.data.logo ? process.env.REACT_APP_MEDIA_URL + res.data.logo.nom : null,
                    tresorerie: res.data.tresorerie,
                    departement: res.data.departement
                })
                const maire = res.data.contactsArray.filter(contact => contact.typeDeContact === 'Maire')
                if (maire.length === 1) {
                    this.setState({
                        maireId: maire[0].id,
                        civilite: maire[0].civilite,
                        nom: maire[0].nom,
                        prenom: maire[0].prenom,
                        signature: maire[0].signature ? process.env.REACT_APP_MEDIA_URL + maire[0].signature : null
                    })
                }
                this.setState({contacts: res.data.contactsArray.filter(contact => contact.typeDeContact !== 'maire')})
            })
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name] : e.target.value})
        if(e.target.name === 'civilite') {
            this.updateInfosMaire(e)
        }
    }

    handleHoraires = (e) => {
        const index = e.target.getAttribute('data-index')
        let horaires = this.state.horaires
        horaires[index] = e.target.value
        this.setState({
            horaires : horaires
        })
    }

    updateInfosMairie = (e) => {
        axios.put(process.env.REACT_APP_API_URL + '/societes/' + this.state.societeId, {
            [e.target.name] : e.target.value
        })
    }

    updateInfosMaire = (e) => {
        if (this.state.maireId) {
            axios.put(process.env.REACT_APP_API_URL + '/contacts/' + this.state.maireId, {
                [e.target.name]: e.target.value
            }).then(res => {
                const contacts = this.state.contacts
                const index = contacts.findIndex(contact => contact.typeDeContact === 'Maire')
                if(index >= 0) {
                    contacts[index] = res.data
                }
                this.setState({contacts : contacts})
            })
        } else {
            axios.post(process.env.REACT_APP_API_URL + '/contacts', {
                [e.target.name]: e.target.value,
                civilite     : e.target.name === 'civilite' ? e.target.value : this.state.civilite,
                typeDeContact: 'Maire',
                eSpace       : process.env.REACT_APP_API_URI + '/e_spaces/' + this.state.societeId,
                societes     : [process.env.REACT_APP_API_URI + '/societes/' + this.state.societeId]
            }).then(res => {
                const contacts = this.state.contacts
                contacts.push(res.data)
                this.setState({
                    maireId : res.data.id,
                    civilite: res.data.civilite,
                    nom     : res.data.nom,
                    prenom  : res.data.prenom,
                    contacts: contacts
                })
            })
        }
    }

    updateHoraires = () => {
        axios.put(process.env.REACT_APP_API_URL + '/societes/' + this.state.societeId, {
            horaires : this.state.horaires
        })
    }

    ajouterContact = (contact) => {
        let contacts = this.state.contacts
        contacts.push(contact)
        this.setState({
            contacts : contacts
        })
    }

    modifierContact = (contact) => {
        let contacts = this.state.contacts
        let aModifier = this.state.contacts.filter(c => c.id === contact.id)
        contacts[contacts.indexOf(aModifier[0])] = contact
        this.setState({
            contacts : contacts
        })
        if(contact.typeDeContact === 'Maire') {
            this.setState({
                civilite  : contact.civilite,
                nom       : contact.nom,
                prenom    : contact.prenom,
            })
        }
    }

    supprimerContact = (contact) => {
        let contacts = this.state.contacts
        contacts.splice(contacts.indexOf(contact), 1)
        this.setState({
            contacts : contacts
        })
    }

    updateLogo = (e) => {
        this.uploadFile(e, process.env.REACT_APP_API_URL + '/societes/' + this.state.societeId, 'logo')
    }

    updateSignature = (e) => {
        this.uploadFile(e, process.env.REACT_APP_API_URL + '/contacts/' + this.state.maireId, 'signature')
    }

    uploadFile(e, url, state) {
        let formData = new FormData()
        formData.append('file', e.target.files[0])
        axios.post("/documents/upload/media", formData, {
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((res) => {
            axios.put(url, {
                [state]: process.env.REACT_APP_API_URI + '/image_media/' + res.data.id
            }).then(() => {
                this.setState({ [state]: process.env.REACT_APP_MEDIA_URL + res.data.nom})
            })
        })
    }

    supprimerLogo = () => {
        axios.put(process.env.REACT_APP_API_URL + '/societes/' + this.state.societeId, {
            logo: null
        }).then(() => {
            this.setState({ logo: null})
        })
    }

    supprimerSignature = () => {
        axios.put(process.env.REACT_APP_API_URL + '/contacts/' + this.state.maireId, {
            signature: null
        }).then(() => {
            this.setState({ signature: null})
        })
    }

    render() {
        const contacts = this.state.contacts.map( contact => (
            <div className="mr-5 mt-3" key={contact.id}>
                <div>
                    <p className="soleilBold-fonts purple">{contact.typeDeContact}</p>
                    <p className="soleilRegular-fonts">{contact.civilite === 'F' ? 'Mme.' : 'M.'} {contact.nom} {contact.prenom}</p>
                    <p className="soleilRegular-fonts">{contact.telephone || '-'}</p>
                    <p className="soleilRegular-fonts">{contact.email || '-'}</p>
                </div>
                <ContactForm contact={contact} modifierContact = {this.modifierContact} supprimerContact = {this.supprimerContact}/>
            </div>
        ))

        const jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]
        const horaires = jours.map( (jour,index) => {
            return (
                <div className="cols small" key={index}>
                    <label>{jour}</label>
                    <textarea className="horaire-mairie" placeholder="non renseigné" value={this.state.horaires[index] ? this.state.horaires[index] : ''} data-index={index} onChange={this.handleHoraires} onBlur={this.updateHoraires}/>
                </div>
            )
        })

        return(
            <div className="container-fluid px-0">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-wrapper mt-3">
                            <div className="d-flex ml-3 align-items-center">
                                <img className="img-fluid mr-4 mb-3" src={communeIcon} alt=""/>
                                <p className="soleilBold-fonts purple title mb-0">mairie</p>
                            </div>
                            <div className="d-flex mt-5">
                                <label className="col-4 soleilRegular-fonts">Commune</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="commune" value={this.state.commune} onChange={this.handleChange} onBlur={this.updateInfosMairie}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Département</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="departement" value={this.state.departement} onChange={this.handleChange} onBlur={this.updateInfosMairie}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Adresse</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="adresse" value={this.state.adresse} onChange={this.handleChange} onBlur={this.updateInfosMairie}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Code postal</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="codePostal" value={this.state.codePostal} onChange={this.handleChange} onBlur={this.updateInfosMairie}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Ville</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="ville" value={this.state.ville} onChange={this.handleChange} onBlur={this.updateInfosMairie}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Email</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="email" value={this.state.email} onChange={this.handleChange} onBlur={this.updateInfosMairie}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Téléphone</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="telephone" value={this.state.telephone} onChange={this.handleChange} onBlur={this.updateInfosMairie}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Nom de la trésorerie</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="tresorerie" value={this.state.tresorerie} onChange={this.handleChange} onBlur={this.updateInfosMairie}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Logo / Blason de la commune</label>
                                {
                                    this.state.logo ?
                                        <img className=" soleilRegular-fonts img-fluid" src={this.state.logo} alt="logo_mairie" style={{maxWidth: "200px"}}/>
                                        : <p className="grey small px-4">Aucun logo/blason enregistré</p>
                                }
                            </div>
                            <div className="d-flex mt-2 ml-3">
                                <div className="col-4 "/>
                                <div className="d-flex">
                                    <div className="modifier-file">
                                        <label htmlFor="modif_blason" className="soleilRegular-fonts red mr-3 cursor">{ this.state.logo ? 'Modifier le logo/blason' : 'Ajouter un logo/blason'}</label>
                                        <input id="modif_blason" className="d-none" type="file" onChange={this.updateLogo}/>
                                    </div>
                                    { this.state.logo ?
                                        <div>
                                            <a className="soleilRegular-fonts purple cursor"
                                               onClick={this.supprimerLogo}>Supprimer</a>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-wrapper mt-3">
                            <div className="d-flex ml-3 align-items-center">
                                <img className="img-fluid mr-4" src={maireIcon} width="40px" alt=""/>
                                    <p className="soleilBold-fonts purple title mb-0">le maire</p>
                            </div>
                            <div className="d-flex mt-5">
                                <label className="col-4 soleilRegular-fonts">Civilité</label>
                                <div className="col-8 d-flex">
                                    <div className="mr-4">
                                        <input type="radio" value="H" id="H" name="civilite" checked={this.state.civilite === "H"} onChange={this.handleChange}/>
                                            <label htmlFor="H"
                                                   className="soleilRegular-fonts purple radio-label">Monsieur</label>
                                    </div>
                                    <div>
                                        <input type="radio" value="F" id="F" name="civilite" checked={this.state.civilite === "F"} onChange={this.handleChange}/>
                                            <label htmlFor="F"
                                                   className="soleilRegular-fonts purple">Madame</label>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Nom</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="nom" value={this.state.nom}  onChange={this.handleChange} onBlur={this.updateInfosMaire}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Prénom</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="prenom" value={this.state.prenom} onChange={this.handleChange} onBlur={this.updateInfosMaire}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className=" mt-3 ml-3 soleilRegular-fonts">Signature du maire avec tampon de la
                                    mairie</label>
                            </div>
                            { this.state.maireId ?
                                <div className="d-flex mt-2">
                                    {
                                        this.state.signature ?
                                            <img className=" col-5 soleilRegular-fonts img-fluid mr-5"
                                                 src={this.state.signature} alt=""/>
                                            : <p className="grey small px-4">Aucune signature enregistrée</p>
                                    }
                                    <div className="d-flex mt-2 px-2">
                                        <div className="modifier-file">
                                            <label htmlFor="modif_signature"
                                                   className="soleilRegular-fonts red mr-3 cursor"> { this.state.signature ? 'Modifier la signature' : 'Ajouter une signature'}</label>
                                            <input id="modif_signature" className="d-none" type="file"
                                                   onChange={this.updateSignature}/>
                                        </div>
                                        { this.state.signature ?
                                            <div>
                                                <a className="soleilRegular-fonts purple cursor"
                                                   onClick={this.supprimerSignature}>Supprimer</a>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
                <div className="timer-wrapper row mt-4 mr-auto ml-auto">
                    <div className="title soleilBold-fonts"><img src={timerIcon} alt="" className="img-fluid mr-3"/> HORAIRES MAIRIE
                    </div>
                    <div className="d-flex mt-4 align-content-start col-12">
                        {horaires}
                    </div>
                </div>
                <div className="mt-3 bg-white pt-4 pb-4 mb-3 row ml-auto mr-auto d-flex flex-column">
                    <div className="d-flex col-12  p-0">
                        <p className="soleilBold-fonts title purple ml-5">contacts</p>
                        <ContactForm societeId = {this.state.societeId} eSpaceId={localStorage.getItem('espace_id')} ajouterContact = {this.ajouterContact}/>
                    </div>
                    <div className="d-flex flex-wrap contacts ml-5">
                        {contacts}
                    </div>
                </div>
            </div>
        )
    }
}

export default InfosMairie;