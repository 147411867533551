import React, { Component } from 'react';
import axios from 'axios';
import '../../svg-map-editor/import.css';
import MapZoom from './MapZoom';
import { appendScript } from '../../utils/appendScript.js';
import { funcGlobal } from '../../utils/funcGlobal';
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import OlSourceOSM from "ol/source/OSM";

window.funcGlobal = funcGlobal

class DrawEmplacement extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      //Avec campuses ID.
      //center: [4.075186, 48.267159], 
      center:[4.076972, 48.266491 ],
      //Sans campuses ID.
      zoom: 19,
      olmap: null
     };
    
  }
  updateMap() {
  }

  componentDidMount() {
  }
  onClickEditor(e) {
    let zoom = this.state.zoom
    let elm = e.target
    console.log(typeof elm.className)
    console.log(this.state.center)
    if (typeof elm.className == "string" && elm.className.indexOf("wcp-editor-toolbar-button") === -1) {
      elm = this.closest(e.target, '.wcp-editor-toolbar-button')
    }
    if (elm == null) return;
    if (typeof elm.dataset["wcpEditorToolbarButtonName"] != "undefined") {
      // zoom
      if (elm.dataset["wcpEditorToolbarButtonName"] == "zoom-out") {
        zoom = this.state.zoom - 1
      } else if (elm.dataset["wcpEditorToolbarButtonName"] == "zoom-in") {
        zoom = this.state.zoom + 1
      }
      this.setState({ zoom: zoom })
      this.updateMap()
    }
  }
  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: parseFloat(e.target.value)
    })
  }

  render() {
    
    return (
      <div> 
         <MapZoom hasEditor="1" />
        {/* <div style={{position: 'relative', zIndex: 3}}>
        <button id="saveBtn" style={{position: 'relative', zIndex: 3}} onClick={this.postDraw}>Sauvegarder</button>
        <input type="number" value={this.state.zoom} name="zoom" onChange={this.onChangeInput}/></div>

        <div className="contents" id="wcp-editor" style={{ height: '100vh' }}>
          <h1 className="contents content-margin ">Formulaire Dessin</h1>
          <hr />

        </div> */}
      </div>
    )
  }

}

export default DrawEmplacement;