import React, { Component } from 'react';
import {Link} from 'react-router-dom';

 class PageNotFound extends Component {
    render() {
        return (
            <div className="container">
               <h1 style={{ marginRight: '60px'}}> Page Not Found - 404</h1> 
                <br />
                <Link style={{ marginRight: '60px'}} to="/">Revenir à la page d'accueil</Link>
            </div>
        )
    }
}
export default PageNotFound;
