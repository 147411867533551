import React, {Component} from 'react';
import axios from "axios";
import pdfIconTbl from '../../assets/images/pdf-icon-tbl.svg';
import LogicimLexique from '../../assets/documents/Logicim-lexique.pdf'
import searchIcon from "../../assets/images/search-icon-purple.svg";
class Lexique extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lexique      : {},
            toDisplay    : [],
            letterDisplay: 'a',
            mot          : this.props.mot ? this.props.mot : null,
            search       : '',
        }
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + '/lexiques?order[titre]=asc').then((res) => {
            if (res && res.data['hydra:member'] && res.data['hydra:member'][0]) {
                const firstWord = this.state.mot ? this.state.mot : res.data['hydra:member'][0]['titre']
                const firstLetter = firstWord.charAt(0).toLowerCase()
                document.getElementById(firstLetter).classList.add('selected')
                const defaultDisplay = this.motsToDisplay(firstLetter, res.data['hydra:member'])
                this.setState({
                    lexique      : res.data['hydra:member'],
                    toDisplay    : defaultDisplay,
                    letterDisplay: firstLetter
                })
            }
        })
    }

    motsToDisplay(letter, lexique) {
        return lexique.filter(definition => {
            return definition.titre.startsWith(letter.toUpperCase()) || definition.titre.startsWith(letter)
        })
    }

    handleClick = (e) => {
        const letter = e.target.id
        const isDisplay = document.getElementById(this.state.letterDisplay)
        const toDisplay = document.getElementById(letter)

        if (isDisplay) {
            isDisplay.classList.remove('selected')
        }

        if (toDisplay) {
            toDisplay.classList.add('selected')
            this.setState({
                letterDisplay: letter,
                toDisplay    :  this.motsToDisplay(letter, this.state.lexique),
                search       : ''
            })
        }
    }

    handleChange = (event) => {
        this.setState({search: event.target.value});
        if(event.target.value !== '') {
            this.search(event.target.value)
        }
    }

    search = (search) => {
        const isDisplay = document.getElementById(this.state.letterDisplay)
        const toDisplay = document.getElementById(search.charAt(0))
        const capitalized = search.charAt(0).toUpperCase() + search.slice(1)
        let results = this.state.lexique.filter(definition => {
            return definition.titre.startsWith(capitalized) || definition.titre.startsWith(search)
        })

        if (isDisplay) {
            isDisplay.classList.remove('selected')
        }

        if (toDisplay) {
            toDisplay.classList.add('selected')
            this.setState({
                letterDisplay : search.charAt(0),
                toDisplay     : results
            })
        }
    }

    render() {
        require('../../assets/css/lexique.css')
        let alphabet = []
        for (let i = 0; i < 26; i++) {
            const letter = (i+10).toString(36)
            alphabet.push(<li id={letter} key={letter} onClick={this.handleClick}>{letter}</li>)
        }

        const mots = this.state.toDisplay.map((definition) =>
            <div className="definition" id={definition.titre}>
                <div className="definition-title" >{definition.titre}</div>
                <div className="definition-content">{definition.contenu}</div>
            </div>
        )

        if (this.state.mot && mots.length > 0) {
            const goTo = document.getElementById(this.state.mot)
            if (goTo) {
                document.getElementById('scroll-to').scrollTo(0, goTo.offsetTop - 300 )
            }
        }

        return (
            <div className="container-fluid bg-white px-3 pt-5">
                <div className="lexique-wrapper px-5 pb-4">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div id="alphabet-list-wrapper">
                            <ul id="aplhabet">
                                { alphabet }
                            </ul>
                        </div>
                        <div>
                            <div id="input-search-lexique" className="mb-3 d-flex mt-4">
                                <input type="text" placeholder="Rechercher dans le lexique" value={this.state.search}
                                       onChange={this.handleChange}/>
                                <div id="input-search-icon-wrapper" className="cursor d-flex justify-content-center align-items-center">
                                    <img src={searchIcon} width='18px' alt=""/>
                                </div>
                            </div>
                            <div id="dl-lexique-wrapper" className="d-flex justify-content-center align-items-center px-3 py-3">
                                <img src={pdfIconTbl} className="img-fluid mr-3" width="25px" alt=""/>
                                <p className="soleilRegular-fonts purple mt-2 mr-3">lexique cimetière</p>
                                <a href={LogicimLexique} target="_blank" className="soleilRegular-fonts mt-2" download>Téléchager le PDF</a>
                            </div>
                        </div>
                        <div id="letter-select-display">
                            <span id="letter-select" className="soleilBold-fonts purple text-uppercase">{this.state.letterDisplay}</span>
                        </div>
                    </div>
                    <div className="definitions-wrapper">
                        {
                            mots.length === 0 ? <div className="m-3 grey">Aucun résultat pour cette lettre</div> : ''
                        }
                        { mots }
                    </div>
                </div>
            </div>
        )
    }
}
export default Lexique;