import React, { Component } from 'react';
import {Nav, NavItem,NavLink} from "react-bootstrap";
import {TabContainer,TabContent,TabPane} from "react-bootstrap";
import EditeurList from "./EditeurList";
import iconSubmenu from '../../assets/images/filter-icon-submenu.svg';
import '../../assets/css/listes.css'

class EditeurMenu extends Component {

    render() {
        return(
            <div className="contents content-margin">
                <TabContainer id="left-tabs-example" defaultActiveKey="liste">
                    <div className="content-header px-3 px-lg-4">
                        <span className="expand-btn">&#9776;</span>
                        <div className="d-flex align-items-center">
                            <div className="col-6 soleilBold-fonts title">CONCESSIONS</div>
                            <div className="col-6 emp-buttons">
                                <Nav variant="pills" className="flex-row">
                                    <NavItem>
                                        <NavLink eventKey="plan">
                                        <button type="button" className="plan-btn soleilBold-fonts">Plan</button></NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink eventKey="liste">
                                        <button type="button" className="liste-btn soleilBold-fonts">Liste</button></NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </div>
                    </div>
                    <div className="table-filter-wrapper px-3 px-lg-4 pt-3 pb-2">
                        <span className="soleilRegular-fonts title"><img src={iconSubmenu} alt=""
                                                                         className="img-fluid mr-2" id="icon-submenu"/>
                            FILTRER LES CONCESSIONS</span>
                        <label htmlFor="in_progress_filter" className="soleilRegular-fonts">
                            <input type="checkbox" id="in_progress_filter" className="mr-2"/>
                                En cours
                        </label>
                        <label htmlFor="expired_filter" className="soleilRegular-fonts">
                            <input type="checkbox" id="expired_filter"  className="mr-2"/>
                                Échue
                        </label>
                        <label htmlFor="resumption_in_progress_filter" className="soleilRegular-fonts">
                            <input type="checkbox" id="resumption_in_progress_filter"  className="mr-2"/>
                                Reprise par abandon en cours
                        </label>
                        <label htmlFor="resumption_in_finalized_filter" className="soleilRegular-fonts">
                            <input type="checkbox" id="resumption_in_finalized_filter"  className="mr-2"/>
                                Reprise par abandon à finaliser
                        </label>
                    </div>
                    <TabContent>
                        <TabPane eventKey="plan">
                            PLAN
                        </TabPane>
                        <TabPane eventKey="liste">
                            <EditeurList/>
                        </TabPane>
                    </TabContent>
                </TabContainer>
            </div>
        )
    }
}

export default EditeurMenu;