import React, { Component } from 'react';
import Pagination from "../../components/pagination/Pagination";
import AjoutEspace from "../../components/ajoutEspace/AjoutEspace";
import user from "../../helpers/user";
import OrderBy from "../../components/utils/OrderBy";
import SearchUser from "../../components/search/SearchUser";
import axios from "../../helpers/axios";
import {Nav} from "react-bootstrap";

class ListeUtilisateurs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected    : 0,
            tab         : 'activated',
            utilisateurs: [],
            total       : 0,
            typeEspace  : 'logicim',
            orderBy     : null,
            sortBy      : null,
            search      : null,
            filters     : ['es.actif =1']
        }
    }

    updateState = (res) => {
        this.setState({
            utilisateurs: res.data.data || [],
            total       : res.data.total,
        })
    }

    handleCheck = (e) => {
        let parent = e.target.parentElement.parentElementuser_
        if(e.target.checked === true) {
            parent.style.border = "1px solid #6B00B2";
            this.setState({selected: this.state.selected + 1});
        } else {
            parent.style.border = "none";
            this.setState({selected: this.state.selected - 1});
        }
    }

    deleteUser = (user) => {

        axios.delete(process.env.REACT_APP_API_URL + "/users/" + user.id).then((res) => {

            this.setState({
                search: 'dfdf'
            })
            this.setState({
                search: null
            })
        } )
    }
    imposonate = async (username) => {
        await localStorage.setItem('impersonate', username)
        window.location.href = "/switch-espace";
    }

    sort = (sortBy) => {
        if (this.state.sortBy === sortBy) {
            this.setState(prevState =>{
                return{
                    ...prevState,
                    orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
                }
            })
        } else {
            this.setState({
                sortBy : sortBy,
                orderBy: 'asc'
            })
        }
    }

    changePane = (value) => {
        if (value === 'activated') {
            this.setState({filters: ['es.actif = 1']})
        }
        if (value === 'not_activated') {
            this.setState({filters: ['es.actif = 0']})
        }
        if (value === 'demo') {
            this.setState({filters: ['u.isDemo = 1']})
        }
        if (value === 'logiplace') {
            this.setState({filters: ['s.id = 1']})
        }
        this.setState({tab: value})
    }

    search = (value) => {
        this.setState({search: value})
    }

    render() {
        const tableRows = this.state.utilisateurs.map((u, index) => {
            const societes = []
            for (let i in u.societes) {
                societes.push(<p key={i}>{u.societes[i].commune ? u.societes[i].commune : '-'} </p>)
            }
            return (
                <tr key={index}>
                    <td className="d-flex">
                        <div className="d-flex flex-column">
                            {societes}
                        </div>
                    </td>
                    <td className="">
                        {u.email}
                    </td>
                    <td>
                        {u.contact && u.contact.nom ? u.contact.nom : '-'}
                    </td>
                    <td>
                        {u.contact && u.contact.prenom ? u.contact.prenom : '-'}
                    </td>
                    <td>
                        <p className="edit-tbl concession cursor underline-hover" onClick={() => this.imposonate(u.username)}>
                            se connecter en tant que
                        </p>
                        <p className="edit-tbl concession cursor underline-hover" onClick={() => window.confirm('Etes vous sur de vouloir supprimer cet utilisateur ?') && this.deleteUser(u) }>
                            Supprimer cet utilisateur
                        </p>
                    </td>
                </tr>
            )
        })

        let myuser = null
        if (localStorage.getItem(user.USER_LOCAL_KEY)) {
            myuser = JSON.parse(localStorage.getItem(user.USER_LOCAL_KEY))
        }

        return (
            <>
                { myuser && (myuser.roles.includes('ROLE_SUPER_ADMIN') || myuser.roles.includes('ROLE_ADMIN')) ?
                    <div className="contents content-margin">
                        <div className="content-header px-3 px-lg-4 d-flex align-items-center" style={{height: '54px'}}>
                            <span className="expand-btn">&#9776;</span>
                            <div className="d-flex align-items-center">
                                <div className="col-6 soleilBold-fonts title">UTILISATEURS</div>
                            </div>
                        </div>
                        <div className="mx-3 pt-4">
                        <ul class="nav nav-pills gap-4 nav-fill">
                            <li className="nav-item">
                                <a className={`nav-link ${this.state.tab === 'activated' ? 'active' : 'bg-gray text-primary' }`}  onClick={() => this.changePane('activated')} aria-current="page" href="#">Clients</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${this.state.tab === 'demo' ? 'active' : 'bg-gray text-primary'}`}  onClick={() => this.changePane('demo')} aria-current="page" href="#">Démonstration prospects</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${this.state.tab === 'not_activated' ? 'active' : 'bg-gray text-primary'}`}  onClick={() => this.changePane('not_activated')} aria-current="page" href="#">Test avant activation</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${this.state.tab === 'logiplace' ? 'active' : 'bg-gray text-primary'}`}  onClick={() => this.changePane('logiplace')} aria-current="page" href="#">Utilisateur LogiPlace</a>
                            </li>
                        </ul>
                        </div>
                        <SearchUser search={this.search}/>
                        <div className="mx-3 pt-1">
                            <div className="table-responsive px-3 scroll listes-leftpanel" id="listes-leftpanel-small">
                                <table className="table table-striped table-borderless">
                                    <thead>
                                    <tr>
                                        <th className="sortStyle">Commune(s)</th>
                                        <th className="sortStyle">
                                            Identifiant
                                            <span onClick={() => this.sort('username')}>
                                                <OrderBy isClicked={this.state.sortBy === 'username'}/>
                                            </span>
                                        </th>
                                        <th className="sortStyle">
                                            Nom
                                            <span onClick={() => this.sort('nom')}>
                                                <OrderBy isClicked={this.state.sortBy === 'nom'}/>
                                            </span>
                                        </th>
                                        <th className="sortStyle">
                                            Prénom
                                            <span onClick={() => this.sort('prenom')}>
                                                <OrderBy isClicked={this.state.sortBy === 'prenom'}/>
                                            </span>
                                        </th>
                                        <th colSpan="2" className="sortStyle">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tableRows}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="container-fluid py-3 px-lg-5">
                            <div className="row d-flex justify-content-between">
                                <AjoutEspace tab={this.state.tab}/>
                                <Pagination
                                    url         = {'/users/getAll/' + this.state.typeEspace + '/'}
                                    eSpaceId    = {null}
                                    type        = {null}
                                    sort        = {this.state.sortBy}
                                    order       = {this.state.orderBy}
                                    updateState = {this.updateState}
                                    search      = {this.state.search}
                                    filters     = {this.state.filters}
                                />
                                <div
                                    className="list-button d-flex justify-content-center justify-content-sm-end align-items-center mt-4 mt-sm-0">
                                    <button type="button" className="listing-incomplete-btn soleilBold-fonts">Liste complète (
                                        {this.state.total} )
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
                }
            </>

        )
    }
}

export default ListeUtilisateurs;
