import React, { Component } from 'react';
import axios from "../../helpers/axios";
import $ from 'jquery';
import '../../assets/css/formDefunt.css';
import {connect} from "react-redux";
import store from "../../store";
import SearchEmplacement from "../../components/search/SearchEmplacement";

class FormExhumation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formDefunt : [],
            exhumationId : null,
            defuntId : null
        }
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        $('#reinhumation-recherche').removeClass('champs-step').addClass('mt-4').insertAfter($('#deplacer-corps'))
        let url = '';
        let exhumation = store.getState().defunt.exhumation;

        if (exhumation !== null && exhumation.id !== this.state.exhumationId) {
            url = '/' + exhumation.id
            this.setState({
                exhumationId : exhumation.id,
                defuntId : JSON.parse(store.getState().defunt.defunt).occupant.id
            })
            this.addFormExhumation(url)
        }

        if(!exhumation && store.getState().defunt.defunt && JSON.parse(store.getState().defunt.defunt).occupant.id !== this.state.defuntId) {
            this.setState({
                exhumationId : null,
                defuntId : JSON.parse(store.getState().defunt.defunt).occupant.id
            })
            this.addFormExhumation('')
        } else if (this.state.defuntId && !this.props.exhumationId && !store.getState().defunt.defunt ) {
            this.setState({
                defuntId : null
            })
        }
    }

    addFormExhumation(url) {
        try {
            axios.get(process.env.REACT_APP_API_URL + '/logicim/formExhumation/' + JSON.parse(store.getState().defunt.defunt).occupant.id + url).then((response) => {
                this.setState({formDefunt: response.data})

                $(function () {
                    $('[data-toggle="popover"]').popover()
                })

                document.querySelectorAll(".exhumation-contact div:not(div:nth-child(1),[id$='civilite'])").forEach(
                    function (div) {
                        div.innerHTML += ('<div class="check-inconnu float-right"> ' +
                            '<input class="table-checkbox" id="inconnu_' + div.firstChild.htmlFor +'" type="checkbox">' +
                            '<label for="inconnu_' + div.firstChild.htmlFor +'" class="soleilBold-fonts purple p-0 m-0">Inconnu</label></div>');
                    })

                const champsExhumDestination = document.querySelector('#exhumation_destinationCorps')
                champsExhumDestination && champsExhumDestination.addEventListener('change', (element) => {
                    this.affichageChampsDestinationCorps(element.target.value)
                })

                const champsExhumDeplacer = document.getElementById('exhumation_deplacerCorps')
                champsExhumDeplacer && champsExhumDeplacer.addEventListener('change',  (element) => {
                    this.affichageFormAutreCimetiere(element.target.value)
                })

                const champsExhumCremaDestionation = document.getElementById('exhumation_cremation_destinationUrneCendre')
                champsExhumCremaDestionation && champsExhumCremaDestionation.addEventListener('change',  (element) => {
                    this.affichageChampsDestinationCendre(element.target.value)
                })

                // on verifie s'il existe déjà une inhumation pour le defunt, si oui le form est en lecture only
                const champsExhum = document.getElementById('form-wrapper-exhum')
                if (store.getState().defunt.exhumation !== null) {
                    // on met a jour les champs à afficher
                    this.affichageChampsSiFormLecture()
                    // on bloque la modification des champs
                    if (champsExhum) {
                        champsExhum.querySelectorAll('input, select')
                            .forEach((element) => element.classList.add('disable-field'))
                    }
                } else {
                    if (champsExhum) {
                        champsExhum.querySelectorAll('input, select')
                            .forEach((element) => element.classList.remove('disable-field'))
                    }
                }

                const typeDemande = document.getElementById('exhumation_typeDemande')
                typeDemande && typeDemande.addEventListener('change', element => {
                    this.affichageFormDemandeur(element.target.value)
                })

                if(typeDemande.value) {
                    this.affichageFormDemandeur(typeDemande.value)
                }
            })
        } catch (error) {
            console.log('défunt non trouvé')
        }
    }

    affichageFormDemandeur(value) {
        const justiceOuServiceField = document.getElementById('exhumation_tribunalOuService')
        const famille = document.getElementById('demande-form-demandeur-famille')
        const justiceOuService = document.getElementById('demande-form-demandeur-justice-service')
        const text = document.getElementById('demande-text-demandeur-justice-service')
        const labelNom = document.getElementById('labelTribunalOuServiceNom')
        const labelContact = document.getElementById('labelTribunalOuServiceContact')

        famille.classList.add('display-none')
        justiceOuService.classList.add('display-none')

        switch (value) {
            case 'Demande de la famille':
                famille.classList.remove('display-none')
                text.innerText = 'Exhumation demandée par la famille'
                justiceOuServiceField.value = null
                break;
            case 'Demande de la justice':
                justiceOuService.classList.remove('display-none')
                labelNom.innerText = 'Tribunal demandeur'
                labelContact.innerText = 'Nom du juge'
                text.innerText = 'Exhumation demandée par la justice'
                justiceOuServiceField.value = 'justice'
                break;
            case 'Demande de la sécurité sociale':
                justiceOuService.classList.remove('display-none')
                labelNom.innerText = 'Service demandeur'
                labelContact.innerText = 'Nom du contact'
                text.innerText = 'Exhumation demandée par la sécurité sociale'
                justiceOuServiceField.value = 'service'
                break;
        }
    }

    affichageChampsDestinationCorps(value) {
        const cremation = document.querySelector('#cremation-exhumation');
        const dateReinhumation = document.querySelector('#reinhumation-date');
        const deplacerCorps = document.querySelector('#deplacer-corps')
        const recherche = document.querySelector('#reinhumation-recherche');
        const autreCimetiere = document.querySelector('#reinhumation-autre');

        cremation && cremation.classList.add('display-none')
        dateReinhumation && dateReinhumation.classList.add('display-none')
        deplacerCorps && deplacerCorps.classList.add('display-none')
        recherche && recherche.classList.add('display-none')
        autreCimetiere && autreCimetiere.classList.add('display-none')

        switch (value) {
            case 'Réinhumation du corps dans la même concession' :
                dateReinhumation && dateReinhumation.classList.remove('display-none')
                break;
            case 'Réinhumation du corps dans une autre concession' :
                dateReinhumation && dateReinhumation.classList.remove('display-none')
                deplacerCorps && deplacerCorps.classList.remove('display-none')
                break;
            case 'Crémation des restes des corps exhumés (R. 2213-37 CGCT)' :
                cremation && cremation.classList.remove('display-none')
                break;
            default :
                break;
        }
    }

    affichageFormAutreCimetiere(value) {
        const recherche = document.querySelector('#reinhumation-recherche');
        const autreCimetiere = document.querySelector('#reinhumation-autre');
        switch (value) {
            case 'Dans mon cimetière' :
                recherche.classList.remove('display-none')
                autreCimetiere.classList.add('display-none')
                break
            case 'Dans un autre cimetière' :
                recherche.classList.add('display-none')
                autreCimetiere.classList.remove('display-none')
                break;
            default :
                break;
        }
    }

    affichageChampsDestinationCendre(destinationUrne) {
        document.getElementById('jardinSouvenir').classList.add('display-none')
        document.getElementById("cremation_inhum_scellement").classList.add('display-none')
        document.getElementById('exhumation-dispersion-champs').classList.add('display-none')
        document.getElementById('exhum-date-dispersion').classList.add('display-none')

        if (destinationUrne === 'Dispersion en pleine nature sauf voies publiques') {
            document.getElementById('exhumation-dispersion-champs').classList.remove('display-none')
            document.getElementById('exhum-date-dispersion').classList.remove('display-none')
        } else if (destinationUrne === "Dispersion en Jardin du Souvenir") {
            document.getElementById('exhum-date-dispersion').classList.remove('display-none')
            document.getElementById('jardinSouvenir').classList.remove('display-none')
        } else {
            document.getElementById("exhum-cremation_inhum_scellement").classList.remove('display-none')
        }
    }

    // gestion de l'affichage des champs cachés en fonction des valeurs des champs hydratés pour un formulaire en lecture only
    affichageChampsSiFormLecture() {
        // affichage des champs en fonction de la destination du corps
        const destinationCorps = document.getElementById('exhumation_destinationCorps').value
        this.affichageChampsDestinationCorps(destinationCorps)
        // affichage des champs en fonction du choix du cimetière actuel ou d'un autre
        const autreCimetiere = document.getElementById('exhumation_deplacerCorps').value
        this.affichageFormAutreCimetiere(autreCimetiere)

        const destinationCendre = document.getElementById('exhumation_cremation_destinationUrneCendre').value
        if (destinationCendre){
            this.affichageChampsDestinationCendre(destinationCendre)
        }
    }

    updateEmplacement = () => {
        switch (document.querySelector('#exhumation_destinationCorps').value) {
            case 'Réinhumation du corps dans une autre concession' :
                const deplacerCorps = document.querySelector('#exhumation_deplacerCorps').value
                if (deplacerCorps !== 'Dans mon cimetière') {
                    document.querySelector('#exhumation_emplacement').value = $('#reinhumation-autre').serialize()
                }
        }
    }

    addCremation = () => {
        let checkboxValidation = document.querySelector("input[name='valid_input_exhumation']");
        if (checkboxValidation !== null && checkboxValidation.checked === true) {
            $('.modal').modal('hide')
            let postDatas = $(".formulaire.exhumation form").serialize();
            const submit = document.querySelector(".formulaire.exhumation").getAttribute('data-submit')
            postDatas += '&valider=1';
            axios.post(submit, postDatas).then((response) => {
                if (
                        response.data.destinationCorps === 'Réinhumation du corps dans la même concession'
                    || (response.data.cremation && response.data.cremation.destinationUrneCendre === "Inhumation dans la sépulture")
                    || (response.data.cremation && response.data.cremation.destinationUrneCendre === "Scellement sur le monument de la sépulture" )
                    || (response.data.cremation && new Date(response.data.cremation.dateDispersion.substr(0, 10)) > new Date())
                    || (response.data.cremation && response.data.cremation.inhumation && new Date(response.data.cremation.inhumation.date.substr(0, 10)) > new Date())
                    || (response.data.date && new Date(response.data.date.substr(0, 10))> new Date())
                )
                {
                    const addExhumation = this.props.addExhumation
                    addExhumation(response.data)
                } else {
                    document.getElementById('ajout-defunt-fenetre').style.display = "none";
                    $('.names-wrapper').css('margin-bottom', '0');
                    let defunts = JSON.parse(store.getState().defunt.defuntListe)
                    for (let defunt in defunts) {
                        if(defunts[defunt].id === JSON.parse(store.getState().defunt.defunt).occupant.id) {
                            defunts.splice(defunt, 1)
                        }
                    }
                    const addListeDefunt = this.props.addListeDefunt
                    if (defunts.length === 0) {
                        addListeDefunt(null)
                    } else {
                        addListeDefunt(JSON.stringify(defunts))
                    }
                    const defuntDisplay = this.props.defuntDisplay
                    defuntDisplay(null)
                    const ajoutPlaceOccupee = this.props.handlePlaceOccupee
                    ajoutPlaceOccupee(1, 'remove')
                }
            });
        } else {
            document.querySelector('#etape3 .valid_form').classList.add('c-error')
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal fade procedure-modal modal-large" id="etape3" tabIndex="-1" role="dialog"
                     aria-labelledby="etape3" aria-hidden="true">
                    <div className="modal-dialog  d-flex justify-content-center align-items-center modal-wrap"
                         role="document">
                        <div className="modal-content">
                            <div className="modal-header bk-grey">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-body-top">
                                    <p className="soleilBold-fonts title purple">exhumation</p>
                                    <div className="progression">
                                        <span className="jalon s1 fait">
                                            <div className="desc">Vérification préalable</div>
                                        </span>
                                        <span className="ligne s2"/>
                                        <span className="jalon">
                                            <div className="desc">Demande</div>
                                        </span>
                                        <span className="ligne s3"/>
                                        <span className="jalon">
                                            <div className="desc">Exhumation</div>
                                        </span>
                                        <span className="ligne s4"/>
                                        <span className="jalon">
                                            <div className="desc">Destination du corps</div>
                                        </span>
                                        <span className="ligne s5 last"/>
                                        <span className="jalon">
                                            <div className="desc">Vérification Validation</div>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-wrapper scroll-popup" id="form-wrapper-exhum">
                                    {
                                        store.getState().defunt.defunt ?
                                        <div dangerouslySetInnerHTML={{__html: this.state.formDefunt}}
                                             className="formulaire exhumation"
                                             data-submit={process.env.REACT_APP_API_URL + '/logicim/formExhumation/' +
                                             JSON.parse(store.getState().defunt.defunt).occupant.id}/>
                                        : ''
                                    }
                                    <div className="modal-step-content etape4 display-none">
                                        <SearchEmplacement id="emplacement-recherche" class="display-none" hiddenField="exhumation_emplacement" libelle="Occupé"/>
                                        <form id="reinhumation-autre" className="display-none champs-step ">
                                            <div className="d-flex">
                                                <label htmlFor="commune_nom" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Nom de la commune :</label>
                                                <input type="text" id="commune_nom" name="commune_nom"
                                                       className="col-4 soleilRegular-fonts col-3"
                                                       placeholder="Nom de la commune" onChange={this.updateEmplacement}/>
                                            </div>
                                            <div className="d-flex mt-2">
                                                <label htmlFor="adresse_cimetiere" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Adresse du cimetière :</label>
                                                <input type="text" id="adresse_cimetiere" name="adresse_cimetiere"
                                                       className="col-4 soleilRegular-fonts col-3"
                                                       placeholder="Adresse du cimetière de destination"
                                                       onChange={this.updateEmplacement}/>
                                            </div>
                                            <div className="d-flex mt-2">
                                                <label htmlFor="reference_emplacement" className="col-3 p-0 m-0  soleilRegular-fonts col-3">Références de l'emplacement :</label>
                                                <input type="text" id="reference_emplacement" name="reference_emplacement"
                                                       className="col-4 soleilRegular-fonts col-3"
                                                       placeholder="Références de l'emplacement" onChange={this.updateEmplacement}/>
                                            </div>
                                            <div className="d-flex mt-2 file-select ml-1 mt-3">
                                                <div className="d-flex align-items-center">
                                                    <p className="col-3 p-0 soleilRegular-fonts col-3">Acte
                                                        de concession
                                                    </p>
                                                    <label htmlFor="acte-concession"
                                                           className="mt-1 mr-3 add-doc-btn dl-actes">
                                                        Choisir un fichier
                                                        <input type="file" id="acte-concession" name="acte-concession"
                                                               className="d-none" onChange={this.updateEmplacement}/>
                                                    </label>
                                                    <div className="select-pdf-file-name soleilRegular-fonts">Aucun fichier
                                                        sélectionné
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex mt-2 file-select ml-1 mt-3">
                                                <div className="d-flex align-items-center">
                                                    <p className="col-3 p-0 soleilRegular-fonts col-3">Autorisation d'entrée au cimetière
                                                    </p>
                                                    <label htmlFor="autorisation-entree"
                                                           className="mt-1 mr-3 add-doc-btn dl-actes">
                                                        Choisir un fichier
                                                        <input type="file" id="autorisation-entree " name="autorisation-entree"
                                                               className="d-none" onChange={this.updateEmplacement}/>
                                                    </label>
                                                    <div className="select-pdf-file-name soleilRegular-fonts">Aucun fichier
                                                        sélectionné
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer navigation mt-5 p-0">
                                        <div>
                                            <button type="button"
                                                    className="tbl bk-red precedent display-none">Précédent
                                            </button>
                                        </div>
                                        <div>
                                            <button className="red cancel-btn tbl" data-dismiss="modal">Annuler</button>
                                            <button type="button" className="tbl bk-red ml-2 suivant">Suivant</button>
                                            <button type="submit" className="tbl btn-navigation bk-red display-none ml-2 preview">Previsualiser</button>
                                            <button type="submit" className="tbl btn-navigation bk-red display-none ml-2 valider" onClick={this.addCremation}>Valider</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addExhumation: (exhumation) => dispatch({
            type: 'ADD_EXHUMATION',
            exhumation : exhumation
        }),
        addListeDefunt: (defunts) => dispatch({
            type: 'ADD_LISTE_DEFUNT',
            defunts : defunts
        }),
        defuntDisplay: (defunt) => dispatch({
            type: 'DISPLAY_DEFUNT',
            defunt : defunt
        })
    }
}

const mapStateToProps = ({exhumation = null, defunt = null, defuntListe = {}, defuntAjoute = 0,defuntAjouteId = null }) => ({
    exhumation,
    defunt,
    defuntListe,
    defuntAjoute,
    defuntAjouteId
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormExhumation);

