import axios from "axios";
import DateTools from "./DateTools";
import DownloadSnappy from "./DownloadSnappy";

const ShowHistorique = (id, data) => {
    axios.get(process.env.REACT_APP_API_URL + '/emplacements/emplacements_archived/' + id).then(response => {
        data.setEmplacementInfo(response.data);
    }).catch(error => {
        console.log(error)
    })
}

export default ShowHistorique;