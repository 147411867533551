import React, {Component} from 'react';
import imgConcession from '../../assets/images/img-kabar.png';
import greenBellIcon from '../../assets/images/green-bell-icon.svg';
import pdfIconTbl from '../../assets/images/pdf-icon-tbl.svg';
import back from "../../assets/images/back-icon-purple.svg";
import {Link} from 'react-router-dom';
import $ from "jquery";
import {connect} from "react-redux";
import axios from "axios";
import {getEmplacement} from "../../actions/emplacements";
import redBellIcon from "../../assets/images/red-bell-icon.svg";
import greyBellIcon from "../../assets/images/grey-bell-icon.svg";
import DateTools from "../../components/utils/DateTools";
import GetPDF from "../../components/utils/GetPDF";
import dayjs from 'dayjs';
import {mergeDeep} from "../../utils/mergeDeep";
import user from "../../helpers/user";

class Plan extends Component {

    constructor(props) {
        super(props);
        this.busyItems = `.stamp1111 `;
        this.echuItems = `.test `;
        this.show = `.test `;
        this.page = 1;
        this.nbItems = 150;
        this.mapData = {};
        this.oldMapData = {};
        this.filtersDate = true;
        this.firstTime = true;
        this.referer = this.props.referer;
        this.canceler = new AbortController();
        this.state = {
            stats: null
        };


        this.myuser = null
        if (localStorage.getItem(user.USER_LOCAL_KEY)) {
            this.myuser = JSON.parse(localStorage.getItem(user.USER_LOCAL_KEY))
        }


    }

    refreshPagination () {
        this.mapData = {};
        this.busyItems = `.stamp1111, .filteroccupe `;
        this.echuItems = `.test, .filterechu `;
        this.show = `.test, .filtershow `;
        this.page = 1

        this.canceler.abort()
        this.fetchMap(this.page, false)
    }
    fetchMap = (page, refresh) => {
        let filters = ''
        if (this.props.filters)
        Object.keys(this.props.filters).map((item) => {
            filters += '&filters[' + item + ']=' + this.props.filters[item]
        })

        var url = "/emplacements/allByEspacejson" +
            "?societe=" + localStorage.getItem('societe_id') +
            "&espace=" + localStorage.getItem('espace_id') +
            "&page=" + page +
            "&nbItemsPerPage=" + this.nbItems +
            "&" + filters
        ;

        if (this.referer === 'concession' && !this.firstTime && !refresh) {

            window.dispatchEvent(new Event('resize'));
            return
        }

        if (0 && page == 1)
        {
            $('.emplacement')
                .removeClass('show')
                .removeClass('filteroccupe')
                .removeClass('filteroccupe')
        }

        axios.get( process.env.REACT_APP_API_URL + url, {
            signal: this.canceler.signal
        }).then((res) => {
            if (res.data) {
                mergeDeep(this.mapData, res.data)
            }

            window.jQuery('#monstyle').remove()
            const $style = document.createElement("style");
            $style.id = "monstyle";

            document.head.appendChild($style);
            let style = this.show  + ' {stroke: white;  stroke-width: 3px;} ' ;

            if (this.props.filters )
                style += this.show  + ' {opacity: 0.8 !important; stroke: white;  stroke-width: 3px;} ' ;

            if (this.referer === 'emplacement') {
                style += ' .echue, .planItemEC, .planItemAR, .planItemNR, .planItemAB, .terrain-commun { background: #aaa !important; fill: #aaa !important } .libre { background: #fff;  stroke-color: black; fill: #fff !important }'
            }
            if (this.referer === 'concession') {
                style +=   '.planInconnu{background: #cecece !important; fill: #cecece !important} .planRepriseABandon {background: #FF9100 !important; fill: #FF9100 !important} .planItemEC, .planItemAR { background: #09D35F !important; fill: #09D35F !important } .echue { background: #FF355C !important; fill: #FF355C !important }'
            }

            $style.innerHTML = style

            if (page == 1) {

                $('.emplacement')
                    .removeClass('show')
                    .removeClass('filteroccupe')
                    .removeClass('filteroccupe')
            }
            if (page == 1 && this.nbItems * this.page < res.data['totalItems'])
            {

                const maxPage =  Math.ceil(res.data['totalItems'] / this.nbItems)
                console.log(maxPage)
                for (let i = page; i <= maxPage; i++) {
                    this.page = this.page + 1;
                    //this.fetchMap(this.page, true)
                    this.fetchMap(this.page, false)
                }
            }

            const css = '.mapplic-filtered svg [id^=landmark] > * {opacity: 1 !important; }';

            window.jQuery('#monstylefilter').remove()
            const $style2 = document.createElement("style");
            $style2.id = "monstylefilter";
            document.head.appendChild($style2);
            if (this.firstTime && page == 1) {
                this.firstTime = false;
                window.jQuery('#mapplic').replaceWith('<div id="mapplic"></div>')
                const map = window.jQuery('#mapplic').mapplic({
                    // source: 'mall.json',
                    axios: axios,
                    searchquery: process.env.REACT_APP_API_URL + url + '&query=',
                    source: this.mapData,
                    customcss: css,
                    sidebar: true,
                    sidebartoggle: true,
                    sortby: 'sortlabel',
                    alphabetic: false, //true,
                    height: '100vh',
                    maxheight: 2000,
                    developer: false,
                    searchdescription: true,
                    searcheverywhere: true,
                    animations: false,
                    minimap: false, //true,
                    marker: 'hidden',
                    fillcolor: false,
                    fullscreen: true,
                    thumbholder: false, //true,
                    selector: "#oldMap *"
                });

                // Location opened
                map.on('locationopened', function (e, location) {
                    // location grants full access to the location
                    window.jQuery('#' + location.id).click()
                    // window.document.getElementById(location.id).click()
                });

                // Level switched
                map.on('levelswitched', function (e, level) {
                    console.log('Switched to ' + level + ' level.');
                });
                map.on('mapready', function (e, self) {
                    window.jQuery('.mapplic-button.mapplic-sidebar-toggle').click();

                    window.dispatchEvent(new Event('resize'));
                });
                this.oldMapData = this.mapData;

            } else if(this.oldMapData.levels) {
                let style = '.toto'

                this.oldMapData.levels[0]['locations'].filter((x) => {
                    return !this.mapData.levels[0]['locations'].filter((y) => {
                        return y.id === x.id
                    }).length
                }).forEach((elm) => {
                    style += ', *[data-id="'+ elm.idInterne +'"] '
                })

                style += '{ display: none }'
                $style2.innerHTML = style
            }

            if (refresh) {
                const map2 = window.jQuery('#mapplic').data('mapplic')
                map2.refreshData(this.mapData)
            }
            window.dispatchEvent(new Event('resize'));
        })
    }

    getImageEmplacement = () => {
        if (!this.props.emplacement.currentEmplacement ||
            !this.props.emplacement.currentEmplacement.emplacement ||
            !this.props.emplacement.currentEmplacement.emplacement.photos ||
            this.props.emplacement.currentEmplacement.emplacement.photos.length === 0
        )
            return imgConcession;

        return process.env.REACT_APP_MEDIA_URL + this.props.emplacement.currentEmplacement.emplacement.photos[0].nom
    }

    openSideBar = () => {
        if (document.getElementById("sidePanelEmplacement"))
            document.getElementById("sidePanelEmplacement").style.width = "350px";
    }

    closeSideBar = () => {
        if (document.getElementById("sidePanelEmplacement"))
            document.getElementById("sidePanelEmplacement").style.width = "0";
    }

    componentDidMount() {
        this.fetchMap(this.page, true)

        axios.get(process.env.REACT_APP_API_URL + "/ESpaces/stats?espace_id=" + localStorage.getItem('espace_id') ).then((res) => {
            this.setState({
                'stats': res.data
            })
        })

        document.addEventListener('click', (event) => {
            if (!document.getElementById('sidePanelEmplacement')?.contains(event.target)) {
                this.closeSideBar()
            }
        })

        window.addEventListener('resize', (event) => {
            if (this.referer == 'concession') {
                let selected = '';
                $('#plan .emplacement').removeClass('show')
                if (this.props.filters) {
                    Object.keys(this.props.filters).map((item) => {
                        this.props.filters[item].map((sub) => {
                            if (sub == '') return
                            $('#plan .' + sub).addClass('show')
                            selected += ' ' + sub
                        })
                    })
                }
                if (selected == '') {
                    $('#plan .emplacement').addClass('show')
                }
            }

            if(this.mapData.levels) {

                this.mapData.levels.map((level) => {
                    level.locations.map((item) => {
                        //   this.emplacements[item.id] = item.uid;
                        //this.show += `, #${item.id} `;
                        let currentItem = $('*[data-id="'+ item.idInterne +'"]')
                        currentItem.addClass('show')
                        if (item.busy === true) {
                            currentItem.addClass('filteroccupe')
                            //    this.busyItems += `, #${item.id} `;
                        }

                        if (item.etat === 'Échue') {
                            currentItem.addClass('filterechu')
                            //   this.echuItems += `, #${item.id} `;
                        }



                    })
                })
            }

        })


        document.getElementById("plan").addEventListener('click', (event) => {

            document.querySelectorAll('.emplacement.active').forEach((item) => {
                item.classList.remove('active')
            })
            if (!event.target.classList.contains('emplacement')) return;
            //if (!this.emplacements[event.target.id]) return;

            document.getElementById("sidePanelEmplacement").style.width = "0";
            setTimeout(function() {
                document.getElementById("sidePanelEmplacement").style.width = "350px";
                event.target.classList.add('active');
            }, 1000)

            this.props.getEmplacement(event.target.dataset.emplacement);

        })

    }

    removeEmplacement(emplacementId) {
        if (window.confirm('Etes vous sûr de vouloir définitivement supprimer cet emplacement ?')) {
            const url = `/emplacements/${emplacementId}`
            axios.delete(process.env.REACT_APP_API_URL + url).then(() => {
                document.querySelector(`[data-id="${emplacementId}"]`).remove()
                this.closeSideBar()
            })
        }
    }

    defunts () {
        if (
            !this.props.emplacement.currentEmplacement ||
            !this.props.emplacement.currentEmplacement.location ||
            !this.props.emplacement.currentEmplacement.emplacement.occupants
        ) {
            return []
        }
        return this.props.emplacement.currentEmplacement.emplacement.occupants
    }

    concessionnaires () {
        if (
            !this.props.emplacement.currentEmplacement ||
            !this.props.emplacement.currentEmplacement.location ||
            !this.props.emplacement.currentEmplacement.location.contacts
        ) {
            return ''
        }
        return this.props.emplacement.currentEmplacement.location.contacts.filter((defunt) => {
            return (defunt.typeDeContact !== 'Occupant');
        }).map((contact, index) => {
            return `<div class="carousel-item ${ index === 0 ? 'active': '' }">
                                            <div>
                                                <div class="card card-body">
                                                    <div class="d-flex align-items-center">
                                                        <div class="title soleilBold-fonts purple">
                                                               ${ this.props.emplacement.currentEmplacement.location.type === 'Terrain commun' ? 'Contact' : contact.typeDeContact }
                                                            <span class="info cursor" data-container="body"
                                                                  data-toggle="popover" data-trigger="hover"
                                                                  data-placement="top" data-content="">
                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="text soleilBold-fonts mb-1">
                                                        ${ (contact.civilite === 'F' ? 'Mme.' : 'M.') + ' ' + contact.prenom + ' ' + contact.nom} <br><br>
                                                        ${ (contact.adresse) ? contact.adresse + '<br /><br />' : ''}
                                                        ${ (contact.telephoneMobile) ? contact.telephoneMobile + '<br /><br />' : ''}
                                                        ${ (contact.telephone) ? contact.telephone + '<br /><br />' : ''}
                                                        ${ (contact.email) ? contact.email + '<br /><br />' : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>`
        })
    }

    getContactAjax () {
        let str = [];
        if (this.props.emplacement.currentEmplacement && this.props.emplacement.currentEmplacement.location) {
            this.defunts().map((defunt) => {
                let dateNaissanceIncomplete = null
                if (defunt.hasOwnProperty('dateNaissanceIncomplete')) {
                    dateNaissanceIncomplete = defunt.dateNaissanceIncomplete
                }
                str.push(
                    <div class="soleilLight-fonts purple mt-2">
                        <p>{defunt.civilite === 'F' ? 'Mme.' : 'M.'} {!defunt.nom && !defunt.prenom ? 'Inconnu' : ''} {defunt.nom ? defunt.nom : ''} {defunt.prenom ? defunt.prenom : ''}</p>
                        <p>{DateTools.getDateDefunt(defunt.dateDeNaissance, defunt.dateDeces, dateNaissanceIncomplete)}</p>
                    </div>
                )
            })
        }

        return str;
    }

    render() {
        require('../../assets/css/plan.css')
        require('../../assets/js/mapplic/mapplic.css')
        let showStats = ''
        if (this.state.stats) {
            const stats = this.state.stats;
            showStats = (
                <div>
                    <div>Emplacements libres : {stats.libre.percent} % - {stats.libre.nb} / {stats.libre.total}</div>
                    <div>Concessions échues : {stats.echues.percent} % - {stats.echues.nb} / {stats.concession.total}</div>
                </div>
            )
        }

        let defunts = this.defunts()
        let dateFin = '';
        if (!this.props.emplacement.currentEmplacement.location?.perpetuelle && this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.dateFin && this.props.emplacement.currentEmplacement.location.dateFin) {
            dateFin = dayjs(this.props.emplacement.currentEmplacement.location.dateFin).format('DD/MM/YYYY')
        }

        if (this.props.emplacement.currentEmplacement.location) {

            $('#recipeCarousel').carousel({
                interval: false,
            })

            $('.carousel .carousel-item').each(function () {
                var minPerSlide = 1;
                var next = $(this).next();
                if (!next.length) {
                    next = $(this).siblings(':first');
                }
                next.children(':first-child').clone().appendTo($(this));

                for (var i = 0; i < minPerSlide; i++) {
                    next = next.next();
                    if (!next.length) {
                        next = $(this).siblings(':first');
                    }

                    next.children(':first-child').clone().appendTo($(this));
                }
            });

        }


        return (
            <div>
                <div id="plan" style={{position: "relative"}}>
                    <div className="essential-cemetery-wrapper">
                        <div id="mapplic"></div>
                        {/*<div id="faux-plan" className="bg-white p-3">
                            <div className="flex-start carre bk-green cursor" onClick={this.openSideBar}>
                                <progress className="progress-time" max="100" value="20">20</progress>
                            </div>
                        </div>*/}
                        <div id="stats">
                            {showStats}
                        </div>
                    </div>
                </div>
                <div id="sidePanelEmplacement" className={`sidepanel ${ !this.props.emplacement.currentEmplacement.location ? 'libre' : this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.etatLibelle === 'Manque de données' ? 'no-data' : 'en-cours'} ${ this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.etatLibelle === 'Échue' ? 'echue' : '' }` }>
                    <img src={back} className="closebtn" onClick={this.closeSideBar} alt=""/>
                    {(this.props.emplacement && this.props.emplacement.currentEmplacement && this.props.emplacement.currentEmplacement.emplacement) ?
                        <>
                            <div className="bg-white ml-4 pl-2 pb-2" id="info-emplacement">
                                <div className="row px-3 pb-4">
                                    <div className="col-4">
                                        <a href={this.getImageEmplacement()} target="_blank"><img src={this.getImageEmplacement()} className="img-fluid" alt=""/></a>
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <div>
                                            <p className="soleilBold-fonts purple mt-1">Emplacement {this.props.emplacement.currentEmplacement.emplacement.libelle }</p>
                                            <p className="soleilLight-fonts purple mt-2 statut-emplacement">{ !this.props.emplacement.currentEmplacement.location || (this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.etatLibelle === 'En cours') ?  this.props.emplacement.currentEmplacement.emplacement.etatLibelle : this.props.emplacement.currentEmplacement.location.etatLibelle }</p>
                                        </div>
                                        <div className="mb-4">
                                        <span className="info cursor" data-container="body" data-toggle="popover"
                                              data-trigger="hover" data-placement="top"
                                              data-content="">
                                        </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`row align-items-center px-3 pb-3 ${ !this.props.emplacement.currentEmplacement.location || this.props.emplacement.currentEmplacement.emplacement.typeOccupation === "jardin" ? 'd-none' : 'd-flex' }`}>
                                    <div>
                                        {
                                            this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.etatLibelle === 'Échue' ?
                                                <img src={redBellIcon} className="img-fluid ml-4" width="50px" alt=""/>
                                                :
                                                this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.etatLibelle === 'Manque de données' ?
                                                    <img src={greyBellIcon} className="img-fluid ml-4" width="50px" alt=""/>
                                                    :
                                                    <img src={greenBellIcon} className="img-fluid ml-4" width="50px" alt=""/>
                                        }
                                    </div>
                                    {
                                        !this.props.emplacement.currentEmplacement.location || (this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.type !== 'Terrain commun') ?
                                            <div className="ml-3 purple soleilBold-fonts">Famille { this.props.emplacement.currentEmplacement.location && typeof this.props.emplacement.currentEmplacement.location.famille ? this.props.emplacement.currentEmplacement.location.famille : ''}
                                                <span className="info cursor ml-4" data-container="body" data-toggle="popover"
                                                      data-trigger="hover" data-placement="top"
                                                      data-content="">
                                                </span>
                                            </div>
                                                : <></>
                                            }

                                </div>
                                <div className={`row px-3 pb-3 ${ !this.props.emplacement.currentEmplacement.location  || this.props.emplacement.currentEmplacement.emplacement.typeOccupation === "jardin"? 'd-none' : ''}`} id="sidepanel-infos-concession">
                                    <div className="px-5">
                                        <div className={`sub-title-1 soleilBold-fonts ${this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.etatLibelle === 'Échue' ? 'red-circle' : this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.etatLibelle === 'Manque de données' ? 'grey-circle' : ''}`}>{ this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.etatLibelle ? this.props.emplacement.currentEmplacement.location.etatLibelle : '-' }</div>
                                        <div className={`d-flex mt-1 ${ !this.props.emplacement.currentEmplacement.location ? 'd-none' : '' }`}>
                                            {
                                                this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.type === 'Terrain commun' ?
                                                    <p className="soleilLight-fonts purple">Terrain commun n°</p> :
                                                    <p className="soleilLight-fonts purple">Concession n°</p>
                                            }
                                            <p className="soleilBold-fonts purple">{ this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.numero }</p>
                                        </div>
                                        <div className="d-flex mt-1">
                                            <p className="soleilLight-fonts purple mr-2">Durée :</p>
                                            <p className="soleilBold-fonts purple">{ this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.perpetuelle === 1 || this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.perpetuelle === "1" ? 'Perpétuelle' :
                                                this.props.emplacement.currentEmplacement.location && this.props.emplacement.currentEmplacement.location.duree && this.props.emplacement.currentEmplacement.location.duree ? this.props.emplacement.currentEmplacement.location.duree + ' ans' : ''}</p>
                                        </div>
                                        {
                                            dateFin != '' ?
                                                <div className="d-flex mt-1">
                                                    <p className="soleilLight-fonts purple mr-2">Date de fin :</p>
                                                    <p className="soleilBold-fonts purple">{ dateFin }</p>
                                                </div>
                                                : ''
                                        }
                                    </div>
                                </div>
                                <div className={`mx-3 px-3 py-2 ${ !this.props.emplacement.currentEmplacement.location ? 'd-none' : ''}`} id="sidepanel-infos-defunts">
                                    <div className="soleilBold-fonts purple">{ this.defunts() && this.defunts().length } défunt{this.defunts() && this.defunts().length > 1 ? 's' : '' }</div>
                                    { this.getContactAjax() }
                                </div>
                                <div className="mx-3 mt-3">
                                    <Link to={{
                                        pathname: "/fiche-concession/" + this.props.emplacement.currentEmplacement.emplacement.id
                                    }} id="editer-emplacement-btn"
                                          className="soleilRegular-fonts">consulter / éditer</Link>
                                </div>

                                <div className={`mx-3 py-2 ${ !this.props.emplacement.currentEmplacement.location ? 'd-none' : ''}`}>
                                    <a href={"#"} onClick={() => GetPDF('location', this.props.emplacement.currentEmplacement.location.id)}  id="sidepanel-dl-fiche" className="soleilRegular-fonts">
                                        <img src={pdfIconTbl} className="mr-2" alt=""/>
                                        Télécharger la fiche
                                    </a>
                                </div>

                                {
                                    this.myuser && (this.myuser.roles.includes('ROLE_SUPER_ADMIN') || this.myuser.roles.includes('ROLE_ADMIN')) ?
                                        <div className="mx-3 mt-3">
                                            <a onClick={() => this.removeEmplacement(this.props.emplacement.currentEmplacement.emplacement.id)}>Supprimer l'emplacement</a>
                                        </div>
                                        : ''
                                }

                            </div>
                            <div className={`bg-white mt-3 mx-4 py-3 px-2 mb-0 ${ !this.props.emplacement.currentEmplacement.location || this.props.emplacement.currentEmplacement.emplacement.typeOccupation === "jardin" ? 'd-none' : ''}`} id="sidepanel-contacts">
                                <div id="recipeCarousel" className="carousel slide w-100" data-ride="carousel">
                                    <div className="carousel-inner w-100" role="listbox" dangerouslySetInnerHTML={{__html: this.concessionnaires()}} >

                                    </div>
                                    <a className="carousel-control-prev w-auto" href="#recipeCarousel" role="button"
                                       data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="carousel-control-next w-auto" href="#recipeCarousel" role="button"
                                       data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </>
                        : ''
                    }

                </div>
                <div className="overlay emplacement" onClick={this.closeSideBar}></div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEmplacement: (id) => getEmplacement(id, dispatch)
    }
}
const mapStateToProps = ({emplacement = null}) => ({
    emplacement
});

export default connect(
    mapStateToProps,
    mapDispatchToProps, null, {forwardRef: true}
)(Plan);