import React, { Component } from 'react';
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import $ from "jquery";

class ArchiveModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
        <div className="contents content-margin">
            <div className="modal fade procedure-modal modal-large" id="show-historique" tabIndex="-1" role="dialog"
                 aria-labelledby="etape1" aria-hidden="true">
                <div className="modal-dialog  d-flex justify-content-center align-items-center modal-wrap"
                     role="document">
                    <div className="modal-content" id="content-attribution-emplacement">
                        <div className="modal-header bk-grey">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-body-top" id="location-progression">
                                <p className="soleilBold-fonts title purple">Historique</p>
                            </div>
                            <div className="form-wrapper scroll-popup">
                                <ol>
                                    {
                                        this.props.historique?.map((item) => {
                                            return (
                                                <li style={{"listStyle": "disc"}} key={item.id}>
                                                    <Link to={{pathname: "/fiche-concession-archive/" + this.props.emplacement.id + "/" + item.id }} onClick={() => {

                                                        $('.modal').modal('hide')

                                                    }}><strong>Famille {item.famille} </strong> archivé le {dayjs(item.archivedAt).format('DD/MM/YYYY')}</Link>
                                                </li>
                                            );
                                        })
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default ArchiveModal;