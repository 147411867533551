import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from "../reducers";
import apiMiddleware from "../middleware/api";

const store = createStore(rootReducer, 
    compose(applyMiddleware(apiMiddleware),
 //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
    );
window.store = store;
export default store;


//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 
//Une meilleur visiblité sur la gestion de states via redux (navigateur)
