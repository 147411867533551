import {
  SET_USER,
  FETCH_USER,
  API_START,
  API_END,
} from "../actions/types";
import { fetchApi } from "../actions/index";
import update from 'react-addons-update';

function fetchLinks(data, links) {
  if (links) {
    links.forEach(link => {
      for (let i = 0; i < data.length; i++) {
        fetchApi({
          url: data[i][link].replace('/api/', '/'),
          type: 'UPDATE_' + i + '_' + link,

        })
      }
    })
  }
  return data;
}
        const initialState = {
        };

export default function(state = initialState, action){
  const { type, payload, totalItems } = action;

  switch (type) {
    case FETCH_USER:
      return {
        ...state,
        isLoadingData: true
      };
    case SET_USER:
      console.log('SETUSER')
      return action.payload;
    case API_START:
      if (action.payload === FETCH_USER) {

        return {
          ...state,
          isLoadingData: true
        };
      }
      break;
    case API_END:
      if (action.payload === FETCH_USER) {
        return {
          ...state,
          isLoadingData: false
        };
      }
      break;
    default:
      if (action.type.indexOf('SET_UPDATE') > -1) {
        const field = action.type.replace('SET_UPDATE_', '').split('_')
        return update(state, {
          contents: {
            [field[0]]: {
              [field[1]]: { $set: action.payload }
            }
          }
        });
      }
      else if (action.type.indexOf('FETCH_') > -1) {
        return {
          ...state,
          isLoadingData: false
        };
      } else if (action.type.indexOf('SET_') > -1) {
        let links = [];
        if (action.links) {
          links = action.links;
        }
        return { ...state, data: fetchLinks(action.payload["hydra:member"], links) };
      } else
        return state;
  }
}
