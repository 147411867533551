import React, { Component } from 'react';
import Tools                from "../../../components/utils/Tools";
import redBellIcon          from '../../../assets/images/red-bell-icon.svg';
import infoWhiteIcon        from '../../../assets/images/info-icon-white.svg';
import axios                from "../../../helpers/axios";
import $                    from 'jquery';
import dlDocProcedure       from "../../../assets/images/dl-documents-procedures.svg";
import {Link}               from "react-router-dom";
import HandleDownload       from "../../../components/utils/HandleDownload"

class Donation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emplacementId : this.props.match.params.emplacementId,
            form : [],
            etape : 1,
            emplacement: {} ,
            lastLocation : {},
            donations : [],
            lastDonationCall : 0,
            lastEtapeCall : 0,
            textEtape1 : ''
        }
    }

    componentDidMount() {
        Tools.updateNameUploadFile()
        axios.get(process.env.REACT_APP_API_URL + '/emplacements/' + this.state.emplacementId)
            .then ((response) => {
                this.setState({ emplacement: response.data})
                const location = this.state.emplacement.lastLocation
                axios.get(process.env.REACT_APP_API_URL + '/emplacements/emplacementToutInfos/' + this.state.emplacementId).then((response) => {
                    const donation = response.data.procedures.proceduresAutres['Donation']
                    if (donation ) {
                        if (!donation.possible) {
                            this.setState({textEtape1: donation.texte})
                            $('.step-btn').remove()
                        } else {
                            this.setState({textEtape1: 'Vous devez vérifier ici si les conditions d\'une donation sont remplies avant d\'enregistrer un nouveau titulaire.'})
                        }
                    }
                })
                if(location) {
                    axios.get(process.env.REACT_APP_API_URL + '/locations/' + location.replace(/\D/g, "")).then((response) => {
                        this.setState({lastLocation: response.data})
                    })
                } else {
                    this.setState({textEtape1: 'Donation impossible car aucune concession n\'est en cours'})
                    $('.step-btn').remove()
                }
                let procedures = this.state.emplacement.procedureDonations
                if (procedures !== undefined && procedures.length > 0) {
                    axios.all(procedures.map(p => axios.get(process.env.REACT_APP_API_URL + '/procedure_donations/' + p.replace(/\D/g, ""))))
                        .then(axios.spread((...res) => {
                            let array = {};
                            for (let key in res){
                                array[res[key].data.id] = res[key].data
                            }
                            this.setState({donations: array})
                            let lastDonation = this.state.emplacement.lastProcedureDonation
                            if (lastDonation != null) {
                                lastDonation = lastDonation.replace(/\D/g, "")
                                this.setState({lastDonation : this.state.donations[lastDonation]})
                                if (this.state.lastDonation.etat <= 3 && (this.state.lastDonation.dateFin === null || this.state.lastDonation.dateFin === undefined )) {
                                    this.setState({etape : this.state.lastDonation.etat + 1})
                                    Tools.navigationPopup('Donation', this.state.lastDonation.etat + 1 === 3)
                                }
                            } else {
                                Tools.navigationPopup('Donation', false)
                            }
                        }));
                } else {
                    Tools.navigationPopup('Reprise pour non renouvellement', false)
                }
            })
    }

    getForm = (e) => {
        if (this.state.lastDonationCall !== $(e.target).attr('data-conversion') || this.state.lastEtapeCall !== e.target.id) {

            this.setState({lastDonationCall : $(e.target).attr('data-conversion'), lastEtapeCall : e.target.id})

            e.stopPropagation();

            let promise = process.env.REACT_APP_API_URL + '/donation/form/' + this.state.emplacementId + '/';
            if ($(e.target).attr('data-conversion') !== undefined) {
                promise += e.target.id + '/' + $(e.target).attr('data-conversion')
            } else {
                promise += this.state.etape
            }

            axios.get(promise)
                .then((response) => {
                    this.setState({form: response.data})

                    $('.modal.donation').modal('show');

                    if ($(e.target).attr('data-conversion') === undefined) {
                        this.updateField();
                    }else {
                        $('input, select').prop( "disabled", true )
                        const checkboxHydrated = $('input[type=checkbox]')
                        checkboxHydrated.prop( "checked", true )
                        checkboxHydrated.parent().addClass('select-row')
                    }
                })
            }
    }

    updateField = () => {

        if ($('.modal.donation .ligne.s2').hasClass('last')) {
            $('.preview').show()
        }

        function addFormToCollection($collectionHolderClass, typeContact) {
            let $collectionHolder = $('.' + $collectionHolderClass);
            const maxItem = $collectionHolder.find('.new-contact').length;
            let prototype = $collectionHolder.data('prototype').replaceAll('__name__', maxItem);
            let index = $collectionHolder.data('index');
            let newForm = prototype;
            $collectionHolder.data('index', index + 1);
            let $newFormLi = $("<li class='new-contact " + typeContact + "'></li>").append(newForm);
            $newFormLi.find('select').each(function () {
                let div = document.createElement("div");
                let parent = this.parentNode;
                parent.insertBefore(div, this);
                div.appendChild(this);
            })
            $newFormLi.find('select').parent().addClass('select-bg');
            $('.beneficiaires').append($newFormLi);
        }

        const heritier = $('[id$=heritierMemeSang]');
        const defuntChamps = $('select[id$=presenceDefunt]');

        if (this.state.etape === 1) {
            if (defuntChamps.val() === '1') {
                defuntChamps.prop("disabled", true);
                defuntChamps.css('color', 'grey');
                heritier.attr('data-require', 'data-require');
            }
        } else if (this.state.etape === 2) {
            addFormToCollection('beneficiaires', 'beneficiaire');
            let lastFormBeneficiaire = $('.new-contact:first').find('[id$=typeDeContact]');
            lastFormBeneficiaire.addClass('disable-field');
            lastFormBeneficiaire.css('background', '#6B00B2');
        }

        defuntChamps.change(function () {
            if ($(this).val() === '1') {
                heritier.attr('data-require', 'data-require');
            }else {
                heritier.removeAttr('data-require');
            }
        })

        $('#ajout-beneficiaire').click(function () {
            addFormToCollection('beneficiaires', 'beneficiaire');
            let form = $('.new-contact:last')
            let lastFormBeneficiaire = form.find('[id$=typeDeContact]');
            form.append('<div><a class="soleilLight-fonts red underline float-right cursor mt-2 annuler-beneficiaire">annuler</a></div>');
            lastFormBeneficiaire.css('background', '#6B00B2');
        })

        $(document).on('click', '.annuler-beneficiaire', function () {
            $(this).parent().parent().remove();
        })

        $('[type="radio"].table-checkbox').change(function () {
            $('[type="radio"].table-checkbox:not(:checked)').parent().removeClass('select-row');
        })

        heritier.change(function () {
            if ($(this).val() === '0' && defuntChamps.val() === '1') {
                $('.preview').hide();
                $('#error').text('Vous ne pouvez pas effectuer de donation si un défunt est présent et que le bénéficiaire n\'est pas un héritier du même sang.')
            } else {
                $('.preview').show();
                $('#error').text('');
            }
        })
    }

    render() {
        require('../../../assets/css/procedures.css')
        require('../../../assets/css/formPop-up.css')

        const etiquettesEnCours = [];
        const etiquettesFinies = [];
        const etiquettesTexte = {
            1: {
                button: 'Verifier',
                texte: this.state.textEtape1,
            },
            2 : {
                button: 'Enregistrer',
                texte: 'Vous enregistrez ici le(s) nouveau(x) titulaire(s) de la concession.',
            },
            3 : {
                button: 'Générer',
                texte: 'Vous générez ici un nouvel acte de concession.',
            }
        }

        if (this.state.lastDonation !== undefined) {
            for (const procedure of Object.keys(this.state.donations)) {
                let donation = this.state.donations[procedure]
                for (let i = 1; i <= donation['etat']; i++) {
                    // on verifie si l'étiquette a affiché n'est pas celle en cours et si une autre procedure a été commencé
                    if (donation['id'] !== this.state.lastDonation.id || i !== this.state.etape || this.state.lastDonation.etat === 3) {
                        let dataTarget = '#etape' + i;
                        let dateFin = new Date(donation['etapes'][i]['date_fin']);
                        etiquettesFinies.push(
                            <div className="etape bg-white p-3 etape-finie">
                                <p className="soleilBold-fonts title purple">étape {i}/3 : donation</p>
                                <p className="soleilRegular-fonts purple etape-description first-letter-uppercase">étape effectuée
                                    le {dateFin.toLocaleDateString()}</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    {
                                        i === 3 ?
                                            <a className="dl-doc soleilBold-fonts d-flex cursor" onClick={() => HandleDownload([donation.acte])}>
                                                <img src={dlDocProcedure} className="img-fluid mr-2" width="20px" alt=""/>
                                                <p>Documents</p>
                                            </a>
                                        : ''
                                    }
                                    <button type="button" className="add-doc-btn soleilBold-fonts"
                                            data-toggle="modal" data-target={dataTarget} id={i} data-conversion={procedure}
                                            onClick={this.getForm}>Détails
                                    </button>
                                </div>
                            </div>
                        )
                    }
                }
            }
        }

        let i = this.state.etape
        let dataTarget = '#etape' + i
        etiquettesEnCours.push (
            <div className="etape bg-white p-3">
                <p className="soleilBold-fonts title purple etape-title">étape {i}/3 :
                    donation</p>
                <p className="soleilRegular-fonts purple etape-description">{etiquettesTexte[i]['texte']}</p>
                <button type="button" className="add-doc-btn soleilBold-fonts step-btn"
                        data-toggle="modal" data-target={dataTarget} id={i} onClick={this.getForm}>{etiquettesTexte[i]['button']}
                </button>
            </div>
        )

        return (
            <div className="contents content-margin">
                <div className="content-header px-3 px-lg-4 page2">
                    <span className="expand-btn">&#9776;</span>
                    <div className="d-flex align-items-center">
                        <div className="col-12 soleilBold-fonts">
                            <span className="title">DONATION DE CONCESSION : </span>
                            <span className="text-white">Emplacement {this.state.emplacement.carre} {this.state.emplacement.allee}</span>
                        </div>
                    </div>
                </div>
                <div className="px-3 px-lg-4  ">
                    <div className="retour-concession d-flex align-items-center mt-3 ml-4 mb-3">
                        <img src={redBellIcon} className="mr-3" width="40px" alt=""/>
                        <Link to={{pathname: "/fiche-concession/" + this.props.match.params.emplacementId}}
                              className="soleilBold-fonts purple m-0 pt-4">
                            {
                                this.state.lastLocation && this.state.lastLocation.champsSuppValeurs ?
                                'Famille ' + this.state.lastLocation.champsSuppValeurs.famille
                                : ''
                            } (retour à l'emplacement)
                        </Link>
                    </div>
                    <div className="essential-cemetery-wrapper scroll">
                        <div className="container-fluid px-0 ">
                            <div className="col-lg-12 bg-white py-3">
                                <div className="info-security-content">
                                    <div className="d-lg-inline-flex align-items-center mb-3">
                                        <img src={infoWhiteIcon} alt=""
                                             className="img-fluid mr-2" width="25px"/>
                                        <p className="soleilBold-fonts mt-2 ">Rappels</p>
                                    </div>
                                    <p className="soleilBook-fonts">
                                        Attention, la donation n'est possible qu'au sein de la même famille si la
                                        concession est déjà occupée.
                                        <br/>
                                            La présence d'un acte notarié est indispensable pour effectuer une donation
                                            (Article 931 du code civil).
                                    </p>
                                </div>
                            </div>
                            <div className="etapes-procedures pt-3 d-flex justify-content-center">
                                <div className="etapes-wrapper">
                                    {etiquettesFinies}
                                    {etiquettesEnCours}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{__html:this.state.form}} className="formulaire" data-submit={process.env.REACT_APP_API_URL + '/donation/form/' + this.state.emplacement.id + '/' + this.state.etape}/>
            </div>
        )
    }
}

export default Donation;