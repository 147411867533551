import StepsMainFunctions from "./StepsMainFunctions";
import $ from "jquery";

const steps = [
    {
        id: 'gestion-compte-1',
        attachTo: { element: '.contents.content-margin ', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function() {
            const $this = this
            return new Promise(function(resolve) {
                setTimeout( function () {
                    if(window.location.pathname !== '/gestion-compte') {
                        $this.text = `<p>Cliquez dès maintenant sur <span class="purple">"Commune"</span> pour ouvrir le menu latéral qui donne accès à <span class="purple">la gestion du compte</span> poursuivre le tutoriel.</p>`
                        $this.attachTo = { element: '#link-commune', on: 'bottom' }
                        $this.buttons.splice(2,1)
                        $this.buttons[0].classes = 'shepherd-button-secondary large-margin-button'
                    }
                    resolve();
                }, 300);
            });
        },
        when: {
            show() {
                const $this = this
                if(window.location.pathname !== '/gestion-compte') {
                    StepsMainFunctions.isVisibleSidePanel(this.tour)
                } else {
                    if ($this.tour && $this.tour.steps) {
                        $this.tour.steps.splice(1, 3)
                    }
                }
                StepsMainFunctions.showRewrite($this)
            },
            cancel() {
                $('#link-commune').off('click')
            },
            destroy() {
                $('#link-commune').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous vous trouvez actuellement dans la partie <span class="purple">gestion du compte</span> de votre commune.</p>`,
    },
    {
        id: 'gestion-compte-2',
        attachTo: { element: '#sidePanelCompte', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button-suivant',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Ce sous-menu vous permet d'accéder à différentes pages relatives à la gestion de votre <span class="purple">espace</span> ainsi qu'au <span class="purple">centre d'aide</span>.</p>`,
    },
    {
        id: 'gestion-compte-3',
        attachTo: { element: '#gerer-compte-link', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        when: {
            show() {
                StepsMainFunctions.showRewrite(this)
                StepsMainFunctions.locationToEmplacement('#gerer-compte-link', 'gestion-compte-menu', this)
            },
            cancel() {
                $('#gerer-compte-link').off('click')
            },
            destroy() {
                $('#gerer-compte-link').off('click')
            }
        },
        text: `<p>Cliquez dès maintenant sur <span class="purple">"Gestion du compte"</span> pour accéder à cette section et poursuivre le tutoriel.</p>`,
    },
    {
        id: 'gestion-compte-4',
        attachTo: { element: '.contents.content-margin ', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button-suivant',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous vous trouvez actuellement dans la partie <span class="purple">gestion du compte</span> de votre commune.</p>`,
    },
    {
        id: 'gestion-compte-4',
        attachTo: { element: '#gestion-compte-menu .nav-tabs', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button-suivant',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Ce <span class="purple">menu</span> vous permet de naviguer entre les différentes pages de la gestion 
               du compte. L'élément souligné en <span class="purple">violet</span> est la page que vous visualisez actuellement.</p>`,
    },
    {
        id: 'gestion-compte-5',
        attachTo: { element: '#infos-mairie-link', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    StepsMainFunctions.ChangePanel('infos-mairie-panel','infos-mairie-link')
                    resolve();
                }, 300);
            });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>La partie <span class="purple">Infos mairie</span> vous permet de consulter et modifier les informations et les contacts de votre mairie.</p>`,
    },
    {
        id: 'gestion-compte-6',
        attachTo: { element: '#infos-cimetiere-link', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    StepsMainFunctions.ChangePanel('infos-cimetiere-panel','infos-cimetiere-link')
                    resolve();
                }, 300);
            });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Consultez ou modifier les <span class="purple">informations</span> et gérer les <span class="purple">tarifs</span> de votre <span class="purple">cimetière</span> en cliquant sur <span class="purple">"Infos cimetière"</span>.</p>`,
    },
    {
        id: 'gestion-compte-7',
        attachTo: { element: '#securite-link', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    StepsMainFunctions.ChangePanel('securite-panel','securite-link')
                    resolve();
                }, 300);
            });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>En cas de problème, vous pouvez <span class="purple">modifer votre mot de passe</span> via la section "<span class="purple">Sécurité"</span>.</p>`,
    },
    {
        id: 'gestion-compte-8',
        attachTo: { element: '#abonnement-link', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    StepsMainFunctions.ChangePanel('abonnement-panel','abonnement-link')
                    resolve();
                }, 300);
            });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>La section <span class="purple">Abonnement & options</span> regroupent les informations relatives à votre <span class="purple">abonnement Logicim</span>.</p>`,
    },
    {
        id: 'gestion-compte-9',
        attachTo: { element: '#parrainage-link', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    StepsMainFunctions.ChangePanel('parrainage-panel','parrainage-link')
                    resolve();
                }, 300);
            });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Si vous souhaitez en savoir plus sur le parrainage vous pouvez consulter la section <span class="purple">Parrainage</span>.</p>`,
    },
    {
        id: 'gestion-compte-10',
        attachTo: { element: '#notifications-link', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    StepsMainFunctions.ChangePanel('notifications-panel','notifications-link')
                    resolve();
                }, 300);
            });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Consultez ici les <span class="purple">notifications</span> actives sur votre compte Logicim.</p>`,
    },
    {
        id: 'gestion-compte-11',
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Terminer',
                type: 'complete'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez maintenant naviguer dans la partie <span class="purple">gestion de compte</span> de Logicim. 
                Vous pouvez refaire à tout moment ce tutoriel via la fenêtre d'assistance <span class="mini-aide"></span>.</p>`,
    },
]

export default steps;