import React, {Component} from 'react';
import btnPlusIcon from "../../assets/images/btn-plus-icon.svg";
import {Modal} from "react-bootstrap";
import '../../assets/css/ajoutEspace.css'
import AjoutUtilisateur from "./AjoutUtilisateur";
import AjoutMairie from "./AjoutMairie";
import axios from "axios";
import AjoutCimetiere from "./AjoutCimetiere";
import AjoutAbonnement from "./AjoutAbonnement";
import Previsualisation from "./Previsualisation";

class AjoutEspace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show         : false,
            etape        : 1,
            nbEtape      : 4,
            previ        : false,
            utilisateurs : [
                {
                    contact: {}
                }
            ],
            mairie     : {
                horaires: []
            },
            maire     : {
            },
            espaces      : [{
                horaires: []
            }],
            abonnement   : {
                options: []
            },
            forms         : [<AjoutUtilisateur tab={props.tab} uploadFile={this.uploadFile} updateGroups={this.updateGroups} index={0} key={0}/>],
            formsCimetiere: [<AjoutCimetiere  uploadFile={this.uploadFile} updateGroups={this.updateGroups} index={0} key={0}/>],
            message       : '',
            showMessage   : false,
            ajout         : false
        }
        this.usersRef   = React.createRef();
        this.espacesRef = React.createRef();
    }

    next = () => {
        if(this.state.etape === 3 && this.state.mairie.id) {
            this.setState({
                etape : 5,
                previ : true
            })
        } else {
            this.setState(prevState => {
                return {etape: prevState.etape + 1}
            })
        }
    }

    previous = () => {
        if(this.state.previ && this.state.etape === 4 && this.state.mairie.id) {
            this.setState({
                etape : 2,
                previ : false
            })
        } else if (this.state.previ) {
            this.setState({previ: false})
        } else {
            this.setState(prevState => {
                return {
                    etape: prevState.etape - 1
                }
            })
        }
    }

    imposonate = async () => {
        const username = this.state.utilisateurs[0].identifiant
        await localStorage.setItem('impersonate', username)
        window.location.href = "/switch-espace";
    }

    updateEspace = (state, objet) => {
        this.setState({
            [state] : objet
        })
    }

    updateGroups = (state, index, object) => {
        console.log(this.state[state])
        let array = this.state[state]
        array[index] = object
        console.log(index)
        console.log(array)
        this.setState({[state] : array})
    }

    uploadFile = async(e)  => {
        let formData = new FormData()
        formData.append('file', e.target.files[0])
        return await axios.post("/documents/upload/media", formData, {
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    }

    addUser = () => {
        let utilisateurs = this.state.utilisateurs
        let forms        = this.state.forms
        const index      = utilisateurs.length
        utilisateurs.push({
            contact: {}
        })
        forms.push(<AjoutUtilisateur uploadFile={this.uploadFile} updateGroups={this.updateGroups} index={index} key={index}/>)
        this.setState({
            forms       : forms,
            utilisateurs: utilisateurs
        })
    }

    addEspace = () => {
        let espaces = this.state.espaces
        let forms   = this.state.formsCimetiere
        const index = espaces.length
        espaces.push({})
        console.log('pushadd')
        forms.push(<AjoutCimetiere uploadFile={this.uploadFile} updateGroups={this.updateGroups} index={index} key={index}/>)
        this.setState({
            formsCimetiere: forms,
            espaces       : espaces
        })
    }

    checkForm = () => {
        let check = true

        document.querySelectorAll('.form-visible [required]').forEach((item) => {
            if (!item.checkValidity()) {

                item.classList.add('error')
            } else {
                item.classList.remove('error')
            }
            check = item.checkValidity() && check
        })

        return check
    }

    saveEspace() {
        console.log({
            utilisateurs: this.state.utilisateurs,
            mairie      : this.state.mairie,
            maire       : this.state.maire,
            espaces     : this.state.espaces,
            abonnement  : this.state.abonnement,
            typeEspace  : 'logicim'
        })
        axios.post( process.env.REACT_APP_API_URL + '/espace/creer', {
            utilisateurs: this.state.utilisateurs,
            mairie      : this.state.mairie,
            maire       : this.state.maire,
            espaces     : this.state.espaces,
            abonnement  : this.state.abonnement,
            typeEspace  : 'logicim'
        }).then(res => {
            console.log(res.data)
            if (res.data === 'ok') {
                this.setState({
                    message    : 'L\'espace a bien été créé.',
                    showMessage: true,
                    ajout      : true
                })
            } else {
                this.setState({
                    message    : 'Une erreur est survenue. Appelez vos developpeurs préférés ou ressayez.',
                    showMessage: true
                })
            }
        }).catch(() => {
            this.setState({
                message    : 'Une erreur est survenue. Appelez vos developpeurs préférés ou rééssayez.',
                showMessage: true
            })
        })
    }

    render() {
        require('../../assets/css/formPop-up.css')

        return (
            <>
                <div className="text-center text-sm-left">
                    <button type="button" className="add-new-location-btn" onClick={() => this.setState({show: true})}>
                        Ajouter nouveau
                        <img src={btnPlusIcon} alt="+" className="img-fluid ml-2"/>
                    </button>
                </div>
                <Modal show={this.state.showMessage} onHide={() => {this.setState({showMessage : false})}}>
                    <Modal.Header className="bk-grey" closeButton/>
                    <Modal.Body>
                        <p className="text-center">
                            {this.state.message}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="red cancel-btn tbl" onClick={() => {this.setState({show : false})}}>
                            Annuler
                        </button>
                        {
                            this.state.ajout === true ?
                                <button type="button" className="tbl bk-red ml-2" onClick={this.imposonate}>
                                    Voir l'espace
                                </button>
                            : ''
                        }
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.show} onHide={() => {this.setState({show: false})}} id="ajout-espace-wrapper">
                    <Modal.Header className="bk-grey" closeButton>
                        Ajouter un utilisateur
                    </Modal.Header>
                    <div className="modal-body">
                        <div className="modal-body-top">
                            <div className="progression">
                                <span className="jalon s1 fait">
                                    <div className="desc">Utilisateur</div>
                                </span>
                                <span className={"ligne " + (this.state.etape >= 2 ? ' fait' : '')}/>
                                <span className="jalon">
                                    <div className="desc">Mairie</div>
                                </span>
                                <span className={"ligne " + (this.state.etape >= 3 ? ' fait' : '')}/>
                                <span className="jalon">
                                    <div className="desc">Cimetière(s)</div>
                                </span>
                                <span className={"ligne " + (this.state.etape === 4 ? ' fait' : '')}/>
                                <span className="jalon">
                                    <div className="desc">Abonnement</div>
                                </span>
                                <span className={"ligne " + (this.state.previ ? ' fait' : '')}/>
                                <span className="jalon">
                                    <div className="desc">Vérification Validation</div>
                                </span>
                            </div>
                        </div>
                        <div className="py-4">
                            <div className={this.state.etape !== 1 ? 'd-none' : 'form-visible'}>
                                <div ref={this.usersRef}>
                                    {this.state.forms}
                                </div>
                                <div className="py-2">
                                    <a className="autocomplete-cancel cursor red float-right" onClick={this.addUser}>
                                        Ajouter un autre utilisateur
                                    </a>
                                </div>
                            </div>
                             <div className={this.state.etape !== 2 ? 'd-none' : 'form-visible'}>
                                 <AjoutMairie uploadFile={this.uploadFile} updateEspace={this.updateEspace}/>
                             </div>
                            <div className={this.state.etape !== 3 ? 'd-none' : 'form-visible'}>
                                <div>
                                    <div ref={this.espacesRef}>
                                        {this.state.formsCimetiere}
                                    </div>
                                    <div className="py-2">
                                        <a className="autocomplete-cancel cursor red float-right" onClick={this.addEspace}>
                                            Ajouter un autre cimetière
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={this.state.etape === 4 && !this.state.previ ? 'form-visible' : 'd-none'}>
                                <AjoutAbonnement updateEspace={this.updateEspace}/>
                            </div>
                            <div className={!this.state.previ ? 'd-none' : ''}>
                                <Previsualisation utilisateurs={this.state.utilisateurs} mairie={this.state.mairie} espaces={this.state.espaces} abonnement={this.state.abonnement}/>
                            </div>
                        </div>
                    </div>
                    <Modal.Footer>
                        <div className='d-flex justify-content-between'>
                            <button type="button" className="red cancel-btn tbl" onClick={() => {this.setState({show : false})}}>
                                Annuler
                            </button>
                            <div>
                                {
                                    this.state.etape > 1 ?
                                        <button type="button" className="tbl bk-red ml-2" onClick={this.previous}>
                                            Précédent
                                        </button>
                                        : ''
                                }
                                {
                                    this.state.previ ?
                                        <button type="button" className="tbl bk-red ml-2" onClick={() => {this.setState({show : false}, this.saveEspace)}}>
                                            Valider
                                        </button>
                                        : this.state.etape === this.state.nbEtape ?
                                            <button type="button" className="tbl bk-red ml-2" onClick={() => {this.checkForm() && this.setState({previ : true})}}>
                                                Prévisualiser
                                            </button>
                                            :
                                            <button type="button" className="tbl bk-red ml-2" onClick={() => {this.checkForm() && this.next()}}>
                                                Suivant
                                            </button>
                                }
                            </div>
                        </div>

                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default AjoutEspace;