import React, { Component } from 'react';
import {Tab, Tabs} from "react-bootstrap"
import InfosMairie from "./InfosMairie";
import InfosCimetiere from "./InfosCimetiere"
import Securite from "./Securite";
import Abonnement from "./Abonnement";
import '../../assets/css/gestion_compte.css';
import Parrainage from "./Parrainage";
import Notifications from "./Notifications";
import axios from "axios";

class gestionMenu extends Component{

    constructor(props) {
        super(props);
        this.state = {
            espace: null,
        }
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + '/e_spaces/' + localStorage.getItem('espace_id')).then(res => this.setState({espace: res.data}))
    }

    render() {
        return (
            <div className="contents content-margin ">
                <div className="content-header px-3 px-lg-4 page2">
                    <span className="sidemenu-expand-btn">&#9776;</span>
                    <div className="d-flex align-items-center">
                        <div className="col-12 soleilBold-fonts title">
                            GÉRER MON COMPTE
                        </div>
                    </div>
                </div>
                <div className="px-3 px-lg-4" id="gestion-compte-menu">
                    <Tabs defaultActiveKey="infos_mairie" className="quick-navbar d-flex mb-3 justify-content-center px-3">
                        <Tab eventKey="infos_mairie" title="Infos Mairie" id="infos-mairie-link">
                            <div className="essential-cemetery-wrapper scroll infos_mairie" id="infos-mairie-panel">
                                <InfosMairie setSociete={this.setSociete}/>
                            </div>
                        </Tab>
                        <Tab eventKey="infos_cimetiere" title="Infos cimetière" id="infos-cimetiere-link">
                            <div className="essential-cemetery-wrapper scroll" id="infos-cimetiere-panel">
                                <InfosCimetiere espace={this.state.espace}/>
                            </div>
                        </Tab>
                        <Tab eventKey="securite" title="Sécurité" id="securite-link">
                            <div className="essential-cemetery-wrapper scroll" id="securite-panel">
                                <Securite/>
                            </div>
                        </Tab>
                        <Tab eventKey="abonnement" title="Abonnement & options" id="abonnement-link">
                            <div className="essential-cemetery-wrapper scroll" id="abonnement-panel">
                                <Abonnement/>
                            </div>
                        </Tab>
                        <Tab eventKey="parrainage" title="Parrainage" id="parrainage-link">
                            <div className="essential-cemetery-wrapper scroll" id="parrainage-panel">
                                <Parrainage/>
                            </div>
                        </Tab>
                        <Tab eventKey="notifications"  title="Notifications" id="notifications-link">
                            <div className="essential-cemetery-wrapper scroll" id="notifications-panel">
                                <Notifications espace={this.state.espace}/>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        )
    }

}

export default gestionMenu;