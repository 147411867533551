import {SELECT_MAP_LOCATION, SET_CHOOSE_EMPLACEMENT} from "../actions/types";
import { fetchApi } from "../actions/index";
import update from 'react-addons-update';

function fetchLinks(data, links) {
    if (links) {
        links.forEach(link => {
            for (let i = 0; i < data.length; i++) {
                fetchApi({
                    url: data[i][link].replace('/api/', '/'),
                    type: 'UPDATE_' + i + '_' + link,

                })
            }
        })
    }

    return data;
}

const initialState = {
    loading: true,
    currentEmplacement: {},
    error: {},
};

export default function(state = initialState, action) {
    const { type } = action;
    switch (type) {
        case SET_CHOOSE_EMPLACEMENT:
            return { ...state, currentEmplacement: action.payload };
        default:
             return state;
    }
}
