function render() {
  return (

    <div className="contents content-margin">
      <div className="content-header px-3 px-lg-4 page2">
        <span className="sidemenu-expand-btn">&#9776;</span>
        <div className="d-flex align-items-center">
          <div className="col-12 soleilBold-fonts title">
            GÉRER MON COMPTE
          </div>
        </div>
      </div>
      <div className="px-3 px-lg-4">
        <div className="quick-navbar d-flex mb-3 justify-content-center">
          <div className="my-2-h text-center">
            <span>Infos Mairie</span>
          </div>
          <div className="my-2-h text-center active">
            <span>infos cimetière(s)</span>
          </div>
          <div className="my-2-h text-center">
            <span>Sécurité</span>
          </div>
          <div className="my-2-h text-center">
            <span>Abonnement & options</span>
          </div>
          <div className="my-2-h text-center">
            <span>Parrainage</span>
          </div>
        </div>

        <div className="essential-cemetery-wrapper">
          <div className="header px-3 px-lg-5 py-2 soleilBold-fonts">
            <img src="assets/images/red-bell-icon.svg" alt=""
                 className="img-fluid mr-2"/>
            Cimetière Essentiel
            <span className="soleilBold-fonts ml-5">en ligne</span>
          </div>

          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-wrapper mt-3">
                  <div className="d-flex">
                    <label className="col-4 soleilRegular-fonts">Nom
                      cimetière</label>
                    <input type="text" value="Cimetière Essentiel"
                           className="col-8 soleilRegular-fonts"/>
                  </div>
                  <div className="d-flex mt-2">
                    <label className="col-4 soleilRegular-fonts">Adresse</label>
                    <input type="text" value="Rue du cimetière"
                           className="col-8 soleilRegular-fonts"/>
                  </div>
                  <div className="d-flex mt-2">
                    <label className="col-4 soleilRegular-fonts">Code
                      postal</label>
                    <input type="text" value="10430"
                           className="col-8 soleilRegular-fonts"/>
                  </div>
                  <div className="d-flex mt-2">
                    <label className="col-4 soleilRegular-fonts">Ville</label>
                    <input type="text" value="ROSIERSES PRES TROYES"
                           className="col-8 soleilRegular-fonts"/>
                  </div>
                </div>
                <div className="timer-wrapper mt-3">
                  <div className="title soleilBold-fonts"><img
                    src="assets/images/timer-icon.svg" alt=""
                    className="img-fluid mr-3"/> HORAIRES CIMETIÈRE
                  </div>
                  <div className="d-flex">
                    <div className="cols">
                      <label>Lundi</label>
                      <textarea>8h - 19h</textarea>
                    </div>
                    <div className="cols">
                      <label>Mardi</label>
                      <textarea>8h - 19h</textarea>
                    </div>
                    <div className="cols">
                      <label>Mercredi</label>
                      <textarea>8h - 19h</textarea>
                    </div>
                    <div className="cols">
                      <label>Jeudi</label>
                      <textarea>8h - 19h</textarea>
                    </div>
                    <div className="cols">
                      <label>Vendredi</label>
                      <textarea>8h - 19h</textarea>
                    </div>
                    <div className="cols">
                      <label>Samedi</label>
                      <textarea>8h - 19h</textarea>
                    </div>
                    <div className="cols">
                      <label>Dimanche</label>
                      <textarea>Fermé</textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="pdf-wrapper mt-3">
                  <div className="soleilRegular-fonts text-1 px-3">
                    <img src="assets/images/green-tick-icon.svg" alt=""
                         className="img-fluid mr-2"/>
                    Règlement du cimetière
                  </div>
                  <div className="d-flex mt-2 px-3">
                    <div className="pdf-name soleilBold-fonts">
                      <img src="assets/images/pdf-added-icon.svg" alt=""
                           className="img-fluid"/>
                      reglement-cim-rivdc.pdf
                    </div>
                    <button type="button"
                            className="download-btn soleilRegular-fonts">Télécharger
                    </button>
                    <button type="button"
                            className="modify-btn soleilRegular-fonts">Modifier
                    </button>
                    <button type="button"
                            className="delete-btn soleilRegular-fonts">Supprimer
                    </button>
                  </div>
                  <div className="warning-wrapper mt-5">
                    <div className="title">
                      <img src="assets/images/warning-icon.svg" alt=""
                           className="img-fluid"/>
                      <span className="soleilRegular-fonts">Vous avez du patrimoine funéraire ? Téléchargez vos informations pour vos citoyens (pdf)</span>
                    </div>
                    <div className="browse-file mt-3">
                      <label htmlFor="pdf-file-select"
                             className="soleilRegular-fonts mr-3">
                        Parcourir ...
                        <input type="file" id="pdf-file-select"
                               accept="application/pdf" className="d-none" />
                      </label>
                      <div
                        className="select-pdf-file-name soleilRegular-fonts">Aucun
                        fichier PDF sélectionné
                      </div>
                    </div>
                  </div>
                  <hr />
                    <div className="soleilRegular-fonts text-1 px-3">Nom autre
                      document
                    </div>
                    <div className="d-flex mt-2 px-3">
                      <div className="pdf-name soleilBold-fonts">
                        <img src="assets/images/pdf-added-icon.svg" alt=""
                             className="img-fluid"/>
                        reglement-cim-rivdc.pdf
                      </div>
                      <button type="button"
                              className="download-btn soleilRegular-fonts">Télécharger
                      </button>
                    </div>
                    <button type="button"
                            className="ml-3 add-doc-btn soleilRegular-fonts mt-4">Ajouter
                      un document
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default render;
