import React, { Component } from 'react';
import axios from "axios";
import {Dropdown, Modal} from "react-bootstrap";


class SupprimerDefunt extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show   : false,
            defunt : this.props.defunt ? JSON.parse(this.props.defunt).occupant : null
        }
    }

    supprimerDefunt = () => {
        this.setState({show : false})
        if (this.props.defunt && this.props.emplacementId) {
            axios.get(process.env.REACT_APP_API_URL + '/contacts/supprimer/occupant/' + JSON.parse(this.props.defunt).occupant.id + '/' + this.props.emplacementId).then((response) => {
                const fermerAjoutDefunt = this.props.fermerAjoutDefunt
                fermerAjoutDefunt(null)
                const removeDefunt = this.props.removeDefunt
                removeDefunt(response.data.defuntId)
                let cremation = JSON.parse(this.props.defunt).cremation
                if((cremation && cremation.destinationUrneCendre !== "Scellement sur le monument de la sépulture") || !cremation) {
                    const ajoutPlaceOccupee = this.props.handlePlaceOccupee
                    ajoutPlaceOccupee(1, 'remove')
                }
            })
        }
    }

    render() {
        let defunt = this.props.defunt ? this.props.defunt : null
        return (
            <>
                <Dropdown.Item className={"soleilRegular-fonts purple " + (!defunt ? "disabled-click" : '')} onClick={() => {
                    if(defunt) {
                        this.setState({show: true})
                    }
                }}>Supprimer le
                    défunt
                </Dropdown.Item>
                <Modal show={this.state.show} onHide={() => {this.setState({show : false})}}>
                    <Modal.Header className="bk-grey" closeButton>
                        Confirmer la suppression
                    </Modal.Header>
                    <Modal.Body>
                        <p className="text-center">
                            Êtes vous sûr de vouloir supprimer le defunt&nbsp;
                            <span className="soleilBold-fonts purple">
                                { this.props.defunt ? JSON.parse(this.props.defunt).occupant.nom + ' ' + JSON.parse(this.props.defunt).occupant.prenom : ''}&nbsp;
                            </span>
                            ?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="red cancel-btn tbl" onClick={() => {this.setState({show : false})}}>
                            Annuler
                        </button>
                        <button type="button" className="tbl bk-red ml-2" onClick={this.supprimerDefunt}>
                            Valider
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default SupprimerDefunt;