import React, { Component } from 'react';
import {Dropdown, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import DateTools from "../utils/DateTools";
import SearchEmplacement from "../search/SearchEmplacement";
import axios from "axios";

class DeplacerDefunt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show         : false,
            id           : null,
            error        : '',
            response     : '',
            confirmation : false

        }
    }

    deplacerDefunt = () => {
        const id = document.getElementById('hidden-field-id')
        const defunt = JSON.parse(this.props.defunt.defunt).occupant
        if (id && id.value !== '') {
            axios.post(process.env.REACT_APP_API_URL + '/logicim/deplacer_defunt', {
                defunt : defunt.id,
                emplacement : id.value
            }).then(res => {
                this.setState({
                    error: '',
                    show: false,
                })
                let response = res.data
                const removeDefunt = this.props.removeDefunt
                if (response === 'ok.') {
                    response = 'Le défunt a été déplacé.'
                    if (removeDefunt) {
                        removeDefunt(defunt.id)
                    }
                }
                this.setState({
                    error        : '',
                    show         : false,
                    response     : response,
                    confirmation : true
                })
            })
        } else {
            this.setState({error : 'veuillez rechercher un emplacement'})
        }
    }

    render() {
        let defunt = this.props.defunt ? this.props.defunt.defunt : null
        let dateNaissanceIncomplete = null
        if (defunt !== null) {
            defunt = JSON.parse(defunt).occupant
            if (defunt.hasOwnProperty('dateNaissanceIncomplete')) {
                dateNaissanceIncomplete = defunt.dateNaissanceIncomplete
            }
        }
        return (
            <>
                <Dropdown.Item className={"soleilRegular-fonts purple " + (!defunt ? "disabled-click" : '') } onClick={() => {
                    if (defunt) {
                        this.setState({show: true})
                    }
                }}>
                    Déplacer le défunt suite à une erreur
                </Dropdown.Item>
                <Modal show={this.state.show} onHide={() => {this.setState({show : false})}}>
                    <Modal.Header className="bk-grey" closeButton>
                        Déplacer un défunt
                    </Modal.Header>
                    <Modal.Body>
                        <Modal.Title>
                            <div className="info-security-content mb-3">
                                <div className="d-lg-inline-flex align-items-center mb-3">
                                    <p className="soleilBold-fonts mt-2 rappels">Attention</p>
                                </div>
                                <div>
                                    <p>
                                        Cette procédure permet de déplacer un défunt suite à une mauvaise saisie dans le mauvais emplacement.
                                        Si il ne s'agit pas d'une erreur de saisie, veuillez effectuer une procédure
                                        d'exhumation pour le défunt que vous souhaitez déplacer.
                                    </p>
                                </div>
                            </div>
                            {
                                defunt ?
                                    <div className="deplacer-defunt mx-1">
                                        <div>
                                            <p className="soleilRegular-fonts">Changement d'emplacement pour :</p>
                                            <p className="text-center mt-1">
                                                {
                                                    defunt.civilite === 'H' ? ' Mr. ' : ' M. '
                                                }
                                                {
                                                    defunt.nom === '' && defunt.prenom === '' ? 'nom et prénom inconnus' : defunt.nom ? defunt.nom : '- ' + defunt.prenom ? defunt.prenom : '-'
                                                }
                                            </p>
                                            <p className="text-center">
                                                {
                                                    DateTools.getDateDefunt(defunt.dateDeNaissance, defunt.dateDeces, dateNaissanceIncomplete)
                                                }
                                            </p>
                                        </div>
                                        <div className="recherche-deplacement mt-4">
                                            <SearchEmplacement id="deplacement-emplacement-recherche" hiddenField="hidden-field-id" libelle="Occupé"/>
                                        </div>
                                        <input type="text" id="hidden-field-id" className="display-none"/>
                                        <p className="error text-center red">{this.state.error}</p>
                                    </div>
                                    :
                                    <div>
                                        <p className="soleilLight-fonts font-italic">Défunt introuvable</p>
                                    </div>
                            }
                        </Modal.Title>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="red cancel-btn tbl" onClick={() => {this.setState({show : false})}}>
                            Annuler
                        </button>
                        {
                            defunt ?
                                <button type="button" className="tbl bk-red ml-2" onClick={this.deplacerDefunt}>
                                    Confirmer le déplacement
                                </button>
                                : ''
                        }
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.confirmation} onHide={() => {this.setState({confirmation : false, response : ''})}}>
                    <Modal.Header className="bk-grey" closeButton/>
                    <Modal.Body>
                        <p className="text-center my-5">{this.state.response}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="tbl bk-red ml-2" onClick={() => {this.setState({confirmation : false, response : ''})}}>
                           Quitter
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = ({defunt = null}) => ({
    defunt
});

export default connect(
    mapStateToProps
)(DeplacerDefunt);