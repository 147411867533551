import React, { Component } from 'react';
import '../../assets/css/ajoutEspace.css'

class AjoutUtilisateur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            utilisateur     : {
                identifiant: '',
                mdp        : '',
                contact    : {
                    civilite : 'H',
                    nom      : '',
                    prenom   : '',
                    fonction : 'Maire',
                    telephone: '',
                    signature: null,
                    role     : 'ROLE_USER'
                },
            },
            signatureDisplay: null
        }
    }

    updateEspace = (utilisateur) => {
        const updateGroups = this.props.updateGroups
        if (updateGroups) {
            updateGroups('utilisateurs', this.props.index, utilisateur)
        }
    }

    handleChangeUtilisateur = (name, value)  =>{
        let utilisateur = {
            ...this.state.utilisateur,
            [name]: value
        }
        this.setState({
            utilisateur : utilisateur
        });
        this.updateEspace(utilisateur)
    }

    handleChangeContact = (event)  =>{
        let name = event.target.name
        let utilisateur = {
            ...this.state.utilisateur,
            contact: {
                ...this.state.utilisateur.contact,
                [name]: event.target.value
            }
        }
        this.setState({
            utilisateur : utilisateur
        });
        this.updateEspace(utilisateur)
    }

    handleChangeSignature = async (e) => {
        const uploadFile = this.props.uploadFile
        if (uploadFile) {
            const res = await this.props.uploadFile(e)
            let utilisateur = {
                ...this.state.utilisateur,
                contact: {
                    ...this.state.utilisateur.contact,
                    signature: res ? process.env.REACT_APP_API_URI + '/image_media/' + res.data.id : null
                }
            }
            this.setState({
                utilisateur     : utilisateur,
                signatureDisplay: res ? process.env.REACT_APP_MEDIA_URL + res.data.nom : null
            });
            this.updateEspace(utilisateur)
        }
    }

    handleCivilite = (value) => {
        let utilisateur = {
            ...this.state.utilisateur,
            contact: {
                ...this.state.utilisateur.contact,
                civilite: value
            }
        }
        this.setState({
            utilisateur : utilisateur
        });
        this.updateEspace(utilisateur)
    }

    supprimerSignature = () => {
        let utilisateur = {
            ...this.state.utilisateur,
            contact: {
                ...this.state.utilisateur.contact,
                signature: null
            }
        }
        this.setState({
            utilisateur     : utilisateur,
            signatureDisplay: null
        });
        this.updateEspace(utilisateur)
    }

    generateMDP = () => {
        const chars  = '&#@ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const length = 10;
        let result   = '';
        for (let i = length; i > 0; --i) {
            result += chars[Math.floor(Math.random() * chars.length)];
        }
        this.handleChangeUtilisateur('mdp', result)
    }

    render() {
        return (
            <>
                <div className="form-wrapper px-5">
                    <p className="soleilBold-fonts purple">Identifiants de connexion</p>
                    <div className="px-2">
                        <div className="d-flex pt-3">
                            <label htmlFor="identifiant" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Email :</label>
                            <input type="text" id="identifiant" name="identifiant" value={this.state.utilisateur.identifiant}
                                   className="soleilRegular-fonts col-3" placeholder="Email" onChange={(e) => this.handleChangeUtilisateur(e.target.name, e.target.value)}/>
                        </div>
                        <div className="d-flex pt-2">
                            <label htmlFor="mdp" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Mot de passe :</label>
                            <input type="text" id="mdp" name="mdp" value={this.state.utilisateur.mdp}
                                   className="soleilRegular-fonts col-3" placeholder="Mot de passe" onChange={(e) => this.handleChangeUtilisateur(e.target.name, e.target.value)}/>
                            <button type="button" className="add-new-location-btn ml-2" onClick={this.generateMDP}>Générer</button>
                        </div>
                    </div>
                </div>
                <div className="form-wrapper px-5">
                    <p className="soleilBold-fonts purple">Informations contact</p>
                    <div className="px-2">
                        <div className="d-flex pt-3">
                            <label className="col-3 p-0 m-0 soleilRegular-fonts">Civilité :</label>
                            <div className="col-8 p-0 m-0 d-flex">
                                <div className="mr-4">
                                    <input type="radio" value="H" id={"H_" + this.props.index} name={"civilite_" + this.props.index}  checked={this.state.utilisateur.contact.civilite === "H"} onClick={(e) => this.handleCivilite("H")}/>
                                    <label htmlFor={"H_" + this.props.index} className="soleilRegular-fonts purple radio-label">Monsieur</label>
                                </div>
                                <div>
                                    <input type="radio" value="F" id={"F_" + this.props.index} name={"civilite_" + this.props.index} checked={this.state.utilisateur.contact.civilite === "F"} onClick={(e) => this.handleCivilite("F")}/>
                                    <label htmlFor={"F_" + this.props.index} className="soleilRegular-fonts purple">Madame</label>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex pt-3">
                            <label htmlFor="commune_nom" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Fonction :</label>
                            <div className="select-grey-wrapper">
                                <select className="soleilRegular-fonts purple select-grey" id="fonction" name="fonction" value={this.state.utilisateur.contact.fonction} onChange={this.handleChangeContact}>
                                    <option value="Maire">Maire</option>
                                    <option value="Secrétaire">Secrétaire</option>
                                    <option value="Elu">Élu</option>
                                    <option value="Directeur Général Services">Directeur Général Services</option>
                                    <option value="Responsable cimetière">Responsable cimetière</option>
                                </select>
                            </div>
                        </div>
                        <div className="d-flex pt-2">
                            <label htmlFor="nom" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Nom :</label>
                            <input type="text" id="nom" name="nom" value={this.state.utilisateur.contact.nom}
                                   className="soleilRegular-fonts col-3" placeholder="Nom" onChange={this.handleChangeContact}/>
                        </div>
                        <div className="d-flex pt-2">
                            <label htmlFor="prenom" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Prénom :</label>
                            <input type="text" id="prenom" name="prenom" value={this.state.utilisateur.contact.prenom}
                                   className="soleilRegular-fonts col-3" placeholder="Prénom" onChange={this.handleChangeContact}/>
                        </div>
                        <div className="d-flex pt-2">
                            <label htmlFor="prenom" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Téléphone :</label>
                            <input type="number" id="telephone" name="telephone" value={this.state.utilisateur.contact.telephone}
                                   className="soleilRegular-fonts col-3" placeholder="Téléphone" onChange={this.handleChangeContact}/>
                        </div>
                        {
                            this.props.tab === 'activated' || this.props.tab === 'not_activated'
                                ?
                                <div className="d-flex pt-2">
                                    <label htmlFor="role" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Rôle :</label>

                                    <div className="select-grey-wrapper">
                                        <select className="soleilRegular-fonts purple select-grey" id="fonction" name="fonction" value={this.state.utilisateur.role} onChange={(e) => {this.handleChangeUtilisateur(e.target.name, e.target.value)}}>
                                            <option value="ROLE_USER">Consultation</option>
                                            <option value="ROLE_SOUS_ADMIN">Consultation et modification</option>
                                            <option value="ROLE_ADMIN">Administrateur du compte client</option>
                                        </select>
                                    </div>
                                </div>
                                :
                                    <div></div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default AjoutUtilisateur;