import StepsMainFunctions from "./StepsMainFunctions";
import $ from "jquery";

const steps = [
    {
        id: 'centre-aide-1',
        attachTo: {element: '.contents.content-margin', on: 'top'},
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function () {
            const $this = this
            return new Promise(function (resolve) {
                setTimeout(function () {
                    if (window.location.pathname !== '/aide') {
                        $this.text = `<p>Cliquez dès maintenant sur <span class="purple">"Commune"</span> pour ouvrir le menu latéral qui donne accès au <span class="purple">centre d'aide</span> et poursuivre le tutoriel.</p>`
                        $this.attachTo = {element: '#link-commune', on: 'bottom'}
                        $this.buttons.splice(2, 1)
                        $this.buttons[0].classes = 'shepherd-button-secondary large-margin-button'
                    }
                    resolve();
                }, 300);
            });
        },
        when: {
            show() {
                const $this = this
                if(window.location.pathname !== '/aide') {
                    StepsMainFunctions.isVisibleSidePanel(this.tour)
                } else {
                    if (this.tour && this.tour.steps) {
                        $this.tour.steps.splice(1, 3)
                    }
                }
                StepsMainFunctions.showRewrite($this)
            },
            cancel() {
                $('#link-commune').off('click')
            },
            destroy() {
                $('#link-commune').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous vous trouvez actuellement dans la partie <span class="purple">centre d'aide</span> de Logicim.</p>`,
    },
    {
        id: 'centre-aide-2',
        attachTo: { element: '#sidePanelCompte', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Ce sous-menu vous permet d'accéder à différentes pages relatives à la gestion de votre <span class="purple">espace</span> ainsi qu'au <span class="purple">centre d'aide</span>.</p>`,
    },
    {
        id: 'centre-aide-3',
        attachTo: { element: '#centre-aide-link', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        when: {
            show() {
                StepsMainFunctions.showRewrite(this)
                StepsMainFunctions.locationToEmplacement('#centre-aide-link', 'centre-aide-menu', this)
            },
            cancel() {
                $('#centre-aide-link').off('click')
            },
            destroy() {
                $('#centre-aide-link').off('click')
            }
        },
        text: `<p>Cliquez dès maintenant sur <span class="purple">"Centre d'aide"</span> pour accéder à cette section et poursuivre le tutoriel.</p>`,
    },
    {
        id: 'centre-aide-4',
        attachTo: { element: '.contents.content-margin', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button-suivant',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous vous trouvez actuellement dans la partie <span class="purple">Centre d'aide</span> de Logicim.</p>`,
    },
    {
        id: 'centre-aide-5',
        attachTo: { element: '#centre-aide-menu .nav-tabs', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Ce <span class="purple">menu</span> vous permet de naviguer entre les différentes pages du centre d'aide. 
            L'élément souligné en <span class="purple">violet</span> est la page que vous visualisez actuellement.</p>`,
    },
    {
        id: 'centre-aide-5',
        attachTo: { element: '#lexique-link', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    StepsMainFunctions.ChangePanel('scroll-to','lexique-link')
                    resolve();
                }, 300);
            });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Le <span class="purple">lexique</span> est maintenant <span class="purple">interactif</span>. Il vous permet d'accéder directement à de nombreuses <span class="purple">définitions</span> concernant le langage du cimetière.</p>`,
    },
    {
        id: 'centre-aide-6',
        attachTo: { element: '#alphabet-list-wrapper', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez cliquer sur une lettre pour voir les définitions commençant par cette lettre. La lettre en <span class="purple">violet</span> est celle dont vous consultez les définitions actuellement.</p>`,
    },
    {
        id: 'centre-aide-7',
        scrollTo: true,
        attachTo: { element: '.definitions-wrapper', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Consultez ici les <span class="purple">définitions</span> de la lettre selectionnée.</p>`,
    },
    {
        id: 'centre-aide-8',
        scrollTo: true,
        attachTo: { element: '#dl-lexique-wrapper', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez aussi <span class="purple">télécharger</span> le lexique sur votre ordinateur en format <span class="purple">PDF</span>.</p>`,
    },
    {
        id: 'centre-aide-9',
        scrollTo: true,
        attachTo: { element: '#tutoriels-link', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    StepsMainFunctions.ChangePanel('tutoriels-panel','tutoriels-link')
                    resolve();
                }, 300);
            });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Tous nos tutoriels sont disponibles dans la partie <span class="purple">Tutoriels</span> du centre d'aide.</p>`,
    },
    {
        id: 'centre-aide-10',
        attachTo: { element: '#faq-link', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    StepsMainFunctions.ChangePanel('faq-panel','faq-link')
                    resolve();
                }, 300);
            });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>La section <span class="purple">FAQ</span> regroupe des questions/réponses qui peuvent vous être utiles pour votre gestion du cimetière.</p>`,
    },
    {
        id: 'centre-aide-11',
        attachTo: { element: '#contact-link', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    StepsMainFunctions.ChangePanel('contact-panel','contact-link')
                    resolve();
                }, 300);
            });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Pour nous contacter en cas de problème, retrouvez dans la partie <span class="purple">Contacter un conseiller</span> nos informations de contact. Nous serons ravi de vous aider !</p>`,
    },
    {
        id: 'gestion-compte-12',
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Terminer',
                type: 'complete'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez maintenant utiliser le <span class="purple">centre d'aide</span> de Logicim. 
                Vous pouvez refaire à tout moment ce tutoriel via la <span class="purple">fenêtre d'assistance</span> ou le <span class="purple">centre d'aide</span> partie Tutoriels.</p>`,
    },
]

export default steps;