import React, { Component } from 'react';
import axios from '../../helpers/axios';
import '../../svg-map-editor/import.css';
import { appendScript } from '../../utils/appendScript.js';
import { funcGlobal } from '../../utils/funcGlobal';
import OlMap from "ol/Map";
import { fromLonLat, toLonLat } from 'ol/proj';
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import OlSourceOSM from "ol/source/OSM";
// import Rotation from 'react-rotation';


window.funcGlobal = funcGlobal;

class DrawEmplacement extends Component {
 
  constructor(props) {
    super(props);

    this.state = {
      //  center:[4, 48 ],
      //  center:[4.076972, 48.266491],
       center:[4.008672, 48.000087],
       //Array of the center
        zoom: 19,
        //Zoom by default 19
      };

    this.olmap = new OlMap({
      target: null,
      layers: [
        new OlLayerTile({
          source: new OlSourceOSM()
        })
      ],
      view: new OlView({
        center: fromLonLat(this.state.center),
        zoom: this.state.zoom,
      })
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    let center = this.olmap.getView().getCenter();
    let zoom = this.olmap.getView().getZoom();
    if (center === nextState.center && zoom === nextState.zoom) return false;
    this.updateMap()

    return true;
  }
  updateMap() {
    //this.olmap.getView().setCenter(this.state.center);
    this.olmap.getView().setZoom(this.state.zoom);
  }
 
  componentDidMount() {
    this.olmap.setTarget("map");
    appendScript("svg-map-editor/svg-path-parser.js");
    appendScript("svg-map-editor/squares-renderer.js");
    appendScript("svg-map-editor/squares.js");
    appendScript("svg-map-editor/squares-elements-jquery.js");
    appendScript("svg-map-editor/squares-controls.js");
    appendScript("svg-map-editor/wcp-form.js");
    appendScript("svg-map-editor/wcp-form-controls.js");
    appendScript("svg-map-editor/wcp-editor.js");
    appendScript("svg-map-editor/wcp-tour.js");
    appendScript("svg-map-editor/wcp-compress.js");
    appendScript("svg-map-editor/wcp-fontawesome.js");
    appendScript("svg-map-editor/image-map-pro-defaults.js");
    appendScript("svg-map-editor/image-map-pro-editor-countries-jquery.js");
    appendScript("svg-map-editor/image-map-pro-editor.js");
    appendScript("svg-map-editor/image-map-pro-editor-content.js");
    appendScript("svg-map-editor/image-map-pro-editor-storage-jquery.js");
    appendScript("svg-map-editor/image-map-pro-editor-init-jquery.js");
    appendScript("svg-map-editor/image-map-pro.js");
    if (document.getElementById("wcp-editor")) {
      document.getElementById("wcp-editor").addEventListener("click", (e) => {
        this.onClickEditor(e)
      })
    }
  }
  
  //OnclickEditor se déclenche au moment où l'on dessine un élément qui fait référence au state de Map 
  onClickEditor(e) {
    let zoom = this.state.zoom
    let elm = e.target
    console.log(typeof elm.className)
    if (typeof elm.className == "string" && elm.className.indexOf("wcp-editor-toolbar-button") === -1) {
      elm = this.closest(e.target, '.wcp-editor-toolbar-button')
    }
    if (elm == null) return;
    if (typeof elm.dataset["wcpEditorToolbarButtonName"] != "undefined") {
      // zoom
      if (elm.dataset["wcpEditorToolbarButtonName"] == "zoom-out") {
        zoom = this.state.zoom - 1
      } else if (elm.dataset["wcpEditorToolbarButtonName"] == "zoom-in") {
        zoom = this.state.zoom + 1
      }
      this.setState({ zoom: zoom })
      this.updateMap()
    }
  }
  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: parseFloat(e.target.value)
    })
  }
  cumulativeOffset = function(element) {
    var top = 0, left = 0;
    do {
        top += element.offsetTop  || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while(element);

    return {
        top: top,
        left: left
    };
};
  saveCoordonates = () => {
    const taille_nav = document.getElementById('navbar').offsetHeight;
    console.log("taille nav : " + taille_nav )
    const element = document.getElementById('imp-editor-shapes-container')
    const rect = element.getBoundingClientRect();
    const ax = rect.left
    const ay = rect.top - taille_nav
    const bx = rect.right
    const by = ay
    const cx = ax
    const cy = rect.bottom - taille_nav
    const dx = rect.right
    const dy = cy


    // console.log(rect)
    const offsets = this.cumulativeOffset(element)
    const width = element.offsetWidth 
    const Height = element.offsetHeight 
    console.log('Width elm :',width)
    console.log('Height elm :', Height)
    console.log(offsets)
    const coordinates = this.olmap.getCoordinateFromPixel([offsets.top, offsets.left])
    
    const coordinates1 = toLonLat(this.olmap.getCoordinateFromPixel([ax,ay]))
    const coordinates2 = toLonLat(this.olmap.getCoordinateFromPixel([bx,by]))
    const coordinates3 = toLonLat(this.olmap.getCoordinateFromPixel([cx,cy]))
    const coordinates4 = toLonLat(this.olmap.getCoordinateFromPixel([dx,dy]))
    
    console.log(coordinates1)
    var coords = toLonLat(coordinates);
    var lat = coords[1];
    var lon = coords[0];
    
    var locTxt = "Latitude: " + lat + " Longitude: " + lon;
    console.log(locTxt);
    const res = axios.get(process.env.REACT_APP_API_URL + '/ESpaces/coordonnees', {
      params: {
        coordonneesLatLon: {
          NO: [coordinates1[1],coordinates1[0]],
          NE: [coordinates2[1],coordinates2[0]], 
          SO: [coordinates3[1],coordinates3[0]],
          SE: [coordinates4[1],coordinates4[0]]
        },
          coordonneesPixel: [rect.width, rect.height]
      }
  }).then(res => console.log(res))
  }

  postDraw = () => {
    let settings = window.$.image_map_pro_editor_current_settings();
    let nodeSvg = {
      posX: '',
      posY: '',
      zoom: this.state.zoom,
      zLevel: '',
      noeud: '',
      // noeud: '<rect x="503" y="200" width="200" height="400"/>',
      zone: '/api/zones/11',
      uid: "EMPLACEMENT_403",
      adresse: ""
    }
    const UIDPREFIX = 'EMPLACEMENT_'
    console.log(settings.spots.length)
    let svgBuild = '';
    for (var i = 0; i < settings.spots.length; i++) {
      let s = settings.spots[i];
      console.log(s)
      if (s.type == 'rect') {
        nodeSvg.posX = parseFloat(s.x)
        nodeSvg.posY = parseFloat(s.y)
        nodeSvg.zLevel = parseInt(s.layerID)
        nodeSvg.noeud = '<rect x="' + s.x + '" y="' + s.y + '" width="' + s.width + '" height="' + s.height + '"/>'
        nodeSvg.uid = UIDPREFIX + s.id
      }
      if (s.type == 'poly') {
        nodeSvg.posX = parseFloat(s.x)
        nodeSvg.posY = parseFloat(s.y)
        nodeSvg.zLevel = parseInt(s.layerID)
        nodeSvg.noeud = '<poly x="' + s.x + '" y="' + s.y + '" width="' + s.width + '" height="' + s.height + '"/>'
        nodeSvg.uid = UIDPREFIX + s.id
      }
      if (s.type == 'oval') {
        nodeSvg.posX = parseFloat(s.x)
        nodeSvg.posY = parseFloat(s.y)
        nodeSvg.zLevel = parseInt(s.layerID)
        nodeSvg.noeud = '<ellipse cx="' + s.x + '" cy="' + s.y + '" rx="' + s.width + '" ry="' + s.height + '"/>'
        nodeSvg.uid = UIDPREFIX + s.id
      }
      if (s.type == 'star') {
        nodeSvg.posX = parseFloat(s.x)
        nodeSvg.posY = parseFloat(s.y)
        nodeSvg.zLevel = parseInt(s.layerID)
        nodeSvg.noeud = '<poly x="' + s.x + '" y="' + s.y + '" width="' + s.width + '" height="' + s.height + '" rotate= "' + s.rotation + '/>'
        // nodeSvg.noeud = '<poly x="' + s.x + '" y="' + s.y + '" width="' + s.width + '" height="' + s.height + '" rotate= "' + s.rotation + '/>'
        nodeSvg.uid = UIDPREFIX + s.id
      }
      //pour la map pour les e-spaces et non les emplacements en tant que tels.
      svgBuild += nodeSvg.noeud;
      axios.post(process.env.REACT_APP_API_URL + "/emplacements", nodeSvg)
      .catch(error => console.log(error))
      //this.state est lié au Map 
    }
    
    const eSpaceId = localStorage.getItem('espace_id');
      axios.patch("/e_spaces/" + eSpaceId, {
        latitude: this.state.center[0],
        longitude: this.state.center[1],
        zoomInitial: this.state.zoom,
        map: "<svg> " + svgBuild + " </svg>"
      },
      {
        headers: {"Content-Type" : "application/merge-patch+json"}
      })
      .catch(error => console.log('error: ', error))
      // this.props.history.push('/emplacement')
  }
  render() {
  
    return (
      
      <div>
        <div id="map" style={{position: 'absolute', zIndex: 1, height: '100vh', width: '100%'}}/>
        <div style={{position: 'relative', zIndex: 3}}>
        <button id="saveBtn" className="btn btn-outline-dark mr-2" style={{position: 'relative', zIndex: 3}} onClick={this.postDraw}>Sauvegarder</button>
        <button id="saveCoordonates" className="btn btn-outline-dark mr-2" style={{position: 'relative', zIndex: 3}}  onClick={this.saveCoordonates}>Coordonnées</button>
        {/* <button id="getallespaces" className="mr-2" style={{position: 'relative', zIndex: 3}}  onClick={this.getAllEspace}>GetAllEspace</button> */}
        <input type="number" value={this.state.zoom} name="zoom" onChange={this.onChangeInput}/></div>
       
        { this.props.hasEditor
        ?  <div className="contents" id="wcp-editor" style={{ height: '100vh' }}>
              <h1 className="contents content-margin ">Formulaire Dessin</h1>
              <hr/>

            </div>
        : ''
        }

      </div>

    )
  }
  closest(el, selector) {
    var matchesFn;
    // find vendor prefix
    ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
        if (typeof document.body[fn] == 'function') {
            matchesFn = fn;
            return true;
        }
        return false;
    })

    var parent;

    // traverse parents
    while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
            return parent;
        }
        el = parent;
    }

    return null;
}

}

export default DrawEmplacement;
