import StepsMainFunctions from "./StepsMainFunctions";
import $ from "jquery";

const steps = [
    {
        id: 'gestion-concessionaire-1',
        scrollTo: true,
        attachTo: { element: '#none', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
        ],
        beforeShowPromise: function() {
            return StepsMainFunctions.PromiseListe('concessions', "#concessions-path", this)
        },
        when: {
            show() {
                if(window.location.pathname === '/aide') {
                    StepsMainFunctions.observer('concession', '#concessions-path', this)
                } else {
                    if (this.tour && this.tour.steps) {
                        this.tour.steps.splice(1, 2)
                    }
                    const concessionnairesButton = $('#none')
                    if (concessionnairesButton.length > 0) {
                        StepsMainFunctions.showRewrite(this)
                        StepsMainFunctions.watchDivWithHeight('content-attribution-emplacement', '#none', this)
                    } else {
                        this.updateStepOptions({text: 'Ce type d\'emplacement n\'a pas de concessionnaires. Vous pouvez relancer ce tutoriel à tout moment sur un autre type d\'emplacement.'})
                    }
                }
            },
            hide() {
                $('#concessions-path').off('click')
            },
            cancel() {
                $('#concessions-path').off('click')
            },
            destroy() {
                $('#concessions-path').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur ce bouton pour accéder à la partie gestion des concessionnaires.</p>`,
    },
    {
        id: 'fiche-toLocation-0',
        attachTo: {element: '.contents.content-margin', on: 'top'},
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
        ],
        when: {
            show() {
                StepsMainFunctions.showRewrite(this)
                StepsMainFunctions.locationToEmplacement('.edit-tbl.concession', 'ajout-defunt-fenetre', this)
            },
            hide() {
                $('.edit-tbl.concession').off('click')
            },
            cancel() {
                $('.edit-tbl.concession').off('click')
            },
            destroy() {
                $('.edit-tbl.concession').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur <span class="purple">"consulter"</span> pour la <span class="purple">concession</span> où vous souhaitez gérer les informations des concessionnaires.</p>`,
    },
    {
        id: 'gestion-concessionaire-1',
        attachTo: { element: '#none', on: 'top' },
        scrollTo: true,
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
        ],
        when: {
            show() {
                const concessionnairesButton = $('#none')
                if (concessionnairesButton.length > 0) {
                    StepsMainFunctions.showRewrite(this)
                    StepsMainFunctions.watchDivWithHeight('content-attribution-emplacement', '#none', this)
                } else {
                    this.updateStepOptions({text: 'Ce type d\'emplacement n\'a pas de concessionnaires. Vous pouvez relancer ce tutoriel à tout moment sur un autre type d\'emplacement.'})
                }
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur ce bouton pour accéder à la partie gestion des concessionnaires.</p>`,
    },
    {
        id: 'gestion-concessionaire-2',
        attachTo: { element: '#content-attribution-emplacement', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette fenêtre vous permet d'accéder directement à la partie <span class="purple">concessionnaires</span> de votre formulaire d'attribution d'emplacement</span>.</p>`,
    },
    {
        id: 'gestion-concessionaire-3',
        attachTo: { element: '#attribution_emplacement_contacts_0', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Ce formulaire de contact concerne le <span class="purple">concessionnaire principal</span>. Si le concessionnaire est <span class="purple">inconnu</span>, vous pouvez laisser le formulaire vide.</p>`,
    },
    {
        id: 'gestion-concessionaire-4',
        scrollTo: true,
        attachTo: { element: '.ajout-co-concessionnaire', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Si vous souhaitez ajouter un ou des <span class="purple">co-concessionnaire(s)</span>, choisissez <span class="purple">oui</span> pour "Le concessionnaire
                souhaite ajouter un co-concessionnaire". Cela ajoutera un <span class="purple">nouveau formulaire</span> de contact que vous pourrez <span class="purple">remplir</span>.
                Vous pouvez faire de même pour le(s) contact(s) de secours.</p>`,
    },
    {
        id: 'gestion-concessionaire-5',
        scrollTo: true,
        attachTo: { element: '#location-form-valider', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Terminer',
                type: 'complete'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                document.getElementById('location-form-valider').addEventListener('click', () => $this.tour.next())
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Une fois tout vos contacts renseignés, vous pouvez directement <span class="purple">valider</span> vos modifications 
                sans passer par les autres étapes du formulaire d'attribution d'emplacement.</p>`,
    }
]

export default steps;