import React, { Component } from 'react';

class DateTools extends Component{

    static formatDate(date) {
        if (!date) return 'Inconnu';
        let date_format = new Date(date.substr(0, 10))
        return ('0' + date_format.getDate()).slice(-2) + '/'
            + ('0' + (date_format.getMonth()+1)).slice(-2) + '/'
            + date_format.getFullYear()
    }

   static verifDateFin (date){
        let statut = 'En cours'
        if (date) {
            const d = new Date(date.substr(0, 10))
            const dateMaintenant = new Date()
            const echeanceRenouvellement = new Date(date.substr(0, 10))
            echeanceRenouvellement.setFullYear(echeanceRenouvellement.getFullYear() + 2)
            if(d < dateMaintenant && dateMaintenant < echeanceRenouvellement) {
                statut = 'A renouveler'
            } else if (d < dateMaintenant) {
                statut = 'Échue'
            }
        } else {
            statut = 'Non defini'
        }
        return statut
    }

    static compareDate(date1, date2, year, month) {
        let firstDate = new Date(date1.substr(0, 10))
        let secondDate = new Date(date2.substr(0, 10))
        if(year) {
            firstDate.setFullYear(firstDate.getFullYear() + year)
        }
        if(month) {
            firstDate.setMonth(firstDate.getMonth() + month)
        }
        return firstDate <= secondDate
    }

    static addMonthOrYear(date, year, month) {
        let dateAModifier = new Date(date.substr(0, 10))
        if(month) {
           dateAModifier.setMonth(dateAModifier.getMonth() + month)
        }
        if(year) {
            dateAModifier.setFullYear(dateAModifier.getFullYear() + year)
        }
        return this.formatDateForInput(dateAModifier)
    }

    static formatDateForInput(date) {
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        return date.getFullYear()+"-"+(month)+"-"+(day)
    }

    static compareDateWithNow(date) {
        const dateToCheck = new Date(date)
        const now = new Date()
        return dateToCheck < now
    }

    static getDateDefunt = (naissance, deces, dateNaissanceIncomplete) => {
        let anneeMort = deces !== undefined ? deces['year'] : '';
        let anneeNaissance;
        if (!naissance  && dateNaissanceIncomplete) {
            anneeNaissance =  dateNaissanceIncomplete['year']
        } else if (naissance) {
            anneeNaissance = new Date(naissance.substr(0, 10)).getFullYear()
        }

        if (anneeNaissance && deces && anneeMort !== '' ) {
            return '( ' + anneeNaissance + ' - ' + anneeMort + ' )'
        } else if (anneeNaissance) {
            return '( ' + anneeNaissance + ' - inconnue )'
        } else if (anneeMort) {
            return '( inconnue - ' + anneeMort + ' )'
        } else {
            return '( dates inconnues ) '
        }
    }
}

export default DateTools;