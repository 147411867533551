import $ from "jquery";
import {Component} from "react";

class StepsMainFunctions extends Component {

    static showRewrite = ($this) => {
        const currentStepElement = $this.el;
        const header = currentStepElement.querySelector('.shepherd-header');
        const progress = document.createElement('span');
        progress.style['margin-right'] = '15px';
        progress.innerText = `ETAPE ${$this.tour.steps.indexOf($this.tour.currentStep) + 1}/${$this.tour.steps.length}`;
        header.insertBefore(progress, currentStepElement.querySelector('.shepherd-cancel-icon'));
    }

    static emptyForm = () => {
        $('.ligne').removeClass('fait')
        $('.jalon.s1').addClass('fait')
        $('.modal-step-content').hide()
        $('.etape1').show()
        $('.btn-navigation').hide()
        $('.suivant').show()
        $('.empty-required').removeClass('empty-required')
        $('#error').text('')
    }

    static ChangePanel(panelId, linkId) {
        document.querySelectorAll('.nav-item').forEach((e) => {
            e.classList.remove('active')
        })
        document.getElementById(linkId).classList.add('active')
        document.querySelectorAll('.tab-pane').forEach((e) => {
            e.classList.remove('active', 'show')
        })
        document.getElementById(panelId).closest('.tab-pane').classList.add('active','show')
    }

    static PromiseListe(liste, element, $this) {
        return new Promise(function(resolve) {
            setTimeout( function () {
                if(window.location.pathname === '/aide') {
                    $this.text = `<p>Cliquez dès maintenant sur <span class="purple">"` + liste[0].toUpperCase() + liste.slice(1) + `"</span> pour accéder à la liste des <span class="purple">` + liste + `</span>.</p>`
                    $this.attachTo = { element: element, on: 'right' }
                    $this.buttons.splice(2,1)
                    $this.buttons[0].classes = 'shepherd-button-secondary large-margin-button'
                }
                resolve();
            }, 300);
        });
    }

    static observer(liste, onClick, $this) {
        const observer = new MutationObserver((mutations, mutationInstance) => {
            const someDiv = document.querySelectorAll('.edit-tbl.' + liste)
            if (someDiv.length > 0) {
                $this.tour.next()
                mutationInstance.disconnect();
            }
        });
        $(onClick).off('click').on('click', function () {
            observer.observe(document, {
                childList: true,
                subtree: true
            });
        })
    }

    static locationToEmplacement(onClick, toWatch, $this) {
        const observer = new MutationObserver((mutations, mutationInstance) => {
            const someDiv = document.getElementById(toWatch)
            if (someDiv) {
                $this.tour.next()
                $(onClick).off('click')
                mutationInstance.disconnect();
            }
        });
        $(onClick).off('click').on('click', function () {
            observer.observe(document, {
                childList: true,
                subtree: true
            });
        })
    }

    static watchDivWithHeight(divId, onClick, $this) {
        $(onClick).off('click').on('click',function (){
            isVisible()
            function isVisible() {
                const heigthDiv = document.getElementById(divId).offsetHeight
                if (heigthDiv > 0) {
                    $this.tour.next()
                } else {
                    setTimeout(isVisible, 450)
                }
            }
        })
    }

    static isVisibleSidePanel(tour) {
        $('#link-commune').off('click').on('click', function () {
            isVisible()
            function isVisible() {
                const widthDiv = document.getElementById('sidePanelCompte').offsetWidth
                if (widthDiv > 0) {
                    tour.next()
                    $('#link-commune').off('click')
                } else {
                    setTimeout(isVisible, 450)
                }
            }
        })
    }
}

export default StepsMainFunctions;