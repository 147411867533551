import React, {Component} from "react";
import $                  from "jquery";
import axios              from "../../../helpers/axios";
import dlDocProcedure     from "../../../assets/images/dl-documents-procedures.svg";
import redBellIcon        from "../../../assets/images/red-bell-icon.svg";
import infoWhiteIcon      from "../../../assets/images/info-icon-white.svg";
import Tools              from "../../../components/utils/Tools";
import {Link}             from "react-router-dom";
import HandleDownload     from "../../../components/utils/HandleDownload"

class RepriseNonRenouv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emplacementId: this.props.match.params.emplacementId,
            form: [],
            etape: 1,
            emplacement: {},
            lastLocation: {},
            reprises: [],
            lastRepriseCall: 0,
            lastEtapeCall: 0,
            textEtape1: 'Vous démarrez ici la procédure de reprise pour non renouvellement.'
        }
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + '/emplacements/' + this.state.emplacementId)
            .then ((response) => {
                this.setState({ emplacement: response.data})
                const location = this.state.emplacement.lastLocation
                if (location) {
                    axios.get(process.env.REACT_APP_API_URL + '/locations/' + location.replace(/\D/g, "")+"?groups[]=full").then((response) => {
                        this.setState({lastLocation: response.data})
                        if(response.data.dateFin) {
                            let echeanceRenouvellement = new Date(response.data.dateFin)
                            echeanceRenouvellement.setFullYear(echeanceRenouvellement.getFullYear() + 2)
                            if (new Date(response.data.dateFin) < new Date() && new Date() < echeanceRenouvellement) {
                                this.setState({textEtape1: 'Reprise pour non renouvellement impossible car la concession n\'est pas échue depuis plus de deux ans.'})
                                $('.step-btn').remove()
                            } else if (new Date(response.data.dateFin) > new Date()) {
                                this.setState({textEtape1: 'Reprise pour non renouvellement impossible car la concession est en cours de validité.'})
                                $('.step-btn').remove()
                            }
                        }
                    })
                } else {
                    this.setState({textEtape1: 'Reprise pour non renouvellement impossible car l\'emplacement est libre.'})
                    $('.step-btn').remove()
                }
                const nbEtape = this.state.emplacement.occupants.length > 0 ? 4 : 3
                let procedures = this.state.emplacement.procedureRepriseNR
                if (procedures !== undefined && procedures.length > 0) {
                    axios.all(procedures.map(p => axios.get(process.env.REACT_APP_API_URL + '/procedure_reprise_non_renouvs/' + p.replace(/\D/g, ""))))
                        .then(axios.spread((...res) => {
                            let array = {};
                            for (let key in res){
                                array[res[key].data.id] = res[key].data
                            }
                            this.setState({reprises: array})
                            let lastReprise = this.state.emplacement.lastprocedureRepriseNR
                            if (lastReprise  != null) {
                                lastReprise  = lastReprise .replace(/\D/g, "")
                                this.setState({lastReprise  : this.state.reprises[lastReprise]})
                                if (this.state.lastReprise.etat <= nbEtape && (this.state.lastReprise.dateFin === null || this.state.lastReprise.dateFin === undefined )) {
                                    this.setState({etape : this.state.lastReprise.etat + 1})
                                    Tools.navigationPopup('Reprise pour non renouvellement', this.state.lastReprise.etat + 1 === nbEtape)
                                }
                            } else {
                                Tools.navigationPopup('Reprise pour non renouvellement', false)
                            }
                        }));
                } else {
                    Tools.navigationPopup('Reprise pour non renouvellement', false)
                }
                Tools.updateNameUploadFile()
            })
    }

    getForm = (e) => {
        if (this.state.lastRepriseCall !== $(e.target).attr('data-reprise') || this.state.lastEtapeCall !== e.target.id) {

            this.setState({lastRepriseCall : $(e.target).attr('data-reprise'), lastEtapeCall : e.target.id})

            let promise = process.env.REACT_APP_API_URL + '/repriseNonRenouv/form/' + this.state.emplacement.id + '/';
            if ($(e.target).attr('data-reprise') !== undefined) {
                promise += e.target.id + '/' + $(e.target).attr('data-reprise')
            } else {
                promise += this.state.etape
            }

            axios.get(promise)
                .then((response) => {
                    this.setState({form: response.data})

                    $('.modal.reprise').modal('show');

                    if ($(e.target).attr('data-reprise') === undefined) {
                        this.updateField();
                    } else {
                        $('.suivant').click(()=>{
                            $('.suivant').hide()
                        })
                        $('.precedent').click(()=>{
                            $('.precedent').hide()
                            $('.suivant').show()
                        })
                    }

                    $(function () {
                        $('[data-toggle="popover"]').popover()
                    })

                    if(parseInt(e.target.id) === 2){
                        this.updateFieldEtape2()
                    }
                })
        }
    }

    updateField = () => {
        $('[type="radio"].table-checkbox').change(function () {
            $('[type="radio"].table-checkbox:not(:checked)').parent().removeClass('select-row');
        })

        if(this.state.etape === 2) {
            document.getElementById('reprise_non_renouvellement2_destinationDefunt').addEventListener('change',  () => {
                this.updateFieldEtape2()
            })
        }
    }

    updateFieldEtape2 = () => {
        if (document.getElementById('reprise_non_renouvellement2_destinationDefunt').value === 'Jardin du souvenir') {
            document.getElementById('jardin_field').classList.remove('display-none')
            document.getElementById('ossuaire_field').classList.add('display-none')
        } else {
            document.getElementById('jardin_field').classList.add('display-none')
            document.getElementById('ossuaire_field').classList.remove('display-none')
        }
    }

    render() {
        require('../../../assets/css/procedures.css')
        require('../../../assets/css/formPop-up.css')

        const nbEtape = this.state.emplacement.occupants ? this.state.emplacement.occupants.length > 0 ? 4 : 3 : 4
        const etiquettesEnCours = [];
        const etiquettesFinies = [];
        const etiquettesTexte = {
            1: {
                button: 'Démarrer',
                texte: this.state.textEtape1,
                titre: 'vérifications et arrêté',
                termineContent: 'Arrêté municipal le '
            },
            2 : {
                button: 'Démarrer',
                texte: 'Vous poursuivez ici la procédure de reprise pour non renouvellement',
                titre: 'destination défunts',
                termineContent : 'Défunts déplacés en '
            },
            3 : {
                button: 'Démarrer',
                texte: 'Vous poursuivez ici la procédure de reprise pour non renouvellement',
                titre: ' Remise en état',
                termineContent: 'Etape effectuée le '
            },
            4 : {
                button: 'Démarrer',
                texte: 'Confirmez ici la date de dispersion ou de mise en ossuaire des défunts ',
                titre: ' Confirmation',
                termineContent: 'Etape effectuée le '
            },
        }

        if (this.state.lastReprise !== undefined) {
            for (const procedure of Object.keys(this.state.reprises)) {
                const reprise = this.state.reprises[procedure]
                for (let index = 1; index <= reprise['etat']; index++) {
                    let document = index === 1 ? [reprise.arrete] : reprise.autorisation ? [reprise.autorisation] : null
                    // on verifie si l'étiquette a affiché n'est pas celle en cours et si une autre procedure a été commencé
                    if (reprise['id'] !== this.state.lastReprise.id || index !== this.state.etape || this.state.lastReprise.etat === nbEtape) {
                        let dataTarget = '#etape' + index;
                        let dateFin = new Date(reprise['etapes'][index]['date_fin']);
                        etiquettesFinies.push(
                            <div className="etape bg-white p-3 etape-finie">
                                <p className="soleilBold-fonts title purple">{etiquettesTexte[index]['titre']}</p>
                                <p className="soleilRegular-fonts purple etape-description first-letter-uppercase">
                                    { index !== 2 ?
                                        etiquettesTexte[index]['termineContent'] + ' ' + dateFin.toLocaleDateString()
                                        :
                                        reprise.destinationDefunt ? etiquettesTexte[index]['termineContent'] + ' ' + reprise.destinationDefunt  : 'Aucun défunt à déplacer'
                                    }</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    {
                                        (index === 1 || index === 2) && document ?
                                            <a className="dl-doc soleilBold-fonts d-flex cursor" onClick={() => HandleDownload(document)}>
                                                <img src={dlDocProcedure} className="img-fluid mr-2" width="20px" alt=""/>
                                                <p>Documents</p>
                                            </a>
                                        : ''
                                    }
                                    <button type="button" className="add-doc-btn soleilBold-fonts step-btn"
                                            data-toggle="modal" data-target={dataTarget} id={index} data-reprise={procedure}
                                            onClick={this.getForm}>Détails
                                    </button>
                                </div>
                            </div>
                        )
                    }
                }
            }
        }

        let i = this.state.etape
        let dataTarget = '#etape' + i
        etiquettesEnCours.push (
            <div className="etape reprise-nr bg-white p-3">
                <p className="soleilBold-fonts title purple etape-title">étape {i}/{nbEtape} :
                    {etiquettesTexte[i]['titre']}</p>
                <p className="soleilRegular-fonts purple etape-description">{etiquettesTexte[i]['texte']}</p>
                <button type="button" className="add-doc-btn soleilBold-fonts step-btn"
                        data-toggle="modal" data-target={dataTarget} id={i} onClick={this.getForm}>{etiquettesTexte[i]['button']}
                </button>
            </div>
        )

        return (
            <div className="contents content-margin">
                <div className="content-header px-3 px-lg-4 page2">
                    <span className="expand-btn">&#9776;</span>
                    <div className="d-flex align-items-center">
                        <div className="col-12 soleilBold-fonts">
                            <span className="title">REPRISE DE CONCESSION POUR NON RENOUVELLEMENT : </span>
                            <span className="text-white">Emplacement {this.state.emplacement.carre} {this.state.emplacement.allee}</span>
                        </div>
                    </div>
                </div>
                <div className="px-3 px-lg-4  ">
                    <div className="retour-concession d-flex align-items-center mt-3 ml-4 mb-3">
                        <img src={redBellIcon} className="mr-3" width="40px" alt=""/>
                        <Link to={{
                            pathname: "/fiche-concession/" + this.props.match.params.emplacementId,
                        }}  className="soleilBold-fonts purple m-0 pt-4">{this.state.lastLocation && this.state.lastLocation.champsSuppValeurs ? 'Famille ' + this.state.lastLocation.champsSuppValeurs.famille : ''} (retour à l'emplacement)</Link>
                    </div>
                    <div className="essential-cemetery-wrapper scroll">
                        <div className="container-fluid px-0 ">
                            <div className="col-lg-12 bg-white py-3">
                                <div className="info-security-content">
                                    <div className="d-lg-inline-flex align-items-center mb-3">
                                        <img src={infoWhiteIcon} alt=""
                                             className="img-fluid mr-2" width="25px"/>
                                        <p className="soleilBold-fonts mt-2 ">Rappels</p>
                                    </div>
                                    <p className="soleilBook-fonts">
                                        Attention, la reprise de concession n'est possible qu'après l'expiration d'un
                                        délai de 2 ans après la date d'échéance du contrat de concession et minimum
                                        5 ans après la dernière inhumation.
                                    </p>
                                </div>
                            </div>
                            <div className="etapes-procedures pt-3 d-flex justify-content-center">
                                <div className="etapes-wrapper">
                                    {etiquettesFinies}
                                    {etiquettesEnCours}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{__html:this.state.form}} className="formulaire" data-submit={process.env.REACT_APP_API_URL + '/repriseNonRenouv/form/' + this.state.emplacement.id + '/' + this.state.etape}/>
            </div>
        )
    }
}

export default RepriseNonRenouv;