import React, { Component } from 'react';
import editerIcon from '../../assets/images/edit-icon-purple.svg';
import pdfIconTbl from '../../assets/images/pdf-icon-tbl.svg';
import filterIconSubmenu from '../../assets/images/filter-icon-submenu.svg';
import {Link} from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import AjoutNouveau from "../../components/concession/AjoutNouveau";
import OrderBy from "../../components/utils/OrderBy";
import GetPDF from "../../components/utils/GetPDF";
import GetListePDF from "../../components/utils/GetListePDF";

class DefuntsListe extends Component {

    constructor(props) {
        super(props);
        this.paginationRef = React.createRef();
        this.state = {
            selected  : 0,
            occupants : [],
            total     : 0,
            orderBy   : null,
            sortBy    : null,
            selectedId: [],
            search    : '',
            typeExt   : 'pdf'
        }
    }

    handleCheck = (e) => {
        let parent     = e.target.parentElement.parentElement
        let selectedId = this.state.selectedId
        let number     = this.state.selected

        if (e.target.checked === true) {
            selectedId.push(parent.getAttribute('data-key'))
            number+=1
        } else {
            const index = selectedId.indexOf(parent.getAttribute('data-key'));
            if (index > -1) {
                selectedId.splice(index, 1);
                number-=1
            }
        }
        this.setState({
            selected  : number,
            selectedId: selectedId
        });
    }

    formatDate = (date) => {
        let date_format = new Date(date)
        return ('0' + date_format.getDate()).slice(-2) + '/'
            + ('0' + (date_format.getMonth()+1)).slice(-2) + '/'
            + date_format.getFullYear()
    }

    updateState = (res) => {
        this.setState({
            occupants: res.data.data,
            total    : res.data.total,
        })
    }

    sort = (sortBy) => {
        if (this.state.sortBy === sortBy) {
            this.setState(prevState =>{
                return{
                    ...prevState,
                    orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
                }
            })
        } else {
            this.setState({
                sortBy : sortBy,
                orderBy: 'asc'
            })
        }
    }

    refreshPagination () {
        this.paginationRef.current.changePage(1)
    }
    render() {
        let tableRows = this.state.occupants.map((defunt) => {
            return (
                    <tr key={defunt.id} data-key={defunt.id} className={this.state.selectedId.includes(defunt.id.toString()) ? 'liste-border' : 'liste-border-none'}>
                        <td>
                            <input type="checkbox" className="table-checkbox" onChange={this.handleCheck} checked={this.state.selectedId.includes(defunt.id.toString())}/>
                            {defunt.emplacement.carre} {defunt.emplacement.allee}
                        </td>
                        <td className="black">
                            { defunt.prenom ? defunt.prenom : '-' }
                        </td>
                        <td className="black">
                            {defunt.nom ? defunt.nom : '-'}
                        </td>
                        <td className="black">-</td>
                        <td>
                            {
                                defunt.dateDeNaissance ?
                                this.formatDate(defunt.dateDeNaissance.substr(0, 10))
                                : defunt.dateNaissanceIncomplete ?
                                    <p>
                                        {defunt.dateNaissanceIncomplete && defunt.dateNaissanceIncomplete.day ? ("0" + defunt.dateNaissanceIncomplete.day).slice(-2)  : ' - '}/
                                        {defunt.dateNaissanceIncomplete && defunt.dateNaissanceIncomplete.month ? ("0" + defunt.dateNaissanceIncomplete.month).slice(-2)   : ' - '}/
                                        {defunt.dateNaissanceIncomplete && defunt.dateNaissanceIncomplete.year ? defunt.dateNaissanceIncomplete.year  : ' -'}
                                    </p>
                                        :''
                            }
                        </td>
                        <td>
                            <span className="tbl bk-purple d-flex align-items-center">
                                <p className="dateDeces-liste">
                                    {defunt.dateDeces && defunt.dateDeces.day ? ("0" + defunt.dateDeces.day).slice(-2) : '- '}/
                                    {defunt.dateDeces && defunt.dateDeces.month ? ("0" + defunt.dateDeces.month).slice(-2) : ' - '}/
                                    {defunt.dateDeces && defunt.dateDeces.year ? defunt.dateDeces.year  : ' -'}
                                </p>
                            </span>
                        </td>
                        <td>{defunt.dateInhumCrema ? defunt.dateInhumCrema : '-'}</td>
                        <td>{defunt.dateExhumation ? defunt.dateExhumation : '-'}</td>
                        <td>{defunt.specificites ? defunt.specificites : '-'}</td>
                        <td>
                            <Link to={{
                                pathname: "/fiche-concession/" + defunt.emplacement.id,
                            }} className="edit-tbl">
                                <img src={editerIcon} alt="" className="img-fluid" />
                                éditer
                            </Link>
                        </td>
                        <td>
                            <p className="exporter-tbl cursor" onClick={() => GetPDF('occupant', defunt.id)}>
                                <img src={pdfIconTbl} alt="" className="img-fluid" />
                                exporter
                            </p>
                        </td>
                    </tr>
            )
        })

        return (
            <div>
                <div className="mx-3">
                    <div className="table-responsive px-3 scroll listes-leftpanel">
                        <table className="table table-striped table-borderless">
                            <thead>
                            <tr>
                                <th className="sortStyle">
                                    Carré / Allée
                                    <span onClick={() => this.sort('allee')}>
                                        <OrderBy isClicked={this.state.sortBy === 'allee'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    Prénom
                                    <span onClick={() => this.sort('prenom')}>
                                        <OrderBy isClicked={this.state.sortBy === 'prenom'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    Nom
                                    <span onClick={() => this.sort('nom')}>
                                        <OrderBy isClicked={this.state.sortBy === 'nom'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">Nom jeune fille</th>
                                <th className="sortStyle">Naissance</th>
                                <th className="sortStyle">Décès</th>
                                <th className="sortStyle">Inhumation</th>
                                <th className="sortStyle">Exhumation</th>
                                <th className="sortStyle">Spécificités</th>
                                <th colSpan="2" className="sortStyle">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {tableRows}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="container-fluid py-3 px-lg-5">
                    <div className="row d-flex justify-content-between">
                        <AjoutNouveau etat="Occupé"/>
                        <Pagination
                            url         = '/contacts/occupants/'
                            ref         = {this.paginationRef}
                            eSpaceId    = {localStorage.getItem('espace_id')}
                            type        = {null}
                            sort        = {this.state.sortBy}
                            order       = {this.state.orderBy}
                            filters     = {this.props.filters}
                            updateState = {this.updateState}
                            search      = {this.state.search}
                        />
                        <div
                            className="list-button d-flex justify-content-center justify-content-sm-end align-items-center mt-4 mt-sm-0">
                            <div>
                                <input type="search" onKeyUp={(e) => {this.paginationRef.current.changePage(1); this.setState((prevState) => {
                                    return {
                                        ...prevState,
                                        search: e.target.value
                                    }
                                })}} placeholder="Rechercher dans cette liste" className={"form-control"} />
                            </div>
                            <div>
                                <button type="button" className="export-locations-list-btn d-flex cursor" onClick={() => GetListePDF('occupant')}>
                                    <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                    <span className="soleilBold-fonts">EXPORTER LISTE <br/> DÉFUNTS</span>
                                </button>
                                <button type="button" className="export-locations-list-btn d-flex cursor" onClick={() => GetListePDF('occupant', null, 'pdf')}>
                                    <img src={filterIconSubmenu} alt="" className="img-fluid"/>
                                    <span className="soleilBold-fonts">EXPORTER EXCEL <br/> DÉFUNTS</span>
                                </button>

                            </div>
                            <div>
                            <select name="" className="form-control mb-2" id="" value={this.state.typeExt} onChange={(e) => {
                                this.state.typeExt = e.target.value
                            }}>
                                <option value="pdf">PDF</option>
                                <option value="csv">Excel</option>
                            </select>
                            <button type="button" className="listing-complete-btn soleilBold-fonts cursor" onClick={() => GetListePDF('occupant',  this.state.selectedId, this.state.typeExt)}>
                                Exporter la sélection
                                <span className="slt-nb">{ this.state.selected }</span></button>
                        </div>
                            <button type="button" className="listing-complete-btn soleilBold-fonts">Liste filtrée ( 0
                                )
                            </button>
                            <button type="button" className="listing-incomplete-btn soleilBold-fonts">Liste complète (
                                {this.state.total} )
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DefuntsListe;