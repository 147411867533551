import React, {Component} from 'react';
import './styles.scss'
import { connect } from "react-redux";
import { fetchApi } from "../../actions";
import Select from 'react-select';
import {Redirect} from "react-router-dom";
import axios from '../../helpers/axios'
import workspace from "../../helpers/workspace";
import {logoStyle} from "../../utils/logoStyle";

class SwitchEspace extends Component {
    state = {
        espaces: {}
    }

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        document.getElementById("root").className = document.getElementById("root").className.replace("switch-page", "")
    }

    async componentDidMount() {
        this.changeWorkspace = workspace.changeWorkspace.bind(this);
        document.getElementById("root").className = document.getElementById("root").className + " switch-page"
        const results = await axios('/users/espaces');
        if (Object.keys(results.data).length === 1 && results.data[0].espaces.length === 1) {
            this.changeWorkspace(this.props, results.data[0].espaces[0].id + "--" + results.data[0].societe.id, false)
        } else {
            this.setState({...this.props, espaces: results.data})
        }
    }
    
    render() {
        const societe = this.state.espaces

        return (
            <div>
                { this.props.espace && this.props.societe && this.props.espace.id && this.props.societe.id
                    ? <Redirect to={"/emplacement"} />
                 : ''
                    }
                <div className="container-fluid">
                    <div className="row mt-5 mt-md-0">
                        <div className="col-md-3 mx-auto">

                            <h1 className="text-center mb-5 text-primary">Choisissez un cimetière {
                                (this.props.espace && this.props.espace)
                                    ? this.props.espace.nom
                                    : ''
                            }</h1>
                            <Select
                                onChange={(e) => this.changeWorkspace(this.props, e.value, false)}
                                        defaultValue="${this.props.espace.data.id}--${this.props.societe.data.id}"
                                        placeholder="Choisissez un cimetière"
                                        label="Choisissez un espace"
                                        options={Object.values(societe).map(societe => {
                                            return {
                                                label: societe.societe.nom,
                                                logo: societe.societe.logo,
                                                options: societe.espaces.map(espace => {
                                                    return {
                                                        'label': espace.nom,
                                                        'value': espace.id + '--' + societe.societe.id,
                                                        'logo' : espace.logo
                                                    }
                                                })
                                            }
                                        })}
                                        styles={logoStyle}
                                    />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ data = [], isLoadingData = false, espace = {}, societe = {}}) => ({
    data,
    isLoadingData,
    espace,
    societe
});

export default connect(
    mapStateToProps,
    {
        fetchApi
    }
)(SwitchEspace);
