const steps = [
    {
        id: 'menu-etape1',
        attachTo: { element: '#sidebar-title', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Le nom <span class="purple">du cimetière</span> que vous consultez est affiché ici.</p>`,
    },
    {
        id: 'menu-etape2',
        attachTo: { element: '.search-input', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez effectuer une <span class="purple">recherche</span> à n'importe quel moment via cette section du menu parmis les emplacements. Elles peuvent concerner
              les concessions, les défunts, les concessionnaires, les assistances et les travaux.</p>`,
    },
    {
        id: 'menu-etape3',
        attachTo: { element: '#left-menu-item', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Le menu vous permet d'accéder aux <span class="purple">différentes sections</span> et aux données les concernant. L'<span class="purple">élément surligné</span> en <span class="red">rose</span>
            est la page sur laquelle vous vous trouvez.</p>`
    },
    {
        id: 'menu-etape4',
        attachTo: { element: '#ressources-item-head', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Nous mettons à votre disposition dans cette liste déroulante des <span class="purple">ressources</span> qui peuvent vous être utiles pour la gestion de votre cimetière. Ces ressources regroupe de
            la <span class="purple">réglementation</span> et des documents d'<span class="purple">assistances</span> pour les différentes procédures.</p>`
    },
    {
        id: 'menu-etape5',
        attachTo: { element: '#link-to-notes', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette icône vous permet d'accéder à vos <span class="purple">notes</span>.</p>`
    },
    {
        id: 'menu-etape6',
        attachTo: { element: '#link-to-calendar', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette icône vous permet d'accéder à votre <span class="purple">calendrier</span>.</p>`,
    },
    {
        id: 'menu-etape7',
        attachTo: { element: '#open-notifs', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette icône vous permet d'ouvrir une fenêtre latérale listant vos dernières <span class="purple">notifications</span>. 
             Le <span class="red">numéro</span> qui se trouve au-dessus vous indique le <span class="purple">nombre de notifications non vues</span>.<p>`
    },
    {
        id: 'menu-etape8',
        attachTo: { element: '#link-commune', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>En cliquant sur <span class="purple">"Commune"</span> vous pourrez accéder à la <span class="purple">gestion de votre compte</span> et au <span class="purple">centre d'aide</span>.</p>`,
    },
    {
        id: 'menu-etape9',
        attachTo: { element: '.help-icon', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Terminer',
                type: 'complete'
            }
        ],
        beforeShowPromise: function() {
            return new Promise(function(resolve) {
                setTimeout( function () {
                    if(document.getElementById('sidePanelCompte').offsetWidth > 0) {
                        document.getElementById("sidePanelCompte").style.width = "0";
                        document.querySelector('.overlay').style.visibility = "hidden";
                    }
                    resolve();
                }, 300);
            });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette icône vous permet d'accéder depuis n'importe quelle page à l'<span class="purple">assistance</span>. Vous pouvez retrouver
              les <span class="purple">tutoriels</span> disponibles sur la page que vous consultez et/ou <span class="purple">effectuer une recherche</span> dans le lexique. N'hésitez pas à consulter l'<span class="purple">assistance</span> dès maintenant.</p>`
    },
];

export default steps;