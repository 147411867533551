import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { deleteDocument, getDocument, personnaliseDocument} from '../../actions/documents';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import CompareArrowsIcon  from '@material-ui/icons/CompareArrows';
import CreateIcon  from '@material-ui/icons/Create';
import DeleteForeverSharp from '@material-ui/icons/DeleteForeverSharp';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';

const Document = ({currentdocument: { id, slug, nom, description, content, actif, type, prototype },
    deleteDocument,
    match}) => {
        useEffect(() => {
            getDocument(match.params.id);
        }, []);

        const BtnDeleteDocument = async (id) => {
            await deleteDocument(id);
        };

        const DuplicateDocument = (id) => {
            personnaliseDocument(id)
        }

    return (
        <Fragment>
            <tr>
               <th scope="row">{slug}</th>
                <td>{nom}</td>
                <td>{description}</td>
                <td>{type}</td>
                <td>{actif ? 'Actif' : 'Désactivé'}</td>
                <td>
                    {!prototype ?
                        <Link class="mx-1 btn btn-danger" onClick={() => {
                            if (window.confirm('Supprimer ?')) BtnDeleteDocument(id)
                        }}>
                            <DeleteForeverSharp/>
                        </Link>
                        :
                        <Link class="mx-1 btn btn-info" onClick={() => {
                            if (window.confirm('Etes vous sûr de vouloir personnaliser ce document ?')) BtnDeleteDocument()
                        }}>
                            <ControlPointDuplicateIcon/>
                        </Link>
                    }
                    <Link  class="mx-1 btn btn-primary"  to={`edit/document/${id}`}>
                        <CreateIcon />
                    </Link>
                    {/* <Link class="mx-1 btn btn-primary" to={`edit/document/${id}#hook`}>
                        <CompareArrowsIcon />
                    </Link> */}
                </td>

            </tr>
        </Fragment>
    );
};

Document.propTypes = {
    deleteDocument: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(
    connect(null, {
        deleteDocument
    })(Document)
);
