import $ from 'jquery';
import StepsMainFunctions from "./StepsMainFunctions";


const watchFormModal = ($this) => {
    StepsMainFunctions.emptyForm()
    $('.editer-location-btn').off('click').on('click', function () {
        isVisible()
        function isVisible() {
            const heigthDiv = document.getElementById('content-attribution-emplacement').offsetHeight
            if (heigthDiv > 0) {
                $this.tour.next()
            } else {
                setTimeout(isVisible, 450)
            }
        }
    })

    document.getElementById('attribution_emplacement_type').addEventListener('change', function (e) {
        if (e.target.value === 'Concession') {
            $this.tour.next()
        }
    })
}

const steps = [
    {
        id: 'creer-concession-1',
        attachTo: { element: '.editer-location-btn', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
        ],
        beforeShowPromise: function() {
            return StepsMainFunctions.PromiseListe('emplacements', "#emplacements-path", this)
        },
        when: {
            show() {
                const $this = this
                if(window.location.pathname === '/aide') {
                    StepsMainFunctions.observer('emplacement', '#emplacements-path', this)
                } else {
                    if (this.tour && this.tour.steps) {
                        this.tour.steps.splice(1, 2)
                    }
                    watchFormModal(this)
                }
                StepsMainFunctions.showRewrite($this)
            },
            hide() {
                $('#emplacements-path').off('click')
            },
            cancel() {
                $('#emplacements-path').off('click')
            },
            destroy() {
                $('#emplacements-path').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur<span class="purple"> éditer location </span>ouvrir le formulaire d'attribution de l'emplacement.</p>`,
    },
    {
        id: 'fiche-toLocation-0',
        attachTo: {element: '.contents.content-margin', on: 'top'},
        buttons: [
            {
                classes: 'shepherd-button-secondary ',
                text: 'Arrêter',
                type: 'cancel'
            },
        ],
        when: {
            show() {
                StepsMainFunctions.showRewrite(this)
                StepsMainFunctions.locationToEmplacement('.edit-tbl.emplacement.estlibre', 'attribution_emplacement_type', this)
                $('.edit-tbl.emplacement:not(.estlibre)').on('click', (e) => e.preventDefault())
            },
            hide() {
                $('.edit-tbl.emplacement').off('click')
            },
            cancel() {
                $('.edit-tbl.emplacement').off('click')
            },
            destroy() {
                $('.edit-tbl.emplacement').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur <span class="purple">"consulter"</span> sur l'<span class="purple">emplacement libre</span> où vous souhaitez créer une concession.</p>`,
    },
    {
        id: 'creer-concession-1',
        attachTo: {element: '.editer-location-btn', on: 'bottom'},
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
        ],
        when: {
            show() {
                const $this = this
                watchFormModal(this)
                StepsMainFunctions.showRewrite($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur<span class="purple"> éditer location </span>ouvrir le formulaire d'attribution de l'emplacement.</p>`,
    },
    {
        id: 'creer-concession-2',
        attachTo: { element: '#content-attribution-emplacement', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette fenêtre vous permet de <span class="purple">créer</span> et <span class="purple">éditer</span> la <span class="purple">location de votre emplacement</span>.</p>`,
    },
    {
        id: 'creer-concession-3',
        attachTo: { element: '#emplacement-rappel', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Retrouvez ici un <span class="purple">rappel</span> de l'emplacement que vous allez attribuer.</p>`,
    },
    {
        id: 'creer-concession-4',
        attachTo: { element: '#type-emplacement-wrapper', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Sélectionnez ici le <span class="purple">type d'emplacement</span> que vous allez créer. Choisissez dès maintenant <span class="purple">Concession</span> pour passer à la suite du tutoriel.</p>`,
    },
    {
        id: 'creer-concession-5',
        attachTo: { element: '#location-progression', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette <span class="purple">barre de progression</span> vous indique le nombre d'étapes que contient le formulaire.</p>`,
    },
    {
        id: 'creer-concession-6',
        attachTo: { element: '#location-progression .jalon.s1', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Le dernier <span class="purple">point coloré</span> vous indique l'étape que vous êtes en train d'effectuer.</p>`,
    },
    {
        id: 'creer-concession-7',
        attachTo: { element: '#concession-surface', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                $('#attribution_emplacement_surface').off('change').on('change', function (e) {
                    if(e.target.value !== '11') {
                        $this.tour.next()
                    } else {
                        $('#attribution_emplacement_surfaceAutre').off('focusout').on('focusout', function (){
                            $this.tour.next()
                        })
                    }
                })
            },
            cancel() {
                $('#attribution_emplacement_surface').off('change')
            },
            destroy() {
                $('#attribution_emplacement_surface').off('change')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Renseignez la <span class="purple">surface</span> pour compléter cette étape. Elle doit être <span class="purple">obligatoirement</span> renseignée pour les concessions pour bonne gestion des procédures juridiques.</p>`,
    },
    {
        id: 'creer-concession-8',
        attachTo: { element: '#location-navigation', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                $('#location-form-suivant').off('click').on('click', function() {
                    $this.tour.next()
                })
            },
            cancel() {
                $('#location-form-suivant').off('click')
            },
            destroy() {
                $('#location-form-suivant').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Les boutons en bas de fenêtre vous permettre de <span class="purple">naviguer</span> entre les étapes ou de quitter le formulaire en appuyant sur "Annuler". Cliquer sur <span class="purple">"Suivant"</span> pour passer à l'étape suivante.</p>`,
    },
    {
        id: 'creer-concession-9',
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Terminer',
                type: 'complete'
            }
        ],
        show() {
            const $this = this
            StepsMainFunctions.showRewrite($this)
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez maintenant compléter le reste du formulaire en suivant les étapes. Vous pouvez refaire ce tutoriel à tout moment</p>`,
    },
]

export default steps;