import React, {Component} from "react";
import btnPlusIcon from "../../assets/images/btn-plus-icon.svg";
import DateTools from "../../components/utils/DateTools";
import axios from "axios";
import $ from "jquery";
import euroIcon from "../../assets/images/euro-svg.svg";
import {Button, Modal} from "react-bootstrap";

class Tarifs extends Component {

    constructor(props) {
        super(props);
        this.state= {
            isClosed                   : true,
            typeEmplacement            : 'Concession',
            tarifAModifier             : null,
            id                         : '',
            tarif                      : '',
            tarifRenouv                : '',
            duree                      : '',
            surface                    : '',
            parMetreCarre              : false,
            autreDuree                 : '',
            dateApplication            : '',
            tarifs                     : [],
            archives                   : [],
            inputPrestationsAttribution: [],
            inputPrestationsRenouv     : [],
            isVisibleAchives           : false,
            show                       : false,
            showModalModif             : false
        }
        this.ajouterTarif = this.ajouterTarif.bind(this)
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + '/infosCompte/cimetiere/' + localStorage.getItem('espace_id')).then((res) => {
            this.setState({
                tarifs : res.data.actifs,
                archives : res.data.archives
            })
        })
    }

    handleChange = (event)  =>{
        let name = event.target.name
        this.setState({[name] : event.target.value});
    }

    handleChandeTypeEmplacement = (event) => {
        this.affichageSurface(event.target.value)
        this.setState({typeEmplacement : event.target.value});
    }

    affichageSurface(value) {
        if (value!== "Concession") {
            document.getElementById('surface-wrapper').classList.add('display-none')
        } else {
            document.getElementById('surface-wrapper').classList.remove('display-none')
        }
    }

    async ajouterTarif(tarif) {
        if (this.state.isClosed === true) {
            await this.setState({isClosed:false})
            $('.essential-cemetery-wrapper').animate({ scrollTop: $('#ajoutTarif').offset().top
            }, 'slow');
        }

        if(tarif) {
            await this.updateState(tarif)
            this.affichageSurface(tarif.typeEmplacement)
        } else {
            this.viderFormulaire()
        }
    }

    updateState (tarif) {
        const toRemovePrestationsAttribution = document.querySelectorAll(".new");
        if(toRemovePrestationsAttribution) {
            toRemovePrestationsAttribution.forEach((e) => {
                e.remove()
            })
        }
        let dateApplication = new Date(tarif.dateApplication)
        this.setState({
            id : tarif.id,
            typeEmplacement : tarif.typeEmplacement,
            tarifRenouv : tarif.tarifRenouv ? tarif.tarifRenouv : '',
            tarif: tarif.tarifFixe ? tarif.tarifFixe : '',
            duree: tarif.perpetuelle ? 'perpetuelle' : document.querySelector("[name='duree'] option[value='" + tarif.duree + "']") ?  tarif.duree : '',
            autreDuree: document.querySelector("[name='duree'] option[value='" + tarif.duree + "']") ? '' : tarif.duree,
            dateApplication: dateApplication.getFullYear() + '-' + ('0' + (dateApplication.getMonth()+1)).slice(-2) + '-' + ('0' + dateApplication.getDate()).slice(-2),
            surface: tarif.surface,
            parMetreCarre:  tarif.parMetreCarre,
            tarifAModifier: tarif,
        })
        this.getInputPrestationsExistantes(tarif.prestationsAttribution, "attribution")
        this.getInputPrestationsExistantes(tarif.prestationsRenouv, "renouv")
    }

    viderFormulaire() {
        this.setState({
            id : '',
            typeEmplacement : 'Concession',
            tarif: '',
            tarifRenouv: '',
            duree: '',
            autreDuree: '',
            dateApplication: '',
            surface: '',
            parMetreCarre: false,
            inputPrestationsAttribution : [],
            inputPrestationsRenouv: [],
            tarifAModifier: null,
        })
        const fieldprestaAttribution = document.getElementById('prestation_attribution_0 input')
        const fieldprestaRenouv= document.getElementById('prestation_renouv_0 input')
        if(fieldprestaAttribution) {
            $('#prestation_attribution_0 input').val('')
        } else {
            this.addEmptyPrestationField('attribution')
        }
        if(fieldprestaRenouv) {
            $('#prestation_renouv_0 input').val('')
        } else {
            this.addEmptyPrestationField('renouv')
        }
    }

    async supprimerTarif (tarif) {
        let id = tarif.id
        if (id) {
             await this.updateState(tarif)
             this.archiveModifyTarif(tarif)
        }
        let tarifs = this.state.tarifs
        for (let i in tarifs) {
            if (tarifs[i].id === id) {
                tarifs.splice(parseInt(i),1)
            }
        }
        this.setState({
            tarifs : tarifs,
            isClosed : true
        })
    }

    validationAjoutTarif = () => {
        let duree = this.state.duree !== 'perpetuelle' ? parseInt(this.state.duree ? this.state.duree : this.state.autreDuree) : null
        const data = {
            typeEmplacement: this.state.typeEmplacement,
            tarifFixe: parseFloat(this.state.tarif),
            tarifRenouv: parseFloat(this.state.tarifRenouv),
            duree: duree,
            dateApplication: this.state.dateApplication,
            perpetuelle: this.state.duree === 'perpetuelle',
            tarifDuree: this.state.duree !== 'perpetuelle' ? parseFloat(parseFloat(this.state.tarif) / parseFloat(duree)) : null,
            eSpace:  process.env.REACT_APP_API_URI + '/e_spaces/' + localStorage.getItem('espace_id'),
            surface: parseInt(this.state.surface),
            parMetreCarre:  this.state.parMetreCarre,
            prestationsAttribution : this.getPrestations('attribution'),
            prestationsRenouv : this.getPrestations('renouv'),
            archive : false
        }
        let tarifs = this.state.tarifs
        let url = process.env.REACT_APP_API_URL + '/tarifs'
        if(this.state.showModalModif) {
            axios.put(process.env.REACT_APP_API_URL + '/tarifs/' + this.state.id, data).then((res) => {
                for (let i in tarifs) {
                    if (tarifs[i].id === this.state.id) {
                        tarifs[i] = res.data
                    }
                }
                this.setState({
                    tarifs: tarifs,
                    showModalModif : false
                })
            })
        } else {
            if (this.state.id) {
                if (DateTools.formatDate(this.state.tarifAModifier.dateApplication) === DateTools.formatDate(this.state.dateApplication)) {
                    this.setState({
                        showModalModif: true,
                    })
                } else {
                    axios.post(url, data).then((res) => {
                        for (let i in tarifs) {
                            if (tarifs[i].id === this.state.id) {
                                this.archiveModifyTarif(tarifs[i])
                                tarifs[i] = res.data
                            }
                        }
                        this.setState({
                            tarifs: tarifs
                        })
                    })
                }
            } else {
                axios.post(url, data).then((res) => {
                    tarifs.push(res.data)
                    this.setState({
                        tarifs: tarifs
                    })
                })
            }
        }
        this.setState({
            isClosed: true,
        })
    }

    archiveModifyTarif(tarif) {
        const data = {
            archive : true
        }
        axios.put(process.env.REACT_APP_API_URL + '/tarifs/' + tarif.id, data).then((res)=> {
            let archives = this.state.archives
            archives.push(res.data)
            this.setState({
                archives : archives
            })
        })
    }

    supprimerDefinitivement(tarif) {
       axios.delete(process.env.REACT_APP_API_URL + '/tarifs/' + tarif.id)
        let tarifs = this.state.archives
        for (let i in tarifs) {
            if (tarifs[i].id === tarif.id) {
                tarifs.splice(parseInt(i),1)
            }
        }
        this.setState({
            tarifs : tarifs,
            show : false
        })
    }

    getSurfacelabel = (index) => {
        const label = {
            1 : "Simple (entre 2m² et 4m²)",
            2 : "Double (entre 4m² et 6m²)",
            3 : "Triple (entre 6m² et 8m²)",
            4 : "Quadruple (entre 8m² et 10m²)",
            5 : "plus de 10m²"
        }
        return label[index]
    }

    handleChangeCheckbox(e) {
        let isChecked = e.target.checked;
        this.setState({parMetreCarre: isChecked})
    }

    addFormPrestations(type) {
        const div = document.getElementById('prestations-' + type + '-container')
        const button = document.getElementById('add-prestations-' + type)
        let nbform = document.querySelectorAll(".prestation_" + type).length
        if(div) {
            let child = document.createElement('div')
            child.classList.add("d-flex", "justify-content-center", "flex-wrap", "prestation_" + type, "w-100", "px-4", "mt-2", "new")
            child.setAttribute("id", "prestation_attribution_" + nbform)
            child.innerHTML +=
                    '<input type="text" name="prestation_' + type + '_' + nbform + '_nom" class="soleilRegular-fonts px-2 mr-2" placeholder="Intitulé" style="width : 40%"/>' +
                    '<input type="text" name="prestation_' + type + '_' + nbform + '_montant" class="soleilRegular-fonts px-2 mr-2" placeholder="Montant TTC" style="width : 40%"/>'
            div.insertBefore(child, button)
        }
    }

    getPrestations = (type) => {
        let array = {}
        let nbform = document.querySelectorAll(".prestation_" + type).length
        for (let i = 0; i <= nbform; i++) {
            let prestation = {}
            let nomField = document.querySelector('[name=prestation_' + type + '_' + i + '_nom]')
            let montantField = document.querySelector('[name=prestation_' + type + '_' + i + '_montant]')
            if(nomField && nomField.value && montantField && montantField.value) {
                prestation['nom'] = nomField.value
                prestation['montant'] = montantField.value
                array[i] = prestation
            }
        }
        return array
    }

    getInputPrestationsExistantes(prestations, type) {
        document.getElementById('prestations-' + type + '-list').innerHTML = ''
        if(prestations && Object.keys(prestations).length > 0) {
            Object.keys(prestations).map((p, index) => {
                document.getElementById('prestations-' + type + '-list').innerHTML +=
                    '<div class="d-flex mt-2 justify-content-center flex-wrap prestation_' + type + ' w-100 px-4" id="prestation_' + type + '_' + index + '">' +
                    '<input type="text" name="prestation_' + type + '_' + index + '_nom" class="soleilRegular-fonts px-2 mr-2" placeholder="Intitulé" style="width: 40%" value="' + prestations[p].nom + '"/>' +
                    '<input type="text" name="prestation_' + type + '_' + index + '_montant" class="soleilRegular-fonts px-2 mr-2" placeholder="Montant TTC" style="width: 40%" value="' + prestations[p].montant + '"/>' +
                    '</div>'
            })
        } else {
            this.addEmptyPrestationField(type)
        }
    }

    addEmptyPrestationField(type) {
        document.getElementById('prestations-' + type + '-list').innerHTML =
            '<div class="d-flex mt-2 justify-content-center flex-wrap prestation_' + type + ' w-100 px-4" id="prestation_' + type + '_0">' +
            '<input type="text" name="prestation_' + type + '_0_nom" class="soleilRegular-fonts px-2 mr-2" placeholder="Intitulé" style="width: 40%" value="" />' +
            '<input type="text" name="prestation_' + type + '_0_montant" class="soleilRegular-fonts px-2 mr-2" placeholder="Montant TTC" style="width: 40%" value="" />' +
            '</div>'
    }

    render() {
        const tarifs = this.state.tarifs.map((tarif) => {
            return (
                <tr>
                    <td>{tarif.typeEmplacement} {tarif.parMetreCarre ? "(par m²)" : "(par unité)"}</td>
                    <td>
                        <span className={"family_no" + (tarif.tarifFixe ? '' : ' incomplete')}>{tarif.tarifFixe ? tarif.tarifFixe : 'à renseigner'}</span>
                    </td>
                    <td>
                        <span className={"family_no" + (tarif.tarifRenouv ? '' : ' incomplete')}>{tarif.tarifRenouv  ? tarif.tarifRenouv  : 'à renseigner'}</span>
                    </td>
                    <td className="display-none"/>
                    <td className="display-none"/>
                    <td className="black">{tarif.perpetuelle ? 'perpétuelle' : tarif.duree !== null && tarif.duree !== undefined ? tarif.duree + ' ans' : 'à renseigner'}</td>
                    <td className="black">{tarif.surface ? this.getSurfacelabel(tarif.surface) : '-'}</td>
                    <td className="black">{tarif.dateApplication ? DateTools.formatDate(tarif.dateApplication) : '-'}</td>
                    <td>
                        <a onClick={() => this.ajouterTarif(tarif)} className="purple cursor">modifier</a>
                    </td>
                    <td>
                        <a className="red cursor" onClick={() => this.supprimerTarif(tarif)} >supprimer</a>
                    </td>
                </tr>
            )
        })

        const archives = this.state.archives.map((tarif) => {
            return (
                <tr>
                    <td>{tarif.typeEmplacement} {tarif.parMetreCarre ? "(par m²)" : "(par unité)"}</td>
                    <td>
                        <span className={"family_no" + (tarif.tarifFixe ? '' : ' incomplete')}>{tarif.tarifFixe ? tarif.tarifFixe : 'à renseigner'}</span>
                    </td>
                    <td>
                        <span className={"family_no" + (tarif.tarifRenouv ? '' : ' incomplete')}>{tarif.tarifRenouv  ? tarif.tarifRenouv  : 'à renseigner'}</span>
                    </td>
                    <td className="display-none"/>
                    <td className="display-none"/>
                    <td className="black">{tarif.perpetuelle ? 'perpétuelle' : tarif.duree !== null ? tarif.duree + ' ans' : 'à renseigner'}</td>
                    <td className="black">{tarif.surface ? this.getSurfacelabel(tarif.surface) : '-'}</td>
                    <td className="black">{tarif.dateApplication ? DateTools.formatDate(tarif.dateApplication) : '-'}</td>
                    <td>
                        <a onClick={() => this.ajouterTarif(tarif)} className="purple cursor">modifier</a>
                    </td>
                    <td>
                        <a onClick={
                            () => {
                                this.setState({
                                    show : true,
                                    tarifAModifier : tarif
                                })
                            }
                        } className="red cursor">supprimer</a>
                    </td>
                </tr>
            )
        })

        const options = [];
        for (let i = 5; i <= 15; i++) {
            options.push(<option value={i}>{i}</option>);
        }

        return (
            <React.Fragment>
                <div className="col-lg-12 mt-3 pb-4" >
                    <div className="bg-white pb-3" id="tarifs-liste">
                        <div className="pt-4 d-flex align-items-center mb-5">
                            <img className="img-fluid ml-4" src={euroIcon} width="50px" alt=""/>
                            <p className="ml-4 soleilBold-fonts tarif-title m-0">Tarifs du cimetière</p>
                        </div>
                    <div className="px-5">
                        <div className="table-responsive px-3 bg-white" id="tarifs-table" >
                            <table className="table table-striped table-borderless">
                                <thead>
                                <tr>
                                    <th id="table-type">Type</th>
                                    <th style={{minWidth: 140}}>Tarif attribution (€)</th>
                                    <th style={{minWidth: 140}}>Tarif renouvellement (€)</th>
                                    <th className="display-none">Droit d'enreg.</th>
                                    <th className="display-none">Total (€)</th>
                                    <th>Durée</th>
                                    <th style={{minWidth: 120}}>Surface</th>
                                    <th>Date d'application</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tarifs}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <button type="button" className="add-new-location-btn ml-5 mt-4 mb-4" id="add-new-tarif"
                            onClick={() => this.ajouterTarif(null)}> Ajouter nouveau
                        <img src={btnPlusIcon} alt="+" className="img-fluid ml-2"/>
                    </button>
                    <div className={"mx-5 " + (this.state.isClosed ? "display-none" : 'display-block')} id="ajoutTarif">
                        <div className="mt-3" id="compte-ajout-tarif">
                            <div className="row mt-4">
                                <div className="col-12">
                                    <form className="form-wrapper">
                                        <div className="d-flex align-items-center" id="ajout-tarif-type-emplacement">
                                            <label className="col-4 soleilRegular-fonts p-0 m-0">Type
                                                d'emplacement</label>
                                            <div className="p-0 col-8 select-grey-wrapper">
                                                <select name="typeEmplacement"
                                                        className="soleilRegular-fonts select-grey"
                                                        value={this.state.typeEmplacement}
                                                        onChange={this.handleChandeTypeEmplacement}
                                                >
                                                    <option value="Concession" >Concession</option>
                                                    <option value="Colombarium">Colombarium</option>
                                                    <option value="Enfeu">Enfeu</option>
                                                    <option value="Cavurne">Cavurne</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-1 align-items-center" id="ajout-tarif-type-duree">
                                            <label className="col-4 soleilRegular-fonts p-0 m-0">Durée</label>
                                            <div className="d-flex col-8 pl-0 pr-0">
                                                <div className="p-0 select-grey-wrapper col-5 ">
                                                    <select className="soleilRegular-fonts select-grey"
                                                            name="duree"
                                                            value={this.state.duree}
                                                            onChange={this.handleChange}>
                                                        <option value=""/>
                                                        {options}
                                                        <option value="20">20</option>
                                                        <option value="25">25</option>
                                                        <option value="30">30</option>
                                                        <option value="50">50</option>
                                                        <option value="99">99</option>
                                                        <option value="100">100</option>
                                                        <option value="perpetuelle">perpétuelle</option>
                                                    </select>
                                                </div>
                                                <label className="col-3 soleilRegular-fonts  pt-3"
                                                       id="second-label">Autre durée</label>
                                                <input type="number"
                                                       className="col-4 soleilRegular-fonts"
                                                       name="autreDuree"
                                                       value={this.state.autreDuree}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div id="surface-wrapper">
                                            <div className="d-flex align-items-center mt-1">
                                                <label className="col-4 soleilRegular-fonts p-0 m-0">Surface</label>
                                                <div className="d-flex col-8 pl-0 pr-0">
                                                    <div className="p-0 select-grey-wrapper col-5 ">
                                                        <select className="soleilRegular-fonts select-grey"
                                                                name="surface"
                                                                value={this.state.surface ? this.state.surface : ""}
                                                                onChange={this.handleChange}>
                                                            <option value=""/>
                                                            <option value="1">Simple (entre 2m² et 4m²)</option>
                                                            <option value="2">Double (entre 4m² et 6m²)</option>
                                                            <option value="3">Triple (entre 6m² et 8m²)</option>
                                                            <option value="4">Quadruple (entre 8m² et 10m²)</option>
                                                            <option value="5">plus de 10m²</option>
                                                        </select>
                                                    </div>
                                                    <p className="col-2">Ou</p>
                                                    <div className="col-5 d-flex align-items-center">
                                                        <label className="soleilRegular-fonts m-0 mr-2 p-0">Par m²</label>
                                                        <input type="checkbox"
                                                               className="soleilRegular-fonts"
                                                               name="parMetreCarre"
                                                               checked={this.state.parMetreCarre}
                                                               onChange={ e => this.handleChangeCheckbox(e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="tarifs-prestations-wrapper">
                                            <div className="d-flex mt-4 align-items-center">
                                                <label className="col-4 soleilRegular-fonts p-0 m-0">Tarif d'attribution TTC (€)</label>
                                                <input type="number"
                                                       name="tarif"
                                                       className="col-8 soleilRegular-fonts"
                                                       placeholder="Tarif d'attribution de l'emplacement prestations incluses"
                                                       value={this.state.tarif}
                                                       onChange={this.handleChange}/>
                                            </div>
                                            <div className="mt-3 d-flex mb-4">
                                                <div className="col-4"/>
                                                <div className="d-flex flex-column align-items-start col-8">
                                                    <label className="soleilLight-fonts font">Dont prestations incluses :</label>
                                                    <div id="prestations-attribution-container" className="w-100 d-flex flex-column justify-content-end align-items-end">
                                                        <div id="prestations-attribution-list" className="w-100">
                                                            <div className="d-flex justify-content-center flex-wrap prestation_attribution w-100 px-4" id="prestation_attribution_0">
                                                                <input type="text"
                                                                       name="prestation_attribution_0_nom"
                                                                       className="soleilRegular-fonts px-2 mr-2"
                                                                       placeholder="Intitulé"
                                                                       style={{width: '50%'}}
                                                                />
                                                                <input type="text"
                                                                       name="prestation_attribution_0_montant"
                                                                       className="soleilRegular-fonts px-2 mr-2"
                                                                       placeholder="Montant TTC"
                                                                       style={{width: '50%'}}
                                                                />
                                                            </div>
                                                        </div>
                                                        <button type="button" className="add-new-location-btn p-1 d-flex align-items-center justify-content-center" id="add-prestations-attribution" onClick={ () => this.addFormPrestations('attribution')}>
                                                            <img src={btnPlusIcon} alt="+" className="img-fluid"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex mt-2 align-items-center">
                                                <label className="col-4 soleilRegular-fonts p-0 m-0">Tarif du renouvellement TTC (€)</label>
                                                <input type="number"
                                                       name="tarifRenouv"
                                                       className="col-8 soleilRegular-fonts"
                                                       placeholder="Tarif de renouvellement prestations incluses"
                                                       value={this.state.tarifRenouv}
                                                       onChange={this.handleChange}/>
                                            </div>
                                            <div className="mt-3 d-flex mb-4">
                                                <div className="col-4"/>
                                                <div className="d-flex flex-column align-items-start col-8">
                                                    <label className="soleilLight-fonts font">Dont prestations incluses :</label>
                                                    <div id="prestations-renouv-container" className="w-100 d-flex flex-column justify-content-end align-items-end">
                                                        <div id="prestations-renouv-list" className="w-100">
                                                            <div className="d-flex justify-content-center flex-wrap prestation_renouv w-100 px-4" id="prestation_renouv_0">
                                                                <input type="text"
                                                                       name="prestation_renouv_0_nom"
                                                                       className="soleilRegular-fonts px-2 mr-2"
                                                                       placeholder="Intitulé"
                                                                       style={{width : '40%'}}
                                                                />
                                                                <input type="text"
                                                                       name="prestation_renouv_0_montant"
                                                                       className="soleilRegular-fonts px-2 mr-2"
                                                                       placeholder="Montant TTC"
                                                                       style={{width : '40%'}}
                                                                />
                                                            </div>
                                                        </div>
                                                        <button type="button" className="add-new-location-btn p-1 d-flex align-items-center justify-content-center" id="add-prestations-renouv" onClick={ () => this.addFormPrestations('renouv')}>
                                                            <img src={btnPlusIcon} alt="+" className="img-fluid"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-2" id="ajout-tarif-type-date">
                                            <label className="col-4 soleilRegular-fonts p-0 m-0">Date de
                                                l'application</label>
                                            <input type="date"
                                                   className="col-8 soleilRegular-fonts"
                                                   name="dateApplication"
                                                   value={this.state.dateApplication}
                                                   onChange={this.handleChange}/>
                                        </div>
                                        <div className="display-none">
                                            <div className="mt-5 d-flex ml-3 ">
                                                <label className="switch">
                                                    <input type="checkbox" value="application_droit"
                                                           name="application_droit" defaultChecked={true} />
                                                    <span className="slider round"/>
                                                </label>
                                                <div className="ml-3 soleilBold-fonts purple">
                                                    Application des droits d'enregistrement ?
                                                    <br/>
                                                    (B.O.I n°8 18/01/2006)
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" className="float-right ml-3 add-doc-btn soleilRegular-fonts mt-4 mb-3"
                                                id="add-tarif-btn" onClick={() => this.validationAjoutTarif()}>
                                            {this.state.id ? 'Modifier' : 'Ajouter'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-3 pb-4" >
                    <div className="bg-white pb-3" id="tarifs-archivés-wrapper">
                        <div className="pt-4 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <img className="img-fluid ml-4" src={euroIcon} width="50px" alt=""/>
                                <p className="ml-4 soleilBold-fonts tarif-title m-0">Tarifs archivés</p>
                            </div>
                            <button className="add-doc-btn mr-4 soleilBold-fonts" onClick={() => this.setState({isVisibleArchive : !this.state.isVisibleArchive})}>afficher les tarifs</button>
                        </div>
                        <div className={"px-5 mt-5 " + (!this.state.isVisibleArchive ? "display-none" : "")}>
                            <div className="table-responsive px-3 bg-white" >
                                <table className="table table-striped table-borderless">
                                    <thead>
                                    <tr>
                                        <th id="table-type">Type</th>
                                        <th>Tarif attribution (€)</th>
                                        <th>Tarif renouvellement (€)</th>
                                        <th className="display-none">Droit d'enreg.</th>
                                        <th className="display-none">Total (€)</th>
                                        <th>Durée</th>
                                        <th>Surface</th>
                                        <th>Date d'application</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {archives}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.show} onHide={() => {this.setState({show : false})}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmer la suppression</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="text-center">
                            Toute suppression est définitive, voulez-vous poursuivre ?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {this.setState({show : false})}}>
                            Annuler
                        </Button>
                        <Button variant="primary" onClick={() => this.supprimerDefinitivement(this.state.tarifAModifier)}>
                            Valider
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showModalModif} onHide={() => {this.setState({showModalModif : false})}}>
                    <Modal.Header className="bk-grey" closeButton>
                        Confirmer la modification
                    </Modal.Header>
                    <Modal.Body>
                        <p className="text-center">
                            Un tarif existe déjà à cette date d'application, êtes-vous sûr(e) de vouloir modifier ?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="red cancel-btn tbl" onClick={() => {this.setState({showModalModif : false})}}>
                            Annuler
                        </button>
                        <button type="button" className="tbl bk-red ml-2" onClick={() => this.validationAjoutTarif()}>
                            Valider
                        </button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

export default Tarifs;