import React, {Component} from 'react';
import DateTools from "../../../components/utils/DateTools";
import dayjs from "dayjs";

class EtapeEnCoursText extends Component {

    static generateTextEtape = (etapes, etape, procedure, dateDebut) => {
        switch (etape) {
            case 0 :
                return {
                    button  : {text : 'Démarrer', disabled : false},
                    texte   : 'Vous devez générer ici l\'acte de notoriété pour la procédure de reprise par abandon car la date du contrat de concession est inconnue.',
                    titre   : 'Acte de notoriété',
                    document: procedure && procedure.hasOwnProperty('documents') && procedure.documents.hasOwnProperty('acte-notoriete') ? [procedure.documents['acte-notoriete']] : null
                }
            case 1 :
                return {
                    button  : {text : 'Démarrer', disabled : false},
                    texte   : dateDebut && this.verifDateContrat(dateDebut) ?
                        'Vous démarrez ici la procédure de reprise après constat d\'abandon'
                        : 'Reprise après constat d\'abandon impossible car le contrat de concession n\'a pas plus de 30 ans.',
                    titre   : 'Pré-constat d\'abandon',
                    document: procedure && procedure.hasOwnProperty('documents') && procedure.documents.hasOwnProperty('acte-abandon-etape1-avis-etat-abandon1') ? [procedure.documents['acte-abandon-etape1-avis-etat-abandon1']] : null
                }
            case 2:
                return {
                    button  : etapes.length > 1 ? etapes[2]['en_cours'] === 'affichage' ? {text : 'Démarrer', disabled : false} : {text : 'Continuer', disabled : false} : '',
                    texte   : 'Saisissez ici les dates des affichages et notification le cas échéant',
                    titre   : 'Affichages et suspensions pré-constat',
                    document: null
                }
            case 3:
                return {
                    button  : EtapeEnCoursText.checkDateButton(procedure.datePremierPVestimee, null, etapes, 3, null),
                    texte   : procedure.dateHeurePV1 ? EtapeEnCoursText.checkDateMax8jours(procedure.etapes[etape]['date_fin'], true)
                        : 'Renseignez ici les informations nécessaires pour générer le premier procès-verbal de constat d’abandon.',
                    titre   : 'premier pv',
                    document: EtapeEnCoursText.handleDocumentsArray(['acte-abandon-etape3-pv1', 'acte-abandon-etape3-pv1-a-afficher'], procedure.documents)
                }
            case 4:
                return {
                    button  : etapes[4]['en_cours'] === 'affichage' ? EtapeEnCoursText.checkDateMax8jours(procedure.dateHeurePV1)
                                : EtapeEnCoursText.checkDateButton(procedure.suspensionPV1Mairie, procedure.suspensionPV1Cimetiere, etapes, 4, true ),
                    texte   : 'Dans les 8 jours maximum suivants l’établissement du PV, vous devez : notifier la famille si vous en avez connaissance ' +
                        'et afficher le PV à la mairie et au cimetière.',
                    titre   : 'Affichages et suspensions 1er pv (1/3)',
                    document: null
                }
            case 5:
                return {
                    button  : etapes[5]['en_cours'] === 'affichage' ?
                                EtapeEnCoursText.checkDatejours(procedure.suspensionPV1Mairie, procedure.suspensionPV1Cimetiere, 15)
                                : EtapeEnCoursText.checkDateButton(procedure.secondSuspensionPV1Mairie, procedure.secondSuspensionPV1Cimetiere, etapes, 5, true ),
                    texte   : 'Le premier affichage dure minimum 1 mois à compter de la dernière publicité (affichage et notification).',
                    titre   : 'Affichages et suspensions 1er pv (2/3)',
                    document: null
                }
            case 6:
                return {
                    button  : etapes[6]['en_cours'] === 'affichage' ?
                                EtapeEnCoursText.checkDatejours(procedure.secondSuspensionPV1Mairie, procedure.secondSuspensionPV1Cimetiere, 15)
                                : EtapeEnCoursText.checkDateButton(procedure.troisiemeSuspensionPV1Mairie, procedure.troisiemeSuspensionPV1Cimetiere, etapes, 6, true ),
                    texte   : 'Le troisième affichage dure minimum 1 mois à compter de la dernière publicité (affichage et notification).',
                    titre   : 'Affichages et suspensions 1er pv (3/3)',
                    document: null
                }
            case 7 :
                return {
                    button  : EtapeEnCoursText.checkDate1an(procedure.troisiemeSuspensionPV1Mairie, procedure.troisiemeSuspensionPV1Cimetiere) === true ? {text : 'Démarrer', disabled : false} : EtapeEnCoursText.checkDate1an(procedure.troisiemeSuspensionPV1Mairie, procedure.troisiemeSuspensionPV1Cimetiere) ,
                    texte   : EtapeEnCoursText.checkDate1an(procedure.troisiemeSuspensionPV1Mairie, procedure.troisiemeSuspensionPV1Cimetiere) === true ?
                                'Enregistrez ici si la concession est toujours en état d’abandon ou non.'
                                : 'La procédure sera en pause pendant 1 an à compter de la dernière suspension d’affichage.',
                    titre   : 'Deuxième avis',
                    document: null
                }
            case 8 :
                return {
                    button  : EtapeEnCoursText.checkDateButton(procedure.suspensionAEA2mairie, procedure.suspensionAEA2cimetiere, etapes, 8 ),
                    texte   : 'Saisissez ici les dates des affichages et notification le cas échéant.\n',
                    titre   : 'Affichages et suspensions 2e AVIS',
                    document: null
                }
            case 9:
                return {
                    button  : EtapeEnCoursText.checkDateButton(procedure.dateDeuxiemePVestimee, null, etapes, 9, null),
                    texte   : procedure.dateHeurePV2 ? EtapeEnCoursText.checkDateMax8jours(procedure.dateHeurePV2, true)
                                : 'Renseignez ici les informations nécessaires pour générer le second procès-verbal de constat d’abandon.',
                    titre   : 'Deuxième PV',
                    document: EtapeEnCoursText.handleDocumentsArray(['acte-abandon-etape9-pv2', 'acte-abandon-etape9-pv2-a-afficher'], procedure.documents)
                }
            case 10:
                return {
                    button  : etapes[10]['en_cours'] === 'affichage' ? EtapeEnCoursText.checkDateMax8jours(procedure.dateHeurePV2, false)
                                : EtapeEnCoursText.checkDateButton(procedure.suspensionPV2Mairie, procedure.suspensionPV2Cimetiere, etapes, 10, true ),
                    texte   : 'Dans les 8 jours maximum suivants l’établissement du PV, vous devez : notifier la famille si vous en avez connaissance ' +
                                'et afficher le PV à la mairie et au cimetière.',
                    titre   : 'Affichages et suspensions 2eme pv (1/1)',
                    document: null
                }
            case 11:
                return {
                    button  : EtapeEnCoursText.checkDateButton(procedure.suspensionPV2Mairie, procedure.suspensionPV2Cimetiere, etapes, 11, true ),
                    texte   : 'Saisissez ici les informations de la délibération de reprise.',
                    titre   : 'Délibération',
                    document: null
                }
            case 12:
                return {
                    button  : {text : 'Démarrer', disabled : false},
                    texte   : 'Vous enregistrez ici les données de l’arrêté municipal.',
                    titre   : 'Arrêté municipal',
                    document: null
                }
            case 13:
                return {
                    button  : EtapeEnCoursText.checkDate1Mois(procedure.qffichageArreteMairie, procedure.affichageArreteCimetiere) === true ? {text : 'Démarrer', disabled : false} : EtapeEnCoursText.checkDate1Mois(procedure.qffichageArreteMairie, procedure.affichageArreteCimetiere),
                    texte   : 'Confirmez ici la date de dispersion ou de mise en ossuaire des défunts.',
                    titre   : 'Confirmation',
                    document: null
                }
            }
            return ''
    }

    static handleDocumentsArray(slugs, documents) {
        let array = []
        slugs.forEach(slug => {
            if (documents && documents.hasOwnProperty(slug)) {
                array.push(documents[slug])
            }
        })
        return array
    }

    static checkDateButton(date, date2, etapes, etape, addDay) {
        if (date2) {
            date = new Date (date) > new Date(date2) ? date : date2
        }
        if (etapes[etape]['en_cours'] === 'affichage') {
            return {text : 'Démarrer', disabled : false}
        } else if (date && DateTools.compareDateWithNow(date) && etapes[etape]['en_cours'] === 'suspension') {
            return {text : 'Continuer', disabled : false}
        }
        else {
            date = new Date(date)
            if(addDay) {
                date.setDate(date.getDate() + 1)
            }
            if(date >= new Date()) {
                return {text: 'A partir du ' + DateTools.formatDate(date.toISOString()), disabled: true}
            } else {
                return {text: 'Démarrer', disabled: false}
            }
        }
    }

    static checkDatejours(date, date2, day) {
        if (date2) {
            date = new Date (date) > new Date(date2) ? date : date2
        }
        date = new Date(date)
        date.setDate(date.getDate() + day)
        if (date <= new Date()) {
            return {text : 'Démarrer', disabled : false}
        } else {
            return {text : 'A partir du ' + DateTools.formatDate(date.toISOString()), disabled : true}
        }
    }

    static checkDateMax8jours(date, text) {
        date = dayjs(date)
        if (dayjs().isAfter(date)) {
            if (text) {
                return "Avant le " + date.format('DD/MM/YYYY hh:mm')
            }
            return {text : "Avant le " + date.format('DD/MM/YYYY hh:mm'), disabled : false}
        }
        if (text) {
            return 'Vous n\'avez pas procéder à l\'affichage du PV dans les 8 jours suivant son établissement. Vous devez recommencer cette étape.'
        }
        return {
            text: "A partir du " + date.format('MM/DD/YYYY'),
            disabled: false
        }

    }

    static checkDate1an(date1, date2) {
        const date = new Date (date1) > new Date(date2) ? new Date(date1) : new Date(date2)
        date.setFullYear(date.getFullYear() + 1)
        if(DateTools.compareDateWithNow(date.toISOString())){
            return true
        } else {
            return {text :'A partir du ' + DateTools.formatDate(date.toISOString()), disabled : true}
        }
    }

    static checkDate1Mois(date1, date2) {
        const date = new Date (date1) > new Date(date2) ? new Date(date1) : new Date(date2)
        date.setMonth(date.getMonth() + 1)
        if(DateTools.compareDateWithNow(date.toISOString())){
            return true
        } else {
            return {text :'A partir du ' + DateTools.formatDate(date.toISOString()), disabled : true}
        }
    }

    static verifDateContrat = (date) => {
        let dateDebut = new Date(date)
        dateDebut.setFullYear(dateDebut.getFullYear() + 30)
        return dateDebut < new Date();
    }
}

export default EtapeEnCoursText;
