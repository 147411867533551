import React, {Component} from 'react';
import DateTools from "../../../components/utils/DateTools";

class RepriseAbandon extends Component {
    constructor(props) {
        super(props);
    }

    generateTextEtape = () => {
        const procedure = this.props.procedure

        switch (this.props.etape) {
            case 0 :
                return (
                    <div className="small-text-etape">
                        <p>Acte de notoriété généré le: {DateTools.formatDate(procedure['date_debut'])}</p>
                    </div>
                )
            case 1 :
                return (
                    <div className="small-text-etape">
                        <p>Avis d'état d'abandon le :  {DateTools.formatDate(procedure['date_debut'])}</p>
                        <p>Date prévisionnelle du 1er PV : {procedure.datePremierPVestimee ? DateTools.formatDate(procedure.datePremierPVestimee) : ''}</p>
                        <p>Connaissance des ayants droits : {
                            procedure.familleOuEntretien.length > 0 ? 'OUI' : 'NON'
                        }</p>
                    </div>
                )
            case 2 :
                return (
                    <div className="small-text-etape">
                        <p>Affichage mairie :  {DateTools.formatDate(procedure.affichageAEAmairie)} - {DateTools.formatDate(procedure.suspensionAEAmairie)}</p>
                        <p>Affichage cimetière : {DateTools.formatDate(procedure.affichageAEAcimetiere)} - {DateTools.formatDate(procedure.suspensionAEAcimetiere)} </p>
                        <p>Connaissance des ayants droits : {
                            procedure.familleOuEntretien.length > 0 ? 'OUI' : 'NON'
                        }</p>
                    </div>
                )
            case 3 :
                return (
                    <div className="small-text-etape">
                        {this.checkDate8Jours(procedure.dateHeurePV1) ?
                            <React.Fragment>
                                <p>Date du PV: {DateTools.formatDate(procedure.dateHeurePV1)}</p>
                                <p>Connaissance des ayants droits : {
                                    procedure.familleOuEntretien.length > 0 ? 'OUI' : 'NON'}</p>
                            </React.Fragment>
                            :
                            <p>L'affichage du PV n'a pas été effectué dans les 8 jours suivant sa création, vous
                                devez recommencer cette étape.</p>
                        }
                    </div>
                )
            case 4 :
                return (
                    <div className="small-text-etape">
                        <p>Notification par LRAR reçue le {procedure.recuLRPVdate ? DateTools.formatDate(procedure.recuLRPVdate) : '-'}</p>
                        <p>Affichage mairie :  {DateTools.formatDate(procedure.affichagePV1Mairie)} - {DateTools.formatDate(procedure.suspensionPV1Mairie)}</p>
                        <p>Affichage cimetière : {DateTools.formatDate(procedure.affichagePV1Cimetiere)} - {DateTools.formatDate(procedure.suspensionPV1Cimetiere)}</p>
                        <p>Connaissance des ayants droits : {
                            procedure.familleOuEntretien.length > 0 ? 'OUI' : 'NON'
                        }</p>
                    </div>
                )
            case 5 :
                return (
                    <div className="small-text-etape">
                        <p>Notification par LRAR reçue le {procedure.recuLRPVdate ? DateTools.formatDate(procedure.recuLRPVdate) : '-'}</p>
                        <p>Affichage mairie :  {DateTools.formatDate(procedure.secondAffichagePV1Mairie)} - {DateTools.formatDate(procedure.secondSuspensionPV1Mairie)}</p>
                        <p>Affichage cimetière : {DateTools.formatDate(procedure.secondAffichagePV1Cimetiere)} - {DateTools.formatDate(procedure.secondSuspensionPV1Cimetiere)}</p>
                        <p>Connaissance des ayants droits : {
                            procedure.familleOuEntretien.length > 0 ? 'OUI' : 'NON'
                        }</p>
                    </div>
                )
            case 6 :
                return (
                    <div className="small-text-etape">
                        <p>Notification par LRAR reçue le {procedure.recuLRPVdate ? DateTools.formatDate(procedure.recuLRPVdate) : '-'}</p>
                        <p>Affichage mairie :  {DateTools.formatDate(procedure.troisiemeAffichagePV1Mairie)} - {DateTools.formatDate(procedure.troisiemeSuspensionPV1Mairie)}</p>
                        <p>Affichage cimetière : {DateTools.formatDate(procedure.troisiemeAffichagePV1Cimetiere)} - {DateTools.formatDate(procedure.troisiemeSuspensionPV1Cimetiere)}</p>
                        <p>Connaissance des ayants droits : {
                            procedure.familleOuEntretien.length > 0 ? 'OUI' : 'NON'
                        }</p>
                    </div>
                )

            case 7 :
                return (
                    <div className="small-text-etape">
                        <p>Avis second état d'abandon le :  {DateTools.formatDate(procedure.etapes[7]['date_fin'])}</p>
                        <p>Date prévisionnelle du 2eme PV : {procedure.dateDeuxiemePVestimee ? DateTools.formatDate(procedure.dateDeuxiemePVestimee) : ''}</p>
                        <p>Photo de l'abadon : {
                            procedure.tombeCasseeInstable2 || procedure.affaissementTerrain2
                            || procedure.grilleRouillee2 || procedure.vegetationNonEntretenue2
                            || procedure.autreEtatAbandon2.length > 0  ? 'OUI' : 'NON'
                        }</p>
                        <p>Connaissance des ayants droits : {
                            procedure.familleOuEntretien.length > 0 ? 'OUI' : 'NON'
                        }</p>
                    </div>
                )
            case 8 :
                return (
                    <div className="small-text-etape">
                        <p>Affichage mairie :  {DateTools.formatDate(procedure.affichageAEA2mairie)} - {DateTools.formatDate(procedure.suspensionAEA2mairie)}</p>
                        <p>Affichage cimetière : {DateTools.formatDate(procedure.affichageAEA2cimetiere)} - {DateTools.formatDate(procedure.suspensionAEA2cimetiere)} </p>
                        <p>Connaissance des ayants droits : {
                            procedure.familleOuEntretien.length > 0 ? 'OUI' : 'NON'
                        }</p>
                    </div>
                )
            case 9 :
                return (
                    <div className="small-text-etape">
                        <div className="small-text-etape">
                            <p>Date du PV:  {DateTools.formatDate(procedure.dateHeurePV2)}</p>
                            <p>Connaissance des ayants droits : {
                                procedure.familleOuEntretien.length > 0 ? 'OUI' : 'NON'
                            }</p>
                        </div>
                    </div>
                )
            case 10 :
                return (
                    <div className="small-text-etape">
                        <p>Notification par LRAR reçue le {procedure.recuLRPV2date ? DateTools.formatDate(procedure.recuLRPV2date) : '-'}</p>
                        <p>Affichage mairie :  {DateTools.formatDate(procedure.affichagePV2Mairie)} - {DateTools.formatDate(procedure.suspensionPV2Mairie)}</p>
                        <p>Affichage cimetière : {DateTools.formatDate(procedure.affichagePV2Cimetiere)} - {DateTools.formatDate(procedure.suspensionPV2Cimetiere)}</p>
                        <p>Connaissance des ayants droits : {
                            procedure.familleOuEntretien.length > 0 ? 'OUI' : 'NON'
                        }</p>
                    </div>
                )
            case 11 :
                return (
                    <div className="small-text-etape">
                        <p>Délibération le {DateTools.formatDate(procedure.datePreviConseilMunicipal)}</p>
                    </div>
                )
            case 12 :
                return (
                    <div className="small-text-etape">
                        <p>Arrêté municipal le  {DateTools.formatDate(procedure.dateArreteMunicipalReprise)}</p>
                        <p>Affichage à la mairie le  {DateTools.formatDate(procedure.affichageArreteMairie)}</p>
                        <p>Affichage au cimetière le  {DateTools.formatDate(procedure.affichageArreteCimetiere)}</p>
                    </div>
                )
            case 13 :
                return (
                    <div className="small-text-etape">
                        <p>Confirmé le {DateTools.formatDate(procedure.dateArreteMunicipalReprise)}</p>
                    </div>
                )
        }

        return ''
    }

    checkDate8Jours = (date) => {
        date = new Date (date)
        date.setDate(date.getDate() + 8)
        return date > new Date()
    }

    render() {
        return (
            <React.Fragment>
                {this.generateTextEtape()}
            </React.Fragment>

        )
    }
}

export default RepriseAbandon;
