import React from "react";
import DateTools from "../utils/DateTools";

const Previsualisation = (props) => {

    const utilisateurs = props.utilisateurs.map((u, index) => {
        return (
            <div className="mt-5" key={index}>
                <p className="soleilBold-fonts purple text-uppercase">Utilisateur {index + 1}</p>
                <div className="d-flex mt-2">
                    <p className="soleilRegular-fonts purple">Fonction :</p>
                    <p className="soleilRegular-fonts ml-1">{u.contact.fonction}</p>
                </div>
                <div className="d-flex mt-2">
                    <p className="soleilRegular-fonts purple">Civilité :</p>
                    <p className="soleilRegular-fonts ml-1">{u.contact.civilite === 'H' ? 'Monsieur' : 'Madame'}</p>
                </div>
                <div className="d-flex mt-2">
                    <p className="soleilRegular-fonts purple">Nom :</p>
                    <p className="soleilRegular-fonts ml-1">{u.contact.nom}</p>
                </div>
                <div className="d-flex mt-2">
                    <p className="soleilRegular-fonts purple">Prénom :</p>
                    <p className="soleilRegular-fonts ml-1">{u.contact.prenom}</p>
                </div>
                <div className="d-flex mt-2">
                    <p className="soleilRegular-fonts purple">Téléphone :</p>
                    <p className="soleilRegular-fonts ml-1">{u.contact.telephone}</p>
                </div>
                <div className="d-flex mt-2">
                    <p className="soleilRegular-fonts purple">Email de connexion :</p>
                    <p className="soleilRegular-fonts ml-1">{u.identifiant}</p>
                </div>
                <div className="d-flex mt-2">
                    <p className="soleilRegular-fonts purple">Mot de passe :</p>
                    <p className="soleilRegular-fonts ml-1">{u.mdp}</p>
                </div>
            </div>
        )
    })

    let mairies
    if(props.mairie.id) {
        mairies = props.mairie.cimetieres
    } else {
        mairies = props.espaces
    }

    const espaces = mairies.map((e, index) => {
        return (
            <div className="mt-5" key={index}>
                <p className="soleilBold-fonts purple text-uppercase">Cimetière {index + 1}</p>
                <div className="d-flex mt-2">
                    <p className="soleilRegular-fonts purple">Nom :</p>
                    <p className="soleilRegular-fonts ml-1">{e.nom}</p>
                </div>
                <div className="d-flex mt-2">
                    <p className="soleilRegular-fonts purple">Adresse :</p>
                    <p className="soleilRegular-fonts ml-1">{e.adresse}</p>
                </div>
                <div className="d-flex mt-2">
                    <p className="soleilRegular-fonts purple">Code postal :</p>
                    <p className="soleilRegular-fonts ml-1">{e.codePostal}</p>
                </div>
                <div className="d-flex mt-2">
                    <p className="soleilRegular-fonts purple">Ville :</p>
                    <p className="soleilRegular-fonts ml-1">{e.ville}</p>
                </div>

                <div className="d-flex mt-2">
                    <p className="soleilRegular-fonts purple">Plan :</p>
                    {
                        e.map ?
                            <img src={process.env.REACT_APP_MEDIA_URL + e.map} alt=""/>
                        : <p className="grey small px-4 m-0 p-0">Aucun plan ajouté</p>
                    }
                </div>
            </div>
        )
    })

    const horaires = props.mairie.horaires.map(horaire => {
        return <p>{horaire}</p>
    })

    const options = props.abonnement.options.map(option => {
        return <p>{option}</p>
    })

    return (
        <>
            <div className="px-5" id="preview-ajout-espace">
                {utilisateurs}
                <div className="mt-5">
                    <p className="soleilBold-fonts purple text-uppercase">Mairie</p>
                    <div className="d-flex mt-2">
                        <p className="soleilRegular-fonts purple">Commune :</p>
                        <p className="soleilRegular-fonts ml-1">{props.mairie.commune}</p>
                    </div>
                    <div className="d-flex mt-2">
                        <p className="soleilRegular-fonts purple">Code postal :</p>
                        <p className="soleilRegular-fonts ml-1">{props.mairie.codePostal}</p>
                    </div>
                    <div className="d-flex mt-2">
                        <p className="soleilRegular-fonts purple">Ville :</p>
                        <p className="soleilRegular-fonts ml-1">{props.mairie.ville}</p>
                    </div>
                    <div className="d-flex mt-2">
                        <p className="soleilRegular-fonts purple">Email :</p>
                        <p className="soleilRegular-fonts ml-1">{props.mairie.email}</p>
                    </div>
                    <div className="d-flex mt-2">
                        <p className="soleilRegular-fonts purple">Téléphone :</p>
                        <p className="soleilRegular-fonts ml-1">{props.mairie.telephone}</p>
                    </div>
                    <div className="d-flex mt-2">
                        <p className="soleilRegular-fonts purple">Horaires :</p>
                        <div className="d-flex flex-column ml-3">
                            {horaires}
                        </div>
                    </div>
                </div>
                {espaces}
                {
                    !props.mairie.id ?
                        <div className="mt-5">
                            <p className="soleilBold-fonts purple text-uppercase">Abonnement</p>
                            <div className="d-flex mt-2">
                                <p className="soleilRegular-fonts purple">Début :</p>
                                <p className="soleilRegular-fonts ml-1">{props.abonnement.dateDebut ? DateTools.formatDate(props.abonnement.dateDebut): ''}</p>
                            </div>
                            <div className="d-flex mt-2">
                                <p className="soleilRegular-fonts purple">Options choises :</p>
                                <div className="d-flex flex-column ml-3">
                                    {options}
                                </div>
                            </div>
                        </div>
                        : ''
                }
            </div>
        </>
    )
}

export default Previsualisation;