import React, { Component } from 'react';
import greyBellIcon from '../../assets/images/grey-bell-icon.svg'
import editIconWhite from "../../assets/images/edit-white-icon.svg";
import refreshPurple from "../../assets/images/refresh-purple-icon.svg";
import pdfExportIcon from "../../assets/images/pdf-export-icon-purple.svg";
import deleteIcon from "../../assets/images/delete-icon-purple.svg";
import plusRedButton from "../../assets/images/plus-red-icon.svg";
import ShowHistorique from "../../components/utils/ShowHistorique"
import ArchiveModal from "../../components/concession/ArchiveModal"

class EmplacementVide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            historique: null
        }
    }


    render() {
        return (
           <React.Fragment>
               <div className="px-0 scroll" id="fiche_libre">
                   <div className="d-flex justify-content-around flex-grow-1">
                       <div className="col-lg-6">
                           <div className="durand-family-wrapper mt-5 ml-5">
                               <div className=" py-2 align-items-center ml-4">
                                   <div className="title soleilBold-fonts text-center text-md-left">
                                       <img src={greyBellIcon} alt="" className="img-fluid mr-3"/>
                                       Emplacement libre
                                   </div>
                               </div>
                           </div>
                           <div id="travaux-wrapper">
                               <div className="d-flex align-items-center ml-5 mt-4">
                                   <p className="title soleilRegular-fonts mb-0">TRAVAUX</p>
                                   <img src={plusRedButton} data-toggle="modal"
                                        data-target="#etape5" id='5' alt="" className="img-fluid ml-2 mb-1 step-btn cursor btn-travaux travaux-form"
                                        data-travaux-id="0"
                                        onClick={this.props.newTravaux}/>
                               </div>
                               <div className="px-3 pt-1 notif_concession ml-5">
                                   {this.props.travaux}
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-6 pb-2 mt-2">
                           <div className="mt-3 mt-md-0">
                               <div className="mx-auto ml-md-auto mr-md-0 durand-buttons">
                                   <button type="button" className="active step-btn editer-location-btn" data-toggle="modal"
                                           data-target="#etape1" id="1">
                                       <img src={editIconWhite} className="active step-btn img-fluid mr-2" data-toggle="modal"
                                            data-target="#etape1" id="1" alt="" />
                                       éditer concession
                                   </button>
                                   {
                                       this.props.emplacement.nbLocationArchives > 0 ?
                                           <button type="button" className="historique-location-btn"  data-toggle="modal"
                                                   data-target="#show-historique" onClick={() => {
                                               ShowHistorique(this.props.emplacement.id, {setEmplacementInfo: (info) => {
                                                       this.setState({historique: info})
                                                   }})
                                           }}>
                                               <img src={refreshPurple} alt="" className="img-fluid mr-2"/>
                                               historique
                                           </button>
                                           : ''
                                   }
                                   <button type="button" className="exporter-location-btn">
                                       <img src={pdfExportIcon} alt=""
                                            className="img-fluid mr-2"/>
                                       exporter
                                   </button>
                               </div>
                           </div>
                           <div id="infos_concession">
                               <div className=" map-wrapper-cont mt-md-2">
                                   <img src={process.env.REACT_APP_API_URL + "/emplacements/emplacement-svg/" + this.props.emplacement.id } alt={""} />
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
               <ArchiveModal historique={this.state.historique} emplacement={this.props.emplacement}></ArchiveModal>
           </React.Fragment>
        )
    }
}

export default EmplacementVide;