
export const logoStyle = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = "#6D00B5";
        return {
            ...styles,
            paddingLeft: '50px',
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? data.color
                    : isFocused
                        ? '#6D00B5'
                        : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'white'
                    : isFocused
                        ? 'white'
                        : 'black',
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':before': {
                backgroundColor: color,
                borderRadius: 10,
                content: '" "',
                display: 'block',
                marginRight: 8,
                height: 30,
                width: 30,
                left: 10,
                backgroundImage: 'url(' + data.logo + ')',
                position: 'absolute',
                backgroundSize: 'cover'
            },
            ':active': {
                ...styles[':active'],
                backgroundColor:
                    !isDisabled && (isSelected ? data.color : '#6D00B5'),
            },
        };
    }
};