import React from 'react'
import {
    ADD_DEFUNT,
    ADD_EXHUMATION,
    ADD_LISTE_DEFUNT,
    ADD_REDUCTION,
    DISPLAY_DEFUNT,
    REMOVE_DEFUNT,
    UPDATE_EMPLACEMENT
} from "../actions/types";

const initialState = {
    emplacement : null,
    exhumation : null,
    defunt : null,
    defuntListe: null,
    defuntAjoute : 0,
    defuntAjouteId : null,
    reduction : null
};


export default function(state = initialState, action){
    switch (action.type) {
        case DISPLAY_DEFUNT :
            let exhumation = null;
            let reduction = null;
            if (action.defunt !== null && JSON.parse(action.defunt).hasOwnProperty('exhumations')) {
                exhumation =  JSON.parse(action.defunt).exhumations[JSON.parse(action.defunt).exhumations.length - 1]
            }
            if (action.defunt) {
                reduction = JSON.parse(action.defunt).reductionReunion
            }
            return {
                emplacement: state.emplacement,
                exhumation: exhumation,
                defunt : action.defunt,
                defuntAjoute: state.defuntAjoute,
                defuntListe: state.defuntListe,
                defuntAjouteId: null,
                reduction: reduction,
            }
        case ADD_DEFUNT :
            let listeDefunt
            if (state.defuntListe != null) {
                listeDefunt = JSON.parse(state.defuntListe)
            } else {
                listeDefunt = []
            }
            if (action.service === 'add') {
                listeDefunt.push(action.defunt.occupant)
            } else {
                for (let defunt in listeDefunt) {
                    if(listeDefunt[defunt].id === action.defunt.occupant.id) {
                        listeDefunt[defunt] = action.defunt.occupant
                    }
                }
            }

            let nbAjout = 0
            if (action.service === 'add') {
                nbAjout = 1
            }
            return {
                emplacement: state.emplacement,
                exhumation: state.exhumation,
                defunt : JSON.stringify(action.defunt),
                defuntAjoute: state.defuntAjoute + nbAjout,
                defuntListe: JSON.stringify(listeDefunt),
                defuntAjouteId: action.defunt.occupant.id,
                reduction: state.reduction,
            }
        case ADD_LISTE_DEFUNT :
            return {
                emplacement: state.emplacement,
                exhumation: state.exhumation,
                defunt : state.defunt,
                defuntAjoute: state.defuntAjoute,
                defuntListe: action.defunts,
                defuntAjouteId: state.defuntAjouteId,
                reduction: state.reduction,
            }
        case UPDATE_EMPLACEMENT :
            return {
                emplacement: action.emplacement,
                exhumation: state.exhumation,
                defunt : state.defunt,
                defuntAjoute: state.defuntAjoute,
                defuntListe: state.defuntListe,
                defuntAjouteId: state.defuntAjouteId,
                reduction: state.reduction,
            }
        case ADD_EXHUMATION :
            return {
                emplacement: state.emplacement,
                exhumation: action.exhumation,
                defunt : state.defunt,
                defuntAjoute: state.defuntAjoute,
                defuntListe: state.defuntListe,
                defuntAjouteId: state.defuntAjouteId,
                reduction: state.reduction,
            }
        case ADD_REDUCTION :
            return {
                emplacement: state.emplacement,
                exhumation: state.exhumation,
                defunt : state.defunt,
                defuntAjoute: state.defuntAjoute,
                defuntListe: state.defuntListe,
                defuntAjouteId: state.defuntAjouteId,
                reduction: action.reduction,
            }

        case REMOVE_DEFUNT :
            let defuntListe = JSON.parse(state.defuntListe)
            for (let key in defuntListe) {
                if(defuntListe[key].id === parseInt(action.defuntId)) {
                    defuntListe.splice(key, 1)
                }
            }
            return {
                emplacement: state.emplacement,
                exhumation: state.exhumation,
                defunt : state.defunt,
                defuntAjoute: state.defuntAjoute,
                defuntListe: JSON.stringify(defuntListe),
                defuntAjouteId: state.defuntAjouteId,
                reduction: state.reduction,
            }
        default :
            return state
    }
}