import React, { Component } from 'react';
import {Tab, Tabs} from "react-bootstrap";
import Tutoriels from "./Tutoriels";
import Lexique from "./Lexique"
import PhoneIcon from "../../assets/images/phone-icon.svg"
import Faq from "./Faq";

class centreAideMenu extends Component {

    render() {
        return(
            <div className="contents content-margin">
                <div className="content-header px-3 px-lg-4 page2">
                    <span className="sidemenu-expand-btn">&#9776;</span>
                    <div className="d-flex align-items-center">
                        <div className="col-12 soleilBold-fonts title">
                            CENTRE D'AIDE
                        </div>
                    </div>
                </div>
                <div className="px-3 px-lg-4" id="centre-aide-menu">
                    <Tabs defaultActiveKey="exportation" className="quick-navbar d-flex mb-3 justify-content-center px-3">
                        <Tab eventKey="exportation" title="Lexique" id="lexique-link">
                            <div className="essential-cemetery-wrapper scroll" id="scroll-to">
                                <Lexique mot ={this.props.match.params.mot}/>
                            </div>
                        </Tab>
                        <Tab eventKey="importation" title="Tutoriels" id="tutoriels-link">
                            <div className="essential-cemetery-wrapper scroll" id="tutoriels-panel">
                                <Tutoriels/>
                            </div>
                        </Tab>
                        <Tab eventKey="point_restauration" title="FAQ" id="faq-link">
                            <div className="essential-cemetery-wrapper scroll" id="faq-panel">
                                <Faq/>
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Contacter un conseiller" id="contact-link">
                            <div className="essential-cemetery-wrapper scroll" id="contact-panel">
                                <div className="container-fluid px-4 bg-white pt-4 d-flex align-items-center flex-column py-5">
                                    <div className="d-flex align-items-center flex-column">
                                        <p className="title text-uppercase purple soleilBold-fonts"> contactez-nous</p>
                                        <p className="soleilLight-fonts purple">L'équipe LogiPlace est à votre écoute.</p>
                                    </div>
                                    <div className="d-flex align-items-center flex-column mt-5">
                                        <img src={PhoneIcon} alt='icone telephone'/>
                                        <p className="title text-uppercase purple soleilBold-fonts mt-2">+33 9 52 68 47 84</p>
                                        <p className="soleilLight-fonts mt-1">Nous sommes disponibles du lundi au vendredi de 9h00 à 17h00.</p>
                                    </div>

                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        )
    }
}
export default centreAideMenu;