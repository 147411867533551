import React, {useEffect, useState} from "react";
import useDebounce from "../utils/useDebounce";

export default function SearchUser (props) {

    const [recherche, setRecherche] = useState('')
    const debouncedValue = useDebounce(recherche, 500)

    const handleRecherche = (e) => {
        setRecherche(e.target.value)
    }

    useEffect(() => {
        props.search(debouncedValue)
    }, [debouncedValue])

    return (
        <>
            <div className="form-wrapper p-4">
                <div className="d-flex">
                    <label htmlFor="user_input" className="p-0 soleilRegular-fonts mr-1 mb-0">Chercher un utilisateur :</label>
                    <input type="text" id="user_input" name="user_input"
                           className="soleilRegular-fonts px-2 col-4"
                           placeholder="Rechercher dans mon cimetière"
                           value={recherche}
                           onChange={handleRecherche}
                    />
                </div>
            </div>
        </>
    )
}