import React, { Component } from 'react';
import axios from "axios";
import DateTools from "../../components/utils/DateTools";

class Abonnement extends Component{

    constructor(props) {
        super(props);
        this.state = {
            abonnement: null
        }
    }

    componentDidMount() {
        if(localStorage.getItem('societe_id'))
            axios.get(process.env.REACT_APP_API_URL + '/abonnements?societe.id=' + localStorage.getItem('societe_id'))
                .then(res => {
                    if(res.data['hydra:member'][0])
                        this.setState({abonnement: res.data['hydra:member'][0]})
                })
    }

    render() {
        const options = {
            'recuperation_donnees': 'Outil de récupération des données',
            'update_map': 'Mise à jour du plan',
            'audit_consulting': 'Audit et conseils en aménagement',
            'assistance_juridique': 'Assistance juridique selon l\'acte',
            'saisie_informations_defunts': 'Saisie des informations des nouveaux défunts',
            'saisie_donnes': 'Saisie des données',
            'import_donnes': 'Import des données',
            'numerisation': 'Numérisation des actes existants',
            'formation': 'Formation au logiciel',
            'photo_emplacement': 'Photographie des emplacements',
            'photo_aerienne': 'Photographie aérienne par drone'
        }

        let optionsRender = []
        if(this.state.abonnement) {
            optionsRender = Object.keys(options).map(option => {
                console.log(option)
                return (
                    <div className="col-md-8 mt-3 options pl-0">
                        <div className={"purple soleilBold-fonts mt-3 ml-0 pl-0 " + (this.state.abonnement.options && this.state.abonnement.options.abonnement && this.state.abonnement.options.abonnement.includes(option) || this.state.abonnement.options && this.state.abonnement.options.premium && this.state.abonnement.options.premium.includes(option) ? 'green-tick' : 'red-tick')}>{options[option]}
                        </div>
                    </div>
                )
            })
        }

        return(
            <div>
                <div className="container-fluid form-wrapper mt-3">
                    <p className="title soleilBold-fonts purple">mon abonnement</p>
                    <p className="soleilBold-fonts purple mt-3">Date de début : {this.state.abonnement && this.state.abonnement.dateDebut ? DateTools.formatDate(this.state.abonnement.dateDebut) : ''}</p>
                    <p className="soleilBold-fonts purple">Etat : {this.state.abonnement && this.state.abonnement.etat === 1 ? '✅' : '❌'}</p>
                    {/*<p className="soleilBold-fonts purple">Date de renouvellement : 1er janvier 2022</p>
                    */}
                </div>
                <div className="container-fluid form-wrapper mt-3">
                    <p className="title soleilBold-fonts purple mb-0">mes options</p>
                    <p className="sous-notes soleilLight-fonts purple">Pour souscrire une nouvelle option, veuillez
                        prendre contact avec votre conseiller personnel.</p>
                    <div className="ml-5">
                        {optionsRender}
                    </div>
                </div>
            </div>
        )
    }
}

export default Abonnement;