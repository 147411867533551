import React, { Component } from 'react';
import pdfIconTbl from '../../assets/images/pdf-icon-tbl.svg';
import filterIconSubmenu from '../../assets/images/filter-icon-submenu.svg';
import {Link} from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import AjoutNouveau from "../../components/concession/AjoutNouveau";
import OrderBy from "../../components/utils/OrderBy";
import GetPDF from "../../components/utils/GetPDF";
import GetListePDF from "../../components/utils/GetListePDF";

class TravauxListe extends Component {

    constructor(props) {
        super(props);
        this.paginationRef = React.createRef();
        this.state = {
            selected  : 0,
            travaux   : [],
            total     : 0,
            orderBy   : null,
            sortBy    : null,
            selectedId: [],
            ids       : [],
            search    : '',
            typeExt   : 'pdf'
        }
    }

    updateState = (res) => {
        this.setState({
            travaux : res.data.data,
            total   : res.data.total,
        })
    }

    handleCheck = (e) => {
        let parent     = e.target.parentElement.parentElement
        let selectedId = this.state.selectedId
        let number     = this.state.selected

        if (e.target.checked === true) {
            selectedId.push(parent.getAttribute('data-key'))
            number+=1
        } else {
            const index = selectedId.indexOf(parent.getAttribute('data-key'));
            if (index > -1) {
                selectedId.splice(index, 1);
                number-=1
            }
        }
        this.setState({
            selected  : number,
            selectedId: selectedId
        });
    }

    formatDate = (date) => {
        let date_format = new Date(date)
        return ('0' + date_format.getDate()).slice(-2) + '/'
            + ('0' + (date_format.getMonth()+1)).slice(-2) + '/'
            + date_format.getFullYear()
    }

    verifEtatTravaux(debut, fin){
        if (debut && fin) {
            let dateDebut = new Date(debut)
            let dateFin = new Date(fin)
            if (dateDebut < new Date() && new Date() < dateFin) {
                return 'EN COURS'
            } else if (dateFin < new Date()) {
                return 'RÉALISÉ'
            } else {
                return 'NON COMMENCÉ'
            }
        } else {
            return 'PAS DE DONNEES'
        }
    }

    refreshPagination () {
        this.paginationRef.current.changePage(1)
    }

    sort = (sortBy) => {
        if (this.state.sortBy === sortBy) {
            this.setState(prevState =>{
                return{
                    ...prevState,
                    orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
                }
            })
        } else {
            this.setState({
                sortBy : sortBy,
                orderBy: 'asc'
            })
        }
    }

    render() {
        let tableRows = this.state.travaux.map((t) => {
            let dateDebut = t.dateDebut ? t.dateDebut.substr(0, 10) : null
            let dateFin   = t.dateFin ? t.dateFin.substr(0, 10) : null
            return (
                <tr key={t.id} data-key={t.id} className={this.state.selectedId.includes(t.id.toString()) ? 'liste-border' : 'liste-border-none'}>
                    <td>
                        <input type="checkbox" className="table-checkbox" onChange={this.handleCheck} checked={this.state.selectedId.includes(t.id.toString())}/>
                        {t.emplacement.carre ? t.emplacement.carre :''} { t.emplacement.allee ? t.emplacement.allee : ''}
                    </td>
                    <td>{t.type}</td>
                    <td>
                        {
                            this.verifEtatTravaux(dateDebut, dateFin) === 'EN COURS' ?
                                <span className="tbl bk-orange">EN COURS</span>
                                : this.verifEtatTravaux(dateDebut, dateFin) === 'RÉALISÉ' ?
                                    <span className="tbl bk-green">RÉALISÉ</span>
                                : this.verifEtatTravaux(dateDebut, dateFin) === 'NON COMMENCÉ' ?
                                    <span className="tbl bk-red">NON COMMENCÉ</span>
                                        :
                                        <span className="tbl bk-grey">PAS DE DONNEES</span>
                        }
                    </td>
                    <td>
                        {t.demandeur.nom} {t.demandeur.prenom}
                    </td>
                    <td>{t.executant.nomSociete}</td>
                    <td>{dateDebut ? this.formatDate(dateDebut) : '-'}</td>
                    <td>{dateFin ? this.formatDate(dateFin) : '-'}</td>
                    <td>
                        <Link to={{
                            pathname: "/fiche-concession/" + t.emplacement.id,
                        }} className="edit-tbl">
                            consulter
                        </Link>
                    </td>
                    <td>
                        <p className="exporter-tbl cursor" onClick={() => GetPDF('travaux', t.id)}>
                            <img src={pdfIconTbl} alt="" className="img-fluid" />
                            exporter
                        </p>
                    </td>
                </tr>
            )
        })
        return (
            <div>
                <div className="mx-3">
                    <div className="table-responsive px-3 scroll listes-leftpanel">
                        <table className="table table-striped table-borderless">
                            <thead>
                            <tr>
                                <th className="sortStyle">
                                    Carré / Allée
                                    <span onClick={() => this.sort('allee')}>
                                        <OrderBy isClicked={this.state.sortBy === 'allee'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    Type
                                    <span onClick={() => this.sort('type')}>
                                        <OrderBy isClicked={this.state.sortBy === 'type'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">État</th>
                                <th className="sortStyle">
                                    Demandeur
                                    <span onClick={() => this.sort('demandeur.nom')}>
                                        <OrderBy isClicked={this.state.sortBy === 'demandeur.nom'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    Service éxécutant
                                    <span onClick={() => this.sort('executant.nom')}>
                                        <OrderBy isClicked={this.state.sortBy === 'executant.nom'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    Début
                                    <span onClick={() => this.sort('dateDebut')}>
                                        <OrderBy isClicked={this.state.sortBy === 'dateDebut'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    Fin
                                    <span onClick={() => this.sort('dateFin')}>
                                        <OrderBy isClicked={this.state.sortBy === 'dateFin'}/>
                                    </span>
                                </th>
                                <th colSpan="2" className="sortStyle">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {tableRows}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="container-fluid py-3 px-lg-5">
                    <div className="row justify-content-between">
                        <AjoutNouveau/>
                        <Pagination
                            url         = '/travaux/allTravauxByEspace/'
                            ref         = {this.paginationRef}
                            eSpaceId    = {localStorage.getItem('espace_id')}
                            type        = {null}
                            sort        = {this.state.sortBy}
                            order       = {this.state.orderBy}
                            filters     = {this.props.filters}
                            updateState = {this.updateState}
                            search      = {this.state.search}
                        />
                        <div
                            className="list-button d-flex justify-content-center justify-content-sm-end align-items-center mt-4 mt-sm-0">
                            <div>
                                <input type="search" onKeyUp={(e) => {this.setState((prevState) => {
                                    return {
                                        ...prevState,
                                        search: e.target.value
                                    }
                                })}} placeholder="Recherche" className={"form-control"} />
                            </div>
                            <div>
                                <button type="button" className="export-locations-list-btn d-flex cursor" onClick={() => GetListePDF('travaux')}>
                                    <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                    <span className="soleilBold-fonts">EXPORTER LISTE <br/>TRAVAUX</span>
                                </button>
                                <button type="button" className="export-locations-list-btn d-flex cursor" onClick={() => GetListePDF('travaux', null, 'csv')}>
                                    <img src={filterIconSubmenu} alt="" className="img-fluid"/>
                                    <span className="soleilBold-fonts">EXPORTER EXCEL <br/>TRAVAUX</span>
                                </button>
                            </div>
                            <div>
                                <select name="" className="form-control mb-2" id="" value={this.state.typeExt} onChange={(e) => {
                                    this.state.typeExt = e.target.value
                                }}>
                                    <option value="pdf">PDF</option>
                                    <option value="csv">Excel</option>
                                </select>
                                <button type="button" className="listing-complete-btn soleilBold-fonts cursor" onClick={() => GetListePDF('travaux',  this.state.selectedId, this.state.typeExt)}>
                                    Exporter la sélection
                                    <span className="slt-nb">{ this.state.selected }</span></button>
                            </div>
                            <button type="button" className="listing-complete-btn soleilBold-fonts">Liste filtrée ( 0
                                )
                            </button>
                            <button type="button" className="listing-incomplete-btn soleilBold-fonts">Liste complète (
                                {this.state.total} )
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TravauxListe;