import React, { Component } from 'react';
import closeCross from '../../assets/images/close-cross.svg';
import notifPurple from '../../assets/images/notif-purple.svg';
import editIconPurple from '../../assets/images/edit-icon-purple.svg';
import archiveIcon from '../../assets/images/poubelle-icon.svg';
import btnPlusIcon from '../../assets/images/btn-plus-icon.svg';
import axios from "axios";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

class Notes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actives : [],
            archives : [],
            corbeille : [],
            titre : '',
            note : '',
            id: '',
            filtre : 'active',
            userId : null,
        }
    }

    componentDidMount() {
        this.getNotes()
    }

    getNotes = () => {
        axios.get(process.env.REACT_APP_API_URL +  '/notes/notesByEspace/' + localStorage.getItem('espace_id')).then((res) => {
                let actives = [];
                let archives = [];
                let corbeille = [];
                for(let note in res.data){
                    if(res.data[note].etatLabel === 'archivée') {
                        archives.push(res.data[note])
                    } else if (res.data[note].etatLabel === 'corbeille') {
                        corbeille.push(res.data[note])
                    } else {
                        actives.push(res.data[note])
                    }
                }
                this.setState({
                    actives : actives,
                    archives : archives,
                    corbeille : corbeille
                })
            }
        )
        axios.get(process.env.REACT_APP_API_URL + '/users/currentUser').then((res) => {
            this.setState({userId : res.data})
        })
    }

    archiverOuCorbeille = (note, etat) => {
        if (window.confirm('Etes vous sûr de vouloir supprimer cette note ?'))
        axios.put(process.env.REACT_APP_API_URL +  '/notes/' + note.id, {
            titre : note.titre,
            contenu : note.contenu,
            user : note.user,
            date : note.date,
            etat : etat,
            eSpace : note.eSpace
        }).then(() => {
            this.getNotes()
        })
    }

    emptyForm = () => {
        this.setState({
            id: '',
            titre: '',
            contenu: ''
        })
    }

    handleChange = (event)  =>{
        let name = event.target.name
        this.setState({[name] : event.target.value});
    }

    handleSubmit = () => {
        axios.post(process.env.REACT_APP_API_URL +  '/notes', {
            titre: this.state.titre,
            contenu: this.state.note,
            user: process.env.REACT_APP_API_URI + '/users/' + this.state.userId,
            date: new Date(),
            etat: 1,
            eSpace: process.env.REACT_APP_API_URI + '/e_spaces/' + localStorage.getItem('espace_id')
        }).then((res) => {
            let notes = this.state.actives
            notes.push(res.data)
            this.setState({
                actives: notes,
                titre: '',
                note: ''
            })
        })
    }

    modifierNoteForm = (note) => {
        this.setState({
            id: note.id,
            titre: note.titre,
            note: note.contenu
        })
    }

    modifierNote = () => {
        let note;
        let index;
        let notes = this.state.actives
        if(this.state.filtre === 'archive') {
            notes = this.state.archives
        } else if (this.state.filtre === 'corbeille') {
            notes = this.state.corbeille
        }
        for(let i in notes) {
            if(notes[i].id === this.state.id) {
                note = notes[i]
                index = i
            }
        }
        if (note) {
            axios.put(process.env.REACT_APP_API_URL + '/notes/' + note.id, {
                titre: this.state.titre,
                contenu: this.state.note,
                user: note.user,
                date: note.date,
                etat: note.etat,
                eSpace: note.eSpace
            }).then((res) => {
                notes[index] = res.data
                this.setState({
                    actives: notes,
                })
            })
        }
    }

    renderToolTip(text) {
        return (
            <Tooltip id="button-tooltip" className="tooltip">
                {text}
            </Tooltip>
        )
    }

    render() {
        require('../../assets/css/notes.css');

        let notesToDisplay = []
        if(this.state.filtre === 'archive') {
             notesToDisplay = this.state.archives
        } else if(this.state.filtre === 'corbeille') {
             notesToDisplay = this.state.corbeille
        } else {
             notesToDisplay = this.state.actives
        }

        let notes = notesToDisplay.map((note) => {
            return (
                <div className="note py-3 px-3 d-flex flex-column justify-content-between" key={note.id}>
                    <div className="note-content mt-2 ">
                        <OverlayTrigger placement="right" delay={{ show: 100, hide: 200 }} trigger="hover" overlay={this.renderToolTip('Supprimer')}>
                            <img src={closeCross} onClick={() => this.archiverOuCorbeille(note, 3)}
                                 className="img-fluid cursor float-right" width="20px" alt=""/>
                        </OverlayTrigger>
                        <p className="note-title purple soleilBold-fonts mb-2">{note.titre}</p>
                        <p className="purple soleilLight-fonts note-contenu">
                            {
                                (note.contenu.length > 160) ? note.contenu.slice(0, 160-1) + '...' : note.contenu
                            }
                        </p>
                    </div>
                    <div className="note-menu d-flex justify-content-lg-between mt-3">
                        <div>
                            <img src={notifPurple} className="img-fluid cursor" width="20px" alt=""/>
                        </div>
                        <div>
                            <OverlayTrigger placement="right" delay={{ show: 100, hide: 200 }} trigger="hover" overlay={this.renderToolTip('Modifier')}>
                                <img src={editIconPurple} className="img-fluid cursor mr-2" width="13px" alt="" data-toggle="modal" data-target="#ajoutNote" onClick={() => this.modifierNoteForm(note)}/>
                            </OverlayTrigger>
                            <OverlayTrigger placement="right" delay={{ show: 100, hide: 200 }} trigger="hover" overlay={this.renderToolTip('Archiver')}>
                                <img src={archiveIcon} className="img-fluid cursor" width="20px" alt="" onClick={() => this.archiverOuCorbeille(note, 2)}/>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <div className="contents content-margin">
                <div className="content-header px-3 px-lg-4 page2">
                    <span className="expand-btn">&#9776;</span>
                    <div className="d-flex align-items-center">
                        <div className="col-12 soleilBold-fonts title">
                            NOTES
                        </div>
                    </div>
                </div>
                <div className="notes-wrapper">
                    <div className="container-fluid px-0">
                        <div className="row">
                            <div className=" notes-container py-4 px-5 d-flex flex-column flex-wrap justify-content-center scroll" >
                                <div className="notes d-flex flex-wrap scroll" >
                                    {notes}
                                </div>
                                <div className="ml-2">
                                    <button type="button" className="add-new-location-btn" data-toggle="modal" data-target="#ajoutNote">
                                        Ajouter une note
                                        <img src={btnPlusIcon} alt="+" className="img-fluid ml-2" />
                                    </button>
                                </div>
                            </div>
                            <div className="col notes-menu">
                                <div className="d-flex mt-4" onClick={() => {this.setState({filtre : 'active'})}}>
                                    <label className="switch">
                                        <input type="checkbox" checked={this.state.filtre === 'active'}/>
                                            <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label  soleilBold-fonts">Dernières notes</div>
                                </div>
                                <div className="d-flex mt-4" onClick={() => {this.setState({filtre : 'archive'})}}>
                                    <label className="switch">
                                        <input type="checkbox" checked={this.state.filtre === 'archive'}/>
                                            <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts">Notes archivées</div>
                                </div>
                                <div className="d-flex mt-4" onClick={() => {this.setState({filtre : 'corbeille'})}}>
                                    <label className="switch">
                                        <input type="checkbox" checked={this.state.filtre === 'corbeille'}/>
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts">Corbeille</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="ajoutNote" tabIndex="-1" role="dialog" aria-labelledby="ajoutNote"
                     aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bk-grey">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="soleilBold-fonts purple ml-5 mt-2">NOUVELLE NOTE</p>
                                <div className="form-wrapper mt-3 ml-0" id="formAjoutNote">
                                    <div className="d-flex">
                                        <label className="col-4 soleilRegular-fonts">Titre</label>
                                        <input type="text" className="col-8 soleilRegular-fonts" name='titre' value={this.state.titre} onChange={this.handleChange}/>
                                    </div>
                                    <div className="d-flex mt-2" id="large-input">
                                        <label className="col-4 soleilRegular-fonts">Note</label>
                                        <textarea className="form-control col-8 soleilRegular-fonts textarea-filed note-textarea"
                                                  rows="3" name='note' value={this.state.note} onChange={this.handleChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="red cancel-btn tbl" data-dismiss="modal">Annuler</button>
                                {
                                    this.state.id ?
                                        <button type="button" className="tbl bk-red" data-dismiss="modal" onClick={this.modifierNote}>Valider</button>
                                        :
                                        <button type="button" className="tbl bk-red" data-dismiss="modal" onClick={this.handleSubmit}>Valider</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Notes;