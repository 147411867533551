import React, { Component } from 'react';
import redBellIcon from '../../assets/images/red-bell-icon.svg';
import Activation from "../../components/gestionCompte/Activation";
import axios from "axios";

class Importation extends Component{

    constructor(props) {
        super(props);
        this.state = {
            actif: null
        }
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + '/e_spaces/' + localStorage.getItem('espace_id')).then(res => this.setState({actif : res.data.actif}))
    }

    render() {
        return (
            <div className="container-fluid px-0">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-wrapper mt-3">
                            <div className="d-flex ">
                                <label className="col-4 soleilRegular-fonts">
                                    <img src={redBellIcon} alt="" className="img-fluid"/>&nbsp;
                                    Cimetière
                                </label>
                                <div className="select-grey-wrapper mb-3 col-6 ">
                                    <select className="form-select form-select-lg soleilRegular-fonts select-grey"
                                            aria-label=".form-select-lg example">
                                        <option selected>Cimetière essentiel</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Ancien logiciel</label>
                                <div className="select-grey-wrapper mb-3 col-6 ">
                                    <select className="form-select form-select-lg soleilRegular-fonts select-grey"
                                            aria-label=".form-select-lg example">
                                        <option selected disabled>Ancien logiciel</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </select>
                                </div>
                            </div>
                            <div className="ml-3">
                                <p className="sous-notes mt-2">Votre ancien logiciel n'est pas répertorié ? <br></br> <span
                                    className="soleilRegular-fonts purple">Contactez nous </span>
                                    et nous ferons le nécessaire.
                                </p>
                            </div>
                            <div className="select-file-wrapper mt-5">
                                <p className="soleilRegular-fonts ml-3">Avez-vous exporter la base de données de votre ancien fournisseur ?
                                    (format .xls) :</p>
                                <div className="browse-file mt-3">
                                    <label htmlFor="pdf-file-select" className="soleilRegular-fonts mr-3">
                                        Parcourir ...

                                    </label>
                                    <div className="select-pdf-file-name soleilRegular-fonts">Aucun fichier .xls
                                        sélectionné
                                    </div>
                                </div>
                            </div>
                            <p className="sous-notes mt-3">
                                Le RGPD impose aux fournisseurs de stockage de données personnelles de permettre
                                l'export de ces données pour garantir
                                leur portabilité. Votre fournisseur a l'obligation de vous fournir vos données dans un
                                format exploitable. Prenez contact
                                avec lui sans tarder.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-wrapper mt-3">
                            <p className="soleilBold-fonts small_title ml-3 mb-3">Liste des anciens imports</p>
                            <div className="table-responsive px-3 bg-white">
                                <table className="table table-striped table-borderless">
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Fichier</th>
                                        <th>Import</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="d-flex form-wrapper mt-3 justify-content-between align-items-center">
                            <p className="soleilBold-fonts small_title">Activation</p>
                            {
                                this.state.actif !== null ? <Activation actif={this.state.actif}/> : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Importation;