import { combineReducers } from "redux";
import documentReducer from "./documentReducer";
import societeReducer from "./societeReducer";
import userReducer from "./userReducer";
import defuntReducer from "./defuntReducer";
import emplacementReducer from "./emplacementReducer";

export default combineReducers({
  document: documentReducer,
  societe: societeReducer,
  user: userReducer,
  defunt: defuntReducer,
  emplacement: emplacementReducer
});