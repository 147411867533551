import React, { Component } from 'react';
import editIconWhite              from '../../assets/images/edit-white-icon.svg';
import FormAttributionEmplacement from "./FormAttributionEmplacement";
import FormEmplacement            from "./FormEmplacement";
import Tools                      from "../../components/utils/Tools";
import axios                      from "../../helpers/axios";
import EmplacementOccupe          from "./EmplacementOccupeDefault";
import EmplacementVide            from "./EmplacementVide";
import JardinDuSouvenirOssuaire   from "./EmplacementOccupeType/JardinDuSouvenirOuOssuaire";
import TerrainCommunCaveau        from "./EmplacementOccupeType/TerrainCommunOuCaveau";
import {connect}                  from "react-redux";
import TravauxFormulaire          from "../Travaux/TravauxFormulaire";
import dlDocProcedure             from "../../assets/images/dl-documents-procedures.svg"

class FicheConcession extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emplacementId : this.props.match.params.emplacementId,
            archivedConcessionId : this.props.match.params.archivedConcessionId || null ,
            travaux : [],
            travauxId : null,
            emplacement: {},
        }
    }

    componentDidMount() {
        Tools.navigationPopup(this.state.emplacementId);
        Tools.updateNameUploadFile();
        Tools.switchColorCheckbox();
        const { addListeDefunt } = this.props
        axios.get(process.env.REACT_APP_API_URL + '/emplacements/' +  (this.state.archivedConcessionId ? 'emplacementToutInfosArchived/' + this.state.archivedConcessionId  : 'emplacementToutInfos/' + this.state.emplacementId) ).then((response) => {
            this.setState({
                emplacement: response.data.emplacement,
                location : response.data.location,
                travaux : response.data.emplacement.travaux,
                procedures : response.data.procedures
            })
            const {updateEmplacement} = this.props
            updateEmplacement(response.data.emplacement)
            if (!this.state.archivedConcessionId && this.props.defunt.emplacement.hasOwnProperty('occupants')) {
                addListeDefunt( JSON.stringify(this.props.defunt.emplacement.occupants))
            }
        })
    }

    formatDate = (date) => {
        const d = new Date(date);
        return ('0' + d.getDate()).slice(-2) + '/'
            + ('0' + (d.getMonth()+1)).slice(-2) + '/'
            + d.getFullYear();
    }

    newTravaux = () => {
        this.setState({travauxId : null})
    }

    updateListeTravaux = (travaux) => {
        let listeTravaux = this.state.travaux
        let exist = false
        listeTravaux.map((travauxListe, i) => {
            if (travaux.id === travauxListe.id) {
                listeTravaux[i] = travaux
                exist = true
            }
        })
        if (!exist) {
            listeTravaux.push(travaux)
        }
        this.setState({travaux: listeTravaux})
    }

    render() {
        require('../../assets/css/formPop-up.css')
        require('../../assets/css/fiche_concession.css')
        let travaux = (
            <div className="d-flex mt-2 justify-content-between">
                <div className="pl-0 soleilRegular-fonts">
                    Aucun travaux en cours
                </div>
            </div>
        )

        if (this.state.travaux.count !== 0) {
            travaux = this.state.travaux.map((travaux) => {
                //si on veut afficher que les travaux en cours
                //if ((travaux.dateFin && new Date(travaux.dateFin.substr(0, 10)) > new Date()) || !travaux.dateFin) {
                    return (
                        <div className="d-flex mt-2 justify-content-between" key={travaux.id}>
                            <div className="d-flex">
                                <a href={process.env.REACT_APP_FILES_URL + travaux.document} target="_blank">
                                    <img src={dlDocProcedure} className="img-fluid mr-2" width="15px" alt=""/>
                                </a>
                                <div className="pl-0 soleilRegular-fonts cursor select-defunt step-btn travaux-form" data-toggle="modal"
                                     data-target="#etape5" id='5' data-travaux-id={travaux.id}
                                     onClick={() => this.setState({travauxId: travaux.id})}>{travaux.type}
                                </div>
                            </div>
                            <div
                                className="pl-0 soleilRegular-fonts">
                                {travaux.dateDebut ? this.formatDate(travaux.dateDebut.substr(0, 10)) : ''} -
                                {travaux.dateFin ? this.formatDate(travaux.dateFin.substr(0, 10)) : ''}
                            </div>
                        </div>
                    )
                //}
            })
        }

        return(
            <div>
                { this.props.defunt.emplacement ?
                    <React.Fragment>
                        <FormEmplacement emplacementId = {this.state.emplacementId}/>
                        <TravauxFormulaire emplacementId = {this.state.emplacementId} updateListeTravaux = {this.updateListeTravaux} travauxId = {this.state.travauxId}/>
                    </React.Fragment> : '' }
                { this.props.defunt.emplacement ?
                    <FormAttributionEmplacement emplacementId = {this.props.defunt.emplacement.id} surface = {this.props.defunt.emplacement.surface} locationId = {
                        this.state.location !== undefined ? this.state.location.id : null
                    }/>  : '' }
                <div className="contents content-margin">
                    <div className="content-header px-3 px-lg-4 page2">
                        <span className="expand-btn">&#9776;</span>
                        <div className="d-flex align-items-center">
                            <div className="col-12 soleilBold-fonts title d-flex align-items-center justify-content-between">
                                <div id="concession-tour-1">
                                    Emplacement { this.props.defunt.emplacement ? this.props.defunt.emplacement.libelle : ''}
                                    <span className="mx-3">|</span>
                                    <span className="soleilRegular-fonts sub-text">Surface&nbsp;
                                        { this.props.defunt.emplacement && this.props.defunt.emplacement.surface  ? this.props.defunt.emplacement.surface + 'm²' : 'non renseignée'}</span>
                                    <span className="mx-3">|</span>
                                    <span className="soleilRegular-fonts sub-text">Pré-réservation
                                        { this.props.defunt.emplacement && this.props.defunt.emplacement.preReservation ? ' possible' : ' impossible'}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-center align-items-center" style={{minWidth : '30px'}}>
                                    <span className="info cursor" data-container="body" data-toggle="popover"
                                          data-trigger="hover" data-placement="top"
                                          data-content={this.props.defunt.emplacement && this.props.defunt.emplacement.notes && this.props.defunt.emplacement.notes !== ''  ? this.props.defunt.emplacement.notes  : "Aucune note pour cet emplacement. Veuillez cliquer sur \"Editer emplacement\" pour en ajouter."}>
                                    </span>
                                </div>
                                <a data-toggle="modal" data-target="#modif-emplacement" className='white-border-button cursor d-block' id='emplacement-btn'>
                                    <img src={editIconWhite} alt="" className="img-fluid cursor mr-2 mb-1" />
                                    Éditer emplacement
                                </a>
                            </div>
                        </div>
                    </div>
                    { this.state.location ?
                        (
                            this.state.location.type === 'Jardin du souvenir' || this.state.location.type === 'Ossuaire' ?
                                <JardinDuSouvenirOssuaire emplacement = {this.state.emplacement} location = {this.state.location} travaux = {travaux} newTravaux = {this.newTravaux}/>
                                : this.state.location.type === 'Terrain commun' || this.state.location.type === 'Caveau' ?
                                <TerrainCommunCaveau emplacement = {this.state.emplacement} location = {this.state.location} travaux = {travaux} newTravaux = {this.newTravaux}/>
                                :
                                <EmplacementOccupe emplacement = {this.state.emplacement} location = {this.state.location} procedures = {this.state.procedures} travaux = {travaux} newTravaux = {this.newTravaux}/>
                        )
                        :
                        <EmplacementVide emplacement = {this.state.emplacement} location = {this.state.location} travaux = {travaux} newTravaux = {this.newTravaux}/>
                    }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addListeDefunt: (defunts) => dispatch({
            type: 'ADD_LISTE_DEFUNT',
            defunts : defunts
        }),
        updateEmplacement : (emplacement) => dispatch({
            type : 'UPDATE_EMPLACEMENT',
            emplacement : emplacement
        })
    }
}

const mapStateToProps = ({emplacement = null, exhumation = null, defunt = null, defuntListe = {}, defuntAjoute = 0,defuntAjouteId = null }) => ({
    exhumation,
    emplacement,
    defunt,
    defuntListe,
    defuntAjoute,
    defuntAjouteId
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FicheConcession);

