import {
  SET_ESPACE,
  SET_ESPACES,
  WRONG_CREDENTIAL,
  RESET_WRONG_CREDENTIAL,
  SET_COLUMN,
  GET_DOCUMENTS,
  GET_DOCUMENT,
  GET_PREVIEW_DOCUMENT,
  DOCUMENT_ERROR,
  CREATE_DOCUMENT,
  START_GETTING_DOCUMENT,
  START_GETTING_PREVIEW_DOCUMENT,
  DELETE_DOCUMENT,
  UPDATE_DOCUMENT,
} from "../actions/types";

import { fetchApi } from "../actions/index";
import update from 'react-addons-update';

function fetchLinks(data, links) {
  if (links) {
    links.forEach(link => {
      for (let i = 0; i < data.length; i++) {
        fetchApi({
          url: data[i][link].replace('/api/', '/'),
          type: 'UPDATE_' + i + '_' + link,

        })
      }
    })
  }
  return data;
}

const initialDocumentValues = {
  id: null,
  template: '',
};
const initialState = {
  documents: [],
  document: initialDocumentValues,
  previewDocument: '',
  loading: true,
  error: {},
};

export default function(state = initialState, action){
  const { type, payload, totalItems } = action;

  switch (type) {
    case GET_PREVIEW_DOCUMENT:
      return {
        ...state,
        previewDocument: payload
      };
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: payload,
        loading: false,
        totalItems: totalItems
      };
      case START_GETTING_DOCUMENT:
        return {
            ...state,
            document: { ...state.document, loading: true },
        };
        case GET_DOCUMENT:
          return {
              ...state,
              document: { ...payload, loading: false },
          };
          case CREATE_DOCUMENT:
            return {
                ...initialState,
                document: payload,
            };
            case DOCUMENT_ERROR:
              return {
                  ...state,
                  error: payload,
                  loading: false,
              };
              case DELETE_DOCUMENT:
                return {
                    ...initialState,
                };
                case UPDATE_DOCUMENT:

    case WRONG_CREDENTIAL:
      return {
        ...state,
        wrongCredential: true
      };
    case RESET_WRONG_CREDENTIAL:
      return {
        ...state,
        wrongCredential: false
      };
    case SET_ESPACES:
      return { data: action.payload };
    case SET_ESPACE:
      return { ...state, espace: action.payload };
    case SET_COLUMN:
      return { ...state, columns: action.payload };

    default:
      if (action.type.indexOf('SET_UPDATE') > -1) {
        const field = action.type.replace('SET_UPDATE_', '').split('_')
        return update(state, {
          contents: {
            [field[0]]: {
              [field[1]]: { $set: action.payload }
            }
          }
        });
      }
      else if (action.type.indexOf('FETCH_') > -1) {
        return {
          ...state,
          isLoadingData: false
        };
      } else if (action.type.indexOf('SET_') > -1) {
        let links = [];
        if (action.links) {
          links = action.links;
        }
        return { ...state, data: fetchLinks(action.payload["hydra:member"], links) };
      } else
        return state;
  }
}
