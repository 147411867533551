import React, { Component } from 'react';
import axios from "axios";

class Notifications extends Component {

    static notifier(procedure, username) {
        const data = {
            titre       : 'Première ' + procedure,
            description : 'L\'utilisateur ' + username + ' vient d\'effectuer sa première procédure de ' + procedure + '.',
            dateRecu    : new Date(),
            archive     : false,
            eSpace      : process.env.REACT_APP_API_URI + '/e_spaces/' + localStorage.getItem('espace_id')
        }
        axios.post(process.env.REACT_APP_API_URL + '/notifications', data)
        data.eSpace = localStorage.getItem('espace_id')
        data.dateRecu = data.dateRecu.toISOString().split('T')[0]
        axios.post(process.env.REACT_APP_ES + 'logicim_' + localStorage.getItem('espace_id') + '_notifications/_doc/', data)
    }

    static checkFirstProcedure(procedure) {
        let item = localStorage.getItem('procedures') ? JSON.parse(localStorage.getItem('procedures')) : null
        const currentUser = JSON.parse(localStorage.getItem('currentUser'))
        if (item) {
            if (procedure in item && item[procedure].includes(currentUser['user'])) {
                return true
            } else {
                if (!(procedure in item)) {
                    item[procedure] = []
                }
                if (item[procedure]) {
                    item[procedure].push(currentUser['user'])
                }
                localStorage.setItem('procedures', JSON.stringify(item))
                this.notifier(procedure, currentUser['user'])
            }
        } else {
            localStorage.setItem('procedures', JSON.stringify({
                [procedure] : [currentUser['user']]
            }))
            this.notifier(procedure, currentUser['user'])
        }
    }
}

export default Notifications;