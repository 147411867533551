import React, { Component } from 'react';
import {Nav, NavItem,NavLink} from "react-bootstrap";
import {TabContainer,TabContent,TabPane} from "react-bootstrap";
import AssistancesListe from "./AssistancesListe";
import '../../assets/css/listes.css'
import Plan from "../plan/Plan";
import FiltrerProcedure from "../../components/pagination/FiltrerProcedure";
import {Link} from "react-router-dom";

class AssistancesMenu extends Component {

    constructor(props) {
        super(props);
        this.emplacementListRef = React.createRef();
    }

    static defaultProps = {
        filters: {
            type: [],
            etat: [],
            date: ['', '', '', '']
        },
        sort: {}
    }

    render() {
        return(
            <div className="contents content-margin">
                <TabContainer id="left-tabs-example" defaultActiveKey="liste">
                    <div className="content-header px-3 px-lg-4">
                        <span className="expand-btn">&#9776;</span>
                        <div className="d-flex align-items-center">
                            <div className="col-6 soleilBold-fonts title">PROCÉDURES</div>
                            <div className="col-6 emp-buttons">
                                <Nav variant="pills" className="flex-row switch-plan-liste">
                                    <NavItem>
                                        <Link to={"/concessions"} style={{"textDecoration": "none"}}>
                                            <button type="button" className="plan-btn soleilBold-fonts">Plan</button>
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink eventKey="liste">
                                            <button type="button" className="liste-btn soleilBold-fonts">Liste</button></NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </div>
                    </div>
                    <div className="table-filter-wrapper px-3 px-lg-4 pt-3 pb-2">
                        <FiltrerProcedure title="FILTRER LES PROCEDURES" filters={this.props.filters} emplacementListRef={this.emplacementListRef} />
                    </div>
                    <TabContent>
                        <TabPane eventKey="plan">
                            <Plan/>
                        </TabPane>
                        <TabPane eventKey="liste">
                            <AssistancesListe ref={this.emplacementListRef} filters={this.props.filters} sort={this.props.sort}/>
                        </TabPane>
                    </TabContent>
                </TabContainer>
            </div>
        )
    }
}

export default AssistancesMenu;