export default class workspace {
    static changeWorkspace = (props, value, force) => {
        let [espaceId, societeId] = value.split('--')
        localStorage.setItem("societe_id", societeId)
        localStorage.setItem("espace_id", espaceId)

        props.fetchApi({
            url: "/sessions/eSpace",
            method: "POST",
            data: {
                eSpace: espaceId
            },
            type: "ESPACE",
            onSuccess: () => {
                props.fetchApi({
                    url: "/sessions/societe",
                    method: "POST",
                    data: {
                        societe: societeId
                    },
                    type: "SOCIETE",
                    onSuccess: () => {
                        console.log('success')
                        console.log(force)
                        if (force) {
                            console.log(force, 'force')
                            window.location.reload()
                        }
                        props.history.push('/concessions')
                    }
                })
            }
        })
    }
}
