import React, { Component } from 'react';
import axios from "../../helpers/axios";
import $ from 'jquery';
import '../../assets/css/formDefunt.css';
import {connect} from "react-redux";
import DateTools from "../../components/utils/DateTools";
import {getEmplacement} from "../../actions/emplacements";

class FormulaireCivilite extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formDefunt : [],
            criteresSpe : false,
            critereSpeValues : [],
            location: {},
            value : '',
            locationId : this.props.location.id,
            defuntActuel : null,
            defuntAjoute : null,
        };
    }

    componentDidMount() {
        if (!this.state.location.isArchived) {
            this.addForm('/contacts/occupant/form/civilite/' + this.state.locationId)
        }
    }

    emptyForm() {
        $('.ligne').removeClass('fait')
        $('.jalon.s1').addClass('fait')
        $('.modal-step-content').hide()
        $('.etape1').show()
        $('.btn-navigation').hide()
        $('.suivant').show()
        $('.empty-required').removeClass('empty-required')
        $('#error').text('')
    }

    addForm = (url) => {
        axios.get(process.env.REACT_APP_API_URL + url)
            .then((response) => {
                this.setState({formDefunt: response.data})
                const defunt_inhumCrema = document.querySelector('#defunt_inhumCrema')
                if(defunt_inhumCrema) {
                    defunt_inhumCrema.addEventListener('change', function () {
                        let visibleCLasse = document.querySelector('#defunt_inhumCrema').value
                        if (visibleCLasse === 'inhumation') {
                            document.getElementById('inhumation').classList.remove('display-none')
                            document.getElementById('cremation').classList.add('display-none')
                        } else {
                            document.getElementById('cremation').classList.remove('display-none')
                            document.getElementById('inhumation').classList.add('display-none')
                        }
                    });
                }

                const cremationDemandePar = document.getElementById('cremationDemandePar')
                if(cremationDemandePar) {
                    cremationDemandePar.addEventListener('change', function () {
                        let demandeur = this.value
                        if (demandeur === 'autre-personne') {
                            document.getElementById('cremation-form').classList.remove('display-none')
                        } else {
                            document.getElementById('cremation-form').classList.add('display-none')
                        }
                    })
                }

                const cremation_destinationUrneCendre = document.getElementById('cremation_destinationUrneCendre')
                if(cremation_destinationUrneCendre) {
                    cremation_destinationUrneCendre.addEventListener('change', function () {
                        let destinationUrne = this.value
                        document.getElementById("cremation_inhum_scellement").classList.add('display-none')
                        if (destinationUrne === 'Dispersion en pleine nature sauf voies publiques') {
                            document.querySelectorAll('#dispersion-champs, #date-dispersion').forEach((element) => element.classList.remove('display-none'))
                        } else if (destinationUrne === 'Dispersion en Jardin du Souvenir') {
                            document.getElementById('date-dispersion').classList.remove('display-none')
                        } else {
                            document.getElementById("cremation_inhum_scellement").classList.remove('display-none')
                            document.querySelectorAll('#dispersion-champs, #date-dispersion').forEach((element) => element.classList.add('display-none'))
                        }
                    })
                }

                $(document).on('change','#dateDeNaissance select, #dateDeDeces select', () => {
                   this.calculAge()
                })

                if (this.props.location.type === 'Jardin du souvenir') {
                    const defunInhumCrema = document.getElementById('defunt_inhumCrema')
                    if (defunInhumCrema) {
                        defunInhumCrema.value = "cremation";
                        defunInhumCrema.classList.add('disable-field')
                    }
                    const cremation = document.getElementById('cremation')
                    cremation && cremation.classList.remove('display-none')
                    const inhumation = document.getElementById('inhumation')
                    inhumation && inhumation.classList.add('display-none')
                    const cremationUrne =  document.getElementById('cremation_destinationUrneCendre')
                    if (cremationUrne) {
                        cremationUrne.value = "Dispersion en Jardin du Souvenir"
                        cremationUrne.classList.add('disable-field')
                    }
                    const dateDispersion = document.getElementById('date-dispersion')
                    dateDispersion && dateDispersion.classList.remove('display-none')
                }

                if (this.props.location.type !== 'Jardin du souvenir' && this.props.location.type !== 'Dispersion pleine nature' && document.querySelector("#cremation_destinationUrneCendre")) {
                    const choixToRemoveJDS = document.querySelector("#cremation_destinationUrneCendre option[value='Dispersion en Jardin du Souvenir']")
                    if(choixToRemoveJDS) {
                        choixToRemoveJDS.remove()
                    }
                    const choixToRemoveDispersion = document.querySelector("#cremation_destinationUrneCendre option[value='Dispersion en pleine nature sauf voies publiques']")
                    if(choixToRemoveDispersion) {
                        choixToRemoveDispersion.remove()
                    }
                }

                const champsDateCremation = document.getElementById('cremation_dateCremation')
                if(champsDateCremation) {
                    champsDateCremation.addEventListener('change', (e) => {
                        document.querySelector('.formulaire.defunt #error').innerText = ''
                        let dateDeces = null
                        const decesYear  = document.getElementById('defunt_dateDeces_year').value
                        const decesMonth = document.getElementById('defunt_dateDeces_month').value
                        const decesDay   = document.getElementById('defunt_dateDeces_day').value
                        if(decesDay && decesMonth && decesYear) {
                            dateDeces = decesYear + '-' + decesMonth + '-' + decesDay
                            if(!DateTools.compareDate(dateDeces, e.target.value)) {
                                e.target.value = ''
                                document.querySelector('.formulaire.defunt #error').innerText = 'La date de crémation doit être supérieur à la date de décès.'
                            }
                        } else if (decesYear) {
                            if (decesYear > new Date(e.target.value).getFullYear()) {
                                document.querySelector('.formulaire.defunt #error').innerText = 'La date de crémation doit être supérieur à la date de décès.'
                            }
                        }
                    })
                }

                const champsDateDisperson = document.getElementById('cremation_dateDispersion')
                if(champsDateDisperson) {
                    champsDateDisperson.addEventListener('change', (e) => {
                        document.querySelector('.formulaire.defunt #error').innerText = ''
                        let dateDeces = null
                        const dateDispersion = e.target.value
                        const dateCremation = champsDateDisperson.value
                        const decesYear  = document.getElementById('defunt_dateDeces_year').value
                        const decesMonth = document.getElementById('defunt_dateDeces_month').value
                        const decesDay   = document.getElementById('defunt_dateDeces_day').value
                        if(decesDay && decesMonth && decesYear) {
                            dateDeces = decesYear + '-' + decesMonth + '-' + decesDay
                        }
                        if(dateCremation) {
                           if(!DateTools.compareDate(dateCremation, dateDispersion)) {
                               e.target.value = ''
                               document.querySelector('.formulaire.defunt #error').innerText = 'La dispersion doit avoir lieu après la crémation.'
                           }
                        }
                        if(dateDeces) {
                            if(!DateTools.compareDate(dateDeces, dateDispersion)) {
                                e.target.value = ''
                                document.querySelector('.formulaire.defunt #error').innerText = 'La date de dispersion doit être supérieur à la date de décès.'
                            }
                        }
                    })
                }

                this.addDocumentType('documentsFile')
                const ajoutUploadDoc = document.querySelector('#documents-ajout')
                if(ajoutUploadDoc) {
                    ajoutUploadDoc.addEventListener('click',  () => {
                        this.addDocumentType('documentsFile')
                    })
                }
            })
    }

    calculAge = () => {
        if (this.state.formDefunt) {
            const naissanceDay = $("#defunt_dateDeNaissance_day").val()
            const naissanceMonth = $("#defunt_dateDeNaissance_month").val()
            const naissanceYear = $("#defunt_dateDeNaissance_year").val()
            const decesDay = $("#defunt_dateDeces_day").val()
            const decesMonth = $("#defunt_dateDeces_month").val()
            const decesYear = $("#defunt_dateDeces_year").val()

            if (naissanceDay && naissanceMonth && naissanceYear && decesDay && decesMonth && decesYear) {
                let age = decesYear - naissanceYear
                let m = decesMonth - naissanceMonth;
                if (m < 0 || (m === 0 && decesDay < naissanceDay)) {
                    age--;
                }
                $('#age').val(age);
            } else if (naissanceYear && decesYear) {
                $('#age').val(decesYear - naissanceYear)
            }
        }
    }

    disableField = () => {
        document.querySelectorAll("form[name='defunt'] .etape4 input:not(#inhumation_date),form[name='defunt'] .etape4 select")
            .forEach((element) => element.classList.add('disable-field'))

        const defuntInhumCrema = document.getElementById('defunt_inhumCrema')
        const inhumation = document.getElementById("inhumation")

        if (defuntInhumCrema && defuntInhumCrema.value === 'cremation') {
            const cremation = document.getElementById("cremation")
            const cremationUrneCendre = document.getElementById('cremation_destinationUrneCendre')
            cremation && cremation.classList.remove('display-none')
            inhumation && inhumation.classList.add('display-none')
            if(cremationUrneCendre && cremationUrneCendre.value === 'Dispersion en pleine nature sauf voies publiques') {
                document.getElementById('dispersion-champs').classList.remove('display-none')
            } else if (cremationUrneCendre && cremationUrneCendre.value === 'Dispersion en Jardin du Souvenir') {
                document.getElementById('date-dispersion').classList.remove('display-none')
            }
        } else if (defuntInhumCrema && defuntInhumCrema.value !== 'cremation') {
            inhumation && inhumation.classList.remove('display-none')
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.defuntId !== this.state.defuntActuel) {
            let url
            this.emptyForm()
            if (this.props.defuntId) {
                url = '/contacts/occupant/form/civilite/' + this.state.locationId + '/' + this.props.defuntId
                axios.get(process.env.REACT_APP_API_URL + url)
                    .then((response) => {
                        const defunt = this.props.defunt.defunt ? JSON.parse(this.props.defunt.defunt) : null
                        if(defunt && defunt.occupant.hasOwnProperty('criteresSpecifiques') && defunt.occupant.criteresSpecifiques[0] !== "") {
                            this.setState({
                                critereSpeValues : defunt.occupant.criteresSpecifiques,
                                criteresSpe: true
                            })
                            const criteresInput = document.getElementById('criteresSpecifiquesField')
                            if(criteresInput) {
                                criteresInput.value = defunt.occupant.criteresSpecifiques.toString()
                            }
                        }
                        this.setState({
                            defuntAjoute : null
                        })
                        this.setState({formDefunt: response.data})
                        if (!$('#defunt_dateDeNaissance_year').val()) {
                            $('#dateDeNaissance select option:selected').each(function () {
                                if (this.value !== 'undefined') {
                                    this.value = $(this).parent().attr('value')
                                }
                            })
                        }
                        $('#dateDeDeces select option:selected').each(function () {
                            this.value = this.innerText
                        })
                        this.addDocumentType('documentsFile')
                        this.calculAge()
                        this.disableField()
                        const ajoutUploadDoc = document.querySelector('#documents-ajout')
                        if (ajoutUploadDoc) {
                            ajoutUploadDoc.addEventListener('click', () => {
                                this.addDocumentType('documentsFile')
                            })
                        }
                    })
            } else {
                url = '/contacts/occupant/form/civilite/' + this.state.locationId
                this.addForm(url)
                this.setState({
                    critereSpeValues : [],
                    criteresSpe: false,
                    defuntAjoute: null
                })
            }
            this.setState({ defuntActuel : this.props.defuntId })
        }
    }

    handleChange = (e) => {
        this.setState({value: e.target.value});
    }

    ajoutCritere(value) {
        let critereSpeValues = this.state.critereSpeValues
        if (value !== '' && !critereSpeValues.includes(value)) {
            critereSpeValues.push(value)
            this.setState({
                critereSpeValues: critereSpeValues,
                value : ''
            })
            document.getElementById('criteresSpecifiquesField').value +=  value + ', '
        }
    }

    ajouterCritere = () => {
        this.ajoutCritere(this.state.value)
    }

    handleChangeCritere = (e) => {
        this.ajoutCritere(e.target.value)
    }

    supprimerCritere = (e) => {
        let value = e.target.getAttribute('data-key')
        let critereSpeValues = this.state.critereSpeValues
        const index = critereSpeValues.indexOf(value);
        if (index > -1) {
            critereSpeValues.splice(index, 1);
        }
        this.setState({critereSpeValues: critereSpeValues})
    }

    ajoutDefunt = () => {
        let checkboxValidation = document.getElementById('valid_input_defunt');
        let postDatas = $(".formulaire.defunt form").serialize();
        if (checkboxValidation !== null) {
            if (checkboxValidation.checked === true)
            {
                postDatas += '&generateFile=1';
            }

            $('.modal').modal('hide')
            const submit = document.querySelector(".formulaire.defunt").getAttribute('data-submit')
            postDatas += '&critereSpecifique=' + JSON.parse(JSON.stringify(this.state.critereSpeValues))
            postDatas += '&valider=1';
            axios.post(submit, postDatas).then((response) => {
                let service = 'add'
                if (this.props.defuntId || this.state.defuntAjoute) {
                    service = 'update'
                }
                if ((response.data.cremation && response.data.cremation.destinationUrneCendre !== "Scellement sur le monument de la sépulture") || !response.data.cremation) {
                    const ajoutPlaceOccupee = this.props.handlePlaceOccupee
                    if (ajoutPlaceOccupee && !this.state.defuntActuel) {
                        ajoutPlaceOccupee(1, 'add')
                    }
                }
                this.setState({
                    defuntAjoute: response.data.occupant.id,
                    defuntActuel: response.data.occupant.id
                })
                this.props.updateDefunt(response.data)
                this.props.addDefunt(response.data, service)
                this.disableField()
            })
        }
    }

    addDocumentType (collection) {
        const $tagsCollectionHolder = $('ul.' + collection);
        $tagsCollectionHolder.data('index', $tagsCollectionHolder.find('input').length);
        let $collectionHolder = $('.' + collection);
        const maxItem = $collectionHolder.find('.form-wrapper-defunt-documents').length;
        const dataPrototype = $collectionHolder.data('prototype')
        let prototype = null
        if (dataPrototype) {
            prototype = dataPrototype.replaceAll('__name__', maxItem)
        }
        if (prototype) {
            let newForm = $(prototype);
            newForm.find('.vich-file').addClass('d-none dl-actes form_document')
            newForm.find('.vich-file').attr('data-mapping', 'documents')
            let formInput = $(document.createElement('div', {
                class: 'd-flex mt-2 file-select mt-4'
            }))
            formInput.append(
                "<div class=\"d-flex mb-1 file-select\">\n" +
                "                <div class=\"d-flex align-items-center\">\n" +
                "                    <div class=\"d-flex p-0 m-0 upload-wrapper form-wrapper-defunt-documents align-items-center\">\n" +
                "                         <label for='defunt_" + collection + "_" + maxItem + "_genericFile_file' class=\"mt-1 mr-3 add-doc-btn dl-actes\">\n" +
                "                              Choisir un fichier\n" +
                "                          </label>\n" +
                "                          <p class=\"select-pdf-file-name soleilRegular-fonts\">Aucun fichier sélectionné</p>\n" +
                "                          <select id='defunt_" + collection + "_" + maxItem + "' type='number' name='defunt[" + collection.replace('File', '') + "][" + maxItem + "]' hidden></select>\n"
                + newForm.html() +
                "                     </div>\n" +
                "                  </div>"
            )
            $collectionHolder.append(formInput)
        }

    }

    render() {
        let url = '/contacts/occupant/form/civilite/' + this.state.locationId;
        if (this.props.defuntId) {
            url += '/' + this.props.defuntId
        } else if (this.state.defuntAjoute){
            url += '/' +this.state.defuntAjoute
        }
        return (
            <React.Fragment>
                <div className="modal fade procedure-modal modal-large" id="etape2" tabIndex="-1" role="dialog"
                     aria-labelledby="etape2" aria-hidden="true">
                    <div className="modal-dialog  d-flex justify-content-center align-items-center modal-wrap"
                         role="document">
                        <div className="modal-content">
                            <div className="modal-header bk-grey">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-body-top">
                                    <p className="soleilBold-fonts title purple">civilité du défunt</p>
                                    <div className="progression">
                                        <span className="jalon s1 fait">
                                            <div className="desc">Vérification préalable</div>
                                        </span>
                                        <span className="ligne s2"/>
                                        <span className="jalon">
                                            <div className="desc">Civilité</div>
                                        </span>
                                        <span className="ligne s3"/>
                                        <span className="jalon">
                                            <div className="desc">Naissance Décès</div>
                                        </span>
                                        <span className="ligne s4"/>
                                        <span className="jalon">
                                            <div className="desc">Inhumation Crémation</div>
                                        </span>
                                        <span className="ligne s5"/>
                                        <span className="jalon">
                                            <div className="desc">Infos diverses</div>
                                        </span>
                                        <span className="ligne s6 last"/>
                                        <span className="jalon">
                                            <div className="desc">Vérification Validation</div>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-wrapper scroll-popup">
                                    <div className="modal-step-content etape5 display-none">
                                        <div className="d-flex my-3 mx-5">
                                            <label htmlFor="criteres" className="col-md-3 p-0 m-0">Critères spécifiques</label>
                                            <div className="select-grey-wrapper col-2 p-0">
                                                <select className=" soleilRegular-fonts select-grey" value={this.state.criteresSpe ? 'oui' : 'non'} onChange={() => this.setState({criteresSpe: !this.state.criteresSpe})}
                                                        name="criteres" id="criteres">
                                                    <option value="oui">Oui</option>
                                                    <option value="non">Non</option>
                                                </select>
                                            </div>
                                        </div>
                                            {
                                                (this.state.criteresSpe)
                                                    ?
                                                    <>
                                                        <div className="d-flex mx-5">
                                                            <label htmlFor="critereAjoutListe" className='soleilRegular-fonts col-md-4 p-0 m-0'>Selectionnez un ou des critères spécifiques</label>
                                                            <div className="select-grey-wrapper col-4 p-0">
                                                                <select name="critereAjoutListe" id="critereAjoutListe" className='purple select-grey' onChange={this.handleChangeCritere}>
                                                                    <option value=''/>
                                                                    <option value="Mort pour la France">Mort pour la France</option>
                                                                    <option value="Militaire">Militaire</option>
                                                                    <option value="Mort pour le service de la Nation">Mort pour le service de la Nation</option>
                                                                    <option value="Personne célèbre">Personne célèbre</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mx-5 mt-2">
                                                            <label htmlFor="critereAjout" className='soleilRegular-fonts col-md-4 p-0 m-0'>Autres critères spécifiques</label>
                                                            <input type="text" id="critereAjout"
                                                                   name="critereAjout"
                                                                   className='soleilRegular-fonts col-md-4 mr-3'
                                                                   value={this.state.value}
                                                                   onChange={this.handleChange} />
                                                            <button className="add-new-location-btn"
                                                                    onClick={this.ajouterCritere}>
                                                                Ajouter
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    ''
                                            }
                                            {
                                                (this.state.critereSpeValues.length > 0)
                                                    ?
                                                    <div className="mt-5 mx-5" id="criteres-liste">
                                                        <p>Critère(s) spécifique(s) ajouté(s) : </p>
                                                        <ul className="list-group">
                                                            {
                                                                this.state.critereSpeValues.map((value) => {
                                                                    return <li className="list-group-item" key={value}><p>{value}</p>
                                                                        <span className="supprimer-critere" data-key={value} onClick={this.supprimerCritere}/></li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: this.state.formDefunt}}
                                        className="formulaire defunt" data-submit={process.env.REACT_APP_API_URL + url}
                                    />
                                    <div className="modal-footer navigation mt-5 p-0">
                                        <div>
                                            <button type="button"
                                                    className="tbl bk-red precedent display-none">Précédent
                                            </button>
                                        </div>
                                        <div>
                                            <button className="red cancel-btn tbl" data-dismiss="modal">Annuler</button>
                                            <button type="button" className="tbl bk-red ml-2 suivant">Suivant</button>
                                            <button type="submit" className="tbl btn-navigation bk-red display-none ml-2 defunt preview">Previsualiser</button>
                                            <button type="submit" className="tbl btn-navigation bk-red display-none ml-2 defunt valider" onClick={this.ajoutDefunt}>Valider</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addDefunt: (defunt, service) => {
            try {
                dispatch({
                    type: 'ADD_DEFUNT',
                    defunt: defunt,
                    service: service
                })
            } catch {
                getEmplacement(this.props.location.id, dispatch)
            }

        }
    }
}

const mapStateToProps = ({ defunt = null, defuntListe = {}, defuntAjoute = 0, defuntAjouteId = null }) => ({
    defunt,
    defuntListe,
    defuntAjoute,
    defuntAjouteId
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormulaireCivilite);