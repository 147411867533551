import React, { Component } from 'react';
import tutorielIcon from '../../assets/images/tutoriel-icon.svg'
import '../../assets/css/Tutoriels.css'
import tutoriels from "../../components/tutorisarion/tutoriels.json";
import Tour from "../../components/tutorisarion/Tour";

class Tutoriels extends Component {
    render() {
        const tutos = tutoriels.map(  tuto => {
            let toto =  require('../../components/tutorisarion/tuto-steps/'+ tuto.etapes)
            return (
                    <Tour steps={toto.default} title={tuto.titre} etapes={tuto.etapes}/>
                )
            })
        return(
            <div className="container-fluid px-4 bg-white pt-4">
                <div
                    className="tutoriel-wrapper d-flex justify-content-center align-items-center flex-column pt-5 pb-5 mt-3 mx-3">
                    <div className="row mt-3 mx-4">
                        <img src={tutorielIcon} alt="" className="img-fluid mr-5" width="70px"/>
                        <div className="d-flex justify-content-center flex-column">
                            <p className="mb-0 soleilBold-fonts mb-2"> Pour bien démarrer avec <span
                                className="soleilRegular-fonts">logicim</span></p>
                            <p className="mb-0 soleilBold-fonts"> Nous avons conçu un tutoriel présentant ses
                                principales fonctionnalités</p>
                        </div>
                    </div>
                    <div className="row mt-5 d-flex flex-column align-items-center">
                        <p className="soleilRegular-fonts mb-2" id="title-sentence">Laissez-vous guidez ...</p>
                        <button className="btn-start soleilRegular-fonts" onClick={() => document.getElementById('menu-steps').click()}>Démarrer le TUTORIEL</button>
                    </div>
                </div>
                <div className="mt-3 mx-5 py-4 d-flex flex-wrap justify-content-center tutos-liste">
                    {tutos}
                </div>
            </div>
        )
    }
}

export default Tutoriels;