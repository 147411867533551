import { SET_SOCIETE, RESET_SOCIETE } from "../actions/types";
import { fetchApi } from "../actions/index";
import update from 'react-addons-update';

function fetchLinks(data, links) {
    if (links) {
        links.forEach(link => {
            for (let i = 0; i < data.length; i++) {
                fetchApi({
                    url: data[i][link].replace('/api/', '/'),
                    type: 'UPDATE_' + i + '_' + link,

                })
            }
        })
    }

    return data;
}

const initialState = {
    loading: true,
    currentSociete: {},
    error: {},
};

export default function(state = initialState, action) {
    const { type } = action;
    switch (type) {
        case RESET_SOCIETE:
            return { ...state, currentSociete: null };
        case SET_SOCIETE:
            return { ...state, currentSociete: action.payload };

        default:
            if (action.type.indexOf('SET_UPDATE') > -1) {
                const field = action.type.replace('SET_UPDATE_', '').split('_')
                return update(state, {
                    contents: {
                        [field[0]]: {
                            [field[1]]: { $set: action.payload }
                        }
                    }
                });
            }
            else if (action.type.indexOf('FETCH_') > -1) {
                return {
                    ...state,
                    isLoadingData: false
                };
            } else if (action.type.indexOf('SET_') > -1) {
                let links = [];
                if (action.links) {
                    links = action.links;
                }
                return { ...state, data: fetchLinks(action.payload["hydra:member"], links) };
            } else
                return state;
    }
}
