import React, { Component } from 'react';
import btnPlusIcon from "../../assets/images/btn-plus-icon.svg";
import {Modal} from "react-bootstrap";
import SearchEmplacement from "../search/SearchEmplacement";
import {Link} from "react-router-dom";

class AjoutNouveau extends Component {

    constructor(props) {
        super(props);
        this.state = {
            etat: this.props.etat,
            show : false,
            id   : null,
        }
    }

    updateId = (id) => {
        this.setState({id: id})
    }

    render() {
        return (
            <>
                <div className="text-center text-sm-left">
                    <button type="button" className="add-new-location-btn" onClick={() => this.setState({show: true})}>
                        Ajouter nouveau
                        <img src={btnPlusIcon} alt="+" className="img-fluid ml-2"/>
                    </button>
                </div>
                <Modal show={this.state.show} onHide={() => {this.setState({show: false})}}>
                    <Modal.Header className="bk-grey" closeButton>
                        Ajouter une concession
                    </Modal.Header>
                    <Modal.Body>
                        <div className="py-4">
                            <p>Choisissez l'emplacement sur lequel vous souhaitez ajouter une concession :</p>
                            <div className="d-flex justify-content-center mt-2">
                                <div className="recherche-deplacement recherche-deplacement-liste mt-2 w-100 px-2">
                                    <SearchEmplacement id="recherche-emplacement-liste" libelle={this.state.etat} updateId={this.updateId}/>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="red cancel-btn tbl" onClick={() => {this.setState({show : false})}}>
                            Annuler
                        </button>
                        {
                            this.state.id ?
                                <Link to={{pathname: "/fiche-concession/" + this.state.id}}
                                      className="tbl bk-red ml-2"
                                >
                                    Valider
                                </Link>
                            : ''
                        }
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
export default AjoutNouveau;