import React, { Fragment } from 'react';
import loadingSecondary from './loading-secondary.gif';

const LoadingSpinner = ({ width = '5rem' }) => {
    return (
        <Fragment>
        <img
            src={loadingSecondary}
            style={{ width, display: 'block' }}
            alt='Loading...'
        />
    </Fragment>
    )
}

export default LoadingSpinner;
