import infoWhiteIcon from '../../assets/images/info-icon-white.svg';
import React, { Component } from 'react';
import axios from "axios";

class Securite extends Component{

    constructor(props) {
        super(props);
        this.state= {
            email: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).email : '',
            message: '',
        }
    }

    sendMail = () => {
        axios.post(process.env.REACT_APP_API_URL + '/reset-password', {
            email: this.state.email
        }).then( res => {
            this.setState({message: res.data})
        }).catch( () => {
            this.setState({ message: 'Un problème est survenu. Veuillez réessayer ultérieurement ou contacter un conseiller.'})
        })
    }

    render() {
        return(
            <div className="container-fluid form-wrapper mt-3">
                <div className="info-security-content row">
                    <div className="d-lg-inline-flex align-items-center mb-3">
                        <img src={infoWhiteIcon} alt="" className="img-fluid mr-2" width="25px"/>
                            <p className="soleilBold-fonts mt-2 ">Attention</p>
                    </div>
                    <p className="soleilBook-fonts">
                        Les actions effectuées sur cette page modifient les paramètres de sécurité de votre compte.
                        <br/>
                            En cas de perte ou de vol de votre smartphone ou de votre ordinateur, stockez vos mots de
                            passe
                            et la liste de vos codes de secours
                            à usage unique dans un endroit sécurisé. Ne divulguez aucune information confidentielle.
                    </p>
                </div>
                <div className="row mt-xl-5 modif-security">
                    <div className="col-5">
                        <p className="soleilBold-fonts"> Mot de passe du compte </p>
                    </div>
                    <div className="col-5">
                        <button data-toggle="modal" data-target="#changeMdpModal">Modifier</button>
                    </div>
                </div>
                <p className="text-center">{this.state.message}</p>

                <div className="modal fade" id="changeMdpModal" tabIndex="-1" role="dialog"
                     aria-labelledby="changeMdpModal"
                     aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bk-grey">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="soleilBold-fonts purple mt-2 mb-3">CHANGER MON MOT DE PASSE</p>
                                {
                                    this.state.email ?
                                    <div className="px-2">
                                        <p className="mb-2">
                                        Vous allez procéder au changement du mot de passe de votre compte
                                        </p>
                                        <p>
                                        Un email vous sera envoyé à l'adresse <strong>{this.state.email}</strong> vous
                                        indiquant la
                                        procédure à suivre pour réaliser ce changement.
                                        </p>
                                    </div>
                                    :
                                    <div className="px-2">
                                        <p className="mb-2">
                                            Aucune adresse mail trouvée pour l'utilisateur
                                        </p>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <button className="red cancel-btn tbl" data-dismiss="modal">Annuler</button>
                                {
                                    this.state.email ?
                                        <button type="button" className="tbl bk-red"
                                                onClick={this.sendMail} data-dismiss="modal">Valider</button>
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Securite;