import axios from "axios";
import DateTools from "./DateTools";
import DownloadSnappy from "./DownloadSnappy";

const ExportListe = (objet, data, type) => {
    console.log(objet)
    console.log(data)
    console.log(type)
    if(data && data != 'csv') {
        getSelectedPDF(objet, data, type)
    } else {
        getAllPDF(objet, type)
    }
}

const getSelectedPDF = (objet, data, type) => {
    let typeStr = ''
    console.log(type)
    if (type) {
        typeStr = `?type=${type}`
    }
    console.log(typeStr)
    axios.get(process.env.REACT_APP_API_URL + '/export/' + objet + typeStr, {
        params: {
            liste : JSON.stringify(data),
        },
        responseType: 'arraybuffer'
    }).then(response => {
        let ext = '.pdf'
        if (type === 'csv') {
            ext = '.csv'
        }
        const nom = 'export' + Math.floor(Math.random() * 10000) + '_' + objet + '_' + DateTools.formatDate(new Date().toString()) + ext
        DownloadSnappy(response, nom, type)
    }).catch(error => {
        console.log(error)
    })
}

const getAllPDF = (objet, type) => {
    let typeStr = ''
    if (type) {
        typeStr = `?type=${type}`
    }
    let ext = '.pdf'
    if (type === 'csv') {
        ext = '.csv'
    }
    axios.get(process.env.REACT_APP_API_URL + '/export/all/' + objet + '/' + localStorage.getItem('espace_id')  + typeStr, {
        responseType: 'arraybuffer'
    }).then(response => {
        const nom = 'export' + Math.floor(Math.random() * 10000) + '_' + objet + '_' + DateTools.formatDate(new Date().toString()) + ext
        DownloadSnappy(response, nom, type)
    }).catch(error => {
        console.log(error)
    })
}

export default ExportListe;