import React, { Component } from 'react';
import {Nav, NavItem,NavLink} from "react-bootstrap";
import {TabContainer,TabContent,TabPane} from "react-bootstrap";
import ConcessionsListe from "./ConcessionsListe";
import iconSubmenu from '../../assets/images/filter-icon-submenu.svg';
import '../../assets/css/listes.css'
import Plan from "../plan/Plan";
import FiltrerConcession from "../../components/pagination/FiltrerConcession";

class ConcessionsMenu extends Component {

    constructor(props) {
        super(props);
        this.emplacementListRef = React.createRef();
        this.mapRef = React.createRef();
    }
    static defaultProps = {
        filters: {
            etatConcession: [],
            duree: [],
            date: [
                '', '', '', '',
            ]
        },
        sort: {}
    }

    handleFilterEmplacementChange = function(event, type) {
        const target = event.currentTarget;

        if (target.checked && this.props.filters[type].indexOf(target.value) === -1) {
            this.props.filters[type].push(target.value);
        }

        if (!target.checked && this.props.filters[type].indexOf(target.value) > -1) {
            this.props.filters[type] = this.props.filters[type].filter((value) => {
                return value !== target.value
            })
        }

        this.emplacementListRef.current.refreshPagination();
        this.mapRef.current.refreshPagination();
    }


    render() {
        return(
            <div className="contents content-margin">
                <TabContainer id="left-tabs-example" defaultActiveKey="plan">
                    <div className="content-header px-3 px-lg-4">
                        <span className="expand-btn">&#9776;</span>
                        <div className="d-flex align-items-center">
                            <div className="col-6 soleilBold-fonts title">CONCESSIONS</div>
                            <div className="col-6 emp-buttons">
                                <Nav variant="pills" className="flex-row switch-plan-liste">
                                    <NavItem>
                                        <NavLink eventKey="plan">
                                        <button type="button" className="plan-btn soleilBold-fonts">Plan</button></NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink eventKey="liste">
                                        <button type="button" className="liste-btn soleilBold-fonts">Liste</button></NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </div>
                    </div>
                    <div className="table-filter-wrapper px-3 px-lg-4 pt-3 pb-2">
                        <FiltrerConcession title="FILTRER LES CONCESSIONS" filters={this.props.filters} mapRef={this.mapRef} emplacementListRef={this.emplacementListRef}/>
                        <div>
                            <input type="checkbox" id="in_progress_filter" className="mr-2" value="en_cours" name="filters[etatConcession][]" checked={this.props.filters.etatConcession.includes('en_cours')} onChange={e => this.handleFilterEmplacementChange(e, 'etatConcession')}  />
                            <label htmlFor="in_progress_filter" className="soleilRegular-fonts">
                                En cours
                            </label>
                        </div>
                        <div>
                            <input type="checkbox" id="expired_filter"  className="mr-2" value="echue" name="filters[etatConcession][]" checked={this.props.filters.etatConcession.includes('echue')} onChange={e => this.handleFilterEmplacementChange(e, 'etatConcession')} />
                            <label htmlFor="expired_filter" className="soleilRegular-fonts">
                                Échue
                            </label>
                        </div>
                        <div>
                            <input type="checkbox" id="resumption_in_progress_filter" value="abandon_en_cours"  className="mr-2" checked={this.props.filters.etatConcession.includes('abandon_en_cours')} onChange={e => this.handleFilterEmplacementChange(e, 'etatConcession')} />
                            <label htmlFor="resumption_in_progress_filter" className="soleilRegular-fonts">
                                Reprise par abandon en cours
                            </label>
                        </div>
                        <div>
                            <input type="checkbox" id="resumption_in_finalized_filter" value="equipements" className="mr-2" checked={this.props.filters.etatConcession.includes('equipements')} onChange={e => this.handleFilterEmplacementChange(e, 'etatConcession')} />
                            <label htmlFor="resumption_in_finalized_filter" className="soleilRegular-fonts">
                                Equipements / Terrain commun
                            </label>
                        </div>
                        <div>
                            <input type="checkbox" id="unknown_filter" value="manque_donnee" className="mr-2" checked={this.props.filters.etatConcession.includes('manque_donnee')} onChange={e => this.handleFilterEmplacementChange(e, 'etatConcession')} />
                            <label htmlFor="unknown_filter" className="soleilRegular-fonts">
                                Manque de données
                            </label>
                        </div>
                    </div>
                    <TabContent>
                        <TabPane eventKey="plan" onEntered={() => {

                            window.dispatchEvent(new Event('resize'));
                            console.log('onent')
                        }
                        }>
                            <Plan filters={this.props.filters} ref={this.mapRef} referer={"concession"}/>
                        </TabPane>
                        <TabPane eventKey="liste">
                            <ConcessionsListe ref={this.emplacementListRef} filters={this.props.filters} sort={this.props.sort}/>
                        </TabPane>
                    </TabContent>
                </TabContainer>
            </div>
        )
    }
}

export default ConcessionsMenu;