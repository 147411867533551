import StepsMainFunctions from "./StepsMainFunctions";
import $ from "jquery";

const steps = [
    {
        id: 'gestion-compte-1',
        attachTo: { element: '.contents.content-margin ', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function() {
            const $this = this
            return new Promise(function(resolve) {
                setTimeout( function () {
                    if(window.location.pathname !== '/gestion-compte') {
                        $this.text = `<p>Cliquez dès maintenant sur <span class="purple">"Commune"</span> pour ouvrir le menu latéral qui donne accès à <span class="purple">la gestion du compte</span> poursuivre le tutoriel.</p>`
                        $this.attachTo = { element: '#link-commune', on: 'bottom' }
                        $this.buttons.splice(2,1)
                        $this.buttons[0].classes = 'shepherd-button-secondary large-margin-button'
                    }
                    resolve();
                }, 300);
            });
        },
        when: {
            show() {
                const $this = this
                if (window.location.pathname !== '/gestion-compte') {
                    StepsMainFunctions.isVisibleSidePanel(this.tour)
                } else {
                    if ($this.tour && $this.tour.steps) {
                        $this.tour.steps.splice(1, 3)
                    }
                }
                StepsMainFunctions.showRewrite($this)
            },
            cancel() {
                $('#link-commune').off('click')
            },
            destroy() {
                $('#link-commune').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous vous trouvez actuellement dans la partie <span class="purple">gestion du compte</span> de votre commune.</p>`,
    },
    {
        id: 'gestion-compte-2',
        attachTo: { element: '#sidePanelCompte', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Ce sous-menu vous permet d'accéder à différentes pages relatives à la gestion de votre <span class="purple">espace</span> ainsi qu'au <span class="purple">centre d'aide</span>.</p>`,
    },
    {
        id: 'gestion-compte-3',
        attachTo: { element: '#gerer-compte-link', on: 'left' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        when: {
            show() {
                StepsMainFunctions.showRewrite(this)
                StepsMainFunctions.locationToEmplacement('#gerer-compte-link', 'gestion-compte-menu', this)
            },
            cancel() {
                $('#gerer-compte-link').off('click')
            },
            destroy() {
                $('#gerer-compte-link').off('click')
            }
        },
        text: `<p>Cliquez dès maintenant sur <span class="purple">"Gestion du compte"</span> pour accéder à cette section et poursuivre le tutoriel.</p>`,
    },
    {
        id: 'gestion-compte-4',
        attachTo: { element: '.contents.content-margin ', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button-suivant',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous vous trouvez actuellement dans la partie <span class="purple">gestion du compte</span> de votre commune.</p>`,
    },
    {
        id: 'gestion-compte-5',
        attachTo: { element: '#infos-cimetiere-link', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            }
        ],
        when: {
            show() {
                StepsMainFunctions.showRewrite(this)
                $('#infos-cimetiere-link').on('click', () => {
                    this.tour.next()
                    $('#infos-cimetiere-link').off('click')
                })
            },
            cancel() {
                $('#infos-cimetiere-link').off('click')
            },
            destroy() {
                $('#infos-cimetiere-link').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur <span class="purple">"Infos cimetière"</span> pour accéder à la gestion des tarifs.</p>`,
    },
    {
        id: 'gestion-compte-6',
        scrollTo: true,
        attachTo: { element: '#tarifs-liste', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    StepsMainFunctions.ChangePanel('infos-cimetiere-panel','infos-cimetiere-link')
                    resolve();
                }, 300);
            });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Retrouvez dans la section <span class="purple">"Tarifs du cimetière"</span> les tarifs enregistrés et utilisés dans Logicim. <span class="purple">Ajouter vos tarifs</span> dans Logicim vous permettra de gagner du temps pour créer vos concessions et effectuer des procédures.</p>`,
    },
    {
        id: 'gestion-compte-7',
        scrollTo: true,
        attachTo: { element: '#add-new-tarif', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            }
        ],
        when: {
            show() {
                StepsMainFunctions.showRewrite(this)
                StepsMainFunctions.watchDivWithHeight('compte-ajout-tarif','#add-new-tarif', this)
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur <span class="purple">"Ajouter nouveau"</span> pour ajouter un nouveau tarif.</p>`,
    },
    {
        id: 'gestion-compte-8',
        scrollTo: true,
        attachTo: { element: '#compte-ajout-tarif', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Ce <span class="purple">formulaire</span> vous permet d'ajouter un nouveau tarif. </p>`,
    },
    {
        id: 'gestion-compte-9',
        scrollTo: true,
        attachTo: { element: '#ajout-tarif-type-emplacement', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Le champs <span class="purple">type d'emplacement</span> vous permet de choisir à quel type d'emplacement s'appliquera le tarif qui vous créé. </p>`,
    },
    {
        id: 'gestion-compte-10',
        scrollTo: true,
        attachTo: { element: '#ajout-tarif-type-duree', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Choississez ensuite la <span class="purple">durée</span> à laquelle s'applique le tarif. Si aucune durée ne correspond dans la liste déroulante, vous pouvez renseigner la durée en nombre dans le champs <span class="purple">"Autre durée</span>.</p>`,
    },
    {
        id: 'gestion-compte-11',
        scrollTo: true,
        attachTo: { element: '#surface-wrapper', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Si le tarif est applicable par <span class="purple">m²</span>, cochez la case "par m² pour la  <span class="purple">surface</span> sinon choisissez dans <span class="purple">liste déroulante</span> les surfaces correspondant au tarifs.</p>`,
    },
    {
        id: 'gestion-compte-12',
        scrollTo: true,
        attachTo: { element: '#tarifs-prestations-wrapper', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Deux type de <span class="purple">tarifs</span> sont à renseigner. Le <span class="purple">tarif d'attribution</span> de l'emplacement prestations incluses (vous pouvez précisez les prestations dans la section "Dont prestations incluses") et le <span class="purple">tarif applicable</span> prestations incluses lors du <span class="purple">renouvellement</span> du contrat.</p>`,
    },
    {
        id: 'gestion-compte-13',
        scrollTo: true,
        attachTo: { element: '#ajout-tarif-type-date', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>La <span class="purple">date</span> d'application est la date depuis laquelle le tarif est appliqué. Si une date plus récente est trouvé pour le même type d'emplacement, durée et surface, <span class="purple">le tarif le plus récent sera appliqué</span>.</p>`,
    },
    {
        id: 'gestion-compte-14',
        scrollTo: true,
        attachTo: { element: '#tarifs-archivés-wrapper', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous avez la possibilité de consulter les <span class="purple">tarifs archivés</span> et qui sont toujours utilisés par Logicim. Lorsque vous modifier ou supprimer un tarif en cours d'utilisation, Logicim les archive.</p>
                <p>Si vous souhaitez <span class="purple">supprimer définitivement</span> un tarif, vous pouvez le faire dans cette section.</p>`,
    },
    {
        id: 'gestion-compte-15',
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Terminer',
                type: 'complete'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Vous pouvez maintenant <span class="purple">ajouter des tarifs</span> dans Logicim. Vous pouvez les <span class="purple">modifier</span> à tout moment. Lorsque Logicim appliquera ces tarifs lors de vos démarches, vous aurez la <span class="purple">possibilité de modifier</span> le champs concerné.</p>`,
    },
]

export default steps;