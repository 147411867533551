import React, { Component } from 'react';
import axios from "axios";

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            faq : [],
        }
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + '/faqs?typeEspace=logicim').then((res) => {
            this.setState({faq : res.data['hydra:member']})
        })
    }

    render() {
        const faq = this.state.faq.map(faq => {
            return (
                <div className="mt-5 px-2">
                    <p className="soleilBold-fonts" style={{fontSize: '18px'}}>{faq.question}</p>
                    <p className="soleilRegular-fonts mt-1" style={{whiteSpace: 'pre-line'}}>{faq.reponse}</p>
                </div>

            )
        })
        return (
            <div className="container-fluid bg-white px-3 py-5">
                <div>
                    {faq}
                </div>
            </div>
        )
    }
}

export default Faq;