import React, { Component } from 'react';
import '../../assets/css/ajoutEspace.css'
import timerIcon from "../../assets/images/timer-icon.svg";
import axios from "axios";
import maireIcon from "../../assets/images/maire-icon.svg";

class AjoutMairie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mairie       : {
                id        : null,
                commune   : '',
                adresse   : '',
                codePostal: '',
                ville     : '',
                email     : '',
                telephone : '',
                tresorerie: '',
                departement: '',
                blason    : null,
                horaires  : [],
                cimetieres: null,
            },
            maire: {
                commune   : '',
                adresse   : '',
                codePostal: '',
                ville     : '',
                email     : '',
                telephone : '',
                logo      : '',
                maireId   : '',
                civilite  : 'H',
                nom       : '',
                prenom    : '',
                signature : '',
            },
            blasonDisplay: null,
            signatureDisplay: null,
            mairies      : [],
            nouveau      : true,
            contacts     : []
        }
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + '/societes-type/logicim').then(res => {
            this.setState({mairies : res.data})
        })
    }

    handleChange = (e)  =>{
        let name   = e.target.name
        let mairie = {
            ...this.state.mairie,
            [name]: e.target.value
        }
        this.setState({
            mairie: mairie
        });
        this.props.updateEspace('mairie', mairie)
    }
    handleChangeMaire = (e)  =>{
        let name   = e.target.name
        let maire = {
            ...this.state.maire,
            [name]: e.target.value
        }
        this.setState({
            maire: maire
        });
    }

    handleHoraires = (e) => {
        const index     = e.target.getAttribute('data-index')
        let horaires    = this.state.mairie.horaires
        horaires[index] = e.target.value
        let mairie      = {
            ...this.state.mairie,
            horaires : horaires
        }
        this.setState({
            mairie: mairie
        });
        this.props.updateEspace('mairie', mairie)
    }

    handleChangeBlason = async (e) => {
        const uploadFile = this.props.uploadFile
        if (uploadFile) {
            const res = await this.props.uploadFile(e)
            let mairie = {
                ...this.state.mairie,
                blason : res ? process.env.REACT_APP_API_URI + '/image_media/' + res.data.id : null
            }
            this.setState({
                mairie: mairie,
                blasonDisplay: res ? process.env.REACT_APP_MEDIA_URL + res.data.nom : null
            });
        }
    }

     handleChoixMairie = async (e) => {
        let mairie
        let blasonDisplay = null
        if (e.target.value !== '') {
            await axios.get(process.env.REACT_APP_API_URL + '/societes/' + e.target.value).then(res => {
                let data = res.data
                data.cimetieres = data.eSpaces
                data.blason = data.logo ? process.env.REACT_APP_API_URI + '/image_media/' + data.logo.id : null
                mairie = data
                blasonDisplay = data.logo ? process.env.REACT_APP_MEDIA_URL + data.logo.nom : null
            })
/*            let value = this.state.mairies.find(mairie => mairie.id === parseInt(e.target.value))

            mairie = {
                id        : value.id,
                commune   : value.commune,
                adresse   : value.adresse,
                codePostal: value.codePostal,
                ville     : value.ville,
                email     : value.email,
                telephone : value.telephone,
                horaires  : value.horaires ? value.horaires : [],
                cimetieres: value.eSpaces,
                blason    : value.logo ? process.env.REACT_APP_API_URI + '/image_media/' + value.logo.id : null,
                departement: value.departement,
                tresorerie: value.tresorerie
            }*/

        } else {
            mairie = {
                ...this.state.mairie,
                id : null
            }
        }
        this.setState({
            mairie       : mairie,
            blasonDisplay: blasonDisplay
        });
        this.props.updateEspace('mairie', mairie)
    }

    supprimerBlason = () => {
        let mairie = {
            ...this.state.mairie,
            blason : null
        }
        this.setState({
            mairie       : mairie,
            blasonDisplay: null
        });
    }

    cancel = () => {
        this.setState({
            mairie      : {
                id        : null,
                commune   : '',
                adresse   : '',
                codePostal: '',
                ville     : '',
                email     : '',
                telephone : '',
                blason    : null,
                horaires  : [],
                cimetieres: null,
                tresorerie: '',
                departement: ''
            },
            maire: {
                commune   : '',
                adresse   : '',
                codePostal: '',
                ville     : '',
                email     : '',
                telephone : '',
                logo      : '',
                maireId   : '',
                civilite  : 'H',
                nom       : '',
                prenom    : '',
                signature : '',
                tresorerie: '',
                departement: ''
            },
            contacts      : [],
            blasonDisplay: null,
            signatureDisplay: null,
            nouveau      : true
        });
    }

    updateInfosMaire = (e) => {
        if (this.state.maire.maireId) {
            axios.put(process.env.REACT_APP_API_URL + '/contacts/' + this.state.maire.maireId, {
                [e.target.name]: e.target.value
            }).then(res => {
                const contacts = this.state.contacts
                const index = contacts.findIndex(contact => contact.typeDeContact === 'Maire')
                if(index >= 0) {
                    contacts[index] = res.data
                }
                this.setState({contacts : contacts})
            })
        } else {
            axios.post(process.env.REACT_APP_API_URL + '/contacts', {
                [e.target.name]: e.target.value,
                civilite     : e.target.name === 'civilite' ? e.target.value : this.state.mairie.civilite,
                typeDeContact: 'Maire',
            }).then(res => {
                const contacts = this.state.contacts
                contacts.push(res.data)
                this.setState({
                    maireId : res.data.id,
                    civilite: res.data.civilite,
                    nom     : res.data.nom,
                    prenom  : res.data.prenom,
                    contacts: contacts
                })
            })
        }
    }

    handleChangeSignature = async (e) => {
        const uploadFile = this.props.uploadFile
        if (uploadFile) {
            const res = await this.props.uploadFile(e)
            let maire = {
                ...this.state.maire,
                signature : res ? process.env.REACT_APP_API_URI + '/image_media/' + res.data.id : null
            }
            this.setState({
                maire: maire,
                signatureDisplay: res ? process.env.REACT_APP_MEDIA_URL + res.data.nom : null
            });
        }
    }

    updateSignature = (e) => {
        this.uploadFile(e, process.env.REACT_APP_API_URL + '/contacts/' + this.state.maireId, 'signature')
    }

    uploadFile(e, url, state) {
        let formData = new FormData()
        formData.append('file', e.target.files[0])
        axios.post("/documents/upload/media", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((res) => {
            axios.put(url, {
                [state]: process.env.REACT_APP_API_URI + '/image_media/' + res.data.id
            }).then(() => {
                this.setState({ [state]: process.env.REACT_APP_MEDIA_URL + res.data.nom})
            })
        })
    }

    supprimerSignature = () => {
        let maire = {
            ...this.state.maire,
            signature : null
        }
        this.setState({
            maire       : maire,
            signatureDisplay: null
        });
    }
    render() {
        const jours    = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]
        const horaires = jours.map( (jour,index) => {
            return (
                <div className="cols small" key={index}>
                    <div className="soleilRegular-fonts pb-2">{jour}</div>
                    <textarea className="horaire-mairie" placeholder="non renseigné" value={this.state.mairie.horaires[index] ? this.state.mairie.horaires[index] : ''} data-index={index} onChange={this.handleHoraires}/>
                </div>
            )
        })
        let options = this.state.mairies.map(mairie => {
            return <option value={mairie.id}>{mairie.commune}</option>
        })
        return (
            <>
                <div className="form-wrapper px-5">
                    <p className="soleilBold-fonts purple">Information de la mairie</p>
                    {
                        this.state.nouveau ?
                            <a className="autocomplete-cancel cursor red float-right" onClick={() => this.setState({nouveau: false})}>
                                Choisir une mairie existante
                            </a>
                            : ''
                    }
                    {
                        !this.state.nouveau ?
                            <div className="d-flex pt-3">
                                <div className="select-grey-wrapper">
                                    <select className="soleilRegular-fonts purple select-grey" name="id" value={this.state.mairie.id} onChange={this.handleChoixMairie}>
                                        <option value={null}/>
                                        {options}
                                    </select>
                                </div>
                                <a className="autocomplete-cancel cursor red float-right ml-2" onClick={this.cancel}>
                                    Annuler
                                </a>
                            </div>
                            : ''
                    }

                    <div className="px-2">
                        <div className="d-flex pt-3">
                            <label htmlFor="commune" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Commune :</label>
                            <input type="text" id="commune" name="commune" value={this.state.mairie.commune}
                                   className="soleilRegular-fonts col-3" placeholder="Commune" onChange={this.handleChange} required/>
                        </div>
                        <div className="d-flex pt-3">
                            <label htmlFor="email" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Département :</label>
                            <input type="text" id="telephone" name="departement" value={this.state.mairie.departement}
                                   className="soleilRegular-fonts col-3" placeholder="departement" onChange={this.handleChange}/>
                        </div>
                        <div className="d-flex pt-3">
                            <label htmlFor="adresse" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Adresse :</label>
                            <input type="text" id="adresse" name="adresse" value={this.state.mairie.adresse}
                                   className="soleilRegular-fonts col-3" placeholder="Adresse" onChange={this.handleChange}/>
                        </div>
                        <div className="d-flex pt-3">
                            <label htmlFor="codePostal" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Code postal :</label>
                            <input type="text" id="codePostal" name="codePostal" value={this.state.mairie.codePostal}
                                   className="soleilRegular-fonts col-3" placeholder="code postal" onChange={this.handleChange}/>
                        </div>
                        <div className="d-flex pt-3">
                            <label htmlFor="ville" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Ville :</label>
                            <input type="text" id="ville" name="ville" value={this.state.mairie.ville}
                                   className="soleilRegular-fonts col-3" placeholder="ville" onChange={this.handleChange}/>
                        </div>
                        <div className="d-flex pt-3">
                            <label htmlFor="email" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Email :</label>
                            <input type="text" id="email" name="email" value={this.state.mairie.email}
                                   className="soleilRegular-fonts col-3" placeholder="email" onChange={this.handleChange}/>
                        </div>
                        <div className="d-flex pt-3">
                            <label htmlFor="email" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Téléphone :</label>
                            <input type="text" id="telephone" name="telephone" value={this.state.mairie.telephone}
                                   className="soleilRegular-fonts col-3" placeholder="telephone" onChange={this.handleChange}/>
                        </div>
                        <div className="d-flex pt-3">
                            <label htmlFor="email" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Nom de la trésorerie :</label>
                            <input type="text" id="telephone" name="tresorerie" value={this.state.mairie.tresorerie}
                                   className="soleilRegular-fonts col-3" placeholder="tresorerie" onChange={this.handleChange}/>
                        </div>
                        <div className="mt-3 d-flex">
                            <div className="d-flex mt-2">
                                <label className="col-4 p-0 m-0 soleilRegular-fonts">Logo / Blason de la commune</label>
                                {
                                    this.state.mairie.blason ?
                                        <img className=" soleilRegular-fonts img-fluid" src={this.state.blasonDisplay} alt="logo_mairie" style={{maxWidth: "200px"}}/>
                                        : <p className="grey small px-4">Aucun logo/blason</p>
                                }
                            </div>
                            <div className="d-flex mt-2 ml-3">
                                <div className="col-4 "/>
                                <div className="d-flex">
                                    <div className="modifier-file">
                                        <label htmlFor="modif_blason" className="soleilRegular-fonts red mr-3 cursor">{ this.state.mairie.blason  ? 'Modifier le logo/blason' : 'Ajouter un logo/blason'}</label>
                                        <input id="modif_blason" className="d-none" type="file" onChange={this.handleChangeBlason}/>
                                    </div>
                                    { this.state.mairie.blason  ?
                                        <div>
                                            <a className="soleilRegular-fonts purple cursor"
                                               onClick={this.supprimerBlason}>Supprimer</a>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="timer-wrapper row mt-4 mr-auto ml-auto">
                            <div className="title soleilBold-fonts"><img src={timerIcon} alt="" className="img-fluid mr-3"/> HORAIRES MAIRIE
                            </div>
                            <div className="d-flex mt-4 align-content-start col-12">
                                {horaires}
                            </div>
                        </div>
                    </div>

                    <p className="soleilBold-fonts purple mt-4">Information du maire</p>

                    <div>
                        <div className="form-wrapper">
                            <div className="d-flex">
                                <label className="col-4 soleilRegular-fonts">Civilité</label>
                                <div className="col-8 d-flex">
                                    <div className="mr-4">
                                        <input type="radio" value="H" id="H" name="civilite" checked={this.state.maire.civilite === "H"} onChange={this.handleChangeMaire}/>
                                        <label htmlFor="H"
                                               className="soleilRegular-fonts purple radio-label">Monsieur</label>
                                    </div>
                                    <div>
                                        <input type="radio" value="F" id="F" name="civilite" checked={this.state.maire.civilite === "F"} onChange={this.handleChangeMaire}/>
                                        <label htmlFor="F"
                                               className="soleilRegular-fonts purple">Madame</label>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Nom</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="nom" value={this.state.maire.nom}  onChange={this.handleChangeMaire} onBlur={this.updateInfosMaire}/>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="col-4 soleilRegular-fonts">Prénom</label>
                                <input type="text" className="col-8 soleilRegular-fonts" name="prenom" value={this.state.maire.prenom} onChange={this.handleChangeMaire} onBlur={this.updateInfosMaire}/>
                            </div>

                            <div className="ml-3 mt-3 d-flex">
                                <div className="d-flex mt-2">
                                    <label className="col-4 p-0 m-0 soleilRegular-fonts">Signature du maire avec tampon de la mairie</label>
                                    {
                                        this.state.maire.signature ?
                                            <img className=" soleilRegular-fonts img-fluid" src={this.state.signatureDisplay} alt="logo_mairie" style={{maxWidth: "200px"}}/>
                                            : <p className="grey small px-4">Aucune signature</p>
                                    }
                                </div>
                                <div className="d-flex mt-2 ml-3">
                                    <div className="col-4 "/>
                                    <div className="d-flex">
                                        <div className="modifier-file">
                                            <label htmlFor="modif_signature" className="soleilRegular-fonts red mr-3 cursor">{ this.state.maire.signature  ? 'Modifier la signature' : 'Ajouter une signature'}</label>
                                            <input id="modif_signature" className="d-none" type="file" onChange={this.handleChangeSignature}/>
                                        </div>
                                        { this.state.maire.signature  ?
                                            <div>
                                                <a className="soleilRegular-fonts purple cursor"
                                                   onClick={this.supprimerSignature}>Supprimer</a>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default AjoutMairie;