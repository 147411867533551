import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";

export default function App() {

  
  const [state, setState] = useState();
  const [data, setData] = useState({
    template: ''
    //<textarea> LogiPlace </textarea>
  });
  const editorRef = useRef(null);
  const log = async () => {
    if (editorRef.current) {
      const donnees = editorRef.current.getContent();
      console.log(donnees);
      const result = await axios.post(process.env.REACT_APP_API_URL + '/template_documents', {
        template: JSON.stringify(donnees)
      })
      .then(res => {
        setData(res.data)
        console.log(res.data)
      }).catch(err => console.log(err))
      console.log(result)
    }
  };
 

  return (
    <div style={{marginTop: '20px'}}>
      <Editor
        apiKey="ny41ht1gzccvwazvhi3om2qoj2r0eiqgapkvf7495atssfld"
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue=""
        value={state}
        init={{
          language : 'fr_FR',
          height: 500,
          menubar: true,
          // menubar File, Edit ...
          plugins: [
            "autosave advlist autolink lists link image",
            "charmap print preview anchor help",
            "searchreplace visualblocks code",
            "insertdatetime media table paste wordcount",
            "export",
            "powerpaste"
          ],
    
          autosave_interval: "5s",
          autosave_retention: "60m",
          //durée d'auto-save
          autosave_restore_when_empty: true,
          autosave_prefix: "tinymce-autosave",
          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | searchreplace | export',
          // toolbar:
          //   "undo redo | formatselect | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | help | restoredraft",
        }}
        onEditorChange={(newVal) => setState(newVal)}
        
        onChange={(e) => {console.log(e.target.getContent());}}
      />
      <button onClick={log} style={{marginTop: '10px'}} className="btn btn-primary">Send</button>
    </div>
  );
}