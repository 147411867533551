import React, { Component, useContext } from 'react'
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import '../../assets/css/shepherd.css'

function Button(props) {
    const tour = useContext(ShepherdTourContext);
    return (
        <div className="tuto-aide">
            <p className="text-bold purple text-center"> {props.title}</p>
            <button className="button dark btn-tuto-aide" id={props.id} onClick={tour.start}>
                Commencer
            </button>
        </div>
    )
}

class Tour extends Component {

    constructor(props) {
        super(props)
    }

    /* Ferme la sidebar pour la gestion du compte */
    fermerNav() {
        document.getElementById("sidePanelAide").style.width = "0"
        document.querySelector('#overlay-aide').style.visibility = "hidden"
    }

    render() {
        const tourOptions = {
            defaultStepOptions: {
                cancelIcon: {
                    enabled: true
                },
                classes: 'shepherd-theme-custom',
                title: this.props.title,
                confirmCancel: true,
                when: {
                    show() {
                        const currentStepElement = this.el;
                        const header = currentStepElement.querySelector('.shepherd-header');
                        const progress = document.createElement('span');
                        progress.style['margin-right'] = '15px';
                        progress.innerText = `ETAPE ${this.tour.steps.indexOf(this.tour.currentStep) + 1}/${this.tour.steps.length}`;
                        header.insertBefore(progress, currentStepElement.querySelector('.shepherd-cancel-icon'));
                    }
                }
            },
            popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 12] } }]
            },
            useModalOverlay: true,
        }
        return (
            <div className="tuto-wrapper">
                <ShepherdTour steps={this.props.steps} tourOptions={tourOptions}>
                    <div onClick={this.fermerNav}>
                        <Button title={this.props.title} id={this.props.etapes}/>
                    </div>
                </ShepherdTour>
            </div>
        )
    }
}

export default Tour;