function render() {
  return (

     <div className="contents">
       {/* contents content-margin */}
      <h3 style={{textAlign: 'center'}}>Bienvenue dans Logicim</h3>
     </div>
   
  )
}

export default render;
