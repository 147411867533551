import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import filtreIcon from "../../assets/images/filter-icon-submenu.svg";
import filtreIconBlanc from "../../assets/images/filter-icon-submenu-blanc.svg";
import "../../assets/css/filtrer.css";

function FiltrerConcession(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFilterDate = function(event, idx, toggle) {
        const target = event.currentTarget;

        if (toggle && props.filters['date'][idx] !== "") {
            props.filters['date'][idx] = "";

        }  else {
            props.filters['date'][idx] = target.value;
        }

        props.emplacementListRef.current.refreshPagination();
        props.mapRef.current.refreshPagination();
    }
    const handleFilterEmplacementChange = function(event, type) {
        const target = event.currentTarget;

        if (target.checked && props.filters[type].indexOf(target.value) === -1) {
            props.filters[type].push(target.value);
        }

        if (!target.checked && props.filters[type].indexOf(target.value) > -1) {
            props.filters[type] = props.filters[type].filter((value) => {
                return value !== target.value
            })
        }

        props.emplacementListRef.current.refreshPagination();
        props.mapRef.current.refreshPagination();
    }

    return (
        <>
            <span className="soleilRegular-fonts title cursor" onClick={handleShow} id="filtre-title">
                    <img src={filtreIcon}
                         alt=""
                         className="img-fluid mr-2" style={{width: '15px', marginTop: '-3px'}}/>
                {props.title}
            </span>

            <Modal show={show} onHide={handleClose} className="filtre-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <img src={filtreIconBlanc}
                             alt=""
                             className="img-fluid mr-2" style={{width: '15px', marginTop: '-3px'}}/>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-3 px-1 py-2 border-right-filtre">
                            <p className="purple text-uppercase font-weight-bold text-center">statut</p>
                            <div className="mt-3 px-2">
                                <div className="d-flex mt-2">
                                    <label className="switch" id="first-switch">
                                        <input type="checkbox" id="switch1"  value="en_cours"  name="filters[etatConcession][]"  checked={props.filters.etatConcession.includes('en_cours')} onChange={e => handleFilterEmplacementChange(e, 'etatConcession')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch1">En cours</label></div>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="switch" id="first-switch">
                                        <input type="checkbox" id="switch2"  value="a_renouveler"  name="filters[etatConcession][]"  checked={props.filters.etatConcession.includes('a_renouveler')} onChange={e => handleFilterEmplacementChange(e, 'etatConcession')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple" ><label htmlFor="switch2">À renouveler</label></div>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="switch" id="first-switch">
                                        <input type="checkbox" id="switch3"  value="echue"  name="filters[etatConcession][]"  checked={props.filters.etatConcession.includes('echue')} onChange={e => handleFilterEmplacementChange(e, 'etatConcession')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch3">
                                        Échue</label></div>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="switch" id="first-switch">
                                        <input type="checkbox" id="switch4"  value="abandon_en_cours"  name="filters[etatConcession][]"  checked={props.filters.etatConcession.includes('abandon_en_cours')} onChange={e => handleFilterEmplacementChange(e, 'etatConcession')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch4">Reprise par abandon en cours</label></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 px-1 py-2 border-right-filtre">
                            <p className="purple text-uppercase font-weight-bold text-bold text-center">durée</p>
                            <div className="mt-3 pl-4">
                                <div className="d-flex mt-2">
                                    <label className="switch">
                                        <input type="checkbox"  value="moins15" id="switch10" name="filters[duree][]"  checked={props.filters.duree.includes('moins15')} onChange={e => handleFilterEmplacementChange(e, 'duree')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch10">15 ans maximum</label></div>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="switch">
                                        <input type="checkbox"  value="16-49"  id="switch11" name="filters[duree][]"  checked={props.filters.duree.includes('16-49')} onChange={e => handleFilterEmplacementChange(e, 'duree')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch11">Entre 16 et 49 ans</label></div>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="switch">
                                        <input type="checkbox"  value="50-99" id="switch12"  name="filters[duree][]"  checked={props.filters.duree.includes('50-99')} onChange={e => handleFilterEmplacementChange(e, 'duree')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch12">Entre 50 et 99 ans</label></div>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="switch">
                                        <input type="checkbox"  value="plus99" id="switch13"  name="filters[duree][]"  checked={props.filters.duree.includes('plus99')} onChange={e => handleFilterEmplacementChange(e, 'duree')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch13">100 ans ou perpétuelle</label></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-3 px-1 py-2 border-right-filtre">
                            <p className="purple text-uppercase font-weight-bold text-bold text-center">Caractéristiques</p>
                            <div className="mt-3 px-2">
                                <div className="d-flex mt-2">
                                    <label className="switch" id="first-switch">
                                        <input type="checkbox" id="switch21"  value="pleine"  name="filters[etatConcession][]"  checked={props.filters.etatConcession.includes('pleine')} onChange={e => handleFilterEmplacementChange(e, 'etatConcession')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch21">Concession pleine</label></div>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="switch" id="first-switch">
                                        <input type="checkbox" id="switch22"  value="archivee"  name="filters[etatConcession][]"  checked={props.filters.etatConcession.includes('archivee')} onChange={e => handleFilterEmplacementChange(e, 'etatConcession')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch22">Concession archivée</label></div>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="switch" id="first-switch">
                                        <input type="checkbox"  id="switch5" value="equipements"  name="filters[etatConcession][]"  checked={props.filters.etatConcession.includes('equipements')} onChange={e => handleFilterEmplacementChange(e, 'etatConcession')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch5">Equipements / Terrain commun</label></div>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="switch" id="first-switch">
                                        <input type="checkbox"  id="switch6" value="manque_donnee"  name="filters[etatConcession][]"  checked={props.filters.etatConcession.includes('manque_donnee')} onChange={e => handleFilterEmplacementChange(e, 'etatConcession')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch6">Manque de données</label></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 px-1 py-2">
                            <p className="purple text-uppercase font-weight-bold text-bold text-center">Dates</p>
                            <div className="d-flex mt-2">
                                <label className="switch" id="first-switch">
                                    <input type="checkbox" id="switch31"  value="an_dernier"  name="filters[date][2]"  checked={props.filters.date[2] === 'an_dernier'} onChange={e => handleFilterDate(e, 2, true)} />
                                    <span className="slider round"></span>
                                </label>
                                <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch31">Attribuée l'an dernier</label></div>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="switch" id="first-switch">
                                    <input type="checkbox" id="switch32"  value="derniers_5_ans"  name="filters[date][3]"  checked={props.filters.date[3] === 'derniers_5_ans'} onChange={e => handleFilterDate(e, 3, true)} />
                                    <span className="slider round"></span>
                                </label>
                                <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch32">Attribuée les 5 dernières années</label></div>
                            </div>

                            <div className="row mx-0 mt-4">
                                <div className="col-6">
                                    <label  className="purple text-uppercase font-weight-bold text-bold text-center">Entre</label>
                                    <div className={"exclu"}>
                                        <input type="date" name="filters[date][0]"  className={"mw-100 w-100"} value={props.filters.date[0]} onChange={e => handleFilterDate(e, 0, false)} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label className={"purple text-uppercase font-weight-bold text-bold text-center"}>Et</label>
                                    <div className={"exclu"}>
                                        <input type="date" name="filters[date][1]" className={"mw-100 w-100"} value={props.filters.date[1]} onChange={e => handleFilterDate(e, 1, false)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="red cancel-btn tbl" data-dismiss="modal" onClick={handleClose}>Annuler</button>
                    <button id="step-tour-valider" className="tbl btn-navigation bk-red ml-2 valider" onClick={handleClose}>Valider</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default FiltrerConcession;