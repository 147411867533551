import StepsMainFunctions from "./StepsMainFunctions";
import $ from "jquery";

const steps = [
    {
        id: 'editer-emplacement-1',
        attachTo: { element: '#emplacement-btn', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
        ],
        beforeShowPromise: function() {
            return StepsMainFunctions.PromiseListe('emplacements', "#emplacements-path", this)
        },
        when: {
            show() {
                const $this = this
                if(window.location.pathname === '/aide') {
                    StepsMainFunctions.observer('emplacement', '#emplacements-path', this)
                } else {
                    if (this.tour && this.tour.steps) {
                        this.tour.steps.splice(1, 2)
                    }
                    $('#emplacement-btn').off('click').on('click',function (){
                        isVisible()
                        function isVisible() {
                            const heigthDiv = document.getElementById('modif-emplacement').offsetHeight
                            if (heigthDiv > 0) {
                                $this.tour.next()
                            } else {
                                setTimeout(isVisible, 450)
                            }
                        }
                    })
                }
                StepsMainFunctions.showRewrite($this)
            },
            hide() {
                $('#emplacements-path').off('click')
            },
            cancel() {
                $('#emplacements-path').off('click')
            },
            destroy() {
                $('#emplacements-path').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur <span class="purple"> Éditer emplacement </span>pour ouvrir le formulaire de modification de l'emplacement.</p>`,
    },
    {
        id: 'fiche-toLocation-0',
        attachTo: {element: '.contents.content-margin', on: 'top'},
        buttons: [
            {
                classes: 'shepherd-button-secondary ',
                text: 'Arrêter',
                type: 'cancel'
            },
        ],
        when: {
            show() {
                StepsMainFunctions.locationToEmplacement('.edit-tbl.emplacement', 'concession-tour-1', this)
            },
            hide() {
                $('.edit-tbl.emplacement').off('click')
            },
            cancel() {
                $('.edit-tbl.emplacement').off('click')
            },
            destroy() {
                $('.edit-tbl.emplacement').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur <span class="purple">"consulter"</span> pour l'<span class="purple">emplacement</span> que vous souhaitez éditer pour poursuivre le tutoriel.</p>`,
    },
    {
        id: 'editer-emplacement-1',
        attachTo: { element: '#emplacement-btn', on: 'bottom' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
        ],
        when: {
            show() {
                const $this= this
                $('#emplacement-btn').off('click').on('click',function (){
                    isVisible()
                    function isVisible() {
                        const heigthDiv = document.getElementById('modif-emplacement').offsetHeight
                        if (heigthDiv > 0) {
                            $this.tour.next()
                        } else {
                            setTimeout(isVisible, 450)
                        }
                    }
                })
                StepsMainFunctions.showRewrite($this)
            },
            cancel() {
                $('#emplacement-btn').off('click')
            },
            destroy() {
                $('#emplacement-btn').off('click')
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez dès maintenant sur<span class="purple"> "Éditer emplacement" </span>pour ouvrir le formulaire de modification de l'emplacement.</p>`,
    },
    {
        id: 'fiche-toLocation-1',
        attachTo: { element: '#modif-emplacement .modal-content', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette fenêtre vous permet de <span class="purple">mettre à jour</span> les informations de votre emplacement</span>.</p>`,
    },
    {
        id: 'editer-emplacement-3',
        attachTo: { element: '#form-emplacement-numeros', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Ces champs vous permettent de sélectionner <span class="purple">le carré</span>, l'<span class="purple">allée</span> et le <span class="purple">numéro</span> de votre emplacement.</p>`,
    },
    {
        id: 'editer-emplacement-4',
        attachTo: { element: '#form-emplacement-precisions', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cette section est libre. Vous pouvez sauvegarder toutes les <span class="purple">notes</span> ou <span class="purple">précisions</span> complémentaires que vous souhaitez pour l'emplacement en question.</p>`,
    },
    {
        id: 'editer-emplacement-5',
        scrollTo: true,
        attachTo: { element: '#form-emplacement-surface-acces', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Renseignez ici le <span class="purple">plus petit accès menant à l'emplacement</span> et la <span class="purple">surface</span>.</p>`,
    },
    {
        id: 'editer-emplacement-6',
        scrollTo: true,
        attachTo: { element: '#form-emplacement-reservation', on: 'right' },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Suivant',
                type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Activez les boutons si vous souhaitez enregistrer l'emplacement comme <span class="purple">pré-réservable</span> et/ou <span class="purple">pré-réservé</span>.</p>`,
    },
    {
        id: 'editer-emplacement-6',
        scrollTo: true,
        attachTo: { element: '#form-emplacement-submit', on: 'top' },
        buttons: [
            {
                classes: 'shepherd-button-secondary large-margin-button',
                text: 'Arrêter',
                type: 'cancel'
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Précédent',
                type: 'back'
            },
        ],
        when: {
            show() {
                const $this = this
                StepsMainFunctions.showRewrite($this)
                document.getElementById('form-emplacement-submit').addEventListener('click', () => $this.tour.next())
            }
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Cliquez sur <span class="red">Valider</span> pour <span class="purple">sauvegarder</span> vos modifications.</p>`,
    },
    {
        id: 'editer-emplacement-6',
        buttons: [
            {
                classes: 'shepherd-button-primary',
                text: 'Terminer',
                type: 'complete'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        text: `<p>Votre <span class="purple">emplacement</span> a bien été <span class="purple">sauvegardé</span>. Vous pouvez refaire ce <span class="purple">tutoriel</span> à tout moment via la <span class="purple">fenêtre d'assistance</span>.</p>`,
    },
]

export default steps;