import React, { Component } from 'react';
import axios from "../../helpers/axios";
import '../../assets/css/formDefunt.css';
import $ from "jquery";
import {connect} from "react-redux";
import store from "../../store";

class FormReductionCorps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formReduction : [],
            reductionId : null,
            defuntId : null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let reduction = store.getState().defunt.reduction;
        if (reduction != null && reduction.id !== this.state.reductionId) {
            this.setState({
                defuntId : JSON.parse(this.props.defunt.defunt).occupant.id,
                reductionId : reduction.id
            })
            this.getFormReduction()
        } else if (this.props.defunt.defunt && (this.state.defuntId !== JSON.parse(this.props.defunt.defunt).occupant.id || (!reduction && this.state.reductionId))) {
            this.setState({
                defuntId : JSON.parse(this.props.defunt.defunt).occupant.id,
                reductionId : null,
            })
            this.getFormReduction()
        }
    }

    url = () => {
        let url = '/logicim/formReduction/' + this.props.locationId + '/' + JSON.parse(this.props.defunt.defunt).occupant.id
        if (store.getState().defunt.reduction) {
            url += '/' + store.getState().defunt.reduction.id
        }
        return url
    }

    getFormReduction() {
        try {
            axios.get(process.env.REACT_APP_API_URL + this.url()).then((response) => {
                this.setState({formReduction: response.data})

                document.querySelectorAll('#reduction_corps_cremation_destinationUrneCendre ' +
                    'option[value="Dispersion en Jardin du Souvenir"],#reduction_corps_cremation_destinationUrneCendre ' +
                    'option[value="Dispersion en pleine nature sauf voies publiques"]').forEach((element) => element.remove())

                const champsReductionCorps =  document.getElementById('reduction_corps_destinationCorps')
                champsReductionCorps && champsReductionCorps.addEventListener('change', function () {
                    if (this.value === 'urne après crémation') {
                        document.getElementById('urne-cremation').classList.remove('display-none')
                        document.getElementById('boite-ossement').classList.add('display-none')
                    } else {
                        document.getElementById('urne-cremation').classList.add('display-none')
                        document.getElementById('boite-ossement').classList.remove('display-none')
                    }
                })

                const champsTypeReduction = document.getElementById('reduction_corps_type')
                champsTypeReduction && champsTypeReduction.addEventListener('change', function () {
                    if (this.value === 'reunion de corps') {
                        document.querySelectorAll('.text-to-change').forEach((e) => {
                            e.innerHTML = "réunion de corps"
                        })
                        document.querySelector('#reduction-destination-corps select').selectedIndex = 0
                        document.getElementById('reduction-destination-corps').classList.add('disable-field')
                        document.getElementById('reunion-corps-defunts').classList.remove('display-none')
                        document.getElementById('urne-cremation').classList.add('display-none')
                        document.getElementById('boite-ossement').classList.remove('display-none')
                    } else {
                        document.querySelectorAll('.text-to-change').forEach((e) => {
                            e.innerHTML = "réduction de corps"
                        })
                        document.getElementById('reduction-destination-corps').classList.remove('display-none')
                        document.getElementById('reunion-corps-defunts').classList.add('display-none')
                        document.getElementById('reduction-destination-corps').classList.remove('disable-field')
                    }
                })

                if (store.getState().defunt.reduction && store.getState().defunt.reduction.type === "reunion de corps") {
                    document.getElementById('reunion-corps-defunts').classList.remove('display-none')
                    for (let defuntId in store.getState().defunt.reduction.reunionDefunts) {
                        const reunionCorpsInput = document.querySelector('#reunion-corps-defunts input[value="' + defuntId + '"]')
                        if(reunionCorpsInput) {
                            reunionCorpsInput.checked = true
                        }
                    }
                }
            })
        } catch (e){
            console.log(e)
        }
    }

    addReduction = () => {
        let checkboxValidation = document.getElementById('valid_input_reduction');
        if (checkboxValidation && checkboxValidation.checked === true) {
            $('.modal').modal('hide')
            let postDatas = $(".formulaire.reduction form").serialize();
            const submit = document.querySelector(".formulaire.reduction").getAttribute('data-submit')
            postDatas += '&valider=1';
            axios.post(submit, postDatas).then((response) => {
                const addReduction = this.props.addReduction
                addReduction(response.data)
                let nbToRemovePlacesOccupees = 1
                if(response.data.type === "reunion de corps"){
                    nbToRemovePlacesOccupees = Object.keys(response.data.reunionDefunts).length
                }
                const ajoutPlaceOccupee = this.props.handlePlaceOccupee
                ajoutPlaceOccupee(nbToRemovePlacesOccupees, 'remove')
            })
        } else {
            document.querySelector('#etape4 .valid_form').classList.add('c-error')
        }
    }

    render() {
        let url = '/logicim/formReduction/' + this.props.defuntId;
        if (this.state.reductionId) {
            url += '/' + this.state.reductionId
        }
        return (
            <React.Fragment>
                <div className="modal fade procedure-modal modal-large" id="etape4" tabIndex="-1" role="dialog"
                     aria-labelledby="etape4" aria-hidden="true">
                    <div className="modal-dialog  d-flex justify-content-center align-items-center modal-wrap"
                         role="document">
                        <div className="modal-content">
                            <div className="modal-header bk-grey">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-body-top">
                                    <p className="soleilBold-fonts title purple">réduction/réunion de corps</p>
                                    <div className="progression">
                                        <span className="jalon s1 fait">
                                            <div className="desc">Vérification préalable</div>
                                        </span>
                                        <span className="ligne s2"/>
                                        <span className="jalon">
                                            <div className="desc">Demande</div>
                                        </span>
                                        <span className="ligne s3"/>
                                        <span className="jalon">
                                            <div className="desc">Exhumation</div>
                                        </span>
                                        <span className="ligne s4"/>
                                        <span className="jalon">
                                            <div className="desc">Destination du corps</div>
                                        </span>
                                        <span className="ligne s5 last"/>
                                        <span className="jalon">
                                            <div className="desc">Vérification Validation</div>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-wrapper scroll-popup" id="form-wrapper-reduct">
                                    <div dangerouslySetInnerHTML={{__html: this.state.formReduction}}
                                         className="formulaire reduction"
                                         data-submit={process.env.REACT_APP_API_URL + url }/>
                                    <div className="modal-footer navigation mt-5 p-0">
                                        <div>
                                            <button type="button"
                                                    className="tbl bk-red precedent display-none">Précédent
                                            </button>
                                        </div>
                                        <div>
                                            <button className="red cancel-btn tbl" data-dismiss="modal">Annuler</button>
                                            <button type="button" className="tbl bk-red ml-2 suivant">Suivant</button>
                                            <button type="submit" className="tbl btn-navigation bk-red display-none ml-2 preview">Previsualiser</button>
                                            <button type="submit" className="tbl btn-navigation bk-red display-none ml-2 valider" onClick={this.addReduction}>Valider</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addReduction: (reduction) => dispatch({
            type: 'ADD_REDUCTION',
            reduction : reduction
        }),
    }
}

const mapStateToProps = ({exhumation = null, defunt = null, defuntListe = {}, defuntAjoute = 0,defuntAjouteId = null }) => ({
    exhumation,
    defunt,
    defuntListe,
    defuntAjoute,
    defuntAjouteId
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormReductionCorps);

