import React, { Component } from 'react';
import redBellIcon from '../../assets/images/red-bell-icon.svg';
import subMenuFilterIcon from '../../assets/images/filter-icon-submenu-blanc.svg';

class Exportation extends Component{

    render() {
        return (
            <div className="container-fluid px-0">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-wrapper mt-3" >
                            <div className="d-flex flex-wrap" id="restauration-cimetiere">
                                <p className="soleilBold-fonts m-0 col-4 p-0 d-inline-flex align-items-center">
                                    <img src={redBellIcon} alt="" className="img-fluid mr-1" />&nbsp;
                                    Cimetière
                                </p>
                                <div className="select-grey-wrapper">
                                    <select className=" soleilRegular-fonts purple select-grey">
                                        <option value="Choisir le cimetière" disabled selected>Choisir le cimetière à exporter</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center">
                                <button type="button" className="big-btn d-flex align-items-center justify-content-center mt-5 mb-4" data-toggle="modal" data-target="#filtreExport">
                                    <p className="m-0 px-xl-5 soleilBold-fonts" >EXPORTER</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-wrapper mt-3" >
                            <p className="soleilRegular-fonts sous-notes mb-3">Les données sont exportées sous différents formats :</p>
                            <ul >
                                <li className="sous-notes soleilRegular-fonts mb-1">Base de données au format XLS (Excel)</li>
                                <li className="sous-notes soleilRegular-fonts mb-1">Actes numérisés / générés par Logicim au format PDF</li>
                                <li className="sous-notes soleilRegular-fonts mb-1 ">Photos au format JPG</li>
                            </ul>
                            <p className="soleilBold-fonts sous-notes mt-5 mb-0">Les données sont exportées en un seul fichier ZIP à décompresser une fois téléchagées.</p>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="filtreExport" tabIndex="-1" role="dialog" aria-labelledby="filtreExport"
                     aria-hidden="true">
                    <div className="modal-dialog d-flex justify-content-center align-items-center modal-wrap"
                         role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="d-flex justify-content-center">
                                    <img src={subMenuFilterIcon} className="img-fluid mr-3"
                                         width="25px"/>
                                        <p className="title soleilBold-fonts mb-0 mt-1">FILTRER LES CONCESSIONS</p>
                                </div>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body row pt-2">
                                <div className="col-lg-4 filtre-categorie pt-3">
                                    <p className="soleilBold-fonts purple title pl-1 ">base de donnéees (xls)</p>
                                    <div className="pl-4">
                                        <div className="d-flex mt-4">
                                            <label className="switch">
                                                <input type="checkbox" defaultChecked={true}/>
                                                <span className="slider round"></span>
                                            </label>
                                            <div className="ml-3 slider-label  soleilBold-fonts">Données des
                                                emplacements
                                            </div>
                                        </div>
                                        <div className="d-flex mt-4">
                                            <label className="switch">
                                                <input type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                            <div className="ml-3 slider-label  soleilBold-fonts">Données des
                                                concessions
                                            </div>
                                        </div>
                                        <div className="d-flex mt-4">
                                            <label className="switch">
                                                <input type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                            <div className="ml-3 slider-label  soleilBold-fonts">Données des défunts
                                            </div>
                                        </div>
                                        <div className="d-flex mt-4">
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round"></span>
                                            </label>
                                            <div className="ml-3 slider-label  soleilBold-fonts">Données des
                                                concessionnaires
                                            </div>
                                        </div>
                                        <div className="d-flex mt-4">
                                            <label className="switch">
                                                <input type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                            <div className="ml-3 slider-label  soleilBold-fonts">Données des travaux
                                            </div>
                                        </div>
                                        <div className="d-flex mt-4">
                                            <label className="switch">
                                                <input type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                            <div className="ml-3 slider-label  soleilBold-fonts">Données des
                                                procédures
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 filtre-categorie pt-3">
                                    <p className="soleilBold-fonts purple title pl-1">actes (pdf)</p>
                                    <div className="pl-4 ">
                                        <div className="d-flex mt-4">
                                            <label className="switch">
                                                <input type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                            <div className="ml-3 slider-label  soleilBold-fonts">Actes de concessions
                                            </div>
                                        </div>
                                        <div className="d-flex mt-4">
                                            <label className="switch">
                                                <input type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                            <div className="ml-3 slider-label  soleilBold-fonts">Actes de procédure
                                            </div>
                                        </div>
                                        <div className="d-flex mt-4">
                                            <label className="switch">
                                                <input type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                            <div className="ml-3 slider-label  soleilBold-fonts">Autorisations de
                                                travaux
                                            </div>
                                        </div>
                                        <div className="d-flex mt-4">
                                            <label className="switch">
                                                <input type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                            <div className="ml-3 slider-label  soleilBold-fonts">Autres actes</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 filtre-categorie pt-3">
                                    <p className="soleilBold-fonts purple title pl-1">images - photos (jpg)</p>
                                    <div className="pl-4">
                                        <div className="d-flex mt-4">
                                            <label className="switch">
                                                <input type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                            <div className="ml-3 slider-label  soleilBold-fonts">Photos des
                                                concessions
                                            </div>
                                        </div>
                                        <div className="d-flex mt-4">
                                            <label className="switch">
                                                <input type="checkbox"/>
                                                <span className="slider round"></span>
                                            </label>
                                            <div className="ml-3 slider-label  soleilBold-fonts">Photos des défunts
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="red cancel-btn tbl" data-dismiss="modal">Annuler</button>
                                <button type="button" className="tbl bk-red">Exporter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Exportation;