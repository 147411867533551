import axios from "axios";
import React, { Component } from 'react';
import user from "../../helpers/user";

class Activation extends Component{

    constructor(props) {
        super(props)
        this.state = {
            actif: this.props.actif
        }
    }

    handleActivation = (e) => {
        this.setState({actif : e.target.value === 'true'})
        axios.put(process.env.REACT_APP_API_URL + '/e_spaces/' + localStorage.getItem('espace_id'), {
            actif: e.target.value === 'true'
        })
    }

    render() {
        let myuser = null
        if (localStorage.getItem(user.USER_LOCAL_KEY)) {
            myuser = JSON.parse(localStorage.getItem(user.USER_LOCAL_KEY))
        }
        return (
            <>
                {
                    myuser && (myuser.roles.includes('ROLE_SUPER_ADMIN') || myuser.roles.includes('ROLE_ADMIN')) ?
                        <select className={"soleilRegular-fonts ml-3 activation-select " + (this.state.actif ? "green" : "red")} name="actif" value={this.state.actif} onChange={this.handleActivation}>
                            <option value={true}>en ligne</option>
                            <option value={false}>inactif</option>
                        </select>
                        : this.state.actif ? <span className="soleilBold-fonts ml-5 green">en ligne</span> : <span className="soleilBold-fonts ml-5 red">inactif</span>
                }
            </>
        )
    }
}

export default Activation;