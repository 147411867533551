import {Button, Modal} from "react-bootstrap"

function PopupLogged(props) {

    const handleClose = () => {
        props.visible = false
    };
    const handleShow = () => {
        props.visible = true
    };
    return (
        <>
            <Modal
                show={props.visible}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Bonjour {
                        (props.user
                            ? ( props.user.identite !== ""
                                ? props.user.identite
                                : props.user.username
                            )
                            : '' )
                        }
                        </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{"textAlign": "center"}}>
                    {
                        (props.user
                        ? <img src={props.user.avatar} style={{"maxWidth": "150px", "margin": "auto", "display": "block", "marginBottom": "15px"}} alt=""/>
                        : '' )
                    }
                    Votre espace de travail est en cours de chargement ...
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PopupLogged
