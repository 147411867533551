import axios from "axios";
import { 
    DOCUMENT_ERROR,
    GET_DOCUMENTS,
    GET_PREVIEW_DOCUMENT,
    START_GETTING_PREVIEW_DOCUMENT,
    START_GETTING_DOCUMENT,
    GET_DOCUMENT,
    CREATE_DOCUMENT,
    DELETE_DOCUMENT,
    UPDATE_DOCUMENT,
    PERSONNALISE_DOCUMENT
} from "./types";

/** TODO: Minh -> à remanier **/
//Récupérer tous les documents.
export const getDocuments = (page) => async (dispatch) => {
    try {
        if (!page) {
            page = 1;
        }
        const response = await axios.get(process.env.REACT_APP_API_URL + '/template_documents?page=' + page);

        dispatch({
            type: GET_DOCUMENTS,
            payload: response.data['hydra:member'],
            totalItems: response.data['hydra:totalItems']
        });
    } catch (err) {
        dispatch({
            type: DOCUMENT_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};
// Pagination 

export const previewDocument = (data) => async (dispatch) => {
    try {
        dispatch({
            type: START_GETTING_PREVIEW_DOCUMENT,
        });

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/template_documents/preview`, {
            data: data
        });

        dispatch({
            type: GET_PREVIEW_DOCUMENT,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: DOCUMENT_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
}

//Récupérer un document par son ID.
export const getDocument = (id) => async (dispatch) => {
    try {
        dispatch({
            type: START_GETTING_DOCUMENT,
        });

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/template_documents/${id}`);

        dispatch({
            type: GET_DOCUMENT,
            payload: response.data,
        });
    } catch (err) {
        dispatch({
            type: DOCUMENT_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};
// Créer un nouveau document.
export const createNewDocument = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        };
        
        let res = await axios.post(
            `${process.env.REACT_APP_API_URL}/template_documents`,
            JSON.stringify(formData),
            config
        );

        dispatch({
            type: CREATE_DOCUMENT,
            payload: res.data,
        });

       
    } catch (err) {
     
        dispatch({
            type: DOCUMENT_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};
// Pour supprimer un document.
export const deleteDocument = (id) => async (dispatch) => {
    try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/template_documents/${id}`);

        dispatch({
            type: DELETE_DOCUMENT,
        });

    } catch (err) {
        console.error(err);
        dispatch({
            type: DOCUMENT_ERROR,
            msg: err.response.statusText,
            status: err.response.status,
        });
    }
};

export const personnaliseDocument = (id) => async (dispatch) => {
    try {
        let newId = null
        await axios.get(`${process.env.REACT_APP_API_URL}/personnalise_document/${id}`).then((response) => {
            newId = response.data
        });

        dispatch({
            type: PERSONNALISE_DOCUMENT,
            newId: newId
        });

    } catch (err) {
        console.error(err);
        dispatch({
            type: DOCUMENT_ERROR,
            msg: err.response.statusText,
            status: err.response.status,
        });
    }
};

// Pour éditer un document
export const updateDocument = (id, formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        };

        await axios.put(
            `${process.env.REACT_APP_API_URL}/template_documents/${id}`,
            JSON.stringify(formData),
            config
        );

        dispatch({
            type: UPDATE_DOCUMENT,
        });

    } catch (err) {
        console.error(err);
        dispatch({
            type: DOCUMENT_ERROR,
            msg: err.response.statusText,
            status: err.response.status,
        });
    }
};

