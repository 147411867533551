import React, {Component} from "react";
import cercueil from '../../assets/images/pictos-defunts/Pictogramme Logicim_Cerceuil-violet-13.svg';
import reunion from '../../assets/images/pictos-defunts/Pictogramme Logicim_Réunion de corps-violetet rose.svg';
import reduction from '../../assets/images/pictos-defunts/Pictogramme Logicim_Réduction de corps-violet et rose.svg';
import urneTombe from '../../assets/images/pictos-defunts/Pictogramme Logicim_Urne dans cerceuil-rose et violet.svg';
import urneScellee from '../../assets/images/pictos-defunts/Pictogramme Logicim_Urne scéllée-rose et violet.svg';
import axios from "axios";
import $ from 'jquery';

class GestionPictoDefunt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            src  : null,
            text : null,
            id   : this.props.defuntId
        }
        this._isMounted = false
    }

    componentDidMount() {
        this._isMounted = true
        this.getSourceImg(this.props.defuntId)
        $(function () {
            $('[data-toggle="popover"]').popover()
        });
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.defuntId !== this.state.id) {
            await this.setState({id : this.props.defuntId})
            this.getSourceImg(this.props.defuntId)
        }
    }

    getSourceImg = (id) => {
        axios.get(process.env.REACT_APP_API_URL + '/etatDefunt/' + id).then((etat) => {
            if(id === this.state.id) {
                switch (etat.data) {
                    case 'cercueil' :
                        this._isMounted && this.setState({
                            src: cercueil,
                            text: 'cercueil'
                        })
                        break;
                    case 'reunion de corps' :
                        this._isMounted && this.setState({
                            src: reunion,
                            text: 'réunion de corps'
                        })
                        break;
                    case 'reduction de corps' :
                        this._isMounted && this.setState({
                            src: reduction,
                            text: 'réduction de corps'
                        })
                        break;
                    case 'Inhumation dans la sépulture' :
                        this._isMounted && this.setState({
                            src: urneTombe,
                            text: 'urne dans la tombe'
                        })
                        break;
                    case 'Scellement sur le monument de la sépulture' :
                        this._isMounted && this.setState({
                            src: urneScellee,
                            text: 'urne scellée'
                        })
                        break;
                }
            }
        })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        return (
            <>
                {
                    this.state.src ?
                        <div data-toggle="popover" data-trigger="hover" data-placement="top"
                             data-content={this.state.text}>
                            <img alt="picto-defunt" src={this.state.src} width="18px"/>
                        </div>
                        : ''
                }
            </>
        )
    }
}

export default GestionPictoDefunt;