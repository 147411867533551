import React, { Component } from 'react';
import pdfIconTbl from '../../assets/images/pdf-icon-tbl.svg';
import filterIconSubmenu from '../../assets/images/filter-icon-submenu.svg';
import emailIcon from '../../assets/images/email-icon.svg';
import phoneIcon from '../../assets/images/phone-icon.svg';
import {Link} from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import AjoutNouveau from "../../components/concession/AjoutNouveau";
import OrderBy from "../../components/utils/OrderBy";
import GetPDF from "../../components/utils/GetPDF";
import GetListePDF from "../../components/utils/GetListePDF";

class ConcessionnairesListe extends Component {

    constructor(props) {
        super(props);
        this.paginationRef = React.createRef();
        this.state = {
            selected        : 0,
            concessionnaires: [],
            total           : 0,
            orderBy         : null,
            sortBy          : null,
            selectedId      : [],
            search          : '',
            typeExt         : 'pdf'
        }
    }

    updateState = (res) => {
        this.setState({
            concessionnaires: res.data.data,
            total           : res.data.total,
        })
    }

    handleCheck = (e) => {
        let parent     = e.target.parentElement.parentElement
        let selectedId = this.state.selectedId
        let number     = this.state.selected

        if (e.target.checked === true) {
            selectedId.push(parent.getAttribute('data-key'))
            number+=1
        } else {
            const index = selectedId.indexOf(parent.getAttribute('data-key'));
            if (index > -1) {
                selectedId.splice(index, 1);
                number-=1
            }
        }
        this.setState({
            selected  : number,
            selectedId: selectedId
        });
    }

    refreshPagination () {
        this.paginationRef.current.changePage(1)
    }
    verifDateFin = (date) => {
        let statut = 'En cours'
        if (date) {
            const d = new Date(date.substr(0, 10))
            const dateMaintenant = new Date()
            const echeanceRenouvellement = new Date(date.substr(0, 10))
            echeanceRenouvellement.setFullYear(echeanceRenouvellement.getFullYear() + 2)
            if(d < dateMaintenant && dateMaintenant < echeanceRenouvellement) {
                statut = 'A renouveler'
            } else if (d < dateMaintenant) {
                statut = 'Échue'
            }
        } else {
            statut = 'Données manquantes'
        }
        return statut
    }

    sort = (sortBy) => {
        if (this.state.sortBy === sortBy) {
            this.setState(prevState =>{
                return{
                    ...prevState,
                    orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
                }
            })
        } else {
            this.setState({
                sortBy : sortBy,
                orderBy: 'asc'
            })
        }
    }

   render() {
       let tableRows = this.state.concessionnaires.map((c) => {
           let statut = this.verifDateFin(c.location && c.location.dateFin)
               return (
                   !c.hasOwnProperty('location') || !c.location.hasOwnProperty('emplacement') ? <></> :
                   <tr key={c.location.emplacement.id} data-key={c.location.emplacement.id} className={this.state.selectedId.includes(c.location.emplacement.id.toString()) ? 'liste-border' : 'liste-border-none'}>
                       <td>
                           <input type="checkbox" className="table-checkbox" onChange={this.handleCheck} checked={this.state.selectedId.includes(c.location.emplacement.id.toString())}/>
                           {c.location.emplacement.carre ? c.location.emplacement.carre : ''} {c.location.emplacement.allee ? c.location.emplacement.allee : ''}
                       </td>
                       <td>
                           <span className="family_no">
                               {c.location.emplacement.numero1}
                               {c.location.emplacement.numero2}
                               {c.location.emplacement.numero3}
                               {c.location.emplacement.numero4}
                           </span>
                       </td>
                       <td>{c.location.famille}</td>
                       <td>
                           {
                               statut === 'En cours' || statut === 'A renouveler' ?
                                   <span className="tbl bk-green text-uppercase px-2">{statut}</span>
                                   :
                                   c.location.dateFin ?
                                       <span className="tbl bk-red text-uppercase">échue</span>
                                       : ''
                           }
                           {
                               !c.location.dateFin ?
                                   <span className="tbl bk-grey text-uppercase">pas de données</span>
                                   :
                                   ''
                           }
                       </td>
                       <td className="black">{c.contact.nom} {c.contact.prenom}</td>
                       <td className="black">{c.contact.typeDeContact}</td>
                       <td className="black">
                           {c.contact.adresse}
                       </td>
                       <td>
                           <a href={"mailto:#" + c.contact.email} className="edit-tbl">
                               <img src={emailIcon} alt="" className="img-fluid"
                                    width="25px"/>
                           </a>
                       </td>
                       <td>
                           <a className="edit-tbl cursor">
                               <img src={phoneIcon} alt="" className="img-fluid"
                                    title={c.contact.telephone} width="25px"/>
                           </a>
                       </td>
                       <td>
                           <Link to={{
                               pathname: "/fiche-concession/" + c.location.emplacement.id,
                           }} className="edit-tbl">
                               consulter
                           </Link>
                       </td>
                       <td>
                           <p className="exporter-tbl cursor" onClick={() => GetPDF('concessionnaire', c.location.emplacement.id)}>
                               <img src={pdfIconTbl} alt="" className="img-fluid" />
                               exporter
                           </p>
                       </td>
                   </tr>
               )
       })
       return (
          <div>
              <div className="mx-3">
                  <div className="table-responsive px-3 scroll listes-leftpanel">
                      <table className="table table-striped table-borderless">
                          <thead>
                          <tr>
                              <th className="sortStyle">
                                  Carré / Allée
                                  <span onClick={() => this.sort('allee')}>
                                      <OrderBy isClicked={this.state.sortBy === 'allee'}/>
                                  </span>
                              </th>
                              <th className="sortStyle">N°</th>
                              <th className="sortStyle">
                                  Famille
                                  <span onClick={() => this.sort('famille')}>
                                        <OrderBy isClicked={this.state.sortBy === 'famille'}/>
                                    </span>
                              </th>
                              <th className="sortStyle">État</th>
                              <th className="sortStyle">
                                  Nom Prénom
                                  <span onClick={() => this.sort('nom')}>
                                        <OrderBy isClicked={this.state.sortBy === 'nom'}/>
                                    </span>
                              </th>
                              <th className="sortStyle">
                                  Type de contact
                                  <span onClick={() => this.sort('typeDeContact')}>
                                        <OrderBy isClicked={this.state.sortBy === 'typeDeContact'}/>
                                    </span>
                              </th>
                              <th className="sortStyle">Adresse</th>
                              <th colSpan="4" className="sortStyle">Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                            {tableRows}
                          </tbody>
                      </table>
                  </div>
              </div>
              <div className="container-fluid py-3 px-lg-5">
                  <div className="row d-flex justify-content-between">
                      <AjoutNouveau etat="Occupé"/>
                      <Pagination
                          ref          = {this.paginationRef}
                          url          = '/locataires/locataireByTypeEspace/'
                          eSpaceId     = {localStorage.getItem('espace_id')}
                          type         = 'Concessionnaire'
                          sort         = {this.state.sortBy}
                          order        = {this.state.orderBy}
                          filters      = {this.props.filters}
                          updateState  = {this.updateState}
                          search       = {this.state.search}
                      />
                      <div className="list-button d-flex justify-content-center justify-content-sm-end align-items-center mt-4 mt-sm-0">
                          <div>
                              <input type="search" onKeyUp={(e) => {this.setState((prevState) => {
                                  return {
                                      ...prevState,
                                      search: e.target.value
                                  }
                              })}} placeholder="Recherche" className={"form-control"} />
                          </div>
                          <div>
                              <button type="button" className="export-locations-list-btn d-flex cursor" onClick={() => GetListePDF('concessionnaire')}>
                                  <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                  <span className="soleilBold-fonts">EXPORTER LISTE <br/> CONCESSIONNAIRES</span>
                              </button>
                              <button type="button" className="export-locations-list-btn d-flex cursor" onClick={() => GetListePDF('concessionnaire',null, 'csv')}>
                                  <img src={filterIconSubmenu} alt="" className="img-fluid"/>
                                  <span className="soleilBold-fonts">EXPORTER EXCEL <br/> CONCESSIONNAIRES</span>
                              </button>
                          </div>
                          <div>
                          <select name="" className="form-control mb-2" id="" value={this.state.typeExt} onChange={(e) => {
                              this.state.typeExt = e.target.value
                          }}>
                              <option value="pdf">PDF</option>
                              <option value="csv">Excel</option>
                          </select>
                          <button type="button" className="listing-complete-btn soleilBold-fonts cursor" onClick={() => GetListePDF('location',  this.state.selectedId, this.state.typeExt)}>
                              Exporter la sélection
                              <span className="slt-nb">{ this.state.selected }</span></button>
                      </div>
                          <button type="button" className="listing-complete-btn soleilBold-fonts">Liste filtrée ( 0
                              )
                          </button>
                          <button type="button" className="listing-incomplete-btn soleilBold-fonts">Liste complète (
                              {this.state.total})
                          </button>
                      </div>
                  </div>
              </div>
          </div>
       )
   }
}

export default ConcessionnairesListe;