import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import filtreIcon from "../../assets/images/filter-icon-submenu.svg";
import filtreIconBlanc from "../../assets/images/filter-icon-submenu-blanc.svg";
import "../../assets/css/filtrer.css";

function FiltrerProcedure(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const type = [
        'Conversion',
        'Donation',
        'Renouvellement',
        'Reprise après constat d\'abandon',
        'Reprise pour non renouvellement',
        'Rétrocession'
        ]

    const handleFilterDate = function(event, idx, toggle) {
        const target = event.currentTarget;

        if (toggle && props.filters['date'][idx] !== "") {
            props.filters['date'][idx] = "";

        }  else {
            props.filters['date'][idx] = target.value;
        }

        props.emplacementListRef.current.refreshPagination();
    }
    const handleFilterProcedureChange = function(event, type) {
        const target = event.currentTarget;

        if (target.checked && props.filters[type].indexOf(target.value) === -1) {
            props.filters[type].push(target.value);
        }

        if (!target.checked && props.filters[type].indexOf(target.value) > -1) {
            props.filters[type] = props.filters[type].filter((value) => {
                return value !== target.value
            })
        }

        props.emplacementListRef.current.refreshPagination();
    }

    return (
        <>
            <span className="soleilRegular-fonts title cursor" onClick={handleShow} id="filtre-title">
                    <img src={filtreIcon}
                         alt=""
                         className="img-fluid mr-2" style={{width: '15px', marginTop: '-3px'}}/>
                {props.title}
            </span>

            <Modal show={show} onHide={handleClose} className="filtre-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <img src={filtreIconBlanc}
                             alt=""
                             className="img-fluid mr-2" style={{width: '15px', marginTop: '-3px'}}/>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-3 px-1 py-2 border-right-filtre">
                            <p className="purple text-uppercase font-weight-bold text-center">Type</p>
                            <div className="mt-3 px-2">
                                {
                                    type.map((item, idx) => {
                                        return (
                                            <div className="d-flex mt-2">
                                                <label className="switch" id="first-switch">
                                                    <input type="checkbox" id={"switch1" + idx}  value={item}  name="filters[type][]"  checked={props.filters.type.includes(item)} onChange={e => handleFilterProcedureChange(e, 'type')} />
                                                    <span className="slider round"></span>
                                                </label>
                                                <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor={"switch1" + idx}>
                                                    {item}
                                                </label></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="col-3 px-1 py-2 border-right-filtre">
                            <p className="purple text-uppercase font-weight-bold text-bold text-center">STATUT</p>
                            <div className="mt-3 px-2">
                                <div className="d-flex mt-2">
                                    <label className="switch" id="first-switch">
                                        <input type="checkbox" id="switch21"  value="en_cours"  name="filters[etat][]"  checked={props.filters.etat.includes('en_cours')} onChange={e => handleFilterProcedureChange(e, 'etat')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch21">En cours</label></div>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="switch" id="first-switch">
                                        <input type="checkbox" id="switch22"  value="termine"  name="filters[etat][]"  checked={props.filters.etat.includes('termine')} onChange={e => handleFilterProcedureChange(e, 'etat')} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch22">Terminé</label></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 px-1 py-2">
                            <p className="purple text-uppercase font-weight-bold text-bold text-center">Dates</p>
                            <div className="d-flex mt-2">
                                <label className="switch" id="first-switch">
                                    <input type="checkbox" id="switch31"  value="an_dernier"  name="filters[date][2]"  checked={props.filters.date[2] === 'an_dernier'} onChange={e => handleFilterDate(e, 2, true)} />
                                    <span className="slider round"></span>
                                </label>
                                <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch31">Effectués l'an dernier</label></div>
                            </div>
                            <div className="d-flex mt-2">
                                <label className="switch" id="first-switch">
                                    <input type="checkbox" id="switch32"  value="derniers_5_ans"  name="filters[date][3]"  checked={props.filters.date[3] === 'derniers_5_ans'} onChange={e => handleFilterDate(e, 3, true)} />
                                    <span className="slider round"></span>
                                </label>
                                <div className="ml-3 slider-label soleilBold-fonts purple"><label htmlFor="switch32">Effectués les 5 dernières années</label></div>
                            </div>

                            <div className="row mx-0 mt-4">
                                <div className="col-6">
                                    <label  className="purple text-uppercase font-weight-bold text-bold text-center">Entre</label>
                                    <div className={"exclu"}>
                                        <input type="date" name="filters[date][0]"  className={"mw-100 w-100"} value={props.filters.date[0]} onChange={e => handleFilterDate(e, 0, false)} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label className={"purple text-uppercase font-weight-bold text-bold text-center"}>Et</label>
                                    <div className={"exclu"}>
                                        <input type="date" name="filters[date][1]" className={"mw-100 w-100"} value={props.filters.date[1]} onChange={e => handleFilterDate(e, 1, false)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="red cancel-btn tbl" data-dismiss="modal" onClick={handleClose}>Annuler</button>
                    <button id="step-tour-valider" className="tbl btn-navigation bk-red ml-2 valider" onClick={handleClose}>Valider</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default FiltrerProcedure;