import React, { Component } from 'react';
import '../../assets/css/ajoutEspace.css'

class AjoutAbonnement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateDebut : '',
            dateRenouv: new Date(new Date().getFullYear() + 1, 0, 1),
            options   : [],
        }
    }

    handleChange = async (e)  =>{
        let name = e.target.name
        await this.setState({
            [name]: e.target.value,
        });
        this.props.updateEspace('abonnement', this.state)
    }

    handleOptions = async (e) => {
        let value   = e.target.value
        let options = this.state.options
        if(e.target.checked) {
            options.push(value)
        } else {
            const index = options.indexOf(value);
            if (index > -1) {
                options.splice(index, 1);
            }
        }
        await this.setState({options : options})
        this.props.updateEspace('abonnement', this.state)
    }

    render() {
        return (
            <>
                <div className="form-wrapper px-5">
                    <p className="soleilBold-fonts purple">Abonnement</p>
                    <div className="px-2">
                        <div className="d-flex pt-3">
                            <label htmlFor="dateDebut" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Début :</label>
                            <input type="date" id="dateDebut" name="dateDebut" value={this.state.debut}
                                   className="soleilRegular-fonts col-3" placeholder="Commune" onChange={this.handleChange} required/>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="options" className="col-3 p-0 m-0 soleilRegular-fonts col-3">Options :</label>
                            <div className="d-flex mt-4">
                                <label className="switch">
                                    <input type="checkbox" value="Audit" defaultChecked={false} onChange={this.handleOptions}/>
                                    <span className="slider round"/>
                                </label>
                                <div className="ml-3 slider-label  soleilBold-fonts">Audit</div>
                            </div>
                            <div className="d-flex mt-4">
                                <label className="switch">
                                    <input type="checkbox" value="Conseil divers (Aménagement, réglement de cimetière, ect)" defaultChecked={false} onChange={this.handleOptions}/>
                                    <span className="slider round"/>
                                </label>
                                <div className="ml-3 slider-label  soleilBold-fonts">Conseil divers (Aménagement, réglement de cimetière, ect)</div>
                            </div>
                            <div className="d-flex mt-4">
                                <label className="switch">
                                    <input type="checkbox" value="Import des données" defaultChecked={false} onChange={this.handleOptions}/>
                                    <span className="slider round"/>
                                </label>
                                <div className="ml-3 slider-label  soleilBold-fonts">Import des données</div>
                            </div>
                            <div className="d-flex mt-4">
                                <label className="switch">
                                    <input type="checkbox" value="Numérisation des actes de concession" defaultChecked={false} onChange={this.handleOptions}/>
                                    <span className="slider round"/>
                                </label>
                                <div className="ml-3 slider-label  soleilBold-fonts">Numérisation des actes de concession</div>
                            </div>
                            <div className="d-flex mt-4">
                                <label className="switch">
                                    <input type="checkbox" value="Saisie des archives des actes de concession" defaultChecked={false} onChange={this.handleOptions}/>
                                    <span className="slider round"/>
                                </label>
                                <div className="ml-3 slider-label  soleilBold-fonts">Saisie des archives des actes de concession</div>
                            </div>
                            <div className="d-flex mt-4">
                                <label className="switch">
                                    <input type="checkbox" value="Saisie des défunts" defaultChecked={false} onChange={this.handleOptions}/>
                                    <span className="slider round"/>
                                </label>
                                <div className="ml-3 slider-label  soleilBold-fonts">Saisie des défunts</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AjoutAbonnement;