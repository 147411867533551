import React from 'react';
import './styles.scss';


const Register = () => {
    return (
        <div className="container-fluid">
            <div className="row mt-5 mt-md-0">
                <div className="col-md-3 mx-auto">

                    <h1 className="text-center mb-5 text-primary">
                    </h1>

                    <form>
                        <div className="form-group">
                            <label htmlFor="inputUsername" className="sr-only">Identifiant</label>
                            <input name="username" type="text" id="inputUsername"
                                className="form-control" placeholder="Identifiant" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputUsername" className="sr-only">Email</label>
                            <input name="email" type="email" id="inputUsername"
                                className="form-control" placeholder="Email" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputPassword" className="sr-only">Mot de passe</label>
                            <input name="password" type="password"
                                id="inputPassword"
                                className="form-control" placeholder="Mot de passe" />
                        </div>

                        <button className="btn btn-outline-primary btn-block btn-primary">Inscription </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Register;
