import React, { Component } from 'react';
import '../../assets/css/main.css';
import '../../assets/css/formAjoutEmplacement.css';
import axios from "../../helpers/axios";
import $ from 'jquery';
import DateTools from "../../components/utils/DateTools";

class FormAttributionEmplacement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form          : [],
            eSpaceId      : localStorage.getItem('espace_id'),
            locationId    : this.props.locationId,
            emplacementId :  null,
            submitForm    : '/emplacements/form',
            surface       : this.props.surface,
            url           : ''
        }
    }

    componentDidMount() {
        this.addForm()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.emplacementId !== this.props.emplacementId || this.state.locationId !== this.props.locationId) {
            this.addForm()
        }
    }

    async addForm() {
        await this.setState({
            emplacementId : this.props.emplacementId,
            locationId : this.props.locationId
        })
        let url = '/emplacements/form/'+ this.props.emplacementId +'/' + this.state.eSpaceId
        if (this.props.locationId) {
            url = url + '/' + this.props.locationId
            this.setState({submitForm: '/emplacements/form/' + this.props.locationId})
        }
        if(this.state.url !== url) {
            await this.setState({url: url})
            axios.get(process.env.REACT_APP_API_URL + url)
                .then((response) => {
                    this.setState({form: response.data});
                    try {
                        this.formHandler()
                    } catch (error) {
                        console.log(error)
                    }
                })
        }
    }

    formHandler() {
        //document.querySelector('#attribution_emplacement_duree option[value="100"]').classList.add('display-none')
        const $tagsCollectionHolder = $('ul.contacts');
        const $tagsCollectionHolderExclus = $('ul.exclus');
        $tagsCollectionHolder.data('index', $tagsCollectionHolder.find('input').length);
        $tagsCollectionHolderExclus.data('index', $tagsCollectionHolder.find('input').length);

        document.getElementById('addDocumentAutre').addEventListener('click', (e) => {
            const newt = $(e.target).closest('.unique-check').find('.dl-input-wrapper:first').clone();
            const index = Math.ceil(Math.random() * 12000);
            newt.find('label').attr('for', newt.find('label').attr('for').replace('__x__', index))
            newt.find('.vich-file input').attr('id', newt.find('.vich-file input').attr('id').replace('__x__', index))
            newt.show().removeClass('display-none').appendTo( $(e.target).closest('.unique-check').find('.d-flex:first'));
        })

        document.querySelectorAll('.remove-documents-location').forEach((item) => {
            item.addEventListener('click', () => {
                if (!window.confirm('Etes vous sur de vouloir supprimer ce documents ?')) return;

                axios.post( process.env.REACT_APP_API_URL + '/locations/supprimerDocumentLocation', {
                    'locationId' : item.dataset.rel,
                    'documentId' : item.dataset.id
                }).then(() => {
                    $(item).closest('div').remove();
                })
            })
        })


        function addFormToCollection($collectionHolderClass,typeContact) {
            let $collectionHolder = $('.' + $collectionHolderClass);
            const maxItem = $collectionHolder.find('.new-contact').length + $collectionHolder.find('.update-contact div').length
            const dataPrototype = $collectionHolder.data('prototype')
            let index = $collectionHolder.data('index')
            let prototype = null
            if (dataPrototype) {
                prototype = dataPrototype.replaceAll('__name__', maxItem)
            }
            if (prototype) {
                let newForm = $(prototype)
                newForm.addClass('form-contact')
                $collectionHolder.data('index', index + 1)
                let $newFormLi = $("<li class='new-contact " + typeContact + "'></li>").append(newForm)
                if ($collectionHolderClass === 'contacts') {
                    if (maxItem === 0) {
                        $('#first-form').before($newFormLi)
                    } else {
                        $('.ajout-' + typeContact + ':last').after($newFormLi)
                    }
                } else {
                    $collectionHolder.append($newFormLi)
                }
                if($collectionHolderClass === 'contacts') {
                    let form=$('#attribution_emplacement_contacts_' + maxItem );
                    let formInputCivilite = $('#attribution_emplacement_contacts_' + maxItem + '_civilite_0');
                    if (formInputCivilite) {
                        formInputCivilite.prop('checked', true)
                    }
                    let formSelectType = $('#attribution_emplacement_contacts_' + maxItem + '_typeDeContact');
                    if (maxItem === 0) {
                        formSelectType.prop('selectedIndex', 0)
                        //formSelectType.addClass('disable-field')
                        form.prepend('<p class="soleilRegular-fonts mb-3">Veuillez renseigner les' +
                            ' informations concernant le concessionnaire : </p>');
                    } else {
                        if(typeContact === 'co-concessionnaire') {
                            formSelectType.prop('selectedIndex', 1)
                            //formSelectType.addClass('disable-field')
                            form.prepend('<p class="soleilRegular-fonts mt-4 mb-3">Veuillez renseigner les' +
                                ' informations concernant le contact : </p>');
                        } else {
                            formSelectType.prop('selectedIndex', 4)
                            //formSelectType.addClass('disable-field')
                            form.prepend('<p class="soleilRegular-fonts mt-4 mb-3">Veuillez renseigner les' +
                                ' informations concernant le contact de secours : </p>');
                        }
                    }

                    $('#attribution_emplacement_contacts_'+ maxItem + ' div:not(div:nth-child(1),div:nth-child(2),div:has( > [id$="civilite"]))').each(function (index) {
                        $(this).append('<div class="check-inconnu float-right"> ' +
                            '<input class="table-checkbox" id="inconnu-index' + index + maxItem + '" type="checkbox">'+
                            '<label for="inconnu-index' + index + maxItem + '" class="soleilBold-fonts purple p-0 m-0">Inconnu</label></div>');
                    })


                }
            }
        }
        $(function () {
            $('[data-toggle="popover"]').popover()
        })

        if($('#attribution_emplacement_contacts_0').length === 0) {
            addFormToCollection('contacts');
        } else {
            $("select[id$='typeDeContact']:not('.allow')").addClass('disable-field')
        }

        let typeEmplacement;
        let typeConcession;

        updateNavigation()

        function updateNavigation() {
            $('.formulaire .emplacement').hide();
            let navigation;
            typeEmplacement = $('#attribution_emplacement_type').val();
            let champs_commun = $('#champs-commun');
            let nbPlaces = $('#attribution_emplacement_nbPlaces');

            switch (typeEmplacement) {
                case "Ossuaire" :
                    navigation = ['Infos sur l\'ossuaire'];
                    $('.ossuaire').show();
                    break;

                case "Caveau" :
                    navigation = ['Infos sur le caveau provisoire'];
                    $('.caveau').show();
                    champs_commun.removeClass('etape3')
                    champs_commun.addClass('etape2')
                    nbPlaces.val(1)
                    break;

                case "Jardin du souvenir" :
                    navigation = ['Infos sur le jardin du souvenir'];
                    champs_commun.removeClass('etape3')
                    champs_commun.addClass('etape2')
                    $('.jardin-souvenir').show();
                    break;

                case "Terrain commun" :
                    navigation = ['Infos du contact', 'Infos sur le terrain commun'];
                    //champs_commun.removeClass('etape3')
                    champs_commun.addClass('etape3')
                    $('.terrain-commun').show();
                    nbPlaces.val(1)
                    $('#attribution_emplacement_typeConcession').val("Individuelle")
                    $('#attribution_emplacement_duree').val(5)
                    break;

                default:
                    navigation = ['Infos du concessionnaire','Infos de la concession', 'Type de concession', 'Actes'];
                    if (typeEmplacement === "Concession") {
                        $('#concession-surface').show()
                    }
                    champs_commun.removeClass('etape2')
                    champs_commun.addClass('etape3')
                    $('.concession').show();
                    break;
            }

            const progression = document.querySelector('.progression.toHandle')
            if (progression) {
                progression.innerHTML =
                    "<span class=\"jalon s1 fait\" id=\"jalon-1-emplacement\">" +
                    "    <div class=\"desc\">Vérification préalable</div>" +
                    "</span>"
            }

            if (typeEmplacement !== "") {
                for (let i in navigation) {
                    document.querySelector('.progression.toHandle').innerHTML +=
                        "<span class='ligne s" + (parseInt(i)+ 2) + "'></span> " +
                        "<span class='jalon'> " +
                        "<div class='desc'>" + navigation[i] + "</div>" +
                        "</span>"
                }
                document.querySelector('.progression.toHandle').innerHTML +=
                    "<span class='ligne s" + (navigation.length + 2) + " last'></span>" +
                    "<span class='jalon'>" +
                    "    <div class='desc'>Vérification et Validation</div>" +
                    "</span>"

                $('#jalon-1-emplacement').css('margin-right', 0);
            } else {
                $('.concession').hide();
                $('#jalon-1-emplacement').css('margin-right', '90%');
            }
        }

        $('#attribution_emplacement_type').on('change',(e) => {
            updateNavigation()
            document.getElementById('attribution_emplacement_tarifs').value = ''
            document.getElementById('attribution_emplacement_dateDebut').value = ''
            document.getElementById('attribution_emplacement_dateFin').value = ''
            if(e.target.value === 'Jardin du souvenir' || e.target.value === 'Ossuaire' || e.target.value === 'Terrain commun') {
                this.updateNumeroConcession(e.target.value)
            }
        });

        $(document).off('change','.add_item_link').on('change','.add_item_link', function () {
            if(this.value === 'oui') {
                if ($(this).hasClass('co-concessionnaire')) {
                    addFormToCollection('contacts', 'co-concessionnaire')
                    let autreConcessionnaire = $('.ajout-co-concessionnaire').last().clone()
                    $(".select-label-concessionnaire",autreConcessionnaire).text("Le concessionnaire souhaite ajouter un autre contact")
                    autreConcessionnaire.insertBefore(".ajout-contact-secours")
                } else if ($('.add_item_link').hasClass('contact-secours')) {
                    addFormToCollection('contacts', 'contact-secours')
                }
            } else {
                if ($(this).hasClass('co-concessionnaire')) {
                    $('.contacts li.co-concessionnaire').last().remove()
                    $('.ajout-co-concessionnaire').last().remove()
                }else {
                    $('.contacts li.contact-secours').last().remove()
                }
            }
        });

        $(document).off('change','#attribution_emplacement_typeConcession').on('change','#attribution_emplacement_typeConcession', function () {
            typeConcession = this.value;
            const liste_exclus = $('.ajout-exclus .list-exclus-wrapper');
            const texte = $('.ajout-exclus #text-exclus');
            const ligne_rempli_exclus = $('.list-group-item p:not(:empty)');
            const question_exclusion = $('#exclusion-champs').hide()

            $('.ajout-exclus').hide()
            question_exclusion.hide()
            document.getElementById('question-ajout-exclus').value = 'non'

            switch (typeConcession) {
                case 'Familiale' :
                    texte.text("Liste des personnes de la famille exclues du droit à l’inhumation " +
                        "dans la concession");
                    question_exclusion.show()
                    liste_exclus.show()
                    break;

                case 'Collective':
                    texte.text("Liste des personnes autorisées à être inhumées dans la concession");
                    liste_exclus.show()
                    $('.ajout-exclus').show()
                    break;

                case 'Individuelle':
                    texte.text("Nom de la personne autorisée à être inhumée dans la concession");
                    liste_exclus.show()
                    $('.ajout-exclus').show()
                    ligne_rempli_exclus.text("")
                    break;

                case 'Honorifique' :
                case 'Non défini':
                    texte.text("");
                    ligne_rempli_exclus.text("");
                    liste_exclus.hide();
                    $('.new-contact.exclu').remove();
                    $('#ajout-exclus').show();
                    $('#annuler_ajout_exclu').hide();
                    question_exclusion.hide()
                    break;
            }
        })

        $(document).off('change','.add-exclus').on('change','.add-exclus', function () {
            if(this.value === 'oui') {
                $('.ajout-exclus').show()
            } else {
                $('.ajout-exclus').hide()
                $('.new-contact.exclu').remove();
                $('.list-group-item').text("");

            }
        })

        $('#ajout-exclus').click(function () {
            addFormToCollection('exclus','exclu')
            $(this).hide()
            $('#annuler_ajout_exclu').show()
        })

        $('#annuler_ajout_exclu').click(function () {
            $('.new-contact.exclu:last').remove()
            $(this).hide()
            $('#ajout-exclus').show()
        })

        $(document).off('click','.valider-ajout-exclus').on('click','.valider-ajout-exclus', function () {
            const first_empty_element = $('.list-group-item p:empty:first').not('.existed');
            const form = $('.new-contact.exclu:last');
            let errorRequired = form.find( '[data-require]:visible').filter(function () {
                return $(this).val() === ""
            });

            if (errorRequired.length>0) {
                errorRequired.css('border', '1px solid red');
            } else {
                $('.new-contact.exclu:last').hide()
                first_empty_element.text(form.find('[id$=\'nom\']').val() + ' ' + form.find('[id$=\'prenom\']').val() )
                first_empty_element.parent().attr('id',$('.exclus').find('.new-contact').length)
                if($('.list-group-item p:empty').length === 1){
                    $('.list-group').append('<li class="list-group-item"><p></p><span class="supprimer-exclus"></span></li>')
                }
                if (typeConcession !== 'Individuelle') {
                    $('#ajout-exclus').show();
                    $('#annuler_ajout_exclu').hide();
                } else {
                    $('#ajout-exclus').hide();
                }
            }
        })

        $(document).off('click','.supprimer-exclus').on('click','.supprimer-exclus', function () {
            let numberForm = parseInt($(this).parent().attr("id")) - 1
            $(this).parent().remove()
            $('.list-group').append('<li class="list-group-item"><p></p><span class="supprimer-exclus"></span></li>')
            if (typeConcession === 'Individuelle') {
                $('#ajout-exclus').show()
                $('#annuler_ajout_exclu').hide()
            }

            if (numberForm !== undefined && isNaN(numberForm) === false ) {
                $('#attribution_emplacement_exclus_' + numberForm).remove();
            } else {
                const contactId = $(this).parent().find('p').attr('data-id')
                supprimerExcluLocation(contactId)
            }
        })

        function supprimerExcluLocation (contactId) {
            axios.post( process.env.REACT_APP_API_URL + '/locations/supprimerContact', {
                'contactId' : parseInt(contactId),
                'locationId' : this.state.locationId,
                'champs' : 'exclus'
            })
            if ( document.querySelector('.list-group-item.existed') === null) {
                document.querySelector('#question-ajout-exclus').classList.remove('disable-field')
            }
        }

        $('.unique-check .actes').change(function () {
            let parent = $(this).parent().parent().parent();
            if ($(this).prop('checked') === true) {
                parent.find('.actes').prop( "checked", false )
                $(this).prop("checked",true)

                if($(this).hasClass("dl")) {
                    parent.find('.dl-input-wrapper').show()
                    parent.find('input[type=file]').addClass('required-file')
                } else{
                    parent.find('input[id$=nom]').val(null)
                    parent.find('input[id$=description]').val(null)
                    parent.find('input[id$=file]').val(null)
                    parent.find('.select-pdf-file-name').text('Aucun fichier selectionné')
                    parent.find('.dl-input-wrapper').hide()
                    parent.find('input[type=file]').removeClass('required-file')
                }
            } else {
                parent.find('input[id$=nom]').val(null)
                parent.find('input[id$=description]').val(null)
                parent.find('input[id$=file]').val(null)
                parent.find('.select-pdf-file-name').text('Aucun fichier selectionné')
                parent.find('.dl-input-wrapper').hide()
                parent.find('input[type=file]').removeClass('required-file')
            }
        });

        $(document).on('change','#attribution_emplacement_perpetuelle', (e) => {
            if (e.target.checked) {
                $('#attribution_emplacement_duree').val("")
                document.getElementById('attribution_emplacement_dateFin').value = ''
                axios.get(process.env.REACT_APP_API_URL + '/tarifs/tarifDuree/' + localStorage.getItem('espace_id') + '/' + typeEmplacement + '/0/' + this.state.surface + '/true').then((res) => {
                    if (res.data) {
                        document.getElementById('attribution_emplacement_tarifs').value = res.data
                        document.getElementById('error').innerText = ""
                    } else {
                        document.getElementById('attribution_emplacement_tarifs').value = ''
                        document.getElementById('error').innerText = "Aucun tarif trouvé pour cette durée et ce type d'emplacement."
                    }
                })
            }
        })

        $(document).on('change','#attribution_emplacement_duree', (e) => {
            $('#attribution_emplacement_perpetuelle').prop( "checked", false )
            if (typeEmplacement && this.state.surface) {
                axios.get(process.env.REACT_APP_API_URL + '/tarifs/tarifDuree/' + localStorage.getItem('espace_id') + '/' + typeEmplacement + '/' + e.target.value + '/' + this.state.surface).then((res) => {
                    if (res.data) {
                        document.getElementById('attribution_emplacement_tarifs').value = res.data
                        document.getElementById('error').innerText = ""
                    } else {
                        document.getElementById('attribution_emplacement_tarifs').value = ''
                        document.getElementById('error').innerText = "Aucun tarif trouvé pour cette durée et ce type d'emplacement."
                    }
                })
            }
        })

        document.querySelectorAll('#attribution_emplacement_duree, #attribution_emplacement_dateDebut ').forEach(function (item) {
            item.addEventListener('change', function () {
                let dateDebut = document.getElementById('attribution_emplacement_dateDebut').value
                if (DateTools.compareDate( dateDebut, '1959-01-05')) {
                    document.querySelector('#attribution_emplacement_duree option[value="100"]').classList.remove('display-none')
                } else {
                    document.querySelector('#attribution_emplacement_duree option[value="100"]').classList.add('display-none')
                }
                let duree = document.getElementById('attribution_emplacement_duree').value
                dateDebut = new Date(dateDebut)
                if ((dateDebut && duree && parseInt(duree) !== 0 ) || typeEmplacement === 'Caveau' ) {
                    if (typeEmplacement === 'Caveau') {
                        dateDebut.setMonth(dateDebut.getMonth() + 6)
                    } else {
                         dateDebut.setFullYear(dateDebut.getFullYear() + parseInt(duree))
                    }
                    let day = ("0" + dateDebut.getDate()).slice(-2);
                    let month = ("0" + (dateDebut.getMonth() + 1)).slice(-2);
                    document.getElementById('attribution_emplacement_dateFin').value = dateDebut.getFullYear() + "-" + month + "-" + day
                } else {
                    document.getElementById('attribution_emplacement_dateFin').value = ''
                }
            })
        })

        const attriEmpSurface = document.getElementById('attribution_emplacement_surface')
        if (attriEmpSurface) {
            attriEmpSurface.addEventListener('change', (e) => {
                document.getElementById('attribution_emplacement_tarifs').value = ''
                if (e.target.value !== "11") {
                    this.setState({surface: e.target.value})
                    document.getElementById('autre-surface').classList.add('display-none')
                } else {
                    document.getElementById('autre-surface').classList.remove('display-none')
                }
            })
        }

        const attriEmpSurfaceAutre = document.getElementById('attribution_emplacement_surfaceAutre')
        if (attriEmpSurfaceAutre) {
            attriEmpSurfaceAutre.addEventListener('change', (e) => {
                this.setState({surface: parseFloat(e.target.value.replace(',', '.')).toFixed(2)})
            })
        }
    }

    updateNumeroConcession(type) {
        let selector
        switch (type) {
            case 'Jardin du souvenir' :
                selector = 'jardinDuSouvenir'
                break;
            case 'Ossuaire' :
                selector = 'ossuaire'
                break;
            case 'Terrain commun' :
                selector ='terrainCommun'
                break;
        }
        axios.get(process.env.REACT_APP_API_URL + '/emplacements/type/findOne/' + type + '/' + localStorage.getItem('espace_id')).then((res) => {
            const numberTypeField = document.getElementById('attribution_emplacement_' + selector)
            const number = parseInt(res.data)  + 1
            if (numberTypeField) {
                numberTypeField.options[0] = new Option(number, number)
            }
        })
    }

    sendFormulaire = () => {
        $('.modal').modal('hide')
        let data = ''
        const form = $('#etape1 .formulaire')
        let postDatas = form.find('form').serialize()
        postDatas += data
        postDatas += '&surface=' + this.state.surface
        postDatas += '&valider=1'
        axios.post(form.data('submit'), postDatas).then(() => {
            window.location.reload(true)
        })
    }

    render() {
        return (
            <div className="contents content-margin">
                <div className="modal fade procedure-modal modal-large" id="etape1" tabIndex="-1" role="dialog"
                     aria-labelledby="etape1" aria-hidden="true">
                    <div className="modal-dialog  d-flex justify-content-center align-items-center modal-wrap"
                         role="document">
                        <div className="modal-content" id="content-attribution-emplacement">
                            <div className="modal-header bk-grey">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-body-top" id="location-progression">
                                    <p className="soleilBold-fonts title purple">attribution de l'emplacement</p>
                                    <div className="progression toHandle">
                                        <span className="jalon s1 fait" id="jalon-1-emplacement">
                                            <div className="desc">Vérification préalable</div>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-wrapper scroll-popup">
                                    <div dangerouslySetInnerHTML={{__html:this.state.form}} className="formulaire" data-submit={process.env.REACT_APP_API_URL + this.state.submitForm}/>
                                    <div id='error'/>
                                    <div className="modal-footer navigation mt-5 p-0">
                                        <div>
                                            <button type="button"
                                                    className="tbl btn-navigation bk-red precedent display-none">Précédent
                                            </button>
                                        </div>
                                        <div id="location-navigation">
                                            <button className="red cancel-btn tbl" data-dismiss="modal">Annuler</button>
                                            <button type="button" className="tbl bk-red ml-2 suivant" id="location-form-suivant">Suivant</button>
                                            <button type="submit" className="tbl bk-red btn-navigation display-none ml-2 preview">Previsualiser</button>
                                            <button type="submit" className="tbl bk-red btn-navigation display-none ml-2 valider" onClick={this.sendFormulaire} id="location-form-valider">Valider</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FormAttributionEmplacement;