
import redBell from '../../assets/images/red-bell-icon.svg';
import searchPopupIcon from '../../assets/images/search-icon-popup.svg';
import React from 'react';
import {Link, NavLink} from 'react-router-dom'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import user from "../../helpers/user";
import axios from "../../helpers/axios";
import dayjs from "dayjs";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searched: false,
      searchedItems: {},
      searchInput: '',
      search: ['Emplacement', 'Location', 'Occupant', 'Concessionnaire', 'Procedure', 'Travaux']
    }
  }

  cancelImpersonate = async () => {
    await localStorage.removeItem('impersonate')
    window.location.href = "/switch-espace";
  }

  submitSearch = () => {

    axios.get(process.env.REACT_APP_API_URL + '/recherche/?espace_id=' + localStorage.getItem('espace_id') + '&include=' + this.state.search.join(',') + '&recherche=' + this.state.searchInput).then((data) => {
      console.log(data)
      this.setState((prevState) => {
        return {
          ...prevState,
          searchedItems: data['data']
        }
      })
    })
    this.setState((prevState) => {
      return {
        ...prevState,
        searched: true
      }
    })
  }

  checkSearchInput = (e) => {
    this.setState((prevState) => {
      let oldSearch = prevState.search;

      if (e.target.checked) {
        oldSearch.push(e.target.value)
      } else {
        oldSearch.splice(oldSearch.indexOf(e.target.value), 1)
      }

      return {
        ...prevState,
        search: oldSearch
      }
    })
  }

  render() {
    let nomEspace = ''
    if (this.props.societe.currentSociete && this.props.societe.currentSociete.eSpaces) {
      const societe = this.props.societe.currentSociete
      const id = localStorage.getItem('espace_id')
      const espace = societe.eSpaces.filter(espace => espace.id === parseInt(id))
      if (espace.length === 1) {
        nomEspace = espace[0].nom
      } else if (societe.length >= 1) {
        nomEspace = societe[0].nom
      }
    }
    let myuser = null
    if (localStorage.getItem(user.USER_LOCAL_KEY)) {
      myuser = JSON.parse(localStorage.getItem(user.USER_LOCAL_KEY))
    }
    return (
        <>
          {
            (this.props.location.pathname.indexOf("/switch-espace") === -1 && this.props.societe.currentSociete.nom )
                ? <div>
                  <div className="side-menu">
                    <div className="wrapper">
                      <h3 className="soleilBold-fonts title" id="sidebar-title">
                        <img src={redBell} alt="" className="img-fluid"/>&nbsp;
                        {nomEspace}
                      </h3>
                      <input type="search" className="search-input px-4"
                             placeholder="Rechercher pour ce cimetière" data-toggle="modal" data-target="#rechercherModal" value={this.state.searchInput} onKeyUp={(e) => {

                        if (e.key === 'Enter') {
                          window.$('#rechercherModal').modal('show')
                          return this.submitSearch()
                        }
                      }} onChange={(e) => {

                            this.setState((prevState) => {
                              return {
                                ...prevState,
                                searchInput: e.target.value
                              }

                             })
                             }} onClick={() => {
                            document.getElementById('searchinput_focus').value = ""
                               setTimeout(() => {
                                document.getElementById('searchinput_focus').focus()


                               }, 500)
                      }}/>
                      <div id="left-menu-item">
                        <NavLink exact to="/emplacement" className="soleilBold-fonts menu-item mt-5" id="emplacements-path">Emplacements</NavLink>
                        <NavLink isActive={(match, location) => {
                          return location.pathname.indexOf("/fiche-concession") > -1 || location.pathname.indexOf('/concessions') > -1;
                        }} to="/concessions" className="soleilBold-fonts menu-item" id="concessions-path">Concessions</NavLink>
                        <NavLink exact to="/defunts" className="soleilBold-fonts menu-item">Défunts</NavLink>
                        <NavLink exact to="/concessionnaires"
                                 className="soleilBold-fonts menu-item">Concessionnaires</NavLink>
                        <NavLink exact to="/travaux" className="soleilBold-fonts menu-item">Travaux</NavLink>
                        <NavLink exact to="/procedures" className="soleilBold-fonts menu-item">Procédures</NavLink>

                        {
                          myuser && (myuser.roles.includes('ROLE_SUPER_ADMIN') || myuser.roles.includes('ROLE_ADMIN')) ?
                              <div>
                                <NavLink exact to="/documents" className="soleilBold-fonts menu-item">Modèles</NavLink>
                                <NavLink exact to="/utilisateurs" className="soleilBold-fonts menu-item">Utilisateurs</NavLink>
                              </div>
                              : ''
                        }
                      </div>
                      <div className="side-menu-footer">
                        {
                          myuser && (myuser.roles.includes('ROLE_SUPER_ADMIN') || myuser.roles.includes('ROLE_ADMIN')) && localStorage.getItem('impersonate') ?
                              <p className="soleilRegular-fonts text-white text-center cursor underline-hover"
                                 onClick={this.cancelImpersonate}>Revenir à l'espace adminitrateur</p>
                              :''
                        }
                        {/*
                        <a href="#" className="col sidebar-shrink">
                          <img src={back} alt="" className="img-fluid"/>
                        </a>
                        <a href="#" className="col">
                          <img src={map} alt="" className="img-fluid"/>
                        </a>
                        <a href="#" className="col">
                          <img src={printer} alt="" className="img-fluid"/>
                        </a>
                        <a href="#" className="col">
                          <img src={stats} alt="" className="img-fluid"/>
                        </a>
                        <a href="#" className="col">
                          <img src={setting} alt="" className="img-fluid"/>
                        </a>
                          */}
                      </div>
                    </div>
                  </div>
                  <div className={"modal fade m-0 " + (this.state.searched ? 'show open' : '' )} id="rechercherModal" tabIndex="-1" role="dialog"
                       aria-labelledby="rechercherModal"
                       aria-hidden="true">
                    <div className="modal-dialog d-flex justify-content-center align-items-center modal-wrap"
                         role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <p className="soleilBold-fonts purple ml-3 mt-3">RECHERCHER</p>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body mx-3">
                          <div className="input-group ">
                            <input type="text" className="px-4"  id="searchinput_focus" placeholder="Votre recherche" name={"searchInput"}  value={this.state.searchInput} autoFocus onKeyUp={(e) => {
                              if (e.key === 'Enter') {
                                return this.submitSearch()
                              }
                            }}

                        onChange={(e) => {

                        this.setState((prevState) => {
                        return {
                        ...prevState,
                        searchInput: e.target.value
                      }
                      })
                      }}
                        />
                            <div className="input-group-prepend">
                              <div className="input-group-text d-flex justify-content-center"  onClick={this.submitSearch}>
                                <img src={searchPopupIcon} className="img-fluid" width="20px" alt=""/>
                              </div>
                            </div>
                          </div>
                          <div className="flex-wrap mx-2 justify-content-between recherche-switch mb-2">
                            <div className="d-flex mt-4 ">
                              <label className="switch" htmlFor={"switch-emplacement"}>
                                <input type="checkbox" defaultChecked={true} id={"switch-emplacement"} value={"Emplacement"} name={"search[]"} onChange={this.checkSearchInput}/>
                                <span className="slider round"></span>
                              </label>
                              <label className="ml-3 slider-label  soleilBold-fonts purple" htmlFor={"switch-emplacement"}>Emplacements</label>
                            </div>
                            <div className="d-flex mt-4">
                              <label className="switch" htmlFor={"switch-concession"}>
                                <input type="checkbox" defaultChecked={true} id={"switch-concession"} value={"Location"} name={"search[]"} onChange={this.checkSearchInput} />
                                <span className="slider round"></span>
                              </label>
                              <label className="ml-3 slider-label  soleilBold-fonts purple" htmlFor={"switch-concession"}>Concessions</label>
                            </div>
                            <div className="d-flex mt-4">
                              <label className="switch" htmlFor={"switch-defunt"}>
                                <input type="checkbox" defaultChecked={true} id={"switch-defunt"} value={"Occupant"} name={"search[]"} onChange={this.checkSearchInput} />
                                <span className="slider round"></span>
                              </label>
                              <label className="ml-3 slider-label  soleilBold-fonts purple" htmlFor={"switch-defunt"}>Défunts</label>
                            </div>
                            <div className="d-flex mt-4">
                              <label className="switch" htmlFor={"switch-concessionnaire"}>
                                <input type="checkbox" defaultChecked={true} id={"switch-concessionnaire"} value={"Concessionnaire"} name={"search[]"} onChange={this.checkSearchInput} />
                                <span className="slider round"></span>
                              </label>
                              <label className="ml-3 slider-label  soleilBold-fonts purple" htmlFor={"switch-concessionnaire"}>Concessionnaires</label>
                            </div>
                            <div className="d-flex mt-4">
                              <label className="switch" htmlFor={"switch-procedure"}>
                                <input type="checkbox" defaultChecked={true} id={"switch-procedure"} value={"Procedure"} name={"search[]"} onChange={this.checkSearchInput} />
                                <span className="slider round"></span>
                              </label>
                              <label className="ml-3 slider-label  soleilBold-fonts purple" htmlFor={"switch-procedure"}>Procédures</label>
                            </div>
                            <div className="d-flex mt-4">
                              <label className="switch" htmlFor={"switch-travaux"}>
                                <input type="checkbox" defaultChecked={true} id={"switch-travaux"} value={"Travaux"} name={"search[]"} onChange={this.checkSearchInput} />
                                <span className="slider round"></span>
                              </label>
                              <label className="ml-3 slider-label  soleilBold-fonts purple" htmlFor={"switch-travaux"}>Travaux</label>
                            </div>
                          </div>

                        </div>
                        {
                          this.state.searched ?
                              (
                              <div className={"searchPanel"}>
                                <p className="soleilBold-fonts purple mb-3 text-uppercase">Vos résultats :</p>

                                <div className={"recherche-switch"} style={{"gap": "20px"}}>
                                  {
                                    Object.keys(this.state.searchedItems).map((item, key) => {
                                      let icon = '';
                                      if (item === 'Concessionnaire') {
                                        icon = ( <span class="material-icons">manage_accounts</span> )
                                      }
                                      if (item === 'Occupant') {
                                        icon = ( <span class="material-icons">portrait</span> )
                                      }
                                      if (item === 'Travaux') {
                                        icon = ( <span class="material-icons">report_problem</span> )
                                      }
                                      if (item === 'Location') {
                                        icon = ( <span class="material-icons">menu_book</span> )
                                      }
                                      if (item === 'Emplacement') {
                                        icon = ( <span class="material-icons">place</span> )
                                      }
                                      if (item === 'Procedure') {
                                        icon = ( <span class="material-icons">checklist</span> )
                                      }
                                      return (
                                          <div className={"ressources-liens"}>
                                            <p className="purple soleilBold-fonts mt-2" style={{"fontSize": "20px"}}>
                                              { icon }
                                              <span style={{"verticalAlign": "text-bottom"}}>{ item === 'Occupant' ? 'Defunt' : item === 'Location' ? 'Concession' : item  }</span></p>

                                            { this.state.searchedItems[item].map((element) => {
                                              let title = element.eSData.id
                                              if (element.eSData.hasOwnProperty('nom')) {
                                                title = (element.eSData.genre || '') + ' ' + element.eSData.nom + ' ' + element.eSData.prenom
                                              }
                                              if (element.typeOccupation != "jardin" && element.eSData.hasOwnProperty('famille') ) {
                                                title = (element.eSData.famille || '') + ' ' + (element.eSData.hasOwnProperty('emplacement') && element.eSData.emplacement && element.eSData.emplacement.hasOwnProperty('numeroIntitule') ? element.eSData.emplacement.numeroIntitule : '') || ''
                                              }
                                              if (element.eSData.hasOwnProperty('numeroIntitule')) {
                                                title = element.eSData.numeroIntitule
                                              }
                                              if (element.eSData.hasOwnProperty('emplacement') && element.eSData.emplacement && element.eSData.emplacement.hasOwnProperty('numeroIntitule')) {
                                                title = element.eSData.emplacement.numeroIntitule
                                              }

                                              if (item === 'Travaux') {
                                                title = dayjs(element.eSData.dateDebut).format('DD/MM/YYYY') + " " + element.eSData.type + ' : ' + element.eSData.emplacement.numeroIntitule
                                              }

                                              return (
                                                  element.eSData.emplacementId ?
                                                  <a target="_blank" href={"/fiche-concession/" + element.eSData.emplacementId}>{
                                                    title } </a>
                                                      : <></>
                                              )
                                            })}
                                          </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                              ) : ''
                        }
                      </div>
                    </div>
                  </div>
                </div>
                : ''
          }
        </>
    );
  }
}
const mapStateToProps = ({ data = {}, isLoadingData = false, documents= {}, societe = {} }) => ({
  data,
  isLoadingData,
  societe,
  documents
});
export default withRouter(connect(
    mapStateToProps
)(Sidebar));
