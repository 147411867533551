import React, {Component} from "react";
import download from "../../assets/images/download-solid.svg";
import imageSolid from "../../assets/images/image-solid.svg";
import {Carousel, Modal, Tab, Tabs} from "react-bootstrap";
import axios from "axios";
import editIconPurple from "../../assets/images/edit-icon-purple.svg";

class ImagesEmplacement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gerer     : false,
            showSlide : false,
            images    : [],
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.emplacement && this.state.images !== this.props.emplacement.photos) {
            this.setState({
                images : this.props.emplacement.photos
            })
        }
    }

    onUploadFiles = async (e) => {
        const files = Array.from(e.target.files)
        await Promise.all(files.map(async(file )=> this.saveFile(file)))
        const images = this.state.images
        let ids = images.map(image => process.env.REACT_APP_API_URI + '/image_media/' + image.id)
        this.updateEmplacement(ids)
    }

    saveFile = async (file) => {
        let formData = new FormData()
        formData.append('file', file)
        await axios.post("/documents/upload/media", formData, {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers : {
                "Content-Type": "multipart/form-data",
            }
        }).then((res) => {
            let images = this.state.images
            images.push(res.data)
            this.setState({images: images})
        })
    }

    updateEmplacement = (ids) => {
        axios.put(process.env.REACT_APP_API_URL + '/emplacements/' + this.props.emplacement.id, {
            photos : ids
        })
    }

    archiverImage = (id) => {
        axios.put(process.env.REACT_APP_API_URL + "/image_media/" + id, {
            archive : true
        })
        const images = this.state.images
        const index = images.findIndex((image => image.id === id))
        images[index].archive = true
        this.setState({
            images : images
        })
    }

    render() {
        let images = this.state.images
        let carrouselItem = []
        if (this.props.emplacement) {
            carrouselItem = images.map(image => {
                if(!image.archive) {
                   return (
                       <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={process.env.REACT_APP_MEDIA_URL + image.nom}
                                alt="image concession"
                            />
                        </Carousel.Item>
                   )
                }
            })
        }
        const files = []
        const archives = []
        const actuelles = []

        images.map(image => {
            const toPush =
                <div className='img-wrapper'>
                    {
                        !image.archive ?
                            <span className="delete-btn-images" onClick={() => this.archiverImage(image.id)}>&times;</span>
                            : ''
                    }
                    <img src={process.env.REACT_APP_MEDIA_URL + image.nom} className="upload" alt="image emplacement" key="url"/>
                </div>
            if (image.archive) {
                archives.push(toPush)
            } else {
                actuelles.push(image)
                files.push(toPush)
            }
        })

        return (
            <div className="d-flex flex-column align-items-center">
                {
                    actuelles.length >= 1 && actuelles[0].nom ?
                        <div className="image-concession">
                            <img src={process.env.REACT_APP_MEDIA_URL + actuelles[0].nom} alt="" className="cursor" onClick={() => {this.setState({showSlide : true})}}/>
                        </div>
                        : ''
                }
                {
                    actuelles.length === 0 ?
                        <div className="empty-image">
                            <img src={imageSolid} className="icon-image" alt="icon-image" width="50px" onClick={() => {this.setState({gerer : true})}}/>
                        </div>
                        :''
                }
                <button type="button" className="editer-images-emplacement text-right mt-1" onClick={() => {this.setState({gerer : true})}}>
                    <img className="step-btn img-fluid mr-1" src={editIconPurple} alt="éditer"/>
                    modifier le(s) images
                </button>
                <Modal show={this.state.gerer} onHide={() => {this.setState({gerer : false})}} className="m-0 modal-images-emplacement d-flex justify-content-center align-items-center">
                    <Modal.Header className="bk-grey" closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="soleilBold-fonts title purple m-2">Photos de l'emplacement</p>
                        {
                            images.length === 0 ?
                                <div className="gerer-images">
                                    <img src={download} className="icon-image" alt="icon image" width="50px"/>
                                    <p className="soleilLight-fonts mt-1 grey">Aucune image ajoutée pour l'emplacement</p>
                                    <label htmlFor="ajout-image" className="ajout-image">Ajouter des images</label>
                                    <input type="file" accept=".jpg,.png,.jpeg" id="ajout-image" className="d-none" multiple
                                           onChange={this.onUploadFiles}/>
                                </div>
                                :
                                <div className="gerer-images not-empty">
                                    <Tabs defaultActiveKey="actuelles" id="photos" className="mb-3 w-100">
                                        <Tab eventKey="actuelles" title="Photos affichées">
                                            <div className="wrapper-photos-emplacement">
                                                {files}
                                            </div>
                                            <div className="float-right mr-2 mt-2">
                                                <label htmlFor="ajout-image" className="ajout-image">Ajouter des photos</label>
                                                <input type="file" accept=".jpg,.jpeg,.png" id="ajout-image" className="d-none" multiple
                                                       onChange={this.onUploadFiles}/>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="archivees" title="Anciennes photos">
                                            <div className="wrapper-photos-emplacement">
                                                {archives}
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="red cancel-btn tbl" onClick={() => {this.setState({gerer : false})}}>
                            Annuler
                        </button>
                        <button type="button" className="tbl bk-red ml-2" onClick={() => {this.setState({gerer : false})}}>
                            Terminé
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal id="display-zoom-images" show={this.state.showSlide} onHide={() => {this.setState({showSlide : false})}} className="bg-transparent">
                    {
                        actuelles.length > 1 ?
                            <Carousel slide={false} variant="dark">
                                {carrouselItem}
                            </Carousel>
                            :
                            actuelles.length === 1 && actuelles[0].nom ?
                                <img src={process.env.REACT_APP_MEDIA_URL + actuelles[0].nom} alt="image concession"/>
                                : ''
                    }
                </Modal>
            </div>
        )
    }
}

export default ImagesEmplacement;