import React, { Component } from 'react';
import axios from "../../helpers/axios";
import $ from "jquery";

class TravauxFormulaire extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emplacementId :  this.props.emplacementId,
            formTravaux: [],
            travauxId : null,
            url : ''
        }
    }

    componentDidMount() {
        this.getForm()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.travauxId !== this.props.travauxId) {
            this.getForm()
            this.setState({travauxId : this.props.travauxId})
        }
    }

    getUrl() {
        let url = process.env.REACT_APP_API_URL + '/travaux/form/' + this.state.emplacementId
        if (this.props.travauxId) {
            url += '/' + this.props.travauxId
        }
        return url;
    }

    getForm() {
        let url = this.getUrl()
        axios.get(url)
            .then((response) => {
                this.setState({
                    formTravaux: response.data,
                    travauxId : this.props.travauxId,
                    url : url
                })
            })
    }

    ajoutTravaux = () => {
        let checkboxValidation = document.querySelector("input[name='valid_input_travaux']");
        if (checkboxValidation !== null) {
            if (checkboxValidation.checked === true) {
                $('.modal').modal('hide')
                let postDatas = $(".formulaire.travaux form").serialize();
                const submit = document.querySelector(".formulaire.travaux").getAttribute('data-submit')
                postDatas += '&valider=1';
                axios.post(submit, postDatas).then((response) => {
                    this.props.updateListeTravaux(response.data)
                });
            } else {
                document.querySelectorAll('#etape5 .valid_form').forEach(event => event.classList.add('c-error'))
            }
        }
    }

    render() {
        require('../../assets/css/formPop-up.css')
        return (
            <React.Fragment>
                <div className="modal fade procedure-modal modal-large" id="etape5" tabIndex="-1" role="dialog"
                     aria-labelledby="etape5" aria-hidden="true">
                    <div className="modal-dialog  d-flex justify-content-center align-items-center modal-wrap"
                         role="document">
                        <div className="modal-content">
                            <div className="modal-header bk-grey">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-body-top">
                                    <p className="soleilBold-fonts title purple">ajout de travaux</p>
                                    <div className="progression">
                                        <span className="jalon s1 fait">
                                            <div className="desc">Demandeur</div>
                                        </span>
                                        <span className="ligne s2"/>
                                        <span className="jalon">
                                            <div className="desc">Type de travaux</div>
                                        </span>
                                        <span className="ligne s3"/>
                                        <span className="jalon">
                                            <div className="desc">Dates</div>
                                        </span>
                                        <span className="ligne s4"/>
                                        <span className="jalon">
                                            <div className="desc">Société</div>
                                        </span>
                                        <span className="ligne s5 last"/>
                                        <span className="jalon">
                                            <div className="desc">Vérification Validation</div>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-wrapper scroll-popup">
                                    <div dangerouslySetInnerHTML={{__html:this.state.formTravaux}} className="formulaire travaux" data-submit={this.state.url}/>
                                    <div className="modal-footer navigation mt-5 p-0">
                                        <div>
                                            <button type="button"
                                                    className="tbl bk-red precedent display-none">Précédent
                                            </button>
                                        </div>
                                        <div>
                                            <button className="red cancel-btn tbl" data-dismiss="modal">Annuler</button>
                                            <button type="button" className="tbl bk-red ml-2 suivant"  id="form-travaux-suivant">Suivant</button>
                                            <button type="submit" className="tbl btn-navigation bk-red display-none ml-2 defunt preview">Previsualiser</button>
                                            <button type="submit" className="tbl btn-navigation bk-red display-none ml-2 defunt valider" onClick={this.ajoutTravaux}>Valider</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default TravauxFormulaire;