import React, { Component} from "react";

class Parrainage extends Component{

    render() {
        return(
            <div className="container-fluid form-wrapper mt-3">
                <h1 className="title soleilBold-fonts purple mb-0">parrainage</h1>
                <p className="sous-notes soleilLight-fonts purple">Pour parrainer une commune, veuillez prendre contact
                    avec votre conseiller personnel.</p>
                <p className="soleilBold-fonts purple mt-4">Parrainer une commune fait économiser 1 mois d'abonnement
                    :</p>
                <div className="ml-5">
                    <div className="col-md-8 mt-3 options pl-0">
                        <div className="purple soleilBold-fonts green-tick  mt-3 ml-0 pl-0 ">Pour vous en tant que
                            parrain
                        </div>
                    </div>
                    <div className="col-md-8 mt-3 options pl-0">
                        <div className="purple soleilBold-fonts green-tick  mt-3 ml-0 pl-0 ">Pour la commune parrainée
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Parrainage;