import axios from "axios";
import {API} from "../actions/types";
import { accessDenied, apiError, apiStart, apiEnd } from "../actions/api";

const apiMiddleware = ({ dispatch }) => next => action => {

    if (typeof action == "undefined" || action.type === "ERROR") {
        action = {type: 'NOT_HANDLE'}
    }
    next(action);

    if (action.type !== API) return;

    const {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headers,
        type
    } = action.payload;
    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
    axios.defaults.baseURL = process.env.REACT_APP_API_URL || "";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.withCredentials = true;

    if (accessToken) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    }
    if (!accessToken && localStorage.getItem('currentUser')) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'))
        axios.defaults.headers.common["Authorization"] = `Bearer ${currentUser.token}`;
    }

    if (label) {
        dispatch(apiStart(label));
    }

    axios
        .request({
            url,
            method,
            headers,
            [dataOrParams]: data
        })
        .then(({ data }) => {
            dispatch(onSuccess(data, type));
        })
        .catch(error => {
            dispatch(apiError(error));
            dispatch(onFailure(error, type));

            if (error.response && error.response.status === 403) {
                dispatch(accessDenied(window.location.pathname));
            }
        })
        .finally(() => {
            if (label) {
                dispatch(apiEnd(label));
            }
        });
};

export default apiMiddleware;